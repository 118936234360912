import { CircularProgress } from '@mui/material'
import React from 'react'
export const TopBarLoading:React.FC = () => {
    return <CircularProgress
    size={30}
    style={{
      position: "fixed",
      top: 15,
      left: "calc(50vw - 15px)",
      color: "white",
      //   color: "rgb(21,128,61)",
      zIndex: 9999,
      marginLeft: 5,
      width: 30,
    }}
  />
}