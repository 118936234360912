import React from "react";

import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
} from "chart.js";
import { TAssetTypes, TProductAsset } from "../../../services/products";
import { useDashboardsStore } from "../../../store/hooks/useDashboardsStore";
import useWindowSize from "../../../utility/useWindowSize";
import { DashboadSizeID } from "../../../hooks/useProductDashboardDimensions";
import { MD_Charts } from "../MD_Charts";
import { DashboadSizeEnum } from "../../../services/frontendSettings";
import { MOBILE_Charts } from "../MOBILE_Charts";
import { LG_Charts } from "../LG_Charts";

ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend
);

const SBCharts: React.FC = () => {
  const { contextState } = useDashboardsStore();
  const charts =
    contextState.groupedProductAssets[TAssetTypes.Histogram]?.filter(
      (x) => x.ProductId === "iot-connected-buildings"
    ) ?? [];
  const { width, height } = useWindowSize();


  const size = React.useMemo(()=>DashboadSizeID(width,height),[width,height]);
  const Wrapper = React.useCallback((charts:TProductAsset[])=>{
      switch(size){
        case DashboadSizeEnum.XL_DESKTOP:return <LG_Charts charts={charts}/>
        case DashboadSizeEnum.LG_DESKTOP:return <LG_Charts charts={charts}/>
        case DashboadSizeEnum.MD_DESKTOP:return <MD_Charts charts={charts}/>
        case DashboadSizeEnum.SM_DESKTOP:return <MOBILE_Charts charts={charts}/>
        case DashboadSizeEnum.TABLET:return <MOBILE_Charts charts={charts}/>
        case DashboadSizeEnum.MOBILE:return <MOBILE_Charts charts={charts}/>
        default:return <MD_Charts charts={charts}/>
      }
  },[size])
  if(!width){
    return null;
  }
  return Wrapper(charts)
};
export { SBCharts };
