import { useTheme } from '@mui/material'
import React from 'react'
type Props = {
    direction?: "x" | "y" | "both"
    spacing:number
}
export const Spacer:React.FC<Props> = ({direction,spacing}) => {
    const theme = useTheme();
    const horizontal = !direction || direction === "x" || direction === "both";
    const vertical = !direction || direction === "y" || direction === "both";
    return <div style={{height: vertical ? theme.spacing(spacing) : 0,width:horizontal ? theme.spacing(spacing) : 0}}/>
}