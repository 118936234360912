import React from 'react'
import { UserAvatar } from './UserAvatar/UserAvatar';
export const UserAvatarIcon:React.FC = () => {
    const dropDownExtraStyles = React.useMemo(() => ({ zIndex:999999, backgroundColor: 'rgb(38,118,1)', right: 25, top: 55 }), [])
    const triangleExtraStyles = React.useMemo(() => ({
        borderBottom: '10px solid white',
        borderLeft: '10px solid transparent',
        borderRight: '10px solid transparent',
        top: -10,
        right:0,
    }), [])
    return <UserAvatar dropDownExtraStyles={dropDownExtraStyles} triangleExtraStyles={triangleExtraStyles} />
}