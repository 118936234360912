import { TAutomaticNotificationSetting } from "../services/automaticNotifications"
import { IAssignmentConfiguration } from "./assignments"

export interface TDateRange {
    startDate?: Date,
    endDate?: Date
}
export type TAlertColumnFilters = {
    activeSearch: string,
    deactiveSearch: string
    datePopupOpen: boolean
}
export type TAlertNote = {
    date: string
    note: string
    imgs: string[]
}

export type TAlertNotification = {
    sentBy: string | null
    NotificationType: string
    TargetUsers: string
    NotificationTime: string
}

export type TAlertAttachment = {
    Id:number,
    RowVersion:string,
    AlertId:number
    ReferenceDate:string,
    ReferenceUser:string | null,
    AttachmentType:"Comment"|"Image",
    AttachmentContent:string
}

export type TAlert = {
    OpenDate: string
    CloseDate: string
    RowVersion: string
    IsActive: boolean
    Id: number,
    Notifications: TAlertNotification[]
    TargetValue?:number
    Value?:number
}

export type TScorecard = {
    Acronym:string,
    Id: number,
    ScorecardGuid: string
    WorkspaceGuid: string
    Name: string
    Description: string
}

export type TMetric = {
    AssignmentsConfiguration?: IAssignmentConfiguration,
    AutomaticNotification: TAutomaticNotificationSetting[],
    Alerts: TAlert[]|null
    HasStatusRules: boolean,
    Id: number,
    MetricGuid: string
    Metrics: TMetric[]
    ChildMetrics: TMetric[]
    Name: string
    ParentGuid: string
    RowVersion: string
    Scorecard: TScorecard
    ScorecardId: number
    UoM:string
    TotalAttachments: number
    HelpDescription:string
    HasAnExitingConfiguration:boolean
}

export enum NotificationTypes {
    Mail = "Mail",
    Task = "Task"
}

export interface ISendNotification {
    AlertId:number,
    targetUsers:string[],
    UserNote:string,
    GraphAPIUserToken:string,
    NotificationType:NotificationTypes,
    ProductId:string,
}