import React from 'react';
import { useGlobalStore } from '../../store/hooks/useGlobalStore';
import { Alert, AlertTitle } from '@mui/material';
import useWindowSize from '../../utility/useWindowSize';
type Props = {
}
const GlobalErrorAlert: React.FC<Props> = () => {
    const {width, topbarHeight} = useWindowSize();
    const { globalState, setGlobalError } = useGlobalStore();
    const { globalError } = globalState;
    if((typeof globalError.Message !== "string") || (globalState.globalError.Message === '')){
        return null;
    }
    return <Alert
        style={{ zIndex: 1000000, position: 'fixed', right: 25, top: (topbarHeight ?? 60) + 25, maxWidth:(width && width > 600) ? 400 : 350 }}
        severity='error'
        onClose={() => setGlobalError({ Message: '', Details: '' })}
    >
        <AlertTitle>
            <p style={{whiteSpace:'pre-wrap'}} className="text-2xl text-red-500">{globalState.globalError.Message}</p>
        </AlertTitle>
        <p className="text-lg text-black">{globalState.globalError.Details}</p>
    </Alert>

}
export { GlobalErrorAlert }