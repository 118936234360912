import React from "react";
import {
  getAlertAttachments,
} from "../../../services/alerts";
import useWindowSize from "../../../utility/useWindowSize";
import { Panel, PanelType } from "@fluentui/react";
import { FlexCol } from "../../Flex/FlexCol";
import { FlexRow } from "../../Flex/FlexRow";
import { formatDate } from "../../../utility/formatDate";
import { AlertAttachment } from "../AlertAttachment/AlertAttachment";
import { MiniLoadingSpinner } from "../../spinner/MiniLoadingSpinner/MiniLoadingSpinner";
import { ImageSlider } from "../../ImageSlider/ImageSlider";
import { TAlert, TAlertAttachment, TMetric } from "../../../types/alerts";
const AlertDetailPanel: React.FC<{
  alert: TAlert;
  metric: TMetric;
  onClose: (e: any) => void;
}> = ({ alert, metric, onClose }) => {
  const { width } = useWindowSize();
  const panelWidth = React.useMemo(() => {
    if (!width) return "25%";
    return width > 1000 ? "25%" : "90%";
  }, [width]);
  const [isLoading, setIsLoading] = React.useState(false);
  const [attachments, setAttachments] = React.useState<TAlertAttachment[]>([]);
  React.useEffect(() => {
    setIsLoading(true);
    getAlertAttachments(alert)
      .then((res) => setAttachments(res))
      .finally(() => setIsLoading(false));
  }, [alert]);
  const [expandImage, setExpandImage] = React.useState(-1);
  const openImgSlider = (e: React.MouseEvent<HTMLDivElement>) => {
    e.stopPropagation();
    setExpandImage(0);
  };
  const handleCloseSlider = () => {
    setExpandImage(-1);
  };
  return (
    <>
      {expandImage >= 0 ? (
        <div
          style={{
            position: "fixed",
            left: 0,
            top: 0,
            width: "100vw",
            height: "100vh",
            zIndex: 10000001,
            backgroundColor: "rgba(0,0,0,0.9)",
          }}
          // onClick={handleCloseSlider}
        >
          <ImageSlider
            selected={expandImage}
            onClose={handleCloseSlider}
            images={attachments
              .filter((a) => a.AttachmentType === "Image")
              .map((a) => a.AttachmentContent)}
          />
        </div>
      ) : (
        <Panel
          isLightDismiss
          onDismiss={onClose}
          styles={{
            scrollableContent: {
              backgroundColor: "rgb(71 85 105)",
              height: "100%",
            },
            commands: {
              backgroundColor: "rgb(51 65 85)",
              color: "white",
              paddingBottom: 20,
              borderBottom: "1px solid lightgrey",
            },
            closeButton: { color: "white", borderColor: "white" },
          }}
          type={PanelType.custom}
          customWidth={panelWidth}
          isOpen={true}
          headerText={metric.Name}
          headerClassName="text-xxl text-white"
        >
          <FlexCol>
            <FlexRow
              justifyContent="space-between"
              className="w-[100%] border-b mb-4 mt-2"
            >
              <p className="text-xl text-white">
                Data apertura: {formatDate(alert.OpenDate)}
              </p>
              {alert.CloseDate && (
                <p className="text-xl text-white">
                  Data chiusura: {formatDate(alert.CloseDate)}
                </p>
              )}
            </FlexRow>
            {isLoading ? (
              <MiniLoadingSpinner
                style={{ color: "white", margin: "20px auto" }}
              />
            ) : (
              <>
                {attachments &&
                  attachments.map((attachment) => (
                    <AlertAttachment
                      openImgSlider={openImgSlider}
                      openSpecificImg={(n:number)=>setExpandImage(n)}
                      imgIndex={attachments.filter((a)=>a.AttachmentType==="Image").map((a)=>a.Id).indexOf(attachment.Id)}
                      attachment={attachment}
                    />
                  ))}
              </>
            )}
          </FlexCol>
        </Panel>
      )}
      {/* {openSlider && (
        <div
          style={{
            position: "fixed",
            left: 0,
            top: 0,
            width: "100vw",
            height: "100vh",
            zIndex: 10000001,
            backgroundColor: "rgba(0,0,0,0.9)",
          }}
        >
          <ImageSlider images={attachments.filter((a)=>a.AttachmentType === "Image").map((a)=>a.AttachmentContent)} />

        </div>
      )} */}
    </>
  );
};
export { AlertDetailPanel };
