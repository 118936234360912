import axios from "axios";
import {
  IDashboardColumnsDimensions,
  ProductPageSection,
} from "../hooks/useProductDashboardDimensions";

export enum DashboadSizeEnum {
  MOBILE = "MOBILE",
  TABLET = "TABLET",
  SM_DESKTOP = "SM_DESKTOP",
  MD_DESKTOP = "MD_DESKTOP",
  LG_DESKTOP = "LG_DESKTOP",
  XL_DESKTOP = "XL_DESKTOP",
}
const controllerName = "FrontendSettings";

export interface IFrontendSettingsGet {
  ProductId: string;
  UIOrder: number;

  IsVisible: boolean;

  SectionColumns: number;
  FrontendSectionsEnum: ProductPageSection;
  WindowSizeEnum: DashboadSizeEnum;

  RowVersion: string;
}

export const getVisibleFrontendSettingsPerProductSize = async (
  productId: string,
  size: DashboadSizeEnum
) => {
  return axios
    .get(
      `${process.env.REACT_APP_SERVER_URL}/${controllerName}/VisibleFrontendSettings/${productId}/${size}`
    )
    .then((res) => res.data as IFrontendSettingsGet[]);
};

export const getAllFrontendSettingsPerProductSize = async (
  productId: string,
  size: DashboadSizeEnum
) => {
  return axios
    .get(
      `${process.env.REACT_APP_SERVER_URL}/${controllerName}/AllFrontendSettings/${productId}/${size}`
    )
    .then((res) => res.data as IFrontendSettingsGet[]);
};

export const insertOrUpdateFrontendSettings = async (
  productId: string,
  size: DashboadSizeEnum,
  columns: IDashboardColumnsDimensions
) => {
  const payload = {
    frontendSettings: Object.entries(columns).map(
      ([FrontendSectionsEnum, setting]) => ({
        ...setting,
        productId,
        windowSizeEnum: size,
        FrontendSectionsEnum,
      })
    ),
  };

  return axios
    .post(`${process.env.REACT_APP_SERVER_URL}/${controllerName}`, payload)
    .then((res) => res.data as IFrontendSettingsGet[]);
};

export const getAllFrontendSettings = () => {
  return axios
    .get(`${process.env.REACT_APP_SERVER_URL}/${controllerName}`)
    .then((res) => res.data as IFrontendSettingsGet[]);
};
