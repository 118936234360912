import React from 'react';
import { DashboardHeaderText } from '../../DashboardHeader/DashboardHeaderText';
import { useDashboardsStore } from '../../../store/hooks/useDashboardsStore';
import { SearchAssets } from '../../../store/dashboardsStore';
import { ScorecardsIcon } from '../Icons/ScorecardsIcon';
import { HeaderProps } from './types';
import { ProductPageSection } from '../../../hooks/useProductDashboardDimensions';
import { AddAssetPanel } from '../../AddAssetPanel/AddAssetPanel';

const ScorecardColumnHeader: React.FC<HeaderProps> = ({ settings }) => {
    const {
        search, contextDispatch,addMode,
    } = useDashboardsStore();
    const setSearchValue = (s: string) => contextDispatch({
        type: "SET_SEARCH",
        payload: {
            ...search,
            [SearchAssets.SCORECARDS]: s
        }
    })
    const handleSearchChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        setSearchValue(e.target.value)
    }
    const handleClosePanel = (e: any) => {
        contextDispatch({ type: 'TOGGLE_ADD_MODE', payload: "scorecards" })

    }
    return <><DashboardHeaderText
        id={ProductPageSection.SCORECARDS}
        icon={<ScorecardsIcon onlyIcon />}
        settings={settings}
        editable={true}
        headerType={"scorecards"}
        text="Scorecards"
        searchKey={SearchAssets.SCORECARDS}
        setSearchValue={setSearchValue}
        handleSearchChange={handleSearchChange}
    />
    {addMode.SCORECARDS && <AddAssetPanel
        type="scorecards"
        closePanel={handleClosePanel}
    />}</>
}
export { ScorecardColumnHeader }