import React from "react";
import {DashboardIconProps} from "./iconsConstants";
import {DirectionalHint} from "@fluentui/react";
import {Link} from "react-router-dom";
import {
  SELECTED_ICON_QUERY_KEY,
} from "../../../hooks/useQueryValues";
import {TooltipContainer} from "../../TooltipContainer/TooltipContainer";
import {default_icon_height} from "./constants";
import {useDashboardsStore} from "../../../store/hooks/useDashboardsStore";
import {
  DESKTOP_ROW_DASHBOARD_CONTAINER_SIDEBAR_WIDTH_MINI,
  MOBILE_ROW_DASHBOARD_CONTAINER_SIDEBAR_WIDTH,
} from "../../RowDashboardContainer/RowDashboardContainerSidebar/RowDashboardContainerSidebar";
import {isMobile} from "react-device-detect";
import { useDashboardsNavigation } from "../../../store/hooks/useDashboardsNavigation";
import { useQuery } from "../../../hooks/useQuery";
const DashboardSideIcon: React.FC<DashboardIconProps> = ({
  tooltip,
  icon,
  id,
  onlyIcon
}) => {
  const query = useQuery();
  const {
    rowDashboardContainersidebarWidth,
    setRowDashboardContainerSidebarWidth,
  } = useDashboardsStore();
  const {
    getSectionPageUrl
  } = useDashboardsNavigation();
  const rowDashboardContainersidebarWidth_isFull = React.useMemo(
    () =>
      rowDashboardContainersidebarWidth >
      DESKTOP_ROW_DASHBOARD_CONTAINER_SIDEBAR_WIDTH_MINI,
    [rowDashboardContainersidebarWidth]
  );

  const styles = React.useMemo<React.CSSProperties>(
    () => ({
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      position: "relative",
      cursor: "pointer",
      width: 35,
      height: default_icon_height + 6,
      padding: "5px 1px",
      objectFit: "contain",
      backgroundColor: "white",
      color: "rgb(51,65,85)",
      fontWeight: "bold",
      borderRadius: 4,
      
    }),
    []
  );
  const href = React.useMemo(()=>getSectionPageUrl(id) ?? '',[id,getSectionPageUrl])
  const handleClick = () => {
    if (isMobile) {
      setRowDashboardContainerSidebarWidth(
        MOBILE_ROW_DASHBOARD_CONTAINER_SIDEBAR_WIDTH
      );
    }
  };
  const tooltipWrapper = (el: React.ReactElement) => {
    if (tooltip && !rowDashboardContainersidebarWidth_isFull) {
      return (
        <TooltipContainer
          tooltip={tooltip}
          tooltipDirection={DirectionalHint.rightCenter}
        >
          {el}
        </TooltipContainer>
      );
    } else {
      return el;
    }
  };

  return tooltipWrapper(
    rowDashboardContainersidebarWidth_isFull ? (
      <Link
        to={href}
        className="flex w-[100%] items-center"
        onClick={handleClick}
      >
        <div style={styles}>{icon}</div>
        {!onlyIcon && <p
          className={`ml-2 text-base text-white ${id === query.get(SELECTED_ICON_QUERY_KEY) ? "!font-bold !text-intesa-green underline" : ""}`}
        >
          {tooltip.text}
        </p>}
      </Link>
    ) : (
      <Link to={href} style={styles}>
        {icon}
      </Link>
    )
  );
};

export {DashboardSideIcon};
