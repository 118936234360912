import {
  ContextualMenu,
  FontIcon,
  IconButton,
  IDragOptions,
  Modal,
} from "@fluentui/react";
import React from "react";
import "./styles.css";
import {
  RowTypeEnum,
  TAssetBagRow,
  TAssetBagSection,
} from "../../services/products";
import { useUserContext } from "../../store/hooks/useUserContext";
import { hasPermission } from "../../store/hooks/useUserStore";
import { known_actions, sbh_area } from "../../types/userStore";
import { highlightClassNames, intesa_green } from "../../themes/themes";
import { FlexRow } from "../Flex/FlexRow";
import { HelpIcon } from "../HelpIcon/HelpIcon";
import { HelpPopup } from "../HelpPopup/HelpPopup";
import { isMobile } from "react-device-detect";
import { HelpModal } from "../HelpModal/HelpModal";
import { formatNumericValue } from "../../utility/formats";
enum SpecialSectionsEnum {
  RISPARMIO = "risparmio",
  RIEPILOGOEE = "riepilogo ee",
  RIEPILOGOGAS = "riepilogo gas",
}
const TableRowKpi: React.FC<{
  section?: string;
  row: TAssetBagRow | TAssetBagSection;
  showBorder: boolean;
  isFirst: boolean;
  valueExtraStyles?: (value: string | number) => React.CSSProperties;
}> = ({ isFirst, row, showBorder, section }) => {
  const containerClassName = React.useMemo(
    () =>
      `flex flex-row items-center min-h-[25px] ${
        showBorder ? "border-b border-black" : ""
      }`,
    [showBorder]
  );
  const innerContainerClassName = React.useMemo(() => {
    const isFirstHeader = row.RowType === RowTypeEnum.HEADER && !isFirst;
    let rowTypeClassNames = "";
    if (row.RowType === RowTypeEnum.HEADER) {
      rowTypeClassNames = "bg-slate-600 w-[100%] rounded";
    } else {
      rowTypeClassNames = "w-[70%] max-w-[70%]";
    }
    return `h-[100%] flex items-center min-h-[25px] 
        ${isFirstHeader ? "mt-1" : ""}
        ${rowTypeClassNames}`;
  }, [row.RowType, isFirst]);
  const descriptionClassNames = React.useMemo(() => {
    let marginsClassNames = "";
    if (row.RowType === RowTypeEnum.HEADER) {
      marginsClassNames = "font-bold ml-2";
    } else {
      marginsClassNames = "font-default ml-6";
    }
    return `${marginsClassNames}
        ${
          row.RowType === RowTypeEnum.HEADER ? "header-text" : "row-text"
        } align-baseline max-w-[100%] !px-2`;
  }, [row.RowType]);

  const [isHelpsOpen, setIsHelpsOpen] = React.useState(false);
  const handleOpenHelps = (e: any) => {
    e.stopPropagation();
    setIsHelpsOpen((isOpen) => !isOpen);
  };
  const yMargin = window.innerHeight > 1080 ? 3 : 1;
  const { userClaims } = useUserContext();
  const hasDefaultPermission = hasPermission(
    userClaims,
    known_actions.default_values,
    sbh_area
  );
  const highlightRowClassNames = React.useMemo(
    () =>
      hasDefaultPermission && row.RowType === RowTypeEnum.TEXT && row.IsDefault
        ? highlightClassNames
        : "",
    [hasDefaultPermission, row.IsDefault, row.RowType]
  );
  const id =
    row.RowType === RowTypeEnum.HEADER
      ? `row-header-${row.Value}-${row.Description}`
      : `row-${row.Value}`;
  const el = document.getElementById(id);
  const getNumericValue = (v: string | number) => {
    try {
      if (typeof v === "string") return parseFloat(v);
      return v;
    } catch (error) {
      return 0;
    }
  };
  const specialSectionsValueStyles: {
    [key in SpecialSectionsEnum]: (
      value: string | number
    ) => React.CSSProperties;
  } = React.useMemo(
    () => ({
      [SpecialSectionsEnum.RISPARMIO]: (v: string | number) => ({
        color: getNumericValue(v) > 0 ? intesa_green : "red",
        fontWeight: "bold",
      }),
      [SpecialSectionsEnum.RIEPILOGOEE]: (v: string | number) => ({
        color: getNumericValue(v) < 0 ? intesa_green : "red",
        fontWeight: "bold",
      }),
      [SpecialSectionsEnum.RIEPILOGOGAS]: (v: string | number) => ({
        color: getNumericValue(v) < 0 ? intesa_green : "red",
        fontWeight: "bold",
      }),
    }),
    []
  );
  const valueExtraStyles = React.useMemo<React.CSSProperties>(() => {
    if (!section) return {};
    if (
      Object.values(SpecialSectionsEnum)
        .map((x) => x.toString())
        .includes(section)
    ) {
      return specialSectionsValueStyles[section as SpecialSectionsEnum](
        row.Value
      );
    }
    return {};
  }, [row.Value, section, specialSectionsValueStyles]);
  return (
    <>
      <div className={`${containerClassName}`} id={id}>
        <div className={innerContainerClassName}>
          <p
            className={`${descriptionClassNames} ${highlightRowClassNames}`}
            style={{ marginTop: yMargin, marginBottom: yMargin }}
          >
            {hasDefaultPermission &&
            row.RowType === RowTypeEnum.TEXT &&
            row.IsDefault
              ? `${row.Description}*`
              : row.Description}
          </p>
          <FlexRow className="ml-auto" alignItems="center">
            {row.RowType === RowTypeEnum.HEADER && row.Value && (
              <p className="ml-2 text-xl mr-2 font-bold text-white">
                {row.Value}
              </p>
            )}
            {row.Helps && (
              <IconButton
                className="hover:bg-slate-800 mr-1 w-[20px] h-[20px] !p-0 bg-slate-600 rounded"
                onClick={handleOpenHelps}
              >
                {/* <FontIcon iconName='Help' className="text-white font-bold hover:text-white text-center" style={{fontSize:12}}/> */}
                <HelpIcon style={{height:20,width:20}}/>
              </IconButton>
            )}
          </FlexRow>
        </div>
        {row.RowType !== RowTypeEnum.HEADER && (
          <FlexRow
            className={
              "min-w-[100px] h-[100%] mx-1 max-w-[30%] w-[30%] align-baseline"
            }
            justifyContent="flex-end"
            alignItems="baseline"
          >
            <p style={valueExtraStyles} className="row-text w-[70%] text-right">
              {row.Value
                ? formatNumericValue(parseFloat(row.Value.toString()),row.Format)
                : "-"}
            </p>
            <p className="row-text-uom w-[30%] ml-2 mr-1 text-right">
              {row.UoM ?? ""}
            </p>
          </FlexRow>
        )}
      </div>
      {row.RowType === RowTypeEnum.HEADER && (
        <HelpPopup
          onClose={() => setIsHelpsOpen(false)}
          growDirection="up"
          isOpen={isHelpsOpen}
          width={isMobile ? 350 : 450}
          helps={row.Helps ?? []}
          elementAttach={el}
        />
      )}
      {row.Rows &&
        row.Rows.map((r, i) => (
          <TableRowKpi
            section={row.Description.toLowerCase()}
            key={`${row.Description}-${i}`}
            row={r}
            isFirst={false}
            showBorder={i !== (row.Rows ? row.Rows.length - 1 : -1)}
          />
        ))}
    </>
  );
};
export { TableRowKpi };
