import React from "react";
import "./styles.css";
import { useUserContext } from "../../store/hooks/useUserContext";
import { hasPermission } from "../../store/hooks/useUserStore";
import { know_urls } from "../../types/userStore";
import { VerticalDashboardSection } from "../VerticalDashboardSection/VerticalDashboardSection";
import { useDashboardsStore } from "../../store/hooks/useDashboardsStore";
import { SectionProps } from "./types";
import { TCElement } from "./ServicesComponents/TCElement";
import { SBAssignmentsElement } from "./ServicesComponents/SBAssignmentsElement";
enum ServicesEnum {
  TICKET_CONNECTOR = "Ticket Connector",
  LOGBOOK = "Logbook",
  SBAssignments = "Segnalazioni",
}
const ServicesColumn: React.FC<SectionProps> = ({ productId, settings }) => {
  const { userClaims } = useUserContext();
  const _hasTCPermissions = hasPermission(
    userClaims,
    process.env.REACT_APP_TICKET_CONNECTOR_URL ?? ("" as any),
    'iot-connected-buildings'
  );
  const services: {
    [x: string]: { [key in ServicesEnum]?: React.ReactElement | null };
  } = React.useMemo(
    () => ({
      [know_urls.iot_dashboard.slice(1)]: {
        [ServicesEnum.TICKET_CONNECTOR]: _hasTCPermissions ? (
          <TCElement productId={productId} />
        ) : null,
        [ServicesEnum.SBAssignments]: <SBAssignmentsElement productId={productId} />,
      },
    }),
    [_hasTCPermissions, productId]
  );
  const { search } = useDashboardsStore();
  const filterServices = React.useCallback(
    (x: [string, React.ReactElement | null]) => {
      if (!search.SERVICES) return true;
      let _ = search.SERVICES.toLowerCase();
      return x[0].toLowerCase().includes(_);
    },
    [search.SERVICES]
  );
  return (
    <VerticalDashboardSection
      title=""
      settings={settings}
      cotainerStyles={{ paddingBottom:250 }}
      id="SBServices"
    >
      {services[productId]
        ? Object.entries(services[productId])
            .filter(filterServices)
            .map((x) => x[1])
        : null}
    </VerticalDashboardSection>
  );
};
export { ServicesColumn };
