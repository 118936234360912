import React from 'react';
import { HeaderProps } from './types';
import { DashboardHeaderText } from '../../DashboardHeader/DashboardHeaderText';
import { ProductPageSection } from '../../../hooks/useProductDashboardDimensions';
import { FilesIcon } from '../Icons/FilesIcon';
export type FilesHeaderProps = {}
export const FilesHeader: React.FC<HeaderProps> = ({settings}) => {
  
    return (
      <DashboardHeaderText
        id={ProductPageSection.FILES}
        icon={<FilesIcon onlyIcon />}
        settings={settings}
        editable={false}
        text="Executive Reports"
      />
    );
}

