import React from "react";
import { PaginationEnum, paginationContext } from "../../store/pager";
import { globalContext } from "../../store/globalStore";
import { useLocation } from "react-router-dom";
import { IColumnFilter, IColumnSorting } from "../../types/odata";
import { APIError } from "../../types/general";
import { useUserContext } from "../../../../store/hooks/useUserContext";
type Props = {
  enumKey: PaginationEnum;
  items: any[];
  loadItems: (
    skip: number,
    pageSize: number,
    sortings: IColumnSorting<any>[],
    filters: IColumnFilter<any>[]
  ) => Promise<{total_items:number,result_items:any[]}>;
  renderItem: (x: any) => React.ReactElement;
  renderContainer: (
    children: React.ReactElement,
    handleScroll: (e: any) => void
  ) => React.ReactElement;
};
export function arraysAreSame(
  v1: any[],
  v2: any[],
  compareFunc: (x1: any, x2: any) => boolean
) {
  if (v1.length !== v2.length) return false;
  for (let i = 0; i < v1.length; i++) {
    if (!compareFunc(v1[i], v2[i])) return false;
  }
  return true;
}
const Pager: React.FC<Props> = ({
  loadItems,
  renderContainer,
  renderItem,
  items,
  enumKey,
}) => {
  const { accessToken } = useUserContext();
  const { state, dispatch } = React.useContext(paginationContext);
  const {state:globalState,dispatch:globalDispatch} = React.useContext(globalContext);
  const {
    sortings,
    filters,
    showFilters,
    isLoading,
    page,
    pageSize,
    totalItems,
    LAST_CALL_PAGE,
    LAST_CALL_SORTINGS,
    LAST_CALL_FILTERS,
    items:state_items
  } = state.pagination[enumKey];
  const totalPages = Math.ceil(totalItems / pageSize);
  const { pathname } = useLocation();
  const setPage = (x: number) =>
    dispatch({
      type: "SET_PAGINATION",
      payload: {
        key: enumKey,
        pagination: { ...state.pagination[enumKey], page: x },
      },
    });
  const setIsLoading = (x: boolean) =>
    dispatch({
      type: "SET_PAGINATION",
      payload: {
        key: enumKey,
        pagination: { ...state.pagination[enumKey], isLoading: x },
      },
    });
  const handleScroll = (e: any) => {
    const el = e.target;
    var scroll_limit = el.scrollHeight - document.body.clientHeight;

    if (e.target.scrollTop < scroll_limit || isLoading) {
      return;
    } else {
      if (page < totalPages && items.length < totalItems) {
        setPage(page + 1);
      }
    }
    // if (e.target.scrollTop >= scroll_limit - 150) {
    //     if (page < totalPages && items.length < totalItems) {
    //         setPage(page + 1)
    //     }
    // }
  };
  React.useEffect(() => {
    const _load = async () => {
      if (isLoading) {
        return;
      }
      const isFirstCall = LAST_CALL_PAGE < 0;
      const pageIsChanged = !isFirstCall && LAST_CALL_PAGE !== page;
      const sortings = state.pagination[enumKey].sortings;
      const filters = state.pagination[enumKey].filters;
      const filtersPresets = state.pagination[enumKey].filtersPresets;
      const sortingsChanged = !arraysAreSame(
        sortings,
        LAST_CALL_SORTINGS,
        (x: IColumnSorting<any>, y: IColumnSorting<any>) =>
          x.key === y.key &&
          x.direction === y.direction &&
          x.innerClass === y.innerClass
      );
      const filtersChanged = !arraysAreSame(
        filters,
        LAST_CALL_FILTERS,
        (x: IColumnFilter<any>, y: IColumnFilter<any>) =>
          x.columnName === y.columnName &&
          x.value === y.value &&
          x.operator === y.operator
      );
      if (accessToken && !isLoading) {
        if (isFirstCall || pageIsChanged || sortingsChanged || filtersChanged) {
          setIsLoading(true);
          let newPage = page < 0 ? 0 : page;
          if (sortingsChanged || filtersChanged) {
            newPage = 0;
          }
          loadItems(newPage * pageSize, pageSize, sortings, filters)
            .then(
              ({total_items,result_items}) => {
                dispatch({
                  type: "SET_PAGINATION",
                  payload: {
                    key: enumKey,
                    pagination: {
                      items:result_items,
                      filters,
                      filtersPresets,
                      sortings,
                      pageSize,
                      page: newPage,
                      LAST_CALL_PAGE: newPage,
                      LAST_CALL_SORTINGS: sortings,
                      LAST_CALL_FILTERS: filters,
                      totalItems:total_items,
                      isLoading: false,
                      showFilters
                    },
                  },
                });
              }
            )
            .catch((err:APIError)=>{
              console.log(err);
              globalDispatch({
                type:"SET_ERROR",
                payload:{
                  details:err.response?.data.Message as string,
                  message:"Qualcosa è andato storto, aggiorna la pagina"
                }
              })
              dispatch({type: "SET_PAGINATION",
              payload: {
                key: enumKey,
                pagination: {
                  ...state.pagination[enumKey],
                  isLoading: false,
                },
              }})
            });
        }
      }
    };
    _load();
  }, [
    page,
    accessToken,
    sortings,
    filters,
    LAST_CALL_PAGE,
    LAST_CALL_SORTINGS,
    LAST_CALL_FILTERS,
    pathname,
  ]);
  return renderContainer(<>{items.map((x) => renderItem(x))}</>, handleScroll);
};
export { Pager };
