import React from 'react'
import { Page } from '../../components/Page/Page'
import { useQuery } from '../../utility/useQuery';
import { IconButton, Typography } from '@mui/material';
import { ETicketDetail } from '../../components/ETicketDetail/ETicketDetail';
import { getSingleTicket } from '../../services/eticket';
import { paginationContext } from '../../store/pager';
import { NavigationStateKeysEnum, navigationContext } from '../../store/navigationStore';
import { ISidebarOptions } from '../../types/panels';
import { topbarHeight } from '../../components/Page/Topbar/Topbar';
import { useSidebars } from '../../store/hooks/useSidebars';
import { ETicketListNavigator } from '../../components/ETicketListNavigator/ETicketListNavigator';
import { DisposableSidebar } from '../../components/Page/Sidebar/DisposableSidebar/DisposableSidebar';
import { APIError } from '../../types/general';
import { globalContext } from '../../store/globalStore';
import { EticketSourceTypeEnum } from '../../types/assignments';
import { ExtendedAlertDetail } from '../../components/AlertDetail/ExtendedAlertDetail';
import { useIsMobile } from '../../utility/useIsMobile';
import { DisposableSidebarIcon } from '../../components/Page/Sidebar/DisposableSidebar/DisposableSidebarIcon/DisposableSidebarIcon';
import { ExtendedSuperMetricValueDetailExpandableContainer } from '../../components/SuperMetricValueDetail/ExtendedSuperMetricValueDetailExpandableContainer';
import { useUserContext } from '../../../../store/hooks/useUserContext';

export const TicketDetailPage: React.FC = () => {
    const { isMobile, bottombarHeight,sidebarWidth } = useIsMobile();
    const { state: paginationState, dispatch: paginationDispatch } = React.useContext(paginationContext);
    const { dispatch: globalDispatch } = React.useContext(globalContext);
    const { items: tickets } = paginationState.pagination.TICKETS;
    const { isLoaded } = paginationState;
    const { accessToken } = useUserContext();
    const query = useQuery();
    const [isLoading, setIsLoading] = React.useState(false);
    const { state, dispatch } = React.useContext(navigationContext)
    const setSidebarOptions = (opt: ISidebarOptions) => dispatch({ type: "SET_SIDEBAR_OPTIONS", options: opt })
    const { defaultBottombarHeight } = useSidebars();
    const defaultSidebarOptions: ISidebarOptions = {
        isOpen: !isMobile,
        isModal: false,
        isClosable: true,
        width: "s",
        position: "right",
        transitionMovementType: "horizontal",
        transitionResizeType: "width",
        childrens: <ETicketListNavigator />,
        offset: {
            x: 0,
            y: topbarHeight,
        },
    }
    React.useEffect(() => {
        setSidebarOptions(defaultSidebarOptions);
    }, [])
    const [ticket, setTicket] = React.useState(tickets.find((t) => t.Id === query.get("id")))
    React.useEffect(() => {
        return () => paginationDispatch({ type: "SET_ISLOADED", payload: { ...isLoaded, TICKETS_DETAILS: false } })
    }, [])
    React.useEffect(() => {
        const _load = async () => {
            if (accessToken) {
                setIsLoading(true);
                getSingleTicket(query.get("id") ?? '-1')
                    .then((result) => {
                        setTicket(result)
                    })
                    .catch((err: APIError) => globalDispatch({ type: "SET_ERROR", payload: { message: err.response.data.Message, details: err.toString() } }))
                    .finally(() => setIsLoading(false))
            }
        }
        _load();
    }, [query, accessToken, isLoaded])

    return <Page>
        {!state[NavigationStateKeysEnum.SIDEBAR].isOpen &&
            <IconButton
                style={{
                    position: 'fixed',
                    right: 10,
                    top: isMobile ? 'unset' : 'calc(50vh - 20px)',
                    bottom: isMobile ? defaultBottombarHeight + 5 : 'unset',
                    height: 40,
                    width: 40
                }}
                onClick={() => setSidebarOptions({ ...defaultSidebarOptions, isOpen: true })}>
                <DisposableSidebarIcon direction='left' />
            </IconButton>}
        <DisposableSidebar height={isMobile ? window.innerHeight - topbarHeight - defaultBottombarHeight : window.innerHeight - topbarHeight} />
        {ticket ?
            <div className="flex flex-col items-center" style={{ padding: 5, width: `calc(100vw - ${sidebarWidth}px)`, height: `calc(100% - ${bottombarHeight + 10}px)`, overflowY: 'auto', overflowX: 'hidden' }}>
                <ETicketDetail isLoading={isLoading} ticket={ticket} />
                <div className='mt-4' />
                {ticket.SourceType === EticketSourceTypeEnum.Metric && <>
                    <ExtendedAlertDetail id={ticket.ExternalEventId} />
                </>}
                {ticket.SourceType === EticketSourceTypeEnum.SuperMetric && <>
                    <ExtendedSuperMetricValueDetailExpandableContainer 
                        id={ticket.ExternalEventId}
                    />
                </>}
            </div>
            :
            <div className="flex flex-col w-[100%] h-[100%] items-center mt-10">
                <Typography>Ticket non trovato</Typography>
            </div>
        }
    </Page>

}