import React from "react";
import {ServicesColumn} from "../../Columns/ServicesColumn";
import {useDashboardsNavigation} from "../../../store/hooks/useDashboardsNavigation";
export type ServicesSectionProps = {};
export const ServicesSection: React.FC<ServicesSectionProps> = ({}) => {
  const {productId,areaSettings} = useDashboardsNavigation();
  return (
    <>
      <ServicesColumn
        settings={areaSettings?.SERVICES}
        productId={productId ?? ""}
      />
    </>
  );
};
