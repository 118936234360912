import React from 'react'
import { Grid } from '@mui/material'
import { DetailsContainer } from '../DetailsContainer/DetailsContainer'
import { EticketSourceTypeEnum, TAssignment } from '../../types/assignments'
import { globalContext } from '../../store/globalStore'
import { getPagedOdataItems } from '../../services/generic'
import { IColumnFilter } from '../../types/odata'
import { APIError } from '../../types/general'
import { ASSIGNMENTS_CONTROLLER } from '../../services/assignments'
import { TopBarLoading } from '../TopBarLoading/TopBarLoading'
import { AssignmentDetail } from '../AssignmentDetail/AssignmentDetail'
import { useUserContext } from '../../../../store/hooks/useUserContext'
type Props = {
    sourceType: EticketSourceTypeEnum
    id: number
}
export const AssignmentEventDetail: React.FC<Props> = ({ sourceType, id }) => {
    const { authenticatedCall } = useUserContext();
    const [isLoading, setIsLoading] = React.useState(false);
    const { dispatch: globalDispatch } = React.useContext(globalContext);
    const [assignments, setAssignments] = React.useState<TAssignment[]>()
    const odataFilters: IColumnFilter<TAssignment>[] = React.useMemo(() => [
        {
            columnName: "SourceType",
            value: sourceType,
            valueType: "enum",
            operator: "eq"
        },
        {
            columnName: "ExternalEventId",
            value: id,
            valueType: "number",
            operator: "eq"
        }
    ], [id, sourceType])
    React.useEffect(() => {
        const _load = async () => {
            setIsLoading(true);
            getPagedOdataItems(ASSIGNMENTS_CONTROLLER, 0, 50, odataFilters, [], false)
                .then((res) => setAssignments(res.value))
                .catch((err: APIError) => globalDispatch({ type: "SET_ERROR", payload: { message: err.response.data.Message, details: err.toString() } }))
                .finally(() => setIsLoading(false))
        }
        authenticatedCall(_load);
    }, [authenticatedCall, globalDispatch, odataFilters])
    if (isLoading) {
        return <TopBarLoading />
    }
    if (!alert) return null;

    // return <div className="flex flex-col items-center w-[100%]" style={{ padding: 5 }}>
    return <Grid item xs={12} sm={12} md={6}>
        {isLoading ? <TopBarLoading /> : <>
            {assignments && assignments.length > 0 ? assignments?.map((assignment) =>
                <AssignmentDetail
                    fullWidth
                    assignment={assignment}
                    isLoading={isLoading}
                    key={assignment.Id}

                />
                // <DetailsContainer fullWidth>
                //     <p className='text-3xl text-center h-[30px] my-2'>Assegnazione</p>
                //     <DetailsLabelValueContainer roundedUp>
                //         <DetailsLabel label='ID' />
                //         <DetailsValue value={assignment.Id} />
                //         {/* <Typography variant='body1' style={{ borderRight: isMobile ? undefined : '1px solid', width: isMobile ? undefined : 225 }} className={`${isMobile ? "!text-xl" : "!text-3xl"} !mr-2`}>ID</Typography> */}
                //         {/* <Typography variant='body1' className={`${isMobile ? "!text-xl" : "!text-3xl"}`}>{assignment.Id}</Typography> */}
                //     </DetailsLabelValueContainer>
                //     <DetailsLabelValueContainer>
                //         <DetailsLabel label='Assegnato a' />
                //         <DetailsValue value={assignment.OwnerEmail} />
                //         {/* <Typography variant='body1' style={{ borderRight: isMobile ? undefined : '1px solid', width: isMobile ? undefined : 225 }} className={`${isMobile ? "!text-xl" : "!text-3xl"} !mr-2`}>Assegnato a</Typography>
                //     <Typography variant='body1' className={`${isMobile ? "!text-xl" : "!text-3xl"}`}>{assignment.OwnerEmail}</Typography> */}
                //     </DetailsLabelValueContainer>
                //     <DetailsLabelValueContainer>
                //         <DetailsLabel label='Data Creazione' />
                //         <DetailsValue value={formatDate(assignment.OpeningTime)} />
                //         {/* <Typography variant='body1' style={{ borderRight: isMobile ? undefined : '1px solid', width: isMobile ? undefined : 225 }} className={`${isMobile ? "!text-xl" : "!text-3xl"} !mr-2`}>Data Creazione</Typography>
                //     <Typography variant='body1' className={`${isMobile ? "!text-xl" : "!text-3xl"}`}>{formatDate(assignment.OpeningTime)}</Typography> */}
                //     </DetailsLabelValueContainer>
                //     <DetailsLabelValueContainer>
                //         <DetailsLabel label='Data Scadenza' />
                //         <DetailsValue value={formatDate(assignment.ExpirationTime)} />
                //         {/* <Typography variant='body1' style={{ borderRight: isMobile ? undefined : '1px solid', width: isMobile ? undefined : 225 }} className={`${isMobile ? "!text-xl" : "!text-3xl"} !mr-2`}>Scadenza</Typography>
                //     <Typography variant='body1' className={`${isMobile ? "!text-xl" : "!text-3xl"}`}>{formatDate(assignment.ExpirationTime)}</Typography> */}
                //     </DetailsLabelValueContainer>
                //     <DetailsLabelValueContainer>
                //         <DetailsLabel label='ClosureReason' />
                //         <DetailsValue value={assignment.ClosureReason} />
                //         {/* <Typography variant='body1' style={{ borderRight: isMobile ? undefined : '1px solid', width: isMobile ? undefined : 225 }} className={`${isMobile ? "!text-xl" : "!text-3xl"} !mr-2`}>ID</Typography> */}
                //         {/* <Typography variant='body1' className={`${isMobile ? "!text-xl" : "!text-3xl"}`}>{assignment.Id}</Typography> */}
                //     </DetailsLabelValueContainer>
                //     <DetailsLabelValueContainer roundedDown>
                //         <DetailsLabel label='Stato' />
                //         <DetailsValue value={Status_Mapper[assignment.Status.toString().toLowerCase()]} />
                //         {/* <Typography variant='body1' style={{ borderRight: isMobile ? undefined : '1px solid', width: isMobile ? undefined : 225 }} className={`${isMobile ? "!text-xl" : "!text-3xl"} !mr-2`}>Stato</Typography>
                //     <Typography variant='body1' className={`${isMobile ? "!text-xl" : "!text-3xl"}`}>{Status_Mapper[assignment.Status.toString().toLowerCase()]}</Typography> */}
                //     </DetailsLabelValueContainer>
                // </DetailsContainer>
            )
                :
                <DetailsContainer fullWidth>
                    <p className='text-xl text-center w-[100%]'>Nessuna assegnazione creata</p>
                </DetailsContainer>

            }
        </>
        }
    </Grid>
}