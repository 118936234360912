import React from 'react';
import { TMetric } from '../../../types/alerts';
import { ExpandableContainer } from '../../ExpandableContainer/ExpandableContainer';
import { AlertRow } from '../AlertRow';
import { Pager } from '../../Pager/Pager';
import '../styles.css'
type Props = {
    metric: TMetric
    level: number
    productId: string
    lastOpen: number
    setLastOpen: (n: number) => void
}
const MetricRow: React.FC<Props> = ({ metric, productId, level, lastOpen, setLastOpen }) => {
    const alertIsActive: (a: TMetric) => boolean = (a: TMetric) => {
        if (a.Alerts && a.Alerts.some(a => a.IsActive)) return true;
        let result = false;
        if (a.Metrics && a.Metrics.length > 0) {
            for (let i = 0; i < a.Metrics.length; i++) {
                result = result || alertIsActive(a.Metrics[i]) as boolean
            }
        }
        return result as boolean;
    }
    const someActiveAlert = React.useMemo(() => {
        return alertIsActive(metric);
    }, [metric])
    const alertsLength = React.useMemo(() => {
        let result = 0;
        const countLeafs = (a: TMetric) => {
            if (a.Metrics && a.Metrics.length > 0) {
                result += a.Metrics ? a.Metrics.map(m => m.Alerts ? m.Alerts.length : 0).reduce((a, b) => a + b, 0) : 0;
                if (a.Metrics) {
                    a.Metrics.forEach((x) => countLeafs(x));
                }
            }
        }
        countLeafs(metric)
        return result;
    }, [metric])
    return metric.HasStatusRules && metric.Alerts && metric.Alerts.length > 0 ?
        <>
            {metric.Alerts && //ALERTS RENDER

                <Pager
                    items={metric.Alerts.sort((a, b) => a.OpenDate > b.OpenDate ? 1 : -1)}
                    renderItem={(a) => <AlertRow lastOpen={lastOpen} setLastOpen={setLastOpen} metric={metric} alert={a} level={level} productId={productId} />}
                    renderContainer={(children:React.ReactElement,handleScroll)=><div 
                    style={{maxHeight:'100%',overflowY:'auto',overflowX:'hidden'}}
                    onScroll={handleScroll}>
                        {children}
                    </div>}
                    pageSize={10}
                />
            }
            {metric.Metrics && metric.Metrics.length > 0 && //ALERT METRIC
            
                <ExpandableContainer
                    containerIsClickable
                    textClassNames='container-title mb-2 text-white'
                    iconPosition='beforeText'
                    iconButtonClassNames='text-white text-xl my-auto hover:bg-transparent hover:text-intesa-green'
                    containerClassNames={` bg-slate-700 mx-8 p-1 my-1 rounded-md border flex ${someActiveAlert ? '' : ''}`}
                    containerExtraStyles={{
                        marginLeft: 35 * (level + 1),
                        width: `calc(100% - ${35 * (level + 1)}px)`
                    }}
                    textIsClickable
                    text={metric.Name}
                    headerAfterChildren={level === 0 && <p className={`text-xl font-bold ml-auto mr-4 my-auto text-white border ${someActiveAlert ? 'bg-amber-600' : 'bg-intesa-green'} rounded text-center px-4 py-1`}>{alertsLength}</p>}
                >
                    {metric.Metrics?.map((m) => <MetricRow lastOpen={lastOpen} setLastOpen={setLastOpen} level={level + 1} productId={productId} metric={m} />)}
                </ExpandableContainer>}
        </>
        :
        <ExpandableContainer
            containerIsClickable
            textClassNames='container-title mb-2 text-white'
            iconPosition='beforeText'
            iconButtonClassNames='text-white text-xl my-auto hover:bg-transparent hover:text-intesa-green'
            containerClassNames={`bg-slate-700 mx-8 p-1 my-1 rounded-md border flex ${someActiveAlert ? '' : ''}`}
            containerExtraStyles={{
                marginLeft: 35 * (level),
                width: `calc(100% - ${35 * (level)}px)`
            }}
            textIsClickable
            text={metric.Name}
            headerAfterChildren={level === lastOpen && <p className={`text-xl font-bold ml-auto mr-4 my-auto text-white border ${someActiveAlert ? 'bg-amber-600' : 'bg-intesa-green'} rounded text-center px-4 py-1`}>{alertsLength}</p>}
        >
            {/* DEFAULT METRIC */}
            {metric.Metrics?.map((m) => <MetricRow lastOpen={lastOpen} setLastOpen={setLastOpen} level={level + 1} productId={productId} metric={m} />)}
        </ExpandableContainer>

}
export { MetricRow }
