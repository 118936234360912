import React from 'react'
import { CurrentConfigurationsList } from '../CurrentConfigurationsList';
import { CurrentSelectedMetrics } from '../CurrentSelectedMetrics';
import { AssignmentsStepper } from '../../AssignmentsStepper';
export const MetricAssignmentsSummary: React.FC = () => {
    
    return <>        
        <CurrentSelectedMetrics summaryValues/>

        <CurrentConfigurationsList />

        <AssignmentsStepper />
    </>
}