import React from "react";
import { FlexCol } from "../Flex/FlexCol";
import useWindowSize from "../../utility/useWindowSize";
import { IconButton } from "@mui/material";
import { AiOutlineClose } from "react-icons/ai";
type Props = {
  closePanel: (e: any) => void;
  children?: React.ReactNode;
  title:string
};
const EditPanel: React.FC<Props> = ({ closePanel, children, title }) => {
  const { width } = useWindowSize();
  return (
    <>
      <div
        className="h-[100vh] w-[100vw] fixed cursor-pointer"
        onClick={closePanel}
        style={{
          backdropFilter: "blur(2px)",
          backgroundColor: "rgba(0,0,0,0.25)",
          zIndex: 99998,
          left: 0,
          top: 0,
        }}
      />
      <FlexCol
        justifyContent="start"
        alignItems="start"
        className="fixed bg-slate-700 px-4 pt-4 text-white"
        style={{
          zIndex: 99998,
          height:'calc(100vh - 50px)',
          right: 0,
          top: 0,
          width:
            width && width * 0.35 > 500
              ? "35%"
              : width && width < 1000 ? '70%' : '45%'
        }}
      >
        <div className="absolute left-0 top-0 h-[60px] flex bg-slate-800 items-center w-[100%] justify-between">
          {title && <p className="text-2xl">{title}</p>}
          <IconButton
            onClick={closePanel}
            // className="!absolute !right-[5px] !top-[5px]"
          >
            <AiOutlineClose className="text-white" />
          </IconButton>
        </div>
        <div style={{marginTop:60}}/>
        {children}
      </FlexCol>
    </>
  );
};
export { EditPanel };
