import React from "react"
import { ISortableHeader } from "../../../types/general"

export type ODataProps = {
    headers: ISortableHeader<any>[],
}
export interface IContext {
    hiddenHeadersWidth:string
    resizedHeadersWidth:string
    visibleHeaders:ISortableHeader<any>[]
}
export const scrollbarWidth = "6px";
export const headerWidth = (h: ISortableHeader<any>,hiddenHeadersWidth:string,resizedHeaderWidth:string, removeScrollbar: boolean,isLast:boolean) => isLast ? undefined : `calc(${h.width} - ${resizedHeaderWidth} + ${hiddenHeadersWidth} + ${h.resize ?? 0}px - ${removeScrollbar ? scrollbarWidth : "0px"})`
export const headerMaxWidth = (h: ISortableHeader<any>,hiddenHeadersWidth:string,resizedHeaderWidth:string,  isLast: boolean) => isLast ? undefined : `calc(${h.width} - ${resizedHeaderWidth} + ${hiddenHeadersWidth} + ${h.resize ?? 0}px)`
export const useODataGrid: (props: ODataProps) => IContext = ({ headers }) => {
    const hiddenHeadersWidth = React.useMemo(() => {
        const hiddenHeaders = headers.filter((h) => !h.isVisible)
        const visibleHeaders = headers.filter((h) => h.isVisible)
        if (hiddenHeaders.length === 0) {
            return "0px"
        }
        const perc_headers = hiddenHeaders.filter((h) => h.width.toString().includes("%"))
        const perc_widths = perc_headers.map((x) => x.width.toString().substring(0, x.width.toString().length - 1)).reduce((a, b) => a + parseFloat(b), 0)
        const static_headers = hiddenHeaders.filter((h) => h.width.toString().includes("px"));
        const static_widths = static_headers.map((x) => x.width.toString().substring(0, x.width.toString().length - 2)).reduce((a, b) => a + parseFloat(b), 0)
        if (perc_headers.length > 0 && static_headers.length > 0) {
            return `calc(${perc_widths / visibleHeaders.length}% + ${static_widths / visibleHeaders.length}px)`
        }
        if (perc_headers.length > 0) {
            return `calc(${perc_widths / visibleHeaders.length}%)`
        }
        if (static_headers.length > 0) {
            return `calc(${static_widths / visibleHeaders.length}%)`
        }
        return "0px";
    }, [headers])
    const resizedHeadersWidth = React.useMemo(() => {
        const resizedHeaders = headers.filter((h) => h.resize)
        const visibleHeaders = headers.filter((h) => h.isVisible)
        if (resizedHeaders.length === 0) {
            return "0px"
        }
        const resizeSum = resizedHeaders.map((x) => x.resize ?? 0).reduce((a, b) => a + b, 0)
        if (resizeSum > 0) {
            return `calc(${resizeSum / visibleHeaders.length}px)`
        }
        return "0px";
    }, [headers])
    const visibleHeaders = React.useMemo(() => headers.filter((h) => h.isVisible), [headers])
    
    return {
        hiddenHeadersWidth,
        resizedHeadersWidth,
        visibleHeaders,
    }
}