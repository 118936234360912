import React from "react";
import { Route, Routes, useLocation, useNavigate } from "react-router-dom";
import { AssignmentList } from "../../pages/AssignmentList/AssignmentList";
import { CircularProgress, IconButton, Typography } from "@mui/material";
import { AssignmentDetailPage } from "../../pages/AssignmentDetailPage/AssignmentDetailPage";
import { TicketList } from "../../pages/TicketList/TicketList";
import { TicketDetailPage } from "../../pages/TicketDetailsPage/TicketDetailsPage";
import { Leftbar } from "../Page/Sidebar/Leftbar";
import { PagesNavigator } from "../Page/PagesNavigator/PagesNavigator";
import { Topbar } from "../Page/Topbar/Topbar";
import { Bottombar } from "../Page/Bottombar/Bottombar";
import { Home } from "../../pages/Home/Home";
import { UserAvatarIcon } from "../UserIcon/UserAvatarIcon";
import { SettingsPage } from "../../pages/SettingsPage/SettingsPage";
import { AlertEventDetailPage } from "../../pages/EventDetailPage/AlertEventDetailPage";
import { SuperMetricEventDetailPage } from "../../pages/EventDetailPage/SuperMetricEventDetailPage";
import { assignments_known_urls } from "../../types/userStore";
import { GoBackIcon } from "./GoBackIcon/GoBackIcon";
import { TitleLabel } from "./TitleLabel/TitleLabel";
import { InputEventsPage } from "../../pages/InputEventsPage/InputEventsPage";
type Props = {};
const AuthenticatedRoutes: React.FC<Props> = () => {
  const SBHubLogo = React.useMemo(() => {
    return (
      // eslint-disable-next-line jsx-a11y/alt-text
      <img
        src={require("../../images/logo/LogoSBHub.png")}
        style={{ height: 45, width: 100, objectFit: "contain" }}
      />
    );
  }, []);
  const { pathname } = useLocation();
  return (<>
    <Topbar>
      <div className="flex w-[100%]">
        <div className="ml-4"/>
        <GoBackIcon />
        <TitleLabel />
      </div>
      <UserAvatarIcon />

    </Topbar>
    <Leftbar>
      <PagesNavigator />
    </Leftbar>
    <Routes>
      <Route path="*" element={<div className="h-[100vh] w-[100vw] bg-slate-700 flex flex-col justify-center items-center"><CircularProgress style={{ color: 'white' }} /></div>} />
      <Route path={assignments_known_urls.home} element={<Home />} />
      <Route path={assignments_known_urls.assignment_list} element={<AssignmentList />} />
      <Route path={assignments_known_urls.assignment_detail} element={<AssignmentDetailPage />} />
      <Route path={assignments_known_urls.ticket_detail} element={<TicketDetailPage />} />
      <Route path={assignments_known_urls.tickets_list} element={<TicketList />} />
      <Route path={assignments_known_urls.settings} element={<SettingsPage />} />
      <Route path={assignments_known_urls.input_events_list} element={<InputEventsPage />} />
      <Route path={assignments_known_urls.alert_event_detail} element={<AlertEventDetailPage />} />
      <Route path={assignments_known_urls.supermetric_event_detail} element={<SuperMetricEventDetailPage />} />
    </Routes>
    <Bottombar>
      <PagesNavigator />
    </Bottombar>
  </>
  );
};
export { AuthenticatedRoutes };
