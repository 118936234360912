import axios from "axios"
import moment from "moment"
import * as types from "../types/actions/alerts"
import { ISendNotification, NotificationTypes, TAlert, TAlertAttachment, TDateRange, TMetric } from "../types/alerts"

export interface ISendMailNotification extends ISendNotification {
    NotificationType:NotificationTypes.Mail
}

export interface ISendTaskNotification extends ISendNotification {
    NotificationType:NotificationTypes.Task
}

export const SendAlertMail = async (payload:ISendMailNotification) => {
    return axios.post(`${process.env.REACT_APP_SERVER_URL}/Notification/sendEmail`, payload).then((res) => res.data)
}

export const CreateAlertTask = async (payload:ISendTaskNotification) => {
    return axios.post(`${process.env.REACT_APP_SERVER_URL}/Notification/createTask`, payload).then((res) => res.data)

}

export const getActiveAlertsWithHierarchy = async (productIDFilter:string) => {
    return axios.get(`${process.env.REACT_APP_SERVER_URL}/Alert/ActiveAltertWithHierarchy/${productIDFilter}`).then((res) => res.data)
}
export const getDeactiveAlertsWithHierarchy = async (productIDFilter:string,dateFilter?: TDateRange | null) => {
    const dateFormat = 'YYYY-MM-DD'
    let url = `${process.env.REACT_APP_SERVER_URL}/Alert/ArchivedAltertWithHierarchy/${productIDFilter}`;
    if (dateFilter) {
        if (moment(dateFilter.startDate).format(dateFormat) !== moment(dateFilter.endDate).format(dateFormat)) {
            url += `/${moment(dateFilter.startDate).format(dateFormat)}/${moment(dateFilter.endDate).format(dateFormat)}`
        } else {
            url += `/${moment(dateFilter.startDate).format(dateFormat)}`
        }
    }
    return axios.get(url).then((res) => res.data)
} 

export const GetPlannerUsers = async (token:string)=>{
    const payload:types.IGetPlannerUsersRequest = {
        graphAPIUserToken:token
    }
    return axios.post(`${process.env.REACT_APP_SERVER_URL}/Notification/GetPlanerUsers`,payload).then(res=>res.data)
}

export const getAlertAttachments = async (alert:TAlert)=>{
    return axios.get(`${process.env.REACT_APP_SERVER_URL}/AlertAttachment/ByAlertId/${alert.Id}`).then((res)=>res.data as TAlertAttachment[])
}

export const getProductMetrics = async (productId:string)=>{
    const url = `${process.env.REACT_APP_SERVER_URL}/Metric/Hierarchy/${productId}`
    return axios.get(url).then((res)=>res.data as TMetric[])
}
