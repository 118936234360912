import React from "react";
import useWindowSize from "../utility/useWindowSize";

export function useMainDashboardDimensions() {
    const { width, height } = useWindowSize();
    const getProductsSlice = React.useCallback(() => {
        if (!width) return 4;
        if (width > 1500) return 4;
        if (width > 800) return 2;
        return 1;
    }, [width])
    const [state, setState] = React.useState({
        page: 0
    })
    const productsSlice = React.useMemo(() => getProductsSlice(), [width])
    const pileWidth = React.useMemo(() => {
        return `${100 / productsSlice}%`
    }, [productsSlice])
    const setPage = (page:number)=>setState({...state,page})
    const goPrevPage = (e: any) => {
        if (state.page === 0) return;
        setState({ ...state, page: state.page - 1 })
    }
    const goNextPage = (e: any) => {
        setState({ ...state, page: state.page + 1 })
    }
    return {
        state,
        page: state.page,
        productsSlice: Math.floor(productsSlice),
        getProductsSlice,
        width,
        height,
        pileWidth,
        goNextPage,
        goPrevPage,
        setPage
    }
}