import React from "react";
import {TutorialsColumn} from "../../Columns/TutorialsColumn";
import {useDashboardsNavigation} from "../../../store/hooks/useDashboardsNavigation";
import {useDashboardsStore} from "../../../store/hooks/useDashboardsStore";
import {TAssetLevels, TAssetTypes} from "../../../services/products";
export type TutorialsSectionProps = {};
export const TutorialsSection: React.FC<TutorialsSectionProps> = ({}) => {
  const {productId,areaSettings} = useDashboardsNavigation();
  const {groupedProductAssets} = useDashboardsStore();
  return (
    <TutorialsColumn
      productId={productId ?? ""}
      tutorials={
        groupedProductAssets[TAssetTypes.Tutorials]?.filter(
          (x) =>
            x.ProductId === productId &&
            x.Level === TAssetLevels.ProductPage
        ) ?? []
      }
      settings={areaSettings?.TUTORIALS}
    />
  );
};
