import React from "react";
import {
  DASHBOARD_IMAGE_KEY,
  TProduct,
  updateProduct,
} from "../../services/products";
import { EditPanel } from "../EditPanel/EditPanel";
import { DashboardTextInput } from "../DashboardTextInput/DashboardTextInput";
import { Button } from "@mui/material";
import { useDashboardsStore } from "../../store/hooks/useDashboardsStore";
type Props = {
  product: TProduct;
  closePanel: (e: any) => void;
};
const EditProductPanel: React.FC<Props> = ({ product, closePanel }) => {
  const successMsg = React.useMemo(
    () => `Ottimo! Prodotto ${product.Name} modificato con successo`,
    [product.Name]
  );
  const { products, contextDispatch } = useDashboardsStore();
  const [state, setState] = React.useState({
    action_result_msg: "",
    error: false,
  });
  const getProductFormData = React.useCallback((product:TProduct)=>({
    Description: product.Description,
    Metadata: {
      ...product.Metadata,
      [DASHBOARD_IMAGE_KEY]: product.Metadata[DASHBOARD_IMAGE_KEY] ?? null,
    },
    RowVersion: product.RowVersion,
  }),[])
  const [formState, setFormState] = React.useState<Partial<TProduct>>(getProductFormData(product));
  const [isLoading, setIsLoading] = React.useState(false);
  const handleSubmit = React.useCallback(
    (e: React.FormEvent) => {
      e.preventDefault();
      setIsLoading(true);
      const payload: TProduct = {
        ...product,
        ...formState,
      };
      updateProduct(payload)
        .then((res: TProduct) => {
          console.log(res);
          const _products = products.map((x) =>
            x.ProductId === res.ProductId ? res : x
          );
          contextDispatch({
            type: "SET_PRODUCTS",
            payload: _products,
          });
          setState({
            error: false,
            action_result_msg: successMsg,
          });
          setFormState(getProductFormData(res))
        })
        .catch((err) => {
          console.log(err);
          setState({
            error: true,
            action_result_msg: err.toString(),
          });
        })
        .finally(() => setIsLoading(false));
    },
    [product, formState, products, contextDispatch, successMsg]
  );
  return (
    <EditPanel title={`Prodotto: ${product.Name}`} closePanel={closePanel}>
      <form onSubmit={handleSubmit} className="w-[100%] h-[100%]">
        <DashboardTextInput
          allowUploadFile
          setValue={(value: any) =>
            setFormState({
              ...formState,
              Metadata: { ...formState.Metadata, [DASHBOARD_IMAGE_KEY]: value },
            })
          }
          disabled={isLoading}
          name="img"
          value={
            formState.Metadata ? formState.Metadata[DASHBOARD_IMAGE_KEY] : ""
          }
          onChange={(e) =>
            setFormState({
              ...formState,
              Metadata: {
                ...formState.Metadata,
                [DASHBOARD_IMAGE_KEY]: e.target.value,
              },
            })
          }
          label="Immagine:"
          labelCustomClassNames="text-white px-2 text-xxl"
          inputType="text"
        />
        <DashboardTextInput
          disabled={isLoading}
          name="name"
          value={formState.Description}
          onChange={(e) =>
            setFormState({ ...formState, Description: e.target.value })
          }
          label="Descrizione:"
          multiline
          rows={15}
          labelCustomClassNames="text-white px-2 text-xxl"
          inputType="text"
        />
        <div className="flex w-[100%]">
          <Button
            variant="contained"
            color="primary"
            type="submit"
            className="!self-end !ml-auto !w-[150px] !bg-intesa-green"
          >
            Salva
          </Button>
        </div>
        {state.action_result_msg && (
          <p
            className={`${
              state.error ? "text-red-500" : "text-white"
            } text-center w-[100%] text-xxl mt-4 font-bold`}
          >
            {state.action_result_msg}
          </p>
        )}
      </form>
    </EditPanel>
  );
};
export { EditProductPanel };
