import React from "react";
import './styles.css';
type Props = {
  position: "left" | "right" | undefined;
  children?:React.ReactNode;
  extraStyles?:React.CSSProperties
};
const Toolbar: React.FC<Props> = ({ position,children,extraStyles }) => {
    const classNames = React.useMemo(()=>{
        switch(position){
            case "left":return "left-toolbar"
            case "right":return "right-toolbar"
            default:return undefined;
        }
    },[position])
  return <div className={classNames} style={extraStyles}>{children}</div>;
};
export { Toolbar };
