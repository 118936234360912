import React from "react";
import {Page} from "../../components/Page/Page";
import {AssignmentsGrid} from "../../components/AssignmentsGrid/AssignmentsGrid";
import {TAssignment} from "../../types/assignments";
import {useHeaders} from "../../store/hooks/useHeaders";
import {PaginationEnum} from "../../store/pager";
import {ODataGridToolbar} from "../../components/ODataGrid/ODataGridToolbar/ODataGridToolbar";
export type THeader = {
  id: string;
  label: string;
  width: string | number;
  element: (a: TAssignment) => string | undefined;
};

export const AssignmentList: React.FC = () => {
  const {assignmentHeaders,setAssignmentHeaders} = useHeaders();
  console.log(assignmentHeaders)
  return (
    <Page>
      <ODataGridToolbar enumStoreKey={PaginationEnum.ASSIGNMENTS} headers={assignmentHeaders} setHeaders={setAssignmentHeaders}/>
      <AssignmentsGrid headers={assignmentHeaders} setHeaders={setAssignmentHeaders}/>
    </Page>
  );
};
