import { Alert, AlertTitle, IconButton, Typography } from '@mui/material'
import React from 'react'
import { topbarHeight } from './Topbar/Topbar'
import { globalContext } from '../../store/globalStore'
import { CgDetailsMore, CgUser } from "react-icons/cg";
import { IoIosArrowUp } from "react-icons/io";
import { useSidebars } from '../../store/hooks/useSidebars';
import { GraphExplorer } from '../GraphExplorer/GraphExplorer';
import { DisposableSidebar } from './Sidebar/DisposableSidebar/DisposableSidebar';
import { ISidebarOptions } from '../../types/panels';
import { navigationContext, NavigationStateKeysEnum } from '../../store/navigationStore';
import { DisposableSidebarIcon } from './Sidebar/DisposableSidebar/DisposableSidebarIcon/DisposableSidebarIcon';
import { FaUserCircle } from 'react-icons/fa';
import { defaultGraphExplorerSidebarOptions } from '../GraphExplorer/constants';
type Props = {
    children: React.ReactNode
}

export const Page: React.FC<Props> = ({ children }) => {
    const {
        isMobile,
        defaultBottombarHeight,
        sidebarIsOpen,
        bottombarIsOpen,
        state:sidebarsState
    } = useSidebars();
    const { state, dispatch } = React.useContext(globalContext);
    const [showTraceback, setShowTraceback] = React.useState(false);
    const { state: navState, dispatch: navDispatch } = React.useContext(navigationContext)
    const setSidebarOptions = (opt: ISidebarOptions) => navDispatch({ type: "SET_SIDEBAR_OPTIONS", options: opt })
    React.useEffect(() => {
        setSidebarOptions(defaultGraphExplorerSidebarOptions);
    }, [])
    return <div className="h-[100vh] flex flex-col overflow-y-auto overflow-x-hidden" style={{
        width:'100vw',
        paddingTop: topbarHeight,
        paddingLeft: sidebarIsOpen ? sidebarsState.sidebarWidth : isMobile ? 0 : 25,
        paddingRight:0,
        paddingBottom: bottombarIsOpen ? sidebarsState.bottombarHeight : 0,
        height:'100vh',
        transition:'all 0.25s ease'
    }}>
        {/* <Topbar>
            {title}
            <figure>
                <div
                    className="flex items-stretch cursor-pointer"
                    style={{ position: 'fixed', top: 5, right: 5 }}
                    onClick={() => window.open(process.env.REACT_APP_SBH_URL ?? '/')}
                >
                    {SBHubLogo}
                </div>
            </figure>

        </Topbar> */}
        {/* <Sidebar>
            <PagesNavigator />
        </Sidebar> */}
        {state.error && <Alert style={{zIndex:9999999999}} onClose={() => { dispatch({ type: "SET_ERROR", payload: undefined }) }} severity="error">
            <AlertTitle>{state.error.message}<IconButton onClick={() => setShowTraceback(!showTraceback)}>{showTraceback ? <IoIosArrowUp /> : <CgDetailsMore />}</IconButton></AlertTitle>
            {showTraceback && <Typography>{state.error.details}</Typography>}
        </Alert>}
        {children}
        
        <DisposableSidebar height={isMobile ? window.innerHeight - topbarHeight - defaultBottombarHeight : window.innerHeight - topbarHeight} />
        {/* <Bottombar>
            <PagesNavigator />
        </Bottombar> */}
    </div>
}