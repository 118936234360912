import { Grid, IconButton, Paper } from '@mui/material'
import React from 'react'
import { intesa_green } from '../../../themes/themes'
import { topbarHeight } from '../../../components/DashboardContainer/DashboardContainer'
import { IoAppsSharp, IoClose } from 'react-icons/io5'
import { isMobile } from 'react-device-detect'
import useWindowSize from '../../../utility/useWindowSize'
import { useUserContext } from '../../../store/hooks/useUserContext'
import { hasPermission } from '../../../store/hooks/useUserStore'
import { known_actions, know_urls, known_products, NeededClaims, TAreas } from '../../../types/userStore'
import { MdAssignmentTurnedIn } from 'react-icons/md'
import { assignments_known_urls } from '../../../apps/assignments/types/userStore'
import { useNavigate } from 'react-router-dom'
import { TiTicket } from 'react-icons/ti'
type PROPS = {}
export const AppsIcon: React.FC<PROPS> = () => {
    const { width } = useWindowSize();
    const iconBottom = topbarHeight + 10 + 50
    const iconPosition: Partial<React.CSSProperties> = {
        right: 10,
        bottom: iconBottom
    }
    const navigate = useNavigate();
    const paper_zIndex = 99999;
    const [isOpen, setIsOpen] = React.useState(false);
    const { userClaims } = useUserContext();
    const iconsStyle:React.CSSProperties = { height: 30, width: 30, objectFit: 'cover', color: 'black',fontSize:"1.5rem" }
    const apps: { area?: 'sbh' | TAreas, permissionNeeded: keyof NeededClaims, icon: React.ReactElement, name: string, onClick: React.MouseEventHandler<HTMLDivElement> }[] = [
        {
            permissionNeeded: known_actions.assignments,
            icon: <MdAssignmentTurnedIn  style={iconsStyle} />,
            name: "Assegnazioni",
            onClick: (e) => {
                navigate(assignments_known_urls.home)
            }
        },
        {
            permissionNeeded: known_products['iot-connected-buildings'].dashboard_url as keyof NeededClaims,
            area: known_products['iot-connected-buildings'].id as TAreas,
            // eslint-disable-next-line jsx-a11y/alt-text
            icon: <TiTicket style={iconsStyle}/>,
            name: "Ticket Connector",
            onClick: (e) => {
                window.open(process.env.REACT_APP_TICKET_CONNECTOR_URL ?? '')
            }
        }
    ]
    console.log(userClaims, apps.filter((x) => hasPermission(userClaims, x.permissionNeeded)))
    return <>
        <IconButton
            onClick={() => setIsOpen(!isOpen)}
            className="!fixed"
            style={{ ...iconPosition, zIndex: 99999, backgroundColor: intesa_green, color: 'white' }}>
            <IoAppsSharp />
        </IconButton>
        {isOpen &&
            <>
                <Paper
                    elevation={6}
                    className="!bg-white !text-black"
                    style={{
                        zIndex: paper_zIndex,
                        border: `2px solid ${intesa_green}`,
                        borderRadius: 10,
                        bottom: iconBottom,
                        padding: '10px 38px',
                        right: 60,
                        position: 'fixed',
                        maxWidth: isMobile ? 'calc(99vw - 60px)' : width && width > 2000 ? '50vw' : '70vw',
                        maxHeight: `calc(100vh - ${topbarHeight + 20 + 10}px)`,
                        minHeight: 250,
                        minWidth: isMobile ? 'calc(99vw - 60px)' : 800,
                        overflow: 'auto'
                    }}
                >
                    <Grid container spacing={1}>
                        {apps.filter((x) => hasPermission(userClaims, x.permissionNeeded, x.area ?? 'sbh')).map((x) => <Grid item xs={12} sm={12} md={6}>
                            <div onClick={x.onClick} className="flex justify-start items-center w-[100%] border cursor-pointer hover:!underline p-2 m-0">
                                {x.icon}
                                <p className="ml-2 text-2xl">{x.name}</p>
                            </div>
                        </Grid>)}
                    </Grid>
                    <IconButton
                        style={{
                            position: 'absolute',
                            right: 5,
                            top: 5,
                            width: 35,
                            fontSize: '1.25rem'
                        }}
                        onClick={() => setIsOpen(false)}>
                        <IoClose style={iconsStyle}/>
                    </IconButton>
                </Paper>
                <div style={{
                    bottom: iconBottom + 6,
                    position: 'fixed',
                    right: 45,
                    width: 0,
                    height: 0,
                    zIndex: paper_zIndex + 1,
                    borderLeft: "8px solid rgb(37,137,0)",
                    borderRight: "8px solid transparent",
                    borderTop: "8px solid transparent",
                    borderBottom: "8px solid transparent",
                }} />
            </>}
    </>;
}