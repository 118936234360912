import React from 'react'
import { Grid, } from '@mui/material'
import { ETicketEventDetail } from '../ETicketEventDetail/ETicketEventDetail'
import { EticketSourceTypeEnum } from '../../types/assignments'
import { AssignmentEventDetail } from '../AssignmentEventDetail/AssignmentEventDetail'
import { EventNotificationsHistory } from '../EventNotificationsHistory/EventNotificationsHistory'
import { useSidebars } from '../../store/hooks/useSidebars'
import { Spacer } from '../Spacer/Spacer'
import { ExtendedSuperMetricValueDetail } from '../SuperMetricValueDetail/ExtendedSuperMetricValueDetail'
type Props = {
    id: number
}
export const SuperMetricEventDetail: React.FC<Props> = ({ id }) => {
    const {
        sidebarIsOpen,
        state: sidebarsState,
        isMobile
    } = useSidebars();
    return <div className='flex flex-col justify-center items-center'>
        <Spacer direction='y' spacing={2} />
        <ExtendedSuperMetricValueDetail id={id} />
        <Spacer direction='y' spacing={2} />
        <div style={{marginLeft:-20, width: `calc(100vw - 40px - ${sidebarIsOpen ? sidebarsState.sidebarWidth : 0}px)`, }}>
            <Grid
                container spacing={2}>
                <AssignmentEventDetail sourceType={EticketSourceTypeEnum.SuperMetric} id={id} />
                <ETicketEventDetail sourceType={EticketSourceTypeEnum.SuperMetric} id={id} />
            </Grid>
        </div>
        <EventNotificationsHistory eventId={id} sourceType={EticketSourceTypeEnum.SuperMetric} />

    </div>
}