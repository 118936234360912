import moment from 'moment';
import 'moment-timezone';


export const formatTimestamp = (date: string | Date | undefined | null): string => {
 
  if (date && moment(date).isAfter(moment("1900-01-01"))) {
    return moment(date).clone().tz("Europe/Rome").locale('it').format('DD/MM/YY HH:mm');
  }
  else {
    return "";
  }
}

export const formatDate = (date: string | Date | undefined | null): string => {
 
  if (date && moment(date).isAfter(moment("1900-01-01"))) {
    return moment(date).clone().tz("Europe/Rome").locale('it').format('DD/MM/YY HH:mm');
  }
  else {
    return "";
  }
}

export const formatShortDate = (date: Date | undefined | null): string => {

  if (date && moment(date).isAfter(moment("1900-01-01"))) {
    return moment(date).clone().tz("Europe/Rome").locale('it').format('DD/MM/YYYY');
  }
  else {
    return "";
  }
}

export const addDays = (date: Date, days: number): string =>  {
  date.setDate(date.getDate() + days);
  return  moment(date).clone().tz("Europe/Rome").locale('it').toISOString();
}


export function formatDateDiff(n?:number|null){
  if(!n)return '';
  // return n.toString();
  //n seconds 
  let years,years_label;
  let months,months_label;
  let days,days_label;
  let hours,hours_label;
  let minutes,minutes_label;
  let _;  //temp variable
  const secondsInAMinute = 60;
  const secondsInAnHour = 60 * 60;
  const secondsInADay = secondsInAnHour*24;
  const secondsInAMonth = secondsInADay * 30;
  const secondsInAYear = secondsInAMonth * 12;
  const formatResult = (a:number,b:string,c:number,d:string) => c > 0 ? `${a} ${b} e ${c} ${d}` : `${a} ${b}`
  if(n > secondsInAYear){
    _ = n / secondsInAYear
    years = Math.floor(_);
    years_label = years !== 1 ? 'anni' : 'anno';
    months = ((_ % 1) * 12) % 1;
    months_label = months !== 1 ? 'mesi' : 'mese';
    return formatResult(years,years_label,months,months_label)
  }
  else if(n > secondsInAMonth){
    _ = n / secondsInAMonth
    months = Math.floor(_);
    months_label = months !== 1 ? 'mesi' : 'mese';
    days = Math.floor(((_ % 1) * 30));
    days_label = days !== 1 ? 'giorni' : 'giorno';
    return formatResult(months,months_label,days,days_label)
  }
  else if(n > secondsInADay){
    _ = n / secondsInADay
    days = Math.floor(_);
    days_label = days !== 1 ? 'giorni' : 'giorno';
    hours = Math.floor((_ % 1) * 24);
    hours_label = hours !== 1 ? 'ore' : 'ora';
    return formatResult(days,days_label,hours,hours_label)
  }
  else if(n > secondsInAnHour){
    _ = n / secondsInAnHour
    hours = Math.floor(_);
    hours_label = hours !== 1 ? 'ore' : 'ora';
    minutes = Math.floor((_ % 1) * 60);
    minutes_label = minutes !== 1 ? 'minuti' : 'minuto';
    return formatResult(hours,hours_label,minutes,minutes_label)
  }
  else{
    minutes = Math.floor(n / secondsInAMinute);
    minutes_label = minutes !== 1 ? 'minuti' : 'minuto';
    return `${minutes} ${minutes_label}`;
  }
}