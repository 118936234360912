import { Typography } from '@mui/material'
import React from 'react'
import { ISortableHeader } from '../../store/hooks/useMetricHeaders'
import { settingsContext } from '../../store/settingsStore'
import { FaArrowDown, FaArrowUp } from 'react-icons/fa'
type Props = {
    header:ISortableHeader
}
export const HeaderCell:React.FC<Props> = ({header}) => {
    const {state,dispatch} = React.useContext(settingsContext)
    const {metricSortings} = state;
    const handleRemoveSort = (e:any) => {
        e.preventDefault();
        dispatch({type:"SET_SORTINGS",payload:metricSortings.filter((s)=>s.key !== header.id)})
    }
    const headerSorting = React.useMemo(()=>metricSortings.find((h)=>h.key === header.id),[metricSortings,header])
    const sortingArrow = React.useMemo(()=>{
        if(!headerSorting)return null;
        return headerSorting.direction === "asc" ? <FaArrowUp/> : <FaArrowDown />
    },[headerSorting])
    return <div onContextMenu={handleRemoveSort} onClick={()=>header.onSort(header.id)} className={`flex justify-between items-center bg-slate-800 text-white border-x border-t h-[40px] ${header.clickable ? "cursor-pointer" : "cursor-default"}`} style={{width:header.width,margin:'0px 0px'}} id={header.id}>
        <Typography variant="body1" align="center" className="!text-white !text-xl !my-[5px] !h-[30px]">{header.label}</Typography>
        {sortingArrow}
    </div>
}