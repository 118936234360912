import axios from "axios"
import { IColumnFilter, IColumnSorting, ODataResponse } from "../types/odata"
import { buildOdataUrl } from "../utility/odata"
import { ETicket } from "../types/etickets"
export const TICKETS_CONTROLLER = "ETicket"
export const getSingleTicket:(id:string)=>Promise<ETicket> = async (id:string)=>{
  const url = `${process.env.REACT_APP_SERVER_URL}/ETicket/Single/${id}`
  return axios.get(url).then((res)=>res.data)
}
export const getTickets:()=>Promise<ODataResponse<ETicket[]>> = async () => {
  const sortings:IColumnSorting<ETicket>[] = [{
    key:"Id",
    direction:"desc",
    innerClass:""
  }]
  const url = buildOdataUrl(TICKETS_CONTROLLER,0,0,[],sortings)
  return axios.get(url).then((res)=>res.data)
} 
export const getPagedTickets:(skip:number,pageSize:number,sortings:IColumnSorting<ETicket>[],filters:IColumnFilter<ETicket>[])=>Promise<ODataResponse<ETicket[]>> = async (skip:number,pageSize:number,sortings:IColumnSorting<ETicket>[],filters:IColumnFilter<ETicket>[]) => {
  const url = buildOdataUrl(TICKETS_CONTROLLER,skip,pageSize,filters,sortings);
  return axios.get(url).then((res)=>{
      return res.data})
}

export const putForceTicketClosure = async(ticket:ETicket)=>{
  const url = `${process.env.REACT_APP_SERVER_URL}/${TICKETS_CONTROLLER}/ForceClosure`
  const payload = {id:ticket.Id}
  return axios.put(url,payload).then((res)=>res.data)

}

export const viewAssignment:(id:string|null)=>Promise<ETicket> = async (id:string|null) => {
    const url = `${process.env.REACT_APP_SERVER_URL}/${TICKETS_CONTROLLER}/Single/${id}`
    return axios.put(url).then((res)=>res.data)
}