import { FontIcon } from '@fluentui/react';
import React from 'react';
import { IconProps } from './iconsConstants';
import { DashboardSideIcon } from './DashboardSideIcon';
import { SpecialReportSvg } from './SpecialReportIcon';
import { ProductPageSection } from '../../../hooks/useProductDashboardDimensions';
export const ReportSvg: React.FC = () => <>
    <SpecialReportSvg/>
    <FontIcon iconName='Settings' style={{ position: 'absolute', right: 0, top: 0, width: 10, height: 10, fontSize: 10, fontWeight: 'bold' }} />
</>
const ReportIcon: React.FC<IconProps> = ({onlyIcon}) => {
    const id = ProductPageSection.REPORTS;
    const tooltip = {
        id:`${id}-dashboard-icon`,
        text:"Report tecnici"
    }
    return <DashboardSideIcon
        onlyIcon={onlyIcon}
        id={id}
        tooltip={tooltip}
        icon={ReportSvg({})}
    />
}
export { ReportIcon }