import React from "react";
import "./styles.css";
import {VerticalDashboardSection} from "../VerticalDashboardSection/VerticalDashboardSection";
import {TProductAsset} from "../../services/products";
import {ScorecardPile} from "../Pile/ScorecardPile/ScorecardPile";
import {useDashboardsStore} from "../../store/hooks/useDashboardsStore";
import {IFrontendSettingsGet} from "../../services/frontendSettings";
import { TPileSize } from "../../types/general";
import { isMobile } from "react-device-detect";
const ScorecardsColumn: React.FC<{
  productId: string;
  scorecards: TProductAsset[];
  settings?: IFrontendSettingsGet;
}> = ({productId, scorecards, settings}) => {
  const {search} = useDashboardsStore();
  const filterScorecards = React.useCallback(
    (r: TProductAsset) => {
      if (!search.SCORECARDS) return true;
      let _ = search.SCORECARDS.toLowerCase();
      return r.Name.toLowerCase().includes(_);
    },
    [search.SCORECARDS]
  );
  const pileSize:TPileSize = React.useMemo(()=>{
    if(isMobile){
      return "x1";
    }
    if(!settings?.SectionColumns){
      return "x2"
    }
    if(settings.SectionColumns < 4){
      return "x1"
    }
    return "x2"
  },[settings?.SectionColumns])
  return (
    <VerticalDashboardSection
      cotainerStyles={{
        paddingBottom: 100,
      }}
      title=""
      settings={settings}
      id="SBMetrics"
    >
      {scorecards.filter(filterScorecards).map((sc) => (
        <ScorecardPile
          productId={productId}
          key={sc.AssetBag.ReportId}
          asset={sc}
          scorecardSize={pileSize}
        />
      ))}
    </VerticalDashboardSection>
  );
};
export {ScorecardsColumn};
