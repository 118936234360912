import React from "react";
import { IUser, IUserRole } from "../../services/user";
import { EditPanel } from "../EditPanel/EditPanel";
import { UserProductsToggle } from "../UserProductsToggle/UserProductsToggle";
import { formatDate } from "../../utility/formatDate";
import { TProduct } from "../../services/products";
type Props = {
  isOpen: boolean;
  onClose: (e: any) => void;
  user: IUser;
  roles: IUserRole[];
  currentValues?: { [key: string]: { RuoloId: string; enable: boolean } };
  canEdit?:boolean
  handleChange?:(u: IUser, a: TProduct, ruoloId?: string | undefined, enable?: boolean | undefined) => Promise<void>
};
const UserPanel: React.FC<Props> = ({
  isOpen,
  onClose,
  user,
  currentValues,
  roles,
  canEdit,
  handleChange
}) => {
  if (!isOpen) {
    return null;
  }
  return (
    <EditPanel title={`Dettaglio utente: ${user.User}`} closePanel={onClose}>
      <div className="mt-2 border-b border-gray-500 w-[100%]" />
      <p className="text-2xl">Autorizzazione Prodotti:</p>
      <UserProductsToggle
        currentValues={currentValues}
        editable={canEdit}
        user={user}
        roles={roles}
        handleChange={handleChange}
      />
      <div className="mt-auto" />
      <div className="flex w-[100%] justify-between items-center px-2 mb-4">
      <div>
          <p className="text-lg w-[150px] mr-1">Ultimo accesso:</p>
          <p className="text-xl">{formatDate(user.LastTimeLogedIn)}</p>
        </div>
        <div>
          <p className="text-lg w-[150px] mr-1">Data registrazione:</p>
          <p className="text-xl">{formatDate(user.CreationDate)}</p>
        </div>
      </div>
    </EditPanel>
  );
};
export { UserPanel };
