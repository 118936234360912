import React from 'react';
import { ChartsProps } from './types/charts';
import { Bar } from 'react-chartjs-2';
import { useCharts } from './hooks/useCharts';
const MD_Charts:React.FC<ChartsProps> = ({charts}) => {
    const containerStyles = React.useMemo<React.CSSProperties>(() => ({
        paddingBottom: 100,
        justifyContent: 'center',
        alignItems: "stretch",
        flexDirection: "column",
        zIndex: 1,
        minHeight: 300,
    }), [])
    const {
        getChartOptions,
        mapChart
    } = useCharts({ charts });
    return <div style={containerStyles}>{charts.map(mapChart).map((data, i) => (
        <div className="grow" style={{ maxWidth: '100%', margin: 10 }}>
            <Bar
                width={700}
                height={400}
                style={{
                    minHeight: 400,
                    maxHeight: 450,
                    borderRadius: 8,
                    padding: 5,
                    border: "1px solid #222A35",
                    backgroundColor: "rgb(71,85,105)",
                    color: "white",
                    margin: 5,
                }}
                data={data}
                options={getChartOptions(i)}
            />
        </div>
    ))}</div>
}
export {MD_Charts}