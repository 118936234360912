import { FormControl, InputLabel, MenuItem, Select, SelectChangeEvent } from '@mui/material';
import React from 'react';
import { areaName } from '../EditAssetsPage';
import { TAssetBag, TAssetTypes, TProductAsset } from '../../../services/products';
import { known_products } from '../../../types/userStore';
import { isJsonString } from '../../../utility/json';
import { StyledTextField } from '../TextFields/StyledTextField';
type Props = {
    asset: TProductAsset
    setAsset: (x: TProductAsset) => void
}
export const assetTypeMap: { [key in TAssetTypes]?: string } = {
    [TAssetTypes.SpecialReport]: "Business Report",
    [TAssetTypes.Report]: "Report Tecnico",
    [TAssetTypes.RangeKPI]: "Speedometro",
    [TAssetTypes.CardKPI]: "Tabella",
    [TAssetTypes.Doughnut]: "Grafico a ciambella",
    [TAssetTypes.Histogram]: "Grafico a istogramma",
    [TAssetTypes.FileTreeView]: "Files",
}
export const exchangeableAssetTypes: { [key in TAssetTypes]?: TAssetTypes[] } = {
    [TAssetTypes.Report]: [TAssetTypes.Report, TAssetTypes.ScoreCard, TAssetTypes.SpecialReport],
    [TAssetTypes.ScoreCard]: [TAssetTypes.Report, TAssetTypes.ScoreCard, TAssetTypes.SpecialReport],
    [TAssetTypes.SpecialReport]: [TAssetTypes.Report, TAssetTypes.ScoreCard, TAssetTypes.SpecialReport],
};
const EditAssetForm: React.FC<Props> = ({ asset, setAsset }) => {
    const nonJsonFormat = (s: string) => {
        return s.replaceAll('\n','')
    }
    return <div className="flex flex-col w-[100%]">
        <FormControl fullWidth className="">
            <InputLabel className='!text-white' id="product-select-label">
                Prodotto
            </InputLabel>
            <Select
                style={{ zIndex: 1 }}
                inputProps={{ zIndex: 1 }}
                disabled
                variant='outlined'
                className="!text-white !my-2 bg-slate-400 !px-2"
                fullWidth
                label="Prodotto"
                labelId='product-select-label'
                id="product-select"
                value={areaName(asset)}
                onChange={(e: SelectChangeEvent) => setAsset({ ...asset, ProductId: e.target.value.toString() as TAssetTypes })}
            >
                {Object.entries(known_products).map((at) => <MenuItem value={at[1].id}>{at[0]}</MenuItem>)}
            </Select>
        </FormControl>
        <FormControl fullWidth className="">
            <InputLabel className='!text-white' id="type-select-label">
                Tipo
            </InputLabel>
            <Select
                label="Tipo"
                disabled={!exchangeableAssetTypes[asset.AssetType]?.includes(asset.AssetType)}
                variant='outlined'
                className={`!my-2 !text-white !px-2 ${!exchangeableAssetTypes[asset.AssetType]?.includes(asset.AssetType) ? 'bg-slate-400' : 'bg-slate-700'}`}
                fullWidth
                labelId='type-select-label'
                id="type-select"
                value={asset.AssetType.toString()}
                onChange={(e: SelectChangeEvent) => setAsset({ ...asset, AssetType: e.target.value.toString() as TAssetTypes })}
            >
                {exchangeableAssetTypes[asset.AssetType]
                    ?
                    exchangeableAssetTypes[asset.AssetType]?.map((at) => <MenuItem value={at}>{assetTypeMap[at] ?? at.toString()}</MenuItem>)
                    :
                    Object.values(TAssetTypes).map((at) => <MenuItem value={at}>{assetTypeMap[at] ?? at.toString()}</MenuItem>)}
            </Select>
        </FormControl>
        <StyledTextField
            asset={asset}
            label="Nome"
            placeholder='Nome...'
            required
            inputId="assetname"
            value={asset.Name}
            setValue={(value) => setAsset({ ...asset, Name: value })}
            onChange={(e: React.ChangeEvent<HTMLInputElement>) => setAsset({ ...asset, Name: e.target.value })}
        />
        <StyledTextField
            asset={asset}
            label="Descrizione"
            placeholder='Descrizione...'
            multiline
            required
            inputId="assetDescription"
            value={asset.Description}
            setValue={(value) => setAsset({ ...asset, Description: value })}
            onChange={(e: React.ChangeEvent<HTMLInputElement>) => setAsset({ ...asset, Description: e.target.value })}
        />
        {asset.AssetType.toString() === TAssetTypes.RangeKPI.toString() &&
            <StyledTextField
                asset={asset}
                label="Help"
                placeholder='Help...'
                inputId="assetHelp"
                value={asset.HelpDescription}
                setValue={(value) => setAsset({ ...asset, HelpDescription: value })}
                onChange={(e: React.ChangeEvent<HTMLInputElement>) => setAsset({ ...asset, HelpDescription: e.target.value })}
            />}
        <StyledTextField
            asset={asset}
            setValue={(value: any) => setAsset({ ...asset, AssetBag: isJsonString(value) ? JSON.parse(value) as TAssetBag : value as TAssetBag })}
            inputId="assetbag"
            placeholder='JSON Asset bag...'
            label="JSON Asset bag"
            required
            setJsonValue={(key: string, value: any) => setAsset({ ...asset, AssetBag: { ...asset.AssetBag, [key]: value } })}
            isJson
            multiline
            value={JSON.stringify(asset?.AssetBag, undefined, 2)}
            onChange={(e: React.ChangeEvent<HTMLInputElement>) => setAsset({ ...asset, AssetBag: isJsonString(e.target.value) ? JSON.parse(e.target.value) : nonJsonFormat(e.target.value) })}

        />

    </div>
}
export { EditAssetForm }