import React from 'react';

import './styles.css';
import { TProductAsset } from '../../services/products';
import { VerticalDashboardSection } from '../VerticalDashboardSection/VerticalDashboardSection';
import { Library } from '../Library/Library';
import { IFrontendSettingsGet } from '../../services/frontendSettings';

const FilesColumn: React.FC<{settings?:IFrontendSettingsGet,files:TProductAsset[]}> = ({settings,files}) => {
    return <VerticalDashboardSection title="" settings={settings} cotainerStyles={{ padding: '20px 1% 0px 1%' }} id='Library' >
        {files.length > 0 && <Library asset={files[0]} />}
    </VerticalDashboardSection>
}
export { FilesColumn }