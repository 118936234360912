import React from "react";
import { AuthError, InteractionStatus } from "@azure/msal-browser";
import { useIsAuthenticated, useMsal } from "@azure/msal-react";
import { useEffect } from "react";
import { apiLoginRequest } from "../../authConfig";
import { appInsights } from "../../components/application-insights/AppInsights";
import { DashboardContainer } from "../../components/DashboardContainer/DashboardContainer";
import { SeverityLevel } from "@microsoft/applicationinsights-web";
import { Button } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { know_urls } from "../../types/userStore";

export const UnauthenticatedPage = () => {
  const autoRedirect = true;
  appInsights.trackPageView({ name: "UnauthenticatedPage" });
  const [authError, setAuthError] = React.useState(false);
  const { instance, accounts, inProgress } = useMsal();
  const authenticated = useIsAuthenticated();
  const navigate = useNavigate();
  const handleTryLogin = React.useCallback(() => {
    console.log("0");
    console.log(accounts);
    if (authError) {
      setAuthError(false);
      return;
    }
    if (!authenticated && inProgress === InteractionStatus.None) {
      console.log("1");
      const tryLogin = async () => {
        instance
          .loginRedirect(apiLoginRequest)
          .then((res) => {
            console.log("2");
            console.log(res);
          })
          .catch((err: AuthError) => {
            console.log("3");
            const trace = JSON.stringify(err);
            console.log(trace);
            appInsights.trackTrace({
              message: trace,
              severityLevel: SeverityLevel.Information,
            });
            console.log(err);
            navigate(know_urls.registration_request_url);
          })
          .finally(() => {
            console.log("4");
            console.log("FINALLY AUTH");
          });
      };
      const handleResponse = (res: any) => {
        console.log("11");
        console.log(res);
      };
      instance
        .handleRedirectPromise()
        .then(handleResponse)
        .catch((err: AuthError) => {
          console.log("3");
          const trace = JSON.stringify(err);
          console.log(trace);
          appInsights.trackTrace({
            message: trace,
            severityLevel: SeverityLevel.Information,
          });
          console.log(err);
          navigate(know_urls.registration_request_url);
          setAuthError(true);
        });
      tryLogin();
    }
  }, [accounts, authError, authenticated, inProgress, instance, navigate]);
  useEffect(() => {
    if (autoRedirect) {
      handleTryLogin();
    }
  }, [authenticated, autoRedirect, handleTryLogin]);

  return (
    <DashboardContainer
      showAllReportIcon
      showReset
      showUser
      showBorder
      containerStyles={{ justifyContent: "start", overflowY: "auto" }}
    >
      <p className="mt-10 text-4xl text-center w-[100%]">
        You are not signed in! Please sign in.
      </p>
      {!autoRedirect && (
        <Button
          className="!mt-4"
          variant="contained"
          color="primary"
          onClick={() => handleTryLogin()}
        >
          Login
        </Button>
      )}
    </DashboardContainer>
  );
};
