import React from 'react';
import './styles.css'
import ReactSpeedometer, { CustomSegmentLabel, Transition } from "react-d3-speedometer"
import { KPI } from '../KPI/KPI';
import useWindowSize from '../../utility/useWindowSize';
import { useUserContext } from '../../store/hooks/useUserContext';
import { hasPermission } from '../../store/hooks/useUserStore';
import { known_actions, sbh_area } from '../../types/userStore';
import { highlightClassNames } from '../../themes/themes';
export type KpiType = 'bar' | 'gauge'

type Props = {
    id: string,
    value: number|string,
    percent: number|string,
    formatValue: (x: any) => string,
    formatSegment:(x:any)=>string,
    title: string,
    footerText?: string,
    footerText2?:string
    limits: {
        min: number,
        max: number
    }
    customSegmentStops?: number[]
    customSegmentLabels?: CustomSegmentLabel[]
    segmentColors?: string[]
    kpiType: KpiType
    containerStyles?:React.CSSProperties
    customWidth?: number
    customHeight?: number
    hidden?: boolean
    isDefault:boolean
}
const DashboardKPI: React.FC<Props> = ({ isDefault, customHeight, hidden, containerStyles, customWidth, kpiType, id, percent, value, title, footerText, formatValue,formatSegment, customSegmentLabels, customSegmentStops, segmentColors, limits, footerText2 }) => {
    const {width} = useWindowSize();
    const {userClaims} = useUserContext();
    const hasDefaultPermission = hasPermission(userClaims,known_actions.default_values,sbh_area);
    if (hidden) return <div style={{ ...containerStyles }} />
    return kpiType === 'bar' ?
        <div style={{ ...containerStyles }}>
            <KPI
                id={id}
                title={title}
                min={limits.min}
                max={limits.max}
                value={parseFloat(value.toString())}
                percent={parseFloat(value.toString())}
                formatValue={formatValue}
            />
        </div>
        : <div style={{ marginBottom: '1px',paddingBottom:2, display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center',...containerStyles }}>
            <p className="w-[100%] text-xl font-bold text-center" style={{position:'absolute',top:-25,left:0}}>{title}</p>
            <ReactSpeedometer
                maxSegmentLabels={customSegmentLabels ? customSegmentLabels.length : undefined}
                segments={customSegmentLabels ? customSegmentLabels.length : undefined}
                width={customWidth}
                paddingVertical={20}
                height={customHeight ?? undefined}
                paddingHorizontal={10}
                ringWidth={5}
                needleHeightRatio={0.8}
                labelFontSize={width && width > 2000 ? '1.15rem' : '0.8rem'}
                value={parseFloat(value.toString())}
                maxValue={limits.max}
                minValue={limits.min}
                segmentValueFormatter={(n:string)=>{
                        return parseFloat(n) ? parseFloat(n).toLocaleString() : n
                }}
                segmentColors={segmentColors}
                customSegmentStops={customSegmentStops}
                customSegmentLabels={customSegmentLabels}
                needleColor="rgb(255,255,255)"
                needleTransitionDuration={6000}
                needleTransition={Transition.easeElastic}
                currentValueText={formatValue(value)}
                valueTextFontSize={width && width > 2000 ? '1.5rem' : '1.25rem'}
                textColor="rgb(255,255,255)"
            />
            {footerText && <p className={`title text-center !px-2 ${hasDefaultPermission && isDefault ? highlightClassNames : 'text-white'}`}>{footerText}{hasDefaultPermission && isDefault ? '*' : ''}</p>}
            {footerText2 && footerText2 !== '()' && <p className="title text-white text-center">{footerText2}</p>}
        </div>
}
export { DashboardKPI }