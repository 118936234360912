import { ISidebarOptions } from "../../types/panels";
import { topbarHeight } from "../Page/Topbar/Topbar";
import { GraphExplorer } from "./GraphExplorer";
export const defaultGraphExplorerSidebarOptions: ISidebarOptions = {
    isOpen: false,
    isModal: false,
    isClosable: true,
    width: "l",
    position: "right",
    transitionMovementType: "horizontal",
    transitionResizeType: "width",
    childrens: <GraphExplorer />,
    offset: {
        x: 0,
        y: topbarHeight,
    },
}