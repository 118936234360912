import React from "react";
import { SetActionType, useSetActions } from "./useSetActions";
import { NavigationStateKeysEnum } from "../navigationStore";

export type StateEnum = (typeof NavigationStateKeysEnum);
export type TState = {[key in keyof StateEnum]:any}
export interface UseContextProps {
    actionType:SetActionType,
    keys_enum:StateEnum,
    context:React.Context<any>,
}

export const useTypedContext = ({actionType,context,keys_enum} : UseContextProps) =>{
    const { state, dispatch } = React.useContext(context);
    const {
        setActions
    } = useSetActions(actionType,state,dispatch);
    return {
        state,dispatch,setActions
    }
}