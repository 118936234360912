/* eslint-disable jsx-a11y/img-redundant-alt */
import React from "react";
import { Pile } from "../../Pile/Pile";
type Props = {
  productId: string;
};
const TCElement: React.FC<Props> = ({ productId }) => {
  const getImg = React.useCallback(
    (imgName: string, isReport?: boolean, imgRows?: number) => {
      const height = imgRows ? `${imgRows * 10}%` : "100%";
      try {
        // const fileName = isReport ? '../../../svg/reports/' + imgName : '../../../svg/' + imgName
        if (isReport) {
          return (
            <img
              style={{
                height,
                objectFit: "cover",
                borderRadius: 4,
                margin: "4px 2px 0px 2px",
              }}
              src={imgName ? require("../../../images/reports/" + imgName) : ""}
              alt="Image Missing"
            />
          );
        } else {
          return (
            <img
              style={{
                height,
                objectFit: "cover",
                borderRadius: "8px 8px 0px 0px",
                margin: "0px 0px 0px 0px",
              }}
              src={
                imgName ? require("../../../images/services/" + imgName) : ""
              }
              alt="Image Missing"
            />
          );
        }
      } catch (error) {
        console.log(error);
        return (
          <img
            src=""
            style={{ height, objectFit: "cover" }}
            alt={`Image for ${imgName} Missing`}
          />
        );
      }
    },
    []
  );
  return <Pile
      id={"TC"}
      productId={productId}
      key={"TC"}
      customColor
      titleCustomBgColor='rgb(22 101 52)'
      showHelp
      tooltip={{ id: 'TC', text: "Strumento per la visualizzazione dei Fault provenienti dagli edifici monitorati, che permette anche la visualizzazione e la gestione di Ticket che possono essere associati a questi Fault." }}
      className="relative shadow-xl m-2 cursor-pointer rounded-lg bg-green-800 hover:!bg-green-700 hover:!text-white"
      img={getImg("ticketconnector/ScreenTC.png")}
      titleSize='xxl'
      size="x1"
      openInNewTab
      targetUrl={process.env.REACT_APP_TICKET_CONNECTOR_URL}
      title={"Ticket Connector"}
  />
};
export { TCElement };
