import axios from "axios";
import React from "react";
import { FontIcon, IconButton } from "@fluentui/react";
import { TProductAsset } from "../../services/products";
import { useDashboardsStore } from "../../store/hooks/useDashboardsStore";
import { useUserContext } from "../../store/hooks/useUserContext";
import { FlexRow } from "../Flex/FlexRow";
import { MiniLoadingSpinner } from "../spinner/MiniLoadingSpinner/MiniLoadingSpinner";
import { FileExplorer, TFile } from "../FileExplorer/FileExplorer";
import { useGraphAuthentication } from "../../hooks/useGraphAuthentication";
import { TooltipContainer } from "../TooltipContainer/TooltipContainer";
const Library: React.FC<{ asset: TProductAsset }> = ({ asset }) => {
  const { executiveReports, contextDispatch } = useDashboardsStore();
  const product_executive_reports = React.useMemo(
    () =>
      asset && asset.ProductId && executiveReports[asset.ProductId]
        ? executiveReports[asset.ProductId]
        : [],
    [executiveReports, asset]
  );
  const [isLoading, setIsLoading] = React.useState(false);
  const { setGraphAccessToken, errors, setErrors } = useUserContext();

  const loadItems = async (token: string) => {
    const url = `${
      process.env.REACT_APP_SERVER_URL
    }${asset.AssetBag.PartialUrl}`;
    axios
      .post(url, {GraphApiToken:token,...asset.AssetBag})
      .then((res) => {
        if (errors.length > 0) {
          setErrors([]);
        }
        contextDispatch({
          type: "SET_EXEC_REPORTS",
          payload: { [asset.ProductId]: res.data },
        });
      })
      .catch((err) => {
        if (err.response.status === 401) {
          setGraphAccessToken("");
          setErrors([...errors, "Non autorizzato"]);
        }
      })
      .finally(() => setIsLoading(false));
  };
  const { getAccessToken } = useGraphAuthentication({
    setIsLoading,
    graphTokenSelector: (userInfo) => userInfo.graphAccessToken,
    loadItems,
    loadConditions: product_executive_reports.length === 0,
  });
  const errors_set = React.useMemo(
    () => (errors && Array.isArray(errors) ? Array.from(new Set(errors)) : []),
    [errors]
  );
  if (errors_set.length > 0) {
    return (
      <FlexRow>
        <p
          style={{ whiteSpace: "pre-wrap" }}
          className="text-white text-xl mx-4"
        >
          {isLoading
            ? "Caricamento..."
            : errors_set.map((s, i) => `${i + 1}. ${s}`).join("\n")}
        </p>
        <TooltipContainer tooltip={{text:"Get Power BI Token", id:"POWER_BI_TOKEN"}} customContentStyles={{content:{fontSize:20}}}>
          <IconButton
            disabled={isLoading}
            className="bg-white rounded-lg px-2 py-1 hover:bg-white text-slate-800"
            onClick={() => {
              setIsLoading(true);
              getAccessToken(true, "graph").finally(() => setIsLoading(false));
            }}
          >
            <FontIcon
              iconName="StatusCircleSync"
              style={{ fontSize: "2rem" }}
            />
          </IconButton>
        </TooltipContainer>
        {isLoading && (
          <MiniLoadingSpinner
            style={{ color: "white", marginLeft: 20, fontSize: "0.75rem" }}
          />
        )}
      </FlexRow>
    );
  }

  return isLoading ? (
    <MiniLoadingSpinner style={{ color: "white" }} />
  ) : (
    <FileExplorer files={product_executive_reports as TFile[]} />
  );
};
export { Library };
