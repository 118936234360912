import React from 'react'
import { Typography } from '@mui/material'
type Props = {
    width:string|number
    element?:React.ReactNode
    value?:any
    checked:boolean
}
export const TableCell:React.FC<Props> = ({width,element,checked,value}) => {
    return <div className={`${checked?"bg-slate-400 !text-black":"bg-white !text-black"} !min-h-[40px] flex items-center justify-center`} style={{width:width,margin:'0px 1px'}} >
        {value && <Typography style={{overflowWrap:'anywhere',lineHeight:4}} variant="body1" align="center" className="!text-base">{value}</Typography> }
        {element && element}
    </div>
}