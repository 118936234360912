import React from "react";
import { useLocation } from "react-router-dom";
import { useQuery } from "./useQuery";
import { know_urls, known_products, product_urls } from "../types/userStore";
import { stringCapitalize } from "../utility/strings";
import { QUERY_PAGE_KEY, QUERY_NAME_KEY, QUERY_TYPE_KEY } from "./useQueryValues";
import { QUERY_EMBED_ID_KEY, QUERY_EMBED_TYPE_KEY, QUERY_EMBED_WORKSPACE_ID_KEY } from "../components/powerBi/PowerBIUserEmbed";
import { appInsights } from "../components/application-insights/AppInsights";
import { useIsAuthenticated } from "@azure/msal-react";

function useTitle() {
  const setPortalTitle = (s: string) => { document.title = s; };
  const query = useQuery();
  const isAuth = useIsAuthenticated();
  const location = useLocation();
  const area = React.useMemo(() => {
    let url = document.URL;
    return url.split("/")[3];
  }, []);
  const queryId = React.useMemo(() => query.get(QUERY_EMBED_ID_KEY), [query])
  const queryWSId = React.useMemo(() => query.get(QUERY_EMBED_WORKSPACE_ID_KEY), [query])
  const queryPage = React.useMemo(() => query.get(QUERY_PAGE_KEY), [query])
  const queryName = React.useMemo(() => query.get(QUERY_NAME_KEY), [query])
  const queryType = React.useMemo(() => query.get(QUERY_TYPE_KEY), [query])
  let lastUsedPayload: any = null;
  React.useEffect(() => {
    let area_clear = area ?? null;
    if (area_clear) {
      const i = area_clear.indexOf("?");
      if (i > 0) {
        area_clear = area_clear.slice(0, i);
      }
    }
    let newTitle = "ISP - Smart Building Hub"

    if (area_clear === know_urls.report.slice(1)) {
      const title = `${queryType}-${queryName}`;
      if (queryName && queryType) {
        newTitle = stringCapitalize(title);
      } else {
        newTitle = `ISP - Iot Connected Buildings - Reports`;
      }
    }
    else if (area_clear === know_urls.user_settings.slice(1)) {
      newTitle = `ISP - Iot Connected Buildings - Configurazione utenti`;
    }
    else if (area_clear === know_urls.assignments_settings.slice(1)) {
      newTitle = `ISP - Iot Connected Buildings - Configurazione segnalazioni`;
    }
    else {
      const dashboards = Object.values(known_products).map((x) =>
        x.dashboard_url.slice(1)
      );
      if (area_clear && dashboards.includes(area_clear)) {
        newTitle = `ISP - ${stringCapitalize(area_clear)}`;
      }
    }
    setPortalTitle(newTitle);
    const formatQuery = () => {
      if (!query) {
        return "";
      }
      if (queryPage) {
        return `-page:${queryPage}`
      }
      // else if(queryId && queryWSId && queryType){
      //   return `-Id:${queryId},WSId:${queryWSId},type:${queryType}`
      // }
      return "";
    };
    const appInsightName = `${newTitle}${formatQuery()}`;
    console.log(newTitle, appInsightName)
    const appInsightPayload = {
      name: appInsightName,
      uri: location.pathname,
      isLoggedIn: isAuth,
      properties: {},
    }
    if (lastUsedPayload !== appInsightPayload) {
      appInsights.trackPageView(appInsightPayload);
    }
    else{
      console.log("Avoiding duplicated appInsightPayload")
    }
    lastUsedPayload = appInsightPayload;
  }, [location.pathname, queryPage, queryId, queryName, queryType, queryWSId, isAuth]);
}

export { useTitle };
