import { ComboBox, IComboBoxOption, mergeStyleSets } from '@fluentui/react';
import React from 'react';
import { FlexRow } from '../Flex/FlexRow';
import { FlexCol } from '../Flex/FlexCol';
type Props = {
    options: IComboBoxOption[]
    selectedKey?: null | string
    onChange: (e: any, option: any) => void
    disabled?: boolean
    label: string
    showSelectedLabel?: boolean
    handleTextChange?:(e:any)=>void
}
const DashboardComboBox: React.FC<Props> = ({ disabled, label, options, selectedKey, onChange }) => {
    const optionStyles = mergeStyleSets({
        root: {
            borderRadius: 4,
            padding: '0px 20px',
            width: '100%',
            flexGrow: 1,
            color: 'white',
            '&:hover': {
                color: 'black'
            }
        },
        optionText: {
        }
    })
    const mapOption = (option: IComboBoxOption) => ({
        ...option, styles: optionStyles
    })
    const styles = mergeStyleSets({
        comboBoxInput: {
            color: 'white',
            '&:hover': {
                color: 'white',
            },
            '&:focus': {
                color: 'white',
            },
        },
        root: {
            backgroundColor: 'rgb(100,116,139)',
            color: 'white',
            '&:hover': {
                backgroundColor: 'rgb(100,116,139)',
                color: 'white',
            }
        },
        container: {
            backgroundColor: 'rgb(100,116,139)',
            flexGrow: 1,
            width: '100%',
        },
        input: {
            backgroundColor: 'rgb(100,116,139)',
            borderRadius: '4px',
            color: 'white',
            fontSize: '1.5rem'
        },
        optionsContainer: {
            width: '100%',
            flexGrow: 1,
            backgroundColor: 'rgb(100,116,139)'
        },
        optionsContainerWrapper: {
            flexGrow: 1,
            width: '100%'
        },
    })
    return <FlexRow
        className={`my-4 min-h-[60px] w-[100%]`}
        alignItems='center'
    >
        <p className="text-xl mr-2 w-[25%] min-w-[100px]">{label}</p>
        <FlexCol className='grow w-[75%]'>
            <ComboBox
                className="combo-box"
                autoComplete='on'
                disabled={disabled}
                styles={styles}
                options={options.map(mapOption)}
                selectedKey={selectedKey}
                onChange={onChange}
            />
        </FlexCol>
    </FlexRow>
}
export { DashboardComboBox }