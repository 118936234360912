import { FontIcon } from '@fluentui/react';
import React from 'react';
import useWindowSize from '../../utility/useWindowSize';
type Props = {
    onClick:()=>void,
    text:string,
    iconName:string
}
const DashboardConfigurationIcon: React.FC<Props> = ({onClick,text,iconName}) => {
    const {width} = useWindowSize();
    const fontSize = React.useMemo(()=>{
        if(!width)return '1.75rem';
        if(width > 1300) return '1.5rem'
        return '1.5rem'
    },[width])
    return <div className="h-[100%] self-end ml-auto cursor-pointer flex items-end mr-10" style={{}} onClick={onClick}>
        <FontIcon iconName={iconName} className="pile-icon self-end my-auto" style={{fontSize}}/>
        {width && width > 1300 && <p className="mx-2 my-auto" style={{fontSize}}>{text}</p>}
    </div>
}
export { DashboardConfigurationIcon }