import { DefaultButton } from '@fluentui/react';
import React from 'react';
type Props = {
    disabled?:boolean
    style?:React.CSSProperties
    className?:string
    onClick:(e:any)=>void
    text:string
}
const DashboardButton: React.FC<Props> = ({text,disabled,style,onClick,className}) => {
    const activeButtonClasses = React.useMemo(() => "text-xxl mt-4 ml-auto cursor-pointer py-4 px-4 bg-slate-500 hover:!bg-intesa-green text-white hover:!text-white", [])
    const disabledButtonClasses = React.useMemo(() => "text-xxl mt-4 ml-auto cursor-default p-2 px-4 bg-gray-600 text-gray-500", [])
    return <DefaultButton
        className={disabled ? disabledButtonClasses : className ?? activeButtonClasses}
        text={text}
        style={{...style}}
        disabled={disabled}
        onClick={onClick}
    />
}
export { DashboardButton }