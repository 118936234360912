import React from "react";
import { DashboardHeaderText } from "../../DashboardHeader/DashboardHeaderText";
import { HeaderProps } from "./types";
import { ProductPageSection } from "../../../hooks/useProductDashboardDimensions";
import { AlertsIcon } from "../Icons/AlertsIcon";
export type AlertsHeaderProps = {};
export const AlertsHeader: React.FC<HeaderProps> = ({settings}) => {
  
    return (
      <DashboardHeaderText
        id={ProductPageSection.ALERTS}
        icon={<AlertsIcon onlyIcon />}
        settings={settings}
        editable={false}
        text="Segnalazioni"
      />
    );
}