import React from 'react'
import { assignments_known_urls } from '../../../types/userStore'
import { useLocation, useNavigate } from 'react-router-dom'
import { SidebarIcon } from './SidebarIcon/SidebarIcon'
import { TfiHome, TfiLayoutAccordionList } from "react-icons/tfi";
import { useIsMobile } from '../../../utility/useIsMobile';
import { useUserContext } from '../../../../../store/hooks/useUserContext';
import { hasPermission } from '../../../../../store/hooks/useUserStore';
import { known_actions } from '../../../../../types/userStore';
export interface INavigationUrl {
    paths: assignments_known_urls[],
    label: string,
    icon: (color: string) => React.ReactElement
}
export const PagesNavigator: React.FC = () => {
    const {isMobile} = useIsMobile();
    const {userClaims} = useUserContext();
    const { pathname } = useLocation();
    const urls: INavigationUrl[] = hasPermission(userClaims,known_actions.view_events) ? [
        {
            paths: [assignments_known_urls.home,assignments_known_urls.wrong_home],
            label: "Home",
            icon: (color: string) => <TfiHome color={color} />
        },
        {
            paths: [assignments_known_urls.assignment_list],
            label: isMobile ? "Assegnaz..." : "Assegnaz...",
            icon: (color: string) => <TfiLayoutAccordionList color={color} />
        },
        {
            paths: [assignments_known_urls.input_events_list],
            label: isMobile ? "Eventi" : "Eventi",
            icon: (color: string) => <TfiLayoutAccordionList color={color} />
        }
    ] : [
        {
            paths: [assignments_known_urls.home,assignments_known_urls.wrong_home],
            label: "Home",
            icon: (color: string) => <TfiHome color={color} />
        },
        {
            paths: [assignments_known_urls.assignment_list],
            label: isMobile ? "Assegnaz..." : "Assegnaz...",
            icon: (color: string) => <TfiLayoutAccordionList color={color} />
        },
    ]
    const navigate = useNavigate();
    const handleClick = (t: INavigationUrl) => navigate(t.paths[0])
    return <>
        {urls.map((u) => <SidebarIcon
            key={u.paths[0]}
            isSelected={u.paths.includes(pathname as any)}
            icon={u.icon}
            label={u.label}
            targetUrl={u.paths[0]}
            onClick={() => handleClick(u)}
        />)
        }
    </>
}