import React from "react";
import { ISidebarOptions } from "../../../../types/panels";

export const usePanels = ({ width, customWidth, isOpen, position, transitionMovementType, transitionResizeType,offset }: ISidebarOptions, height?: string | number) => {
    const getWidth = React.useCallback(() => {
        if (width === "custom" && customWidth) return customWidth;
        switch (width) {
            case "s": return 300;
            case "m": return 400;
            case "l": return 500;
            case "xl": return 600;
            case "xxl": return 700;
            default: return 300;
        }
    }, [width, customWidth])
    const _width = getWidth();
    const offset_y = offset.y
    const offset_x = offset.x
    const defaultTop = (offset_y ?? 0)
    const _leftPosition = React.useMemo(() => {
        const defaultLeft = (offset_x ?? 0)
        const defaultPos = { borderRight: '1px solid', left: defaultLeft, top: defaultTop, height: height ?? '100%', width: _width };
        const closeDimensions = {
            height: transitionResizeType === "height" || transitionResizeType === "both" ? 0 : height ?? '100%',
            width: transitionResizeType === "width" || transitionResizeType === "both" ? 0 : _width,
        }
        if (transitionMovementType === "horizontal" || transitionMovementType === "both") {
            return isOpen ? defaultPos : { ...closeDimensions, left: -(_width), top: defaultTop, }
        }
        return defaultPos;
    }, [isOpen, position, _width])
    const _rightPosition = React.useMemo(() => {
        const defaultRight = (offset_x ?? 0)
        const defaultPos = { borderLeft: '1px solid', borderBottom: '1px solid', borderTop: '1px solid', right: defaultRight, top: defaultTop, height: height ?? '100%', width: _width };
        const closeDimensions = {
            height: transitionResizeType === "height" || transitionResizeType === "both" ? 0 : height ?? '100%',
            width: transitionResizeType === "width" || transitionResizeType === "both" ? 0 : _width,
        }
        if (transitionMovementType === "horizontal" || transitionMovementType === "both") {
            return isOpen ? defaultPos : { ...closeDimensions, right: -(_width), top: defaultTop }
        }
        return defaultPos;
    }, [isOpen, position, _width])
    const _Position = position === "left" ? _leftPosition : _rightPosition
    const styles: React.CSSProperties = React.useMemo(() => {
        return {
            position: 'fixed',
            // padding: 8,
            transition: transitionMovementType !== 'none' || transitionResizeType !== 'none' ? 'all 0.3s' : undefined,
            zIndex: 3,
            maxWidth:`calc(100vw - ${offset_x ?? 0}px)`,
            ..._Position
        }
    }, [position, width, customWidth, isOpen, transitionMovementType, transitionResizeType])
    return {
        styles
    }
}