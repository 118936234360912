import React, { useState } from 'react';
import { FaArrowAltCircleRight, FaArrowAltCircleLeft } from 'react-icons/fa';
import './styles.css'
import { isMobile } from 'react-device-detect';
type Props = {
    images:string[]
    onClose:()=>void
    selected:number
}
export const ImageSlider:React.FC<Props> = ({ images: slides,onClose,selected }) => {
  const [current, setCurrent] = useState(selected);
  const length = slides.length;

  const nextSlide = (e:React.MouseEvent<HTMLOrSVGElement>)=>{
    e.stopPropagation();
    setCurrent(current === length - 1 ? 0 : current + 1);
  };

  const prevSlide =(e:React.MouseEvent<HTMLOrSVGElement>)=>{
    e.stopPropagation();
    setCurrent(current === 0 ? length - 1 : current - 1);
  };

  if (!Array.isArray(slides) || slides.length <= 0) {
    return null;
  }
  const handleClose = (e:React.MouseEvent<HTMLDivElement>)=>{
    e.stopPropagation();
    onClose();
  }
  return (
    <section className='slider' onClick={handleClose}>
      <FaArrowAltCircleLeft className='left-arrow' onClick={prevSlide} />
      <FaArrowAltCircleRight className='right-arrow' onClick={nextSlide} />
      {slides.map((slide, index) => {
        return (
          <div
            className={index === current ? 'slide active' : 'slide'}
            key={index}
          >
            {index === current && (
              <img src={slide} alt='travel image' className={isMobile ? 'mobile-image' : 'image'} />
            )}
          </div>
        );
      })}
    </section>
  );
};