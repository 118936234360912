import React from "react";
import { ISortableHeader } from "../../../types/general";
import { Typography } from "@mui/material";
import { ODataGridRowCell } from "../ODataGridRow/ODataGridRowCell/ODataGridRowCell";
import { paginationContext, PaginationEnum } from "../../../store/pager";
export type ODataGridCardProps = {
  item: any
  headers: ISortableHeader<any>[]
  enumStoreKey:PaginationEnum
};
export const ODataGridCard: React.FC<ODataGridCardProps> = ({ item, headers, enumStoreKey }) => {
  const {state} = React.useContext(paginationContext);
  return <div
    // onClick={() => handleAssignmentClick(ticket)}
    className={`flex flex-col w-[90%] bg-gray-300 max-h-[350px] rounded shadow mx-[5%] my-2`}
    style={{ border: "1px solid" }}
  >
    {headers.map((h) => <div className="flex items-center">
      <Typography fontWeight={"bold"}>{h.label}</Typography>
      <div className="ml-2" />
      <ODataGridRowCell isLoading={state.pagination[enumStoreKey].isLoading} enumStoreKey={enumStoreKey} header={h} x={item} />
    </div>)}
  </div>;
};
