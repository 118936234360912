import React from "react";
import { TMetric } from "../../../types/alerts";
import { TableRow } from "./Steps/TableRow/TableRow";

type Props = {
    metric: TMetric
    isNotSelectable?: boolean
}
export const MetricLeaf: React.FC<Props> = ({ metric, isNotSelectable }) => {
    return <TableRow 
        metric={metric}
        isSelectable={!isNotSelectable}
    />
    // return <div
    //     onClick={handleSelectChange}
    //     className={`flex flex-col w-[100%] border text-white rounded w-[100%] px-2 my-1 pb-2 cursor-pointer hover:bg-slate-700 ${checked ? 'bg-slate-700' : 'bg-slate-600'}`}>
    //     <div className={`flex items-center justify-between`}>
    //         <Typography className="text-xl">{metric.Id} - {metric.Name}</Typography>
                
            
    //         {/* {selectedView === "ASSIGNMENTS" ?
    //         <IconButton
    //             className="!text-white"
    //             onClick={() => dispatch({ type: "SET_STATE", payload: {...state,selectedView:"EDIT_ASSIGNMENT",selectedMetric:metric,currentStep:2} })}
    //         >
    //             <BiEdit />
    //         </IconButton>
    //         : */}
    //         {!isNotSelectable && <Checkbox
    //             // sx={{
    //             //     color: green[400],
    //             //     '&.Mui-checked': {
    //             //         color: green[600],
    //             //     },
    //             // }}
    //             checked={checked}
    //             onChange={handleSelectChange}
    //         />}
    //         {/* } */}
    //     </div>
    //     {cfg && <div className="flex items-center justify-end w-[100%] px-3 pb-2">
    //         <AssignmentConfigurationLeafItem cfg={cfg} />
    //     </div>}
    //     {/* {cfg && <Grid className="flex justify-center w-[100%] items-center" container spacing={1}>
    //         <Grid item xs={12}>
    //             <div className="flex items-center">
    //                 {cfg?.IsEnable ?
    //                     <GiConfirmed className="text-3xl text-intesa-green border-intesa-green" />
    //                     :
    //                     <BsXCircle className="text-3xl text-red-500" />
    //                 }
    //                 <Typography className="text-xl">
    //                     Owner:{cfg?.OwnerMail}
    //                 </Typography>
    //             </div>
    //         </Grid>
    //         <Grid item xs={12}>
    //             <Typography className="text-xl">
    //                 {cfg?.CanBeDelegated ? "Delegabile" : "Non delegabile"}
    //             </Typography>
    //         </Grid>
    //         <Grid item xs={12}>
    //             {cfg?.IsEscalationRequired ?
    //                 <>
    //                     <div className="flex items-center w-[100%] px-2">
    //                         <Typography>Scalabile a: </Typography>
    //                         <Typography className='text-xl'>{cfg.EscalationReceiversMail}</Typography>
    //                     </div>
    //                     <div className="flex items-center w-[100%] px-2">
    //                         <Typography className='text-xl'>Escalation dopo {cfg.EscalationTimeInDays} giorni</Typography>
    //                     </div>
    //                 </> :
    //                 <Typography>Escalation non richiesta</Typography>
    //             }
    //         </Grid>
    //     </Grid>} */}
    // </div>
}