import React from "react";
import "./styles.css";
import { TPileSize } from "../../types/general";
import { TAssetTypes, TProductAsset } from "../../services/products";
import { VerticalDashboardSection } from "../VerticalDashboardSection/VerticalDashboardSection";
import { ReportPile } from "../Pile/ReportPile/ReportPile";
import { useDashboardsStore } from "../../store/hooks/useDashboardsStore";
import { IFrontendSettingsGet } from "../../services/frontendSettings";
import { usePowerBIContext } from "../../store/hooks/usePowerBiContext";
import { MiniLoadingSpinner } from "../spinner/MiniLoadingSpinner/MiniLoadingSpinner";
import { isMobile } from "react-device-detect";

const ReportColumn: React.FC<{
  reportType: TAssetTypes.Report | TAssetTypes.SpecialReport;
  productId: string;
  reports: TProductAsset[];
  settings?: IFrontendSettingsGet;
}> = ({ productId, reports, settings, reportType }) => {
  const { search } = useDashboardsStore();
  const {isLoading} = usePowerBIContext();
  const filterReports = React.useCallback(
    (r: TProductAsset) => {
      const value =
        reportType === TAssetTypes.SpecialReport
          ? search.SPECIAL_REPORTS
          : search.REPORTS;
      if (!value) return true;
      let _ = value.toLowerCase();
      return r.Name.toLowerCase().includes(_);
    },
    [reportType, search.SPECIAL_REPORTS, search.REPORTS]
  );
  const pileSize: TPileSize = React.useMemo(() => {
    if(isMobile){
      return "x1";
    }
    if (!settings?.SectionColumns) {
      return "x1";
    }
    if (settings.SectionColumns < 3.5) {
      return "x1";
    } else {
      return "x2";
    }
  }, [settings?.SectionColumns]);
  return (
    <VerticalDashboardSection
      cotainerStyles={{ paddingBottom: 100 }}
      title=""
      settings={settings}
      id="SBReports"
    >
      {isLoading ? <MiniLoadingSpinner style={{color:'white'}}/> : reports.filter(filterReports).map((r) => (
        <ReportPile
          type={reportType}
          customSize={pileSize}
          key={r.AssetBag.ReportId}
          productId={productId}
          asset={r}
        />
      ))}
    </VerticalDashboardSection>
  );
};
export { ReportColumn };
