import { FontIcon } from "@fluentui/react";
import React from "react";
import { useNavigate, useLocation } from "react-router-dom";

import { TopbarTitle } from "../TopbarTitle/TopbarTitle";
import { useQuery } from "../../../hooks/useQuery";
import { LinksNavigator } from "../../LinksNavigator/LinksNavigator";
import { useNavigationContext } from "../../../store/hooks/useNavigationContext";
import { TbReportSearch } from "react-icons/tb";
import { reportSidebarOptions } from "../../powerBi/PowerBIUserEmbed";
import { stringCapitalize } from "../../../utility/strings";
import { RxReset } from "react-icons/rx";
import { usePowerBIContext } from "../../../store/hooks/usePowerBiContext";
import { IconButton } from "@mui/material";
import { TooltipContainer } from "../../TooltipContainer/TooltipContainer";
const resetFiltersIcon = require('../../../images/reports/reset-filters-icon.png');
export const reportTopbarHeight = 50;

const ReportTopBar: React.FC = () => {
  const location = useLocation();
  const query = useQuery();
  const navigate = useNavigate();
  const title = React.useMemo(() => {
    if (!query.get("name")) return "";
    switch (location.pathname) {
      default:
        return `${stringCapitalize(query.get("type") ?? "Report")}: ${query.get("name")}`;
    }
  }, [location.pathname, query]);
  const handleBack = React.useCallback(() => {
    navigate(-1);
    // if (query.get("area")) {
    //     navigate(`/${query.get("area")}`)
    // } else {
    //     navigate('/')
    // }
  }, []);
  const { sidebar, setSidebarOptions, setSidebarIsOpen } =
    useNavigationContext();
  const handleToggleSidebar = (e: React.MouseEvent<HTMLElement>) => {
    e.stopPropagation();
    setSidebarOptions(reportSidebarOptions(!sidebar.isOpen));
  };
  // return <TopBarWrapper extraStyles={{backgroundImage:"linear-gradient(to right,rgb(15,23,42),rgb(71,85,105))"}}>
  const sidebarBtnStyles = React.useMemo<React.CSSProperties>(
    () => ({
      // borderRadius: '8px',
      margin: "auto 15px auto 5px",
      // backgroundColor:intesaGreenBg_reducedOpacity,
      // backgroundColor: 'rgba(100,116,139,0.2)',
      // height: 40,
      // width: 40,
      padding: 0,
      fontSize: 30,
    }),
    []
  );
  const {embedState, setEmbedState } = usePowerBIContext();
  const resetPowerBiEmbeddingState = () => {
    setEmbedState({error:"",reportRendered:null})
  }
  React.useEffect(() => {
    return () => {
      setSidebarIsOpen(false);
      resetPowerBiEmbeddingState();
    };
  }, []);

  
  return (
    <div
      style={{
        height: reportTopbarHeight,
        alignItems: "center",
        backgroundImage:
          "linear-gradient(to right,rgb(15,23,42),rgb(71,85,105))",
        width: "100vw",
        display: "flex",
      }}
    >
      <IconButton
        style={{ height: 35, width: 35, margin: "auto 15px auto 5px" }}
        className="rounded-full hover:bg-slate-600"
        onClick={handleBack}
      >
        <FontIcon iconName="CaretSolidLeft" className="text-white text-xl" />
      </IconButton>
      <FontIcon
        iconName="BIDashboard"
        className="text-white text-xxxl my-auto mr-1"
      />
      <LinksNavigator />
      <div style={{marginLeft:'auto'}}/>
      <TopbarTitle title={title} />
      <IconButton
        style={sidebarBtnStyles}
        className="!rounded-lg hover:!bg-transparent hover:!text-white"
        onClick={handleToggleSidebar}
      >
        <TbReportSearch className="text-white text-3xl"/>
      </IconButton>
    </div>
  );
};
export { ReportTopBar };
