import { DirectionalHint, ICalloutProps, ITooltipHostStyles, ITooltipStyles, TooltipHost } from '@fluentui/react';
import React from 'react';
import { TTooltip } from '../../types/Tooltip';
type Props = {
    tooltip: TTooltip,
    containerStyle?: React.CSSProperties,
    customContentStyles?:Partial<ITooltipStyles>,
    containerClassName?: string
    children?:React.ReactNode
    tooltipDirection?:DirectionalHint,
    customHostStyles?:Partial<ITooltipHostStyles>
}
const TooltipContainer: React.FC<Props> = ({customContentStyles,customHostStyles, tooltip,containerClassName,containerStyle,children,tooltipDirection}) => {
    const calloutProps = React.useMemo<ICalloutProps>(()=>({ gapSpace: 0 }),[]);
    const hostStyles: Partial<ITooltipHostStyles> = React.useMemo(()=>({ root: { display: 'inline-block' },  }),[]);
    return <div className={containerClassName ?? ''} style={{...containerStyle}}>
        <TooltipHost
            id={tooltip.id}
            content={tooltip.text}
            calloutProps={calloutProps}
            tooltipProps={{styles:customContentStyles}}
            styles={customHostStyles ?? hostStyles}
            directionalHint={tooltipDirection}
        >
            {children}
        </TooltipHost>
    </div>
}
export { TooltipContainer }