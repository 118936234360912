import { Container, Paper } from "@mui/material";
import React from "react";
import { TProductAsset } from "../../services/products";
import { useDashboardsStore } from "../../store/hooks/useDashboardsStore";
import axios from "axios";
import { useUserContext } from "../../store/hooks/useUserContext";
import { useGraphAuthentication } from "../../hooks/useGraphAuthentication";
import { FontIcon, IconButton } from "@fluentui/react";
import { MiniLoadingSpinner } from "../spinner/MiniLoadingSpinner/MiniLoadingSpinner";
import { NewsLetterPile } from "../Pile/NewsLetterPile/NewsLetterPile";
import { TooltipContainer } from "../TooltipContainer/TooltipContainer";
type Props = {
  assets: TProductAsset[];
};
const NewsLettersContainer: React.FC<Props> = ({ assets }) => {
  const [isLoading, setIsLoading] = React.useState(false);
  const { errors, setErrors, setGraphAccessToken } = useUserContext();
  const { news_letters, contextDispatch, setNewsLettersHash, hashNewsLetters } =
    useDashboardsStore();
  React.useEffect(
    () => setNewsLettersHash(JSON.stringify(hashNewsLetters())),
    [setNewsLettersHash, hashNewsLetters]
  );
  const loadItems = (token: string) => {
    const asset = assets[0];
    if (!asset) {
      setIsLoading(false);
      // setErrors([...errors, "Nessuna news letter configurata. Contatta l'amministratore"])
      return;
    }
    const url = `${process.env.REACT_APP_SERVER_URL}${asset.AssetBag.PartialUrl}`;
    axios
      .post(url, { GraphApiToken: token, ...asset.AssetBag })
      .then((res) =>
        contextDispatch({ type: "SET_NEWS_LETTERS", payload: res.data })
      )
      .catch((err) => {
        if (err.response.status === 401) {
          setGraphAccessToken("");
          setErrors([...errors, "Non autorizzato"]);
        }
      })
      .finally(() => setIsLoading(false));
  };
  const { getAccessToken } = useGraphAuthentication({
    setIsLoading,
    graphTokenSelector: (userInfo) => userInfo.graphAccessToken,
    loadItems,
    loadConditions: news_letters.length === 0,
  });
  const renderPiles = React.useCallback(() => {
    if (isLoading) return null;
    return news_letters.length > 0 ? (
      news_letters.map((t) => (
        <NewsLetterPile key={t.Id} asset={t} pileSize={"x4"} />
      ))
    ) : (
      <div className="flex flex-row items-center">
        <p className="text-2xl text-white font-bold">
          Nessuna newsletter trovata
        </p>
        <IconButton
          disabled={isLoading}
          className="!bg-transparent rounded-lg px-2 py-1 !hover:bg-transparent"
          onClick={() => {
            setIsLoading(true);
            getAccessToken(true, "graph").finally(() => setIsLoading(false));
          }}
        >
          <FontIcon iconName="StatusCircleSync" style={{ fontSize: "2rem" }} />
        </IconButton>
      </div>
    );
  }, [news_letters, isLoading]);
  const errors_set = React.useMemo(
    () => (errors && Array.isArray(errors) ? Array.from(new Set(errors)) : []),
    [errors]
  );
  return (
    <Container
      maxWidth="xl"
      className="my-2"
      style={{ maxHeight: "calc(100vh - 16px - 16px - 60px - 60px - 40px)" }}
    >
      <Paper className="w-[100%] border-lightgrey border p-2 !bg-slate-700 !rounded-xl !shadow-xl">
        <p className="text-3xl text-white text-center h-[40px] border-b mb-1">
          News Letters
        </p>
        <div
          className="w-[100%] items-start justify-stretch flex flex-col h-[100%]"
          style={{
            overflowY: "auto",
            maxHeight: "calc(100vh - 16px - 16px - 8px - 60px - 60px - 40px)",
            padding: "20px 3% 20px 3%",
          }}
        >
          <div className="w-[100%] flex flex-row flex-wrap">
            {/* {news_letters.map((report) => <N customSize="xxl" type="standard" productId="iot-connected-buildings" asset={report} />)} */}
            {errors_set.length > 0 ? (
              <div className="flex flex-row items-center">
                <p
                  style={{ whiteSpace: "pre-wrap" }}
                  className="text-white text-xl mx-4"
                >
                  {isLoading
                    ? "Caricamento..."
                    : errors_set.map((s, i) => `${i + 1}. ${s}`).join("\n")}
                </p>
                <TooltipContainer
                  tooltip={{ text: "Get Power BI Token", id: "POWER_BI_TOKEN" }}
                  customContentStyles={{ content: { fontSize: 20 } }}
                >
                  <IconButton
                    disabled={isLoading}
                    className="bg-white rounded-lg px-2 py-1 hover:bg-white text-slate-800"
                    onClick={() => {
                      setIsLoading(true);
                      getAccessToken(true, "graph").finally(() =>
                        setIsLoading(false)
                      );
                    }}
                  >
                    <FontIcon
                      iconName="StatusCircleSync"
                      style={{ fontSize: "2rem" }}
                    />
                  </IconButton>
                </TooltipContainer>
                {isLoading && (
                  <MiniLoadingSpinner
                    containerClassNames="!text-white"
                    style={{
                      color: "white",
                      marginLeft: 20,
                      fontSize: "0.75rem",
                    }}
                  />
                )}
              </div>
            ) : (
              renderPiles()
            )}
            {isLoading && errors.length === 0 && (
              <MiniLoadingSpinner style={{ color: "white" }} />
            )}
          </div>
        </div>
      </Paper>
    </Container>
  );
};
export { NewsLettersContainer };
