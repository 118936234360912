import React from 'react'
import { putForceTicketClosure } from '../../services/eticket'
import { Button, Typography } from '@mui/material'
import { DetailsContainer } from '../DetailsContainer/DetailsContainer'
import { DetailsLabelValueContainer } from '../DetailsLabelValueContainer/DetailsLabelValueContainer'
import { DetailsLabel } from '../DetailsLabel/DetailsLabel'
import { DetailsValue } from '../DetailsValue/DetailsValue'
import { formatDate } from '../../utility/formatDate'
import { ETicket } from '../../types/etickets'
import { TopBarLoading } from '../TopBarLoading/TopBarLoading'
import { DetailsTitle } from '../DetailsTitle/DetailsTitle'
import { DetailsRow } from '../DetailsRow/DetailsRow'
import { assignments_known_urls } from '../../types/userStore'
import { DetailNavigatorIcon } from '../DetailNavigatorIcon/DetailNavigatorIcon'
type Props = {
    ticket: ETicket
    isLoading: boolean
}
export const ETicketDetail: React.FC<Props> = ({ isLoading, ticket }) => {
    const [openClosureConfirm, setOpenClosureConfirm] = React.useState(false);
    const handleForceClosure = React.useCallback(async () => {
        putForceTicketClosure(ticket)
    }, [ticket])

    return <DetailsContainer>
        <DetailsTitle text="Ticket" />
        {isLoading && <TopBarLoading />}
        <DetailNavigatorIcon url={assignments_known_urls.ticket_detail} id={ticket.Id}/>
        <DetailsRow>
            <DetailsLabelValueContainer roundedUp>
                <DetailsLabel label='ID' />
                <DetailsValue value={ticket.Id} />
            </DetailsLabelValueContainer>

            <DetailsLabelValueContainer roundedUp>
                <DetailsLabel label='Nome' />
                <DetailsValue value={ticket.TicketName} />
            </DetailsLabelValueContainer>

        </DetailsRow>
        <DetailsRow>
            <DetailsLabelValueContainer>
                <DetailsLabel label='Edificio' />
                {/* <DetailsValue value={`${ticket.BuildingName} [${ticket.CRImmobile}]`} /> */}
                <DetailsValue align="left" value={`Livorno - Via Nadi 1 / Via Cairoli 20 [IT049009000005]`} />
            </DetailsLabelValueContainer>
            <DetailsLabelValueContainer>
                <DetailsLabel label='Causale' />
                <DetailsValue value={ticket.Causale} />
            </DetailsLabelValueContainer>
        </DetailsRow>
        <DetailsRow>
            <DetailsLabelValueContainer>
                <DetailsLabel label='ExternalEventId' />
                <DetailsValue value={ticket.ExternalEventId} />
            </DetailsLabelValueContainer>
            <DetailsLabelValueContainer>
                <DetailsLabel label='ExternalReferenceId' />
                <DetailsValue value={ticket.ExternalReferenceId} />
            </DetailsLabelValueContainer>
        </DetailsRow>
        <DetailsRow>
            <DetailsLabelValueContainer>
                <DetailsLabel label='OpeningRequestStatus' />
                <DetailsValue value={ticket.OpeningRequestStatus} />
            </DetailsLabelValueContainer>
            <DetailsLabelValueContainer>
                <DetailsLabel label='ClosureRequestStatus' />
                <DetailsValue value={ticket.ClosureRequestStatus} />
            </DetailsLabelValueContainer>
        </DetailsRow>
        <DetailsRow>
            <DetailsLabelValueContainer>
                <DetailsLabel label='OpeningRequestTime' />
                <DetailsValue value={formatDate(ticket.OpeningRequestTime)} />
            </DetailsLabelValueContainer>
            <DetailsLabelValueContainer>
                <DetailsLabel label='ClosureRequestTime' />
                <DetailsValue value={formatDate(ticket.ClosureRequestTime)} />
            </DetailsLabelValueContainer>
        </DetailsRow>
        <DetailsRow>
            <DetailsLabelValueContainer>
                <DetailsLabel label='OpeningTime' />
                <DetailsValue value={formatDate(ticket.OpeningTime)} />
            </DetailsLabelValueContainer>
            <DetailsLabelValueContainer>
                <DetailsLabel label='ClosureTime' />
                <DetailsValue value={formatDate(ticket.ClosureTime)} />
            </DetailsLabelValueContainer>
        </DetailsRow>
        <DetailsRow>
            <DetailsLabelValueContainer>
                <DetailsLabel label='CodiceAmbito' />
                <DetailsValue value={ticket.CodiceAmbito} />
            </DetailsLabelValueContainer>
            <DetailsLabelValueContainer>
                <DetailsLabel label='NomeSpecifica' />
                <DetailsValue value={ticket.NomeSpecifica} />
            </DetailsLabelValueContainer>
        </DetailsRow>
        <DetailsRow>
            <DetailsLabelValueContainer>
                <DetailsLabel label='Description' />
                <DetailsValue value={ticket.Description} />
            </DetailsLabelValueContainer>
        </DetailsRow>
        <div className="mt-2" />
        <div className="flex w-[99%] justify-end">
            <Button variant="contained" className="!self-end !bg-red-600 !text-white hover:!bg-red-700" onClick={() => setOpenClosureConfirm(true)}>Forza Chiusura</Button>
        </div>
        <div className="mt-2" />
        {
            openClosureConfirm &&
            <>
                <div onClick={() => setOpenClosureConfirm(false)} style={{ position: 'fixed', width: '100vw', height: '100vh', backgroundColor: 'rgba(0,0,0,0.3)', left: 0, top: 0, zIndex: 5, backdropFilter: 'blur(1px)' }} />
                <div className="fixed flex flex-col bg-white text-black p-2 rounded" style={{ top: 'calc(50vh - 150px)', left: 'calc(50vw - 150px)', width: 350, height: 150, zIndex: 10 }}>
                    <Typography className='!text-2xl' >Confermi di voler forzare la chiusura del ticket {ticket.Id}</Typography>
                    <div className='mt-auto flex justify-between w-[100%]'>
                        <Button variant="outlined" onClick={() => setOpenClosureConfirm(false)}>Annulla</Button>
                        <Button variant='contained' color="secondary" onClick={handleForceClosure}>Conferma</Button>
                    </div>
                </div>
            </>
        }
    </DetailsContainer>


}