import { Checkbox } from "@mui/material";
import { TMetric } from "../../../types/alerts";

type Props = {
    metric: TMetric
    onChange: (m: TMetric) => void
    checked: boolean
    editable?:boolean
}
export const MetricLeaf: React.FC<Props> = ({ metric, checked, onChange,editable }) => {
    return <div className={`flex justify-between w-[100%] border-b px-2 py-1 mb-2`}>
        <p className="text-xl text-white">{metric.Name}</p>
        <Checkbox
            style={{ color: 'white' }}
            checked={checked}
            onChange={editable ? () => onChange(metric) : undefined}
        />
    </div>
}