import React from 'react';
import { useLocation } from 'react-router-dom';
import useWindowSize from '../../../utility/useWindowSize';

type Props = {
    extraStyles?:React.CSSProperties
    children?:React.ReactNode
}
const TopBarWrapper: React.FC<Props> = ({ children,extraStyles }) => {
    const location = useLocation();
    const area = React.useMemo(() => {
        let url = document.URL;
        return url.split('/')[3];
    }, [location.pathname])
    const { mainContentWidth, sidebarWidth } = useWindowSize();
    const customStyles = React.useMemo(() => {
        return {backgroundColor:"#107c41",...extraStyles};
    }, [area])
    return <div id="topbar" className="bar" style={{
        width: mainContentWidth && sidebarWidth ? mainContentWidth + sidebarWidth : 'auto',
        ...customStyles
    }}>
        {children}
    </div>
}
export { TopBarWrapper }