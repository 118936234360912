export const intesa_green = 'rgb(38,118,1)'
export const intesaGreenBg_reducedOpacity = 'rgba(37,137,0,0.6)'
export const intesaLightTheme = {
  palette: {
    themePrimary: '#107c41',
    themeLighterAlt: '#f1faf5',
    themeLighter: '#caead8',
    themeLight: '#a0d8b9',
    themeTertiary: '#55b17e',
    themeSecondary: '#218d51',
    themeDarkAlt: '#0f703b',
    themeDark: '#0c5f32',
    themeDarker: '#094624',
    neutralLighterAlt: '#f8f8f8',
    neutralLighter: '#f4f4f4',
    neutralLight: '#eaeaea',
    neutralQuaternaryAlt: '#dadada',
    neutralQuaternary: '#d0d0d0',
    neutralTertiaryAlt: '#c8c8c8',
    neutralTertiary: '#d0d0d0',
    neutralSecondary: '#323130',
    neutralPrimaryAlt: '#747474',
    neutralPrimary: '#323130',
    neutralDark: '#4a4a4a',
    black: '#363636',
    white: '#ffffff',
  },
  semanticColors: {
    primaryButtonBackgroundDisabled: '#5d7869',
  },
  sidebar:{
    // backgroundColor:'rgba(0,120,212,0.6)'
  },
  filterColors: {
    filtered: {
      chip: {
        root: {
          border: '1px solid rgb(240,240,240)',
          boxShadow:'2px 2px 2px 1px rgba(0,0,0,0.2)',
          height: 'initial !important',
          backgroundColor: 'rgba(16,124,65)',
          color: 'white',
          padding:'0px 2px',
          zIndex: 100000000
        },
        label: {
          fontSize: '1rem',
          fontFamily:'system-ui',
          color: 'white',
          fontWeight:600,
          padding:'4px 4px',
          margin:'0px 5px'
        },
        icon: {
          color: 'white',
          fontWeight:400
        }
      },
      gridHeaderIcon: {
        color: 'rgb(16,124,65)',
        fontWeight:'bolder'
        // color: 'rgba(0, 153, 0,0.9)'
      }
    },
    unfiltered: {
      chip: {
        backgroundColor: '',
        color: 'black',
      },
      gridHeaderIcon: {
        color: 'black',
        fontWeight:'bolder'
      }
    }
  }
};
export const intesaTestTheme = {
  palette: {
    themePrimary: 'red',
    themeLighterAlt: 'red',
    themeLighter: 'red',
    themeLight: 'red',
    themeTertiary: 'red',
    themeSecondary: 'red',
    themeDarkAlt: 'red',
    themeDark: 'red',
    themeDarker: 'red',
    neutralLighterAlt: 'red',
    neutralLighter: 'red',
    neutralLight: 'red',
    neutralQuaternaryAlt: 'red',
    neutralQuaternary: 'red',
    neutralTertiaryAlt: 'red',
    neutralTertiary: 'red',
    neutralSecondary: 'red',
    neutralPrimaryAlt: 'red',
    neutralPrimary: 'red',
    neutralDark: 'red',
    black: 'red',
    white: 'red',
  },
  semanticColors: {
    primaryButtonBackgroundDisabled: 'red',
  },
  sidebar:{
    // backgroundColor:'rgba(0,120,212,0.6)'
  },
  filterColors: {
    filtered: {
      chip: {
        root: {
          border: '1px solid rgb(240,240,240)',
          boxShadow:'2px 2px 2px 1px rgba(0,0,0,0.2)',
          height: 'initial !important',
          backgroundColor: 'rgba(16,124,65)',
          color: 'white',
          padding:'0px 2px',
          zIndex: 100000000
        },
        label: {
          fontSize: '1rem',
          fontFamily:'system-ui',
          color: 'white',
          fontWeight:600,
          padding:'4px 4px',
          margin:'0px 5px'
        },
        icon: {
          color: 'white',
          fontWeight:400
        }
      },
      gridHeaderIcon: {
        color: 'rgb(16,124,65)',
        fontWeight:'bolder'
        // color: 'rgba(0, 153, 0,0.9)'
      }
    },
    unfiltered: {
      chip: {
        backgroundColor: 'red',
        color: 'black',
      },
      gridHeaderIcon: {
        color: 'black',
        fontWeight:'bolder'
      }
    }
  }
}
export const intesaDarkTheme = {
  palette: {
    themePrimary: '#107c41',
    themeLighterAlt: '#f1faf5',
    themeLighter: '#caead8',
    themeLight: '#a0d8b9',
    themeTertiary: '#55b17e',
    themeSecondary: '#218d51',
    themeDarkAlt: '#0f703b',
    themeDark: '#0c5f32',
    themeDarker: '#094624',
    neutralLighterAlt: '#f8f8f8',
    neutralLighter: '#f4f4f4',
    neutralLight: '#eaeaea',
    neutralQuaternaryAlt: '#dadada',
    neutralQuaternary: '#d0d0d0',
    neutralTertiaryAlt: '#c8c8c8',
    neutralTertiary: '#d0d0d0',
    neutralSecondary: '#323130',
    neutralPrimaryAlt: '#747474',
    neutralPrimary: '#323130',
    neutralDark: '#4a4a4a',
    black: '#363636',
    white: '#ffffff',
  },
  semanticColors: {
    primaryButtonBackgroundDisabled: '#5d7869',
  },
  sidebar:{
    // backgroundColor:'rgba(0,120,212,0.6)'
  },
  filterColors: {
    filtered: {
      chip: {
        root: {
          border: '1px solid rgb(240,240,240)',
          boxShadow:'2px 2px 2px 1px rgba(0,0,0,0.2)',
          height: 'initial !important',
          backgroundColor: 'rgba(16,124,65)',
          color: 'white',
          padding:'0px 2px',
          zIndex: 100000000
        },
        label: {
          fontSize: '1rem',
          fontFamily:'system-ui',
          color: 'white',
          fontWeight:600,
          padding:'4px 4px',
          margin:'0px 5px'
        },
        icon: {
          color: 'white',
          fontWeight:400
        }
      },
      gridHeaderIcon: {
        color: 'rgb(16,124,65)',
        fontWeight:'bolder'
        // color: 'rgba(0, 153, 0,0.9)'
      }
    },
    unfiltered: {
      chip: {
        backgroundColor: '',
        color: 'black',
      },
      gridHeaderIcon: {
        color: 'black',
        fontWeight:'bolder'
      }
    }
  }
};
// export const highlightClassNames = 'bg-yellow-500/50'
// export const highlightClassNames = 'text-amber-400'
export const highlightClassNames = ''