import React from 'react';
import { StyledTextFieldProps } from './StyledTextField';
import { Button, TextField } from '@mui/material';
import { EditJsonForm } from '../EditJsonForm/EditJsonForm';
import { isJsonString } from '../../../utility/json';
import { CustomReactJsonEditor } from './CustomReactJsonEditor';
export interface JSONStyledTextFieldProps extends StyledTextFieldProps {
    setJsonValue: (key: string, value: any) => void
}
type TMode = "plainText" | "JSONForm"
const JsonTextField: React.FC<JSONStyledTextFieldProps> = ({
    label,
    placeholder,
    required,
    inputId,
    value,
    onChange,
    multiline,
    disabled,
    setJsonValue,
    setValue,
    asset,
    editorRef,
}) => {
    const [mode, setMode] = React.useState<TMode>("plainText");
    const [isOpen, setIsOpen] = React.useState(false);
    const handleChangeMode = (mode: TMode) => {
        setMode(mode);
    }
    return <div>
        <div className="w-[100%] flex justify-end mb-2 h-[50px] items-center">
            {mode === "plainText" && setJsonValue && <div className='flex justify-end'>
                <Button onClick={() => { setIsOpen(true) }} variant="contained" color="primary" className="!mx-2 !text-white !h-[40px]">Edit Json</Button>
                <EditJsonForm
                    asset={asset}
                    title={label}
                    isOpen={isOpen}
                    setValue={setValue}
                    setJsonValue={setJsonValue}
                    onClose={() => setIsOpen(false)}
                    jsonObj={value}
                />
            </div>
            }
            <Button
                className="!mx-4 !text-white !h-[40px]"
                variant={mode === "plainText" ? 'contained' : 'outlined'}
                onClick={() => handleChangeMode('plainText')}>
                TESTO
            </Button>
            <Button
                className="!text-white !h-[40px]"
                variant={mode === "JSONForm" ? 'contained' : 'outlined'}
                onClick={() => handleChangeMode('JSONForm')}>
                JSON FORM
            </Button>
        </div>
        {mode === "JSONForm" && //package types have a bug and doesnt compile TODO
            <div className='bg-gray-200 !text-white'>
                <CustomReactJsonEditor values={isJsonString(value) ? JSON.parse(value) : value} onChange={(value: any) => { setValue(value) }} />
            </div>
        }
        <>
            {mode === "plainText" &&
                <TextField
                    variant="outlined"
                    label={label}
                    disabled={disabled}
                    placeholder={placeholder}
                    required={required}
                    multiline={multiline}
                    fullWidth
                    InputLabelProps={{
                        style: {
                            color: 'white',
                            paddingRight: 10,
                            paddingLeft: 10
                        }
                    }}
                    inputProps={{
                        style: {
                            color: 'white',
                            backgroundColor: 'rgb(51,65,85)',
                            borderRadius: 8,
                            paddingRight: 10,
                            paddingLeft: 10
                        },

                    }}
                    id={inputId}
                    value={value}
                    onChange={onChange}
                    className="!my-2 !text-white !border-slate-800" />
            }


        </>
    </div >
}
export { JsonTextField }