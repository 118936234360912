import React from "react";
import { Divider, IconButton, Menu, MenuItem } from "@mui/material";
import { AiFillFilter, AiOutlineFilter } from "react-icons/ai";
import { IColumnFilterPreset } from "../../types/general";
import { PaginationEnum, paginationContext } from "../../store/pager";
import { GrCheckboxSelected } from "react-icons/gr";
import { useFilterPresets } from "../../store/hooks/useFilterPresets";
import { FilterChips } from "../FilterChips/FilterChips";
import { LightTooltip } from "../LightTooltip/LightTooltip";
import { useIsMobile } from "../../utility/useIsMobile";
import { HeaderFilterPopup } from "../HeaderCell/HeaderFilterPopup/HeaderFilterPopup";
import { AllHeadersFilterPopup } from "../HeaderCell/AllHeadersFilterPopup/AllHeadersFilterPopup";
import { assignments_known_urls } from "../../types/userStore";
import { useNavigate } from "react-router-dom";
type Props = {
  enumKey: PaginationEnum;
  style?: React.CSSProperties;
};
export const FilterPresetsIcon: React.FC<Props> = ({ enumKey, style }) => {
  const { isMobile } = useIsMobile();
  const { presets } = useFilterPresets();
  const navigate = useNavigate();
  const { state, dispatch } = React.useContext(paginationContext);
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const [advancedMode, setAdvancedMode] = React.useState(false);
  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const presetIsApplied = (preset: IColumnFilterPreset<any>) =>
    state.pagination[enumKey].filtersPresets.find(
      (p) => p.presetName === preset.presetName
    );
  const somePresetIsApplied = Object.values(presets[enumKey]).some((preset) =>
    presetIsApplied(preset)
  );
  const presetInSameColumnIsApplied = (preset: IColumnFilterPreset<any>) =>
    state.pagination[enumKey].filtersPresets.find(
      (p) => p.filter.columnName === preset.filter.columnName
    );
  const handleMenuItemClick = (preset: IColumnFilterPreset<any>) => {
    let newPresets: IColumnFilterPreset<any>[] = [];
    if (presetIsApplied(preset)) {
      newPresets = state.pagination[enumKey].filtersPresets.filter(
        (p) => p.presetName !== preset.presetName
      );
    } else if (presetInSameColumnIsApplied(preset)) {
      newPresets = state.pagination[enumKey].filtersPresets.map((p) =>
        p.filter.columnName === preset.filter.columnName ? preset : p
      );
    } else {
      newPresets = [...state.pagination[enumKey].filtersPresets, preset];
    }
    // navigate(`${known_urls.assignment_list}/${JSON.stringify(newPresets.map((p) => p.filter))}/${JSON.stringify(newPresets)}`)

    dispatch({
      type: "SET_FILTERS_AND_PRESETS",
      payload: {
        key: enumKey,
        presets: newPresets,
        filters: [...state.pagination[enumKey].filters,...newPresets.map((p) => p.filter)]
      },
    });
  };
  const presetsGroups = Array.from(
    new Set(Object.values(presets[enumKey]).map((preset) => preset.filterGroup))
  );
  return (<>
    <div style={style}>
      <LightTooltip title={<p className='text-xl'>Filtri pre-configurati</p>} placement="right-start" arrow>
        <IconButton
          id="filters-presets-button"
          aria-controls={open ? "filters-presets-menu" : undefined}
          aria-haspopup="true"
          aria-expanded={open ? "true" : undefined}
          onClick={handleClick}
        >
          {somePresetIsApplied ? (
            <AiFillFilter color='rgb(38,118,1)' />
          ) : (
            <AiOutlineFilter />
          )}
        </IconButton>
      </LightTooltip>

      <Menu
        id="filters-presets-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        MenuListProps={{
          "aria-labelledby": "filters-presets-button",
        }}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "right",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "left",
        }}
      >
        {/* on advancedMode display all header filters in column */}
        {presetsGroups.map((groupName, index) => (
          <div>
            {groupName && (
              <MenuItem key={groupName} className="hover:!bg-transparent !cursor-default !font-bold">
                {groupName}
              </MenuItem>
            )}
            {Object.values(presets[enumKey])
              .filter((preset) => preset.filterGroup === groupName)
              .map((preset: IColumnFilterPreset<any>) => (
                <MenuItem
                  key={preset.presetName}
                  disabled={state.pagination[enumKey].isLoading}
                  className="!text-xl"
                  onClick={() => handleMenuItemClick(preset)}
                >
                  {presetIsApplied(preset) && (
                    <GrCheckboxSelected className="!text-green-700 mr-2" />
                  )}
                  {preset.presetName}
                </MenuItem>
              ))}
            {index < presetsGroups.length - 1 && <Divider />}
          </div>
        ))}
        {Object.entries(presets[enumKey]).length === 0 && (
          <MenuItem className="hover:!bg-transparent !cursor-default">
            Nessun filtro preconfigurato
          </MenuItem>
        )}
        <Divider />
        <MenuItem
          key={"Avanzate"}
          disabled={state.pagination[enumKey].isLoading}
          className="!text-2xl !font-bold"
          onClick={() => {
            setAdvancedMode(true);
            handleClose();

          }}
        >
          Filtro avanzato
        </MenuItem>
        {/* {Object.entries(presets[enumKey]).map(([key,preset]) => <MenuItem
                    className='!text-xl'
                    onClick={() => handleMenuItemClick(preset)}>
                    {presetIsApplied(preset) && <GrCheckboxSelected className='!text-green-700 mr-2' />}
                    {preset.presetName}
                </MenuItem>)} */}
        {/* {isMobile && <MenuItem onClick={()=>setAdvancedMode(true)}>Avanzato</MenuItem>} */}
      </Menu>
    </div>
    {!isMobile && <div style={{maxWidth:'calc(100% - 350px)',padding:'10px 0px'}}><FilterChips enumKey={enumKey} /></div>}
    <AllHeadersFilterPopup
      open={advancedMode}
      enumStoreKey={enumKey}
      onClose={() => setAdvancedMode(false)}
    />
  </>
  );
};
