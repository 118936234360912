import React from "react";
import { AiOutlineAppstoreAdd } from "react-icons/ai";
import { IoNewspaperSharp } from "react-icons/io5";
import {
  MAIN_DASHBOARD_VIEW_TYPE,
  useQueryValues,
} from "../../../hooks/useQueryValues";
import { MainDashboardIcon } from "./MainDashboardIcon";
import { TMainDashboardNewsletterIconProps } from "./types";
const NewsLettersIcon: React.FC<TMainDashboardNewsletterIconProps> = (
  props
) => {
  const { queryValues } = useQueryValues();
  // const _newsLettersHighlightPopup = React.useMemo(() => {
  //   if (!props.highlight) return null;
  //   return (
  //     <>
  //       <div
  //         style={{
  //           position: "absolute",
  //           width: 0,
  //           height: 0,
  //           zIndex: 999999,
  //           borderLeft: "10px solid transparent",
  //           borderRight: "10px solid transparent",
  //           borderTop: "10px solid white",
  //           bottom: topbarHeight - 10,
  //           left: props.iconLeft,
  //         }}
  //       />
  //       <div
  //         style={{
  //           position: "absolute",
  //           borderRadius: 4,
  //           border: "1px solid yellow",
  //           padding: "4px 6px",
  //           bottom: topbarHeight,
  //           zIndex: 999999,
  //           left: props.iconLeft,
  //           backgroundColor: "snow",
  //           color: "black",
  //         }}
  //       >
  //         Nuove News Letters
  //       </div>
  //     </>
  //   );
  // }, [props.highlight, props.iconLeft]);
  return (
    <>
      <MainDashboardIcon
        {...props}
        tooltip={{
          text:
            queryValues[MAIN_DASHBOARD_VIEW_TYPE] === "newsletter"
              ? "Home Page"
              : "News Letters",
          id: "NEWS_LETTERS_BTN",
        }}
      >
        {queryValues[MAIN_DASHBOARD_VIEW_TYPE] === "newsletter" ? (
          <AiOutlineAppstoreAdd />
        ) : (
          <IoNewspaperSharp />
        )}
      </MainDashboardIcon>
      {/* {_newsLettersHighlightPopup} */}
    </>
  );
};
export { NewsLettersIcon };
