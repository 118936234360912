import { IComboBoxOption } from '@fluentui/react';
import React from 'react';
import { CreateAssetPayload, createReport, TAssetLevels, TAssetTypes, TProductAsset } from '../../services/products';
import { useDashboardsStore } from '../../store/hooks/useDashboardsStore';
import { FlexCol } from '../Flex/FlexCol';
import { DashboardButton } from '../DashboardButton/DashboardButton';
import { DashboardComboBox } from '../DashboardComboBox/DashboardComboBox';
import { DashboardTextInput } from '../DashboardTextInput/DashboardTextInput';
import useWindowSize from '../../utility/useWindowSize';
import { IconButton } from '@mui/material';
import { AiOutlineClose } from 'react-icons/ai';
import { usePowerBIContext } from '../../store/hooks/usePowerBiContext';
import { known_products } from '../../types/userStore';
import { useDashboardsNavigation } from '../../store/hooks/useDashboardsNavigation';


const AddAssetPanel: React.FC<{ type: "specialReports" | "reports" | "scorecards", closePanel: (e: any) => void }> = ({ type, closePanel }) => {
    const { getProductIdFromPath } = useDashboardsNavigation();
    const productId = React.useMemo(() => getProductIdFromPath(), [getProductIdFromPath])
    const [selectedOption, setSelectedOption] = React.useState<null | string>(null);
    const {
        width
    } = useWindowSize();
    const areaId = React.useMemo(() => Object.values(known_products).find(x => x.dashboard_url.slice(1) === productId)?.id, [productId])
    const [state, setState] = React.useState({
        name: '',
        description: '',
        img: '',
        workspaceId: '6eae3de6-456b-4a56-9849-803774b49128',
        uiorder: 0,
        pageName:'',
        directLink:''
    })
    const [isLoading, setIsLoading] = React.useState(false);
    const {
        loadAssets
    } = useDashboardsStore();
    const {
        name, description, img: image, workspaceId, uiorder
    } = state;
    const assetType = React.useMemo(() => {
        switch (type) {
            case "specialReports": return TAssetTypes.SpecialReport
            case "reports": return TAssetTypes.Report
            case "scorecards": return TAssetTypes.ScoreCard
            default: return TAssetTypes.Report;
        }
    }, [type])
    const disabled = !name || !selectedOption || !workspaceId;

    const handleSubmit = async (e: any) => {
        if (!areaId) {
            console.log("Area ID is null")
            return;
        }
        if (disabled) {
            return; //disabled button
        }
        setIsLoading(true);
        const payload: CreateAssetPayload = {
            productId: areaId,
            name,
            description,
            level: TAssetLevels.ProductPage,
            uiorder,
            assetType,
            assetBag: {
                image,
                reportId: selectedOption,
                workspaceId,
                pageName:state.pageName,
                directLink:state.directLink
            }
        }
        createReport(payload).then((res: TProductAsset) => {
            setIsLoading(false);
            closePanel(e);
            loadAssets(true);
        });

    }
    const onChange = (e: any) => {
        switch (e.target.name) {
            case "name": {
                setState({ ...state, name: e.target.value });
                break;
            }
            case "description": {
                setState({ ...state, description: e.target.value });
                break;
            }
            case "img": {
                setState({ ...state, img: e.target.value });
                break;
            }
            case "uiorder": {
                setState({ ...state, uiorder: e.target.value });
                break;
            }
            case "workspaceId": {
                setState({ ...state, workspaceId: e.target.value });
                break;
            }
            case "id": {
                setSelectedOption(e.target.value);
                break;
            }
            case "pageName": {
                setState({ ...state, pageName: e.target.value } );
                break;
            }
            case "directLink": {
                setState({ ...state, directLink: e.target.value });
                break;
            }
            default: return;
        }
    }
    const handleOptionChange = (e: any, option: any) => {
        setSelectedOption(option.key)
        setState({ ...state, name: option.text })
    };
    const {
        workspace_items
    } = usePowerBIContext();
    const [assetOptions, setAssetOptions] = React.useState<IComboBoxOption[]>([])
    React.useEffect(() => {
        if (workspace_items[state.workspaceId]) {
            if (assetType === TAssetTypes.ScoreCard) {
                setAssetOptions(workspace_items[state.workspaceId].filter((x) => x.reportType === undefined).map((x) => ({ key: x.id, text: x.name })))
            } else {
                setAssetOptions(workspace_items[state.workspaceId].filter((x) => x.reportType === "PowerBIReport").map((x) => ({ key: x.id, text: x.name })))
            }
        }
    }, [assetType, state.workspaceId, workspace_items])
    const assetTypeText = React.useMemo(() => {
        if (!assetType) return '';
        switch (assetType.toString()) {
            case TAssetTypes.Report.toString(): return "Report Tecnico"
            case TAssetTypes.SpecialReport.toString(): return "Report di business"
            case TAssetTypes.ScoreCard.toString(): return "Scorecard"
            default: return ''
        }
    }, [assetType])
    return <>
        <div className="h-[100vh] w-[100vw] fixed cursor-pointer" onClick={closePanel} style={{ backdropFilter: 'blur(2px)', backgroundColor: 'rgba(0,0,0,0.25)', zIndex: 999, left: 0, top: 0 }} />
        <FlexCol justifyContent='start' alignItems='start' className=" text-white h-[100vh] fixed bg-slate-700 px-4 pt-4"
            style={{
                zIndex: 9999,
                right: 0,
                top: 0,
                width: width && width * 0.35 > 500 ? '35%' : width && width < 500 ? width * 0.8 : 450,
            }}>
            <div className="flex w-[100%] justify-between items-center">
                <p className='w-[100%]  text-xxl mb-4'>Nuovo {assetTypeText}</p>
                <IconButton onClick={closePanel}>
                    <AiOutlineClose style={{ color: 'white' }} />
                </IconButton>
            </div>
            <DashboardComboBox
                label='Selezione:'
                disabled={isLoading}
                options={assetOptions}
                selectedKey={selectedOption}
                onChange={handleOptionChange}
            />
            <DashboardTextInput
                inputType='text'
                disabled={isLoading}
                onChange={onChange}
                value={state.name}
                name="name"
                label="Nome:"
            />
            <DashboardTextInput
                name="directLink"
                disabled={isLoading}
                value={state.directLink}
                onChange={onChange}
                label="Link diretto:"
                inputType="text"
            />
            <DashboardTextInput
                inputType='text'
                disabled={isLoading}
                onChange={onChange}
                value={selectedOption}
                name="id"
                label="ID:"
            />
            <DashboardTextInput
                inputType='text'
                disabled={isLoading}
                onChange={onChange}
                value={state.workspaceId}
                name="workspaceId"
                label="Workspace Id:"
            />
            <DashboardTextInput
                name="pageName"
                disabled={isLoading}
                value={state.pageName}
                onChange={onChange}
                label="Pagina:"
                inputType="text"
            />
            <DashboardTextInput
                allowUploadFile
                setValue={(value: any) => setState({ ...state, img: value })}
                inputType='text'
                disabled={isLoading}
                onChange={onChange}
                value={state.img}
                name="img"
                label="Immagine:"
            />
            <DashboardTextInput
                inputType='text'
                multiline
                disabled={isLoading}
                onChange={onChange}
                value={state.description}
                name="description"
                label="Tooltip:"
            />
            <DashboardTextInput
                inputType='number'
                disabled={isLoading}
                onChange={onChange}
                value={state.uiorder}
                name="uiorder"
                label="Posizione:"
            />
            <DashboardButton
                onClick={handleSubmit}
                disabled={disabled || isLoading}
                text="Salva"
                style={{ zIndex: 9999 }}
            />
        </FlexCol>
    </>
}
export { AddAssetPanel }