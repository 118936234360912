import  { useState, useCallback } from 'react';
import {
    defaultStaticRanges,
    defaultInputRanges
} from "react-date-range/dist/defaultRanges";
export const useReactDateRangePicker = ({ startRange }) => {
    const staticRangesLabels = {
        "Today": "Oggi",
        "Yesterday": "Ieri",
        "This Week": "Questa settimana",
        "Last Week": "Scorsa settimana",
        "This Month": "Questo mese",
        "Last Month": "Scorso mese"
    };

    const inputRangesLabels = {
        "days up to today": "giorni fino oggi",
        "days starting today": "giorni da oggi"
    };

    function translateRange(dictionary) {
        return (item) =>
            dictionary[item.label] ? { ...item, label: dictionary[item.label] } : item;
    }

    const itStaticRanges = defaultStaticRanges.map(translateRange(staticRangesLabels));
    const itInputRanges = defaultInputRanges.map(translateRange(inputRangesLabels));
    const [range, setRange] = useState(startRange ?? {
        startDate: new Date(),
        endDate: new Date(),
        key: 'selection',
    })
    const handleSelect = useCallback((ranges) => {
        if (ranges) {
            console.log(ranges)
            const { selection,range1 } = ranges;
            const _selection = selection ?? range1;
            console.log(_selection,range1)
            if(!_selection){
                return;
            }
            const { startDate: rangeStartDate, endDate: rangeEndDate } = _selection;
            if (rangeStartDate && rangeEndDate) {
                let startDate = new Date(rangeStartDate);
                let endDate = new Date(rangeEndDate);
                startDate.setHours(0, 0, 0, 0);
                endDate.setHours(23, 59, 59, 999);
                console.log(startDate,endDate)
                const newRange = {
                    key: 'selection',
                    startDate,
                    endDate
                }
                setRange(newRange);
            }
            else if (ranges.selected && ranges.selected.startDate) {
                const newRange = {
                    key: 'selection',
                    startDate: new Date(ranges.selected.startDate),
                    endDate: new Date()
                }
                setRange(newRange);
            }
        }
    }, [range])

    return {
        handleSelect,
        range,
        itStaticRanges,
        itInputRanges,
    }
}