import { Grid, Typography } from '@mui/material'
import React from 'react'
import { settingsContext } from '../../../../store/settingsStore'
import { AssignmentConfigurationItem } from './AssignmentConfigurationItem/AssignmentConfigurationItem'
export const CurrentConfigurationsList: React.FC = () => {
    const { state: contextState } = React.useContext(settingsContext)
    if(contextState.selectedMetrics?.filter((m)=>m.AssignmentsConfiguration).length === 0){
        return null;
    }
    return <>
        <Typography color={"white"} variant='body1' className='!text-xl'>Configurazioni esistenti</Typography>
        <div className="mb-2" />
        <div className="w-[100%] pb-20">
            <Grid
                container
                style={{
                    overflow: 'auto',
                    // height: height && height > 1000 ? height - (60*2 + 150) : 600,
                    paddingBottom: 85,
                }}
                spacing={1}>
                    {contextState.selectedMetrics?.map((m)=>m.AssignmentsConfiguration && <Grid xs={12} sm={12} md={6} lg={6} item><AssignmentConfigurationItem showMetricName variant='contained' automaticNotifications={m.AutomaticNotification} cfg={m.AssignmentsConfiguration} /></Grid>)}
                {/* {contextState.assignmentConfigurations.filter((cfg) => contextState.selectedMetrics?.find((m) => m.Id === cfg.MetricId)).map((cfg) => <Grid xs={12} sm={12} md={6} lg={6} item><AssignmentConfigurationItem showMetricName variant='contained' cfg={cfg} /></Grid>)} */}
            </Grid>
        </div>

    </>
}