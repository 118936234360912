import React from "react";
import {useDashboardsNavigation} from "../../../store/hooks/useDashboardsNavigation";
import {useDashboardsStore} from "../../../store/hooks/useDashboardsStore";
import {useProductDashboardDimensions} from "../../../hooks/useProductDashboardDimensions";
import {ScorecardsColumn} from "../../Columns/ScorecardsColumn";
import {TAssetTypes} from "../../../services/products";
import {columnElementID} from "../../../types/stores/dashboardNavigationStore";
export type ScorecardsSectionProps = {};
export const ScorecardsSection: React.FC<ScorecardsSectionProps> = ({}) => {
  const {productId, pilesDimensions, areaSettings} = useDashboardsNavigation();
  const {groupedProductAssets} = useDashboardsStore();
  return (
    <>
      <ScorecardsColumn
        settings={areaSettings?.SCORECARDS}
        productId={productId ?? ""}
        scorecards={
          groupedProductAssets[TAssetTypes.ScoreCard]
            ?.filter((r) => r.ProductId === productId)
            .sort((a, b) => a.UIOrder - b.UIOrder) ?? []
        }
      />
    </>
  );
};
