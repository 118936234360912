import React from 'react'
import { settingsContext } from '../../../../store/settingsStore'
import { MetricExpandableContainer } from '../MetricExpandableContainer'
import { MetricLeaf } from '../MetricLeaf'
import { TMetric } from '../../../../types/alerts'
import { Typography } from '@mui/material'
import { AssignmentCfgHeaders } from './AssignmentCfgHeaders/AssignmentCfgHeaders'
type Props = {
    summaryValues?: boolean
}
export const CurrentSelectedMetrics: React.FC<Props> = ({ summaryValues }) => {
    const { state: contextState } = React.useContext(settingsContext)
    const { selectedMetrics, selectedProductId, configurationFormData } = contextState;
    const isSingular = selectedMetrics && selectedMetrics?.length === 1
    const mapMetric = (x: TMetric) => {
        if (!summaryValues) {
            return x
        }
        return {
            ...x, AssignmentsConfiguration: {
                ...configurationFormData,
                Metric: x,
                Name:"",
                Scorecard: x.Scorecard,
                CreationDate: "",
                ModifiedDate: "",
                RowVersion: "",
                ScorecardId: x.ScorecardId,
                MetricId: x.Id,
                ProductId: selectedProductId ?? ''
            }
        }
    }
    return <div className='flex flex-col border rounded border-white p-2 w-[100%]'>
        <Typography className="!text-2xl text-white">{summaryValues ? "Riepilogo" : `${isSingular ? "Selezionata" : "Selezionate"} ${selectedMetrics?.length} ${isSingular ? "metrica" : "metriche"}`}</Typography>
        <div id="assignments_list" className="w-[100%]">
            <div className="flex flex-col w-[100%] h-[100%] overflow-auto">
                <AssignmentCfgHeaders />
                {selectedMetrics?.map(mapMetric).map((m) =>
                    (m.Metrics !== undefined && m.Metrics !== null && m.Metrics.length > 0)
                        ?
                        <MetricExpandableContainer
                            metric={m}
                        />
                        :
                        <MetricLeaf
                            isNotSelectable
                            metric={m}
                        />

                )}
            </div>
        </div>
    </div>
}