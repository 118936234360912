import React from 'react'
import { useHeaders } from '../../store/hooks/useHeaders';
import { Page } from '../../components/Page/Page';
import { ODataGridToolbar } from '../../components/ODataGrid/ODataGridToolbar/ODataGridToolbar';
import { PaginationEnum } from '../../store/pager';
import { InputEventsGrid } from '../../components/InputEventsGrid/InputEventsGrid';
type PROPS={}
export const InputEventsPage:React.FC<PROPS> = () => {
    const {inputEventsHeaders,setInputEventHeaders} = useHeaders();
  return (
    <Page>
      <ODataGridToolbar enumStoreKey={PaginationEnum.INPUT_EVENTS} headers={inputEventsHeaders} setHeaders={setInputEventHeaders}/>
      <InputEventsGrid headers={inputEventsHeaders} />
    </Page>
  );
}