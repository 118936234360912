import React from "react";
import { HeaderProps } from "./types";
import { DashboardHeaderText } from "../../DashboardHeader/DashboardHeaderText";
import { ProductPageSection } from "../../../hooks/useProductDashboardDimensions";
import { KPIIcon } from "../Icons/KPIIcon";
export type KPIColumnHeaderProps = {};
export const KPIColumnHeader: React.FC<HeaderProps> = ({settings}) => {
  
  return (
    <DashboardHeaderText
      id={ProductPageSection.KPI}
      icon={<KPIIcon onlyIcon />}
      settings={settings}
      editable={false}
      text="KPI"
    />
  );
};
