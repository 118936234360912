import { Typography } from '@mui/material'
import React from 'react'
type PROPS = {
    description?: string
    preChildren?:React.ReactNode,
    afterChildren?:React.ReactNode
}
export const DetailDescription: React.FC<PROPS> = ({ description,preChildren,afterChildren }) => {
    return <div className='!grow !p-4 !border !border-solid rounded'>
        {preChildren}
        <Typography align='center' className=' !text-3xl'>{description}</Typography>
        {afterChildren}
    </div>
}