import React from 'react'
import { HeaderFilterProps } from '../../types';
import { FormControl, FormControlLabel, IconButton, Switch, } from '@mui/material';
import { HeaderFilterCellFormControl } from '../HeaderFilterCellFormControl/HeaderFilterCellFormControl';
import { useHeaderCell } from '../../hooks/useHeaderCell';
export const BooleanHeaderFilterCell: React.FC<HeaderFilterProps> = ({
    header,enumStoreKey
}) => {
    const {setHeaderFilter,headerCurrentFilter,removeHeaderFilter,isLoading} = useHeaderCell({header,enumStoreKey})

    const handleChange: (event: React.ChangeEvent<HTMLInputElement>, checked: boolean) => void = (event, checked) => {
        setHeaderFilter(header, checked, true)
    }
    return <FormControl className="flex items-center justify-center" variant="outlined" style={{ margin: '0px 0px 2px 0px', height: 55 }}>
        <FormControlLabel control={
            <Switch checked={headerCurrentFilter?.value ?? false} onChange={handleChange} onContextMenu={(e) => { e.preventDefault(); setHeaderFilter(header); }} />
        } label={header.label} labelPlacement='start' />

    </FormControl>
}