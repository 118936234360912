import { IconButton } from '@fluentui/react';
import React from 'react';
type Props = {
    children:React.ReactNode
    style?:React.CSSProperties
    onClick?:(e:React.MouseEvent<HTMLElement>)=>void
}
const HelpIconBtn:React.FC<Props> = ({style,children,onClick}) => {
    return <IconButton onClick={onClick} style={style} className="!p-0 rounded">
{children}
    </IconButton>
}
export {HelpIconBtn}