import React from "react";
import "./styles.css";
import {TProductAsset} from "../../services/products";
import useWindowSize from "../../utility/useWindowSize";
import {VerticalDashboardSection} from "../VerticalDashboardSection/VerticalDashboardSection";
import {DashboardKPI} from "../DashboardGauge/DashboardGauge";
import {TableKpi} from "../KPI/TableKpi";
import {IFrontendSettingsGet} from "../../services/frontendSettings";
const KPIColumn: React.FC<{
  gauges: TProductAsset[];
  tables: TProductAsset[];
  settings?: IFrontendSettingsGet;
  charts?: React.ReactNode;
}> = ({settings, gauges, tables, charts}) => {
  const {width, height} = useWindowSize();
  const containerFlexDirection = React.useMemo(
    () => (width && width > 600 ? "row" : "column"),
    [width]
  );
  return (
    <VerticalDashboardSection title="" settings={settings} id="SBKPI">
      <div className="flex flex-col w-[100%] mb-0 px-1">
        <div
          className="w-[100%] items-stretch flex"
          style={{
            flexDirection: containerFlexDirection,
            marginLeft: width && width > 1000 ? undefined : 65,
            alignItems: "center",
          }}
        >
          {gauges.map((gauge) => (
            <DashboardKPI
              isDefault={gauge.AssetBag.IsDefault}
              containerStyles={{
                maxHeight: 300,
                maxWidth: width ? (width > 600 ? 250 : width - 65) : 250,
                margin:
                  width && width > 600
                    ? "0px auto 0px auto"
                    : "0px 0px 0px 0px",
                display: "flex",
                flexDirection: "column",
                justifyContent: "flex-end",
              }}
              customWidth={250}
              customHeight={200}
              kpiType={"gauge"}
              title={gauge.Name}
              id={gauge.Name}
              value={gauge.AssetBag.Value ?? 0}
              percent={gauge.AssetBag.Value ?? 0}
              formatSegment={(n: any) => n.toLocaleString()}
              formatValue={(n: number) =>
                `${n.toLocaleString()}${gauge.AssetBag.UoM ?? ""}`
              }
              limits={{
                min: gauge.AssetBag.Min ?? 0,
                max: gauge.AssetBag.Max ?? 0,
              }}
            />
          ))}
          {tables.map((kpi) => (
            <TableKpi
              key={`${kpi.ProductId}-tableKpi`}
              sections={kpi.AssetBag.Section ?? []}
              extraStyles={{
                maxHeight: height,
                height: undefined,
                width: width && width > 600 ? "calc(45% - 25px)" : undefined,
                margin:
                  width && width > 600
                    ? "5px 15px 5px 15px"
                    : "0px 0px 0px 65px",
              }}
            />
          ))}
        </div>
        {charts}
      </div>
    </VerticalDashboardSection>
  );
};
export {KPIColumn};
