import React from "react";
import { DashboardFooter } from "../../DashboardFooter/DashboardFooter";
import {
  Checkbox,
  FormControl,
  FormControlLabel,
  InputBase,
  InputLabel,
  MenuItem,
  Select,
  SelectChangeEvent,
} from "@mui/material";
import { DashboardConfigurationIcon } from "../../DashboardConfigurationIcon/DashboardConfigurationIcon";
import { useDashboardsStore } from "../../../store/hooks/useDashboardsStore";
import { hasPermission } from "../../../store/hooks/useUserStore";
import { useUserContext } from "../../../store/hooks/useUserContext";
import { known_actions, know_urls, sbh_area } from "../../../types/userStore";
import { useNavigate } from "react-router-dom";
import { SELECTED_AREA, useQueryValues } from "../../../hooks/useQueryValues";
import { useDashboardsNavigation } from "../../../store/hooks/useDashboardsNavigation";
import { Toolbar } from "../../Toolbar/Toolbar";

export type ProductDashboardFooterProps = {};
export const ProductDashboardFooter: React.FC<
  ProductDashboardFooterProps
> = () => {
  const navigate = useNavigate();
  const { viewDisabledAssets, setViewDisabledAssets } = useDashboardsStore();
  const { productId } = useDashboardsNavigation();
  const { userClaims } = useUserContext();
  
  const _hasConfigPermissions = React.useMemo(()=>hasPermission(
    userClaims,
    know_urls.edit_dashboard_pages,
    sbh_area
  ),[userClaims]);
  const _hasViewDisabledReportsPermissions = React.useMemo(()=>hasPermission(
    userClaims,
    known_actions.view_disabled_reports,
    sbh_area
  ),[userClaims]);
  const { queryValues, encodeValues } = useQueryValues();
  return (
    <DashboardFooter
      showBorder
      leftIcons={_hasViewDisabledReportsPermissions ? <Toolbar position="left" extraStyles={{ marginTop: "5px" }}>
          
          <FormControlLabel
            label="Visualizza disabilitati"
            control={
              <Checkbox
                checked={viewDisabledAssets}
                onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                  setViewDisabledAssets(event.target.checked);
                }}
                inputProps={{ "aria-label": "controlled" }}
                sx={{
                  color: "white",
                  marginLeft: 2,
                  "&.Mui-checked": {
                    color: "white",
                  },
                }}
              />
            }
          />
        </Toolbar> : undefined
      }
      rightIcon={
        _hasConfigPermissions ? (
          <DashboardConfigurationIcon
            iconName="Settings"
            text="Configurazioni"
            onClick={() => {
              navigate(
                encodeValues(
                  know_urls.edit_dashboard_pages,
                  ["selected-area"],
                  { ...queryValues, [SELECTED_AREA]: productId ?? "" }
                )
              );
              // navigate(know_urls.edit_assets)
            }}
          />
        ) : undefined
      }
    />
  );
};
