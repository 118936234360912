import React from "react";
import { TAssignment } from "../../types/assignments";
import { useLocation, useNavigate } from "react-router-dom";
import { assignments_known_urls } from "../../types/userStore";
import {
  ASSIGNMENTS_CONTROLLER,
} from "../../services/assignments";
import { PaginationEnum } from "../../store/pager";
import { isMobile } from "react-device-detect";
import { ODataGrid } from "../ODataGrid/ODataGrid";
import { useHeaders } from "../../store/hooks/useHeaders";
import { useQuery } from "../../utility/useQuery";
import { FilterChips } from "../FilterChips/FilterChips";
import { Spacer } from "../Spacer/Spacer";
import { FilterPresetsIcon } from "../FilterPresetsIcon/FilterPresetsIcon";
import { Card, CardContent, CardHeader } from "@mui/material";
type Props = {
};
export const AssignmentsListNavigator: React.FC<Props> = () => {
  const { assignmentHeaders } = useHeaders();
  const navigate = useNavigate();
  const { pathname } = useLocation();
  const query = useQuery();
  const buildUrl = (a: TAssignment) => `${assignments_known_urls.assignment_detail}?id=${a.Id}`
  const isCurrent = (a: TAssignment) => pathname === assignments_known_urls.assignment_detail && query.get('id') === a.Id.toString()
  const handleAssignmentClick = (a: TAssignment) => {
    navigate(buildUrl(a));
  };
  return (
    <>
      <Spacer
        spacing={2}
        direction="y"
      />
      <FilterPresetsIcon enumKey={PaginationEnum.ASSIGNMENTS} />
      {/* <FilterChips enumKey={PaginationEnum.ASSIGNMENTS}/> */}
      <ODataGrid
        setHeaders={() => { }}
        expands=""
        hideHeaders
        controllerName={ASSIGNMENTS_CONTROLLER}
        // state={paginationState.pagination.ASSIGNMENTS}
        // setState={(pagination) => paginationDispatch({ type: "SET_PAGINATION", payload: { key: PaginationEnum.ASSIGNMENTS, pagination } })}
        // currentFilters={assignmentsFilters}
        // currentSortings={assignmentSortings}
        // currentItems={assignments}
        enumStoreKey={PaginationEnum.ASSIGNMENTS}
        // getPagedItems={getPagedAssignments}
        // handleRemoveSort={handleRemoveSort}
        // headerCurrentFilter={headerCurrentFilter}
        // headerCurrentSort={headerCurrentSort}
        headers={assignmentHeaders}
        // isLoading={isLoading}
        // lastCallFilters={paginationState.pagination.ASSIGNMENTS.LAST_CALL_FILTERS}
        // lastCallSortings={paginationState.pagination.ASSIGNMENTS.LAST_CALL_SORTINGS}
        // localFilter={localFilter}
        // removeHeaderFilter={removeHeaderFilter}
        // setHeaderFilter={setHeaderFilter}
        // setLocalFilter={handleSetLocalFilter}
        //   showFilters={paginationState.pagination.ASSIGNMENTS.showFilters}
        renderItem={(a: TAssignment) => (
          <div

          >
            <Card
              key={a.Id}
              onClick={() => handleAssignmentClick(a)}
              className={`cursor-pointer  ${isMobile ? "" : "hover:!bg-gray-100"} ${isCurrent(a) ? "!bg-gray-200" : ''} `} 
              style={{ height: 200,width:'calc(100% - 20px)',margin:'4px 10px' }}
            >
              <CardContent>
                <div className="flex w-[100%]">
                  <p className="text-lg p-0 m-0 text-left" style={{width:100}}>{a.Id}</p>
                  <p className={`text-lg py-1 m-0 text-center ${isCurrent(a) ? "underline font-bold " : ''}`} style={{width:'calc(100% - 100px)',marginRight:100}}>[{a.Polo}]</p>
                </div>
                <p className={`text-lg py-1 m-0 text-center w-[100%] ${isCurrent(a) ? "underline font-bold " : ''}`}>{a.BuildingName}</p>
                <p className={`text-base py-1 m-0 text-center w-[100%]`}>{a.Description}</p>
              </CardContent>
            </Card>
          </div>
        )}
      />
    </>
  );
};
