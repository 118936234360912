import React from "react";
import { useDashboardsStore } from "../../../store/hooks/useDashboardsStore";
import { usePowerBiAuth } from "./usePowerBiAuth";
import { TAssetTypes, TProductAsset } from "../../../services/products";
import { usePowerBIContext } from "../../../store/hooks/usePowerBiContext";
import { GetPowerBiItemInfos } from "../../../services/powerBi";
import { emergency_mails } from "../../../utility/emergency_mails";
import { useUserContext } from "../../../store/hooks/useUserContext";

export interface IPowerBiEmbedError {
  detail: {
    detailedMessage: string;
    message: string;
    level: number;
    errorCode: string;
    technicalDetails: {
      requestId: string;
      errorInfo: any;
    };
  };
}
export interface I_BIReport {
  datasetId: string;
  datasetWorkspaceId: string;
  embedUrl: string;
  id: string;
  name: string;
  isOwnedByMe: boolean;
  assetType?: TAssetTypes;
  reportType?: string;
}
export function usePowerBiHelperPreLoad() {
  const { contextState } = useDashboardsStore();
  const { groupedProductAssets, isLoaded: assetsLoaded } = contextState;
  const bi_assets = React.useMemo(
    () => [
      ...(groupedProductAssets[TAssetTypes.Report] ?? []),
      ...(groupedProductAssets[TAssetTypes.SpecialReport] ?? []),
      ...(groupedProductAssets[TAssetTypes.ScoreCard] ?? []),
    ],
    [groupedProductAssets]
  );
  const known_workspaces = React.useMemo(
    () =>
      new Set(
        bi_assets
          .filter((a) => a.AssetBag.WorkspaceId)
          .map((x) => x.AssetBag.WorkspaceId)
      ),
    [bi_assets]
  );
  const know_ids = React.useMemo(
    () =>
      new Set(
        bi_assets
          .filter((a) => a.AssetBag.ReportId)
          .map((x) => x.AssetBag.ReportId)
      ),
    [bi_assets]
  );
  // const known_workspaces = React.useMemo(() => ['47d85e5f-8a3a-4b72-b9ab-928035198e28'], [productAssets])
  const {
    powerBiAccessToken,
    isLoading,
    setIsLoading,
    setAccessToken,
    embedAccess,
    setEmbedAccess,
    state: power_bi_context_state,
    dispatch: power_bi_context_dispatch,
  } = usePowerBIContext();
  const {account} = useUserContext();
  const { getAccessToken, loginState, setLoginState } = usePowerBiAuth({
    setAccessToken,
    catchError: (err: any, ssoError: boolean) => {
      console.log(err.errorCode);
      let msg = "";
      switch (err.errorCode) {
        case "consent_required": {
          msg = "Consenti i popup/rendirizzamenti come mostrato nell'immagine";
          break;
        }
        case "user_cancelled": {
          msg = "Operazione annullata";
          break;
        }
        default: {
          msg = "Qualcosa è andato storto, contatta l'amministratore.";
        }
      }

      setLoginState({
        ...loginState,
        sso: {
          success: !ssoError,
          error: ssoError,
        },
        popup: {
          success: false,
          error: true,
        },
      });
    },
  });

  React.useEffect(() => {
    const getAssetIsAccessible = async (asset: TProductAsset) => {
      if(asset.AssetBag.DirectLink){
        return true;
      }
      if (asset.AssetBag.ReportId && powerBiAccessToken) {
        return GetPowerBiItemInfos(
          asset.AssetType,
          asset.AssetBag.ReportId,
          powerBiAccessToken
        ).then((res) => {
          return res.status >= 200 && res.status < 400;
        });
      }
    };
    const findReportsAndScorecardsForAllWs = async (
      powerBiAccessToken: string
    ) => {
      let workspace_items: { [key: string]: I_BIReport[] } = {};
      let accessible_items: { [key: string]: boolean } = {};
      let unaccessible_items: { [key: string]: boolean } = {};
      setIsLoading(true);
      await Promise.all(
        bi_assets.map(async (a) => {
          if (a.AssetBag.ReportId || a.AssetBag.DirectLink) {
            await getAssetIsAccessible(a)
              .then((accessible) => {
                if (a.AssetBag.ReportId || a.AssetBag.DirectLink) {
                  accessible_items = {
                    ...accessible_items,
                    [a.AssetBag.ReportId ? a.AssetBag.ReportId : a.AssetBag.DirectLink ? a.AssetBag.DirectLink : a.Id]: accessible as boolean,
                  };
                }
              })
              .catch((err) => console.log(err));
            // .finally(() => {
            // });
          }
        })
      );
      know_ids.forEach((id) => {
        if (id && !Object.keys(accessible_items).includes(id)) {
          unaccessible_items[id] = false;
        }
      });
      power_bi_context_dispatch({
        type: "SET_STATE",
        payload: {
          ...power_bi_context_state,
          embedAccess: {
            ...embedAccess,
            ...accessible_items,
            ...unaccessible_items,
          },
          workspace_items,
          isLoading: false,
        },
      });
      setEmbedAccess({
        ...embedAccess,
        ...accessible_items,
        ...unaccessible_items,
      });
    };
    const isEmergencyMail = emergency_mails.includes(account.username);
    if (isEmergencyMail) {
      return;
    }
    if (!powerBiAccessToken) {
      getAccessToken(true, "analysis");
    } else {
      if (
        !isLoading &&
        assetsLoaded &&
        known_workspaces &&
        know_ids &&
        (!embedAccess ||
          Array.from(know_ids).some(
            (x) => x && !Object.keys(embedAccess).includes(x)
          ))
      ) {
        findReportsAndScorecardsForAllWs(powerBiAccessToken);
      }
    }
  }, [powerBiAccessToken, bi_assets, assetsLoaded]);

  return {
    embedAccessIsLoading: isLoading,
  };
}
