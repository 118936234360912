import { ProductPageSection } from "../../../hooks/useProductDashboardDimensions";
import { TTooltip } from "../../../types/Tooltip";

export const iconButtonClassNames = "bg-white hover:bg-white px-4 py-1 rounded cursor w-[30px] h-[30px]";
export const iconTextClassNames = "text-slate-800 hover:text-slate-800 text-dashboard-side-icon font-bold";
export const iconClassNames = "text-slate-800 hover:text-slate-800 font-bold text-dashboard-side-icon";
export interface IconProps {
    // el:React.ReactNode
    onlyIcon?:boolean
}

export interface DashboardIconProps extends IconProps {
    icon:React.ReactNode,
    tooltip:TTooltip
    id:ProductPageSection
}