import React from 'react';
import { TProductAsset } from '../../../services/products';
import { TPileSize, TSize } from '../../../types/general';
import { Pile } from '../Pile';
type ScorecardPileProps = {
    productId: string
    asset: TProductAsset
    pileSize?: TPileSize,
}
const TutorialPile: React.FC<ScorecardPileProps> = ({ productId, asset, pileSize }) => {
    const handleClick = () => {
        if (asset?.Url) {
            window.open(asset?.Url)
        }
    }
    const getImg = React.useCallback((imgName?: string, imgRows?: number) => {
        const height = imgRows ? `${imgRows * 10}%` : '100%'
        if (!imgName) {
            return <img src="" style={{ height, objectFit: 'fill' }} alt={`Image for ${imgName} Missing`} />
        }
        const isUrl = imgName?.startsWith("http");
        if (isUrl) {
            return <img style={{ height, objectFit: 'fill', borderRadius: '8px 8px 0px 0px', margin: 0 }} src={imgName} alt="Image Missing" />
        }
        try {
            return <img style={{ height, objectFit: 'fill', borderRadius: '8px 8px 0px 0px', margin: 0 }} src={imgName ? require('../../../images/scorecards/' + imgName) : ''} alt="Image Missing" />
        } catch (error) {
            console.log(error)
            return <img src="" style={{ height, objectFit: 'fill' }} alt={`Image for ${imgName} Missing`} />
        }
    }, [asset.AssetBag])
    const assetKey = React.useMemo(()=>{
        return asset.Id ? asset.Id.toString() : asset.Url ?? ''
    },[asset])
    return <Pile
        id={assetKey}
        img={getImg(asset?.ThumbnailsImageUrl)}
        title={asset.Name}
        titleCustomBgColor="rgb(51,65,85)"
        productId={productId}
        edit={false}
        className={`relative shadow-xl text-white hover:text-white m-2 cursor-pointer rounded-lg border border-gray-50 bg-white`}
        tooltip={{ id: asset.AssetBag?.ReportId ?? asset?.Name.toString(), text: asset.Description ?? '' }}
        key={assetKey}
        size={pileSize ?? "x4"}
        titleSize='xl'
        onClick={handleClick}
        asset={asset}
    />
}
export { TutorialPile }