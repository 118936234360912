import { createCtx } from "../store/general";
import { IError } from "../types/general";


export type TState = {
  error?: IError
};

// usage
const initialState: TState = {
};
type AppState = TState;
type Action = { type: "SET_STATE"; payload: TState }
  | { type: "SET_ERROR"; payload?: IError };

function reducer(state: AppState, action: Action): AppState {
  switch (action.type) {
    case "SET_STATE":
      return action.payload;
    case "SET_ERROR":
      return { ...state, error: action.payload }
    default:
      return state;
  }
}

export const [globalContext, GlobalProvider] = createCtx(reducer, initialState);
