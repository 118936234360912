export function arraysAreSame(v1: any[], v2: any[], compareFunc: (x1: any, x2: any) => boolean) {
    if (v1.length !== v2.length) return false;
    for (let i = 0; i < v1.length; i++) {
        if (!compareFunc(v1[i], v2[i])) return false;
    }
    return true;
}
export const listValuesAreEquals = (v1: any[], v2: any[], checkValue = (x1: any, x2: any) => x1 === x2) => {
    if (v1.length !== v2.length) {
        return false;
    }
    for (let i = 0; i < v1.length; i++) {
        if (!checkValue(v1[i], v2[i])) {
            return false;
        }
    }
    return true;
}