import React from "react";
import { useDashboardsStore } from "../../store/hooks/useDashboardsStore";
import { TAssetTypes, TProductAsset } from "../../services/products";
import { FlexCol } from "../Flex/FlexCol";
import { useQuery } from "../../hooks/useQuery";
import { known_products } from "../../types/userStore";
import { useNavigate } from "react-router";
import { useNavigationContext } from "../../store/hooks/useNavigationContext";
import { ScorecardSvg } from "../Columns/Icons/ScorecardsIcon";
import { SpecialReportSvg } from "../Columns/Icons/SpecialReportIcon";
import { ReportSvg } from "../Columns/Icons/ReportIcon";
import { usePowerBIContext } from "../../store/hooks/usePowerBiContext";
import { usePowerBiHelperPreLoad } from "../powerBi/hooks/usePowerBiHelperPreLoad";
import { AiOutlineArrowRight } from "react-icons/ai";
import { IconButton } from "@mui/material";
type Props = {};
const AllReportsListNavigator: React.FC<Props> = () => {
  const query = useQuery();
  const { products, editableAssets } = useDashboardsStore();
  usePowerBiHelperPreLoad();
  const { embedAccess } = usePowerBIContext();
  const { setSidebarIsOpen } = useNavigationContext();
  const sortByProduct = React.useCallback(
    (a: TProductAsset, b: TProductAsset) =>
      a.ProductId > b.ProductId ? 1 : -1,
    []
  );
  const sortAssets = React.useCallback(
    (a: TProductAsset, b: TProductAsset) => (a.UIOrder > b.UIOrder ? 1 : -1),
    []
  );
  const filterReports = React.useCallback(
    (a: TProductAsset) =>
      embedAccess &&
      a.AssetBag.ReportId &&
      embedAccess[a.AssetBag.ReportId] &&
      (a.AssetType.toString() === TAssetTypes.Report.toString() ||
        a.AssetType.toString() === TAssetTypes.SpecialReport.toString()),
    [embedAccess]
  );
  const filterScorecards = React.useCallback(
    (a: TProductAsset) =>
      embedAccess &&
      a.AssetBag.ReportId &&
      embedAccess[a.AssetBag.ReportId] &&
      a.AssetType.toString() === TAssetTypes.ScoreCard.toString(),
    [embedAccess]
  );
  const navigate = useNavigate();
  const reports = React.useMemo(
    () => editableAssets.filter(filterReports).sort(sortAssets),
    [editableAssets, filterReports, sortAssets]
  );
  const scorecards = React.useMemo(
    () => editableAssets.sort(sortAssets).filter(filterScorecards),
    [editableAssets, filterScorecards, sortAssets]
  );
  const groupedAssets: { [key: string]: TProductAsset[] } =
    React.useMemo(() => {
      let result: { [key: string]: TProductAsset[] } = {};
      if (!query.get("type") || query.get("type") === "report") {
        reports.sort(sortByProduct).forEach((r) => {
          const productName =
            products.find((p) => p.ProductId === r.ProductId)?.Name ?? "";
          if (result[productName] && result[productName].length > 0) {
            result[productName] = [...result[productName], r];
          } else {
            result[productName] = [r];
          }
        });
      }
      if (!query.get("type") || query.get("type") === "scorecard") {
        scorecards.sort(sortByProduct).forEach((r) => {
          const productName =
            products.find((p) => p.ProductId === r.ProductId)?.Name ?? "";
          if (result[productName] && result[productName].length > 0) {
            result[productName] = [...result[productName], r];
          } else {
            result[productName] = [r];
          }
        });
      }
      return result;
    }, [products, reports, scorecards, sortByProduct]);
  const getAssetTypeQueryValue = (r: TProductAsset) => {
    if (r.AssetType.toString() === TAssetTypes.ScoreCard.toString()) {
      return "scorecard";
    }
    return "report";
  };
  const targetUrl = React.useCallback(
    (r: TProductAsset) =>
      `/report?type=${getAssetTypeQueryValue(r)}&area=${known_products[
        r.ProductId as keyof typeof known_products
      ].dashboard_url.slice(1)}&Id=${r.AssetBag.ReportId}&wsId=${
        r.AssetBag.WorkspaceId
      }&name=${r.Name}`,
    []
  );
  const handleClick = (e: React.MouseEvent<HTMLElement>, r: TProductAsset) => {
    navigate(targetUrl(r));
    setSidebarIsOpen(false); //i suppose we render this component inside the disposable sidebar, maybe use Props?
  };
  // React.useEffect(()=>{
  //     return () => setSidebarIsOpen(false);   //i suppose we render this component inside the disposable sidebar, maybe use Props?
  // },[])
  const assetIcon = (r: TProductAsset) => {
    const iconStyles: React.CSSProperties = {
      position: "relative",
      height: 30,
      width: 30,
      fontSize: 24,
      backgroundColor: "white",
      objectFit: "contain",
      fill: "rgb(45,56,76)",
      stroke: "rgb(45,56,76)",
      color: "rgb(45,56,76)",
    };
    switch (r.AssetType.toString()) {
      case TAssetTypes.ScoreCard.toString():
        return (
          <div style={iconStyles}>
            <ScorecardSvg />
          </div>
        );
      case TAssetTypes.Report.toString():
        return (
          <div style={iconStyles}>
            <ReportSvg />
          </div>
        );
      case TAssetTypes.SpecialReport.toString():
        return (
          <div style={iconStyles}>
            <SpecialReportSvg />
          </div>
        );
      default:
        return <></>;
    }
  };
  
  return (
    <FlexCol
      className="w-[100%] max-h-[99%] overflow-auto pb-20 pt-1"
      alignItems="center"
    >
      {Object.entries(groupedAssets).length === 0 && (
        <p className="mt-10 text-3xl text-white text-center">
          Nessun report trovato.
        </p>
      )}
      {Object.entries(groupedAssets).map((entry) => (
        <div className="w-[100%] px-2 mb-4">
          <p className="w-[100%] text-left text-zinc-800 font-bold text-3xl">
            {entry[0]}
          </p>
          {entry[1].map((r) => (
            <div className="flex w-[100%] justify-between items-baseline">
              <p
                onClick={(e: React.MouseEvent<HTMLElement>) =>
                  handleClick(e, r)
                }
                className={`text-2xl hover:!text-amber-500 cursor-pointer ${
                  query.get("Id") === r.AssetBag.ReportId ? "underline" : ""
                }`}
              >
                {r.Name}
              </p>
              {assetIcon(r)}
            </div>
          ))}
        </div>
      ))}
      <IconButton
        style={{
          zIndex: 100,
          height: 30,
          width: 30,
          position: "absolute",
          right: 5,
          top:5
        }}
        className="!rounded !bg-intesa-green hover:bg-intesa-green"
        onClick={()=>setSidebarIsOpen(false)}
      >
        <AiOutlineArrowRight className="text-white text-4xl" />
      </IconButton>
    </FlexCol>
  );
};
export { AllReportsListNavigator };
