import { graphConfig } from "../authConfig";
/**
 * Attaches a given access token to a Microsoft Graph API call. Returns information about the user
 */


export async function callMsGraph(accessToken: string) {
    const headers = new Headers();
    const bearer = `Bearer ${accessToken}`;

    headers.append("Authorization", bearer);

    const options = {
        method: "GET",
        headers: headers
    };

    return fetch(graphConfig.graphMeEndpoint, options)
        .then(response => response.json())
        .catch(()=>{});
}


export async function getUserProfileImageUrl(accessToken: string) {
    const headers = new Headers();
    const bearer = `Bearer ${accessToken}`;

    headers.append("Authorization", bearer);
    const options = {
        method: "GET",
        headers: headers
    };

    let url = `${graphConfig.graphMeEndpoint}/photos/48x48/$value`;

    return fetch(url, options)
        .then(response => response.blob()).catch(() => { })
        .then(blob => blob?.arrayBuffer()).catch(() => { });
}
