import React from 'react';
const EnvironmentEvidence: React.FC = () => {
    let url = document.URL;
    let isMainPage = (url.split('/')[3] === '');
    const env = () => {
        switch (url.split('/')[2]) {
            case "localhost:3000": return 'LOCAL';
            case "smartbs07.azurewebsites.net": return "TEST";
            case "smartbt07.azurewebsites.net": return "DEV";
            default: return '';
        }
    }
    const [s, setS] = React.useState(env());
    React.useEffect(() => {
        setS(env());
    }, [window.location.pathname])
    if (!s) return null;
    const style: React.CSSProperties = {
        zIndex: 99999,
        position:'fixed',
        top: isMainPage ? 5 : undefined,
        bottom: isMainPage ? undefined : 5,
        right: isMainPage ? 250 : 300,
    }

    return <div style={{ ...style,  }}>
        {/* return <div > */}
        <p className="text-2xl  text-center font-bold text-white">{s}</p>
    </div>
}
export { EnvironmentEvidence }