import axios from "axios"

export interface IUserNotification {
    Title:string
    Text:string
}

export interface IUserNotificationGet extends IUserNotification {
        Id: number,
        Start: string,
        End: string,
        RowVersion: string,
        CreatedBy: string,
        LastUpdateBy: string,
        CreationDate: string,
        LastUpdateDate: string     
}

export interface IUserNotificationPost extends IUserNotification {
    Start:string
    End:string
}

export const getUserNotifications = async (date:string)=>{
    return axios.get(`${process.env.REACT_APP_SERVER_URL}/UserNotification?$orderby=Start desc&$filter=(Start le '${date}' and End ge '${date}')&$skip=0&$top=100`).then((res) => res.data as IUserNotificationGet[])
}

export const addUserNotification = async (n:IUserNotificationPost)=>{
    return axios.post(`${process.env.REACT_APP_SERVER_URL}/UserNotification`,n).then((res) => res.data)
}


export const editUserNotification = async (n:IUserNotificationPost)=>{
    return axios.put(`${process.env.REACT_APP_SERVER_URL}/UserNotification`,n).then((res) => res.data)
}

export const deleteUserNotification = async (n:IUserNotificationGet)=>{
    return axios.delete(`${process.env.REACT_APP_SERVER_URL}/UserNotification/${n.Id}`).then((res) => res.data)
}