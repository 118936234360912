import React from "react"
import { sidebarsContext } from "../sidebars"
import { useIsMobile } from "../../utility/useIsMobile";

export const useSidebars = () => {
    const { state, dispatch } = React.useContext(sidebarsContext);
    const { isMobile, sidebarWidth, bottombarHeight } = useIsMobile();
    const sidebarIsOpen = !isMobile && state.sidebarWidth && state.sidebarWidth > 0
    const bottombarIsOpen = isMobile && state.bottombarHeight && state.bottombarHeight > 0
    const toggleSidebar = React.useCallback(() => dispatch({ type: "SET_STATE", payload: { ...state, sidebarWidth: sidebarIsOpen ? 0 : sidebarWidth } }), [dispatch, sidebarIsOpen, sidebarWidth, state])
    const toggleBottombar = React.useCallback(() => dispatch({ type: "SET_STATE", payload: { ...state, bottombarHeight: bottombarIsOpen ? 0 : bottombarHeight } }), [bottombarHeight, bottombarIsOpen, dispatch, state])
    return {
        isMobile,
        sidebarIsOpen,
        bottombarIsOpen,
        toggleSidebar,
        toggleBottombar,
        defaultSidebarWidth:sidebarWidth,
        defaultBottombarHeight:bottombarHeight,
        state,
        dispatch,
    }
}