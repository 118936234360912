import React from "react";
import { IEmbedState, powerbiContext } from "../powerBiStore";
import { TProductAsset } from "../../services/products";
import { GetPowerBiItemInfos } from "../../services/powerBi";

export const usePowerBIContext = () => {
  const { state, dispatch } = React.useContext(powerbiContext);
  const {
    workspace_items,
    isLoading,
    embedAccess,
    embedErrors,
    embedState,
    accessToken: powerBiAccessToken,
  } = state;
  const setIsLoading = (payload: boolean) =>
    dispatch({ type: "SET_IS_LOADING", payload });
  const setAccessToken = (payload: string) =>
    dispatch({ type: "SET_ACCESS_TOKEN", payload });
  const setEmbedAccess = React.useCallback(
    (payload: { [key: string]: boolean }) =>
      dispatch({ type: "SET_EMBED_ACCESS", payload }),
    [dispatch]
  );
  const setEmbedState = React.useCallback(
    (payload: IEmbedState) =>
      dispatch({ type: "SET_EMBED_STATE", payload }),
    [dispatch]
  );

  const getAssetIsAccessible = React.useCallback(
    async (asset: TProductAsset) => {
      if (asset.AssetBag.ReportId && powerBiAccessToken) {
        return GetPowerBiItemInfos(
          asset.AssetType,
          asset.AssetBag.ReportId,
          powerBiAccessToken
        ).then((res) => {
          return res.status >= 200 && res.status < 400;
        });
      }
    },
    [powerBiAccessToken]
  );
  const assetIsAccessible = React.useCallback((asset: TProductAsset) => {
    if(asset.AssetBag.DirectLink){
      return true;
    }
    return asset.AssetBag.ReportId !== undefined && asset.AssetBag.ReportId !== "" && embedAccess && embedAccess[asset.AssetBag.ReportId] === true
  }, [embedAccess])
  return {
    state,
    isLoading,
    embedAccess,
    embedErrors,
    powerBiAccessToken,
    workspace_items,
    embedState,
    dispatch,
    setAccessToken,
    setEmbedAccess,
    setIsLoading,
    getAssetIsAccessible,
    assetIsAccessible,
    setEmbedState,
  };
};
