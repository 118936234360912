import React from 'react'
import { HeaderFilterProps } from '../../types'
import { useHeaderCell } from '../../hooks/useHeaderCell'
import { MenuItem, OutlinedInput, Select, SelectChangeEvent, styled } from '@mui/material'
import { paginationContext } from '../../../../store/pager'
import { TAssignment } from '../../../../types/assignments'
import { HeaderFilterCellFormControl } from '../HeaderFilterCellFormControl/HeaderFilterCellFormControl'
const StyledSelect = styled(Select)({
    borderRadius:0
})
export const ClosureReasonFilterCell:React.FC<HeaderFilterProps> = ({
    header,enumStoreKey
}) => {
    const {setHeaderFilter,headerCurrentFilter,removeHeaderFilter,isLoading} = useHeaderCell({header,enumStoreKey})

    const handleChange: React.ChangeEventHandler<HTMLInputElement> = (event: SelectChangeEvent<string[]>) => {
        console.log(event.target.value)
        const value = event.target.value === 'clear' ? [] : typeof event.target.value === 'string' ? event.target.value.split(',') : event.target.value;
        setHeaderFilter(header, value)
    }
    const { state } = React.useContext(paginationContext);
    const [availableReasons, setAvailableReasons] = React.useState<string[]>([]);
    React.useEffect(() => {
        let result: string[] = [];
        state.pagination.ASSIGNMENTS.items.forEach((a: TAssignment) => {
            if (!result.includes(a.ClosureReason)) {
                result.push(a.ClosureReason)
            }
        })
        if (result.length > availableReasons.length) {
            setAvailableReasons(result)
        }
    }, [state.pagination.ASSIGNMENTS.items])
    return <HeaderFilterCellFormControl
        header={header}
        input={
            <StyledSelect
                onContextMenu={(e)=>{
                    e.preventDefault();
                    removeHeaderFilter(header)
                }}
                label='Tags'
                disabled={isLoading}
                value={headerCurrentFilter ? headerCurrentFilter.value as string[] : []}
                input={<OutlinedInput id={header.label} label={header.label} onChange={handleChange} />}
                placeholder='Tags'
            >
                {availableReasons.map((s) => <MenuItem value={s}>{s}</MenuItem>)}
                {/* <MenuItem key='clear' value='clear'>Rimuovi selezione</MenuItem> */}
            </StyledSelect>} />;
}