import React from 'react';
import { DashboardHeaderText } from '../../DashboardHeader/DashboardHeaderText';
import { ReportIcon } from '../Icons/ReportIcon';
import { useDashboardsStore } from '../../../store/hooks/useDashboardsStore';
import { SearchAssets } from '../../../store/dashboardsStore';
import { HeaderProps } from './types';
import { ProductPageSection } from '../../../hooks/useProductDashboardDimensions';
import { AddAssetPanel } from '../../AddAssetPanel/AddAssetPanel';

const ReportColumnHeader: React.FC<HeaderProps> = ({ settings }) => {
    const {
        search, contextDispatch,addMode
    } = useDashboardsStore();
    const setSearchValue = (s: string) => contextDispatch({
        type: "SET_SEARCH",
        payload: {
            ...search,
            [SearchAssets.REPORTS]: s
        }
    })
    const handleSearchChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        setSearchValue(e.target.value)
    }
    const handleClosePanel = (e: any) => {
        contextDispatch({ type: 'TOGGLE_ADD_MODE', payload: "reports" })

    }
    return <><DashboardHeaderText
        id={ProductPageSection.REPORTS}
        icon={<ReportIcon onlyIcon/>}
        settings={settings}
        editable={true}
        headerType={"reports"}
        searchKey={SearchAssets.REPORTS}
        text="Report Tecnici"
        setSearchValue={setSearchValue}
        handleSearchChange={handleSearchChange}
    />
    {addMode.REPORTS && <AddAssetPanel
        type="reports"
        closePanel={handleClosePanel}
    />}
</>
}
export { ReportColumnHeader }