import { FontIcon, IconButton } from "@fluentui/react";
import React from "react";
import {
  callDeleteAsset,
  TAssetTypes,
  TProductAsset,
} from "../../services/products";
import { useDashboardsStore } from "../../store/hooks/useDashboardsStore";
import { TPileSize, TSize } from "../../types/general";
import { TTooltip } from "../../types/Tooltip";
import useWindowSize from "../../utility/useWindowSize";
import { FlexCol } from "../Flex/FlexCol";
import { PileWrapper } from "./PileWrapper/PileWrapper";
import { HelpIcon } from "../HelpIcon/HelpIcon";
import { HelpIconBtn } from "../HelpIconBtn/HelpIconBtn";
import { MiniLoadingSpinner } from "../spinner/MiniLoadingSpinner/MiniLoadingSpinner";
import { DashboardButton } from "../DashboardButton/DashboardButton";
import { EditAssetPanel } from "../EditAssetPanel/EditAssetPanel";
import { MdDisabledVisible } from "react-icons/md";
import { HelpPopup } from "../HelpPopup/HelpPopup";
import { isDesktopWidth } from "../../pages/MainDashboard/MainDashboard";
export type PileProps = {
  id: string;
  size: TPileSize;
  productId: string;
  className?: string;
  onClick?: () => void;
  extraStyles?: React.CSSProperties;
  tooltip?: TTooltip;
  showHelp?: boolean;
  title: string;
  img?: React.ReactElement;
  titlePosition?: "up" | "down";
  titleCustomStyles?: React.CSSProperties;
  titleSize?: TSize;
  imgRows?: number;
  children?: React.ReactElement;
  edit?: boolean;
  asset?: TProductAsset;
  customColor?: boolean;
  disabled?: boolean;
  targetUrl?: string;
  openInNewTab?: boolean;
  titleCustomBgColor?: string;
  loadingBiPreLoad?: boolean;
  customShadow?: string;
  customAspectRatio?: number;
  deletable?: boolean;
};
const Pile: React.FC<PileProps> = ({
  id,
  disabled,
  customColor,
  titleCustomStyles,
  titleCustomBgColor,
  productId,
  asset,
  edit,
  deletable,
  titleSize,
  titlePosition,
  size,
  className,
  img,
  openInNewTab,
  onClick,
  tooltip,
  extraStyles,
  showHelp,
  title,
  imgRows,
  targetUrl,
  loadingBiPreLoad,
  customShadow,
  customAspectRatio,
}) => {
  const { width, height } = useWindowSize();
  const { deleteAssetOnStore } = useDashboardsStore();
  const el = document.getElementById(id);
  const tooltipWidth = React.useMemo(() => {
    if (!el) return 100;
    return el.getBoundingClientRect().width - 20;
  }, [el]);
  const pileWidth = React.useMemo(() => {
    //m-2 = 0.5rem * 2
    switch (size) {
      case "unset":
        return isDesktopWidth(width) ? "98%" : "95%";
      case "x1":
        return "calc(100% - 1rem)"; //1
      case "x2":
        return "calc(50% - 1rem)"; //2
      case "x3":
        return "calc(33.33% - 1rem)"; //3
      case "x4":
        return "calc(25% - 1rem)"; //4
      default:
        return "calc(25% - 1rem)"; //4
    }
  }, [width, size]);
  const pileHeight = React.useMemo(() => {
    //m-2 = 0.5rem
    // switch (size) {
    //     default: return height ? height * 0.2 : 250
    // }
    if (!pileWidth) return 250;
    return `${pileWidth.substring(0, 4)}((${pileWidth.substring(
      5,
      pileWidth.length - 1
    )})*0.25)`;
  }, [height, size]);
  const titleSizeClass = React.useMemo(() => {
    if (!titleSize) return "text-xl";
    return `text-${titleSize}`;
  }, [titleSize]);
  const s = "";
  const [state, setState] = React.useState({
    isTooltipOpen: false,
    isEditPanelOpen: false,
    isDeleteConfirmOpen: false,
    isLoading: false,
  });
  //'rgb(51,53,75)' purple
  //rgb(40,75,75) green

  const defaultClassNames = React.useMemo(
    () =>
      disabled
        ? "m-1 p-1 cursor-default rounded-lg border-slate-400 border"
        : "m-1 p-1 cursor-pointer rounded-lg border-slate-400 border hover:!bg-pile-hover hover:!text-white",
    [disabled]
  );
  const defaultBg = React.useMemo(
    () => (disabled ? "grey" : "rgb(100,116,139)"),
    [disabled]
  );
  const defaultBorderColor = React.useMemo(
    () => (disabled ? "lightgrey" : "rgb(110,126,149)"),
    [disabled]
  );
  return (
    <>
      <div style={{ display: "none" }} id={id} />
      <PileWrapper
        id={`wrapper-${id}`}
        disabled={disabled}
        openInNewTab={openInNewTab}
        targetUrl={targetUrl}
        className={className ?? defaultClassNames}
        style={{
          position: "relative",
          backgroundColor: customColor ? undefined : defaultBg,
          border: customColor ? undefined : defaultBorderColor,
          boxShadow: customShadow ?? "5px 8px 5px rgba(71,85,105,0.8)",
          margin: width && width > 600 ? undefined : "10px auto",
          aspectRatio: customAspectRatio ?? 16 / 9,
          maxHeight: customAspectRatio ? undefined : 300,
          width: pileWidth,
          // minHeight: pileHeight > 200 ? pileHeight : 200,
          minHeight: 200,
          // height: pileHeight,
          ...extraStyles,
        }}
        onClick={onClick}
      >
        {loadingBiPreLoad && (
          <MiniLoadingSpinner
            style={{
              position: "absolute",
              left: 5,
              top: 5,
              width: 30,
              height: 30,
              color:
                asset?.AssetType === TAssetTypes.ScoreCard
                  ? "#d97706"
                  : "white",
            }}
          />
        )}
        {!loadingBiPreLoad && disabled && (
          <MdDisabledVisible
            style={{
              position: "absolute",
              left: 5,
              top: 5,
              width: 40,
              height: 40,
              color:
                asset?.AssetType === TAssetTypes.ScoreCard
                  ? "#d97706"
                  : "white",
            }}
          />
        )}
        <div
          id={`pile-img-${id}`}
          className="flex flex-col h-[100%] hover:!text-white"
        >
          {titlePosition === "up" ? (
            <>
              {/* < p style={{color:'rgb(230, 153, 0)'}} className={`${titleSizeClass} text-amber-500 font-bold text-center mb-auto`} >{title}</p> */}
              <p
                style={
                  titleCustomStyles ?? {
                    position: "absolute",
                    top: -2,
                    paddingBottom: 5,
                    paddingTop: 5,
                    width: "calc(100% + 4px)",
                    left: -2,
                    borderRadius: "4px 4px 0px 0px",
                    backgroundColor: titleCustomBgColor ?? "rgb(51,65,85)",
                  }
                }
                className={`${titleSizeClass} font-bold text-center mb-auto`}
              >
                {title}
              </p>
              {img}
            </>
          ) : (
            <>
              {img}
              <p
                style={
                  titleCustomStyles ?? {
                    position: "absolute",
                    bottom: -2,
                    paddingBottom: 5,
                    paddingTop: 5,
                    width: "calc(100% + 4px)",
                    left: -2,
                    borderRadius: "0px 0px 4px 4px",
                    // border:'1px solid white',
                    backgroundColor: titleCustomBgColor ?? "rgb(51,65,85)",
                  }
                }
                className={`${titleSizeClass} truncate font-bold text-center my-auto`}
              >
                {title}
              </p>
            </>
          )}
        </div>
      </PileWrapper>
      {showHelp && tooltip && (
        <div
          className="relative cursor-pointer"
          onClick={(e: any) => {
            e.stopPropagation();
            setState({
              isTooltipOpen: !state.isTooltipOpen,
              isEditPanelOpen: false,
              isDeleteConfirmOpen: false,
              isLoading: false,
            });
          }}
        >
          {/* <TooltipContainer tooltip={tooltip} containerClassName='bg-slate-800 rounded-lg w-[40px] h-[40px] border flex justify-center items-center' containerStyle={{ position: 'absolute', right: -5, bottom: -5 }}>
                    <FontIcon
                        iconName='help'
                        className="text-white text-lg font-bold m-auto"
                    />
                </TooltipContainer> */}
          <HelpIconBtn
            onClick={(e: any) => {}}
            style={{
              position: "absolute",
              right: 0,
              bottom: 0,
              height: 25,
              width: 25,
            }}
          >
            <HelpIcon style={{ height: 25, width: 25 }} />

            {/* <FontIcon
                        iconName='help'
                        className="text-white font-bold m-auto"
                        style={{ fontSize: 12 }}
                    /> */}
          </HelpIconBtn>
          <HelpPopup
            onClose={() => setState({ ...state, isTooltipOpen: false })}
            isOpen={state.isTooltipOpen}
            width={tooltipWidth}
            helps={[{ Item: tooltip.text, Description: "" }]}
          />
        </div>
      )}
      {edit && (
        <div
          style={{ maxWidth: "100%", maxHeight: "100%" }}
          className="relative"
        >
          {deletable && (
            <IconButton
              onClick={(e: any) => {
                e.stopPropagation();
                setState({
                  isTooltipOpen: false,
                  isEditPanelOpen: false,
                  isDeleteConfirmOpen: !state.isDeleteConfirmOpen,
                  isLoading: false,
                });
              }}
              className="hover:bg-intesa-green hover:text-white text-black bg-white border border-black p-6 rounded"
              style={{
                border: "1px solid black",
                position: "absolute",
                zIndex: 999,
                right: 80,
                top: 20,
              }}
            >
              <FontIcon iconName="Delete" className="text-xxl p-2" />
            </IconButton>
          )}
          <IconButton
            onClick={(e: any) => {
              e.stopPropagation();
              setState({
                isTooltipOpen: false,
                isEditPanelOpen: !state.isEditPanelOpen,
                isDeleteConfirmOpen: false,
                isLoading: false,
              });
            }}
            className="hover:bg-intesa-green hover:text-white text-black bg-white border border-black p-6 rounded"
            style={{
              border: "1px solid black",
              position: "absolute",
              zIndex: 999,
              right: 20,
              top: 20,
            }}
          >
            <FontIcon iconName="Edit" className="text-xxl p-2" />
          </IconButton>
        </div>
      )}
      {state.isEditPanelOpen && asset && (
        <EditAssetPanel
          productId={productId}
          closePanel={() =>
            setState({
              isTooltipOpen: false,
              isEditPanelOpen: false,
              isDeleteConfirmOpen: false,
              isLoading: false,
            })
          }
          asset={asset}
        />
      )}
      {state.isDeleteConfirmOpen && (
        <>
          <div
            className="w-[100vw] h-[100vh] absolute cursor-pointer"
            style={{
              backdropFilter: "blur(2px)",
              zIndex: 99998,
              backgroundColor: "rgba(0,0,0,0.25)",
              left: 0,
              top: 0,
            }}
            onClick={(e: any) => {
              e.stopPropagation();
              setState({
                isTooltipOpen: false,
                isEditPanelOpen: false,
                isDeleteConfirmOpen: false,
                isLoading: false,
              });
            }}
          />
          <FlexCol
            className="fixed w-[375px] h-[200px] bg-slate-800 border-white border p-4 rounded-md"
            style={{ zIndex: 99999, left: "calc(50vw - 100px)", top: "35%" }}
          >
            <div className="relative w-[100%]">
              <p className="text-xl text-center max-w-[80%] mx-auto">
                Sei sicuro di voler eliminare {title}?
              </p>
              <IconButton
                style={{
                  position: "absolute",
                  right: -10,
                  top: -15,
                }}
                className="hover:!bg-transparent"
                onClick={() =>
                  setState({
                    isTooltipOpen: false,
                    isEditPanelOpen: false,
                    isDeleteConfirmOpen: false,
                    isLoading: false,
                  })
                }
              >
                <FontIcon
                  className="text-xl text-white"
                  iconName="ChromeClose"
                />
              </IconButton>
            </div>
            {state.isLoading && (
              <MiniLoadingSpinner
                containerClassNames="flex items-center w-[100%] mt-2"
                style={{ margin: "auto" }}
              />
            )}
            <DashboardButton
              // className="ml-auto mt-auto"
              style={{
                marginTop: "auto",
                fontSize: "1.35rem",
                padding: "5px 15px",
              }}
              text="Elimina"
              onClick={() => {
                if (asset?.Id) {
                  setState({
                    ...state,
                    isLoading: true,
                  });
                  callDeleteAsset(asset.Id)
                    .then(() => {
                      deleteAssetOnStore(asset);
                      setState({
                        isTooltipOpen: false,
                        isEditPanelOpen: false,
                        isDeleteConfirmOpen: false,
                        isLoading: false,
                      });
                    })
                    .catch((err) => {
                      alert(
                        "L'operazione non è andata a buon fine, aggiorna la pagina e riprova."
                      );
                    });
                }
              }}
            />
          </FlexCol>
        </>
      )}
    </>
  );
};
export { Pile };
