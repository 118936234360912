import { createCtx } from "../store/general"
import { TAssignment } from "../types/assignments";
import { IColumnFilterPreset } from "../types/general";
import { ETicket } from "../types/etickets";
import { IColumnFilter, IColumnSorting } from "../types/odata"
import { IInputEvent } from "../types/eventNotifications";
export const initialAssignmentSortings: IColumnSorting<TAssignment>[] = [
    { key: "Id", direction: "desc", innerClass: "" },
  ];
  export const initialTicketsSortings: IColumnSorting<ETicket>[] = [
    { key: "Id", direction: "desc", innerClass: "" },
  ];
  export const initialDataMonitorSortings: IColumnSorting<IInputEvent>[] = [
    { key: "OpenDate", direction: "desc", innerClass: "" },
  ];
export enum PaginationEnum {
    ASSIGNMENTS = "ASSIGNMENTS",
    TICKETS= "TICKETS",
    INPUT_EVENTS="INPUT_EVENTS"
}
export enum SitePagesEnum {
    ASSIGNMENTS_LIST = "ASSIGNMENTS_LIST",
    TICKETS_LIST = "TICKETS_LIST",
    ASSIGNMENT_DETAILS = "ASSIGNMENT_DETAILS",
    TICKETS_DETAILS = "TICKETS_DETAILS",
  }
export type TPagination = {
    
    items:any[],
    showFilters:boolean,
    pageSize:number,
    page:number,
    totalItems:number,
    filters:IColumnFilter<any>[],
    filtersPresets:IColumnFilterPreset<any>[],
    sortings:IColumnSorting<any>[],
    LAST_CALL_PAGE:number,
    LAST_CALL_SORTINGS:IColumnSorting<any>[],
    LAST_CALL_FILTERS:IColumnFilter<any>[],
    isLoading:boolean
}
export type TPaginationDictionary = {
    [key in PaginationEnum]:TPagination
}

export type TState = {
    isLoaded: {
        [key in SitePagesEnum]: boolean
      },
    pagination:TPaginationDictionary
}
// usage
const initialState: TState = {
    isLoaded:{
        [SitePagesEnum.ASSIGNMENTS_LIST]:false,
        [SitePagesEnum.TICKETS_LIST]:false,
        [SitePagesEnum.ASSIGNMENT_DETAILS]:false,
        [SitePagesEnum.TICKETS_DETAILS]:false,
    },
    pagination:{
        [PaginationEnum.ASSIGNMENTS]:{
            items:[],
            showFilters:true,
            isLoading:false,
            pageSize:250,
            page:0,
            totalItems:0,
            filters:[],
            filtersPresets:[],
            sortings:initialAssignmentSortings,
            LAST_CALL_PAGE:-1,
            LAST_CALL_SORTINGS:[],
            LAST_CALL_FILTERS:[]
        },
        [PaginationEnum.TICKETS]:{
            items:[],
            showFilters:true,
            isLoading:false,
            pageSize:250,
            page:0,
            totalItems:0,
            filters:[],
            filtersPresets:[],
            sortings:initialTicketsSortings,
            LAST_CALL_PAGE:-1,
            LAST_CALL_SORTINGS:[],
            LAST_CALL_FILTERS:[]
        },
        [PaginationEnum.INPUT_EVENTS]:{
            items:[],
            showFilters:true,
            isLoading:false,
            pageSize:250,
            page:0,
            totalItems:0,
            filters:[],
            filtersPresets:[],
            sortings:initialDataMonitorSortings,
            LAST_CALL_PAGE:-1,
            LAST_CALL_SORTINGS:[],
            LAST_CALL_FILTERS:[]
        },
    }
}
type AppState = TState;
type Action =
    { type: "SET_STATE", payload: TState }|
    { type: "SET_ISLOADED"; payload: { [key in SitePagesEnum]: boolean } }|
    { type: "SET_PAGINATION", payload: {key:PaginationEnum,pagination:TPagination} } |
    { type: "SET_PAGINATION_TOTAL_ITEMS", payload: {key:PaginationEnum,totalItems:number} } |
    { type: "SET_FILTERS"; payload: {key:PaginationEnum,filters:IColumnFilter<any>[]} }| 
    { type: "SET_FILTERS_AND_PRESETS"; payload: {key:PaginationEnum,filters:IColumnFilter<any>[],presets:IColumnFilterPreset<any>[]} }| 
    { type: "SET_FILTERS_PRESETS"; payload: {key:PaginationEnum,presets:IColumnFilterPreset<any>[]} }| 
    { type: "SET_SORTINGS"; payload: {key:PaginationEnum,sortings:IColumnSorting<any>[]} }

function reducer(state: AppState, action: Action): AppState {
    switch (action.type) {
        case "SET_STATE":
            return action.payload;
        case "SET_ISLOADED":{
            return {...state,isLoaded:action.payload}
        }
        case "SET_FILTERS":{
            return {...state,pagination:{...state.pagination,[action.payload.key]:{...state.pagination[action.payload.key]
                ,filters:action.payload.filters}}}
        }
        case "SET_FILTERS_AND_PRESETS":{
            return {...state,pagination:{...state.pagination,[action.payload.key]:{...state.pagination[action.payload.key]
                ,filters:action.payload.filters
                ,filtersPresets:action.payload.presets
            }}}
        }
        case "SET_FILTERS_PRESETS":{
            return {...state,pagination:{...state.pagination,[action.payload.key]:{...state.pagination[action.payload.key]
                ,filtersPresets:action.payload.presets
                ,filters:action.payload.presets.map((x)=>x.filter)}}}
        }
        case "SET_SORTINGS":{
            return {...state,pagination:{...state.pagination,[action.payload.key]:{...state.pagination[action.payload.key],sortings:action.payload.sortings}}}
        }
        case "SET_PAGINATION":{
            return {...state,pagination:{...state.pagination,[action.payload.key]:action.payload.pagination}}
        }
        case "SET_PAGINATION_TOTAL_ITEMS":
            return {...state,pagination:{...state.pagination,[action.payload.key]:{...state.pagination[action.payload.key],totalItems:action.payload.totalItems}}}
            
        default:
            return state;
    }
}

export const [paginationContext, PaginationProvider] = createCtx(reducer, initialState);