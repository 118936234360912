import { FontIcon } from '@fluentui/react';
import React from 'react';
import { IconProps, iconClassNames } from './iconsConstants';
import { DashboardSideIcon } from './DashboardSideIcon';
import { ProductPageSection } from '../../../hooks/useProductDashboardDimensions';
const KPIIcon: React.FC<IconProps> = ({onlyIcon}) => {
    const icon = <FontIcon iconName="BIDashboard" className={iconClassNames} />
    const id = ProductPageSection.KPI
    const tooltip = {
        id:`${id}-dashboard_icon`,
        text:"KPI"
    }
    return <DashboardSideIcon
        onlyIcon={onlyIcon}
        id={id}
        tooltip={tooltip}
        icon={icon}
    />
}
export { KPIIcon }