import { createCtx } from "../store/general"
import { Building } from "../services/building"
import { TSelectedKey } from "../types/Lists"
import { IGenericError } from "../types/general"

export type TGroupingType = "Edifici" | "Poli"
export type TSecondaryGroupingType = "OpenTickets" | "OldestTickets"

type TState = {
    buildings: Building[],
    mapBuildings: Building[],
    selectedBuildings: Building[],
    selectedPolo: string,
    loading: boolean,
    forceListLoad: boolean,
    selectedListPivot: TSelectedKey,
    reportArea: string,
    powerBIToken: string,
    globalError: IGenericError,
}

// usage
const initialState: TState = {
    buildings: [],
    selectedBuildings: [],
    selectedPolo: '',
    loading: false,
    forceListLoad: false,
    selectedListPivot: "Open",
    mapBuildings: [],
    reportArea: '',
    powerBIToken: '',
    globalError: { Message: '', Details: '' },
}
type AppState = TState;
type Action =
    | { type: 'SET_BUILDINGS', payload: Building[] }
    | { type: 'SET_SELECTED_BUILDINGS'; payload: Building[] }
    | { type: 'SET_LOADING', payload: boolean }
    | { type: "SET_SELECTED_BUILDINGS_AND_POLO", payload: { selectedBuildings: Building[], polo: string } }
    | { type: "SET_FORCE_LIST_LOAD", payload: boolean }
    | { type: "SET_SELECTED_LIST_PIVOT", payload: TSelectedKey }
    | { type: "SET_MAP_BUILDINGS", payload: Building[] }
    | { type: "SET_REPORT_AREA", payload: string }
    | { type: "SET_POWER_BI_TOKEN", payload: string }
    | { type: "SET_GLOBAL_ERROR", payload: IGenericError }

function reducer(state: AppState, action: Action): AppState {
    switch (action.type) {

        case 'SET_BUILDINGS':
            return { ...state, buildings: action.payload }
        case 'SET_MAP_BUILDINGS':
            return { ...state, mapBuildings: action.payload }
        case 'SET_SELECTED_BUILDINGS':
            return { ...state, selectedBuildings: action.payload }
        case 'SET_LOADING':
            return { ...state, loading: action.payload }
        case 'SET_SELECTED_BUILDINGS_AND_POLO':
            return { ...state, selectedBuildings: action.payload.selectedBuildings, selectedPolo: action.payload.polo }
        case 'SET_FORCE_LIST_LOAD':
            return { ...state, forceListLoad: action.payload }
        case 'SET_SELECTED_LIST_PIVOT':
            return { ...state, selectedListPivot: action.payload }
        case 'SET_REPORT_AREA':
            return { ...state, reportArea: action.payload }
        case 'SET_POWER_BI_TOKEN':
            return { ...state, powerBIToken: action.payload }
        case 'SET_GLOBAL_ERROR':
            return { ...state, globalError: action.payload }
        default:
            return state;
    }
}

export const [globalContext, GlobalProvider] = createCtx(reducer, initialState);