import React from "react";
import { TAlertAttachment } from "../../../types/alerts";
import { formatDate } from "../../../utility/formatDate";
import { FlexRow } from "../../Flex/FlexRow";
const AlertAttachment: React.FC<{
  attachment: TAlertAttachment;
  openImgSlider: (e: React.MouseEvent<HTMLDivElement>) => void;
  openSpecificImg: (n: number) => void;
  imgIndex?: number
}> = ({ attachment, openSpecificImg, imgIndex }) => {
  return (
    <>
      <FlexRow
        // justifyContent="space-between"
        className="rounded bg-slate-400 text-black p-2 border border-zinc-200 w-[100%] my-2"
        alignItems="start"
      >
        {attachment.AttachmentType === "Comment" ? (<p className="text-xl grow">{attachment.AttachmentContent}</p>
        ) : (
          <img
            onClick={() => openSpecificImg(imgIndex ?? -1)}
            style={{
              objectFit: "cover",
              height: 350,
              width: 175,
              cursor: "pointer",
            }}
            className="rounded"
            alt=""
            src={attachment.AttachmentContent}
          />
        )}
        <div className="flex flex-col ml-auto mr-2">
          <p className="text-lg text-right">
            {attachment.ReferenceUser ?? ''}
          </p>
          <p className="text-sm text-right">
            {formatDate(attachment.ReferenceDate)}
          </p>
        </div>
      </FlexRow>
    </>
  );
};
export { AlertAttachment };
