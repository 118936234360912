import React from "react";
import { usePowerBiAuth } from "../components/powerBi/hooks/usePowerBiAuth";
import { useUserContext } from "../store/hooks/useUserContext";
import { IUserInfo } from "../types/user";

type TProps = {
    loadConditions: boolean,
    setIsLoading: (b: boolean) => void,
    graphTokenSelector: (u: IUserInfo) => string
    loadItems: (t: string) => void
}
type TContext = {
    getAccessToken: (tryPopup?: boolean | undefined, tokenType?: "graph" | "analysis") => Promise<void>,
    graphToken: string
}
export const useGraphAuthentication: (props: TProps) => TContext = ({
    setIsLoading,
    graphTokenSelector,
    loadItems,
    loadConditions,
}) => {
    const {
        userInfo,
        errors,
        setErrors,
        setGraphAccessToken,
    } = useUserContext();
    const graphToken = graphTokenSelector(userInfo);
    const handleSetToken = (token: string) => {
        if (errors.length > 0) {
            setErrors([]);
        }
        setGraphAccessToken(token);
    }
    const handleErrorCode = (errorCode: string) => {
        let msg = '';
        switch (errorCode) {
            case "consent_required": {
                msg = "Consenti i popup/rendirizzamenti."
                break;
            }
            case "user_cancelled": {
                msg = "Operazione annullata"
                break;
            }
            default: {
                msg = "Qualcosa è andato storto, contatta l'amministratore."
            }
        }
        return msg;
    }
    const { getAccessToken } = usePowerBiAuth({
        setAccessToken: handleSetToken, catchError(err, ssoError) {
            setErrors([...errors, handleErrorCode(err.errorCode)])
        },
    });
    React.useEffect(() => {
        if (errors.length === 0) {
            if (!graphToken) {
                setIsLoading(true);
                getAccessToken(true, "graph").finally(() => setIsLoading(false));
            } else {
                if (loadConditions) {
                    console.log("Loading Items")
                    setIsLoading(true);
                    loadItems(graphToken)
                }

            }
        }
    }, [errors, getAccessToken, graphToken, loadConditions, loadItems, setIsLoading])
    return {
        graphToken,
        getAccessToken,
    }
}