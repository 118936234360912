import React from 'react'
import { UserRolesProps } from '../UserRolesCard/types'
import { IconButton, Typography } from '@mui/material';
import { userListheaders } from './UserListHeaders/UserListHeaders';
import { UserPanel } from '../UserPanel/UserPanel';
import { BiEdit } from 'react-icons/bi';
export const UserRolesListRow: React.FC<UserRolesProps> = ({
    currentValues,
    user,
    roles,
    handleChange
}) => {
    const [panelIsOpen,setPanelIsOpen] = React.useState(false);
    return <div className="flex w-[100%] items-center !min-h-[30px]" style={{minWidth:800}}>
        {userListheaders.map((h) => <Typography key={h.id} align='center' className='!border !bg-white !text-black !mt-auto !h-[100%]' style={{ width: h.width }}>
            {h.value(user, currentValues)}
        </Typography>)}
        <IconButton
            onClick={()=>setPanelIsOpen(true)}
            className='!w-[5%] !bg-white !text-black hover:!bg-white hover:!text-black !rounded-none !border !border-solid !border-gray-300'
        >
            <BiEdit />
        </IconButton>
        <UserPanel
            roles={roles}
            isOpen={panelIsOpen}
            onClose={() => setPanelIsOpen(false)}
            user={user}
            currentValues={currentValues}
            canEdit
            handleChange={handleChange}
        />
    </div>
}