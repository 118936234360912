import React from "react"
import { TMetric } from "../../../types/alerts"
import { MetricLeaf } from "./MetricLeaf"
import { ExpandableContainer } from "../../../components/ExpandableContainer/ExpandableContainer"
import { settingsContext } from "../../../store/settingsStore"

type Props = {
    metric: TMetric
}
export const MetricExpandableContainer: React.FC<Props> = ({ metric }) => {
    const {state} = React.useContext(settingsContext);
    const {selectedMetrics} = state;
    const someChildMetricIsSelected = metric.ChildMetrics.some(x=>selectedMetrics?.includes(x))
    return <div className="mb-1">
        <ExpandableContainer
            containerIsClickable
            containerClassNames={`flex border border-white  ${someChildMetricIsSelected ? "bg-slate-400 text-black" : "bg-slate-800 text-white"} w-[35%]`}
            textClassNames="text-2xl"
            textIsClickable
            iconPosition="beforeText"
            text={metric.Name}
            borderIfOpen
            defaultOpen={someChildMetricIsSelected}
        >
            <>
            {/* <AssignmentCfgHeaders/> */}
            <div className="mb-1"/>
                <div className="flex flex-col">{metric.ChildMetrics && metric.ChildMetrics.map((m) => m.ChildMetrics !== undefined && m.ChildMetrics !== null && m.ChildMetrics.length > 0 
                    ? <MetricExpandableContainer 
                        metric={m}
                    />
                    : <MetricLeaf 
                        metric={m}
                    />)}</div>
                <div className="mb-4" />
            </>
        </ExpandableContainer>
    </div>

}