import React from 'react'
import { TAssignment } from '../../../../types/assignments'
import { Typography } from '@mui/material'
import { useNavigate } from 'react-router-dom'
import { assignments_known_urls } from '../../../../types/userStore'
import { formatDate } from '../../../../utility/formatDate'
import { isMobile } from 'react-device-detect'
import { ISortableHeader } from '../../../../types/general'
import { Status_Mapper } from '../../../../store/hooks/useHeaders'
import { ETicket } from '../../../../types/etickets'
type Props = {
    ticket: ETicket,
    headers: ISortableHeader<ETicket>[]
}
export const TicketCard: React.FC<Props> = ({ ticket, headers }) => {
    const navigate = useNavigate();
    const handleAssignmentClick = (a: ETicket) => {
        navigate(`${assignments_known_urls.assignment_detail}?id=${a.Id}`)
    }
    return <div
        onClick={() => handleAssignmentClick(ticket)}
        className={`flex flex-col w-[90%] bg-gray-300 max-h-[350px] rounded shadow mx-[5%] my-2`} style={{ border: '1px solid' }} >
        <div className="flex  items-between" style={{ borderBottom: '1px solid' }}>
            <div className="flex flex-col w-[50%] pl-2">
                <Typography fontWeight={"bold"}>
                    Id:
                </Typography>
                <Typography>
                    {ticket.Id}
                </Typography>
            </div>
            <div className="flex flex-col w-[50%] items-end pr-2">
                <Typography fontWeight={"bold"}>
                    Stato
                </Typography>
                <Typography>
                    {Status_Mapper[ticket.CumulativeStatus.toLowerCase()]}
                </Typography>
            </div>
        </div>
        <div className="flex items-between p-2">
            <Typography fontWeight={"bold"}>
                CRImmobile:
            </Typography>
            <div className="ml-2" />
            <Typography style={{ overflowWrap: 'anywhere' }}>
                {ticket.CRImmobile}
            </Typography>
        </div>
        <div className="flex items-between p-2">
            <Typography fontWeight={"bold"}>
                Ambito:
            </Typography>
            <div className="ml-2" />
            <Typography style={{ overflowWrap: 'anywhere' }}>
                {ticket.CodiceAmbito}
            </Typography>
        </div>
        <div className="flex items-between p-2">
            <Typography fontWeight={"bold"}>
                Specifica:
            </Typography>
            <div className="ml-2" />
            <Typography style={{ overflowWrap: 'anywhere' }}>
                {ticket.NomeSpecifica}
            </Typography>
        </div>

        <div className="flex  flex-col items-center p-2 max-h-[250px] overflow-hidden">
            <Typography fontWeight={"bold"}>
                Descrizione:
            </Typography>
            <div className="ml-2" />
            <Typography
                textOverflow={"ellipsis"}
            >
                {ticket.Description}
            </Typography>
        </div>
    </div>

}