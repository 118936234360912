import React from 'react'
import { HeaderFilterProps } from '../../types';
import { FormControl, InputLabel, MenuItem, OutlinedInput, Select } from '@mui/material';
import { HeaderFilterCellFormControl } from '../HeaderFilterCellFormControl/HeaderFilterCellFormControl';
import styled from '@emotion/styled';
import { useHeaderCell } from '../../hooks/useHeaderCell';
const StyledSelect = styled(Select)({
    borderRadius: 0
})
export const EnumHeaderFilterCell: React.FC<HeaderFilterProps> = ({
    header,
    localValue,
    enumStoreKey
}) => {
    const {setHeaderFilter,headerCurrentFilter,removeHeaderFilter,isLoading} = useHeaderCell({header,enumStoreKey})

    const handleChange: React.ChangeEventHandler<HTMLInputElement> = (event) => {
        const value = event.target.value === 'clear' ? '' : event.target.value;
        if (localValue?.setValue) {
            localValue.setValue(value)
        } else {
            setHeaderFilter(header, value)
        }
    }
    const selectValue = React.useMemo(()=>{
        if(localValue){
            return localValue.value
        }else{
            return headerCurrentFilter && headerCurrentFilter.operator === 'eq' ? headerCurrentFilter.value : ''
        }
    },[headerCurrentFilter,localValue])
    return <HeaderFilterCellFormControl
        header={header}
        input={
            <StyledSelect
                label='Stato'
                disabled={isLoading}
                value={selectValue}
                input={<OutlinedInput onContextMenu={(e)=>{
                    e.preventDefault();
                    removeHeaderFilter(header);
                }} id={header.label} label={header.label} onChange={handleChange} />}
                placeholder='Stato'
            >
                {header.filterOptions?.map(({ value, label }) => <MenuItem value={value}>{label}</MenuItem>)}
                <MenuItem key='clear' value='clear'>Rimuovi selezione</MenuItem>
            </StyledSelect>} />;
}