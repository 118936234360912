import React from 'react'
import { IUser } from '../../../services/user';
import { known_products } from '../../../types/userStore';
import { HeaderCell } from '../../HeaderCell/HeaderCell';
import { Typography } from '@mui/material';
type PROPS = {}
export const userListheaders = [
    {
        id: "username",
        label: "Username",
        value: (u: IUser,currentValues: { [key: string]: { RuoloId: string; enable: boolean } }) => u.User,
        width:'19%',
    },
    {
        id: "iot",
        label: "IOT",
        width:'19%',
        value: (u: IUser,currentValues: { [key: string]: { RuoloId: string; enable: boolean } }) => currentValues[known_products['iot-connected-buildings'].id] ?
            currentValues[known_products['iot-connected-buildings'].id].RuoloId : 
            null
    },
    {
        id: "energy",
        label: "ENERGY",
        width:'19%',
        value: (u: IUser,currentValues: { [key: string]: { RuoloId: string; enable: boolean } }) => currentValues[known_products['energy'].id] ?
            currentValues[known_products['energy'].id].RuoloId : 
            null
    },
    {
        id: "iot",
        width:'19%',
        label: "OCCUPANCY",
        value: (u: IUser,currentValues: { [key: string]: { RuoloId: string; enable: boolean } }) => currentValues[known_products['occupancy'].id] ?
            currentValues[known_products['occupancy'].id].RuoloId : 
            null
    },
    {
        id: "iot",
        width:'19%',
        label: "ASSET-IMMOBILIARE",
        value: (u: IUser,currentValues: { [key: string]: { RuoloId: string; enable: boolean } }) => currentValues[known_products['assetimmobiliare'].id] ?
            currentValues[known_products['assetimmobiliare'].id].RuoloId : 
            null
    },
]
export const UserListHeaders: React.FC<PROPS> = () => {

    return <div className='flex w-[100%] items-center' style={{minWidth:800}}>
        {userListheaders.map((h)=><Typography key={h.id} align='center' className='!border !bg-slate-800 !text-white' style={{width:h.width}}>
            {h.label}
        </Typography>)}
    </div>;
}