import React from 'react';
import { Pile } from '../Pile';
import { TProductAsset } from '../../../services/products';
import { TPileSize, TSize } from '../../../types/general';
import { known_products, special_products } from '../../../types/userStore';
type Props = {
}
type ScorecardPileProps = {
    asset: TProductAsset
    pileSize?: TPileSize,
}
const NewsLetterPile: React.FC<ScorecardPileProps> = ({ asset, pileSize }) => {
    const getImg = React.useCallback((imgName?: string, imgRows?: number) => {
        const height = imgRows ? `${imgRows * 10}%` : '100%'
        if (!imgName) {
            return <img src="" style={{ height, objectFit: 'fill' }} alt={`Image for ${imgName} Missing`} />
        }
        const isUrl = imgName?.startsWith("http");
        if (isUrl) {
            return <img style={{ height, objectFit: 'fill', borderRadius: '8px 8px 0px 0px', margin: 0 }} src={imgName} alt="Image Missing" />
        }
        try {
            return <img style={{ height, objectFit: 'fill', borderRadius: '8px 8px 0px 0px', margin: 0 }} src={imgName ? require('../../../images/scorecards/' + imgName) : ''} alt="Image Missing" />
        } catch (error) {
            console.log(error)
            return <img src="" style={{ height, objectFit: 'fill' }} alt={`Image for ${imgName} Missing`} />
        }
    }, [asset.AssetBag])
    return <Pile
        titlePosition='down'
        customAspectRatio={3 / 4}
        id={asset.Url ?? ''}
        customShadow=''
        targetUrl={asset.Url ?? ''}
        img={getImg(asset?.ThumbnailsImageUrl)}
        openInNewTab
        title={asset.Name}
        customColor
        titleCustomBgColor="rgb(70,91,119)"
        productId={special_products.sbh.id}
        edit={false}
        className={`relative shadow-none text-white hover:text-white m-2 cursor-pointer rounded-lg border border-gray-50 bg-white`}
        tooltip={{ id: asset.Url ?? asset?.Name.toString(), text: asset.Description ?? '' }}
        key={asset.Url ?? ''}
        size={pileSize ?? "x4"}
        titleSize='xl'
        asset={asset}
    />
}
export { NewsLetterPile }