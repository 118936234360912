import React from "react";
import { VscFeedback } from "react-icons/vsc";
import { TMainDashboardIconProps } from "./types";
import { MainDashboardIcon } from "./MainDashboardIcon";

const FormLinkIcon: React.FC<TMainDashboardIconProps> = (props) => {
  return (
    <MainDashboardIcon {...props} onClick={() => window.open(process.env.REACT_APP_SUGGESTIONS_FORM_URL ?? '')}>
      <VscFeedback />
    </MainDashboardIcon>
  );
};
export { FormLinkIcon };
