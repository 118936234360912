import React from "react";
import { useTypedContext } from "./useContext";
import {
  DEFAULT_NAVIGATION_TYPE,
  NAVIGATION_SET_ACTION,
  NavigationStateKeysEnum,
  PANEL_NAVIGATION_TYPE,
  navigationContext,
} from "../navigationStore";
import { IPanelOptions, ISidebarOptions } from "../../types/panels";

export const useNavigationContext = () => {
  const {
    state: navigationState,
    dispatch: navigationDispatch,
    setActions,
  } = useTypedContext({
    actionType: NAVIGATION_SET_ACTION,
    context: navigationContext,
    keys_enum: NavigationStateKeysEnum,
  });
  const panel = React.useMemo(
    () => navigationState[NavigationStateKeysEnum.PANEL],
    [navigationState]
  );
  const sidebar = React.useMemo<ISidebarOptions>(
    () => navigationState[NavigationStateKeysEnum.SIDEBAR],
    [navigationState]
  );
  const navigationType = React.useMemo(
    () => navigationState[NavigationStateKeysEnum.NAVIGATION_TYPE],
    [navigationState]
  );

  const setDefaultNavigationType = React.useCallback(
    () =>
      setActions[NavigationStateKeysEnum.NAVIGATION_TYPE](
        DEFAULT_NAVIGATION_TYPE
      ),
    [setActions]
  );
  const setPanelNavigationType = React.useCallback(
    () =>
      setActions[NavigationStateKeysEnum.NAVIGATION_TYPE](
        PANEL_NAVIGATION_TYPE
      ),
    [setActions]
  );
  React.useEffect(() => {
    if (panel.isOpen && navigationType !== PANEL_NAVIGATION_TYPE) {
      setPanelNavigationType();
    } else{
      if (panel.isOpen && navigationType !== DEFAULT_NAVIGATION_TYPE) {
        setDefaultNavigationType();
      }
    }
  }, [
    navigationType,
    panel.isOpen,
    setDefaultNavigationType,
    setPanelNavigationType,
  ]);
  const setSidebarOptions = React.useCallback(
    (sidebarOptions: ISidebarOptions) => {
      navigationDispatch({
        type: "SET_SIDEBAR_OPTIONS",
        options: sidebarOptions,
      });
    },
    [navigationDispatch]
  );
  const setSidebarIsOpen = React.useCallback(
    (b: boolean) => setSidebarOptions({ ...sidebar, isOpen: b }),
    [sidebar, setSidebarOptions]
  );
  const setSidebarChildrens = React.useCallback(
    (panelChildrens: React.ReactNode | null) =>
      setSidebarOptions({ ...sidebar, childrens: panelChildrens }),
    [sidebar, setSidebarOptions]
  );
  const setPanelOptions = React.useCallback(
    (panelOptions: IPanelOptions) => {
      navigationDispatch({
        type: "SET_PANEL_OPTIONS",
        options: panelOptions,
      });
    },
    [navigationDispatch]
  );
  const setPanelIsOpen = React.useCallback(
    (b: boolean) => setPanelOptions({ ...panel, isOpen: b }),
    [panel, setPanelOptions]
  );
  const setPanelChildrens = React.useCallback(
    (panelChildrens: React.ReactNode | null) =>
      setPanelOptions({ ...panel, panelChildrens }),
    [panel, setPanelOptions]
  );
  return {
    navigationState,
    navigationDispatch,
    navigationType,
    panel,
    sidebar,
    setPanelOptions,
    setPanelIsOpen,
    setPanelChildrens,
    setSidebarOptions,
    setSidebarIsOpen,
    setSidebarChildrens,
  };
};
