import { createCtx } from "../store/general"
import { TMetric } from "../types/alerts";
import { AssignmentPriorityEnum, IAssignmentConfiguration, TConfigurationFormData } from "../types/assignments";
import { IColumnSorting, IState } from "../types/stores/settings";
import { known_products } from "../types/userStore";
export const INITIAL_STEP = 1;
export type TSelectorView = "USER" | "ASSIGNMENTS";
export enum StepsEnum {
    SELECTION=1,
    FORM=2,
    CONFIRM=3
}
export const initialConfigurationFormData:TConfigurationFormData = {
    OwnerMail: "",
    IsAutomaticClose:false,
    ClosureReason:["Altro"],
    Tags:[],
    Priority:AssignmentPriorityEnum.Low,
    IsEnable: true,
    CanBeDelegated: false,
    NotificationUserMail: [],
    IsEscalationRequired: false,
    EscalationReceiversMail: "",
    EscalationTimeInDays: 7,
}
const initialState : IState = {
    search:'',
    popupMessage:'',
    popupType:"success",
    metrics:[],
    metricSortings:[],
    assignmentConfigurations:[],
    selectedMetrics:null,
    selectedProductId:known_products["iot-connected-buildings"].id,
    currentStep:INITIAL_STEP,
    configurationFormData:initialConfigurationFormData
}

type AppState = IState;
type Action =
    {type:"SET_STATE",payload:IState} |
    {type :"SET_SELECTED_PRODUCTID",payload:string|null} |
    {type :"SET_SELECTED_METRICS",payload:TMetric[]|null} |
    {type :"SET_STEP",payload:number} |
    {type :"SET_METRICS",payload:TMetric[]} |
    {type :"SET_ASSIGNMENT_CONFIGURATIONS",payload:IAssignmentConfiguration[]} |
    {type :"SET_SEARCH",payload:string}|
    {type :"SET_SORTINGS",payload:IColumnSorting[]}|
    {type:"RESET_FORM"}

function reducer(state: AppState, action: Action): AppState {
    switch (action.type) {
        case "SET_STATE":return action.payload
        case "SET_SELECTED_PRODUCTID":return {...state,selectedProductId:action.payload}
        case "SET_SELECTED_METRICS":return {...state,selectedMetrics:action.payload}
        case "SET_STEP":return {...state,currentStep:action.payload}
        case "SET_METRICS":return {...state,metrics:action.payload}
        case "SET_ASSIGNMENT_CONFIGURATIONS":return {...state,assignmentConfigurations:action.payload}
        case "SET_SEARCH":return {...state,search:action.payload}
        case "SET_SORTINGS":return {...state,metricSortings:action.payload}
        case "RESET_FORM":return {...state,configurationFormData:initialConfigurationFormData}
        default:
            return state;
    }
}

export const [settingsContext, SettingsProvider] = createCtx(reducer, initialState);