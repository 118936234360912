import React from 'react';
import './styles.css'
import { GetUserRoles, GetUsers, IUser, IUserRole, updateUserRole } from '../../../services/user';
import { TProduct } from '../../../services/products';
import { appInsights } from '../../../components/application-insights/AppInsights';
import useWindowSize from '../../../utility/useWindowSize';
import { useUserContext } from '../../../store/hooks/useUserContext';
import { useDashboardsStore } from '../../../store/hooks/useDashboardsStore';
import { hasPermission } from '../../../store/hooks/useUserStore';
import { known_actions } from '../../../types/userStore';
import { Pager } from '../../../components/Pager/Pager';
import { Grid } from '@mui/material';
import { UserRolesCard } from '../../../components/UserRolesCard/UserRolesCard';
import { MiniLoadingSpinner } from '../../../components/spinner/MiniLoadingSpinner/MiniLoadingSpinner';
import { UserRoles } from '../../../components/UserRolesCard/UserRoles/UserRoles';
import { UserNotifications } from '../../../components/UserNotificationsPanel/UserNotifications/UserNotifications';
import { UserRolesListRow } from '../../../components/UserRolesListRow/UserRolesListRow';
import { UserListHeaders } from '../../../components/UserRolesListRow/UserListHeaders/UserListHeaders';
type TState = {
    users: IUser[],
    currentValues: { [key: string]: { [key: string]: { RuoloId: string, enable: boolean } } },
    roles: IUserRole[],
    products: TProduct[],
    isLoading: boolean
}
const InitialState = {
    isLoading: false,
    users: [],
    roles: [],
    products: [],
    currentValues: {},
}
const UserSettingsPage: React.FC<{ search: string,viewType:"list"|"cards",setViewType:(x:"list"|"cards")=>void }> = ({ search,viewType,setViewType }) => {

    appInsights.trackPageView({ name: "UserSettingsPage" });

    const { topbarHeight } = useWindowSize();
    const [state, setState] = React.useState<TState>(InitialState)
    const { userClaims, userInfo, accessToken } = useUserContext();
    const { loadProducts } = useDashboardsStore();
    const loadUsers = React.useCallback(async () => {
        setState({ ...state, isLoading: true })
        loadProducts().then((products: TProduct[]) => {
            GetUserRoles().then((roles) => {
                GetUsers().then((users) => {
                    const currentValues: { [key: string]: { [key: string]: { RuoloId: string, enable: boolean } } } = {}
                    const setCurrentValues = (users: IUser[]) => {
                        users.forEach((u) => {
                            console.log(u.AbilitazioniUtente)
                            u.AbilitazioniUtente.forEach((a) => {
                                const currentValue = currentValues[u.User]
                                currentValues[u.User] = currentValue
                                    ? { ...currentValue, [a.ProductId]: { RuoloId: a.RuoloId, enable: a.IsEnabled } }
                                    : { [a.ProductId]: { RuoloId: a.RuoloId, enable: a.IsEnabled } }
                            })
                        })
                    }
                    setCurrentValues(users)

                    setState({ ...state, products, roles, users, currentValues, isLoading: false })
                }).catch((e) => console.log(e))
            }).catch((e) => console.log(e))
        }).catch((e) => console.log(e));
    },[loadProducts, state])
    // eslint-disable-next-line react-hooks/exhaustive-deps
    React.useEffect(() => { loadUsers() }, [accessToken])
    const handleChange = async (u: IUser, a: TProduct, ruoloId?: string, enable?: boolean) => {
        if (!ruoloId) return;
        updateUserRole(u, a, ruoloId, enable).then(() => {
            let userCurrentValues = state.currentValues[u.User];
            if (!userCurrentValues) return; //something is wrong, didnt load users
            userCurrentValues = { ...userCurrentValues, [a.ProductId]: { RuoloId: ruoloId, enable: enable ?? false } }
            setState({ ...state, currentValues: { ...state.currentValues, [u.User]: userCurrentValues } })
        })
    }
    const sortUsers = React.useCallback((u1: IUser, u2: IUser) => {
        const userName = userInfo.account.username;
        if (u1.User !== userName && u2.User !== userName) {
            return u1.CreationDate > u2.CreationDate ? 1 : -1;
        }
        return userName !== u1.User ? 1 : -1;
    }, [userInfo.account.username])
    // const usersFilterOptions = React.useMemo(() => (["Tutti", "Abilitati", "Disabilitati"].map((x) => ({ key: x, text: x }))), []);
    // const [usersFilterKey, setUserFilterKey] = React.useState("Tutti");
    // const handleUsersFilterChange = (e: any, opt: IComboBoxOption | undefined) => {
    //     if (opt) {
    //         setUserFilterKey(opt.key.toString());
    //     }
    // }
    // const filterUserByFilterKey = (u: IUser) => {
    //     const IconicsAreaId = "iconics";
    //     switch (usersFilterKey) {
    //         case "Tutti": return true;
    //         case "Abilitati": return u.AbilitazioniUtente.find((a) => a.ProductId === IconicsAreaId && a.IsEnabled) !== undefined;
    //         case "Disabilitati": return u.AbilitazioniUtente.find((a) => a.ProductId === IconicsAreaId && !a.IsEnabled) !== undefined;
    //         default: return true;
    //     }
    // }
    const filterUsers = React.useCallback((u: IUser) => {
        return search
            ? u.User.toLowerCase().includes((search.toLowerCase()))
            : true;
        // }, [state.users, localFilter, userInfo.accessToken, usersFilterKey])
    }, [search])
    // const _hasUserConfigPermissions = React.useMemo(() => hasPermission(userClaims, know_actions.main_dashboard_users, sbh_area), [userClaims]);
    const canEditOtherUsers = hasPermission(userClaims, known_actions.edit_other_users, 'sbh')
    // const navigate = useNavigate();
    // if (!_hasUserConfigPermissions) {
    //     navigate('/')
    // }
    const currentUser = state.users.find((u) => u.User === userInfo.account.username)

    return state.isLoading ? <div style={{ height: 'calc(100vh - 48px)' }} className='bg-slate-600 w-[100%] flex flex-col items-center justify-center'><MiniLoadingSpinner style={{ color: 'white' }} /></div>
        // : <div id="users_list" className="relative max-w-[100%] overflow-y-hidden bg-slate-500" style={{ height: 'calc(100vh - 48px)', overflow: 'auto' }}>
        //     {canEditOtherUsers && <div className="flex flex-row justify-between px-4 py-1 bg-slate-700 text-white mb-4 w-[100%] border-b border-white">

        //         <div className="flex flex-row">
        //             <IconButton
        //                 className="mx-2 my-auto"
        //                 onClick={() => loadUsers()}
        //             >
        //                 <FontIcon
        //                     className="text-white text-lg"
        //                     iconName="Refresh" />
        //             </IconButton>
        //             {/* <ComboBox
        //                     className="mx-4 my-2 w-[200px]"
        //                     options={usersFilterOptions}
        //                     selectedKey={usersFilterKey}
        //                     onChange={handleUsersFilterChange}
        //                 /> */}

        //         </div>
        //     </div>} 
        :
        <div id="users_list" className="relative max-w-[100%] overflow-y-hidden bg-slate-500 pt-4" style={{ height: 'calc(100vh - 48px)', overflow: 'auto' }}>
            <div className="px-2">
                {!canEditOtherUsers && currentUser && <>
                    <UserRoles
                        whiteText
                        user={currentUser}
                        roles={state.roles}
                        currentValues={state.currentValues[currentUser.User]}
                        handleChange={handleChange}
                        hideButtons
                    />
                    <div className="mt-4" />
                    <UserNotifications user={currentUser} editable />
                </>}
                {canEditOtherUsers && state.users.filter(filterUsers).length > 0
                    && <Pager
                        pageSize={50}
                        renderContainer={(children: React.ReactElement, handleScroll: (e: any) => void) => <Grid
                            style={{ overflow: 'auto', maxHeight: `calc(100vh - ${topbarHeight ? topbarHeight + 65 : 70}px)`, paddingBottom: 85, }}
                            onScroll={handleScroll}
                            container
                            spacing={1}>
                                {viewType === "list" && <UserListHeaders />}
                            {children}
                        </Grid>}
                        items={state.users.filter(filterUsers).sort(sortUsers)}
                        renderItem={(u: IUser) => {
                            if(viewType === "cards")
                                return <UserRolesCard
                                    key={u.User + u.Id}
                                    user={u}
                                    roles={state.roles}
                                    currentValues={state.currentValues[u.User]}
                                    handleChange={handleChange}
                                />
                            else if(viewType === "list"){
                                return <UserRolesListRow
                                key={u.User + u.Id}
                                user={u}
                                roles={state.roles}
                                currentValues={state.currentValues[u.User]}
                                handleChange={handleChange}
                            />
                            }
                            else{
                                return <></>
                            }
                                
                        }}

                    />
                }
                {canEditOtherUsers && state.users.filter(filterUsers).length === 0 && <p className="text-xl text-white text-center mx-auto my-2">Nessun risultato</p>}
            </div>
        </div>
}
export { UserSettingsPage }