export enum AssignmentStatusEnum{
    StandBy = "StandBy",
    New = "New",
    Viewed = "Viewed",
    Assigned = "Assigned",
    Resolved = "Resolved",
}
export enum EticketSourceTypeEnum 
{
    Metric = "Metric",
    SuperMetric = "SuperMetric",
}
export interface ITag {
    Tag:string
}
export type TAssignment = {
    Id: number,
    Description?:string,
    OpeningTime: string,
    ExpirationTime: string,
    ClosureTime: string,
    ClosureReason: string,
    EscalationNotificationTime: string,
    Notes: string,
    RowVersion: string,
    ConfigurationId: number,
    BuildingName:string,
    Polo:string,
    // AlertId: number,
    SourceType:EticketSourceTypeEnum,
    ExternalEventId:number,
    ExternalReferenceId:number,
    Status: AssignmentStatusEnum,
    LastStatusChangingTime: string,
    OwnerEmail: string,
    UnlockColumn?:string
    Tags:ITag[]
    Presidio:string
    CRImmobile:string
}

export interface IAssignmentConfiguration {
    OwnerMail:string
    MetricId:number,
    ClosureReason:string[],
    IsAutomaticClose:boolean
}