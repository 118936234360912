import React from 'react';
import { IconProps } from './iconsConstants';
import './styles.css'
import { DashboardSideIcon } from './DashboardSideIcon';
import { default_icon_height } from './constants';
import { FaPlay } from 'react-icons/fa'
import { ProductPageSection } from '../../../hooks/useProductDashboardDimensions';
const TutorialsIcon: React.FC<IconProps> = ({onlyIcon}) => {
    // const icon = <Logo style={{ fill: 'rgb(51,65,85)', color: 'rgb(51,65,85)',stroke:'rgb(51,65,85)',...default_icon_styles }} />
    const icon = <FaPlay style={{ fill: 'rgb(51,65,85)', color: 'rgb(51,65,85)',stroke:'rgb(51,65,85)',height:default_icon_height-5,width:default_icon_height-5 }} />
    const id = ProductPageSection.TUTORIALS
    const tooltip = {
        id:`${id}-dashboard_icon`,
        text:"Tutorials"
    }
    return <DashboardSideIcon
        onlyIcon={onlyIcon}
        id={id}
        tooltip={tooltip}
        icon={icon}
    />
}
export { TutorialsIcon }