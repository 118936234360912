import { IconButton } from '@mui/material'
import React from 'react'
import { FaLongArrowAltLeft } from 'react-icons/fa'
import { useLocation, useNavigate } from 'react-router-dom'
import { assignments_known_urls } from '../../../types/userStore'
import { know_urls } from '../../../../../types/userStore'
type PROPS = {}
export const GoBackIcon: React.FC<PROPS> = () => {
    const navigate = useNavigate();
    const {pathname} = useLocation();
    const handleBack = React.useCallback(() => {
        switch (pathname) {
            case assignments_known_urls.wrong_home: return navigate('/');
            case assignments_known_urls.home: return navigate('/');
            case assignments_known_urls.input_events_list: return navigate(assignments_known_urls.home);
            case assignments_known_urls.assignment_list: return navigate(assignments_known_urls.home);
            case assignments_known_urls.tickets_list: return navigate(assignments_known_urls.home);
            case assignments_known_urls.ticket_detail: return navigate(assignments_known_urls.tickets_list);
            case assignments_known_urls.assignment_detail: return navigate(assignments_known_urls.assignment_list);
            case assignments_known_urls.alert_event_detail: return navigate(assignments_known_urls.input_events_list);
            case assignments_known_urls.supermetric_event_detail: return navigate(assignments_known_urls.input_events_list);
            default: navigate(-1);
        }
    }, [navigate]);
    return <IconButton onClick={handleBack}>
        <FaLongArrowAltLeft color='white' />
    </IconButton>
}