import { FontIcon } from '@fluentui/react';
import React from 'react';
import './styles.css'
import { TFile } from './FileExplorer';
import moment from 'moment';
interface Props {
    file: TFile

}
const Directory: React.FC<Props> = ({ file }) => {
    const [isExpanded, setIsExpanded] = React.useState(file.Name === moment().year().toString());
    const handleClick = (e: any) => {
        e.stopPropagation();
        if (file.Type === "File") {
            window.open(file.Url)
        } else {
            setIsExpanded((prev) => !prev)
        }
    }
    return file.Type === 'File'
        ?
        <div className="flex flex-row" onClick={handleClick}>
            <img style={{ margin: 'auto 2px', height: 30, width: 30, objectFit: 'contain' }} src={`https://res-1.cdn.office.net/files/fabric-cdn-prod_20221209.001/assets/item-types/16/${file.Name.split('.').pop()?.toUpperCase()}.svg`} />
            <p className="cursor-pointer text-end mx-1 my-1 file-p">{file.Name}</p>
        </div>
        :
        <div className="flex flex-col">
            <div className={`px-1 flex ${file.Items ? 'hover:bg-transparent cursor-pointer rounded-lg' : ''}`} onClick={handleClick}>
                <FontIcon style={{ color: 'rgb(247,188,11)', fill: 'yellow', height: 30, width: 30, margin: 'auto 2px', fontSize: '1.5rem' }} iconName={isExpanded ? 'OpenFolderHorizontal' : 'FabricFolderFill'} />
                <p className="cursor-pointer text-end mx-1 mt-auto file-p">{file.Name}</p>
            </div>
            <div className="flex flex-col ml-4">
                {
                    isExpanded && file.Items && file.Items.map((f) => (<Directory key={f.Name} file={f} />))
                }
            </div>
        </div>

}
export { Directory }