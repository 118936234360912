import React from "react";
import {useLocation} from "react-router-dom";
import {paginationContext, PaginationEnum} from "../../../../store/pager";
import {IconButton, Snackbar} from "@mui/material";
import {CgExport} from "react-icons/cg";
import {LightTooltip} from "../../../LightTooltip/LightTooltip";
export type ExportFilteredGridButtonProps = {
  enumKey: PaginationEnum;
};
export const ExportFilteredGridButton: React.FC<
  ExportFilteredGridButtonProps
> = ({enumKey}) => {
  const {pathname} = useLocation();
  const {state} = React.useContext(paginationContext);
  const {filters, filtersPresets} = state.pagination[enumKey];
  const [showSnackbar, setShowSnackbar] = React.useState(false);
  const encodedFilters = encodeURIComponent(JSON.stringify(filters));
  const encodedPresets = encodeURIComponent(JSON.stringify(filtersPresets));
  const encodedUrl = `${window.location.origin}${pathname}?filters=${encodedFilters}&presets=${encodedPresets}`
  return (
    <>
      <LightTooltip title={<p className='text-xl'>Esporta filtri</p>}>
        <IconButton
          onClick={() => {
            navigator.clipboard.writeText(
              // `${window.location.href}/${encodeURIComponent(JSON.stringify(filters))}/${encodeURIComponent(JSON.stringify(filtersPresets))}`
              // `${window.location.href}/${JSON.stringify(filters)}/${JSON.stringify(filtersPresets)}`
              encodedUrl
            );
            setShowSnackbar(true);
          }}
        >
          <CgExport />
        </IconButton>
      </LightTooltip>
      <Snackbar
        open={showSnackbar}
        autoHideDuration={6000}
        message="Copiato negli appunti"
        onClose={() => setShowSnackbar(false)}
      />
    </>
  );
};
