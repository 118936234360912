import React from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { assignments_known_urls } from "../../types/userStore";
import { PaginationEnum } from "../../store/pager";
import { isMobile } from "react-device-detect";
import { ODataGrid } from "../ODataGrid/ODataGrid";
import { useHeaders } from "../../store/hooks/useHeaders";
import { useQuery } from "../../utility/useQuery";
import { Spacer } from "../Spacer/Spacer";
import { FilterPresetsIcon } from "../FilterPresetsIcon/FilterPresetsIcon";
import { TICKETS_CONTROLLER } from "../../services/eticket";
import { ETicket } from "../../types/etickets";
type Props = {
};
export const ETicketListNavigator: React.FC<Props> = () => {
  const { ticketHeaders } = useHeaders();
  const navigate = useNavigate();
  const { pathname } = useLocation();
  const query = useQuery();
  const buildUrl = (a: ETicket) => `${assignments_known_urls.ticket_detail}?id=${a.Id}`
  const isCurrent = (a: ETicket) => pathname === assignments_known_urls.ticket_detail && query.get('id') === a.Id.toString()
  const handleTicketClick = (a: ETicket) => {
    navigate(buildUrl(a));
  };
  return (
    <>
      <Spacer
        spacing={2}
        direction="y"
      />
      <FilterPresetsIcon enumKey={PaginationEnum.TICKETS} />
      <ODataGrid
        setHeaders={() => { }}
        expands=""
        hideHeaders
        controllerName={TICKETS_CONTROLLER}
        enumStoreKey={PaginationEnum.TICKETS}
        headers={ticketHeaders}
        renderItem={(a: ETicket) => (
          <div
            key={a.Id}
            onClick={() => handleTicketClick(a)}
            className={`flex rounded cursor-pointer  ${isMobile ? "" : "hover:!border-dotted hover:!border-gray-400"
              } `} //${a.Status === "New" && !isMobile ? "!text-black bg-pink-100 !border-black" : 'bg-white'}
            style={{ border: "1px solid white", marginBottom: 1 }}
          >
            <p className={`text-lg text-center w-[100%] ${isCurrent(a) ? "underline font-bold " : ''}`}>{a.Id}-{a.Description}</p>
          </div>
        )}
      />
    </>
  );
};
