import React from 'react'
import { Grid } from '@mui/material'
import { ETicketEventDetail } from '../ETicketEventDetail/ETicketEventDetail'
import { EticketSourceTypeEnum } from '../../types/assignments'
import { AssignmentEventDetail } from '../AssignmentEventDetail/AssignmentEventDetail'
import { ExtendedAlertDetail } from '../AlertDetail/ExtendedAlertDetail'
import { useSidebars } from '../../store/hooks/useSidebars'
import { EventNotificationsHistory } from '../EventNotificationsHistory/EventNotificationsHistory'
type Props = {
    id: number
}
export const AlertEventDetail: React.FC<Props> = ({ id }) => {
    const {
        sidebarIsOpen,
        state:sidebarsState
    } = useSidebars();
   return <div className='flex flex-col justify-center items-center pb-2'>
        <ExtendedAlertDetail showContainerOnLoading id={id} />
        <p className='text-3xl font-bold text-center'>Azioni in corso</p>
        <Grid 
        style={{width: `calc(100vw - ${sidebarIsOpen ? sidebarsState.sidebarWidth : 0}px)`,}}
        container spacing={3}>
            <AssignmentEventDetail sourceType={EticketSourceTypeEnum.Metric} id={id} />
            <ETicketEventDetail sourceType={EticketSourceTypeEnum.Metric} id={id} />
        </Grid>
        <div className='mt-4 border-b border-solid border-gray-200'/>
        <EventNotificationsHistory eventId={id} sourceType={EticketSourceTypeEnum.Metric}/>
    </div>
}