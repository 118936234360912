import React from "react";
import { DashboardHeaderText } from "../../DashboardHeader/DashboardHeaderText";
import { useDashboardsStore } from "../../../store/hooks/useDashboardsStore";
import { SearchAssets } from "../../../store/dashboardsStore";
import { SpecialReportIcon } from "../Icons/SpecialReportIcon";
import { HeaderProps } from "./types";
import { ProductPageSection } from "../../../hooks/useProductDashboardDimensions";
import { AddAssetPanel } from "../../AddAssetPanel/AddAssetPanel";
const SpecialReportColumnHeader: React.FC<HeaderProps> = ({ settings }) => {
  const { search, contextDispatch,addMode } = useDashboardsStore();
  const setSearchValue = (s: string) =>
    contextDispatch({
      type: "SET_SEARCH",
      payload: {
        ...search,
        [SearchAssets.SPECIAL_REPORTS]: s,
      },
    });
  const handleSearchChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setSearchValue(e.target.value);
  };
  const handleClosePanel = (e: any) => {
    contextDispatch({ type: "TOGGLE_ADD_MODE", payload: "specialReports" });
  };
  return (
    <>
      <DashboardHeaderText
        id={ProductPageSection.SPECIAL_REPORTS}
        icon={<SpecialReportIcon onlyIcon />}
        settings={settings}
        editable={true}
        headerType={"specialReports"}
        text="Report di business"
        setSearchValue={setSearchValue}
        handleSearchChange={handleSearchChange}
        searchKey={SearchAssets.SPECIAL_REPORTS}
      />
      {addMode.SPECIAL_REPORTS && (
        <AddAssetPanel type="specialReports" closePanel={handleClosePanel} />
      )}
    </>
  );
};
export { SpecialReportColumnHeader };
