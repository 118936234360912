import React from 'react'
import { TAssignment } from '../../../../types/assignments'
import { Typography } from '@mui/material'
import { useNavigate } from 'react-router-dom'
import { assignments_known_urls } from '../../../../types/userStore'
import { formatDate } from '../../../../utility/formatDate'
import { ISortableHeader } from '../../../../types/general'
import { Status_Mapper } from '../../../../store/hooks/useHeaders'
import { useIsMobile } from '../../../../utility/useIsMobile'
import { DetailsFocusPaper } from '../../../DetailsRow/DetailsFocusPaper/DetailsFocusPaper'
import { intesa_green } from '../../../../../../themes/themes'
type Props = {
    assignment: TAssignment,
    headers: ISortableHeader<TAssignment>[]
}
export const AssignmentCard: React.FC<Props> = ({ assignment, headers }) => {
    const { isMobile } = useIsMobile();
    const navigate = useNavigate();
    const handleAssignmentClick = (a: TAssignment) => {
        navigate(`${assignments_known_urls.assignment_detail}?id=${a.Id}`)
    }
    const isNewStatus = assignment.Status === "New";
    return <div
        onClick={() => handleAssignmentClick(assignment)}
        className={`flex flex-col w-[90%] max-h-[350px] rounded ${isNewStatus && isMobile ? "!text-black border-intesa-green" : "shadow"}  mx-[5%] my-2`}
        style={{ border: '1px solid green' }} >
        <div className="flex  items-between" style={{ borderBottom: '1px solid' }}>
            <div className="flex flex-col w-[50%] pl-1">
                <Typography fontWeight={"bold"}>
                    ID:
                </Typography>
                <Typography>
                    {assignment.Id}
                </Typography>
            </div>
            <div className="flex flex-col w-[50%] items-end">
                {/* <Typography fontWeight={"bold"}>
                    Stato
                </Typography> */}
                <Typography align='center' style={{
                    color: isNewStatus ? intesa_green : 'black',
                    fontWeight: isNewStatus ? 'bold' : 'normal',
                    // border: '1px solid black',
                    // borderRadius:6,
                    padding:'2px 4px'
                }}>
                    {Status_Mapper[assignment.Status.toLowerCase()]}
                </Typography>
            </div>
        </div>
        <div className="flex items-between p-1">
            <Typography fontWeight={"bold"}>
                Owner:
            </Typography>
            <div className="ml-2" />
            <Typography style={{ overflowWrap: 'anywhere' }}>
                {assignment.OwnerEmail}
            </Typography>
        </div>
        <div className="flex  items-between">
            <div className="flex flex-col w-[50%] pl-1">
                <Typography fontWeight={"bold"}>
                    Data Creazione:
                </Typography>
                <Typography>
                    {formatDate(assignment.OpeningTime)}
                </Typography>
            </div>
            <div className="flex flex-col w-[50%] items-end pr-1">
                <Typography fontWeight={"bold"}>
                    Scadenza:
                </Typography>
                <Typography>
                    {formatDate(assignment.ExpirationTime)}
                </Typography>
            </div>
        </div>
        <DetailsFocusPaper>
            <div className="flex  flex-col items-center p-1 max-h-[250px] overflow-hidden">
                <Typography fontWeight={"bold"}>
                    Descrizione:
                </Typography>
                <div className="ml-1" />
                <Typography
                    textOverflow={"ellipsis"}
                    align='center'
                >
                    {assignment.Description}
                </Typography>
            </div>
        </DetailsFocusPaper>
    </div>

}