import React from 'react';
import { topbarHeight } from '../DashboardContainer/DashboardContainer';
type Props = {
    extraStyles?: React.CSSProperties
    title: string
    showBorder?:boolean
    children?:React.ReactFragment
}
const DashboardSection: React.FC<Props> = ({ children, extraStyles,title,showBorder }) => {
    return <div style={{height:'100%',}} className='w-[100%] items-center justify-center flex flex-col '>
        <p style={{ fontSize: '2.5rem' }} className={`text-center w-[100vw] ${showBorder ? 'border-b' : ''}`}>{title}</p>
        <div className="w-[100%] flex flex-row flex-wrap overflow-y-auto" style={{height:`calc(100vh - ${topbarHeight*2 + 5}px)`, ...extraStyles }}>
            {children}
        </div>
    </div>
}
export { DashboardSection }