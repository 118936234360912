import React from "react";
import {Link, useLocation} from "react-router-dom";
import {paginationContext} from "../../../../store/pager";
import {IconButton} from "@mui/material";
import {useIsMobile} from "../../../../utility/useIsMobile";
import {assignments_known_urls} from "../../../../types/userStore";

type SidebarIconProps = {
  onClick: () => void;
  label: string;
  icon: (color: string) => React.ReactElement;
  isSelected: boolean;
  targetUrl: string;
};

const SidebarIcon: React.FC<SidebarIconProps> = ({
  onClick,
  label,
  icon,
  isSelected,
  targetUrl,
}) => {
  const {isMobile, sidebarWidth} = useIsMobile();
  const {state} = React.useContext(paginationContext);
  const isLoading =
    state.pagination.ASSIGNMENTS.isLoading ||
    state.pagination.TICKETS.isLoading;
  const {pathname} = useLocation();
  const style: React.CSSProperties = {
    position: "relative",
    width: isMobile ? 80 : sidebarWidth - 10,
    height: isMobile ? "90%" : 58,
    transition: "all .2s ease",
    WebkitTransition: "all .2s ease",
    MozTransition: "all .2s ease",
    // borderBottom:(targetUrl !== know_urls.home && pathname.includes(targetUrl)) ? '2px solid green' : undefined
    // backgroundColor:pathname === targetUrl ? 'green' : 'white'
    // color:isSelected? 'black' : 'white'
  };
  const selectedIconColor = isMobile ? "black" : "white";
  const defaultIconColor = isMobile ? "lightgrey" : "black";
  const iconColor = isSelected ? selectedIconColor : defaultIconColor;
  const selectedBgClassnames = isMobile
    ? "bg-white text-black"
    : "bg-intesa-green text-white";
  const defaultBgClassnames = isMobile
    ? "bg-transparent text-black"
    : "bg-white text-black";
  const classNames = `flex flex-col ml-0.5 rounded ${
    isSelected ? selectedBgClassnames : defaultBgClassnames
  } ${isLoading ? "cursor-default" : "cursor-pointer"}`;
  const handleClick = () => {
    if (isLoading) return;
    onClick();
  };
  return (
    <Link
      style={style}
      to={targetUrl}
      className={classNames}
      onClick={handleClick}
    >
      <IconButton
        className={`${
          isLoading ? "cursor-default" : "cursor-pointer"
        } hover:!bg-transparent`}
        style={{height: 40}}
      >
        {icon(iconColor)}
      </IconButton>
      <p
        style={{
          bottom: 0,
          right: "calc(50% - 50px)",
          width: 100,
          color: iconColor,
        }}
        className="absolute text-sm font-semibold text-center"
      >
        {label}
      </p>
      {targetUrl !== assignments_known_urls.home && pathname.includes(targetUrl) && (
        <div
          style={{
            position: "absolute",
            right: 0,
            bottom: 0,
            borderTop: "5px solid transparent",
            borderBottom: "5px solid rgb(21,128,61)",
            borderLeft: "5px solid transparent",
            borderRight: "5px solid rgb(21,128,61)",
          }}
        />
      )}
    </Link>
  );
};
export {SidebarIcon};
