export type TAction = "UserConfiguration" | "BuildingConfiguration";
export type TRoles = "base" | "advanced" | "admin";
export enum assignments_known_urls {
  wrong_home = "/assignments/",
  home = "/assignments",
  assignment_list = "/assignments/internal-tickets",
  filtered_assignment_list="/assignments/internal-tickets/:filters/:presets",
  assignment_detail = "/assignments/internal-tickets/detail",
  tickets_list = "/assignments/tickets",
  ticket_detail="/assignments/tickets/detail",
  settings="/assignments/settings",
  input_events_list="/assignments/events-list",
  alert_event_detail="/assignments/alert-event-detail",
  supermetric_event_detail="/assignments/supermetric-event-detail"
}
