import React from 'react'
import { EticketSourceTypeEnum, TAssignment } from '../../../../types/assignments'
import { Typography } from '@mui/material'
import { useNavigate } from 'react-router-dom'
import { assignments_known_urls } from '../../../../types/userStore'
import { formatDate } from '../../../../utility/formatDate'
import { ISortableHeader } from '../../../../types/general'
import { Status_Mapper } from '../../../../store/hooks/useHeaders'
import { useIsMobile } from '../../../../utility/useIsMobile'
import { IInputEvent } from '../../../../types/eventNotifications'
type Props = {
    item: IInputEvent,
    headers: ISortableHeader<IInputEvent>[]
}
export const DataMonitorCard: React.FC<Props> = ({ item, headers }) => {
    const { isMobile } = useIsMobile();
    const navigate = useNavigate();
    const sourceTypeRouteMap: { [key in EticketSourceTypeEnum]: string } = {
        [EticketSourceTypeEnum.Metric]: "alert-event-detail",
        [EticketSourceTypeEnum.SuperMetric]: "supermetric-event-detail",
    }
    const handleRowClick = (a: IInputEvent) => {
        navigate(`/${sourceTypeRouteMap[a.SourceType]}?id=${a.ExternalEventId}`);
    };
    return <div
        onClick={() => handleRowClick(item)}
        className={`flex flex-col w-[90%] max-h-[350px] rounded shadow mx-[5%] my-2`}
        style={{ border: '1px solid' }} >
        <div className="flex  items-between" style={{ borderBottom: '1px solid' }}>
            <div className="flex flex-col w-[50%] pl-2">
                <Typography fontWeight={"bold"}>
                    ID Riferimento:
                </Typography>
                <Typography>
                    {item.ExternalReferenceId}
                </Typography>
            </div>
            <div className="flex flex-col w-[50%] items-end pr-2">
                <Typography fontWeight={"bold"}>
                    ID Evento
                </Typography>
                <Typography>
                    {item.ExternalEventId}
                </Typography>
            </div>
        </div>
        <div className="flex items-between p-1">
            <Typography fontWeight={"bold"}>
                Stato:
            </Typography>
            <div className="ml-2" />
            <Typography >
                {item.IsActive ? "Attivo" : "Disattivo"}
            </Typography>
        </div>
        <div className="flex items-between p-1">
            <Typography fontWeight={"bold"}>
                Data apertura:
            </Typography>
            <div className="ml-2" />
            <Typography>
                {formatDate(item.OpenDate)}
            </Typography>
        </div>
        <div className="flex items-between p-1">
            <Typography fontWeight={"bold"}>
                Data chiusura:
            </Typography>
            <div className="ml-2" />
            <Typography >
                {formatDate(item.CloseDate)}
            </Typography>
        </div>
        <div className="flex items-between p-1 w-[100%]">
            <div className='grow'>
                <Typography fontWeight={"bold"}>
                    Valore Attuale:
                </Typography>
                <div className="ml-2" />
                <Typography>
                    {item.Value} {item.UoM}
                </Typography>
            </div>
            <div className='grow pr-4'>
                <Typography fontWeight={"bold"} align='right'>
                    Valore Target:
                </Typography>
                <div className="ml-2" />
                <Typography align='right'>
                    {item.TargetValue} {item.UoM}
                </Typography>
            </div>
        </div>
    </div>

}