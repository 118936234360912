import React from "react"
import { IconButton } from "@mui/material"
import { CgFileDocument } from "react-icons/cg"
import { MdOutlineEditNotifications } from "react-icons/md"
import { UserProductsToggle } from "../../UserProductsToggle/UserProductsToggle"
import { UserPanel } from "../../UserPanel/UserPanel"
import { UserNotificationsPanel } from "../../UserNotificationsPanel/UserNotificationsPanel"
import { useUserContext } from "../../../store/hooks/useUserContext"
import { IUser, IUserRole } from "../../../services/user"
import { TProduct } from "../../../services/products"
type Props = {
    user: IUser;
    whiteText?: boolean
    hideButtons?: boolean
    handleChange: (
        u: IUser,
        a: TProduct,
        ruoloId?: string,
        enable?: boolean
    ) => Promise<void>;
    currentValues?: { [key: string]: { RuoloId: string; enable: boolean } };
    roles: IUserRole[];
}
export const UserRoles: React.FC<Props> = ({ whiteText, hideButtons, user, currentValues, handleChange, roles }) => {
    const { account } = useUserContext();
    const isMe = account.username === user.User;
    const [panelIsOpen, setPanelIsOpen] = React.useState(false);
    const [userNotificationsPanelIsOpen, setUserNotificationsPanelIsOpen] = React.useState(false);
    return <><div className="flex w-[100%] items-center">
        <p className={`text-xl ${whiteText ? 'text-white' : ''}`}>{user.User}</p>
        <div className="ml-auto" />
        {!hideButtons && <><IconButton
            onClick={() => setUserNotificationsPanelIsOpen(true)}
            className="!rounded !bg-transparent !hover:bg-transparent !text-white"
            style={{
                zIndex: 9999,
            }}
        >
            <MdOutlineEditNotifications />
        </IconButton>
            <IconButton
                onClick={() => setPanelIsOpen(true)}
                className="!rounded !bg-transparent !hover:bg-transparent !text-white"
                style={{
                    zIndex: 9999,
                }}
            >
                <CgFileDocument style={{ fill: 'white', stroke: 'white', color: 'white' }} />
            </IconButton>
        </>}
    </div>
        <UserProductsToggle currentValues={currentValues} handleChange={handleChange} editable={!isMe} user={user} roles={roles} />
        <UserPanel
            roles={roles}
            isOpen={panelIsOpen}
            onClose={() => setPanelIsOpen(false)}
            user={user}
            currentValues={currentValues}
        />
        <UserNotificationsPanel
            isOpen={userNotificationsPanelIsOpen}
            onClose={() => setUserNotificationsPanelIsOpen(false)}
            user={user}
        />
    </>
}