import React from 'react'
import { settingsContext } from '../../../store/settingsStore'
import { Box, Step, StepLabel, Stepper } from '@mui/material';
export const assignments_stepper_elementId = "assignments-stepper"
export const AssignmentsVisualSteps: React.FC = () => {
    const { state: contextState, dispatch } = React.useContext(settingsContext);
    const { currentStep } = contextState;
    const defaultStepTitles = React.useMemo(() => [
        "Seleziona",
        "Compila",
        "Conferma"
    ], [])
    const handleClick = (i: number) => {
        if (i < currentStep)
            dispatch({ type: "SET_STEP", payload: i })
    }
    return <Box sx={{ width: '100%' }} id={assignments_stepper_elementId}>
        <Stepper activeStep={currentStep - 1} alternativeLabel>
            {defaultStepTitles.map((label, i) => (
                <Step
                    sx={{
                        '& .MuiStepLabel-root .MuiStepLabel-label': {
                            color: 'white',
                            cursor:'pointer'
                        },
                    }}
                    key={label}>
                    <StepLabel
                        onClick={() => handleClick(i)} className='!text-white'>{label}</StepLabel>
                </Step>
            ))}
        </Stepper>
    </Box>

}