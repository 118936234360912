import React from "react";
import { useNavigate } from "react-router-dom";
import { TProductAsset } from "../../../services/products";
import { useDashboardsStore } from "../../../store/hooks/useDashboardsStore";
import { TPileSize, TSize } from "../../../types/general";
import { Pile } from "../Pile";
import {
  EditableAssets,
  dashboardsContext,
} from "../../../store/dashboardsStore";
import { usePowerBIContext } from "../../../store/hooks/usePowerBiContext";
import { GetPowerBiItemInfos } from "../../../services/powerBi";
import { emergency_mails } from "../../../utility/emergency_mails";
import { useUserContext } from "../../../store/hooks/useUserContext";
type ScorecardPileProps = {
  productId: string;
  asset: TProductAsset;
  scorecardSize?: TPileSize;
};
const ScorecardPile: React.FC<ScorecardPileProps> = ({
  productId,
  asset,
  scorecardSize,
}) => {
  const navigate = useNavigate();
  const {account} = useUserContext();
  const {
    assetIsAccessible,
    isLoading
  } = usePowerBIContext();
  // const disabled = !assetIsAccessible(asset) 
  const disabled = false; //temp
  
  const { state: dashboards_state } = React.useContext(dashboardsContext);
  const { viewDisabledAssets } = dashboards_state;
//   React.useEffect(() => {
//     if (token && asset.AssetBag.ReportId) {
//       GetPowerBiItemInfos(asset.AssetType,asset.AssetBag.ReportId,token).then((res) => {
//           if (res.status >= 200 && res.status < 400) {
//             setDisabled(false);
//           } else {
//             return [];
//           }
//         })
//         .finally(() => setIsLoading(false));
//     }
//   }, [asset.AssetBag.ReportId, asset.AssetType, token]);
  const isEmergencyMail = emergency_mails.includes(account.username);
  const targetUrl = React.useMemo(
    () => isEmergencyMail ? 
      `https://app.powerbi.com.mcas.ms/groups/${asset.AssetBag.WorkspaceId}/scorecards/${asset.AssetBag.ReportId}?ctid=cc4baf00-15c9-48dd-9f59-88c98bde2be7&experience=power-bi` :
      `/report?type=scorecard&area=${productId}&Id=${asset.AssetBag.ReportId}&wsId=${asset.AssetBag.WorkspaceId}&name=${asset.Name}`,
    [productId, asset]
  );
  const handleClick = () => {
    navigate(targetUrl);
  };
  const getImg = React.useCallback((imgName?: string, imgRows?: number) => {
    const height = imgRows ? `${imgRows * 10}%` : "100%";
    if (!imgName) {
      return (
        <img
          src=""
          style={{ height, objectFit: "fill" }}
          alt={`Image for ${imgName} Missing`}
        />
      );
    }
    const isUrl = imgName?.startsWith("http");
    if (isUrl) {
      return (
        <img
          style={{
            height,
            objectFit: "fill",
            borderRadius: "8px 8px 0px 0px",
            margin: 0,
          }}
          src={imgName}
          alt="Image Missing"
        />
      );
    }
    try {
      return (
        <img
          style={{
            height,
            objectFit: "fill",
            borderRadius: "8px 8px 0px 0px",
            margin: 0,
          }}
          src={imgName ? require("../../../images/scorecards/" + imgName) : ""}
          alt="Image Missing"
        />
      );
    } catch (error) {
      console.log(error);
      return (
        <img
          src=""
          style={{ height, objectFit: "fill" }}
          alt={`Image for ${imgName} Missing`}
        />
      );
    }
  }, []);
  const { contextState } = useDashboardsStore();

  const { editMode } = React.useMemo(() => contextState, [contextState]);
  const assetKey = React.useMemo(() => {
    return asset.Id ? asset.Id.toString() : targetUrl;
  }, [asset, targetUrl]);
  if (!viewDisabledAssets && disabled) return null;
  return (
    <Pile
      openInNewTab={isEmergencyMail}
      loadingBiPreLoad={isLoading}
      disabled={disabled}
      id={assetKey}
      customColor
      titleCustomBgColor={disabled ? "gray" : "rgb(120 53 15)"}
      targetUrl={targetUrl}
      img={getImg(asset.AssetBag.Image)}
      title={asset.Name}
      productId={productId}
      edit={editMode[EditableAssets.SCORECARDS]}
      className={`relative shadow-xl m-2 cursor-pointer rounded-lg border border-amber-900 ${
        disabled ? "cursor-default" : "cursor-pointer"
      } ${disabled ? "bg-gray-300" : "bg-amber-800"} ${
        editMode[EditableAssets.SCORECARDS]
          ? ""
          : "hover:!bg-amber-700 hover:!text-white"
      }`}
      showHelp
      tooltip={{
        id: asset.AssetBag.ReportId ?? asset.Id.toString(),
        text: asset.Description ?? "",
      }}
      key={assetKey}
      size={scorecardSize ?? "x1"}
      titleSize="xl"
      onClick={handleClick}
      asset={asset}
      deletable
    />
  );
};
export { ScorecardPile };
