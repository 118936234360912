import { CgFileDocument } from "react-icons/cg";
import React from "react";
import { IUser, IUserRole } from "../../services/user";
import { TProduct } from "../../services/products";
import { useUserContext } from "../../store/hooks/useUserContext";
import { Card, Grid, IconButton } from "@mui/material";
import { UserPanel } from "../UserPanel/UserPanel";
import { UserProductsToggle } from "../UserProductsToggle/UserProductsToggle";
import { UserNotificationsPanel } from "../UserNotificationsPanel/UserNotificationsPanel";
import { MdOutlineEditNotifications } from "react-icons/md";
import { UserRoles } from "./UserRoles/UserRoles";
import { hasPermission } from "../../store/hooks/useUserStore";
import { known_actions } from "../../types/userStore";
import { UserRolesProps } from "./types";
;
const UserRolesCard: React.FC<UserRolesProps> = ({
  currentValues,
  user,
  handleChange,
  roles,
}) => {
  const { account,userClaims } = useUserContext();

  const isMe = account.username === user.User;
  return (
    <>
      <Grid item xs={12} md={6} lg={6} xl={4} key={user.User}>
        <Card
          className={`p-2 !rounded-lg ${
            isMe ? "!bg-slate-800 !text-white" : "!bg-slate-600 !text-white"
          }`}
          style={{
            border: isMe ? "1px solid rgb(150,150,150)" : "1px solid rgb(150,150,150)",
            // borderRadius: 1,
          }}
        >
          <UserRoles 
            user={user}
            currentValues={currentValues}
            roles={roles}
            handleChange={handleChange}
          />
        </Card>
      </Grid>
      
    </>
  );
};
export { UserRolesCard };
