import React from 'react'
import { ISuperMetricConfiguration } from '../../types/etickets'
import { Button, FormControl, InputLabel, LinearProgress, MenuItem, OutlinedInput, Select, Snackbar } from '@mui/material'
import { AssignmentStatusEnum, TAssignment } from '../../types/assignments'
import { resolveAssignment } from '../../services/assignments'
import { getSuperMetricConfiguration } from '../../services/supermetric'
import { useSidebars } from '../../store/hooks/useSidebars'
import { intesa_green } from '../../../../themes/themes'
import { useUserContext } from '../../../../store/hooks/useUserContext'
type Props = {
    externalReferenceId: number
    assignment: TAssignment
}
export const SuperMetricDetailToolbar: React.FC<Props> = ({ externalReferenceId: superMetricId, assignment }) => {
    const { isMobile } = useSidebars();
    const { account } = useUserContext();
    const notResolvable = React.useMemo(() => account.username.toLowerCase() !== assignment.OwnerEmail.toLowerCase() || assignment.Status === AssignmentStatusEnum.Resolved, [assignment, account])
    // const notResolvable = React.useMemo(() => assignment.Status === AssignmentStatusEnum.Resolved, [assignment, account])
    const [isLoading, setIsLoading] = React.useState(false);
    const [superMetricConfiguration, setSuperMetricConfiguration] = React.useState<ISuperMetricConfiguration>()
    React.useEffect(() => {
        getSuperMetricConfiguration(superMetricId)
            .then((cfg) => setSuperMetricConfiguration(cfg))
            .catch((err) => console.log(err))
    }, [superMetricId])
    // const mockReasons = ["Test", "Test2"]
    // const [reason, setReason] = React.useState(mockReasons[0])
    const [reason, setReason] = React.useState<string | null | undefined>(superMetricConfiguration?.ClosureReason[0])
    const handleChange: React.ChangeEventHandler<HTMLInputElement> = (e) => {
        setReason(e.target.value)
    }
    console.log(superMetricConfiguration,account,notResolvable,reason)
    const [openSnackbar, setOpenSnackBar] = React.useState(false);
    const handleClick = () => {
        if (reason) {
            setIsLoading(true);
            resolveAssignment(assignment.Id, reason)
                .then((res) => setOpenSnackBar(true))
                .finally(() => {
                    setReason('')
                    setIsLoading(false)
                });
        }
    }
    // console.log(!reason || isLoading || notResolvable,reason,isLoading,notResolvable,account.username,assignment.OwnerEmail)

    const label = "Motivazione Chiusura"
    if (isLoading) {
        return <LinearProgress style={{ color: intesa_green, width: 100, height: 10, marginTop: 20 }} />
    }
    return <div
        style={{ width: isMobile ? 'calc(100vw - 40px)' : '75%' }}
        className='border rounded p-2 border-intesa-green bg-gray-100 my-2'>
        {superMetricConfiguration && <div className={`${isMobile ? 'flex flex-col justify-center items-center mb-4' : 'flex items-center'}`}>
            <p className={`${isMobile ? 'text-lg' : 'text-2xl'}`}>
                Se ritieni che questa segnalazione sia già stata gestita o non sia rilevante/corretta ti chiediamo Risolvere il caso inserendo un commento. Altrimenti agisci per risolvere l’anomalia; solo in seguito alla gestione dell’anomalia, il caso si risolverà automaticamente sul portale
            </p>
            <div className='flex items-center justify-end' style={{ marginTop: 10, }}>
                <FormControl variant="outlined" style={{ minWidth: 200, height: 60 }}>
                    <InputLabel htmlFor={"supermetricdetail-toolbar-select"}>{label}</InputLabel>
                    {superMetricConfiguration.IsAutomaticClose ?
                        <OutlinedInput
                            disabled={!superMetricConfiguration.ClosureReason || isLoading || notResolvable}
                            value={reason} style={{ height: 60 }}
                            id={"supermetricdetail-toolbar-select"} label={label} onChange={handleChange} />
                        :
                        <Select
                            disabled={!superMetricConfiguration.ClosureReason || isLoading || notResolvable}
                            id="supermetricdetail-toolbar-select"
                            label={label}
                            input={<OutlinedInput style={{ height: 60 }} id={"supermetricdetail-toolbar-select"} label={label} onChange={handleChange} />}

                        >
                            {superMetricConfiguration.ClosureReason.map((reason) => <MenuItem value={reason}>{reason}</MenuItem>)}
                        </Select>
                    }
                </FormControl>
                <div className='ml-4' />

                <Button

                    style={{ height: 60, width: 120 }}
                    variant='contained'
                    disabled={!reason || isLoading || notResolvable}
                    onClick={handleClick}
                >
                    Invia
                </Button>
                <Snackbar
                    open={openSnackbar}
                    autoHideDuration={6000}
                    onClose={() => setOpenSnackBar(false)}
                    message={`Segnalazione risolta correttamente`}
                />
            </div>
        </div>}
    </div>
}