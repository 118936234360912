import React from "react";
import { TicketRow } from "./TicketRow/TicketRow";
import { useNavigate } from "react-router-dom";
import { assignments_known_urls } from "../../types/userStore";
import { PaginationEnum, paginationContext } from "../../store/pager";
import { ISortableHeader } from "../../types/general";
import {
  TICKETS_CONTROLLER,
  getPagedTickets,
} from "../../services/eticket";
import { ETicket } from "../../types/etickets";
import { ODataGrid } from "../ODataGrid/ODataGrid";
import { useIsMobile } from "../../utility/useIsMobile";
type Props = {
  headers: ISortableHeader<ETicket>[];
};
export const TicketsGrid: React.FC<Props> = ({ headers }) => {
  const { isMobile } = useIsMobile();
  const { state: paginationState, dispatch: paginationDispatch } =
    React.useContext(paginationContext);
  const {
    isLoading,
    sortings: ticketsSortings,
    filters: ticketsFilters,
    filtersPresets,
    items: tickets,
  } = paginationState.pagination.TICKETS;
  const handleRemoveSort = (
    e: any,
    header: ISortableHeader<ETicket>
  ) => {
    e.preventDefault();
    if (isLoading) return;
    paginationDispatch({
      type: "SET_SORTINGS",
      payload: {
        key: PaginationEnum.TICKETS,
        sortings: ticketsSortings.filter((s) => s.key !== header.id),
      },
    });
  };
  const headerCurrentSort = (header: ISortableHeader<ETicket>) =>
    ticketsSortings.find((h) => h.key === header.id);
  const headerCurrentFilter = (header: ISortableHeader<ETicket>) =>
    ticketsFilters.find((h) => h.columnName === header.id);
  const [localFilter, setLocalFilter] = React.useState(
    Object.fromEntries(
      headers.map((h) => [h.id, headerCurrentFilter(h)?.value])
    )
  );
  const handleSetLocalFilter = (
    h: ISortableHeader<ETicket>,
    value: string | number
  ) => setLocalFilter({ ...localFilter, [h.id]: value });
  const setHeaderFilter = (
    header: ISortableHeader<ETicket>,
    value?: any
  ) => {
    if (!value) {
      removeHeaderFilter(header);
    } else {
      const operator = header.defaultFilterOperator ?? "contains";
      const newValue = value ?? localFilter[header.id];
      paginationDispatch({
        type: "SET_FILTERS_AND_PRESETS",
        payload: {
          key: PaginationEnum.TICKETS,
          presets: filtersPresets.filter(
            (preset) => preset.filter.columnName !== header.id
          ),
          filters: ticketsFilters.find((f) => f.columnName === header.id)
            ? ticketsFilters.map((f) =>
              f.columnName === header.id
                ? {
                  columnName: header.id,
                  value: newValue,
                  operator,
                  valueType: header.valueType,
                }
                : f
            )
            : [
              ...ticketsFilters,
              {
                columnName: header.id,
                value: newValue,
                operator,
                valueType: header.valueType,
              },
            ],
        },
      });
    }
  };
  const removeHeaderFilter = (h: ISortableHeader<ETicket>) => {
    paginationDispatch({
      type: "SET_FILTERS_AND_PRESETS",
      payload: {
        key: PaginationEnum.TICKETS,
        filters: ticketsFilters.filter((f) => f.columnName !== h.id),
        presets: filtersPresets.filter(
          (preset) => preset.filter.columnName !== h.id
        ),
      },
    });
    setLocalFilter({ ...localFilter, [h.id]: "" });
  };
  const navigate = useNavigate();
  const handleRowClick = (a: ETicket) => {
    navigate(`${assignments_known_urls.ticket_detail}?id=${a.Id}`);
  };
  return (
    <ODataGrid
      setHeaders={() => { }}
      expands=""
      controllerName={TICKETS_CONTROLLER} // state={paginationState.pagination.TICKETS}
      // setState={(pagination) => paginationDispatch({ type: "SET_PAGINATION", payload: { key: PaginationEnum.TICKETS, pagination } })}
      // currentFilters={ticketsFilters}
      // currentSortings={ticketsSortings}
      // currentItems={tickets}
      enumStoreKey={PaginationEnum.TICKETS}
      // getPagedItems={getPagedTickets}
      // handleRemoveSort={handleRemoveSort}
      // headerCurrentFilter={headerCurrentFilter}
      // headerCurrentSort={headerCurrentSort}
      headers={headers}
      // isLoading={isLoading}
      // lastCallFilters={paginationState.pagination.ASSIGNMENTS.LAST_CALL_FILTERS}
      // lastCallSortings={paginationState.pagination.ASSIGNMENTS.LAST_CALL_SORTINGS}
      // localFilter={localFilter}
      // removeHeaderFilter={removeHeaderFilter}
      // setHeaderFilter={setHeaderFilter}
      // setLocalFilter={handleSetLocalFilter}
      //   showFilters={paginationState.pagination.ASSIGNMENTS.showFilters}
      renderItem={(a: ETicket) => (
        <div
          key={a.Id}
          onClick={() => handleRowClick(a)}
          className={`flex rounded cursor-pointer ${isMobile ? "" : "hover:!border-dotted hover:!border-gray-400"
            } bg-white`}
          style={{ border: "1px solid white", marginBottom: 1 }}
        >
          <TicketRow enumStoreKey={PaginationEnum.TICKETS} headers={headers} item={a} />
        </div>
      )}
    />
  );
};
