import { Typography } from '@mui/material'
import React from 'react'
import { useIsMobile } from '../../utility/useIsMobile'
type Props = { extraClassnames?: string, bold?: boolean, value: string | number | null | undefined, align?: "center" | "right" | "left" | "inherit" | "justify" }
export const DetailsValue: React.FC<Props>
    = ({ bold, value, align, extraClassnames }) => {
        const {isMobile} = useIsMobile();
        return <div style={{}} className='h-[100%] min-h-[20px] max-w-[95%] overflow-hidden' >
            <Typography
                variant='body1'
                align={align ?? (isMobile ? 'center' : 'right')}
                className={`${isMobile ? "!text-xl" : "!text-2xl"} ${bold ? "!font-bold" : ""} grow ${extraClassnames ?? ''}`}>
                {value}
            </Typography>
        </div>

    }