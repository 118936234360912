import { know_urls } from "../../types/userStore";

export const NoTopbarUrls = [
    "/", 
    // '/iot-connected-buildings',
    know_urls.iot_dashboard,
    // '/energy', 
    know_urls.energy_dashboard,
    // '/occupancy', 
    know_urls.occupancy_dashboard,
    // '/dati-immobiliari',
    know_urls.assetimmobiliare_dashboard,
    // '/redirectCallback',
    know_urls.redirectCallback,
    know_urls.edit_assets
]