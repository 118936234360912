import React from "react";
interface Props {
    children: React.ReactNode,
    show:boolean,
    pileWidth:number|string
}
export const DashboardColumn: React.FC<Props> = ({ children,show, pileWidth }) => {
    
    return show ? <div className="flex flex-col h-[100%]" style={{width: pileWidth }}>
        {children}
    </div> : null;
}