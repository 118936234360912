import React from 'react'
import { TAssignment } from '../../../../types/assignments'
import { ODataFilterOperators } from '../../../../types/odata'
import { paginationContext, PaginationEnum } from '../../../../store/pager'
type PROPS = {
    assignment: TAssignment
}
export const TagsElementCell: React.FC<PROPS> = ({ assignment }) => {
    const { state, dispatch } = React.useContext(paginationContext);
    const setFilter = (newValue: string[]) => {
        const operator: ODataFilterOperators = "any";
        const enumStoreKey = PaginationEnum.ASSIGNMENTS;
        const headerId = "Tags"
        const valueType = "string"
        const nestedColumn = "Tag"
        dispatch({
            type: "SET_FILTERS_AND_PRESETS",
            payload: {
                key: enumStoreKey,
                presets: state.pagination[enumStoreKey].filtersPresets.filter(
                    (preset) => preset.filter.columnName !== headerId
                ),
                filters: state.pagination[enumStoreKey].filters.find(
                    (f) => f.columnName === headerId
                )
                    ? state.pagination[enumStoreKey].filters.map((f) =>
                        f.columnName === headerId
                            ? {
                                columnName: headerId,
                                value: newValue,
                                operator,
                                valueType,
                                nestedColumn: nestedColumn
                            }
                            : f
                    )
                    : [
                        ...state.pagination[enumStoreKey].filters,
                        {
                            columnName: headerId,
                            value: newValue,
                            operator,
                            nestedColumn,
                            valueType
                        },
                    ],
            },
        });
    }
    return <div className='flex w-[100%] items-center justify-center flex-wrap'>
        {assignment.Tags.map((t) => <p onContextMenu={(e) => {
            e.preventDefault();
            setFilter([t.Tag])
        }} className='text-white text-base font-bold shadow-lg px-1 border-white border grow max-w-[50%] w-[45%] text-center m-[1px] bg-intesa-green rounded-lg'>
            {t.Tag}
        </p>)}
    </div>
}