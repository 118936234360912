import React from 'react'
import { StyledContainedButton, StyledOutlinedButton } from '../../MainDashboard/UserNotificationsIcon/UserNotificationsIcon'
import { INITIAL_STEP, StepsEnum, initialConfigurationFormData, settingsContext } from '../../../store/settingsStore'
import { known_products } from '../../../types/userStore'
import { getAssignnmentMetrics, insertOrUpdateAssignmentConfigurationBulk } from '../../../services/assignments'
import { IConfiguration, IInsertOrUpdateConfigurations } from '../../../types/assignments'
import { TMetric } from '../../../types/alerts'
import { useUserContext } from '../../../store/hooks/useUserContext'
export const AssignmentsStepper: React.FC = () => {
    const [isLoading,setIsLoading] = React.useState(false);
    const { accessToken } = useUserContext();
    const { state: contextState, dispatch: contextDispatch } = React.useContext(settingsContext)
    const { currentStep, selectedProductId, assignmentConfigurations, selectedMetrics, configurationFormData, metricSortings } = contextState;
    const {
        OwnerMail: ownerMail,
        IsEscalationRequired: isEscalationRequired,
        EscalationReceiversMail: escalationReceiversMail,
        ClosureReason:closureReasons,
        IsAutomaticClose
    } = configurationFormData;
    const stringIsNotEmpty = (s: string) => s !== undefined && s !== ''
    const validFormData = React.useMemo(() => (IsAutomaticClose || closureReasons.length > 0) && stringIsNotEmpty(ownerMail) && (isEscalationRequired ? stringIsNotEmpty(escalationReceiversMail) : true)
        , [closureReasons.length, escalationReceiversMail, isEscalationRequired, ownerMail]);
    const handleSubmit = React.useCallback(async () => {
        if (selectedMetrics) {
            setIsLoading(true);
            const mapMetric: (x: TMetric) => IConfiguration = (x: TMetric) => x.AssignmentsConfiguration ? ({
                ...x,
                scorecardId: x.ScorecardId,
                productId: selectedProductId ?? '',
                metricId: x.Id,
                rowVersion: x.AssignmentsConfiguration.RowVersion,
                Id: x.AssignmentsConfiguration.Id,
                ...configurationFormData
            }) : ({
                ...x,
                scorecardId: x.ScorecardId,
                productId: selectedProductId ?? '',
                metricId: x.Id,
                ...configurationFormData
            })
            const resetContext = (message: string) => contextDispatch({
                type: "SET_STATE",
                payload: {
                    ...contextState,
                    popupMessage: message,
                    popupType: "success",
                    // selectedView: "ASSIGNMENTS",
                    // selectedMethod: "NEW",
                    selectedMetrics: null,
                    selectedProductId: known_products["iot-connected-buildings"].id,
                    currentStep: INITIAL_STEP,
                    configurationFormData: initialConfigurationFormData

                }
            })
            const payload: IInsertOrUpdateConfigurations = {
                toAdd: selectedMetrics.filter((x) => !x.AssignmentsConfiguration).map(mapMetric),
                toUpdate: selectedMetrics.filter((x) => x.AssignmentsConfiguration).map(mapMetric)
            }
            insertOrUpdateAssignmentConfigurationBulk(payload)
                .then(() => {
                    if (accessToken && selectedProductId) {
                        getAssignnmentMetrics(selectedProductId, metricSortings)
                            .then((ChildMetrics) => {
                                contextDispatch({ type: "SET_METRICS", payload: ChildMetrics });
                            })
                            .catch((err) => console.log(err))
                    }
                    resetContext("Nuova configurazione creata correttamente.")
                })
                .catch((err) => contextDispatch({
                    type: "SET_STATE", payload: {
                        ...contextState,
                        popupMessage: "Qualcosa è andato storto, aggiorna la pagina e riprova.",
                        popupType: "error"
                    }
                }))
                .finally(()=>setIsLoading(false));
            // if (selectedMethod === "NEW") {
            //     addMetricAssignmentConfigurationBulk(payload)
            //         .then(() => resetContext("Nuova configurazione creata correttamente."))
            //         .catch((err) => contextDispatch({
            //             type: "SET_STATE", payload: {
            //                 ...contextState,
            //                 popupMessage: "Qualcosa è andato storto, aggiorna la pagina e riprova.",
            //                 popupType: "error"
            //             }
            //         }))
            // } else {
            //     updateMetricAssignmentConfigurationBulk(payload)
            //         .then(() => resetContext("Configurazione aggiornata correttamente."))
            // }
        }
    }, [assignmentConfigurations, configurationFormData, contextDispatch, contextState, selectedMetrics, selectedProductId, metricSortings])
    if (currentStep === StepsEnum.SELECTION) {
        return <div className="fixed bg-slate-800 flex justify-end items-center px-2" style={{ zIndex: 9999999, left: 0, bottom: 0, height: 50, width: '100vw' }}>
            {/* <StyledOutlinedButton type="button" onClick={() => contextDispatch({ type: "SET_STEP", payload: currentStep - 1 })}>
                Indietro
            </StyledOutlinedButton> */}
            <StyledOutlinedButton
                disabled={!selectedMetrics || selectedMetrics.length === 0}
                type="button" onClick={() => contextDispatch({ type: "SET_STEP", payload: StepsEnum.FORM })}>
                Avanti
            </StyledOutlinedButton>
        </div>
    }
    if (currentStep === StepsEnum.FORM) {
        return <div className="fixed bg-slate-800 flex justify-between items-center px-2" style={{ left: 0, bottom: 0, height: 50, width: '100vw' }}>
            <StyledOutlinedButton size="large" color="primary" type="button" onClick={() => contextDispatch({
                type: "SET_STATE",
                payload: {
                    ...contextState,
                    currentStep: StepsEnum.SELECTION
                }
            })}>
                Indietro
            </StyledOutlinedButton>
            <StyledOutlinedButton size="large" variant="contained" color="primary" type="button"
                disabled={!validFormData}
                onClick={() => contextDispatch({
                    type: "SET_STATE",
                    payload: {
                        ...contextState,
                        // selectedView: "CONFIRM",
                        currentStep: StepsEnum.CONFIRM,
                    }
                })}>
                Riepilogo
            </StyledOutlinedButton>
        </div>
    }
    if (currentStep === StepsEnum.CONFIRM) {
        return <div className="fixed bg-slate-800 flex justify-between items-center px-2" style={{ left: 0, bottom: 0, height: 50, width: '100vw' }}>
            <StyledOutlinedButton size="large" color="primary" type="button" onClick={() => contextDispatch({
                type: "SET_STATE",
                payload: {
                    ...contextState,
                    currentStep: StepsEnum.FORM
                }
            })}>
                Indietro
            </StyledOutlinedButton>
            <StyledContainedButton size="large" variant="contained" color="primary" type="button"
                disabled={isLoading}
                onClick={handleSubmit}
            >
                Conferma
            </StyledContainedButton>
        </div>
    }
    return null
}