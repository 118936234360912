import React from 'react'
import { getUserProfileImageUrl } from '../../services/graph';
import { useMsal } from '@azure/msal-react';
import { AccountInfo } from '@azure/msal-browser';
import { useUserContext } from '../../../../store/hooks/useUserContext';
type PROPS = {}
export interface IFacepilePersona {
  personaName?: string;
  /**
   * Url to the image to use, should be a square aspect ratio and big enough to fit in the image area.
   */
  imageUrl?: string;
  /**
   * The user's initials to display in the image area when there is no image.
   * @defaultvalue Derived from `personaName`
   */
  imageInitials?: string;
}
export const GraphExplorer: React.FC<PROPS> = () => {
  const { account, userInfo, setGraphAccessToken } = useUserContext();
  const { graphAccessToken } = userInfo;
  const { instance } = useMsal();
  const [image, setUserImage] = React.useState<IFacepilePersona[]>();

  React.useEffect(() => {
    console.log(graphAccessToken)
    if (!graphAccessToken) {
      const request = {
        scopes: [
          "User.Read",
          "https://graph.microsoft.com/Files.Read.All",
          "https://graph.microsoft.com/Tasks.ReadWrite",
        ],
        account: account as AccountInfo,
      };
      instance.setActiveAccount(account);
      instance.acquireTokenSilent(request).then((response) => setGraphAccessToken(response.accessToken))
    }
    else{
      getUserProfileImageUrl(graphAccessToken).then((arrayBuffer) => {
        if (arrayBuffer) {
          const user: IFacepilePersona[] = [
            {
              imageUrl: URL.createObjectURL(new Blob([arrayBuffer])),
            },
          ];
          setUserImage(user);
        }
      });
    }
  }, [graphAccessToken])
  return <div className='flex'>
    {image && image.length > 0 && <img src={image[0].imageUrl} alt="Immagine non trovata" />}
  </div>;

}