import React from "react"
import { TMetric } from "../../types/alerts"
import { settingsContext } from "../settingsStore"
import { AssignmentPriorityEnum, IAssignmentConfiguration } from "../../types/assignments"
import { FaCircle } from "react-icons/fa";
import { Typography } from "@mui/material";
import { TooltipContainer } from "../../components/TooltipContainer/TooltipContainer";

const priorityMap: { [key: string]: React.ReactNode } = {
    [AssignmentPriorityEnum.Low]: <FaCircle className='!m-auto h-[100%] w-[15px]' color="gray" />,
    [AssignmentPriorityEnum.Medium]: <FaCircle className='!m-auto h-[100%] w-[15px]' color="yellow" />,
    [AssignmentPriorityEnum.High]: <FaCircle className='!m-auto h-[100%] w-[15px]' color="red" />,
}
export interface IHeader {
    id: keyof TMetric | keyof IAssignmentConfiguration
    label: string
    width: string | number
    value?: (a: TMetric) => any
    element?: (a: TMetric) => React.ReactNode | string | undefined
    clickable?: boolean
}
export interface ISortableHeader extends IHeader {
    onSort: (key: keyof TMetric | keyof IAssignmentConfiguration) => void
}
export const useMetricHeaders = () => {
    const { state, dispatch } = React.useContext(settingsContext);
    const { metricSortings } = state;
    const defaultHeaderSort = (key: keyof TMetric | keyof IAssignmentConfiguration, innerClass: string) => {
        const toAdd = metricSortings.find((s) => s.key === key) === undefined;
        const newDirection: "asc" | "desc" = toAdd ? "asc" : metricSortings.find((s) => s.key === key)?.direction === "asc" ? "desc" : "asc";
        const newValue = { key, direction: newDirection, innerClass };
        dispatch({
            type: "SET_SORTINGS",
            payload: toAdd ? [...metricSortings, newValue] : metricSortings.map((s) => s.key === key ? newValue : s)
        })
    }
    const headers: ISortableHeader[] = [
        {
            id: "Priority",
            label: "Priorità",
            width: '10%',
            element: (a: TMetric) => a.AssignmentsConfiguration ? priorityMap[a.AssignmentsConfiguration.Priority] : <></>,
            clickable: true,
            onSort: (key) => defaultHeaderSort(key, "AssignmentsConfiguration")
        },
        {
            id: "Id",
            label: "ID",
            width: '5%',
            clickable: true,
            onSort: (key) => defaultHeaderSort(key, ''),
            value: (a: TMetric) => a.Id?.toString()
        },
        {
            id: "Name",
            label: "Name",
            width: '30%',
            clickable: true,
            onSort: (key) => defaultHeaderSort(key, ''),
            value: (a: TMetric) => a.Name?.toString()
        },
        {
            id: "OwnerMail",
            label: "Owner",
            width: '30%',
            clickable: true,
            onSort: (key) => defaultHeaderSort(key, 'AssignmentsConfiguration'),
            value: (a: TMetric) => a.AssignmentsConfiguration?.OwnerMail.toString()
        },
        {
            id: "CanBeDelegated",
            label: "Delegabile",
            width: '5%',
            clickable: true,
            onSort: (key) => defaultHeaderSort(key, ''),
            value: (a: TMetric) => a.AssignmentsConfiguration?.CanBeDelegated ? "Sì" : "No"
        },
        // {
        //     id: "IsEscalationRequired",
        //     label: "Escalation",
        //     width: '20%',
        //     clickable:true,
        //     onSort:(key)=>defaultHeaderSort(key,''),
        //     value: (a: TMetric) => a.AssignmentsConfiguration?.CanBeDelegated
        //         ?
        //         `Scalabile a ${a.AssignmentsConfiguration.EscalationReceiversMail} dopo ${a.AssignmentsConfiguration.EscalationTimeInDays}` : "Escalation non richiesta"
        // },
        {
            id: "NotificationUserMail",
            label: "Utenti notificati",
            width: '20%',
            clickable: true,
            onSort: (key) => defaultHeaderSort(key, ''),
            element: (a: TMetric) => a.AutomaticNotification ? <TooltipContainer
                containerStyle={{width:"90%"}}
                customContentStyles={{content:{fontSize:18}}}
                tooltip={{ id: a.AutomaticNotification.map((n) => n.UserMail).join("\n"), text: a.AutomaticNotification.map((n) => n.UserMail).join(" ") }}
            >
                <Typography overflow={"hidden"} textOverflow={"clip"} fontSize="0.85rem">
                    {a.AutomaticNotification.map((n) => n.UserMail).join(";")}
                </Typography>
            </TooltipContainer> : <></>
        },
    ]

    return { headers }
}