import React from "react";
import {
  getActiveAlertsWithHierarchy,
  getDeactiveAlertsWithHierarchy,
  } from "../../services/alerts";
import {
  getProductAssets,
  getProducts,
  TAssetTypes,
  TProduct,
  TProductAsset,
} from "../../services/products";
import { dashboardsContext, NEWSLETTER_HASH_KEY } from "../dashboardsStore";
import { hashString } from "../../utility/strings";
import { TDateRange, TMetric } from "../../types/alerts";

export function useDashboardsStore() {
  const { state, dispatch } = React.useContext(dashboardsContext);
  const {
    products,
    groupedProductAssets,
    isLoaded,
    editMode,
    alertDateFilter,
    metrics,
    deactiveMetrics,
    addMode,
    tutorials,
    executiveReports,
    search,
    news_letters,
    viewDisabledAssets,
    rowDashboardContainersidebarWidth,
    news_letters_hash,
  } = state;
  const editableAssets = [
    ...(groupedProductAssets[TAssetTypes.Report] ?? []),
    ...(groupedProductAssets[TAssetTypes.SpecialReport] ?? []),
    ...(groupedProductAssets[TAssetTypes.ScoreCard] ?? []),
  ];
  const groupProductAssets = React.useCallback(
    (productAssets: TProductAsset[]) => {
      let result: { [key in TAssetTypes]?: TProductAsset[] } = {};
      for (let asset of productAssets) {
        let typeAssets = result[asset.AssetType] ?? [];
        typeAssets = [...typeAssets, asset];
        result[asset.AssetType] = typeAssets;
      }
      return result;
    },

    []
  );
  const loadActiveAlerts = React.useCallback(
    async (productIDFilter: string, set = true) => {
      return getActiveAlertsWithHierarchy(productIDFilter).then(
        (metrics: TMetric[]) => {
          if (set) {
            dispatch({
              type: "SET_METRICS",
              payload: { [productIDFilter]: metrics },
            });
          }
          return { [productIDFilter]: metrics };
        }
      );
    },
    [dispatch]
  );

  const loadDeactiveAlerts = React.useCallback(
    async (
      productIDFilter: string,
      dateFilter?: TDateRange | null,
      set = true
    ) => {
      return getDeactiveAlertsWithHierarchy(productIDFilter, dateFilter).then(
        (metrics: TMetric[]) => {
          if (set) {
            dispatch({
              type: "SET_DEACTIVE_METRICS",
              payload: { [productIDFilter]: metrics },
            });
          }
          return { [productIDFilter]: metrics };
        }
      );
    },
    [dispatch]
  );
  const loadAlerts = React.useCallback(
    async (productIDFilter: string) => {
      loadActiveAlerts(productIDFilter, false).then((activeMetrics) =>
        loadDeactiveAlerts(productIDFilter, undefined, false).then(
          (deactiveMetrics) => {
            dispatch({
              type: "SET_STATE",
              payload: { ...state, metrics: activeMetrics, deactiveMetrics },
            });
          }
        )
      );
    },
    [dispatch, loadActiveAlerts, loadDeactiveAlerts, state]
  );

  const loadProducts = React.useCallback(
    async (set = true) => {
      return getProducts().then((products: TProduct[]) => {
        if (set) {
          dispatch({ type: "SET_STATE", payload: { ...state, products } });
        }
        return products;
      });
    },
    [dispatch, state]
  );
  const loadAssets = React.useCallback(
    async (set = true) => {
      return getProductAssets().then((productAssets: TProductAsset[]) => {
        if (set) {
          dispatch({
            type: "SET_STATE",
            payload: {
              ...state,
              groupedProductAssets: groupProductAssets(productAssets),
            },
          });
        }
        return productAssets;
      });
    },
    [dispatch, groupProductAssets, state]
  );
  const loadProductsAndAssets = React.useCallback(async () => {
    return loadProducts(false).then((products) =>
      loadAssets(false).then((productAssets) => {
        const payload = {
          ...state,
          products,
          groupedProductAssets: groupProductAssets(productAssets),
          isLoaded: true,
        };
        dispatch({ type: "SET_STATE", payload });
        return payload;
      })
    );
  }, [dispatch, groupProductAssets, loadAssets, loadProducts, state]);
  const setAlertDateFilter = React.useCallback(
    (alertDateFilter: TDateRange | null) => {
      dispatch({ type: "SET_ALERT_DATE_FILTER", payload: alertDateFilter });
    },
    [dispatch]
  );
  const addAssetToStore = React.useCallback(
    (newAsset: TProductAsset) => {
      // const newAssets = [...state.productAssets,newAsset]
      const newAssets: TProductAsset[] = [];
      dispatch({
        type: "SET_STATE",
        payload: {
          ...state,
          groupedProductAssets: {
            ...state.groupedProductAssets,
            [newAsset.AssetType]: [...newAssets, newAsset],
          },
        },
      });
    },
    [dispatch, state]
  );
  const editAssetOnStore = React.useCallback(
    (x: TProductAsset) => {
      dispatch({
        type: "SET_STATE",
        payload: {
          ...state,
          groupedProductAssets: {
            ...state.groupedProductAssets,
            [x.AssetType]: state.groupedProductAssets[x.AssetType]?.map((a) =>
              a.Id === x.Id ? x : a
            ),
          },
        },
      });
    },
    [dispatch, state]
  );
  const deleteAssetOnStore = React.useCallback(
    (x: TProductAsset) => {
      dispatch({
        type: "SET_STATE",
        payload: {
          ...state,
          groupedProductAssets: {
            ...state.groupedProductAssets,
            [x.AssetType]: state.groupedProductAssets[x.AssetType]?.filter(
              (a) => a.Id !== x.Id
            ),
          },
        },
      });
    },
    [dispatch, state]
  );
  const setViewDisabledAssets = React.useCallback(
    (payload: boolean) => {
      dispatch({ type: "SET_VIEW_DISABLED_ASSETS", payload });
    },
    [dispatch]
  );
  const setRowDashboardContainerSidebarWidth = React.useCallback(
    (payload: number) => {
      dispatch({ type: "SET_ROW_DASHBOARD_CONTAINER_SIDEBAR_WIDTH", payload });
    },
    [dispatch]
  );
  const hashNewsLetters = React.useCallback(() => {
    if (!news_letters || news_letters.length === 0) return 0;
    return hashString(news_letters.map((x) => x.Name).join("-"));
  }, [news_letters]);
  const setNewsLettersHash = React.useCallback(
    (payload: string) => {
      dispatch({ type: "SET_NEWS_LETTERS_HASH", payload });
      localStorage.setItem(NEWSLETTER_HASH_KEY, payload);
    },
    [dispatch]
  );
  return {
    products,
    groupedProductAssets,
    isLoaded,
    editMode,
    alertDateFilter,
    metrics,
    deactiveMetrics,
    addMode,
    tutorials,
    executiveReports,
    search,
    news_letters,
    news_letters_hash,
    viewDisabledAssets,
    rowDashboardContainersidebarWidth,
    editableAssets,
    setRowDashboardContainerSidebarWidth,
    loadProductsAndAssets,
    loadAssets,
    loadProducts,
    setAlertDateFilter,
    loadActiveAlerts,
    loadDeactiveAlerts,
    loadAlerts,
    addAssetToStore,
    editAssetOnStore,
    deleteAssetOnStore,
    contextState: state,
    contextDispatch: dispatch,
    setViewDisabledAssets,
    setNewsLettersHash,
    hashNewsLetters,
  };
}
