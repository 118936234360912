import dayjs from "dayjs";
import { IColumnFilter, IColumnSorting } from "../types/odata";
export const getNextValue = (f:IColumnFilter<any>)=>{
    switch(f.valueType){
        case "datetime":return dayjs(f.value).add(1,'day')
        default:return f.value + 1
    }
}
export const encodeOdataStringValue = (value:string)=>`'${value.replaceAll("'","''")}'`
export const encodeOdataCollectionValue = (value:string[])=>`(${value.map((s)=>"'" + s + "'").join(',')})`
export const encodeOdataBooleanValue = (value:boolean)=>value ? 'true':'false'
export const encodeOdataDateTimeValue = (value:string)=>`'${dayjs(value).set('hour',0).set('minute',0).set('second',0).toISOString()}'`
export const encodeOdataDateRangeValue = (value:{startDate:string,endDate:string},index?:number)=>index === 0 ? 
    `'${dayjs(value.startDate).set('hour',0).set('minute',0).set('second',0).toISOString()}'`
    :
    `'${dayjs(value.endDate).set('hour',0).set('minute',0).set('second',0).toISOString()}'`
    export const encodeOdataFilterValue = (f:IColumnFilter<any>,index?:number)=>{
    switch(f.valueType){
        case "string":return encodeOdataStringValue(f.value)
        case "enum":return encodeOdataStringValue(f.value)
        case "datetime":return encodeOdataDateTimeValue(f.value)
        case "daterange":return encodeOdataDateRangeValue(f.value,index)
        case "boolean":return encodeOdataBooleanValue(f.value)
        case "collection":return encodeOdataCollectionValue(f.value)
        default:return f.value
    }
}
export const encodeOdataFilter = (f:IColumnFilter<any>)=>{
    switch(f.operator){
        case "ge-le":return `(${f.columnName as string} ge ${encodeOdataFilterValue(f,0)} and ${f.columnName as string} le ${encodeOdataFilterValue(f,1)})`
        case "contains":return `contains(${f.columnName as string},${encodeOdataFilterValue(f)})`
        case "any":return f.value.map((x:any)=>`${f.columnName as string}/any(x:x/${f.nestedColumn} eq ${encodeOdataFilterValue({...f,value:x})})`).join(" and ")
        case "all-ne":return f.value.map((x:any)=>`${f.columnName as string}/all(x:x/${f.nestedColumn} ne ${encodeOdataFilterValue({...f,value:x})})`).join(" and ")
        default:return `(${f.columnName as string} ${f.operator} ${encodeOdataFilterValue(f)})`
    }
}
export const encodeOdataFilters = (filters:IColumnFilter<any>[])=>{
    const filterFilter = (f:IColumnFilter<any>)=>{
        switch(f.valueType){
            case "boolean":return f.value !== undefined
            default:return f.value as boolean
        }
    }
    if(filters.filter(filterFilter).length === 0){
        return '';
    }
    return `$filter=(${filters.filter(filterFilter).map((f)=>encodeOdataFilter(f)).join(" and ")})`
}
export const encodeOdataSortings = (sortings:IColumnSorting<any>[])=>{
    const sortingsQuery = sortings.length === 0 ? "" : `$orderby=${sortings.map((sorting)=>`${sorting.innerClass ? sorting.innerClass + '.' : ''}${sorting.key as string} ${sorting.direction}`)}`
    return sortingsQuery;
}
export const appendOdataQuery = (url:string,query:string)=>`${url}${query ? '&' : ''}${query}`
export const buildOdataUrl = (controller:string,skip:number,top:number,filters:IColumnFilter<any>[],sortings:IColumnSorting<any>[],onlyCount?:boolean,expand?:string)=>{
    const filtersQuery = encodeOdataFilters(filters)
    const sortingsQuery = encodeOdataSortings(sortings)
    const skipQuery = skip ? `$skip=${skip}` : "";
    const topQuery = onlyCount ? "$top=0" : `$top=${top}`;
    const expandQuery = expand ? `$expand=${expand}` : ""
    let url = `${process.env.REACT_APP_SERVER_URL_ODATA}/${controller}?$count=true${expandQuery ? "&" + expandQuery : ""}`
    url = appendOdataQuery(url,skipQuery); 
    url = appendOdataQuery(url,topQuery); 
    url = appendOdataQuery(url,filtersQuery); 
    url = appendOdataQuery(url,sortingsQuery); 
    return url;
}