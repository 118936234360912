import React from "react";
import { Route, Routes } from "react-router-dom";
import { know_urls } from "../../types/userStore";
import { UserSettingsPage } from "../../pages/settings/users/UserSettingsPage";
import { PowerBIUserEmbed } from "../../components/powerBi/PowerBIUserEmbed";
import { EditAssetsPage } from "../../pages/EditAssetsPage/EditAssetsPage";
import { DashboardPageEditor } from "../../pages/DashboardPageEditor/DashboardPageEditor";
import MainDashboard from "../../pages/MainDashboard/MainDashboard";
import { PbiLoading } from "../../components/powerBi/PbiLoading";
import { useUserContext } from "../../store/hooks/useUserContext";
import { useDashboardsStore } from "../../store/hooks/useDashboardsStore";
import { TProduct } from "../../services/products";
import { ProductDashboard } from "../../components/ProductDashboard/ProductDashboard";
import { QUERY_PAGE_KEY, SELECTED_ICON_QUERY_KEY } from "../../hooks/useQueryValues";
import { useDashboardSettings } from "../../store/hooks/useDashboardSettings";
import { getProductRoute } from "../../utility/routes";
import { MiniLoadingSpinner } from "../../components/spinner/MiniLoadingSpinner/MiniLoadingSpinner";
import { SettingsPage } from "../../pages/settings/SettingsPage";
import { SettingsProvider } from "../../store/settingsStore";
import { assignments_known_urls as assignments_known_urls } from "../../apps/assignments/types/userStore";
import { Home as AssignmentIndex } from "../../apps/assignments/pages/Home/Home";
import { AssignmentList } from "../../apps/assignments/pages/AssignmentList/AssignmentList";
import { AssignmentDetailPage } from "../../apps/assignments/pages/AssignmentDetailPage/AssignmentDetailPage";
import { TicketDetailPage } from "../../apps/assignments/pages/TicketDetailsPage/TicketDetailsPage";
import { TicketList } from "../../apps/assignments/pages/TicketList/TicketList";
import { InputEventsPage } from "../../apps/assignments/pages/InputEventsPage/InputEventsPage";
import { AlertEventDetailPage } from "../../apps/assignments/pages/EventDetailPage/AlertEventDetailPage";
import { SuperMetricEventDetailPage } from "../../apps/assignments/pages/EventDetailPage/SuperMetricEventDetailPage";
type Props = {};
const AuthenticatedRoutes: React.FC<Props> = () => {
  const { accessToken } = useUserContext();
  const { isLoaded, loadProductsAndAssets,products } = useDashboardsStore();
  const { loadAllSettings } = useDashboardSettings();
  React.useEffect(() => {
    loadAllSettings();
  }, [loadAllSettings]);
  React.useEffect(() => {
    if (accessToken && !isLoaded) {
      loadProductsAndAssets();
    }
  }, [accessToken, isLoaded, loadProductsAndAssets]);
  const mapProductDashboardsRoutes = (p:TProduct) => (
    <>
      <Route path={getProductRoute(p)} element={<ProductDashboard title={p.Name} />} />
      <Route
        path={`${getProductRoute(p)}?${QUERY_PAGE_KEY}=:page&${SELECTED_ICON_QUERY_KEY}=:selected-icon`}
        element={<ProductDashboard title={p.Name} />}
      />
      <Route
        path={`${getProductRoute(p)}?${QUERY_PAGE_KEY}=:page`}
        element={<ProductDashboard title={p.Name} />}
      />
    </>
  );
  const _routes = React.useMemo(()=>products.map((p)=>mapProductDashboardsRoutes(p)),[products])
  return (
    <Routes>
      {/* <Route path="*" element={<div className="h-[100vh] w-[100vw] bg-slate-700 flex flex-col justify-center items-center"><MiniLoadingSpinner style={{color:'white'}}/></div>}/> */}
      <Route path={know_urls.user_settings} element={<SettingsProvider><SettingsPage /></SettingsProvider>} />
      <Route path={know_urls.assignments_settings} element={<SettingsProvider><SettingsPage /></SettingsProvider>} />
      <Route path={know_urls.report} element={<PowerBIUserEmbed />} />
      <Route path={know_urls.edit_assets} element={<EditAssetsPage />} />
      <Route
        path={know_urls.edit_dashboard_pages}
        element={<DashboardPageEditor />}
      />
      <Route path={know_urls.redirectCallback} element={<PbiLoading />} />
      <Route path="/" element={<MainDashboard />} />
      {_routes}
    </Routes>
  );
};
export { AuthenticatedRoutes };
