import 'react-date-range/dist/styles.css'; // main style file
import 'react-date-range/dist/theme/default.css'; // theme css file
import { DateRangePicker } from 'react-date-range';
import { it } from 'date-fns/locale'

import { useReactDateRangePicker } from './hooks/useReactDateRangePicker';
import { DefaultButton, PrimaryButton } from '@fluentui/react';
import './styles/datepicker_styles.css'
export const ReactDateRangePicker = ({ startRange, onClose, customSetFilters }) => {
    const {
        handleSelect,
        setFilters,
        itStaticRanges,
        itInputRanges,
        range,
        handleConfirm
    } = useReactDateRangePicker({ startRange });
    return (
        <>
            <DateRangePicker
                rangeColors={["#107c41", "#107c41", "#107c41"]}
                color={"#107c41"}
                locale={it}
                ranges={[range]}
                onChange={(range) => {
                    handleSelect(range);
                }}
                staticRanges={itStaticRanges}
                inputRanges={itInputRanges}
            />
            <div className="flex flex-row justify-between">
                <DefaultButton
                    onClick={onClose}
                >
                    Annulla
                </DefaultButton>
                <PrimaryButton
                    variant="contained"
                    color="primary"
                    onClick={() => {
                        if (customSetFilters) {
                            customSetFilters(range);
                        } else {
                            setFilters();
                        }
                        onClose();
                    }}
                >
                    Conferma
                </PrimaryButton>
            </div>
        </>
    )
}