import { IFacepilePersona } from "@fluentui/react"
import { createCtx } from "../store/general"
import { ICustomClaims } from "../services/user"
import { BuildingSettings } from "../types/Building"
import { IUserInfo } from "../types/user"
import { getInitialState } from "./autosave"

export type TGroupingType = "Edifici" | "Poli"
export type TSecondaryGroupingType = "OpenTickets" | "OldestTickets"

type TState = {
    userInfo: IUserInfo,
    buildingSettings?: BuildingSettings,
    userImage: IFacepilePersona[],
    errors: string[]
}

// usage
const initialState: TState = {
    userInfo: {
        accessToken: getInitialState('userStore')?.user?.accessToken ?? '',
        graphAccessToken: getInitialState('userStore')?.user?.graphAccessToken ?? '',
        account: getInitialState('userStore')?.user?.account ?? { username: '', name: '' },
        customClaims: getInitialState('userStore')?.user?.customClaims ?? [],
    },
    userImage: [
        {
            imageUrl: process.env.REACT_APP_SERVER_DEFAULT_USER_IMAGE
        }
    ],
    errors: []
}
type AppState = TState;
type Action =
    | { type: 'SET_BUILDINGS_SETTINGS', payload: BuildingSettings }
    | { type: 'SET_USER_INFO', payload: IUserInfo }
    | { type: 'SET_USER_CLAIMS', payload: ICustomClaims[] }
    | { type: 'SET_ACCESS_TOKEN', payload: string }
    | { type: 'SET_GRAPH_ACCESS_TOKEN', payload: string }
    | { type: 'SET_ERRORS', payload: string[] }
    | { type: 'SET_USER_IMAGE', payload: IFacepilePersona[] }

function reducer(state: AppState, action: Action): AppState {
    switch (action.type) {
        case 'SET_BUILDINGS_SETTINGS':
            return { ...state, buildingSettings: action.payload }
        case 'SET_ACCESS_TOKEN':
            return { ...state, userInfo: { ...state.userInfo, accessToken: action.payload } }
        case 'SET_GRAPH_ACCESS_TOKEN':
            return { ...state, userInfo: { ...state.userInfo, graphAccessToken: action.payload } }
        case 'SET_USER_INFO':
            return { ...state, userInfo: action.payload }
        case 'SET_USER_CLAIMS':
            return { ...state, userInfo: { ...state.userInfo, customClaims: action.payload } }
        case 'SET_USER_IMAGE':
            return { ...state, userImage: action.payload }
        case 'SET_ERRORS':
            return { ...state, errors: action.payload }
        default:
            return state;
    }
}

export const [userContext, UserProvider] = createCtx(reducer, initialState);