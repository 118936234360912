import React from "react";
import { useIsMobile } from "../../../utility/useIsMobile";
import { ODataGridRowCell } from "./ODataGridRowCell/ODataGridRowCell";
import { ODataGridCard } from "../ODataGridCard/ODataGridCard";
import { headerMaxWidth, headerWidth, useODataGrid } from "../hooks/useODataGrid";
import { paginationContext } from "../../../store/pager";
import { ODataGridRowProps } from "../../../types/odata";
import { useNavigate } from "react-router-dom";
import { ISortableHeader } from "../../../types/general";

export const ODataGridRow: React.FC<ODataGridRowProps> = ({ 
  item, 
  headers, 
  itemContainerCustomClassNames, 
  itemCustomClassNames, 
  enumStoreKey ,
  customGridCard
}) => {
  const {
    visibleHeaders,
    hiddenHeadersWidth,
    resizedHeadersWidth,
    
  } = useODataGrid({ headers });
  const { state } = React.useContext(paginationContext);
  const { isMobile } = useIsMobile();
  const navigate = useNavigate();
  if (isMobile) {
    if(customGridCard){
      return customGridCard
    }
    return <ODataGridCard enumStoreKey={enumStoreKey} item={item} headers={headers} />;
  } else {
    return (
      <div
        className={`border border-solid w-[100%] cursor-default flex text-black ${itemContainerCustomClassNames ? itemContainerCustomClassNames(item) : ''}`}>
        {headers.filter((h) => h.isVisible).map((h, i) => (
          <div
            key={`${item.id}-${i}`}
            className={`min-h-[40px] overflow-hidden ${h.navigationUrl ? 'cursor-pointer hover:!shadow' : ''} ${itemCustomClassNames ? itemCustomClassNames(item) : ''}`}
            onClick={() => {
              if (h.navigationUrl) { navigate(h.navigationUrl(item)) }
            }}
            style={{
              width: headerWidth(h, hiddenHeadersWidth, resizedHeadersWidth, i < visibleHeaders.length - 1,i >= headers.filter((h) => h.isVisible).length - 1),
              maxWidth: headerMaxWidth(h, hiddenHeadersWidth, resizedHeadersWidth, i >= visibleHeaders.length - 1),
              flexGrow: i < visibleHeaders.length - 1 ? undefined : 1,
              minWidth: headerWidth(h, hiddenHeadersWidth, resizedHeadersWidth, i < visibleHeaders.length - 1,i >= headers.filter((h) => h.isVisible).length - 1),
              margin: "auto 0px",
              height: '100%',
              alignContent: 'center',
              borderRight: '1px solid white'
            }}
            id={`Assignment-${item.Id}-${h.id.toString()}`}
          >
            <ODataGridRowCell header={h} x={item} enumStoreKey={enumStoreKey} isLoading={state.pagination[enumStoreKey].isLoading} />
          </div>
        ))}
      </div>
    );
  }
};
