import React from 'react'
import { Typography } from '@mui/material'
import { isMobile } from 'react-device-detect'
import { ISortableHeader } from '../../../types/general'
import { ETicket } from '../../../types/etickets'
import { TicketCard } from './TicketCard/TicketCard'
import useWindowSize from '../../../utility/useWindowSize'
import { ODataGridRowCell } from '../../ODataGrid/ODataGridRow/ODataGridRowCell/ODataGridRowCell'
import { GridRowProps } from '../../../types/odata'
import { paginationContext } from '../../../store/pager'
export const TicketRow: React.FC<GridRowProps<ETicket>> = ({ item, headers,enumStoreKey }) => {
    const { width } = useWindowSize();
    const {state} = React.useContext(paginationContext);
    if (isMobile || (width && width < 1000)) {
        return <TicketCard ticket={item} headers={headers} />
    } else {
        return <>
            {headers.map((h, i) => <div key={`${h.id}-${i}`} className={`rounded min-h-[40px]`} style={{ width: h.width, margin: '0px 1px' }} id={`Assignment-${item.Id}-${h.id}`}>
                <ODataGridRowCell isLoading={state.pagination[enumStoreKey].isLoading} enumStoreKey={enumStoreKey} header={h} x={item} />

            </div>)}
        </>
    }
}