import axios from "axios"
export type TAutomaticNotificationSetting = {
    MetricId: number;
    UserMail:string;
    Id: number;
    RowVersion: string
}
export const getAutomaticNotificationSettings = async (user: string) => {
    return axios.get(`${process.env.REACT_APP_SERVER_URL}/AutomaticNotificationSettings?$filter=userMail eq '${user}'`).then((res) => res.data as TAutomaticNotificationSetting[])
}

export type TPostAutomaticNotificationSettingsByUserPayload = {
    metricIds:number[]
    userMail:string
    productId:string
}

export const postAutomaticNotificationSettingsByUser = async (payload:TPostAutomaticNotificationSettingsByUserPayload) => {
    return axios.post(`${process.env.REACT_APP_SERVER_URL}/AutomaticNotificationSettings/ByUser`,payload).then((res)=>res.data)
}