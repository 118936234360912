import React from "react"
import { Button, MenuItem, Select, SelectChangeEvent, Snackbar } from "@mui/material"
import { known_products } from "../../../types/userStore"
import { TMetric } from "../../../types/alerts"
import { MiniLoadingSpinner } from "../../spinner/MiniLoadingSpinner/MiniLoadingSpinner"
import { StyledTextField } from "../../StyledTextField/StyledTextField"
import { isMobile } from "react-device-detect"
import { useUserContext } from "../../../store/hooks/useUserContext"
import { getAutomaticNotificationSettings, postAutomaticNotificationSettingsByUser } from "../../../services/automaticNotifications"
import { IUser } from "../../../services/user"
import { MetricExpandableContaier } from "./MetricExpandableContainer"
import { MetricLeaf } from "./MetricLeaf"
import { getProductMetrics } from "../../../services/alerts"

type Props = {
    editable?: boolean
    user: IUser
}
export type TMetricNotificationsState = { [key: number]: boolean }
export const UserNotifications: React.FC<Props> = ({ editable, user }) => {
    const { userInfo } = useUserContext();
    const [snackbar, setSnackbar] = React.useState({ open: false, message: '', severity: "success" })
    const [searchMetric, setSearchMetric] = React.useState<string | null>(null)
    const [metrics, setMetrics] = React.useState<TMetric[]>([])
    const [metricsState, setMetricsState] = React.useState<TMetricNotificationsState>({})
    const [loadingState, setLoadingState] = React.useState({ isLoading: false, isSaving: false });
    const { isLoading, isSaving } = loadingState;
    const [productId, setProductId] = React.useState(known_products["iot-connected-buildings"].id)
    const _getProductMetrics = React.useCallback(async () => {
        setLoadingState({ ...loadingState, isLoading: true });
        getProductMetrics(productId)
            .then((metrics) => {
                setMetrics(metrics);
                getAutomaticNotificationSettings(user.User).then((settings) => {
                    let _leafMetrics: TMetric[] = [];
                    const findLeadMetrics = (metric: TMetric) => {
                        if (metric.Metrics) {
                            metric.Metrics.forEach((_) => findLeadMetrics(_))
                        } else {
                            _leafMetrics.push(metric)
                        }
                    }
                    metrics.forEach((_) => findLeadMetrics(_))
                    console.log(_leafMetrics);
                    setMetricsState(Object.fromEntries(_leafMetrics.map((x) => [x.Id, settings.find((s) => s.MetricId === x.Id) !== undefined])))
                })
            })
            .catch((err) => console.log(err))
            .finally(() => setLoadingState({ ...loadingState, isLoading: false }))
    }, [productId, userInfo.account])
    React.useEffect(() => {
        _getProductMetrics();
    }, [_getProductMetrics])
    const filterMetrics = (metric: TMetric) => searchMetric ? metric.Name.toLowerCase().includes(searchMetric.toLowerCase()) : true;
    const handleSave = React.useCallback(() => {
        const payload = {
            metricIds: Object.entries(metricsState).filter((e) => e[1]).map((e) => parseInt(e[0])),
            userMail: user.User,
            productId
        }
        const _postAutomaticNotificationSettingsByUser = async () => {
            setLoadingState({ ...loadingState, isSaving: false });
            postAutomaticNotificationSettingsByUser(payload)
                .then(() => {
                    setSnackbar({ message: "Configurazione salvata correttamente.", open: true, severity: 'success' })
                })
                .catch((err) => {
                    setSnackbar({ message: "Qualcosa è andato storto.", open: true, severity: "error" })
                    console.log(err)
                })
                .finally(() => {
                    setLoadingState({ ...loadingState, isSaving: false });
                });
        }
        _postAutomaticNotificationSettingsByUser();
    }, [metricsState, user])
    const handleReset = React.useCallback(() => _getProductMetrics(), [_getProductMetrics])
    const sortMetrics = (a: TMetric, b: TMetric) => a.Metrics ? -1 : 1;
    return <>
        <Snackbar
            open={snackbar.open}
            autoHideDuration={6000}
            onClose={() => setSnackbar({ open: false, message: '', severity: "success" })}
        >
            <p className={`w-[100%] p-2 rounded-lg ${snackbar.severity === "success" ? "bg-intesa-green" : "bg-red-500"} text-xl`}>
                {snackbar.message}
            </p>
        </Snackbar>
        <div className={`flex  w-[100%] items-center ${isMobile ? 'flex-col' : 'flex-row justify-between'}`}>
            <Select
                style={{ zIndex: 999999, backgroundColor: 'rgb(71 85 105)', marginRight: isMobile ? 0 : 10 }}
                inputProps={{
                    zIndex: 999999
                }}
                variant="outlined"
                className="!my-2 !text-white"
                fullWidth
                label="Prodotto"
                labelId="product-select-label"
                id="product-select"
                value={productId}
                disabled={isLoading}
                onChange={(e: SelectChangeEvent) =>
                    setProductId(e.target.value as string)
                }
                MenuProps={{ style: { zIndex: 999999 } }}
            >
                {Object.entries(known_products).map((at) => (
                    <MenuItem style={{ zIndex: 999999 }} value={at[1].id}>{at[0]}</MenuItem>
                ))}
            </Select>
            <StyledTextField onBlur={undefined} variant="outlined" placeholder="Cerca..." value={searchMetric} onChange={(e) => setSearchMetric(e.target.value)} />
        </div>

        {isLoading ? <MiniLoadingSpinner /> :
            <div className="flex flex-col w-[100%] overflow-auto" style={{ height: editable ? 'calc(100% - 60px)' : '100%' }}>
                {metrics.filter(filterMetrics).sort(sortMetrics).map((_) => _.Metrics !== undefined && _.Metrics !== null
                    ?
                    <MetricExpandableContaier
                        metric={_}
                        state={metricsState}
                        setState={setMetricsState}
                        editable={editable}
                    />
                    :
                    <MetricLeaf
                        metric={_}
                        checked={metricsState[_.Id] === true}
                        onChange={(metric) => setMetricsState({ ...metricsState, [metric.Id]: !metricsState[metric.Id] })}
                        editable={editable}
                    />
                )}
                {editable && <>
                    <div
                        style={{ zIndex: 9999999, position: 'absolute', bottom: 0, right: 0, height: 60, borderTop: '1px solid lightgrey', display: 'flex' }}
                        className="bg-slate-800 w-[100%] p-2">
                        <Button disabled={isSaving || isLoading} onClick={handleReset} variant="contained" className="!bg-white !text-black">
                            Annulla
                        </Button>
                        <div className="ml-auto" />
                        <Button disabled={isSaving || isLoading} onClick={handleSave} variant="contained" className="!bg-intesa-green !text-white">
                            Salva
                        </Button>
                    </div>
                    <div style={{ paddingBottom: 50 }} />
                </>}
            </div>
        }
    </>
}