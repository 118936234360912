import React from "react";
import { UserSettingsPage } from "./users/UserSettingsPage";
import { AssignmentsSettingsPage } from "./assignments/AssignmentsSettingsPage";
import { DefaultButton, PrimaryButton, TextField } from "@fluentui/react";
import { TSelectorView } from "../../store/settingsStore";
import { topbarHeight } from "../../components/DashboardContainer/DashboardContainer";
import { known_actions, know_urls } from "../../types/userStore";
import { useLocation, useNavigate } from "react-router-dom";
import { hasPermission } from "../../store/hooks/useUserStore";
import { useUserContext } from "../../store/hooks/useUserContext";

export const SettingsPage: React.FC = () => {
    const { pathname } = useLocation();
    const navigate = useNavigate();
    const viewUrlMapper: { [key in TSelectorView]: string } = {
        "USER": know_urls.user_settings,
        "ASSIGNMENTS": know_urls.assignments_settings
    }
    const renderSelectorBtn = (view: TSelectorView, label: string) => <p className={`${pathname === viewUrlMapper[view] ? "font-bold border-b border-white text-white" : "text-white"} text-xl cursor-pointer`}
        onClick={() => navigate(viewUrlMapper[view])}
    >
        {label}
    </p>
    const [search, setSearch] = React.useState("")
    const { userClaims } = useUserContext();
    const [userViewType, setUserViewType] = React.useState<"list" | "cards">("list")
    return <>
        <div className="flex w-[100%] bg-slate-700 items-center" style={{ height: topbarHeight }}>
            <div className="mx-2" />
            {renderSelectorBtn("USER", "Configura Utenti")}
            <div className="mx-2" />
            {hasPermission(userClaims, known_actions.assignments) && renderSelectorBtn("ASSIGNMENTS", "Configura Segnalazioni")}
            {(pathname === know_urls.user_settings) && <div className="flex flex-row items-center grow px-2">
                <TextField
                    className="w-[200px] mx-4 my-2"
                    styles={{
                        root: { borderRadius: 8, backgroundColor: 'rgb(100,116,139)' },
                        wrapper: { borderRadius: 8, backgroundColor: 'rgb(100,116,139)' },
                        field: { borderRadius: 8, backgroundColor: 'rgb(100,116,139)', color: 'white' },
                        fieldGroup: { borderRadius: 8, backgroundColor: 'rgb(100,116,139)' },
                    }}
                    placeholder='Cerca...'
                    onChange={(e: any) => setSearch(e.target.value)}
                    value={search}
                />
                <div className="ml-auto items-end">
                    
                    {userViewType === "list" ? <PrimaryButton>Lista</PrimaryButton> : <DefaultButton onClick={() => setUserViewType("list")}>Lista</DefaultButton>}
                    {userViewType === "cards" ? <PrimaryButton>Cards</PrimaryButton> : <DefaultButton onClick={() => setUserViewType("cards")}>Cards</DefaultButton>}
                </div>
            </div>}
            {/* {(pathname === know_urls.user_settings) && <p>{usersCount}</p>} */}
        </div>
        {(pathname === know_urls.user_settings) && <UserSettingsPage viewType={userViewType} setViewType={setUserViewType} search={search} />}
        {(pathname === know_urls.assignments_settings) && <AssignmentsSettingsPage />}
    </>
}