import axios from "axios";
export const DASHBOARD_IMAGE_KEY = 'dashboard_image'
export interface TProduct {
    ProductId: string
    Name:string
    IsEnabled: boolean
    Description: string | null
    UIPosition: number
    Metadata: {[key:string]:any}
    RowVersion:string
}

export async function getProducts() {
    let url = `${process.env.REACT_APP_SERVER_URL}/Product`;
    const headers = {
        'Content-Type': 'application/json',
    };
    return axios.get(url, { headers }).then((res) => res.data);
}
export enum RowTypeEnum {
    HEADER = "Header",
    TEXT = "Text",
}
export interface TRowHelp {
    Item:string
    Description:string
}
export interface TAssetBagRow {
    RowType:RowTypeEnum,
    IsDefault:boolean
    Description:string,
    Value:string,
    Format:number,
    UoM:string
    Rows?:TAssetBagRow[]
    Helps?:TRowHelp[]
}
export interface TAssetBagSection {
        RowType:RowTypeEnum
        Description:string
        UoM:string
        Value:number|string
        Format:number,
        Helps?:TRowHelp[]
        IsDefault:boolean
        Rows?:TAssetBagRow[]
    }
export interface TSegmentLabel {
    text:string
    position:"OUTSIDE"|"INSIDE",
    color:string
}
export interface Target {
    Value:string
    IsDefault:boolean
    Label:string
    UIOrder:number
    UoM:string
    Format:number
}
// export interface TAssetBag {
//     Max?: number,
//     Min?: number,
//     UoM?: string,
//     Value?: number | string,
//     Image?: string,
//     ReportId?: string
//     WorkspaceId?: string
//     Section?:TAssetBagSection[]
//     Segments?:number[]
//     SegmentsLabels?:TSegmentLabel[]
//     SegmentsColors?:string[]
//     Period?:string[]
//     Continuità?:number[]
//     Consumi?:number[]
//     PartialUrl?:string
//     Paths?:string[]
//     GroupId?:string
//     Name?:string
//     TargetList?:Target[]
//     Labels?:string[]
//     DatasetData?:number[]
//     DatasetLabel?:string
//     DatasetBackgroundColors?:string[]
//     DatasetBorderColors?:string[]
// }
export type TAssetBag = IAssetBag & ITutorialAssetBag & IDoughnutAssetBag & ITargetAssetBag & IRangeAssetBag & IFilesAssetBag & IHistogramAssetBag & ICardKPIAssetBag & IReportAssetBag
export interface IAssetBag {
    HelpDescription:string
    DisplayTitle:boolean
    Title:string
    AdditionalInfos:string
    Format:number
}
export interface ITutorialAssetBag {
    PartialUrl?:string
    Paths?:string[]
    GroupId?:string
    IsDefault:boolean
}
export interface IDoughnutAssetBag {
    Helps?:TRowHelp[]
    Labels?:string[]
    DatasetData?:number[]
    DatasetLabel?:string
    DatasetBackgroundColors?:string[]
    DatasetBorderColors?:string[]
    IsDefault:boolean
}
export interface ITargetAssetBag{
    IsDefault:boolean
    TargetList?:Target[]
}
export interface IRangeAssetBag{
    Max?: number,
    Min?: number,
    UoM?: string,
    IsDefault:boolean
    Value?: number | string,
    Segments?:number[]
    SegmentsLabels?:TSegmentLabel[]
    SegmentsColors?:string[]
}
export interface IFilesAssetBag {
    PartialUrl?:string
    Paths?:string[]
    GroupId?:string
    IsDefault:boolean
}
export interface IHistogramAssetBag {
    Period?:string[]
    Continuita?:number[]
    Consumi?:number[]
    IsDefault:boolean
}
export interface ICardKPIAssetBag {
    Section?:TAssetBagSection[]
    IsDefault:boolean
}
export interface IReportAssetBag {
    Image?: string,
    ReportId?: string
    WorkspaceId?: string
    IsDefault:boolean
    PageName?:string
    DirectLink?:string
}
export enum TAssetTypes {
    Report = "Report",
    SpecialReport = "SpecialReport",
    CardKPI = "CardKPI",
    Service = "Service",
    FileTreeView = "FileTreeView",
    RangeKPI = "RangeKPI",
    ScoreCard = "ScoreCard",
    Tutorials = "Tutorial",
    Target = "Target",
    Histogram = "Histogram",
    Doughnut = "Doughnut",
    Newsletter = "Newsletter",
}
export enum TAssetLevels {
    MainPage = "MainPage",
    ProductPage = "ProductPage"
}

export interface TProductAsset {
    RowVersion:string
    IsDefault:boolean
    AssetBag: TAssetBag
    AssetType: TAssetTypes
    Name:string
    Description: string
    HelpDescription: string
    Id: number
    Level: TAssetLevels
    ProductId: string
    UIOrder:number
    ThumbnailsImageUrl?:string
    Url?:string
}

export type TGroupedProductAssets = {[key in TAssetTypes]?:TProductAsset[]}

export async function getProductAssets(productId?: string, level?: TAssetLevels): Promise<TProductAsset[]> {
    let url = `${process.env.REACT_APP_SERVER_URL}/ProductAssets?$count=true`
    if(productId || level){
        url += '&$filter='
    }
    if(productId){
        url += `ProductId eq '${productId}'`
    }
    if (level) {
        url += ` & Level eq ${level}`
    }
    return axios.get(url)
        .then(response => response.data as TProductAsset[]);
}

export interface CreateAssetPayload {
    productId: string,
    name: string,
    description: string,
    level: TAssetLevels,
    assetType: TAssetTypes,
    uiorder:number,
    assetBag: {
        workspaceId: string,
        reportId: string,
        image: string,
        pageName:string,
        directLink:string
    }
}
export async function createReport(payload:CreateAssetPayload){
    return axios.post(`${process.env.REACT_APP_SERVER_URL}/ProductAssets/Report`,payload).then((res)=>res.data)
}
export interface UpdateAssetPayload {
    rowVersion:string,
    id:number,
    productId: string,
    name: string,
    description: string,
    level: TAssetLevels,
    assetType: TAssetTypes,
    assetBag: {
        workspaceId: string,
        reportId: string,
        image: string
        pageName:string
    }
}
export async function updateReport(payload:TProductAsset){
    console.log(payload)
    return axios.put(`${process.env.REACT_APP_SERVER_URL}/ProductAssets/Report`,payload).then((res)=>res.data)
}
export async function updateHistogram(payload:TProductAsset){
    return axios.put(`${process.env.REACT_APP_SERVER_URL}/ProductAssets/Histogram`,payload).then((res)=>res.data)
}
export async function updateFileTreeView(payload:TProductAsset){
    return axios.put(`${process.env.REACT_APP_SERVER_URL}/ProductAssets/FileTreeView`,payload).then((res)=>res.data)
}
export async function updateRangeKPI(payload:TProductAsset){
    return axios.put(`${process.env.REACT_APP_SERVER_URL}/ProductAssets/RangeKPI`,payload).then((res)=>res.data)
}
export async function updateCardKPI(payload:TProductAsset){
    return axios.put(`${process.env.REACT_APP_SERVER_URL}/ProductAssets/CardKPI`,payload).then((res)=>res.data)
}
export async function updateProductAsset(payload:TProductAsset){
    return axios.put(`${process.env.REACT_APP_SERVER_URL}/ProductAssets`,payload).then((res)=>res.data)
}
export async function callDeleteAsset(id:number){
    return axios.delete(`${process.env.REACT_APP_SERVER_URL}/ProductAssets/${id}`).then((res)=>res.data)
}
export async function updateProduct(payload:TProduct){
    return axios.put(`${process.env.REACT_APP_SERVER_URL}/Product`,payload).then((res)=>res.data)
}