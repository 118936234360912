import "./App.css";
import {
  initializeIcons,
  Sticky,
  StickyPositionType,
  ThemeProvider,
} from "@fluentui/react";
import {  Route, Routes } from "react-router-dom";
import { GlobalProvider } from "./store/globalStore";
import {
  AuthenticatedTemplate,
  UnauthenticatedTemplate,
} from "@azure/msal-react";
import { UnauthenticatedPage } from "./routes/unauthenticated/UnauthenticatedPage";
import { useTitle } from "./hooks/useTitles";
import { DashboardsProvider } from "./store/dashboardsStore";
import { UserProvider } from "./store/userStore";
import { VersionChecker } from "./components/VersionChecker/VersionChecker";
import { NavigationProvider } from "./store/navigationStore";
import TopBar from "./components/topbar/TopBar";
import { know_urls } from "./types/userStore";
import { PowerBIProvider } from "./store/powerBiStore";
import { EnvironmentEvidence } from "./components/EnvironmentEvidence/EnvironmentEvidence";
import { GlobalErrorAlert } from "./components/GlobalErrorAlert/GlobalErrorAlert";
import { intesaLightTheme } from "./themes/themes";
import { InvalidLoginRequestPage } from "./pages/InvalidLoginRequestPage/InvalidLoginRequestPage";
import { AuthenticatedRoutes } from "./routes/AuthenticatedRoutes/AuthenticatedRoutes";
import { AssignmentsApp } from "./apps/assignments/AssignmentsApp";

function App() {
  useTitle();

  initializeIcons();
  
  return (<>
    <GlobalProvider>
      <DashboardsProvider>
        <PowerBIProvider>
          <UserProvider>
            <NavigationProvider>
              <AuthenticatedTemplate>
                <ThemeProvider applyTo="body" theme={intesaLightTheme}>
                  <div className="max-h-screen">
                    <GlobalErrorAlert />
                    <EnvironmentEvidence />
                    <Sticky stickyPosition={StickyPositionType.Header}>
                      <TopBar />
                    </Sticky>
                    <Routes>
                      <Route path="*" element={<AuthenticatedRoutes/>}/>
                    </Routes>
                    <AssignmentsApp />
                    <VersionChecker />
                  </div>
                </ThemeProvider>
              </AuthenticatedTemplate>
              <UnauthenticatedTemplate>
                <Routes>
                  <Route path="*" element={<UnauthenticatedPage/>} />
                  <Route
                    path={know_urls.registration_request_url}
                    element={<InvalidLoginRequestPage/>}
                  />
                </Routes>
              </UnauthenticatedTemplate>
            </NavigationProvider>
          </UserProvider>
        </PowerBIProvider>
      </DashboardsProvider>
    </GlobalProvider>
    
    </>
  );
}

export default App;
