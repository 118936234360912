import {FontIcon, IconButton} from "@fluentui/react";
import React from "react";
import useWindowSize from "../../utility/useWindowSize";
import {useDashboardsStore} from "../../store/hooks/useDashboardsStore";
import {useDashboardsNavigation} from "../../store/hooks/useDashboardsNavigation";
import {useNavigate} from "react-router-dom";
import { useQuery } from "../../hooks/useQuery";
import { QUERY_PAGE_KEY } from "../../hooks/useQueryValues";
import { isMobile } from "react-device-detect";
export const dashboardHeaderHeight = 60;
export const mobileDashboardHeaderHeight = 40;
const DashboardHeader: React.FC<{children?: React.ReactNode}> = ({
  children,
}) => {
  const {rowDashboardContainersidebarWidth} = useDashboardsStore();
  const {productId,maxPage} = useDashboardsNavigation();
  const query = useQuery();
  const page = parseInt(query.get(QUERY_PAGE_KEY) ?? "0")
  const navigate = useNavigate();

  const {width} = useWindowSize();
  const l = React.useMemo(() => {
    if (!width) return 40;
    if (width > 1000) return 40;
    return 24;
  }, [width]);
  const handlePrevPage = (e: any) => {
    e.stopPropagation();
    const _page = page ?? 1;
    navigate(`/${productId}?page=${(_page > 0 ? _page : 1) - 1}`);
  };
  const handleNextPage = (e: any) => {
    e.stopPropagation();
    let _page = page ?? 0;
    _page = _page + 1 > maxPage ? 0 : _page + 1;
    navigate(`/${productId}?page=${_page}`);
  };
  return (
    <div
      style={{
        marginLeft: rowDashboardContainersidebarWidth,
        width: `calc(100vw - ${rowDashboardContainersidebarWidth}px)`,
        zIndex: 2,
      }}
      className="fixed flex border-b bg-slate-700"
    >
      <div
        className="relative flex items-center"
        style={{width: "calc(100vw)",height:isMobile ? mobileDashboardHeaderHeight : dashboardHeaderHeight}}
      >
        <IconButton
          style={{
            zIndex: 9998,
            height: l,
            width: l,
            position: "absolute",
            left: 0,
            top: `calc(50% - ${l / 2}px)`,
          }}
          className="text-xl rounded-full hover:bg-transparent hover:!text-xxl"
          onClick={handlePrevPage}
        >
          <FontIcon iconName="CaretSolidLeft" className="arrow-icon" />
        </IconButton>
        {children}
        <IconButton
          style={{
            zIndex: 9998,
            height: l,
            width: l,
            position: "absolute",
            right: 0,
            top: `calc(50% - ${l / 2}px)`,
          }}
          className="text-xl rounded-full hover:bg-transparent hover:!text-xxl"
          onClick={handleNextPage}
        >
          <FontIcon iconName="CaretSolidRight" className="arrow-icon" />
        </IconButton>
      </div>
    </div>
  );
};
export {DashboardHeader};
