import React from "react";
import {useNavigate} from "react-router-dom";
import {TAssetTypes, TProductAsset} from "../../../services/products";
import {useDashboardsStore} from "../../../store/hooks/useDashboardsStore";
import {TPileSize, TSize} from "../../../types/general";
import useWindowSize from "../../../utility/useWindowSize";
import {Pile} from "../Pile";
import {
  EditableAssets,
  dashboardsContext,
} from "../../../store/dashboardsStore";
import {usePowerBIContext} from "../../../store/hooks/usePowerBiContext";
import {useUserContext} from "../../../store/hooks/useUserContext";
import {GetPowerBiItemInfos} from "../../../services/powerBi";
import {emergency_mails} from "../../../utility/emergency_mails";
type ReportPileProps = {
  productId: string;
  asset: TProductAsset;
  customSize?: TPileSize;
  type: TAssetTypes.Report | TAssetTypes.SpecialReport;
};
const ReportPile: React.FC<ReportPileProps> = ({
  asset,
  productId,
  customSize,
  type,
}) => {
  const {account} = useUserContext();
  const navigate = useNavigate();
  // const { isLoading: powerBiHelperLoading, assetIsAccessible } =
  // usePowerBIContext();
  const {state: dashboards_state} = React.useContext(dashboardsContext);
  const {viewDisabledAssets} = dashboards_state;
  // const disabled = !assetIsAccessible(asset) && false; //removed disabled temporary
  const {assetIsAccessible, isLoading} = usePowerBIContext();
  const isEmergencyMail = emergency_mails.includes(account.username);

  const targetUrl = React.useMemo(
    () =>
      isEmergencyMail
        ? `https://app.powerbi.com.mcas.ms/groups/${asset.AssetBag.WorkspaceId}/reports/${asset.AssetBag.ReportId}/?ctid=cc4baf00-15c9-48dd-9f59-88c98bde2be7&experience=power-bi`
        : `/report?type=report&area=${productId}&Id=${
            asset.AssetBag.ReportId
          }&wsId=${asset.AssetBag.WorkspaceId}&name=${asset.Name}${
            asset.AssetBag.PageName
              ? "&pageName=" + asset.AssetBag.PageName
              : ""
          }`,
    [productId, asset]
  );
  const disabled = !isEmergencyMail && !assetIsAccessible(asset);
  const handleReportClick = () => {
    if (!isEmergencyMail && disabled) return;
    if (asset.AssetBag.DirectLink) {
      return
    } else {
      navigate(targetUrl);
    }
  };
  const getImg = React.useCallback(
    (imgName?: string, imgRows?: number) => {
      const height = imgRows ? `${imgRows * 10}%` : "calc(100%)";
      const imgStyles: React.CSSProperties = {
        height,
        objectFit: "fill",
        borderRadius: 8,
        margin: 0,
      };
      if (!imgName) {
        return (
          <img
            src=""
            style={{height, objectFit: "fill"}}
            alt={`Image for ${imgName} Missing`}
          />
        );
      }
      const isUrl = imgName?.startsWith("http");
      if (isUrl) {
        return <img style={imgStyles} src={imgName} alt="Image Missing" />;
      }
      try {
        // const fileName = isReport ? '../../../svg/reports/' + imgName : '../../../svg/' + imgName
        return (
          <img
            style={imgStyles}
            src={imgName ? require("../../../images/reports/" + imgName) : ""}
            alt="Image Missing"
          />
        );
      } catch (error) {
        return (
          <img
            src=""
            style={{height, objectFit: "fill"}}
            alt={`Image for ${imgName} Missing`}
          />
        );
      }
    },
    [asset.AssetBag]
  );
  const {contextState} = useDashboardsStore();
  const {editMode} = contextState;
  const defaultSize = React.useMemo(() => "x2" as TPileSize, []);
  const edit = React.useMemo(
    () =>
      type === TAssetTypes.Report
        ? editMode[EditableAssets.REPORTS]
        : editMode[EditableAssets.SPECIAL_REPORTS],
    [type, editMode]
  );
  const activeClassNames = React.useMemo(
    () =>
      `relative shadow-xl m-2  rounded-lg border cursor-pointer border-slate-700 bg-slate-800 ${
        edit ? "" : "hover:!bg-slate-700 hover:!text-white"
      }`,
    [disabled, edit]
  );
  const disabledClassNames = React.useMemo(
    () =>
      `relative shadow-xl m-2  rounded-lg border cursor-default border-gray-700 bg-slate-800`,
    [disabled, edit]
  );
  const assetKey = React.useMemo(() => {
    return asset.Id ? asset.Id.toString() : targetUrl;
  }, [asset]);
  console.log(asset.AssetBag);
  if (!viewDisabledAssets && disabled) return null;
  return (
    <Pile
      openInNewTab={isEmergencyMail || asset.AssetBag.DirectLink !== undefined}
      loadingBiPreLoad={isLoading}
      disabled={disabled}
      id={assetKey}
      targetUrl={asset.AssetBag.DirectLink ?? targetUrl}
      customColor
      titleCustomBgColor={disabled ? "gray" : "rgb(51,65,85)"}
      title={asset.Name}
      img={getImg(asset.AssetBag.Image)}
      productId={productId}
      edit={edit}
      className={disabled ? disabledClassNames : activeClassNames}
      showHelp
      tooltip={{
        id: asset.AssetBag.ReportId ?? asset.Id.toString(),
        text: asset.Description ?? "",
      }}
      key={assetKey}
      size={customSize ?? defaultSize}
      titleSize="xl"
      onClick={handleReportClick}
      asset={asset}
      deletable
    />
  );
};
export {ReportPile};
