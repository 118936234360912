import React from "react";
import { TAssignment } from "../../types/assignments";
import { AssignmentRow } from "./AssignmentRow/AssignmentRow";
import { useNavigate } from "react-router-dom";
import { assignments_known_urls } from "../../types/userStore";
import {
  ASSIGNMENTS_CONTROLLER,
  getPagedAssignments,
} from "../../services/assignments";
import { PaginationEnum, paginationContext } from "../../store/pager";
import { isMobile } from "react-device-detect";
import { ISortableHeader } from "../../types/general";
import { ODataGrid } from "../ODataGrid/ODataGrid";
import { useIsMobile } from "../../utility/useIsMobile";
type Props = {
  headers: ISortableHeader<TAssignment>[];
  setHeaders: (headers: ISortableHeader<TAssignment>[]) => void
};
export const AssignmentsGrid: React.FC<Props> = ({ headers, setHeaders }) => {
  return (
    <ODataGrid
      expands=""
      controllerName={ASSIGNMENTS_CONTROLLER}
      // state={paginationState.pagination.ASSIGNMENTS}
      // setState={(pagination) => paginationDispatch({ type: "SET_PAGINATION", payload: { key: PaginationEnum.ASSIGNMENTS, pagination } })}
      // currentFilters={assignmentsFilters}
      // currentSortings={assignmentSortings}
      // currentItems={assignments}
      enumStoreKey={PaginationEnum.ASSIGNMENTS}
      // getPagedItems={getPagedAssignments}
      // handleRemoveSort={handleRemoveSort}
      // headerCurrentFilter={headerCurrentFilter}
      // headerCurrentSort={headerCurrentSort}
      headers={headers}
      setHeaders={setHeaders}
      // isLoading={isLoading}
      // lastCallFilters={paginationState.pagination.ASSIGNMENTS.LAST_CALL_FILTERS}
      // lastCallSortings={paginationState.pagination.ASSIGNMENTS.LAST_CALL_SORTINGS}
      // localFilter={localFilter}
      // removeHeaderFilter={removeHeaderFilter}
      // setHeaderFilter={setHeaderFilter}
      // setLocalFilter={handleSetLocalFilter}
      //   showFilters={paginationState.pagination.ASSIGNMENTS.showFilters}
      renderItem={(a: TAssignment) => (
        <div
          key={a.Id}
          // onClick={() => handleAssignmentClick(a)}
          className={`flex cursor-pointer  ${isMobile ? "" : "hover:!border-solid hover:!border-gray-200 hover:!shadow"
            } `} //${a.Status === "New" && !isMobile ? "!text-black bg-pink-100 !border-black" : 'bg-white'}
          style={{
            border: "1px solid white",
          }}
        >
          <AssignmentRow headers={headers} item={a} enumStoreKey={PaginationEnum.ASSIGNMENTS}/>
        </div>
      )}
    />
  );
};
