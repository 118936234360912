import axios from "axios";
import { Building } from "./building";
import { TProduct } from "./products";
export interface IUser {
    Id: string,
    Settings: any,
    AbilitazioniUtente: IUserArea[]
    RowVersion: string,
    User:string,
    CreationDate:string,
    LastTimeLogedIn:string
}

export interface ICurrentUser {
    Id: string,
    Settings: any,
    Abilitazioni: ICustomClaims[]
    RowVersion: string,
    User:string,
}

export interface ISettings {
    CRImmobile: string,
    buildings: Building[],
    selectedBuildings: Building[],
}

export interface ICustomClaims {
    ProductId: string,
    RuoloId: string,
}

export interface IUserRole {
    RuoloId:string,
    Description:string
}

export interface IUserArea {
    ProductId:string,
    IsEnabled:boolean,
    Descrizione:string,
    Note:string | null,
    RuoloId:string,
}

export interface IUserBuildings {
    Polo: string,
    CRImmobile: string[],
}


export async function GetUserInfo() {
    return axios.get<ICurrentUser>(`${process.env.REACT_APP_SERVER_URL}/User/Current`)
        .then(response => {
            return response.data;
        });
}

export async function GetUserDefaultBuilding() {
    return axios.get<IUser>(`${process.env.REACT_APP_SERVER_URL}/User/Current`)
        .then(response => {
            return JSON.parse(response.data.Settings.DefaultBuildingSettings) as IUserBuildings;
        });
}

export async function SetUserDefaultBuilding(userSettings: IUserBuildings) {
    return axios.put<IUser>(`${process.env.REACT_APP_SERVER_URL}/User/SetUserBuildings`, userSettings)
        .then(response => {
            const settings = JSON.parse(response.data.Settings);
            return settings as ISettings
        });
}


export async function GetUserRoles(){
    return axios.get<IUserRole[]>(`${process.env.REACT_APP_SERVER_URL}/UserRoles`).then((r)=>r.data)
}
export async function GetUserAreas(){
    return axios.get<IUserArea[]>(`${process.env.REACT_APP_SERVER_URL}/UserArea`).then((r)=>r.data)
}

export async function GetUsers(){
    return axios.get<IUser[]>(`${process.env.REACT_APP_SERVER_URL}/User`).then((r)=>r.data)
}

export async function updateUserRole(u:IUser,a:TProduct,ruoloId:string,isEnabled?:boolean){
    const payload = {
        userId:u.Id,
        ProductId:a.ProductId,
        RuoloId:ruoloId,
        note:"",
        isEnabled:isEnabled??false,
    }
    return await axios.put(`${process.env.REACT_APP_SERVER_URL}/User/SetUserPermission`,payload)
}