import React from "react";
import {useDashboardsNavigation} from "../../../store/hooks/useDashboardsNavigation";
import {ReportColumn} from "../../Columns/ReportColumn";
import {useDashboardsStore} from "../../../store/hooks/useDashboardsStore";
import {TAssetTypes} from "../../../services/products";
import {columnElementID} from "../../../types/stores/dashboardNavigationStore";
export type ReportsSectionProps = {
  reportType: TAssetTypes.Report | TAssetTypes.SpecialReport;
};
export const ReportsSection: React.FC<ReportsSectionProps> = ({reportType}) => {
  const {productId, areaSettings} = useDashboardsNavigation();
  const {groupedProductAssets} = useDashboardsStore();
  return (
    <>
      <ReportColumn
        settings={areaSettings?.REPORTS}
        productId={productId ?? ""}
        reportType={reportType}
        reports={
          groupedProductAssets[reportType]
            ?.filter((r) => r.ProductId === productId)
            .sort((a, b) => a.UIOrder - b.UIOrder) ?? []
        }
        
      />
    </>
  );
};
