import { createTheme } from "@mui/material/styles";
import { intesa_green } from "../../../themes/themes";

export const theme = createTheme({
    palette: {
        primary: {
          main: intesa_green,
          // main: 'rgb(37,137,0)',
        },
        secondary: {
          main: 'rgb(185 28 28)',
          contrastText:'rgb(255,255,255)'
        },
      },
  });