import { OutlinedInputProps, TextField, TextFieldProps, alpha, styled } from "@mui/material";
import './styles.css'
import { intesa_green } from "../../themes/themes";
const CustomTextField = styled((props: TextFieldProps) => (
    <TextField
        InputProps={{ disableUnderline: true } as Partial<OutlinedInputProps>}
        {...props}
    />
))(({ theme }) => ({
    '& .MuiInputBase-input': {
        color: 'white',
        backgroundColor: 'rgb(51,65,85)',
        borderColor: 'white',
        // borderRadius:10
    },
    '& .MuiFormLabel-root': {
        color: 'white'
    },
    '&.MuiInputLabel-root': {
        color: 'white'
    },
    '&.MuiInputLabel-root:focus': {
        color: 'white'
    },
    '&.MuiFilledInput-root': {
        overflow: 'hidden',
        borderRadius: 4,
        border: '1px solid white',
        transition: theme.transitions.create([
            'border-color',
            'background-color',
            'box-shadow',
        ]),
        '&.MuiOutlinedInput-notchedOutline': {
            borderColor: 'white'
        },
        '&:hover': {
            backgroundColor: 'transparent',
        },
        '&.Mui-focused': {
            backgroundColor: 'transparent',
            boxShadow: `${alpha(theme.palette.primary.main, 0.25)} 0 0 0 2px`,
            borderColor: intesa_green,
            color:'white'
        },
        '& fieldset': {
            borderColor: 'white'
        }
    },
}));
export const StyledTextField: React.FC<TextFieldProps> = (props) => {
    return <CustomTextField {...props} />
}