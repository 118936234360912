
import React from 'react'
import { AssignmentStatusEnum, EticketSourceTypeEnum, TAssignment } from '../../../types/assignments'

import { formatDate } from '../../../utility/formatDate'
import { DetailsContainer } from '../../DetailsContainer/DetailsContainer'
import { DetailsLabelValueContainer } from '../../DetailsLabelValueContainer/DetailsLabelValueContainer'
import { DetailsValue } from '../../DetailsValue/DetailsValue'
import { DetailsLabel } from '../../DetailsLabel/DetailsLabel'
import { Status_Mapper } from '../../../store/hooks/useHeaders'
import { TopBarLoading } from '../../TopBarLoading/TopBarLoading'
import { DetailsRow } from '../../DetailsRow/DetailsRow'
import { DetailsTitle } from '../../DetailsTitle/DetailsTitle'
import { assignments_known_urls } from '../../../types/userStore'
import { DetailNavigatorIcon } from '../../DetailNavigatorIcon/DetailNavigatorIcon'
import { DetailDescription } from '../../DetailsRow/DetailDescription/DetailDescription'
import { statusClassNames } from '../../ODataGrid/ODataGridRow/ODataGridRowCell/ODataGridRowCell'
type Props = {
    isLoading: boolean
    assignment: TAssignment
    fullWidth?: boolean
}
export const AssignmentDetailCard: React.FC<Props> = ({ isLoading, assignment, fullWidth }) => {
    return <DetailsContainer fullWidth={fullWidth}>
        {isLoading && <TopBarLoading />}
        <DetailsTitle
            text="Assegnazione"
            extraText={assignment.Id.toString()}
        />
        <DetailNavigatorIcon url={assignments_known_urls.assignment_detail} id={assignment.Id} />
        <DetailDescription description={assignment.Description} />
        <div className='flex flex-col items-center justify-start mt-2 p-2 shadow rounded border'>
            <div className='flex flex-row justify-between w-[100%]'>
                <div className='w-[50%]'>
                    <p className='text-lg text-left m-0 p-0'>Owner</p>
                    <p className='text-xl text-left m-0 p-0 font-bold'>{assignment.OwnerEmail}</p>
                </div>
                <div className='w-[50%]'>
                    <p className={statusClassNames(assignment.Status)}>Stato</p>
                    <p className='text-xl text-right m-0 p-0 font-bold'>{Status_Mapper[assignment.Status.toString().toLowerCase()]}</p>
                </div>
            </div>
            <div style={{ width: '100%', borderBottom: '1px solid lightgray' }} />
            <div className='flex flex-row justify-between w-[100%]'>
                <div className='w-[50%]'>
                    <p className='text-lg text-left m-0 p-0'>Polo</p>
                    <p className='text-xl text-left m-0 p-0 font-bold'>{assignment.Polo}</p>
                </div>
                <div className='w-[50%]'>
                    <p className='text-lg text-right m-0 p-0 pr-2'>Edificio</p>
                    <p className='text-xl text-right m-0 p-0 font-bold'>{assignment.BuildingName}</p>
                </div>
            </div>
            <div style={{ width: '100%', borderBottom: '1px solid lightgray' }} />
            <div className='flex flex-row justify-between w-[100%]'>
                <div className='w-[50%]'>
                    <p className='text-lg text-left m-0 p-0'>Data Creazione</p>
                    <p className='text-xl text-left m-0 p-0 font-bold'>{formatDate(assignment.OpeningTime)}</p>
                </div>
                <div className='w-[50%]'>
                    <p className='text-lg text-right m-0 p-0 pr-2'>Data Scadenza</p>
                    <p className='text-xl text-right m-0 p-0 font-bold'>{formatDate(assignment.ExpirationTime)}</p>
                </div>
            </div>
            <div style={{ width: '100%', borderBottom: '1px solid lightgray' }} />
            <div className='flex flex-row justify-between w-[100%]'>
                <div className='w-[50%]'>
                    <p className='text-lg text-left m-0 p-0'>{`Riferimento ${assignment.SourceType === EticketSourceTypeEnum.Metric ? "metrica" : "supermetrica"}`}</p>
                    <p className='text-xl text-left m-0 p-0 font-bold'>{assignment.ExternalReferenceId}</p>
                </div>
                <div className='w-[50%]'>
                    <p className='text-lg text-right m-0 p-0 pr-2'>ID Evento</p>
                    <p className='text-xl text-right m-0 p-0 font-bold'>{assignment.ExternalEventId}</p>
                </div>
            </div>
            <div style={{ width: '100%', borderBottom: '1px solid lightgray' }} />
            {assignment.Status === AssignmentStatusEnum.Resolved && <div className='flex flex-row justify-between w-[100%]'>
                <p className='text-lg text-left m-0 p-0'>{`Motivo chiusura`}</p>
                <p className='text-xl text-left m-0 p-0 font-bold'>{assignment.ClosureReason}</p>
            </div>}
        </div>
        {/* <DetailsRow>
            <DetailsLabelValueContainer direction='column'>
                <DetailsLabel label='Owner' />
                <DetailsValue bold value={assignment.OwnerEmail} />
            </DetailsLabelValueContainer>
            <DetailsLabelValueContainer direction='column'>
                <DetailsLabel label='ID' />
                <DetailsValue bold value={assignment.Id} />
            </DetailsLabelValueContainer>

        </DetailsRow>
        <DetailsRow >
            <DetailsLabelValueContainer direction='column'>
                <DetailsLabel label='Stato' />
                <DetailsValue bold value={Status_Mapper[assignment.Status.toString().toLowerCase()]} />
            </DetailsLabelValueContainer>
            {assignment.Status === AssignmentStatusEnum.Resolved &&
                <DetailsLabelValueContainer direction='column'>
                    <DetailsLabel label='Motivo Chiusura' />
                    <DetailsValue bold value={assignment.ClosureReason} />
                </DetailsLabelValueContainer>
            }

        </DetailsRow>
        <DetailsRow>
            <DetailsLabelValueContainer direction='column' fullWidth justify="center">
                <DetailsLabel label='Polo' />
                <DetailsValue align='left' value={assignment.Polo} />
            </DetailsLabelValueContainer>
            <DetailsLabelValueContainer direction='column'>
                <DetailsLabel label='Edificio' />
                <DetailsValue align='right' value={assignment.BuildingName} />
            </DetailsLabelValueContainer>
        </DetailsRow>
        <DetailsRow >
            <DetailsLabelValueContainer direction='column'>
                <DetailsLabel label='Data Creazione' />
                <DetailsValue bold value={formatDate(assignment.OpeningTime)} />
            </DetailsLabelValueContainer>
            <DetailsLabelValueContainer direction='column'>
                <DetailsLabel label='Data Scadenza' />
                <DetailsValue bold value={formatDate(assignment.ExpirationTime)} />
            </DetailsLabelValueContainer>
        </DetailsRow>
        <DetailsRow >
            <DetailsLabelValueContainer direction='column' fullWidth>
                <DetailsLabel label={`Riferimento ${assignment.SourceType === EticketSourceTypeEnum.Metric ? "metrica" : "supermetrica"}`} />
                <DetailsValue value={assignment.ExternalReferenceId} />
            </DetailsLabelValueContainer>
            <DetailsLabelValueContainer direction='column'>
                <DetailsLabel label={'ID Evento'} />
                <DetailsValue value={assignment.ExternalReferenceId} />
            </DetailsLabelValueContainer>
        </DetailsRow> */}
    </DetailsContainer>
}
