import React from 'react'
import { GlobalProvider } from './store/globalStore'
import { PaginationProvider } from './store/pager'
import { SidebarsProvider } from './store/sidebars'
import { NavigationProvider } from './store/navigationStore'
import { ThemeProvider } from '@mui/material'
import { theme } from './theme/theme'
import { AuthenticatedTemplate, UnauthenticatedTemplate } from '@azure/msal-react'
import { Route, Routes, useLocation } from 'react-router-dom'
import { UnauthenticatedPage } from './pages/unauthenticated/UnauthenticatedPage'
import { AuthenticatedRoutes } from './components/AuthenticatedRoutes/AuthenticatedRoutes'
type PROPS = {}
export const ASSIGNMENTS_AREA_PATH = "assignments"
export const AssignmentsApp: React.FC<PROPS> = () => {
  const { pathname } = useLocation();
  if (pathname.split('/')[1] !== ASSIGNMENTS_AREA_PATH) {
    return null;
  }
  return <GlobalProvider>
    <PaginationProvider>
      <SidebarsProvider>
        <NavigationProvider>
          <ThemeProvider theme={theme}>
            <AuthenticatedTemplate>
              <div className="max-h-screen">
                <Routes>
                  <Route path="*" element={<AuthenticatedRoutes />} />
                </Routes>
              </div>
            </AuthenticatedTemplate>
            <UnauthenticatedTemplate>
              <Routes>
                <Route path="*" element={<UnauthenticatedPage />} />
              </Routes>
            </UnauthenticatedTemplate>
          </ThemeProvider>
        </NavigationProvider>
      </SidebarsProvider>
    </PaginationProvider>
  </GlobalProvider>
}