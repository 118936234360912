import React from "react";
import useWindowSize from "../utility/useWindowSize";

import {useUserContext} from "../store/hooks/useUserContext";
import {
  DashboadSizeEnum,
  IFrontendSettingsGet,
  getAllFrontendSettings,
  getAllFrontendSettingsPerProductSize,
  getVisibleFrontendSettingsPerProductSize,
} from "../services/frontendSettings";
import {useDashboardSettings} from "../store/hooks/useDashboardSettings";
export enum ProductPageSection {
  SPECIAL_REPORTS = "SPECIAL_REPORTS",
  REPORTS = "REPORTS",
  SCORECARDS = "SCORECARDS",
  ALERTS = "ALERTS",
  KPI = "KPI",
  TUTORIALS = "TUTORIALS",
  SERVICES = "SERVICES",
  FILES = "FILES",
}
export type IDashboardColumnsDimensions = {
  [key in ProductPageSection]?: IFrontendSettingsGet;
};

export const DashboadSizeMap: {
  [key in DashboadSizeEnum]: (width?: number, height?: number) => boolean;
} = {
  [DashboadSizeEnum.MOBILE]: (width?: number, height?: number) =>
    width !== undefined && width < 600,
  [DashboadSizeEnum.TABLET]: (width?: number, height?: number) =>
    width !== undefined && width > 600 && width < 900,
  [DashboadSizeEnum.SM_DESKTOP]: (width?: number, height?: number) =>
    width !== undefined && width > 900 && width < 1300,
  [DashboadSizeEnum.MD_DESKTOP]: (width?: number, height?: number) =>
    width !== undefined && width > 1300 && width < 1700,
  [DashboadSizeEnum.LG_DESKTOP]: (width?: number, height?: number) =>
    width !== undefined && width > 1700 && width < 2000,
  [DashboadSizeEnum.XL_DESKTOP]: (width?: number, height?: number) =>
    width !== undefined && width > 2000,
};
export const DashboadSizeID = (width?: number, height?: number) => {
  if (!width) return undefined;
  for (let ID of Object.keys(DashboadSizeEnum)) {
    if (DashboadSizeMap[ID as DashboadSizeEnum](width, height)) {
      return ID as DashboadSizeEnum;
    }
  }
  return DashboadSizeEnum.MD_DESKTOP;
};
export const getDashboardPagesLocalStorageKey = (
  areaId: string,
  size: DashboadSizeEnum
) => `${areaId}-pages-${size}`;
export function useProductDashboardDimensions(
  productId?: string,
  // settingsContext:ISettingsContext,
  customSize?: DashboadSizeEnum,
  load_function_type?: "filtered_visible"|"filtered_all"|"all"
) {
  const {accessToken} = useUserContext();
  const {width, height} = useWindowSize();
  const currentSize = React.useMemo<DashboadSizeEnum | undefined>(
    () => DashboadSizeID(width, height),
    [width, height]
  );
  const size = React.useMemo<DashboadSizeEnum | undefined>(
    () => customSize ?? currentSize,
    [currentSize, customSize]
  );
  const sortSettingPages = React.useCallback(
    (a: IFrontendSettingsGet, b: IFrontendSettingsGet) => a.UIOrder - b.UIOrder,
    []
  );
  const {
    settings,
    isLoadingSettings,
    setIsLoadingSettings,
    setNewSettingsOnStore,
  } = useDashboardSettings();
  const areaKey = React.useMemo<string | undefined>(
    () =>
      size && productId
        ? getDashboardPagesLocalStorageKey(productId, size)
        : undefined,
    [size, productId]
  );
  const areaSettings = React.useMemo(
    () => (areaKey ? settings[areaKey] : undefined),
    [settings, areaKey]
  );
  
  const load_function = React.useMemo(()=>{
    switch(load_function_type){
      case "filtered_visible":return (productId: string, size: DashboadSizeEnum) =>getVisibleFrontendSettingsPerProductSize(productId, size);
      case "filtered_all":return (productId: string, size: DashboadSizeEnum) =>getAllFrontendSettingsPerProductSize(productId, size);
      case "all":return (productId: string, size: DashboadSizeEnum) =>getAllFrontendSettings();
      default:return (productId: string, size: DashboadSizeEnum) =>getVisibleFrontendSettingsPerProductSize(productId, size);
    }
  },[load_function_type])

  React.useEffect(() => {
    //settings
    const getCurrentSettings = async () => {
      if (!accessToken || !size || !areaKey || !productId) {
        return;
      }
      setIsLoadingSettings(true);
      load_function(productId, size)
        .then((res: IFrontendSettingsGet[]) => {
          setNewSettingsOnStore(res, areaKey);
        })
        .finally(() => setIsLoadingSettings(false));
    };
    if (areaKey && !settings[areaKey]) {
      getCurrentSettings();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [accessToken, areaKey]);

  //TODO reactive this on complete edit-dashboard-pages flow
  // React.useEffect(() => localStorage.setItem(areaKey, JSON.stringify(columns)), [columns])
  return {
    areaSettings,
    isLoadingSettings,
    setIsLoadingSettings,
    setNewSettingsOnStore,
    sortSettingPages,
    width,
    height,
    size,
    currentSize,
  };
}
