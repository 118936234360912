import axios from "axios";
import { TAssetTypes } from "./products";

export async function getPowerBiReport(workspaceId: string, reportId: string) {
  const headers = {
    "Content-Type": "application/json",
  };
  return axios
    .get(
      `${process.env.REACT_APP_SERVER_URL}/PowerBi?workspaceId=${workspaceId}&reportId=${reportId}`,
      { headers }
    )
    .then((response) => response.data);
}
export const GetPowerBiItemInfos = async (
  type: TAssetTypes,
  reportId: string,
  token: string
) => {
  let _type = "reports";
  switch (type) {
    case TAssetTypes.ScoreCard:
        _type = "scorecards";
        break;
    default: {
      _type = "reports";
      break;
    }
  }
  const url = `https://api.powerbi.com/v1.0/myorg/${_type}/${reportId}`;
  return fetch(url, {
    method: "GET",
    credentials: "include",
    headers: {
      Authorization: `Bearer ${token}`,
      "Content-Type": "application/json",
    },
  });
};
