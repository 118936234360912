import React from 'react';
import { IntesaBottomLogo } from '../IntesaBottomLogo/IntesaBottomLogo';
import { intesa_green } from '../../themes/themes';
import { useDashboardsStore } from '../../store/hooks/useDashboardsStore';
import { useLocation } from 'react-router-dom';
import { isMobile } from 'react-device-detect';
import { topbarHeight } from '../DashboardContainer/DashboardContainer';
const DashboardFooter: React.FC<{
    showBorder?: boolean,
    rightIcon?: React.ReactElement,
    leftIcons?: React.ReactElement
}> = ({ leftIcons: leftIcon, showBorder, rightIcon }) => {
    const { rowDashboardContainersidebarWidth } = useDashboardsStore();
    const {pathname} = useLocation();
    const marginLeft = React.useMemo(()=>{
        if(pathname === "/"){
            return 0;
        }
        return rowDashboardContainersidebarWidth;
    },[rowDashboardContainersidebarWidth,pathname])
    return <div
        style={{
            zIndex: 1,
            backgroundColor: intesa_green,
            borderColor: 'white',
            marginLeft,
            paddingBottom: 2,
            width: `calc(100vw - ${marginLeft}px)`,
            height:topbarHeight,
            display:'flex',
            position:'fixed',
            bottom:0,
            left:0,
        }}
        className={`relative ${showBorder ? 'border-t' : ''}`}>
        {leftIcon}
        {(!isMobile)&& <IntesaBottomLogo sidebarWidth={marginLeft} />}
        {rightIcon}

    </div>
}
export { DashboardFooter }