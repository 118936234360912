import styled from '@emotion/styled';
import { Tooltip, tooltipClasses, TooltipProps } from '@mui/material';
export const LightTooltip = styled(({ className, ...props }: TooltipProps) => (
    <Tooltip {...props} classes={{ popper: className }} />
))(({ theme }) => ({
    [`& .${tooltipClasses.arrow}`]: {
        color: 'rgb(240,240,240)',
        "&:before": {
            border: "1px solid rgba(0,0,0,0.4)"
        },
    },
    [`& .${tooltipClasses.tooltip}`]: {
        border: "1px solid rgba(0,0,0,0.4)",
        backgroundColor: 'rgb(240,240,240)',
        color: 'rgba(0, 0, 0, 0.87)',
        boxShadow: '1px 1px 1px rgba(0,0,0,0.4)',
        fontSize: 13,
    },
}));