import React from 'react';
import { IconProps, iconTextClassNames } from './iconsConstants';
import { DashboardSideIcon } from './DashboardSideIcon';
import { default_icon_styles } from './constants';
import { TbAppsFilled } from 'react-icons/tb'
import { ProductPageSection } from '../../../hooks/useProductDashboardDimensions';
const ServicesIcon: React.FC<IconProps> = ({onlyIcon }) => {
    const id = ProductPageSection.SERVICES
    const tooltip = {
        id:`${id}-dashboard_icon`,
        text:"Servizi"
    }
    const icon =<TbAppsFilled className={iconTextClassNames} style={default_icon_styles} />
    return <DashboardSideIcon
        onlyIcon={onlyIcon}
        id={id}
        tooltip={tooltip}
        icon={icon}
    />
}
export { ServicesIcon }