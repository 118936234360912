import React from 'react'
import { useIsMobile } from '../../../utility/useIsMobile'
import { sidebarsContext } from '../../../store/sidebars'
import { IconButton } from '@mui/material'
import { AiFillCaretDown, AiFillCaretUp } from 'react-icons/ai'
type Props = {
    children?: React.ReactNode
}
export const Bottombar: React.FC<Props> = ({ children }) => {
    const {isMobile,bottombarHeight} = useIsMobile();
    const {state,dispatch} = React.useContext(sidebarsContext)
    if(!isMobile){
        return null;
    }
    const bottombarIsOpen = state.bottombarHeight && state.bottombarHeight > 0
    return <div className="fixed flex justify-center items-center bg-intesa-green bottom-[0px] left-[0px] text-white"
        style={{
            width:'100vw',
            height: state.bottombarHeight,
            borderTop:'1px solid lightgrey'
        }}>
        {bottombarIsOpen && children}
    </div>
}