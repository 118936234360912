import React, {useState} from "react";
type Props = {
  id: string | number;
  min: number;
  value: number;
  max: number;
  percent: number;
  formatValue: (n: string | number) => string;
  title: string;
  getColor?: (n: number) => string;
};
const KPI: React.FC<Props> = ({
  value,
  min,
  max,
  percent,
  getColor,
  formatValue,
  title,
  id,
}) => {
  var _: any;
  const [state, setState] = useState({i: 0, overlayWidth: 0});
  const {overlayWidth, i} = state;
  let width = overlayWidth;
  const [currentPercent, setCurrentPercent] = React.useState(width);
  let ii = i;
  // const overlay_completer = () => {
  //     if (ii == 0) {
  //         var elem = document.getElementById(`kpi_${id}`);
  //         const frame = () => {
  //             if (!elem) {
  //                 return;
  //             }
  //             if (width >= percent) {
  //                 clearInterval(id);
  //             } else {
  //                 width++;
  //                 elem.style.width = width + '%';
  //                 setCurrentPercent((curr)=>curr+1)
  //             }
  //         }
  //         _ = setInterval(frame, 35);
  //         ii = 1;
  //     }
  // }
  // useEffect(() => {
  //     overlay_completer();
  //     return () => {
  //         if (id) {
  //             setState({
  //                 i: 0,
  //                 overlayWidth: 0,
  //             })
  //             clearInterval(id);
  //         }
  //     }
  // }, [])
  return (
    <div className="flex flex-col items-center grow mx-8 mt-2">
      <p className="text-xl text-left self-start">{title}</p>
      <div
        className="relative w-[100%] mt-2 flex relative items-center mx-8 rounded-md p-1 shadow-lg"
        style={{backgroundColor: "rgb(104,128,157)"}}
      >
        {percent > 5 && (
          <p className="absolute left-0 min-w-[30px] px-2 text-l h-[30px] pt-1">
            {formatValue(min)}
          </p>
        )}
        <div
          id={`kpi_${id}`}
          style={{
            width: `${percent}%`,
            backgroundImage:
              "linear-gradient(to right,rgb(71,84,104),rgb(49,61,81))",
            borderRadius: "0px 3px 3px 0px",
          }}
          className="flex justify-end items-center flex-row h-[30px] border-amber-500"
        >
          <p className="text-xl ml-auto">{formatValue(value)}</p>
        </div>
        {percent < 100 - formatValue(max).length * 2 && (
          <p
            className="absolute right-0 min-w-[30px] px-2 text-l h-[30px] pt-1"
            style={{borderRadius: "3px 0px 0px 3px"}}
          >
            {formatValue(max)}
          </p>
        )}
      </div>
    </div>
  );
};
export {KPI};
