import { FontIcon, IconButton } from '@fluentui/react';
import React from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { TopbarTitle } from '../TopbarTitle/TopbarTitle';
const SettingsTopBar: React.FC = () => {
    const location = useLocation();
    const navigate = useNavigate();
    const title = React.useMemo(() => {
        switch (location.pathname) {
            default: return "Configurazioni"
        }
    }, [location.pathname])
    return <div style={{alignItems:'center',width:'100vw',display:'flex'}} className="bg-slate-800">
        <IconButton style={{ height: 35, width: 35, margin: '0px 10px' }} className="rounded-full hover:bg-slate-600" onClick={() => navigate('/')}>
            <FontIcon iconName='CaretSolidLeft' className="text-white text-xl" />
        </IconButton>
        <FontIcon iconName='AccountManagement' className="text-white text-xxxl mr-8" />
        <TopbarTitle title={title} />
    </div>
}
export { SettingsTopBar }