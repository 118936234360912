import React from 'react'
import { HeaderFilterProps } from '../../types'
import { HeaderFilterCellFormControl } from '../HeaderFilterCellFormControl/HeaderFilterCellFormControl';
import { MenuItem, OutlinedInput, Select, SelectChangeEvent } from '@mui/material';
import { paginationContext } from '../../../../store/pager';
import { TAssignment } from '../../../../types/assignments';
import styled from '@emotion/styled';
import { useHeaderCell } from '../../hooks/useHeaderCell';
const StyledSelect = styled(Select)({
    borderRadius: 0
})
export const TagsHeaderFilterCell: React.FC<HeaderFilterProps> = ({
    header, enumStoreKey
}) => {
    const { setHeaderFilter, headerCurrentFilter, removeHeaderFilter, isLoading } = useHeaderCell({ header, enumStoreKey })

    const handleChange: React.ChangeEventHandler<HTMLInputElement> = (event: SelectChangeEvent<string[]>) => {
        const value = event.target.value === 'clear' ? [] : typeof event.target.value === 'string' ? event.target.value.split(',') : event.target.value;
        setHeaderFilter(header, value)
    }
    const { state } = React.useContext(paginationContext);
    const [availableTags, setAvailableTags] = React.useState<string[]>([]);
    React.useEffect(() => {
        let result: string[] = [];
        state.pagination.ASSIGNMENTS.items.forEach((a: TAssignment) => a.Tags.forEach((t) => {
            if (!result.includes(t.Tag)) {
                result.push(t.Tag)
            }
        }))
        if (result.length > availableTags.length) {
            setAvailableTags(result)
        }
    }, [state.pagination.ASSIGNMENTS.items])
    return <HeaderFilterCellFormControl
        header={header}
        input={
            <StyledSelect
                onContextMenu={(e) => {
                    e.preventDefault();
                    removeHeaderFilter(header)
                }}
                multiple
                label='Tags'
                disabled={isLoading}
                value={headerCurrentFilter ? headerCurrentFilter.value as string[] : []}
                input={<OutlinedInput id={header.label} label={header.label} onChange={handleChange} />}
                placeholder='Tags'
            >
                {availableTags.map((s) => <MenuItem value={s}>{s}</MenuItem>)}
                {/* <MenuItem key='clear' value='clear'>Rimuovi selezione</MenuItem> */}
            </StyledSelect>} />;
}