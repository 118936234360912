import axios from "axios";
import { IEventNotification } from "../types/eventNotifications";
import { IColumnFilter, IColumnSorting, ODataResponse } from "../types/odata";
import { buildOdataUrl } from "../utility/odata";

export const EVENT_NOTIFICATIONS_CONTROLLER = "History"
export const getPagedEventNotifications:(
    skip:number,
    top:number,
    sortings:IColumnSorting<IEventNotification>[],
    filters:IColumnFilter<IEventNotification>[],
    onlyCount?:boolean)=>Promise<ODataResponse<IEventNotification[]>> = async (skip,top,sortings,filters,onlyCount) => {
    const url = buildOdataUrl(EVENT_NOTIFICATIONS_CONTROLLER,skip,top,filters,sortings,onlyCount);
    return axios.get(url).then((res)=>{
        return res.data})
}