import { DefaultButton } from '@fluentui/react';
import axios, { AxiosResponse } from 'axios';
import React from 'react';
import { FlexCol } from '../Flex/FlexCol';
import { FlexRow } from '../Flex/FlexRow';
interface IVersionGet {
    Version: string
}
const VersionChecker: React.FC = () => {
    let id: number | undefined;
    const MS_interval = React.useMemo(() => 15 * 60 * 1000, []);    //every 15 minutes
    const lastFrontEndVersion = localStorage.getItem("frontend_version")
    const [state, setState] = React.useState({
        version: lastFrontEndVersion ?? '',
        reloadNeeded: false,
        storeResetRequired: false,
        mandatoryReload: false,
    })
    const checkVersion = () => {
        //api call then set state
        axios.get(`${process.env.REACT_APP_SERVER_URL}/Version`).then((res: AxiosResponse<IVersionGet>) => {
            if (state.version === '') {
                setState({
                    version: res.data.Version,
                    reloadNeeded: false,
                    storeResetRequired: false,
                    mandatoryReload: false,
                })
            }
            else if (state.version !== res.data.Version) {
                setState({
                    ...state,
                    reloadNeeded: true,
                    storeResetRequired: true,
                    mandatoryReload: true,
                })
            }
        })
    }
    React.useEffect(() => {
        checkVersion();
        setInterval(checkVersion, MS_interval)
        return () => { if (id) { clearInterval(id) } };
    }, [])
    const handleRefresh = () => {
        if (state.storeResetRequired) {
            localStorage.clear();
            sessionStorage.clear();
        }
        window.location.reload();
    }
    const onClose = () => {
        setState({
            ...state,
            storeResetRequired: false,
            mandatoryReload: false,
            reloadNeeded: false
        })
    }
    const containerStyles: React.CSSProperties = React.useMemo(() => {
        if (state.mandatoryReload) {
            return { position: 'absolute', width: 350, left: 'calc(50% - 175px)', bottom: '50%', zIndex: 9999999 }
        } else {
            return { position: 'absolute', width: 350, left: 50, bottom: '5%', zIndex: 9999999 }
        }
    }, [state])
    React.useEffect(()=>{
        localStorage.setItem("frontend_version",state.version);
    },[state.version])
    if (!state.reloadNeeded) return null;
    return <>
        {state.mandatoryReload && <div style={{ position: 'fixed', left: 0, top: 0, width: '100vw', height: '100vh', zIndex: 9999998, backgroundColor: 'rgba(0,0,0,0.25)' }} />}
        {/* <FlexCol style={{ position: 'absolute', width: 350, right: 'calc(50% - 175px)', top: '15%', zIndex: 9999999 }} className="bg-white p-2 rounded" alignItems='center' justifyContent='center'> */}
        <FlexCol style={containerStyles} className="border-amber-400 border-4 bg-white p-2 rounded" alignItems='star5t' justifyContent='space-between'>
            <p className="text-xxl">Nuova versione disponibile, utilizza il pulsante sotto per aggiornare all'ultima versione disponibile</p>
            <FlexRow className="mt-4 w-[100%]" alignItems='center' justifyContent='space-between'>
                {!state.mandatoryReload && <p className="text-base self-start text-blue-800 cursor-pointer hover:underline mt-auto" onClick={onClose}>Aggiorna dopo</p>}
                <DefaultButton
                    className={`bg-slate-600 rounded text-white ${state.mandatoryReload ? 'w-[100%]' : 'ml-auto'}`}
                    text="Aggiorna"
                    onClick={handleRefresh}
                />
            </FlexRow>
        </FlexCol>
    </>
}
export { VersionChecker }