import React from "react";
import { withAITracking } from "@microsoft/applicationinsights-react-js";
import "./TopBar.style.scss";
import { useLocation } from "react-router-dom";
import { TopBarWrapper } from "./TopBarWrapper/TopBarWrapper";
import { ReportTopBar, reportTopbarHeight } from "./ReportTopBar/ReportTopBar";
import { SettingsTopBar } from "./SettingsTopBar/SettingsTopBar";
import { NoTopbarUrls } from "./constants";
import { useMsal } from "@azure/msal-react";
import { AccountInfo } from "@azure/msal-browser";
import { IFacepilePersona } from "@fluentui/react";
import { useUserStore } from "../../store/hooks/useUserStore";
import { getUserProfileImageUrl } from "../../services/graph";
import { reactPlugin } from "../application-insights/AppInsights";
import { know_urls } from "../../types/userStore";
const TopBar = () => {
  const { account, setGraphAccessToken, setUserImage } =
    useUserStore(); //authentication and accessToken management. ONLY HERE.
  const { instance } = useMsal();
  React.useEffect(() => {
    if (account) {
      const request = {
        scopes: [
          "User.Read",
          "https://graph.microsoft.com/Files.Read.All",
          "https://graph.microsoft.com/Tasks.ReadWrite",
        ],
        account: account as AccountInfo,
      };
      instance.setActiveAccount(account);

      instance
        .acquireTokenSilent(request)
        .then((response) => {
          setGraphAccessToken(response.accessToken);
          getUserProfileImageUrl(response.accessToken).then((arrayBuffer) => {
            if (arrayBuffer) {
              const user: IFacepilePersona[] = [
                {
                  imageUrl: URL.createObjectURL(new Blob([arrayBuffer])),
                },
              ];
              setUserImage(user);
            }
          });
        })
        .catch((e) => {
          console.log(e);
          // applicationStore?.notification.addError(e);
        });
    }
  }, [account]);
  const location = useLocation();
  if (NoTopbarUrls.includes(location.pathname)) {
    return null;
  }
  const isReportPage = location.pathname === know_urls.report;
  const isSettingsPage = location.pathname === know_urls.user_settings || location.pathname === know_urls.assignments_settings;
  if (isReportPage) {
    return (
      <TopBarWrapper extraStyles={{ height: reportTopbarHeight }}>
        <ReportTopBar />
      </TopBarWrapper>
    );
  }
  if (isSettingsPage) {
    return (
      <TopBarWrapper>
        <SettingsTopBar />
      </TopBarWrapper>
    );
  }
  return null;
};

export default withAITracking(reactPlugin, TopBar);
