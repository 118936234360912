import React from "react";
import { AuthError, InteractionStatus } from "@azure/msal-browser";
import { useIsAuthenticated, useMsal } from "@azure/msal-react";
import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { Page } from "../../components/Page/Page";
import { apiLoginRequest } from "../../../../authConfig";

export const UnauthenticatedPage = () => {
  const { instance, accounts, inProgress } = useMsal();
  const authenticated = useIsAuthenticated();
  const navigate = useNavigate();
  const handleTryLogin = React.useCallback(() => {
    if (!authenticated && inProgress === InteractionStatus.None) {
      console.log("1");
      const tryLogin = async () => {
        instance
          .loginRedirect(apiLoginRequest)
          .then((res) => {
            console.log("2");
            console.log(res);
          })
          .catch((err: AuthError) => {
            console.log(err);
          })
          .finally(() => {
            console.log("FINALLY AUTH");
          });
      };
      const handleResponse = (res: any) => {
        console.log("11");
        console.log(res);
      };
      instance
        .handleRedirectPromise()
        .then(handleResponse)
        .catch((err: AuthError) => {
          console.log(err);
        });
      tryLogin();
    }
  }, [accounts, authenticated, inProgress, instance, navigate]);
  useEffect(() => {
    handleTryLogin();
  }, [authenticated, handleTryLogin]);

  return (
    <Page
    >
      <p className="mt-10 text-4xl text-center w-[100%]">
        You are not signed in! Please sign in.
      </p>
    </Page>
  );
};
