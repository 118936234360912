import React from 'react'
export const DetailsLabelValueContainer: React.FC<{
    fullWidth?: boolean, 
    children: React.ReactNode, 
    roundedUp?: boolean, 
    roundedDown?: boolean, 
    direction?: "row" | "column" | "row-reverse",
    grow?:boolean
}> = ({ fullWidth, direction, children, roundedDown, roundedUp,grow }) => {
    const flexDirection = (direction) ? { flexDirection: direction } : {}
    return <div
        className={`flex flex-row items-stretch justify-start ${roundedDown ? 'rounded-b-lg' : ''} ${roundedUp ? 'rounded-t-lg' : ''}`}
        style={{
            padding: '2px 4px',
            maxWidth: (fullWidth) ? '100%' : grow ? undefined : '65%',
            width: (fullWidth) ? '100%' : grow ? undefined : 'calc(45% - 8px)',
            flexGrow: grow ? 1 : undefined,
            ...flexDirection,
        }}>
        {children}
    </div>
}