import React from "react";
import "./styles.css";
import {IFrontendSettingsGet} from "../../services/frontendSettings";
import { useDashboardsStore } from "../../store/hooks/useDashboardsStore";
import { isMobile } from "react-device-detect";
import { dashboardHeaderHeight, mobileDashboardHeaderHeight } from "../DashboardHeader/DashboardHeader";
type Props = {
  id: string;
  extraStyles?: React.CSSProperties;
  cotainerStyles?: React.CSSProperties;
  settings?: IFrontendSettingsGet;
  title: string;
  showBorder?: boolean;
  children?: React.ReactNode;
};
const VerticalDashboardSection: React.FC<Props> = ({
  id,
  children,
  extraStyles,
  title,
  showBorder,
  cotainerStyles,
  settings,
}) => {
  const {rowDashboardContainersidebarWidth} = useDashboardsStore();
  const x_padding_perc = React.useMemo(() => 2, []);
  const x_padding = React.useMemo(() => `${x_padding_perc}%`, [x_padding_perc]);
  const width_padding_reduction = React.useMemo(
    () => `${x_padding_perc * 2}%`,
    [x_padding_perc]
  );
  return (
    <div
      id={id}
      className="items-start justify-stretch flex flex-col h-[100%]"
      style={{
        overflowY: "auto",
        maxHeight: `calc(100vh - ${isMobile ? mobileDashboardHeaderHeight : dashboardHeaderHeight}px)`,
        marginTop: isMobile ? mobileDashboardHeaderHeight : dashboardHeaderHeight,
        padding: `10px ${x_padding}`,
        // minWidth: `calc(${
        //   10 * (settings?.SectionColumns ?? 3)
        // }% - ${width_padding_reduction} - ${rowDashboardContainersidebarWidth}px)`,
        minWidth:`calc((100% - ${rowDashboardContainersidebarWidth}px) * ${((settings?.SectionColumns) ?? 3) / 10})`,
        ...cotainerStyles,
      }}
    >
      <p
        style={{fontSize: "2.5rem"}}
        className={`text-center w-[100%] ${showBorder ? "border-b" : ""}`}
      >
        {title}
      </p>
      <div
        className="w-[100%] flex flex-row flex-wrap"
        style={{...extraStyles}}
      >
        {children}
      </div>
    </div>
  );
};
export {VerticalDashboardSection};
