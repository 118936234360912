import {IComboBoxOption} from "@fluentui/react";
import React from "react";
import {
  TAssetTypes,
  TProductAsset,
  updateReport,
} from "../../services/products";
import {useDashboardsStore} from "../../store/hooks/useDashboardsStore";
import {usePowerBiAuth} from "../powerBi/hooks/usePowerBiAuth";
import {DashboardComboBox} from "../DashboardComboBox/DashboardComboBox";
import {DashboardTextInput} from "../DashboardTextInput/DashboardTextInput";
import {FlexRow} from "../Flex/FlexRow";
import {MiniLoadingSpinner} from "../spinner/MiniLoadingSpinner/MiniLoadingSpinner";
import {DashboardButton} from "../DashboardButton/DashboardButton";
import {usePowerBIContext} from "../../store/hooks/usePowerBiContext";
import {known_products} from "../../types/userStore";
import {EditPanel} from "../EditPanel/EditPanel";
interface Props {
  asset: TProductAsset;
  productId: string;
  closePanel: () => void;
}
const EditAssetPanel: React.FC<Props> = ({asset, closePanel, productId}) => {
  const [error, setError] = React.useState("");
  const [state, setState] = React.useState(asset);
  const areaId = React.useMemo(
    () =>
      Object.values(known_products).find(
        (x) => x.dashboard_url.slice(1) === productId
      )?.id,
    [productId]
  );
  const [selectedOption, setSelectedOption] = React.useState<null | string>(
    asset.AssetBag.ReportId ?? ""
  );
  const [isLoading, setIsLoading] = React.useState(false);
  React.useEffect(() => setState(asset), [asset]);
  const handleStateChange = (
    name:
      | "directLink"
      | "pageName"
      | "name"
      | "description"
      | "img"
      | "reportId"
      | "uiorder"
      | "workspaceId"
      | "id",
    value: any
  ) => {
    switch (name) {
      case "name": {
        setState({...state, Name: value});
        break;
      }
      case "description": {
        setState({...state, Description: value});
        break;
      }
      case "img": {
        setState({...state, AssetBag: {...state.AssetBag, Image: value}});
        break;
      }
      case "pageName": {
        setState({...state, AssetBag: {...state.AssetBag, PageName: value}});
        break;
      }
      case "directLink": {
        setState({...state, AssetBag: {...state.AssetBag, DirectLink: value}});
        break;
      }
      case "reportId": {
        setState({...state, AssetBag: {...state.AssetBag, ReportId: value}});
        break;
      }
      case "uiorder": {
        setState({...state, UIOrder: value});
        break;
      }
      case "workspaceId": {
        setState({...state, AssetBag: {...state.AssetBag, WorkspaceId: value}});
        break;
      }
      case "id": {
        setState({...state, AssetBag: {...state.AssetBag, ReportId: value}});
        break;
      }
      default:
        return;
    }
  };
  const onChange = (e: any) => {
    handleStateChange(e.target.name, e.target.value);
  };
  const {editAssetOnStore} = useDashboardsStore();

  const handleSubmit = React.useCallback(
    async (e: any) => {
      setIsLoading(true);
      updateReport(state)
        .then((res) => {
          setIsLoading(false);
          editAssetOnStore(res);
          closePanel();
        })
        .catch((err) => {
          setIsLoading(false);
          setError("Some error happened, please refresh the page and retry.");
        });
    },
    [state]
  );
  const [biTokens, setBiTokens] = React.useState<{[key: string]: string}>({});

  const {getAccessToken, getReportsList, getScorecardsList} = usePowerBiAuth({
    setAccessToken: (token: string) => {
      if (asset.AssetBag.WorkspaceId) {
        setBiTokens({...biTokens, [asset.AssetBag.WorkspaceId]: token});
      }
    },
    catchError: (err: any, ssoError: boolean) => {
      console.log(err);
    },
  });
  const handleOptionChange = (e: any, option: any) => {
    setState({
      ...state,
      Name: option.text,
      AssetBag: {...state.AssetBag, ReportId: option.key},
    });
  };
  const {workspace_items} = usePowerBIContext();
  const [assetOptions, setAssetOptions] = React.useState<IComboBoxOption[]>([]);
  React.useEffect(() => {
    if (
      state.AssetBag.WorkspaceId &&
      workspace_items[state.AssetBag.WorkspaceId]
    ) {
      setAssetOptions(
        workspace_items[state.AssetBag.WorkspaceId].map((x) => ({
          key: x.id,
          text: x.name,
        }))
      );
    }
  }, [state.AssetBag.WorkspaceId]);

  const assetTypeOptions = React.useMemo(
    () => [
      {key: TAssetTypes.Report.toString(), text: "Report Tecnico"},
      {key: TAssetTypes.ScoreCard.toString(), text: "Scorecard"},
      {key: TAssetTypes.SpecialReport.toString(), text: "Report di business"},
    ],
    []
  );
  const handleTypeChange = (e: any, option: any) => {
    if (option) {
      switch (option.key) {
        case TAssetTypes.Report.toString(): {
          setState({...state, AssetType: TAssetTypes.Report});
          break;
        }
        case TAssetTypes.ScoreCard.toString(): {
          setState({...state, AssetType: TAssetTypes.ScoreCard});
          break;
        }
        case TAssetTypes.SpecialReport.toString(): {
          setState({...state, AssetType: TAssetTypes.SpecialReport});
          break;
        }
      }
    }
  };
  console.log(state)
  return (
    <EditPanel title={`Modifica ${asset.Name}`} closePanel={closePanel}>
      <div
        className="flex flex-col px-2 w-[100%] overflow-auto"
        style={{height: "calc(100vh - 200px)"}}
      >
        <DashboardComboBox
          label="Tipo:"
          disabled={isLoading}
          options={assetTypeOptions}
          selectedKey={state.AssetType.toString()}
          onChange={handleTypeChange}
        />
        <DashboardComboBox
          label="Selezione:"
          disabled={isLoading}
          options={assetOptions}
          selectedKey={state.AssetBag.ReportId ?? ""}
          onChange={handleOptionChange}
        />
        <DashboardTextInput
          disabled={isLoading}
          name="name"
          value={state.Name}
          onChange={onChange}
          label="Nome:"
          inputType="text"
        />
        <DashboardTextInput
          name="directLink"
          disabled={isLoading}
          value={state.AssetBag.DirectLink}
          onChange={onChange}
          label="Link diretto:"
          inputType="text"
        />
        <DashboardTextInput
          name="id"
          disabled={isLoading}
          value={state.AssetBag.ReportId}
          onChange={onChange}
          label="ID:"
          inputType="text"
        />
        <DashboardTextInput
          disabled={isLoading}
          name="workspaceId"
          value={state.AssetBag.WorkspaceId}
          onChange={onChange}
          label="Workspace Id:"
          inputType="text"
        />
        <DashboardTextInput
          name="pageName"
          disabled={isLoading}
          value={state.AssetBag.PageName}
          onChange={onChange}
          label="Pagina:"
          inputType="text"
        />
        <DashboardTextInput
          allowUploadFile
          setValue={(value: any) =>
            setState({...state, AssetBag: {...state.AssetBag, Image: value}})
          }
          disabled={isLoading}
          name="img"
          value={state.AssetBag.Image}
          onChange={onChange}
          label="Immagine:"
          inputType="text"
        />
        <DashboardTextInput
          multiline
          disabled={isLoading}
          name="description"
          value={state.Description}
          onChange={onChange}
          label="Tooltip:"
          inputType="text"
        />
        <DashboardTextInput
          inputType="number"
          disabled={isLoading}
          name="uiorder"
          value={state.UIOrder}
          onChange={onChange}
          label="Posizione:"
        />
      </div>
      <FlexRow
        style={{
          position: "absolute",
          left: 0,
          bottom: 0,
          padding: 10,
          height: 50,
        }}
        alignItems="center"
        justifyContent="center"
        className="w-[100%]  bg-slate-800"
      >
        <p className="text-red-500 text-xxl w-[100%] font-bold text-center">
          {error}
        </p>
        {isLoading ? <MiniLoadingSpinner /> : <div style={{width: 50}} />}
        <DashboardButton
          className="!mb-2"
          disabled={isLoading}
          onClick={handleSubmit}
          text="Salva"
        />
      </FlexRow>
    </EditPanel>
  );
};
export {EditAssetPanel};
