import { Alert } from "@mui/material";
import React from "react";
import { intesa_green } from "../../themes/themes";
type Props = {
  isOpen: boolean;
  onClose: () => void;
  severity: "success" | "error" | "warning";
  style: React.CSSProperties;
  children: React.ReactNode;
  duration: number;
};
const TimedAlert: React.FC<Props> = ({
  children,
  isOpen,
  onClose,
  severity,
  style,
  duration,
}) => {
  const progress_div_id = "timed_alert_progress";
  var id: any;
  const handleClose = () => {
    clearInterval(id);
    if (onClose) {
      onClose();
    }
  };
  const overlay_completer = () => {
    var elem = document.getElementById(progress_div_id);
    if (elem) {
      let width = parseInt(elem.style.width);
      const frame = () => {
        if (!elem) {
          return;
        }
        if (width >= 100) {
          handleClose();
        } else {
          width++;
          elem.style.width = width + "%";
        }
      };
      if (width === 1) {
        id = setInterval(frame, duration / 100);
      }
    }
  };
  React.useEffect(() => {
    if (isOpen) {
      overlay_completer();
    }
  }, [isOpen]);
  const getBackgroundColor = () => {
    switch (severity) {
        case "success":
            return intesa_green
        case "error":
            return "red";
        case "warning":
            return "yellow"
        default:
            return intesa_green;
    }
  }
  const backgroundColor = getBackgroundColor()
  if(!isOpen){
    return null;
  }
  return (<Alert severity={severity} style={style}>
        {children}
        <div
          id={progress_div_id}
          style={{ position:'absolute',bottom:0,left:0,marginTop: "auto", height: 2,zIndex:0, width: "1%",backgroundColor:backgroundColor }}
        ></div>
      </Alert>
  );
};
export { TimedAlert };
