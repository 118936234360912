import { Typography } from '@mui/material'
import React from 'react'
import { useIsMobile } from '../../utility/useIsMobile'
export const DetailsLabel: React.FC<{ label: string,bold?:boolean,align?: "center" | "right" | "left" | "inherit" | "justify" }> = ({ label,bold,align }) => {
    const {isMobile} = useIsMobile();
    return <div className='!mr-2 min-h-[20px]' style={{}}>
        <Typography 
        variant='body1' 
        align={align ?? (isMobile ? 'center' : 'right')}
        className={`${isMobile ? "!text-xl":"!text-2xl"} ${bold ? "!font-bold" : ""} `}>
            {label}:
        </Typography>
    </div>
}