import React from 'react';
interface Props {
    children?: React.ReactNode,
    justifyContent?: 'start' | 'center' | 'end' | string
    alignItems?: 'start' | 'center' | 'end' | string
    style?: React.CSSProperties
    className?: string
}
const FlexCol: React.FC<Props> = ({ children, style, className, justifyContent,alignItems }) => {
    return <div style={
        {
            display: 'flex',
            flexDirection: 'column', 
            justifyContent:justifyContent ? justifyContent : 'start', 
            alignItems:alignItems?alignItems : 'start',
            ...style
        }} className={className ?? ''}>
        {children}
    </div>
}
export { FlexCol }