import { TextField } from '@fluentui/react';
import React from 'react';
import './styles.css';
import { FlexRow } from '../Flex/FlexRow';
import { uploadFile } from '../../services/files';
type Props = {
    onChange: (e: any) => void,
    value: any
    disabled?: boolean
    label: string
    name: string
    containerExtraClassNames?: string
    inputContainerExtraClassNames?: string
    multiline?: boolean
    placeholder?: string
    labelCustomClassNames?: string
    onlyTextfield?: boolean
    extraInputStyles?: React.CSSProperties
    fieldGroupCustomBg?: string
    inputType: "text" | "number"
    rows?: number
    noMargins?: boolean
    allowUploadFile?: boolean
    setValue?: (value: any) => void
    onBlur?: (e: any) => void,
    onKeyDown?: (e: any) => void
}
const DashboardTextInput: React.FC<Props> = ({ setValue, allowUploadFile, noMargins, rows, inputType, fieldGroupCustomBg, extraInputStyles, onlyTextfield, labelCustomClassNames, placeholder, multiline, onChange, onKeyDown, name, label, value, disabled, containerExtraClassNames, inputContainerExtraClassNames, onBlur }) => {
    const activeInputClassName = React.useMemo(() => 'leading-8 text-xxl bg-slate-500 text-white rounded outline-none', [])
    const disabeldInputClassName = React.useMemo(() => 'leading-8 text-xxl bg-slate-500 text-gray', [])
    const inputClassName = React.useMemo(() => disabled ? disabeldInputClassName : activeInputClassName, [disabled])
    const textField = <TextField
        onBlur={onBlur}
        onKeyDown={onKeyDown}
        placeholder={placeholder ?? ''}
        autoAdjustHeight={rows !== undefined}
        type={inputType}
        multiline={multiline}
        rows={rows ?? undefined}
        disabled={disabled}
        styles={{ fieldGroup: { height: undefined, outline: 'none !important', outlineColor: 'transparent', backgroundColor: fieldGroupCustomBg ?? 'rgb(51,65,85)', border: 'none' }, ...extraInputStyles }}
        className={`outline-none text-xl grow ${inputContainerExtraClassNames ?? ''}`}
        name={name}
        inputClassName={inputClassName}
        autoComplete="off"
        onChange={onChange}
        value={value} />
    const handleFileClick = async (e: any) => {
        const file = e.target.files[0];
        if (!file) return;
        const formData = new FormData();
        formData.append("file", file)
        uploadFile(formData).then((res) => {
            if (setValue) {
                setValue(res.data)
            }
        }).catch(err => console.log(err))
    }
    if (onlyTextfield) return textField
    return <FlexRow className={`min-h-[60px] w-[100%] ${containerExtraClassNames ?? ''} ${noMargins ? '' : 'my-4'}`} alignItems='center'>
        <p className={labelCustomClassNames ?? "text-xl mr-2 w-[25%] min-w-[100px]"}>{label}</p>
        {textField}
        {allowUploadFile && <label className="custom-file-upload"><input type="file" onChange={handleFileClick} />Carica</label>}
    </FlexRow>
}
export { DashboardTextInput }