import React from 'react'
import { ISortableHeader } from '../../../types/general'
import { IoIosResize } from 'react-icons/io'
type PROPS = {
    startResize:(e:React.MouseEvent<HTMLDivElement>,header:ISortableHeader<any>)=>void
    header:ISortableHeader<any>
}
export const ResizeIconButton: React.FC<PROPS> = ({
    startResize,
    header
}) => {
    
    // return null;    //TODO così non prende lo stopResize se non è all'uscita il mouse non è all'interno dell'header
    return <>
        <div
            onMouseDown={(e)=>startResize(e,header)}
            
            style={{
                position: 'absolute',
                cursor: 'ew-resize',
                right: 5,
                top: 2,
                height: 10,
                width: 10,
                // border: '1px solid black',
                backgroundColor: 'rgba(0,0,0,0)'
            }}>
            {/* <IoIosResize /> */}
        </div>
    </>
}