import React from 'react';

import {
    Chart as ChartJS,
    Tooltip,
    Legend,
    ArcElement,
    TooltipItem,
} from 'chart.js';
import ChartDataLabels, { Context } from 'chartjs-plugin-datalabels';
import {
    Doughnut
} from 'react-chartjs-2';
import { TProductAsset } from '../../services/products';
import useWindowSize from '../../utility/useWindowSize';

ChartJS.register(ArcElement, Tooltip, Legend, ChartDataLabels);
ChartJS.defaults.set('plugins.datalabels', {
    color: 'white',
    align: 'end',
    offset: 0,
    anchor: 'end',
    fontSize: 30,
});
type Props = {
    assets: TProductAsset[]
    doughnutContainerWidth?: number
    noContainer?: boolean
}
export const mockData = {
    labels: ['Red', 'Blue', 'Yellow', 'Green', 'Purple', 'Orange'],
    datasets: [
        {
            label: 'Color',
            data: [12, 19, 3, 5, 2, 3],
            backgroundColor: [
                'rgb(255, 99, 132)',
                'rgb(54, 162, 235)',
                'rgb(255, 206, 86)',
                'rgb(75, 192, 192)',
                'rgb(153, 102, 255)',
                'rgb(255, 159, 64)',
            ],
            borderColor: [
                'white',
                'white',
                'white',
                'white',
                'white',
            ],
            borderWidth: 1,
        },
    ],
};

const DoughnutKpi: React.FC<Props> = ({ assets, noContainer }) => {
    const tablesKpiID = "table-kpi"
    const { width, height } = useWindowSize();
    const [doughnutContainerWidth, setDoughnutContainerWidth] = React.useState<number | undefined>(undefined);
    React.useEffect(() => {
        const tables = document.getElementsByClassName(tablesKpiID);
        if (!tables || tables.length === 0) return;
        let _ = tables[0].getBoundingClientRect().width;
        if (width) {
            setDoughnutContainerWidth(_);
        }
    }, [width, height])
    const mock = false;
    const data = (asset: TProductAsset) => mock ? mockData : ({
        labels: asset.AssetBag.Labels ?? [],
        datasets: [
            {
                label: asset.AssetBag.DatasetLabel ?? '',
                data: asset.AssetBag.DatasetData ?? [],
                backgroundColor: asset.AssetBag.DatasetBackgroundColors ?? [],
                borderColor: asset.AssetBag.DatasetBorderColors ?? [],
                borderWidth: 1,
            }
        ]
    })
    const doughnutDefaultWidth = React.useMemo(()=>assets.length === 1 ? height && height < 1900 ? 275 : 300 : 200,[assets.length, height]);
    const doughnutWidthOffset = React.useMemo(()=>doughnutDefaultWidth / 6,[doughnutDefaultWidth]);
    const doughnutRadiusToWidthFactor = React.useMemo(()=>1 / 3.5,[])
    const doughnutWidth = React.useMemo(() => !noContainer && doughnutContainerWidth ? doughnutContainerWidth - doughnutWidthOffset : doughnutDefaultWidth, [doughnutContainerWidth, doughnutDefaultWidth, doughnutWidthOffset, noContainer])
    const doughnutMinHeight = React.useMemo(() => (doughnutWidth * doughnutRadiusToWidthFactor * 2) + 45, [doughnutRadiusToWidthFactor, doughnutWidth])
    const doughnutHeight = React.useMemo(() => doughnutWidth + doughnutWidthOffset > doughnutMinHeight ? doughnutWidth + doughnutWidthOffset : doughnutMinHeight, [doughnutMinHeight, doughnutWidth, doughnutWidthOffset])
    const percFormatter = (value: any, context: any) => {
        const sum = context.dataset.data.reduce((a: number, b: any) => a + b, 0)
        const result = (value / sum * 100)
        const round = Math.round(result) - result > 0.5 ? Math.ceil : Math.round;
        return `${value.toLocaleString()} (${round(result)}%)`
    }
    const mapAssets = (asset: TProductAsset) => <div className="flex flex-col h-[100%] w-[100%] items-center mt-6" style={{position:'absolute',top:0}}>
        <p className="w-[100%] font-bold text-xl text-center mt-2">{asset.AssetBag.DatasetLabel}</p>
        <div key={`doughnut-${asset.Id.toString()}`}
            style={{
                width: doughnutWidth / assets.length,
                height: doughnutHeight / assets.length
            }}>
            <Doughnut
                options={{
                    responsive:true,
                    cutout: '55%',
                    radius: (doughnutWidth * doughnutRadiusToWidthFactor) / assets.length,
                    plugins: {
                        legend: {
                            // display: true,
                            position:'bottom',
                            align:'center',
                            title: {
                                // color:'white'
                            },
                            labels: {
                                color: 'white',
                                font: {
                                    size: 12,
                                    weight: 'bold'
                                },
                                
                            }
                        },
                        datalabels: {
                            color: 'white',
                            align: 'center',
                            offset: 3,
                            anchor: 'end',
                            padding: {
                                top:2,bottom:2,right:8,left:8
                            },
                            labels: {
                                title: {
                                    backgroundColor: 'rgba(100,116,139,0.7)',
                                    borderColor: 'white',
                                    borderWidth: 1,
                                    borderRadius: 4,
                                    font: {
                                        size: assets.length === 1 ? 18 : 16,
                                        // weight: 'bold'
                                    }
                                }
                            },
                            formatter(value, context: Context) {
                                return percFormatter(value, context)
                            },
                        },
                        tooltip: {
                            callbacks: {
                                title: function (props: TooltipItem<"doughnut">[]) {
                                    return `${props[0].label}`
                                },
                                label: (props: TooltipItem<"doughnut">) => { return `${percFormatter(props.parsed, props)}` }
                            }
                        }
                    }
                }}
                width={doughnutWidth / assets.length}
                height={doughnutHeight / assets.length}
                // width={200}
                // height={200}

                data={data(asset)} />
        </div>
    </div>
    return noContainer
        ? <>{assets.map(mapAssets)}</>
        : (
            <div
                className="border-2 border-slate-500 rounded-lg shadow-md"
                style={{
                    height: doughnutHeight,
                    minHeight: doughnutMinHeight,
                    width: doughnutWidth + doughnutWidthOffset,
                    alignItems: 'center',
                    justifyContent: 'center',
                    display: 'flex',
                    // borderRadius: 10,
                    padding: 5,
                    // border: '1px solid black',
                    // backgroundColor: 'rgb(100,116,139)',
                    color: 'black',
                    margin: 10
                }}>
                {assets.map(mapAssets)}
            </div>
        );
}
export { DoughnutKpi }