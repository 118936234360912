import { IconButton, Typography } from "@mui/material";
import React from "react";
import { HeaderFilterCell } from "./HeaderFilterCell/HeaderFilterCell";
import { HeaderProps } from "./types";
import { useIsMobile } from "../../utility/useIsMobile";
import { AiFillFilter, AiOutlineFilter } from "react-icons/ai";
import { ResizeIconButton } from "./ResizeIconButton/ResizeIconButton";
import { headerMaxWidth, headerWidth } from "../ODataGrid/hooks/useODataGrid";
import { useHeaderCell } from "./hooks/useHeaderCell";
import { HeaderFilterPopup } from "./HeaderFilterPopup/HeaderFilterPopup";
export const HeaderCell: React.FC<HeaderProps> = (props) => {
  // const handleRemoveSort = (e: any) => {
  //   e.preventDefault();
  //   if (isLoading) return;
  //   dispatch({
  //     type: "SET_ASSIGNMENT_SORTINGS",
  //     payload: assignmentSortings.filter((s) => s.key !== header.id),
  //   });
  // };
  const {
    setHeaderIsVisible,
    startResize,
    header,
    hiddenHeadersWidth,
    resizedHeadersWidth,
    isLast,
    showFilter,
    isLoading,
    enumStoreKey,
  } = props;
  const headerCellProps = useHeaderCell(props)


  const { isMobile } = useIsMobile();
  const [openFilterPopup, setOpenFilterPopup] = React.useState(false);
  if (isMobile) {
    return null;
  }
  return (<>
    <div
      className="flex flex-col"
      style={{
        maxWidth: headerMaxWidth(header, hiddenHeadersWidth, resizedHeadersWidth, isLast),
        minWidth: headerWidth(header, hiddenHeadersWidth, resizedHeadersWidth, !isLast,isLast),
        flexGrow: isLast ? 1 : undefined,
        width: headerWidth(header, hiddenHeadersWidth, resizedHeadersWidth, !isLast,isLast),
        margin: "1px 0px",
        border: "0px 1px",
      }}
      id={header.id.toString()}
    >
      {showFilter && (
        <HeaderFilterCell
          header={header}
          enumStoreKey={enumStoreKey}
          // localFilter={localFilter}
          // setLocalFilter={setLocalFilter}
          {...headerCellProps}
        />
      )}
      <div
        onContextMenu={(e) => headerCellProps.handleRemoveSort(e, header)}
        onClick={(e) => {
          if (isLoading) return;
          header.onSort(e, header.id, headerCellProps.headerCurrentSort?.direction === "asc" ? "desc" : "asc");
        }}
        className={`flex relative justify-between items-center 
        ${isLoading ? "bg-intesa-green !text-gray-400" : "bg-intesa-green !text-white"} 
        text-white border-x border-t border-gray-200 border-solid h-[50px] ${header.clickable && !isLoading ? "cursor-pointer" : "cursor-default"
          }`}
      >
        <Typography
          variant="body1"
          align="center"
          className="!w-[100%] !text-xl !my-[5px] !h-[30px] !text-base"
        >
          {header.label}
        </Typography>
        {!header.hideFilterIcon && <IconButton
          onContextMenu={(e) => {
            console.log("HERE")
            e.preventDefault();
            headerCellProps.removeHeaderFilter(header)
          }}
          onClick={(e) => { e.stopPropagation(); setOpenFilterPopup(true); }}
        >
          {headerCellProps.headerCurrentFilter ? <AiFillFilter color="black" /> : <AiOutlineFilter color="white" />}

        </IconButton>}
        {headerCellProps.sortingArrow}
        <ResizeIconButton
          startResize={startResize}
          header={header}
        />
      </div>
    </div>
    <HeaderFilterPopup
      enumStoreKey={enumStoreKey}
      header={header}
      open={openFilterPopup}
      onClose={() => setOpenFilterPopup(false)}
      {...headerCellProps} />
  </>
  );
};
