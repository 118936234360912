import { FontIcon } from '@fluentui/react';
import React from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { useQuery } from '../../hooks/useQuery';
import './styles.css'
import { product_urls } from '../../types/userStore';
const LinksNavigator: React.FC = () => {
    const location = useLocation();
    const navigate = useNavigate();
    const query = useQuery();
    const urls = React.useMemo(() => {
        const links = ["Home", query.get('area') ?? '']
        const v = location.pathname.split('/')
        v.slice(0,v.length - 1).forEach((s) => links.push(s));
        return links.filter(s => s !== '');
    }, [location])
    const handleClick = (s: string, i: number) => {
        if (s === query.get('reportname')) {
            return;
        }
        if (s === 'Home') {
            navigate('/'); 
        } else {
            if (Object.entries(product_urls).map((x) => '/' + x).includes(s)) {
                navigate('/' + urls.slice(1, i + 1).join('/') + '?page=0')
            } else {
                navigate('/' + urls.slice(1, i + 1).join('/'))
            }
        }
    }
    return <div className="mx-1 flex items-center text-white">
        {urls.map((u, i) =>
        (<div key={u} className='flex items-center'>
            <p onClick={() => handleClick(u, i)} className='truncate nav-text mx-2 cursor-pointer hover:!underline' >
                {u === 'report' ? query.get('name') : u}
            </p>{i < urls.length - 1 && <FontIcon className="mt-1 nav-icon" iconName='CaretRight8' />}
        </div>))}
    </div>
}
export { LinksNavigator }