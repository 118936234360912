import React from "react";
import {useDashboardsStore} from "../../store/hooks/useDashboardsStore";
import {useUserContext} from "../../store/hooks/useUserContext";
import {MiniLoadingSpinner} from "../../components/spinner/MiniLoadingSpinner/MiniLoadingSpinner";
import {
  Alert,
  AlertTitle,
  Button,
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  SelectChangeEvent,
} from "@mui/material";
import {known_products} from "../../types/userStore";
import {
  TAssetTypes,
  TProductAsset,
  updateCardKPI,
  updateFileTreeView,
  updateHistogram,
  updateProductAsset,
  updateRangeKPI,
  updateReport,
} from "../../services/products";
import {
  DefaultButton,
  FontIcon,
  IconButton,
  PrimaryButton,
} from "@fluentui/react";
import {useNavigate} from "react-router";
import {ReactComponent as ScorecardSVG} from "../../images/dashboard_icons/scorecards.svg";
import "./styles.css";
import {SlTarget, SlSpeedometer} from "react-icons/sl";
import {RiPlayFill} from "react-icons/ri";
import {GiHistogram} from "react-icons/gi";
import {AiOutlineTable} from "react-icons/ai";
import {BiDoughnutChart} from "react-icons/bi";
import {IntesaBottomLogo} from "../../components/IntesaBottomLogo/IntesaBottomLogo";
import useWindowSize from "../../utility/useWindowSize";
import {EditAssetForm, assetTypeMap} from "./EditAssetForm/EditAssetForm";
import {StyledTextField} from "./TextFields/StyledTextField";
import {BsFillClipboardDataFill} from "react-icons/bs";
import {DiOnedrive} from "react-icons/di";
import { IoNewspaperSharp } from "react-icons/io5";
type Props = {};
export const areaName = (a: TProductAsset) => {
  const area = Object.entries(known_products).find((x) => {
    return x[1].id === a.ProductId;
  });
  if (area) {
    return area[0];
  }
};
export const assetIcon = (a: TProductAsset, styles?: React.CSSProperties) => {
  const iconStyles = styles ?? {width: 25, height: 25};
  switch (a.AssetType.toString()) {
    case TAssetTypes.Newsletter.toString():
      return <div
      style={{...iconStyles, objectFit: "fill"}}
      className="relative p-0 m-0"
    >
      <IoNewspaperSharp  style={iconStyles} />
    </div>
    case TAssetTypes.Report.toString():
      return (
        <div
          style={{...iconStyles, objectFit: "fill"}}
          className="relative p-0 m-0"
        >
          <BsFillClipboardDataFill style={iconStyles} />
        </div>
      );
    case TAssetTypes.SpecialReport.toString():
      return (
        <div
          style={{...iconStyles, objectFit: "fill"}}
          className="relative flex p-0 m-0"
        >
          <BsFillClipboardDataFill style={iconStyles} />
        </div>
      );
    case TAssetTypes.CardKPI.toString():
      return <AiOutlineTable style={iconStyles} />;
    case TAssetTypes.Histogram.toString():
      return <GiHistogram style={iconStyles} />;
    case TAssetTypes.FileTreeView.toString():
      return <DiOnedrive style={iconStyles} />;
    case TAssetTypes.ScoreCard.toString():
      return <ScorecardSVG style={iconStyles} />;
    case TAssetTypes.Tutorials.toString():
      return <RiPlayFill style={iconStyles} />;
    case TAssetTypes.Target.toString():
      return <SlTarget style={iconStyles} />;
    case TAssetTypes.RangeKPI.toString():
      return <SlSpeedometer style={iconStyles} />;
    case TAssetTypes.Doughnut.toString():
      return <BiDoughnutChart style={iconStyles} />;
    default:
      return null;
  }
};
const EditAssetsPage: React.FC<Props> = () => {
  const {width} = useWindowSize();
  const {loadProductsAndAssets, contextState} = useDashboardsStore();
  const {products, groupedProductAssets} = contextState;
  const navigate = useNavigate();
  const {accessToken} = useUserContext();
  React.useEffect(() => {
    return () => {
      loadProductsAndAssets();
    };
  }, []);
  const [isLoading, setIsLoading] = React.useState(false);
  React.useEffect(() => {
    if (accessToken && products.length === 0) {
      setIsLoading(true);
      loadProductsAndAssets().finally(() => setIsLoading(false));
    }
  }, [accessToken, loadProductsAndAssets, products]);
  const [search, setSearch] = React.useState("");
  const defaultArea = known_products["iot-connected-buildings"].id;
  const [searchArea, setSearchArea] = React.useState(defaultArea);
  const filterAsset = (a: TProductAsset) => {
    const _searchArea = searchArea.toLowerCase();
    const _search = search.toLowerCase();
    const area_name = areaName(a) ?? "";

    return _search
      ? (a.ProductId === _searchArea &&
          (a.Name.toLowerCase().includes(_search) ||
            area_name.toLowerCase().includes(_search) ||
            a.AssetType.toString().toLowerCase().includes(_search))) ||
          assetTypeMap[a.AssetType]?.toLowerCase().includes(_search)
      : a.ProductId === _searchArea;
  };
  const [selectedAsset, setSelectedAsset] =
    React.useState<TProductAsset | null>(null);
  const selectedClassNames = React.useMemo(
    () =>
      "relative cursor-pointer bg-slate-700 border-2 border-white text-white fill-white rounded-md p-2",
    [selectedAsset]
  );
  const unselectedClassNames = React.useMemo(
    () =>
      "relative cursor-pointer hover:bg-slate-700 hover:text-white hover:fill-white text-black fill-black bg-slate-400 border-2 border-white rounded-md p-2",
    [selectedAsset]
  );
  const [successMessageAsset, setSuccessMessageAsset] = React.useState<{
    asset: TProductAsset;
    msg: string;
  } | null>(null);
  const [errorMessageAsset, setErrorMessageAsset] = React.useState<{
    asset: TProductAsset;
    msg: string;
  } | null>(null);
  const handleSave = async (e: React.MouseEvent<HTMLButtonElement>) => {
    e.stopPropagation();
    if (selectedAsset) {
      setIsLoading(true);
      const handleError = (err: any) => {
        setErrorMessageAsset({asset: selectedAsset, msg: err.toString()});
      };
      const onSuccess = () => loadProductsAndAssets();
      const onAfterSave = () => {
        setSuccessMessageAsset({asset: selectedAsset, msg: ""});
        setSelectedAsset(null);
        setIsLoading(false);
        setTimeout(() => {
          setSuccessMessageAsset(null);
        }, 5000);
      };
      const callUpdate = async (
        upd: (x: TProductAsset) => Promise<void>,
        asset: TProductAsset
      ) => upd(asset).then(onSuccess).catch(handleError).finally(onAfterSave);
      switch (selectedAsset.AssetType) {
        case TAssetTypes.Report:
          return callUpdate(updateReport, selectedAsset);
        case TAssetTypes.SpecialReport:
          return callUpdate(updateReport, selectedAsset);
        case TAssetTypes.ScoreCard:
          return callUpdate(updateReport, selectedAsset);
        case TAssetTypes.FileTreeView:
          return callUpdate(updateFileTreeView, selectedAsset);
        case TAssetTypes.RangeKPI:
          return callUpdate(updateRangeKPI, selectedAsset);
        case TAssetTypes.CardKPI:
          return callUpdate(updateCardKPI, selectedAsset);
        case TAssetTypes.Histogram:
          return callUpdate(updateHistogram, selectedAsset);
        default:
          return callUpdate(updateProductAsset, selectedAsset);
      }
    }
  };
  const [viewMode, setViewMode] = React.useState<"List" | "Grouped">("Grouped");
  const editableAssets = React.useMemo(
    () => [
      ...(groupedProductAssets[TAssetTypes.CardKPI] ?? []),
      ...(groupedProductAssets[TAssetTypes.Histogram] ?? []),
      ...(groupedProductAssets[TAssetTypes.RangeKPI] ?? []),
      ...(groupedProductAssets[TAssetTypes.Target] ?? []),
      ...(groupedProductAssets[TAssetTypes.Report] ?? []),
      ...(groupedProductAssets[TAssetTypes.Service] ?? []),
      ...(groupedProductAssets[TAssetTypes.SpecialReport] ?? []),
      ...(groupedProductAssets[TAssetTypes.ScoreCard] ?? []),
      ...(groupedProductAssets[TAssetTypes.Tutorials] ?? []),
      ...(groupedProductAssets[TAssetTypes.Doughnut] ?? []),
      ...(groupedProductAssets[TAssetTypes.Newsletter] ?? []),
      ...(groupedProductAssets[TAssetTypes.FileTreeView] ?? []),
    ],
    [groupedProductAssets]
  );
  const sortedAssets = React.useMemo(
    () =>
      editableAssets
        .filter(filterAsset)
        .sort((a, b) => (a.AssetType > b.AssetType ? 1 : -1)),
    [editableAssets, filterAsset]
  );
  const toolbarHeight = 60;
  const bottombarHeight = 60;
  const handleAssetClick = React.useCallback((a: TProductAsset) => {
    setSelectedAsset(a);
    window.scrollTo(0, 0);
  }, []);
  const showAssets = React.useMemo(() => {
    console.log(width, selectedAsset);
    if (!width) return true;
    if (width > 1300) return true;
    if (!selectedAsset) return true;
    return false;
  }, [width, selectedAsset]);
  const showForm = React.useMemo(() => {
    if (!width) return true;
    if (width > 1300) return true;
    if (selectedAsset) return true;
    return false;
  }, [width, selectedAsset]);
  return (
    <>
      <div
        className="flex w-[100%] bg-slate-800 items-center text-white border-b border-white !z-[99999]"
        style={{position: "fixed", height: toolbarHeight}}
      >
        <IconButton
          style={{
            border: "1px solid rgb(148,163,184)",
            position: "absolute",
            left: 20,
            width: 40,
            height: toolbarHeight - 10,
            top: 5,
            objectFit: "contain",
          }}
          className="rounded hover:bg-slate-600 p-6 bg-slate-700"
          onClick={() => navigate("/")}
        >
          <FontIcon
            iconName="Home"
            style={{color: "white", fontSize: 28}}
            className=""
          />
        </IconButton>
        <p
          className="bg-slate-700 text-center rounded py-1 text-3xl w-[100%] font-bold"
          style={{
            position: "absolute",
            height: toolbarHeight - 10,
            top: 5,
            left: "calc(50% - 125px)",
            width: 250,
            border: "1px solid rgb(148,163,184)",
          }}
        >
          Modifica Assets
        </p>
        {isLoading && (
          <MiniLoadingSpinner
            containerClassNames="flex justify-end mr-8 w-[100%] mt-2"
            style={{color: "white"}}
          />
        )}
        {successMessageAsset && (
          <Alert
            onClose={() => {
              setSuccessMessageAsset(null);
            }}
            style={{zIndex: 1000000, position: "fixed", right: 10, top: 10}}
            severity="success"
          >
            <AlertTitle>Salvataggio preso in carica.</AlertTitle>
            <p className="text-xl">
              {assetIcon(successMessageAsset.asset, {
                position: "absolute",
                right: 10,
                bottom: 10,
                color: "black",
                height: 20,
                width: 20,
              })}
            </p>
            <p className="text-xl">Nome: {successMessageAsset.asset.Name}</p>
            <p className="text-xl">
              Prodotto: {areaName(successMessageAsset.asset)}
            </p>
            {successMessageAsset.msg && (
              <p className="text-xl">{successMessageAsset.msg}</p>
            )}
          </Alert>
        )}
        {errorMessageAsset && (
          <Alert
            onClose={() => {
              setErrorMessageAsset(null);
            }}
            style={{zIndex: 10000000, position: "fixed", right: 10, top: 10}}
            severity="error"
          >
            <AlertTitle>Errore!</AlertTitle>
            <p className="text-xl">
              {assetIcon(errorMessageAsset.asset, {
                position: "absolute",
                left: 10,
                bottom: 10,
                color: "black",
                height: 20,
                width: 20,
              })}
            </p>
            <p className="text-xl">Nome: {errorMessageAsset.asset.Name}</p>
            <p className="text-xl">
              Prodotto: {areaName(errorMessageAsset.asset)}
            </p>
            {errorMessageAsset.msg && (
              <p className="text-xl">{errorMessageAsset.msg}</p>
            )}
          </Alert>
        )}
      </div>
      <div
        className="w-[100%] bg-slate-800 text-white"
        style={{
          height: `calc(100vh - ${bottombarHeight}px)`,
          padding: `${toolbarHeight}px 0px`,
        }}
      >
        <div
          className="flex w-[100%] p-2"
          style={{
            height: `calc(100vh - ${bottombarHeight * 2}px)`,
            backgroundColor: "rgb(70,96,129)",
          }}
        >
          {showAssets && (
            <div className="page-section px-2 overflow-auto pb-10 !h-[100%] pt-2">
              <div style={{display: "flex", marginBottom: 20}}>
                <Button
                  className="!text-white !ml-2"
                  variant={viewMode === "Grouped" ? "contained" : "outlined"}
                  onClick={() => setViewMode("Grouped")}
                >
                  Raggruppati
                </Button>
                <Button
                  className="!text-white !ml-2"
                  variant={viewMode === "List" ? "contained" : "outlined"}
                  onClick={() => setViewMode("List")}
                >
                  Lista
                </Button>
              </div>
              <FormControl fullWidth className="">
                <InputLabel className="!text-white" id="product-select-label">
                  Prodotto
                </InputLabel>
                <Select
                  variant="outlined"
                  className="!my-2 !text-white bg-slate-700"
                  fullWidth
                  label="Prodotto"
                  labelId="product-select-label"
                  id="product-select"
                  value={searchArea}
                  onChange={(e: SelectChangeEvent) =>
                    setSearchArea(e.target.value as string)
                  }
                >
                  {Object.entries(known_products).map((at) => (
                    <MenuItem value={at[1].id}>{at[0]}</MenuItem>
                  ))}
                </Select>
              </FormControl>
              <div className="mb-4" />
              <StyledTextField
                asset={null}
                setValue={(value) => setSearch(value)}
                onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                  setSearch(e.target.value)
                }
                value={search}
                placeholder="Cerca..."
                label="Cerca"
                inputId="asset-search-id"
              />
              <div className="mb-4" />
              {viewMode === "List" ? (
                <Grid container spacing={2}>
                  {sortedAssets.map((a) => (
                    <Grid item xs={12} md={6}>
                      <div
                        onClick={() => handleAssetClick(a)}
                        className={
                          a.Id !== selectedAsset?.Id
                            ? unselectedClassNames
                            : selectedClassNames
                        }
                      >
                        <div className="flex items-center w-[100%]">
                          <p className="text-left text-xl font-bold  text-left mr-auto">
                            {a.Name}
                          </p>
                          {assetIcon(a)}
                        </div>
                      </div>
                    </Grid>
                  ))}
                </Grid>
              ) : (
                <Grid container spacing={2}>
                  {sortedAssets.map((a, i) => (
                    <>
                      {(i === 0 ||
                        sortedAssets[i - 1].AssetType !== a.AssetType) && (
                        <p className="mx-4 px-1 w-[100%] text-white text-2xl mt-4 border-b !font-thin">
                          {assetTypeMap[a.AssetType] ?? a.AssetType}
                        </p>
                      )}
                      <Grid item xs={12} md={6}>
                        <div
                          onClick={() => setSelectedAsset(a)}
                          className={
                            a.Id !== selectedAsset?.Id
                              ? unselectedClassNames
                              : selectedClassNames
                          }
                        >
                          <div className="flex items-center w-[100%]">
                            <p className="text-left text-xl font-bold  text-left mr-auto">
                              {a.Name}
                            </p>
                            {assetIcon(a)}
                          </div>
                        </div>
                      </Grid>
                    </>
                  ))}
                </Grid>
              )}
            </div>
          )}
          {showForm && (
            <div
              id="edit-assets-form-div"
              className="page-section px-2 mx-2 overflow-auto !h-[100%] pb-4 pt-2 flex flex-col items-center"
            >
              {selectedAsset && (
                <EditAssetForm
                  asset={selectedAsset}
                  setAsset={setSelectedAsset}
                />
              )}
            </div>
          )}
        </div>
      </div>
      <div
        className="flex w-[100%] bg-intesa-green items-center text-white border-t border-white"
        style={{position: "fixed", bottom: 0, height: bottombarHeight}}
      >
        <IntesaBottomLogo customLeft={"0px"} sidebarWidth={0} />

        {selectedAsset && (
          <div className="ml-auto">
            <DefaultButton
              className="rounded !bg-slate-400"
              onClick={() => setSelectedAsset(null)}
              text="ANNULLA"
            />
            <PrimaryButton
              className="mx-4 rounded !bg-slate-800 !text-white"
              onClick={handleSave}
              text="SALVA"
            />
          </div>
        )}
      </div>
    </>
  );
};
export {EditAssetsPage};
