import { FontIcon, IconButton } from "@fluentui/react";
import React from "react";
import { TAlert, TMetric } from "../../types/alerts";
import { TProductAsset } from "../../services/products";
import "./styles.css";
import { useUserContext } from "../../store/hooks/useUserContext";
import { FlexCol } from "../Flex/FlexCol";
import { FlexRow } from "../Flex/FlexRow";
import { AlertDetailPanel } from "./AlertDetailPanel/AlertDetailPanel";
import { hasPermission } from "../../store/hooks/useUserStore";
import { TAreas, known_actions } from "../../types/userStore";
import { formatDate } from "../../utility/formatDate";
import {  Badge, BadgeProps, styled } from "@mui/material";
import { AlertActionPopup } from "./AlertActionPopup/AlertActionPopup";
import { useNavigationContext } from "../../store/hooks/useNavigationContext";
import useWindowSize from "../../utility/useWindowSize";
import { BiPlus } from "react-icons/bi";
import { formatNumericValue } from "../../utility/formats";
import { TooltipContainer } from "../TooltipContainer/TooltipContainer";
import { HelpIconBtn } from "../HelpIconBtn/HelpIconBtn";
import { HelpIcon } from "../HelpIcon/HelpIcon";
import { TimedAlert } from "../TimedAlert/TimedAlert";
import { intesa_green } from "../../themes/themes";
export type TAlertRowState = {
  isOpen: boolean;
  popupOpen: "mail" | "task" | "detail" | null;
  containerOpen: boolean;
  isPopupLoading: boolean;
};
export type TAlertRowActionState = {
  success: boolean;
  error: boolean;
  msg: string;
};
type Props = {
  lastOpen: number;
  setLastOpen: (n: number) => void;
  metric: TMetric;
  level: number;
  alert: TAlert;
  productId: string;
  scorecard?: TProductAsset;
  isLast?: boolean;
};
const MAIL_NOTIFICATION_TYPE = "Mail";
const TASK_NOTIFICATION_TYPE = "Task";

const AlertRow: React.FC<Props> = ({
  metric,
  level,
  alert,
  productId,
  scorecard,
  isLast,
  lastOpen,
  setLastOpen,
}) => {
  const [state, setState] = React.useState<TAlertRowState>({
    isOpen: false,
    popupOpen: null,
    containerOpen: false,
    isPopupLoading: false,
  });
  const [openHelp, setOpenHelp] = React.useState(false);
  const { sidebar, setSidebarOptions } = useNavigationContext();
  const { topbarHeight } = useWindowSize();
  const [alertState, setAlertState] = React.useState<TAlertRowActionState>({
    success: false,
    error: false,
    msg: "",
  });
  const handleToggleSidebar = React.useCallback(
    (type: "mail" | "task" | "detail" | null) => {
      setSidebarOptions({
        isOpen: !sidebar.isOpen,
        isModal: false,
        isClosable: false,
        width: "xl",
        position: "right",
        transitionMovementType: "horizontal",
        transitionResizeType: "width",
        childrens: (
          <AlertActionPopup
            type={type}
            metricName={metric.Name}
            state={state}
            setState={setState}
            alert={alert}
            alertState={alertState}
            setAlertState={setAlertState}
          />
        ),
        offset: {
          x: 0,
          y: topbarHeight ?? 0,
        },
      });
    },
    [
      alert,
      alertState,
      metric.Name,
      setSidebarOptions,
      sidebar.isOpen,
      state,
      topbarHeight,
    ]
  );
  const { userInfo, userClaims } = useUserContext();
  const hasAlertActionsPermissions = React.useMemo(() => {
    if (userInfo && userInfo.customClaims && userInfo.customClaims.length > 0) {
      return hasPermission(
        userInfo.customClaims,
        known_actions.alert_actions,
        productId as any
      );
    }
  }, [productId, userInfo]);

  const handleActionClick = React.useCallback(
    (type: "mail" | "task" | "detail" | null) => {
      // setState({
      //     ...state,
      //     isOpen: false,
      //     popupOpen: type,
      // })
      handleToggleSidebar(type);
    },
    [handleToggleSidebar]
  );

  const haveNotifications = React.useMemo(
    () => alert.Notifications && alert.Notifications.length > 0,
    [alert]
  );
  const haveMailNotifications = React.useMemo(
    () =>
      alert.Notifications &&
      alert.Notifications.filter(
        (a) => a.NotificationType === MAIL_NOTIFICATION_TYPE
      ).length > 0,
    [alert]
  );
  const haveTasksNotifications = React.useMemo(
    () =>
      alert.Notifications &&
      alert.Notifications.filter(
        (a) => a.NotificationType === TASK_NOTIFICATION_TYPE
      ).length > 0,
    [alert]
  );
  console.log(userClaims,hasPermission(
    userClaims,
    known_actions.view_attachments,
    'sbh' as TAreas
  ))
  const haveAttachments = React.useMemo(
    () =>
      hasPermission(
        userClaims,
        known_actions.view_attachments,
        'sbh' as TAreas
      ) && metric.TotalAttachments > 0,
    [metric.TotalAttachments, userClaims]
  );
  const StyledBadge = styled(Badge)<BadgeProps>(({ theme }) => ({
    "& .MuiBadge-badge": {
      backgroundColor: intesa_green,
      top: 10,
      fontSize:'0.65rem',
      right: 10,
      height:17,
      width:17,
      minWidth:'unset',
      border: `1px solid lightgrey`,
      color: "white",
    },
  }));
  const formattedValues = React.useMemo(() => {
    const moltiplicators: { [key: string]: number } = { "%": 100 };
    return {
      targetValue: alert.TargetValue
        ? `${formatNumericValue(
            alert.TargetValue * (moltiplicators[metric.UoM] ?? 1)
          )} ${metric.UoM}`
        : "-",
      value: alert.Value
        ? `${formatNumericValue(
            alert.Value * (moltiplicators[metric.UoM] ?? 1)
          )} ${metric.UoM}`
        : "-",
    };
  }, [metric, alert]);

  return (
    <>
      <FlexCol
        className="grow mt-1 text-black"
        style={{
          width: `calc(100% - ${35 * level}px)`,
          margin: `2px 0px 0px ${35 * level}px`,
          overflow: "hidden",
          zIndex: 0,
        }}
        alignItems="stretch"
        justifyContent="stretch"
      >
        <div
          onClick={() => {
            if (haveNotifications) {
              setState({ ...state, isOpen: !state.isOpen });
            }
          }}
          className={`relative flex items-center justify-between alert-container rounded w-[100%] px-2 py-1 grow ${
            haveNotifications ? "cursor-pointer" : ""
          } ${isLast ? "mb-8" : ""}`}
          style={{ backgroundColor: "snow", border: "1px solid white" }}
        >
          <div className="metric-name-section items-center">
            {/* <div className="flex-col flex h-[100%] items-center px-2 justify-end"> */}
            {/* <FontIcon
              iconName={alert.IsActive ? "Warning12" : "Accept"}
              className={`text-xxxl ${
                alert.IsActive
                  ? "text-amber-900"
                  : "text-intesa-green font-bold"
              }`}
            /> */}
            <TooltipContainer
              tooltip={{
                text: metric.HelpDescription,
                id: metric.Id.toString(),
              }}
              containerStyle={{
                padding: 0,
                margin: 0,
                marginTop: "auto",
                borderRadius: 10,
              }}
              customContentStyles={{
                content: { fontSize: 22 },
                root: {
                  padding: 8,
                  margin: 0,
                  borderRadius: 10,
                  boxShadow: "3px 3px rgba(0,0,0,0.3)",
                },
              }}
            >
              <HelpIconBtn
                style={{ height: 20, width: 20,marginRight:2,marginTop:2}}
                onClick={(e: any) => setOpenHelp(!openHelp)}
              >
                <HelpIcon style={{height:20,width:20}}/>
              </HelpIconBtn>
            </TooltipContainer>
            {/* </div> */}
            <p className="text-xl font-bold">{metric.Name}</p>
            {haveNotifications && <div className="ml-6 mt-1" />}
            <div className="notifications-section">
            {haveMailNotifications && (
              <TooltipContainer
                tooltip={{
                  text: "Notifiche Mail",
                  id: `MAIL_NOTIFICATIONS_BADGE_${alert.Id}`,
                }}
                customContentStyles={{ content: { fontSize: 20 } }}
              >
                <StyledBadge
                  badgeContent={
                    alert.Notifications.filter(
                      (a) => a.NotificationType === MAIL_NOTIFICATION_TYPE
                    ).length
                  }
                  // color="primary"
                >
                  <FontIcon
                    className="text-xxl w-[20%] mx-2"
                    iconName="Mail"
                    style={{ color: intesa_green }}
                  />
                </StyledBadge>
              </TooltipContainer>
            )}
            {haveTasksNotifications && (
              <TooltipContainer
                tooltip={{
                  text: "Notifiche Task",
                  id: `TASK_NOTIFICATIONS_BADGE_${alert.Id}`,
                }}
                customContentStyles={{ content: { fontSize: 20 } }}
              >
                <StyledBadge
                  badgeContent={
                    alert.Notifications.filter(
                      (a) => a.NotificationType === TASK_NOTIFICATION_TYPE
                    ).length
                  }
                  // color="primary"
                >
                  <FontIcon
                    className="text-xxl w-[20%] mx-2"
                    iconName="TaskManager"
                    style={{ color: intesa_green }}
                  />
                </StyledBadge>
              </TooltipContainer>
            )}
            {haveAttachments && (
              <>
                <div className="ml-4" />
                <TooltipContainer
                  tooltip={{
                    text: "Attachments",
                    id: `ATTACHMENTS_BADGE_${alert.Id}`,
                  }}
                  customContentStyles={{ content: { fontSize: 20 } }}
                >
                  <IconButton
                    style={{ zIndex: 99999 }}
                    onClick={(e: any) => {
                      e.stopPropagation();
                      setState({ ...state, popupOpen: "detail" });
                    }}
                  >
                    <StyledBadge
                      badgeContent={metric.TotalAttachments}
                      // color="primary"
                    >
                      <FontIcon
                        className="text-xxl w-[20%] mx-2"
                        iconName="Comment"
                        style={{ color: intesa_green }}
                      />
                    </StyledBadge>
                  </IconButton>
                </TooltipContainer>
              </>
            )}
            </div>
            {(haveNotifications || haveAttachments) && <div className="mr-4" />}
          </div>
          <FlexRow
            alignItems="center"
            className="mx-2 actions-section"
            justifyContent="center"
          >
            {hasAlertActionsPermissions && (
              <>
                <TooltipContainer
                  customContentStyles={{ content: { fontSize: 20 } }}
                  tooltip={{
                    text: "Invio Mail per la segnalazione",
                    id: `CREATE_MAIL_ICON_BTN_${alert.Id}`,
                  }}
                >
                  <IconButton
                    className="hover:bg-transparent mx-4 !w-[40px] !h-[40px] relative"
                    onClick={(e: any) => {
                      e.stopPropagation();
                      handleActionClick("mail");
                    }}
                  >
                    <FontIcon
                      className="alert-row-icon text-intesa-green hover:bg-transparent"
                      iconName="Mail"
                    />
                    <BiPlus
                      style={{
                        position: "absolute",
                        top: 0,
                        right: 0,
                        backgroundColor: intesa_green,
                        color: "white",
                        borderRadius: "50%",
                        fontSize: 17,
                      }}
                    />
                  </IconButton>
                </TooltipContainer>
                <TooltipContainer
                  customContentStyles={{ content: { fontSize: 20 } }}
                  tooltip={{
                    text: "Crea Task per la segnalazione",
                    id: `CREATE_TASK_ICON_BTN_${alert.Id}`,
                  }}
                >
                  <IconButton
                    className="hover:bg-transparent mx-4 relative"
                    onClick={(e: any) => {
                      e.stopPropagation();
                      handleActionClick("task");
                    }}
                  >
                    <FontIcon
                      className="alert-row-icon text-intesa-green hover:bg-transparent"
                      iconName="TaskManager"
                    />
                    <BiPlus
                      style={{
                        position: "absolute",
                        top: -3,
                        right: -3,
                        backgroundColor: intesa_green,
                        color: "white",
                        borderRadius: "50%",
                        fontSize: 17,
                      }}
                    />
                  </IconButton>
                </TooltipContainer>
              </>
            )}
          </FlexRow>
          <div className="target-container-section">
            <div className="dates-section">
              <p className="alert-date h-[50%]">
                Aperta il: {formatDate(alert.OpenDate)}
              </p>
              {!alert.IsActive && (
                <p className="alert-date h-[50%]">
                  Chiusa il: {formatDate(alert.CloseDate)}
                </p>
              )}
            </div>
            <div className="target-section">
              <FlexRow
                alignItems="baseline"
                justifyContent="space-between"
                className="w-[100%] h-[50%]"
              >
                <p className="text-xl">Valore soglia:</p>
                <p className="text-xxl text-intesa-green ml-2 font-bold">
                  {formattedValues.targetValue}
                </p>
              </FlexRow>
              <FlexRow
                alignItems="baseline"
                justifyContent="space-between"
                className="w-[100%] h-[50%]"
              >
                <p className="text-xl">Valore corrente:</p>
                <p className="text-xxl ml-2 text-red-500 font-bold">
                  {formattedValues.value}
                </p>
              </FlexRow>
            </div>
            {haveNotifications && (
              <IconButton
                style={{
                  position: "absolute",
                  bottom: -5,
                  width: 20,
                  height: 20,
                  left: "calc(50% - 10px)",
                }}
                className="!bg-transparent !hover:bg-transparent"
                onClick={() => setState({ ...state, isOpen: !state.isOpen })}
              >
                <FontIcon
                  className="text-xl text-intesa-green"
                  iconName={state.isOpen ? "caretSolidUp" : "caretSolidDown"}
                />
              </IconButton>
            )}
          </div>
        </div>
        {state.isOpen && (
          <FlexCol
            className="w-[100%] grow"
            style={{ marginTop: 2 }}
            alignItems="stretch"
            justifyContent="stretch"
          >
            {alert.Notifications &&
              alert.Notifications.map((n, i) => (
                <FlexRow
                  alignItems="center"
                  className={`px-2 ml-8 grow rounded ${
                    i ===
                    (alert.Notifications && alert.Notifications.length - 1)
                      ? "mb-2"
                      : ""
                  }`}
                  style={{
                    marginTop: 2,
                    marginBottom: 2,
                    backgroundColor: "lightgrey",
                    border: "1px solid white",
                  }}
                >
                  {n.NotificationType === MAIL_NOTIFICATION_TYPE ? (
                    <FontIcon className="text-xxxl" iconName="Mail" />
                  ) : (
                    <FontIcon className="text-xxxl" iconName="TaskManager" />
                  )}
                  <p className="ml-4 text-sm border-r border-r-white pr-4">
                    {formatDate(n.NotificationTime)}
                  </p>
                  <p className="text-base ml-4 text-slate-800 font-bold">
                    {n.NotificationType === MAIL_NOTIFICATION_TYPE
                      ? `Inviata a ${n.TargetUsers}`
                      : `Creato task per ${n.TargetUsers}`}
                  </p>
                  {n.sentBy ? <><p className="ml-auto mr-2 text-base">{n.sentBy}</p><FontIcon className="text-xxxl mr-2" iconName="HandsFree" /></> : <FontIcon className="text-xxxl mr-2 ml-auto" iconName="SyncOccurence" />}
                </FlexRow>
              ))}
          </FlexCol>
        )}
        {state.popupOpen === "detail" && (
          <AlertDetailPanel
            onClose={() => setState({ ...state, popupOpen: null })}
            alert={alert}
            metric={metric}
          />
        )}
      </FlexCol>
      {(alertState.success || alertState.error) && <TimedAlert
        duration={alertState.success ? 2000 : 2500}
        isOpen={alertState.success || alertState.error}
        onClose={() => setAlertState({ success: false, error: false, msg: "" })}
        severity={alertState.success ? "success" : "error"}
        style={{
          position: "fixed",
          zIndex: 9998,
          left: "calc(50vw - 200px)",
          width: 400,
          height:100,
          top: 20,
          backgroundColor:'rgb(15 23 42)',
          border:'1px solid white',
          borderRadius:5
        }}
      >
        <p className="text-xl text-white">{alertState.msg}</p>
      </TimedAlert>}
    </>
  );
};
export { AlertRow };
