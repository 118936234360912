import { DatePicker, DateValidationError, LocalizationProvider, PickerChangeHandlerContext } from '@mui/x-date-pickers'
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs'
import React from 'react'
import { HeaderFilterProps } from '../../types'
import { HeaderFilterCellFormControl } from '../HeaderFilterCellFormControl/HeaderFilterCellFormControl'
import { useHeaderCell } from '../../hooks/useHeaderCell'

export const DateTimeHeaderFilterCell: React.FC<HeaderFilterProps> = ({enumStoreKey,header,
}) => {
    const {setHeaderFilter,headerCurrentFilter,removeHeaderFilter,isLoading} = useHeaderCell({header,enumStoreKey})

    const handleChange: (value: Date | null, context: PickerChangeHandlerContext<DateValidationError>) => void = (value, context) => {
        setHeaderFilter(header, value);
    }
    return <HeaderFilterCellFormControl
        header={header}
        hideLabel
        input={
            <LocalizationProvider dateAdapter={AdapterDayjs}>
                <DatePicker
                    slotProps={{
                        actionBar: {
                            actions: ['clear'],
                        },
                    }}
                    disabled={isLoading}
                    format="DD/MM/YYYY"
                    label={header.label}
                    value={headerCurrentFilter?.value}
                    onChange={handleChange}
                />
            </LocalizationProvider>} />
}