import { FontIcon, IconButton, mergeStyleSets, Modal } from "@fluentui/react";
import moment from "moment";
import React from "react";
import { FaWindowClose } from "react-icons/fa";
import { useDashboardsStore } from "../../store/hooks/useDashboardsStore";
import {
  TAlertColumnFilters,
  TDateRange,
  TMetric,
} from "../../types/alerts";
import { MiniLoadingSpinner } from "../spinner/MiniLoadingSpinner/MiniLoadingSpinner";
import { VerticalDashboardSection } from "../VerticalDashboardSection/VerticalDashboardSection";
import { FlexCol } from "../Flex/FlexCol";
import { ExpandableContainer } from "../ExpandableContainer/ExpandableContainer";
import { FlexRow } from "../Flex/FlexRow";
import { ScorecardAlerts } from "../Alert/ScorecardAlerts/ScorecardAlerts";
import { ReactDateRangePicker } from "../ReactDateRangePicker/ReactDateRangePicker";
import "./styles.css";
import { TextField } from "@mui/material";
import { known_products } from "../../types/userStore";
import { IGenericError } from "../../types/general";
import { useGlobalStore } from "../../store/hooks/useGlobalStore";
import { IFrontendSettingsGet } from "../../services/frontendSettings";
type Props = {
  settings?: IFrontendSettingsGet;
  productId: string;
};
const MetricsColumn: React.FC<Props> = ({ productId, settings }) => {
  const product_id_filter = React.useMemo(() => {
    return Object.values(known_products).find(
      (x) => x.dashboard_url.slice(1) === productId
    )?.id;
  }, [productId]);
  const { setGlobalError } = useGlobalStore();

  const {
    metrics: activeMetrics,
    loadActiveAlerts,
    loadDeactiveAlerts,
    deactiveMetrics,
    alertDateFilter,
    setAlertDateFilter,
    isLoaded: assetsAreLoaded,
    contextDispatch,
    contextState,
  } = useDashboardsStore();
  const [loadingState, setLoadingState] = React.useState({
    activeLoading: false,
    deactiveLoading: false,
  });
  React.useEffect(() => {
    const _loadDeactiveAlerts = async (_activeMetrics: {
      [x: string]: TMetric[];
    }) => {
      if (assetsAreLoaded) {
        loadDeactiveAlerts(product_id_filter ?? "", alertDateFilter, false)
          .then((_deactiveMetrics) =>
            contextDispatch({
              type: "SET_STATE",
              payload: {
                ...contextState,
                metrics: _activeMetrics,
                deactiveMetrics: _deactiveMetrics,
              },
            })
          )
          .catch((err: IGenericError) => setGlobalError(err))
          .finally(() =>
            setLoadingState({ activeLoading: false, deactiveLoading: false })
          );
      }
    };
    const _loadAlerts = async () => {
      if (assetsAreLoaded) {
        setLoadingState({ activeLoading: true, deactiveLoading: false });
        loadActiveAlerts(product_id_filter ?? "", false)
          .then((res) => {
            _loadDeactiveAlerts(res);
          })
          .catch((err: IGenericError) => {
            console.log(err);
            setGlobalError(err);
          });
      }
    };
    _loadAlerts();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [alertDateFilter, assetsAreLoaded]);

  const activeAlertsLength = React.useMemo(() => {
    let result = 0;
    const countLeafs = (a: TMetric) => {
      if (a.Alerts && a.Alerts.length > 0) {
        result += a.Alerts ? a.Alerts.length : 0;
      }
      if (a.Metrics) {
        a.Metrics.forEach((m) => countLeafs(m));
      }
    };
    activeMetrics[product_id_filter ?? ""]?.forEach((m) => countLeafs(m));
    return result;
  }, [product_id_filter, activeMetrics]);
  const deactiveAlertsLength = React.useMemo(() => {
    let result = 0;
    const countLeafs = (a: TMetric) => {
      if (a.Alerts && a.Alerts.length > 0) {
        result += a.Alerts ? a.Alerts.length : 0;
      }
      if (a.Metrics) {
        a.Metrics.forEach((m) => countLeafs(m));
      }
    };
    deactiveMetrics[product_id_filter ?? ""]?.forEach((m) => countLeafs(m));
    return result;
  }, [product_id_filter, deactiveMetrics]);
  const groupMetricsByScorecard = React.useCallback(
    (metrics: TMetric[]) => {
      let _: { [key: string]: TMetric[] } = {};
      if (metrics) {
        metrics.forEach((m) => {
          const key = m.Scorecard.Name;

          if (Object.keys(_).includes(key)) {
            _[key] = [..._[key], m];
          } else {
            _[key] = [m];
          }
        });
      }
      return _;
    },
    []
  );
  const [filters, setFilters] = React.useState<TAlertColumnFilters>({
    activeSearch: "",
    deactiveSearch: "",
    datePopupOpen: false,
  });
  const { activeSearch, deactiveSearch } = filters;
  const searchField = React.useCallback(
    (search: string, handleSearchChange: (e: any) => void) => (
      <TextField
        variant="outlined"
        className="!ml-2 !w-[150px] !rounded !text-xxl"
        placeholder="Cerca..."
        value={search}
        label={""}
        name="search"
        size="small"
        inputProps={{
          style: {
            backgroundColor: "rgb(51,65,85)",
            color: "white",
            borderRadius: 8,
            width: 150,
            lineHeight: 4,
          },
        }}
        onChange={handleSearchChange}
      />
    ),
    []
  );
  const contentStyles = mergeStyleSets({
    container: {
      display: "flex",
      flexFlow: "column nowrap",
    },
  });
  const dateFormat = (d?: Date) => {
    return moment(d).format("YY-MM-DD");
  };
  const handleActiveSearchChange = (e: any) => {
    setFilters({ ...filters, activeSearch: e.target.value });
  };
  const handleDeactiveSearchChange = (e: any) => {
    setFilters({ ...filters, deactiveSearch: e.target.value });
  };
  const searchFilter = React.useCallback((a: TMetric, search: string) => {
    let result = true;
    const lowerCaseSearch = search.toLowerCase();
    if (result && search !== "") {
      result =
        a.Name.toLowerCase().includes(lowerCaseSearch) ||
        a.Scorecard.Name.toLowerCase().includes(lowerCaseSearch);
    }
    if (a.Alerts) {
      for (let k = 0; k < a.Alerts.length; k++) {
        result =
          result ||
          a.Alerts[k].Notifications.some((n) =>
            n.sentBy?.toLowerCase().includes(lowerCaseSearch)
          );
      }
    }
    if (a.Metrics) {
      for (let k = 0; k < a.Metrics.length; k++) {
        result = result || searchFilter(a.Metrics[k], search);
      }
    }
    return result;
  }, []);
  const activeSearchFilter = React.useCallback(
    (a: TMetric) => searchFilter(a, activeSearch),
    [activeSearch, searchFilter]
  );
  const deactiveSearchFilter = React.useCallback(
    (a: TMetric) => searchFilter(a, deactiveSearch),
    [deactiveSearch, searchFilter]
  );
  const dateFilterString = React.useMemo(() => {
    if (alertDateFilter) {
      if (
        dateFormat(alertDateFilter.startDate) !==
        dateFormat(alertDateFilter.endDate)
      ) {
        return `Filtro data: da ${dateFormat(
          alertDateFilter?.startDate
        )} a ${dateFormat(alertDateFilter?.endDate)}`;
      } else {
        return `Filtro data: da ${dateFormat(alertDateFilter?.startDate)}`;
      }
    }
  }, [alertDateFilter]);
  const groupedActiveMetrics = React.useMemo(() => {
    const result = Object.entries(
      groupMetricsByScorecard(
        activeMetrics[product_id_filter ?? ""]?.filter(activeSearchFilter)
      )
    );
    return result;
  }, [
    product_id_filter,
    activeMetrics,
    activeSearchFilter,
    groupMetricsByScorecard,
  ]);
  const groupedDeActiveMetrics = React.useMemo(() => {
    const result = Object.entries(
      groupMetricsByScorecard(
        deactiveMetrics[product_id_filter ?? ""]?.filter(deactiveSearchFilter)
      )
    );
    return result;
  }, [
    product_id_filter,
    deactiveMetrics,
    deactiveSearchFilter,
    groupMetricsByScorecard,
  ]);
  const [alertContainerOpen, setAlertContainerOpen] = React.useState({
    active: true,
    deactive: false,
  });

  return (
    <>
      {/* <DisposableSidebar /> */}
      <VerticalDashboardSection
        cotainerStyles={{
          paddingBottom: 100,
        }}
        title=""
        settings={settings}
        id="Metrics-column"
      >
        <FlexCol justifyContent="space-between" className="h-[100%] w-[100%]">
          <>
            <div className="relative p-1 border rounded-md w-[100%] shadow-lg">
              <div className="w-[100%] flex h-[50px]">
                <p
                  className="ml-2 container-title font-bold text-amber-600 cursor-pointer"
                  style={{ position: "absolute", top: 0 }}
                  onClick={(e) =>
                    setAlertContainerOpen({
                      ...alertContainerOpen,
                      active: !alertContainerOpen.active,
                    })
                  }
                >
                  Segnalazioni attive
                </p>
                {(loadingState.activeLoading || !groupedActiveMetrics) && (
                  <MiniLoadingSpinner
                    containerClassNames="my-auto mx-6 w-[30px] h-[30px]"
                    style={{
                      position: "absolute",
                      left: "calc(50% - 15px)",
                      width: 30,
                      height: 30,
                      top: 0,
                      color: "white",
                    }}
                  />
                )}
                <p className="text-xl font-bold ml-auto mr-4 my-auto text-white border bg-amber-600 rounded text-center px-4 py-1">
                  {activeAlertsLength}
                </p>
              </div>
              <ExpandableContainer
                open={alertContainerOpen.active}
                setOpen={(x) =>
                  setAlertContainerOpen({ ...alertContainerOpen, active: x })
                }
                iconPosition="afterText"
                text=""
                textIsClickable
                textClassNames=""
                borderIfOpen
                defaultOpen
                iconButtonClassNames="mt-auto ml-2 justify-end text-white text-xl mb-1 items-end flex hover:bg-transparent hover:text-white"
                iconButtonStyles={{ width: 30, height: 20 }}
                headerBeforeChildren={
                  <FlexRow
                    className="mb-1 grow"
                    alignItems="center"
                    justifyContent="start"
                  >
                    {searchField(activeSearch, handleActiveSearchChange)}
                  </FlexRow>
                }
              >
                {groupedActiveMetrics.map(([scorecardName, metrics]) => (
                  <ScorecardAlerts
                    key={scorecardName}
                    metrics={metrics}
                    name={scorecardName}
                    productId={productId}
                  />
                ))}
              </ExpandableContainer>
            </div>
            <div className="mt-4" />
            <div className="relative p-1 border w-[100%] border-gray-300 shadow-lg rounded">
              <div className="w-[100%] flex h-[50px]">
                <p
                  className="ml-2 container-title font-bold text-gray-400 cursor-pointer"
                  style={{ position: "absolute", top: 0 }}
                  onClick={(e) =>
                    setAlertContainerOpen({
                      ...alertContainerOpen,
                      deactive: !alertContainerOpen.deactive,
                    })
                  }
                >
                  Segnalazioni "rientrate"
                </p>
                {(loadingState.deactiveLoading || !groupedActiveMetrics) && (
                  <MiniLoadingSpinner
                    containerClassNames="my-auto mx-6 w-[30px] h-[30px]"
                    style={{
                      position: "absolute",
                      left: "calc(50% - 15px)",
                      width: 30,
                      height: 30,
                      top: 0,
                      color: "white",
                    }}
                  />
                )}
                <p className="text-xl font-bold ml-auto mr-4 my-auto text-white border bg-intesa-green rounded text-center px-4 py-1">
                  {deactiveAlertsLength}
                </p>
              </div>
              <ExpandableContainer
                open={alertContainerOpen.deactive}
                setOpen={(x) =>
                  setAlertContainerOpen({ ...alertContainerOpen, deactive: x })
                }
                iconPosition="afterText"
                text=""
                textIsClickable
                textClassNames=""
                borderIfOpen
                iconButtonClassNames="mt-auto ml-2 justify-end text-gray-300 text-xl mb-1 items-end flex hover:bg-transparent hover:text-gray-300"
                iconButtonStyles={{ width: 30, height: 20 }}
                headerBeforeChildren={
                  <FlexRow
                    className="grow mb-1 w-[100%]"
                    alignItems="center"
                    justifyContent="start"
                  >
                    {searchField(deactiveSearch, handleDeactiveSearchChange)}
                    <FlexRow className="ml-2 mb-1" alignItems="center">
                      <IconButton
                        className="text-gray-300  hover:bg-transparent hover:text-gray-300 !h-[unset]"
                        onClick={() =>
                          setFilters({ ...filters, datePopupOpen: true })
                        }
                      >
                        <FontIcon
                          className="mt-1 hover:text-xxl text-xl hover:text-gray-300"
                          iconName="DateTime"
                        />
                      </IconButton>
                      {alertDateFilter && (
                        <p className="text-xl mt-auto">{dateFilterString}</p>
                      )}
                      {alertDateFilter && (
                        <IconButton
                          className="!ml-2 hover:bg-transparent !mt-auto !mb-1 p-0 !h-[unset]"
                          onClick={() => setAlertDateFilter(null)}
                        >
                          <FaWindowClose className="text-xl text-white" />
                        </IconButton>
                      )}
                    </FlexRow>
                  </FlexRow>
                }
              >
                {groupedDeActiveMetrics.map(([scorecardName, metrics]) => (
                  <ScorecardAlerts
                    key={scorecardName}
                    metrics={metrics}
                    name={scorecardName}
                    productId={productId}
                  />
                ))}
              </ExpandableContainer>
            </div>
          </>
        </FlexCol>
        <Modal
          containerClassName={contentStyles.container}
          isOpen={filters.datePopupOpen}
          isBlocking={false}
          onDismiss={() => {
            setFilters({ ...filters, datePopupOpen: false });
          }}
          titleAriaId="Modal"
        >
          <div className="flex flex-col grow m-2 relative">
            <p className="text-center text-lg font-bold">Filtro Data</p>
            <ReactDateRangePicker
              customSetFilters={(dateFilter: TDateRange) => {
                // loadDeactiveAlerts(dateFilter)
                setAlertDateFilter(dateFilter);
              }}
              startRange={null}
              onClose={() => {
                setFilters({ ...filters, datePopupOpen: false });
              }}
            />
          </div>
        </Modal>
      </VerticalDashboardSection>
    </>
  );
};
export { MetricsColumn };
