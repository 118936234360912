import React from 'react'
import { IInputEvent } from '../../../types/eventNotifications';
import { DataMonitorCard } from './InputEventsCard/DataMonitorCard';
import { ODataGridRow } from '../../ODataGrid/ODataGridRow/ODataGridRow';
import { GridRowProps } from '../../../types/odata';
export const InputEventsGridRow: React.FC<GridRowProps<IInputEvent>> = ({
    item, headers,enumStoreKey
}) => {
    return <ODataGridRow
        itemContainerCustomClassNames={(x:IInputEvent) => x.IsActive
            ? "border-gray-500"
            : "border-gray-300"}
        itemCustomClassNames={(x) => x.IsActive ? "bg-gray-100"
            : "bg-gray-100"}
        item={item}
        headers={headers}
        enumStoreKey={enumStoreKey}
        customGridCard={<DataMonitorCard headers={headers} item={item} />}
    />
}