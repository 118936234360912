import { TMetric, TScorecard } from "./alerts"
export interface IInsertOrUpdateConfigurations {
    toAdd:IConfiguration[],
    toUpdate:IConfiguration[]
}
export enum AssignmentPriorityEnum {
    Low="Low",
    Medium="Medium",
    High="High"
}
export interface TConfigurationFormData {
    Id?:number,
    Priority:AssignmentPriorityEnum,
    OwnerMail: string,
    IsEnable: boolean,
    CanBeDelegated: boolean,
    NotificationUserMail: string[],
    ClosureReason: string[],
    Tags: string[],
    IsAutomaticClose:boolean,
    IsEscalationRequired: boolean,
    EscalationReceiversMail: string,
    EscalationTimeInDays: number
}
export interface IAssignmentConfiguration extends TConfigurationFormData {
    Priority:AssignmentPriorityEnum
    Metric: TMetric,
    Name:string,
    Scorecard: TScorecard,
    CreationDate: string,
    ModifiedDate: string,
    RowVersion: string,
    ScorecardId: number,
    MetricId: number,
    ProductId: string,
}
export interface IConfiguration extends TConfigurationFormData{
    scorecardId:number
    productId:string
    metricId:number
}