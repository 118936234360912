import React from 'react'
import { Card, CardContent, Typography } from '@mui/material'
import { GiConfirmed } from 'react-icons/gi'
import { BsXCircle } from 'react-icons/bs'
import { settingsContext } from '../../../../../store/settingsStore'
import { IAssignmentConfiguration } from '../../../../../types/assignments'
import { TAutomaticNotificationSetting } from '../../../../../services/automaticNotifications'
import { TooltipContainer } from '../../../../../components/TooltipContainer/TooltipContainer'
type Props = {
    cfg: IAssignmentConfiguration
    automaticNotifications:TAutomaticNotificationSetting[]
    variant: "outlined" | "contained"
    showMetricName: boolean
}
export const AssignmentConfigurationItem: React.FC<Props> = ({ cfg, variant, showMetricName,automaticNotifications }) => {
    const { state, dispatch } = React.useContext(settingsContext);
    const handleClick = React.useCallback(() => {
        dispatch({
            type: "SET_STATE",
            payload: {
                ...state,
                currentStep: 1,
                // selectedMethod: "EDIT",
                selectedMetrics: [cfg.Metric]
            }
        })
    }, [cfg.Metric, dispatch, state])

    const variantClasses = React.useMemo(() => {
        switch (variant) {
            case "outlined": return "!bg-slate-600 !border !border-white !text-white"
            case "contained": return "!bg-slate-700 !text-white"
            default: return "!bg-slate-600 !border !border-white !text-white"
        }
    }, [variant])
    if(!automaticNotifications){
        return null;
    }
    return <Card className={`flex flex-col p-4 rounded-lg relative ${variantClasses}`} style={{ minHeight: 250 }} >
        <CardContent style={{ padding: 0 }}>
            {/* <div className={`flex flex-col items-start ${size === "large" ? "p-4" : "p-2"}`}> */}

            {showMetricName && <div className="flex items-center justify-start">
                {cfg.IsEnable ?
                    <GiConfirmed className="text-3xl text-intesa-green border-intesa-green" />
                    :
                    <BsXCircle className="text-3xl text-red-500" />
                }
                <div className="mx-1" />
                <Typography className='text-4xl'>{cfg.Name}</Typography>
            </div>}
            <div className="mt-2" />
            <Typography>Id: {cfg.MetricId}</Typography>
            {showMetricName
                ?
                <Typography className='text-xl'>Owner: {cfg.OwnerMail}</Typography>
                :
                <div className="flex w-[100%] items-center">
                    {cfg.IsEnable ?
                        <GiConfirmed className="text-3xl text-intesa-green border-intesa-green" />
                        :
                        <BsXCircle className="text-3xl text-red-500" />
                    }
                    <Typography className='text-xl ml-1'>Owner: {cfg.OwnerMail}</Typography>
                </div>
            }
            {/* <div className="pt-2 w-[5%] border-b" /> */}
            {cfg.CanBeDelegated
                ? <Typography className="w-[100%]" align='left'>Delegabile</Typography>
                : <Typography className=" w-[100%]" align='left'>Non delegabile</Typography>}
            {cfg.IsEscalationRequired
                ?
                <>
                    <div className="flex items-center w-[100%] ">
                        <Typography>Scalabile a: </Typography>
                        <Typography className='text-xl'>{cfg.EscalationReceiversMail}</Typography>
                    </div>
                    <div className="flex items-center w-[100%]">
                        <Typography className='text-xl'>Escalation dopo {cfg.EscalationTimeInDays} giorni</Typography>
                    </div>
                </>
                :
                <Typography className="w-[100%]" align='left'>Escalation non richiesta</Typography>
            }
            <Typography>Utenti Notificati:</Typography>
            <TooltipContainer
                containerStyle={{width:"90%"}}
                customContentStyles={{content:{fontSize:18}}}
                tooltip={{ id: automaticNotifications.map((n) => n.UserMail).join("\n"), text: automaticNotifications.map((n) => n.UserMail).join(" ") }}
            >
                <Typography overflow={"hidden"} textOverflow={"clip"} fontSize="0.85rem">
                    {automaticNotifications.map((n) => n.UserMail).join(";")}
                </Typography>
            </TooltipContainer>
            {/* <Typography>Utenti notificati: {automaticNotifications ? automaticNotifications.map((n)=>n.UserMail).join(';') : ''}</Typography> */}

            {/* </div> */}
        </CardContent>
    </Card>
}