import React from 'react'
import { LinearProgress, Typography, } from '@mui/material'
import { DetailsContainer } from '../DetailsContainer/DetailsContainer'
import { DetailsLabelValueContainer } from '../DetailsLabelValueContainer/DetailsLabelValueContainer'
import { DetailsLabel } from '../DetailsLabel/DetailsLabel'
import { DetailsValue } from '../DetailsValue/DetailsValue'
import { formatDate } from '../../utility/formatDate'
import { IExtendedSuperMetricValue } from '../../types/etickets'
import { getSuperMetricValue } from '../../services/supermetric'
import { globalContext } from '../../store/globalStore'
import { APIError } from '../../types/general'
import { assignments_known_urls } from '../../types/userStore'
import { Link, useLocation, useNavigate } from 'react-router-dom'
import { DetailsTitle } from '../DetailsTitle/DetailsTitle'
import { DetailNavigatorIcon } from '../DetailNavigatorIcon/DetailNavigatorIcon'
import { DetailsRow } from '../DetailsRow/DetailsRow'
import { DetailDescription } from '../DetailsRow/DetailDescription/DetailDescription'
import { DetailsFocusPaper } from '../DetailsRow/DetailsFocusPaper/DetailsFocusPaper'
import { intesa_green } from '../../../../themes/themes'
import { useUserContext } from '../../../../store/hooks/useUserContext'
import { know_urls } from '../../../../types/userStore'
type Props = {
    id: number
}
export const ExtendedSuperMetricValueDetail: React.FC<Props> = ({ id }) => {
    const { authenticatedCall } = useUserContext();
    const [isLoading, setIsLoading] = React.useState(false);
    const { dispatch: globalDispatch } = React.useContext(globalContext);
    const [superMetricValue, setSuperMetricValue] = React.useState<IExtendedSuperMetricValue>();
    React.useEffect(() => {
        const _load = async () => {
            setIsLoading(true);
            getSuperMetricValue(id)
                .then((res) => setSuperMetricValue(res))
                .catch((err: APIError) => globalDispatch({ type: "SET_ERROR", payload: { message: err.response.data.Message, details: err.toString() } }))
                .finally(() => setIsLoading(false))
        }
        authenticatedCall(_load);
    }, [authenticatedCall, globalDispatch, id])
    const reports_links = React.useMemo(() => {
        if (!superMetricValue) {
            return [];
        }
        const custom_report_link_key = "custom_link_report";
        return Object.entries(superMetricValue.Metadata).filter(([key, value]) => key.toLowerCase().includes(custom_report_link_key)).map(([key, value]) => ({
            Name: key.substring(custom_report_link_key.length + 1).replaceAll("_", " "),
            Link: value
        }))
    }, [superMetricValue])
    
    if (!superMetricValue) return null;
    if (isLoading) { return <LinearProgress style={{ color: intesa_green,marginTop:20, width: 100, height: 10 }} /> }
    return <>
        <DetailsContainer>
            <DetailsTitle text="Evento Super Metrica" extraText={`Stato: ${superMetricValue.IsActive ? "Attivo" : "Disattivo"}`} />
            <DetailNavigatorIcon url={assignments_known_urls.supermetric_event_detail} id={superMetricValue.Id} />
            <DetailsRow>
                <DetailsLabelValueContainer direction='row'>
                    <DetailsLabel label="Valore" />
                    <DetailsValue bold value={`${superMetricValue.ActivationValue} ${superMetricValue.Metadata.faultUoM}`} />
                </DetailsLabelValueContainer>
                <DetailsLabelValueContainer direction='row-reverse'>
                    <DetailsValue bold value={formatDate(superMetricValue.ActivationTime)} />
                    <DetailsLabel label="Data Calcolo" />
                </DetailsLabelValueContainer>
                {/* <Typography className='w-[100%] !text-2xl' align='right'>{`Data Calcolo: ${formatDate(superMetricValue.ActivationTime)}`}</Typography> */}
            </DetailsRow>
            <DetailsFocusPaper>
                <DetailDescription description={superMetricValue.SuperMetric.Description} />
                <div className='h-[10px]' />
                <DetailsRow>
                    <DetailsLabelValueContainer direction='row'>
                        <DetailsLabel bold label="Polo" />
                        <DetailsValue value={superMetricValue.Polo} />
                    </DetailsLabelValueContainer>
                </DetailsRow>
                <DetailsRow>
                    <DetailsLabelValueContainer direction='row'>
                        <DetailsLabel bold label="Edificio" />
                        <DetailsValue value={superMetricValue.BuildingName} />
                    </DetailsLabelValueContainer>
                </DetailsRow>
                <DetailsRow>
                    <DetailsLabelValueContainer direction='row' roundedUp>
                        <DetailsLabel bold label="ID Supermetrica" />
                        <DetailsValue value={superMetricValue.SuperMetricId} />
                    </DetailsLabelValueContainer>
                    <DetailsLabelValueContainer direction='row-reverse'>
                        <DetailsValue value={superMetricValue.Id} />
                        <DetailsLabel bold label="ID Evento" />
                    </DetailsLabelValueContainer>
                </DetailsRow>
            </DetailsFocusPaper>
            <div className='flex justify-start px-4'>
                <div>
                    {reports_links.length > 0 && <Typography align='left' fontSize="1.25rem">Report collegati:</Typography>}
                    {reports_links.map((r) => <Link target="_blank" to={r.Link}>
                        <Typography align='left' className='hover:!bg-gray-200 !px-4' fontSize="1.25rem">{r.Name}</Typography>
                    </Link>
                    )}
                </div>
            </div>
            {/* {superMetricValue.Metadata.custom_link_report_detail && <IconButton
                // style={{
                //     position:'absolute',
                //     top:5,
                //     right:5,
                // }}
                onClick={(e)=>{
                    e.stopPropagation();
                    window.open(superMetricValue.Metadata.custom_link_report_detail)
                }}
                className='!bg-white !text-black hover:!bg-white hover:!text-black'
            >
                <BiSolidReport />
            </IconButton>} */}

        </DetailsContainer >
    </>
}