import React from "react";
import "./styles.css";
import axios from "axios";
import { FontIcon, IconButton } from "@fluentui/react";
import { TPileSize } from "../../types/general";
import { TProductAsset } from "../../services/products";
import { useUserContext } from "../../store/hooks/useUserContext";
import { useDashboardsStore } from "../../store/hooks/useDashboardsStore";
import { VerticalDashboardSection } from "../VerticalDashboardSection/VerticalDashboardSection";
import { FlexRow } from "../Flex/FlexRow";
import { MiniLoadingSpinner } from "../spinner/MiniLoadingSpinner/MiniLoadingSpinner";
import { TutorialPile } from "../Pile/TutorialPile/TutorialPile";
import { useGraphAuthentication } from "../../hooks/useGraphAuthentication";
import { IFrontendSettingsGet } from "../../services/frontendSettings";
import { TooltipContainer } from "../TooltipContainer/TooltipContainer";
import { isMobile } from "react-device-detect";

const TutorialsColumn: React.FC<{
  productId: string;
  tutorials: TProductAsset[];
  settings?: IFrontendSettingsGet;
}> = ({ productId, tutorials, settings }) => {
  const { errors, setErrors, setGraphAccessToken } = useUserContext();

  const [isLoading, setIsLoading] = React.useState(false);
  const { tutorials: tutorialPiles, contextDispatch } = useDashboardsStore();

  const loadItems = (token: string) => {
    const asset = tutorials[0];
    if (!asset) {
      setIsLoading(false);
      return;
    }

    const url = `${
      process.env.REACT_APP_SERVER_URL
    }${asset.AssetBag.PartialUrl}`;
    axios
      .post(url, {GraphApiToken:token,...asset.AssetBag})
      .then((res) =>
        contextDispatch({
          type: "SET_TUTORIALS",
          payload: { [productId]: res.data },
        })
      )
      .catch((err) => {
        if (err.response.status === 401) {
          setGraphAccessToken("");
          setErrors([...errors, "Non autorizzato"]);
        }
      })
      .finally(() => setIsLoading(false));
  };
  const { getAccessToken } = useGraphAuthentication({
    setIsLoading,
    graphTokenSelector: (userInfo) => userInfo.graphAccessToken,
    loadItems,
    loadConditions: !tutorialPiles[productId],
  });
  const errors_set = React.useMemo(
    () => (errors && Array.isArray(errors) ? Array.from(new Set(errors)) : []),
    [errors]
  );
  const pileSize: TPileSize = React.useMemo(() => {
    if(isMobile){
      return "x1";
    }
    if (!settings?.SectionColumns) {
      return "x2";
    }
    if (settings.SectionColumns < 3.5) {
      return "x1";
    } else {
      if (settings.SectionColumns > 5) {
        return "x4";
      } else {
        return "x2";
      }
    }
  }, [settings?.SectionColumns]);
  return (
    <VerticalDashboardSection
      cotainerStyles={{
        paddingBottom: 100,
      }}
      title=""
      settings={settings}
      id="Tutorials"
    >
      {isLoading && errors_set.length === 0 && (
        <MiniLoadingSpinner style={{ color: "white" }} />
      )}
      {errors_set.length > 0 ? (
        <FlexRow>
          <p
            style={{ whiteSpace: "pre-wrap" }}
            className="text-white text-xl mx-4"
          >
            {isLoading
              ? "Caricamento..."
              : errors_set.map((s, i) => `${i + 1}. ${s}`).join("\n")}
          </p>
          <TooltipContainer
            tooltip={{ text: "Get Power BI Token", id: "POWER_BI_TOKEN" }}
            customContentStyles={{ content: { fontSize: 20 } }}
          >
            <IconButton
              disabled={isLoading}
              className="bg-white rounded-lg px-2 py-1 hover:bg-white text-slate-800"
              onClick={() => {
                setIsLoading(true);
                getAccessToken(true, "graph").finally(() =>
                  setIsLoading(false)
                );
              }}
            >
              <FontIcon
                iconName="StatusCircleSync"
                style={{ fontSize: "2rem" }}
              />
            </IconButton>
          </TooltipContainer>
          {isLoading && (
            <MiniLoadingSpinner
              style={{ color: "white", marginLeft: 20, fontSize: "0.75rem" }}
            />
          )}
        </FlexRow>
      ) : tutorialPiles[productId]?.length > 0 ? (
        tutorialPiles[productId]?.map((t) => (
          <TutorialPile
            productId={productId}
            key={t.Id}
            asset={t}
            pileSize={pileSize}
          />
        ))
      ) : (
        <p className="text-2xl text-white font-bold">
          {isLoading ? "" : "Nessun tutorial trovato"}
        </p>
      )}
    </VerticalDashboardSection>
  );
};
export { TutorialsColumn };
