import { useMsal } from '@azure/msal-react';
import { Facepile, FontIcon, IFacepilePersona, PersonaPresence, PersonaSize } from '@fluentui/react';
import React from 'react';
import useWindowSize from '../../../utility/useWindowSize';
import { useUserContext } from '../../../store/hooks/useUserContext';
import { FlexCol } from '../../Flex/FlexCol';
import { FlexRow } from '../../Flex/FlexRow';
const UserFacepile: React.FC<{ userImage: IFacepilePersona[], dropDownExtraStyles?: React.CSSProperties, triangleExtraStyles?: React.CSSProperties }> = ({ userImage, dropDownExtraStyles, triangleExtraStyles }) => {
    const [isOpen, setIsOpen] = React.useState(false);
    const { topbarHeight } = useWindowSize();
    const { instance } = useMsal();
    const triangleStyle = {
        right: 0,
        top: -4,
        width: 0,
        height: 0,
        borderLeft: '5px solid transparent',
        borderRight: '5px solid transparent',
        borderBottom: '5px solid rgb(235,235,235)',
        zIndex: 1002
    }
    const dropDownStyle = {
        backgroundColor: 'rgb(235,235,235)',
        right: 15,
        top: topbarHeight ? topbarHeight - 10 : 30,
        borderRadius: '4px 2px 4px 4px',
        zIndex: 1001
    }
    const handleLogout = () => {
        instance.logout();
    }
    const {
        userInfo
    } = useUserContext();
    return <div className="relative cursor-pointer" onClick={(e: any) => { e.stopPropagation(); setIsOpen(!isOpen) }}>
        <Facepile
            personaSize={PersonaSize.size32}
            personas={userImage}
            getPersonaProps={() => ({ presence: PersonaPresence.online })} />
        {isOpen &&
            <div className="fixed" style={{ width: '100vw', height: '100vh', left: 0, top: 0, opacity: 1, zIndex: 999999 }}>
                <div className="py-2 absolute border border-white cursor-default" style={{ ...dropDownStyle, ...dropDownExtraStyles }}>
                    <div className="absolute" style={{ ...triangleStyle, ...triangleExtraStyles }} />
                    <p className="px-4 text-lg pb-2 pt-1 border-b border-white" style={{ textTransform: 'capitalize' }}>{userInfo.account.username}</p>
                    <FlexCol className='w-[100%] px-2 py-4' alignItems='start'>
                        {userInfo.customClaims.map((claim) => <FlexRow alignItems='center' justifyContent='space-between' style={{width:'calc(100% - 8px)',margin:'0px 4px'}} className='border-b border-dashed'>
                            <p className="text-lg grow-2" style={{ textTransform: 'capitalize' }}>{claim.ProductId}:</p>
                            <p className="text-base text-right ml-2 grow" style={{ textTransform: 'capitalize' }}>{claim.RuoloId}</p>
                        </FlexRow>)}
                    </FlexCol>
                    <p className="px-2 w-[100%] text-xl text-end pr-4 pt-4 hover:underline cursor-pointer border-t border-white" onClick={handleLogout}>Logout</p>
                </div>
            </div>
        }
    </div>
}
export { UserFacepile }