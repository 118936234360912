import { Grid, IconButton, Modal, TextField } from '@mui/material';
import React from 'react';
import './styles.css';
import { TProductAsset } from '../../../services/products';
import { isJsonString } from '../../../utility/json';
import { FiDelete } from 'react-icons/fi'
import { MdOutlineAddBox } from 'react-icons/md'
import { assetIcon } from '../EditAssetsPage';
import { GiConfirmed } from 'react-icons/gi';
import InputAdornment from '@mui/material/InputAdornment';
import { CustomReactJsonEditor } from '../TextFields/CustomReactJsonEditor';
import { AiOutlineClose } from 'react-icons/ai';
import { IconButtonMenu } from '../../../components/IconButtonMenu/IconButtonMenu';
import { BsPlusCircleFill } from 'react-icons/bs';
type Props = {
    asset: TProductAsset | null,
    title: string
    jsonObj: string | any
    isOpen: boolean
    setJsonValue: (key: string, value: any) => void
    onClose: (e: React.MouseEvent<HTMLElement>) => void
    setValue: (value: any) => void
}
enum addOptions { STRING = "string", NUMBER = "number", UNDEFINED = "", ARRAY = "array", OBJECT = "object" }
function getAddOptionFromValue(value: any) {
    if (Array.isArray(value)) return addOptions.ARRAY;
    switch (typeof value) {
        case "string": return addOptions.STRING;
        case "number": return addOptions.NUMBER;
        case "object": return addOptions.OBJECT;
        default: return addOptions.UNDEFINED;
    }
}
const EditJsonForm: React.FC<Props> = ({ title, jsonObj, setJsonValue, setValue, isOpen, onClose, asset }) => {
    const [editedKeys, setEditedKeys] = React.useState<{ [key: string]: string }>({})
    React.useEffect(() => { return () => setEditedKeys({}) }, [isOpen])
    const obj = React.useMemo(() => isJsonString(jsonObj) ? JSON.parse(jsonObj) : jsonObj, [jsonObj])
    const textFieldInputProps = React.useMemo(() => ({
        style: {
            color: 'white',
            backgroundColor: 'rgb(51,65,85)',
        }
    }), [])
    const textFieldInputLabelProps = React.useMemo(() => ({
        style: {
            color: 'white',
        }
    }), [])
    const formatValue = (value: any) => {
        // if (typeof (value) === "object" || assetJsonColumns.includes(value)) return JSON.stringify(value)
        return value
    }
    const handleDeleteKeyIndex = (k: string, i: number) => {
        if (setValue) {
            if (!Array.isArray(obj[k])) {
                return;
            }
            let _ = { ...obj, [k]: obj[k].slice(0, i).concat(obj[k].slice(i + 1)) }
            setValue(_)
        }
    }
    const handleAddKeyIndex = (k: string, i: number) => {
        if (setValue) {
            if (!Array.isArray(obj[k])) {
                return;
            }
            const value_add_option = getAddOptionFromValue(obj[k][0])
            let _ = { ...obj, [k]: obj[k].slice(0, i).concat([getDefaultValue(value_add_option)]).concat(obj[k].slice(i)) }
            setValue(_)
        }
    }
    const handleDeleteKey = (k: string) => {
        if (setValue) {
            delete obj[k]
            setValue(obj)
        }
    }
    const mapArrayValueInput = (key: string, i: number, value: any) => {
        const handleArrayTextChange = (v: any, i: number) => {
            if (setValue) {
                obj[key][i] = v;
                setValue(obj)
            }
        }
        const isArray = Array.isArray(value)
        const isObjectButNotArray = typeof (value) === "object" && !isArray
        const handleArrayObjChange = (newValue: any) => {
            obj[key][i] = newValue;
            setValue(obj)
        }
        return <Grid item xs={12} md={isArray || isObjectButNotArray ? 12 : 6} lg={isArray || isObjectButNotArray ? 12 : 4}>
            <div className="flex">
                {!isArray && !isObjectButNotArray ?
                    <TextField
                        id={`input-${i}-${key}`}
                        inputProps={textFieldInputProps}
                        InputLabelProps={textFieldInputLabelProps}
                        placeholder=''
                        size="small"
                        label={`${key}-${i}`}
                        value={formatValue(value)}
                        onChange={(e) => handleArrayTextChange(e.target.value, i)}
                    />
                    :
                    <div className='bg-gray-200 !text-white !w-[85%]'>
                        <p className="text-2xl w-[100%] bg-slate-500 text-white px-4 pb-2">{key}-{i}</p>
                        <CustomReactJsonEditor values={value} onChange={(e: any) => handleArrayObjChange(e)} />
                    </div >
                }
                <div className="mx-2" />
                <IconButton className="!h-[30px] !w-[30px] !text-white !text-lg !rounded-sm !bg-amber-800" onClick={() => handleDeleteKeyIndex(key, i)}><FiDelete /></IconButton>
                <div className="mx-1" />
                <IconButton className="!h-[30px] !w-[30px] !text-white !text-lg !rounded-sm !bg-intesa-green" onClick={(e) => handleAddKeyIndex(key, i)}><MdOutlineAddBox /></IconButton>
            </div>
        </Grid>
    }
    const mapValueInput = (key: string, value: any) => {
        const isArray = Array.isArray(value)
        const isObject = typeof (value) === "object"
        const isObjectButNotArray = isObject && !isArray
        return <div className="flex flex-col w-[100%]">
            {isArray && value.length > 0 &&
                <Grid container >
                    {value.map((a, i) => mapArrayValueInput(key, i, a))}
                </Grid>}

            {isArray && value.length === 0 &&
                <div className="flex h-[40px] items-center">
                    <IconButton className="!h-[30px] !w-[30px] !text-white !text-lg !rounded-sm !bg-intesa-green" onClick={(e) => handleAddKeyIndex(key, 0)}><MdOutlineAddBox /></IconButton>
                </div>
            }
            {isObjectButNotArray && <div className='bg-gray-200 !text-white !w-[100%]'>
                <CustomReactJsonEditor
                    values={value}
                    onChange={() => { }}
                />
            </div>}
            {!isArray && !isObjectButNotArray &&
                <div className="flex w-[100%] items-center">
                    <TextField
                        id={`input-${key}`}
                        className="!grow"
                        placeholder=''
                        inputProps={textFieldInputProps}
                        InputLabelProps={textFieldInputLabelProps}
                        label={key}
                        value={formatValue(value)}
                        onChange={(e: React.ChangeEvent<HTMLInputElement>) => setJsonValue(key, e.target.value)}
                    />
                    <div className="mx-2" />
                </div>
            }
        </div>
    }
    const mapValue = (key: string, value: any) => {
        const isArray = Array.isArray(value)
        const handleChangeKeyConfirm = (key: string) => {
            if (setValue && editedKeys[key]) {
                let oldValue = obj[key]
                delete obj[key]
                obj[editedKeys[key]] = oldValue
                setValue(obj)
            }
        }
        return <div className={`flex w-[100%] justify-between border-b py-1 mb-4 ${isArray ? "items-top" : "items-center"}`}>
            <TextField
                id={`input-key-${key}`}
                placeholder=''
                label={""}
                // inputProps={textFieldInputLabelProps}
                // endAdornment:<Button onClick={handleChangeKeyConfirm}><GiConfirmed/></Button>}}
                InputProps={{
                    style: { color: 'white',backgroundColor:'rgb(146,64,14)' },
                    endAdornment: Object.keys(editedKeys).includes(key) ? <InputAdornment position="end"><IconButton className="!text-white" onClick={() => handleChangeKeyConfirm(key)}><GiConfirmed /></IconButton></InputAdornment> : null,
                }}
                InputLabelProps={textFieldInputLabelProps}
                size="small"
                variant='outlined'
                value={editedKeys[key] ?? key}

                onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                    setEditedKeys({ [key]: e.target.value })
                }}
            />
            <div className="mx-2" />
            {mapValueInput(key, value)}
            <IconButton className="!h-[30px] !w-[30px] !text-white !text-xl !rounded-sm !bg-red-800" onClick={() => handleDeleteKey(key)}><FiDelete /></IconButton>
        </div>
    }
    const getDefaultValue = (t: addOptions) => {
        switch (t) {
            case addOptions.STRING: return "";
            case addOptions.NUMBER: return 0;
            case addOptions.ARRAY: return [];
            case addOptions.OBJECT: return {};
            default: return "";
        }
    }
    const newKey = () => {
        let key = "chiave_1";
        while (Object.keys(obj).includes(key)) {
            key = `${key.substring(0, key.length - 2)}_${parseInt(key.substring(key.length - 1)) + 1}`;
        }
        return key;
    }
    const addJsonKeyValue = (key: string, value: any) => {
        if (setValue) {
            setValue({ ...obj, [key]: value })
        }
    }
    return <Modal onClose={onClose} open={isOpen} className="w-[100vw] h-[100vh]">
        <div className='popup-container bg-slate-600 text-white shadow-xl !px-4 !py-2 rounded'>
            <IconButton onClick={onClose} style={{ position: 'absolute', right: 5, top: 5 }} className="!bg-slate-500 !border !rounded !text-slate-800 !font-bold"><AiOutlineClose /></IconButton>
            <div className='w-[100%] flex items-center mb-4 mt-4'>
                {asset ? assetIcon(asset, { color: 'white', fill: 'white', height: 25, width: 25 }) : null}
                <p className="text-3xl ml-4">{asset?.Name}: {title}</p>
                <IconButtonMenu
                    icon={<BsPlusCircleFill style={{color:"white",fontSize:30,margin:'auto 10px'}}/>}
                    options={Object.values(addOptions).map(x=>({key:x,value:x,label:x}))}
                    handleItemClick={(e,opt)=>{addJsonKeyValue(newKey(), getDefaultValue(opt.value as addOptions))}}
                />
            </div>
            {Object.keys(obj).sort((a, b) => a > b ? 1 : -1).map((key) => (mapValue(key, obj[key])))}
            {/* <div className="w-[100%] flex items-center justify-end mt-4">
                <>
                
                    <p className="mr-2 text-xl">Aggiungi valore</p>
                    <Select
                        style={{ zIndex: 1 }}
                        inputProps={{ zIndex: 1 }}
                        variant='outlined'
                        className="!text-white !h-[30px] !my-2 bg-slate-400 !px-2 mr-4"
                        label="Aggiungi"
                        labelId='add-select-label'
                        id="add-select"
                        value={addOptions.UNDEFINED}
                        onChange={(e: SelectChangeEvent) => addJsonKeyValue(newKey(), getDefaultValue(e.target.value as addOptions))}
                    >
                        {Object.values(addOptions).map((at) => <MenuItem value={at}>{at}</MenuItem>)}
                    </Select>
                </>
            </div> */}
        </div>
    </Modal>
}
export { EditJsonForm }