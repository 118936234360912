import axios from "axios"
import { IExtendedSuperMetricValue, ISuperMetric, ISuperMetricConfiguration, ISuperMetricValue } from "../types/etickets"
import { getPagedOdataItems } from "./generic"
import { IColumnFilter } from "../types/odata"

export const getSuperMetric = async (id:number)=>{
    return axios.get(`${process.env.REACT_APP_SERVER_URL}/SuperMetric/Single/${id}`).then((res)=>res.data as ISuperMetric)
}
export const getSuperMetricValue = async (id:number)=>{
    return axios.get(`${process.env.REACT_APP_SERVER_URL}/SuperMetricValue/Single/${id}`).then((res)=>res.data as IExtendedSuperMetricValue)
}
export const getExtendedSuperMetricValue = async (id:number)=>{
    const idFilter : IColumnFilter<ISuperMetricValue>={
        columnName:"Id",
        value:id,
        valueType:"number",
        operator:'eq'
    } 
    return getPagedOdataItems("SuperMetricValue",0,1,[idFilter],[],false,"SuperMetric").then((res)=>res.value as IExtendedSuperMetricValue[])
}
export const getSuperMetricConfiguration = async (id:number)=>{
    return axios.get(`${process.env.REACT_APP_SERVER_URL}/SuperMetricConfiguration/Single/${id}`).then((res)=>res.data as ISuperMetricConfiguration)
}