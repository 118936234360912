import { FontIcon, IconButton } from '@fluentui/react';
import React from 'react';
type Props = {
    textIsClickable?: boolean
    containerIsClickable?: boolean
    iconPosition: "beforeText" | "afterText" | "right",
    text: string,
    textClassNames?: string,
    containerClassNames?: string,
    defaultOpen?: boolean,
    border?: boolean,
    headerAfterChildren?: React.ReactNode,
    headerBeforeChildren?: React.ReactNode,
    open?: boolean,
    setOpen?: (open: boolean) => void,
    children?: React.ReactNode
    iconColorClassNames?: string,
    iconButtonClassNames?: string,
    iconButtonStyles?: React.CSSProperties
    containerExtraStyles?: React.CSSProperties
    iconNames?: string[]
    childrenInside?: boolean
    borderIfOpen?: boolean
}
const ExpandableContainer: React.FC<Props> = ({
    iconColorClassNames,
    containerExtraStyles,
    text,
    textIsClickable,
    containerIsClickable,
    textClassNames,
    containerClassNames,
    defaultOpen,
    iconPosition,
    border,
    children,
    open,
    borderIfOpen,
    setOpen,
    iconButtonClassNames,
    iconButtonStyles,
    headerAfterChildren,
    headerBeforeChildren,
    iconNames,
    childrenInside }) => {
    const [localOpen, setLocalOpen] = React.useState(defaultOpen);
    const _setOpen = setOpen ?? setLocalOpen;
    const _open = setOpen ? open : localOpen;
    const handleClick = (e: any) => {
        e.stopPropagation();
        _setOpen(!_open)
    }
    const handleTextClick = (e: any) => {
        if (textIsClickable) {
            handleClick(e);
        }
    }
    const handleContainerClick = (e: any) => {
        if (containerIsClickable) {
            handleClick(e);
        }
    }
    const localIconNames = iconNames ?? ["CaretSolidDown", "CaretSolidRight"]
    return <><div
        onClick={handleContainerClick}
        className={containerClassNames ?? "flex flex-row w-[100%]"}
        style={{ cursor: containerIsClickable ? 'pointer' : 'default', borderBottom: (border || borderIfOpen && _open) ? "1px solid lightgrey" : undefined, ...containerExtraStyles }}>
        {headerBeforeChildren}
        {iconPosition === "beforeText"
            ?
            <>
                <IconButton
                    style={{ width: 35, ...iconButtonStyles }}
                    className={iconButtonClassNames ?? "hover:bg-transparent"}
                    onClick={handleClick}
                >
                    <FontIcon
                        className={iconColorClassNames ?? ""}
                        iconName={_open ? localIconNames[0] : localIconNames[1]} />
                </IconButton>
                <p onClick={handleTextClick} className={textClassNames ?? "text-lg font-bold"} style={{ cursor: textIsClickable ? 'pointer' : 'default' }}>{text}</p>
            </>
            :
            <>
                <p onClick={handleTextClick} className={textClassNames ?? "text-lg font-bold"} style={{ cursor: textIsClickable ? 'pointer' : 'default' }}>{text}</p>
                <IconButton
                    className={iconButtonClassNames ?? "hover:bg-transparent"}
                    style={{ width: 35, marginLeft: iconPosition === "right" ? "auto" : undefined, ...iconButtonStyles }}
                    onClick={handleClick}
                >
                    <FontIcon
                        iconName={_open ? localIconNames[0] : localIconNames[1]} />
                </IconButton>
            </>
        }
        {headerAfterChildren}
        {_open && childrenInside && children}
    </div>
        {_open && !childrenInside && children}
    </>
}
export { ExpandableContainer }