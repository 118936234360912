import React from "react";
import './styles.scss'
import { Button, Menu, MenuItem } from "@mui/material";
import { useDashboardsNavigation } from "../../store/hooks/useDashboardsNavigation";
import { NeededClaims, TAreas, known_products } from "../../types/userStore";
import { useNavigate } from "react-router-dom";
import { hasPermission } from "../../store/hooks/useUserStore";
import { useUserContext } from "../../store/hooks/useUserContext";
import {
  DESKTOP_ROW_DASHBOARD_CONTAINER_SIDEBAR_WIDTH_FULL,
} from "../RowDashboardContainer/RowDashboardContainerSidebar/RowDashboardContainerSidebar";
import { useDashboardsStore } from "../../store/hooks/useDashboardsStore";
import { isMobile } from "react-device-detect";

type Props = {
  titleFontSize: string | number;
};
const ProductSelector: React.FC<Props> = ({ titleFontSize }) => {
  const navigate = useNavigate();
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const handleClick = React.useCallback(
    (event: React.MouseEvent<HTMLButtonElement>) => {
      setAnchorEl(event.currentTarget);
    },
    []
  );
  const handleClose = React.useCallback(() => setAnchorEl(null), [setAnchorEl]);
  const { productId: currentProductId } = useDashboardsNavigation();
  const reducedProductIds = React.useMemo<{ [key: string]: string }>(
    () =>
      Object.fromEntries(
        Object.values(known_products).map((x) => [
          x.id,
          x.id !== known_products.assetimmobiliare.id
            ? x.id.slice(0, 3)
            : "IMM",
        ])
      ),
    []
  );
  const reducedProductId = React.useMemo(
    () => reducedProductIds[currentProductId],
    [currentProductId, reducedProductIds]
  );
  const { userClaims } = useUserContext();
  const products = React.useMemo(
    () =>
      Object.values(known_products).filter((p)=>p.id!=='sbh').filter((p) =>
        hasPermission(
          userClaims,
          p.dashboard_url as keyof NeededClaims,
          p.id as TAreas
        )
      ),
    [userClaims]
  );
  const handleProductClick = (path: string) => {
    navigate(`${path}?page=0`);
    setAnchorEl(null);
  };
  const { contextState } = useDashboardsStore();
  const { rowDashboardContainersidebarWidth: sidebarWidth } = contextState;
  const useReducedProduct = React.useMemo(
    () => sidebarWidth < DESKTOP_ROW_DASHBOARD_CONTAINER_SIDEBAR_WIDTH_FULL,
    [sidebarWidth]
  );
  return (
    <div>
      {isMobile ? 
        <Button
        id="product-selector"
        size="small"
        variant="contained"
        aria-orientation="vertical"
        className="!ml-4 !text-center mobile-btn"
        aria-controls={open ? "basic-menu" : undefined}
        aria-haspopup="true"
        aria-expanded={open ? "true" : undefined}
        onClick={handleClick}>
          {reducedProductId}
        </Button>
      : <Button
        id="product-selector"
        aria-controls={open ? "basic-menu" : undefined}
        aria-haspopup="true"
        aria-expanded={open ? "true" : undefined}
        variant="outlined"
        onClick={handleClick}
        className="!py-4 !px-1 !font-bold flex flex-col"
        style={{
          color: "white",
          borderColor: "white",
          //   backgroundColor:intesa_green,
          fontSize: titleFontSize,
          writingMode: "vertical-rl",
          // transform: "rotate(-180deg)",
        }}
      >
        {useReducedProduct ? reducedProductId : currentProductId}
      </Button>}
      <Menu
        id="product-selector"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        MenuListProps={{
          "aria-labelledby": "basic-button",
          style: {
            backgroundColor: "#466081",
            color: "white",
          },
        }}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "right",
        }}
        transformOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
      >
        {products.map((p) => (
          <MenuItem
            key={p.dashboard_url}
            onClick={() => handleProductClick(p.dashboard_url)}
          >
            <p className={`${p.id === currentProductId ? "underline" : ""}`}>
              {p.id}
            </p>
          </MenuItem>
        ))}
      </Menu>
    </div>
  );
};
export { ProductSelector };
