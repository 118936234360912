import { isMobile } from "react-device-detect"
import useWindowSize from "./useWindowSize"
export const desktopInitialSidebarWidth = 125;
export const desktopInitialBottombarHeight = 60;
export const useIsMobile = () => {
    const { width } = useWindowSize();
    const _isMobile = isMobile || window.innerWidth < 1000 || (width && width < 1000);
    const bottombarHeight = _isMobile ? desktopInitialBottombarHeight : 0
    const sidebarWidth = _isMobile ? 0 : desktopInitialSidebarWidth
    return {
        isMobile: _isMobile,
        bottombarHeight,
        sidebarWidth
    }
}