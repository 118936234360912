import React from 'react'
import { HeaderFilterCell } from '../HeaderFilterCell/HeaderFilterCell'
import { ODataFilterOperators, ODataValueTypes } from '../../../types/odata'
import { Button, Typography } from '@mui/material'
import { useIsMobile } from '../../../utility/useIsMobile'
import { HeaderPopupFilterInputProps } from '../types'

export const HeaderPopupFilterInput: React.FC<HeaderPopupFilterInputProps> = (props) => {
    const {
        enumStoreKey,
        header,
        selectedOperator,
        setSelectedOperator,
        currentValues,
        setCurrentValues
    } = props;
    const { isMobile } = useIsMobile();
    const operatorToLabelMap: { [key in ODataFilterOperators]: string } = {
        le: "<=",
        ge: ">=",
        eq: "=",
        contains: "in",
        any: "in",
        "all-ne":"non in",
        "ge-le": "Range",
        in: "in",
        ne: "!="
    }
    const typeToOperators: { [key in ODataValueTypes]: ODataFilterOperators[] } = {
        number: ["le", "ge", "eq"],
        string: ["le", "ge", "eq", "contains"],
        guid: ["le", "ge", "eq", "contains"],
        enum: ["eq", "ne"],
        boolean: [],
        collection: [],
        daterange: [],
        datetime: [],
    }

    if (!header) {
        return null;
    }
    if (header.hideFilter) {
        return null;
    }
    const typeOperatorsVisible = typeToOperators[header.valueType].length > 0;
    return <div
        className='flex flex-col relative items-center justify-center border border-solid shadow-lg bg-white border-gray-300 p-1 rounded min-w-[200px] h-[175px]'
        style={{
            marginRight: isMobile ? 0 : 4,
            marginBottom: 4,
        }}
    >
        <Typography align='left' className='!text-xl !w-[100%] !font-bold !m-0 !p-0' style={{ height: 25 }}>{header.label}</Typography>
        {header.customFilterCell
            ? <div style={{height:150,width:'100%',alignContent:"center"}}>
                {header.customFilterCell({
                    header,
                    enumStoreKey,
                    filterValueType: header.valueType,
                    fullWidth: true,
                    localValue: { value: null, setValue: () => { } }
                })}
            </div>
            :
            <div className='w-[100%] h-[100%] items-center justify-start flex flex-col'>
                {typeOperatorsVisible &&
                    <div className='flex items-center' style={{ height: 43, paddingBottom: 10 }}>
                        {typeToOperators[header.valueType].map((x) => <Button
                            onClick={() => setSelectedOperator({ ...selectedOperator, [header.id.toString()]: x })}
                            size="small"
                            className='!m-0 !mr-1'
                            variant={x === selectedOperator[header.id.toString()] ? 'contained' : 'outlined'}>
                            <p className='text-sm m-0 p-0'>{operatorToLabelMap[x]}</p>
                        </Button>)}
                    </div>}
                <div style={{
                    height: typeOperatorsVisible ? 110 : 150,
                    alignContent: typeOperatorsVisible ? 'start' : 'center',
                    // marginBottom: typeOperatorsVisible ? 20 : 0,
                    width: '100%'
                }}>
                    <HeaderFilterCell
                        //value,setValue e applica tutto da qua
                        localValue={{
                            value: currentValues[header.id.toString()],
                            setValue: (x) => setCurrentValues({ ...currentValues, [header.id.toString()]: x })
                        }}
                        fullWidth
                        hideIcon
                        enumStoreKey={enumStoreKey}
                        header={header}
                        filterValueType={header.valueType}
                    />
                </div>
            </div>
        }
    </div>
}