import React from 'react'
import { Page } from '../../components/Page/Page';
import { TAssignment } from '../../types/assignments';
import { useHeaders } from '../../store/hooks/useHeaders';
import { TicketsGrid } from '../../components/TicketsGrid/TicketsGrid';
import { PaginationEnum } from '../../store/pager';
import { ODataGridToolbar } from '../../components/ODataGrid/ODataGridToolbar/ODataGridToolbar';
export type THeader = {
    id: string
    label: string
    width: string | number
    element: (a: TAssignment) => string | undefined
}

export const TicketList: React.FC = () => {
    const { ticketHeaders,setTicketHeaders } = useHeaders();
    return <Page>
        <ODataGridToolbar enumStoreKey={PaginationEnum.TICKETS} headers={ticketHeaders} setHeaders={setTicketHeaders}/>
        <TicketsGrid headers={ticketHeaders} />
    </Page >
}