import React from 'react';
import { DashboardHeaderText } from '../../DashboardHeader/DashboardHeaderText';
import { HeaderProps } from './types';
import { ProductPageSection } from '../../../hooks/useProductDashboardDimensions';
import { TutorialsIcon } from '../Icons/TutorialsIcon';
export const TutorialsHeader: React.FC<HeaderProps> = ({settings}) => {
  
    return (
      <DashboardHeaderText
        id={ProductPageSection.TUTORIALS}
        icon={<TutorialsIcon onlyIcon />}
        settings={settings}
        editable={false}
        text="Tutorials"
      />
    );
}