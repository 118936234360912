import React from "react";
import { TSelectedKey } from "../../types/Lists";
import { globalContext } from "../globalStore"
import { Building } from "../../services/building";
import { IGenericError } from "../../types/general";

export const useGlobalStore = () => {
    const { state, dispatch } = React.useContext(globalContext);
    const setBuildings = React.useCallback((payload: Building[]) => dispatch({ type: "SET_BUILDINGS", payload }),[dispatch])
    const setMapBuildings = React.useCallback((payload: Building[]) => dispatch({ type: "SET_MAP_BUILDINGS", payload }),[dispatch])
    const setSelectedBuildings = React.useCallback((payload: Building[]) => dispatch({ type: "SET_SELECTED_BUILDINGS", payload }),[dispatch])
    const setSelectedBuildingsAndPolo = React.useCallback((payload: { selectedBuildings: Building[], polo: string }) => dispatch({ type: "SET_SELECTED_BUILDINGS_AND_POLO", payload }),[dispatch])
    const setLoading = React.useCallback((payload: boolean) => dispatch({ type: "SET_LOADING", payload }),[dispatch])
    const setForceListLoad = React.useCallback((payload: boolean) => dispatch({ type: 'SET_FORCE_LIST_LOAD', payload }),[dispatch])
    const setSelectedListPivot = React.useCallback((payload: TSelectedKey) => dispatch({ type: "SET_SELECTED_LIST_PIVOT", payload }),[dispatch])
    const setReportArea = React.useCallback((payload: string) => dispatch({ type: "SET_REPORT_AREA", payload }),[dispatch])
    const setPowerBIToken = React.useCallback((payload: string) => dispatch({ type: "SET_POWER_BI_TOKEN", payload }),[dispatch])
    const setGlobalError = React.useCallback((payload: IGenericError) => dispatch({ type: "SET_GLOBAL_ERROR", payload }),[dispatch])
    return {
        globalState: state,
        dispatch,
        setBuildings,
        setSelectedBuildings,
        setSelectedBuildingsAndPolo,
        setLoading,
        setForceListLoad,
        setSelectedListPivot,
        setMapBuildings,
        setReportArea,
        setPowerBIToken,
        setGlobalError,
    }
}