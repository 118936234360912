import React from "react";
import { ASSIGNMENTS_CONTROLLER } from "../../services/assignments";
import { IColumnFilter, ODataResponse } from "../../types/odata";
import { assignments_known_urls } from "../../types/userStore";
import { PaginationEnum } from "../pager";
import { getPagedOdataItems } from "../../services/generic";
import { IColumnFilterPreset } from "../../types/general";
import { useFilterPresets } from "./useFilterPresets";
import { notEqual } from "assert";
export enum PileContainerEnum {
  personal = "Personali",
  my_area = "La mia Area",
  tags = "Tags",
}
export enum PileSetEnum {
  attivi = "Attivi",
  storico = "Storico",
}
export const dangerClassName = "bg-red-800 text-white"
export const warningClassName = "bg-amber-500 text-white"
export const pileCustomClassNames = (dangerTreshold: number, warningTreshold: number, count: number) => {
  if (count > dangerTreshold) return dangerClassName;
  if (count > warningTreshold) return warningClassName;
  return "bg-intesa-green text-white";
}
export interface IPile {
  containerName: PileContainerEnum;
  customColorClassName?: (count: number) => string;
  innerContainerName?: string;
  help: string;
  key: string;
  title: string;
  subtitle?: string;
  filterPresets: IColumnFilterPreset<any>[];
  requestAction: (filters: IColumnFilter<any>[]) => Promise<ODataResponse<any>>;
  targetUrl: string;
  storageEnumKey: PaginationEnum;
}
export const usePiles = () => {
  const { presets: state_presets } = useFilterPresets();
  const tags = React.useMemo(
    () => ["Asset", "Consumi", "Chiusura Filiali", "Giornale lavori"],
    []
  );
  const tag_preset: (tag: string, notEqual?: boolean) => IColumnFilterPreset<any> =
    React.useCallback(
      (tag, notEqual) => ({
        presetName: `tag-${tag}`,
        filterGroup: "Tag",
        filter: {
          columnName: "Tags",
          operator: notEqual ? "all-ne" : "any",
          value: [tag],
          valueType: "string",
          nestedColumn: "Tag",
        },
      }),
      []
    );
  const active_tags_presets: {
    [key: string]: { help: string; presets: IColumnFilterPreset<any>[] };
  } = {
    "Assegnazioni aperte": {
      help: "Assegnazioni da risolvere",
      presets: [state_presets.ASSIGNMENTS.NOT_RESOLVED],
    },

  };
  const tags_piles: IPile[] = React.useMemo(() => {
    let v: IPile[] = [];
    Object.entries(active_tags_presets).forEach(([label, item]) =>
      tags.forEach((tag) =>
        v.push({
          containerName: PileContainerEnum.tags,
          innerContainerName: tag,
          key: `active-tags-${label}-${tag}`,
          customColorClassName: (n) => pileCustomClassNames(100, 50, n),
          // label: `${tps.map((tp)=>tp.presetName).join(', ')}`,
          // help:tps.map((tp)=>tp.help ?? tp.presetName).join(', '),
          title: tag,
          subtitle: label,
          help: item.help,
          filterPresets: [...item.presets, tag_preset(tag)],
          requestAction: (filters: IColumnFilter<any>[]) =>
            getPagedOdataItems(ASSIGNMENTS_CONTROLLER, 0, 0, filters, [], true),
          targetUrl: assignments_known_urls.assignment_list,
          storageEnumKey: PaginationEnum.ASSIGNMENTS,
        })
      )
    );
    return v;
  }, [tag_preset, tags]);
  const hist_tag_presets: { [key: string]: IColumnFilterPreset<any>[] } = {
    // "Assegnazioni ultima settimana": [
    //   state_presets.ASSIGNMENTS.LAST_WEEK_ASSIGNMENTS,
    // ],
    "Assegnazioni ultimo mese": [
      state_presets.ASSIGNMENTS.LAST_MONTH_ASSIGNMENTS,
    ],
    // "Assegnazioni ultimo anno": [
    //   state_presets.ASSIGNMENTS.LAST_YEAR_ASSIGNMENTS,
    // ],
  };
  const hist_tags_piles: IPile[] = React.useMemo(() => {
    let v: IPile[] = [];
    Object.entries(hist_tag_presets).forEach(([label, presets]) =>
      tags.forEach((tag) =>
        v.push({
          containerName: PileContainerEnum.tags,
          innerContainerName: tag,
          key: `history-tags-${tag}-${label}`,
          title: tag,
          subtitle: label,
          help: label,
          filterPresets: [
            ...presets,
            tag_preset(tag),
            // state_presets.ASSIGNMENTS.STANDBY_ASSIGNMENTS,
            state_presets.ASSIGNMENTS.RESOLVED,
          ],
          requestAction: (filters: IColumnFilter<any>[]) =>
            getPagedOdataItems(ASSIGNMENTS_CONTROLLER, 0, 0, filters, [], true),
          targetUrl: assignments_known_urls.assignment_list,
          storageEnumKey: PaginationEnum.ASSIGNMENTS,
        })
      )
    );
    return v;
  }, [tag_preset, tags]);
  const active_no_tag_pile: IPile = React.useMemo(() => ({
    containerName: PileContainerEnum.tags,
    innerContainerName: "Altro",
    key: `active-tags-altro`,
    title: "Altro",
    subtitle: "Assegnazioni aperte senza tag",
    help: "Assegnazioni aperte senza tag",
    filterPresets: [...tags.map((tag) => tag_preset(tag, true)),
    state_presets.ASSIGNMENTS.NOT_RESOLVED,
    ],
    requestAction: (filters: IColumnFilter<any>[]) =>
      getPagedOdataItems(ASSIGNMENTS_CONTROLLER, 0, 0, filters, [], true),
    targetUrl: assignments_known_urls.assignment_list,
    storageEnumKey: PaginationEnum.ASSIGNMENTS,
  }), [])
  const hist_no_tag_pile: IPile = React.useMemo(() => ({
    containerName: PileContainerEnum.tags,
    innerContainerName: "Altro",
    key: `history-tags-altro`,
    title: "Altro",
    subtitle: "Assegnazioni ultimo mese senza tag",
    help: "Assegnazioni ultimo mese senza tag",
    filterPresets: [...tags.map((tag) => tag_preset(tag, true)),
    state_presets.ASSIGNMENTS.LAST_MONTH_ASSIGNMENTS,
    ],
    requestAction: (filters: IColumnFilter<any>[]) =>
      getPagedOdataItems(ASSIGNMENTS_CONTROLLER, 0, 0, filters, [], true),
    targetUrl: assignments_known_urls.assignment_list,
    storageEnumKey: PaginationEnum.ASSIGNMENTS,
  }), [])
  const pileSets: { [key in PileSetEnum]: IPile[] } = React.useMemo(
    () => ({
      [PileSetEnum.attivi]: [
        ...tags_piles,
        active_no_tag_pile,
        {
          containerName: PileContainerEnum.personal,

          key: "personal-my-open-assignments",
          title: "Assegnazioni aperte",
          customColorClassName: (n) => pileCustomClassNames(10, 5, n),
          help: "Le mie assegnazioni non risolte",
          filterPresets: [
            state_presets.ASSIGNMENTS.MY_ASSIGNMENTS,
            state_presets.ASSIGNMENTS.NOT_RESOLVED,
          ],
          // filterPreset:[myTicketsFilter,statusFilter(AssignmentStatusEnum.New)],
          requestAction: (filters: IColumnFilter<any>[]) =>
            getPagedOdataItems(ASSIGNMENTS_CONTROLLER, 0, 0, filters, [], true),
          targetUrl: assignments_known_urls.assignment_list,
          storageEnumKey: PaginationEnum.ASSIGNMENTS,
        },
        {
          containerName: PileContainerEnum.personal,

          key: "personal-my-standby-assignments",
          title: "Assegnazioni da sbloccare",
          customColorClassName: (n) => pileCustomClassNames(10, 5, n),
          help: "Le mie assegnazioni da sbloccare",
          filterPresets: [
            state_presets.ASSIGNMENTS.MY_ASSIGNMENTS,
            state_presets.ASSIGNMENTS.STANDBY_ASSIGNMENTS,
          ],
          // filterPreset:[myTicketsFilter,statusFilter(AssignmentStatusEnum.New)],
          requestAction: (filters: IColumnFilter<any>[]) =>
            getPagedOdataItems(ASSIGNMENTS_CONTROLLER, 0, 0, filters, [], true),
          targetUrl: assignments_known_urls.assignment_list,
          storageEnumKey: PaginationEnum.ASSIGNMENTS,
        },
        {
          containerName: PileContainerEnum.my_area,

          key: "all-open-assignments",
          help: "Le assegnazioni aperte nella mia area",
          title: "Le assegnazioni aperte nella mia area",
          customColorClassName: (n) => pileCustomClassNames(100, 50, n),
          filterPresets: [state_presets.ASSIGNMENTS.NOT_RESOLVED],
          requestAction: (filters: IColumnFilter<any>[]) =>
            getPagedOdataItems(ASSIGNMENTS_CONTROLLER, 0, 0, filters, [], true),
          targetUrl: assignments_known_urls.assignment_list,
          storageEnumKey: PaginationEnum.ASSIGNMENTS,
        },
        {
          containerName: PileContainerEnum.my_area,

          key: "all-standby-assignments",
          title: "Assegnazioni da sbloccare",
          customColorClassName: (n) => pileCustomClassNames(10, 5, n),
          help: "Assegnazioni da sbloccare",
          filterPresets: [
            state_presets.ASSIGNMENTS.STANDBY_ASSIGNMENTS,
          ],
          // filterPreset:[myTicketsFilter,statusFilter(AssignmentStatusEnum.New)],
          requestAction: (filters: IColumnFilter<any>[]) =>
            getPagedOdataItems(ASSIGNMENTS_CONTROLLER, 0, 0, filters, [], true),
          targetUrl: assignments_known_urls.assignment_list,
          storageEnumKey: PaginationEnum.ASSIGNMENTS,
        },
        // {
        //   containerName: PileContainerEnum.my_area,
        //   key: "active-events",
        //   label: "Eventi Attivi",
        //   help:"Eventi Attivi",
        //   filterPresets: [state_presets.INPUT_EVENTS.ACTIVE_EVENTS],
        //   requestAction: (filters: IColumnFilter<any>[]) =>
        //     getPagedOdataItems("InputEvents", 0, 0, filters, [], true),
        //   targetUrl: known_urls.input_events_list,
        //   storageEnumKey: PaginationEnum.INPUT_EVENTS,
        // },
      ],
      [PileSetEnum.storico]: [
        ...hist_tags_piles,
        hist_no_tag_pile,
        // {
        //   containerName: PileContainerEnum.personal,

        //   key: "personal-last-week-assignments",
        //   title: "Le mie assegnazioni dell'ultima settimana",
        //   help: "Le mie assegnazioni dell'ultima settimana",
        //   filterPresets: [
        //     state_presets.ASSIGNMENTS.MY_ASSIGNMENTS,
        //     state_presets.ASSIGNMENTS.LAST_WEEK_ASSIGNMENTS,
        //   ],
        //   requestAction: (filters: IColumnFilter<any>[]) =>
        //     getPagedOdataItems(ASSIGNMENTS_CONTROLLER, 0, 0, filters, [], true),
        //   targetUrl: assignments_known_urls.assignment_list,
        //   storageEnumKey: PaginationEnum.ASSIGNMENTS,
        // },
        // {
        //   containerName: PileContainerEnum.my_area,

        //   key: "last-week-assignments",
        //   help: "Assegnazioni ultima settimana",
        //   title: "Assegnazioni ultima settimana",
        //   filterPresets: [state_presets.ASSIGNMENTS.LAST_WEEK_ASSIGNMENTS],
        //   requestAction: (filters: IColumnFilter<any>[]) =>
        //     getPagedOdataItems(ASSIGNMENTS_CONTROLLER, 0, 0, filters, [], true),
        //   targetUrl: assignments_known_urls.assignment_list,
        //   storageEnumKey: PaginationEnum.ASSIGNMENTS,
        // },
        // {
        //   containerName: PileContainerEnum.personal,

        //   key: "personal-last-month-assignments",
        //   title: "Le mie assegnazioni dell'ultimo mese",
        //   help: "Le mie assegnazioni dell'ultimo mese",
        //   filterPresets: [
        //     state_presets.ASSIGNMENTS.MY_ASSIGNMENTS,
        //     state_presets.ASSIGNMENTS.LAST_MONTH_ASSIGNMENTS,
        //   ],
        //   requestAction: (filters: IColumnFilter<any>[]) =>
        //     getPagedOdataItems(ASSIGNMENTS_CONTROLLER, 0, 0, filters, [], true),
        //   targetUrl: assignments_known_urls.assignment_list,
        //   storageEnumKey: PaginationEnum.ASSIGNMENTS,
        // },
        // {
        //   containerName: PileContainerEnum.my_area,

        //   key: "last-month-assignments",
        //   help: "Assegnazioni ultimo mese",
        //   title: "Assegnazioni ultimo mese",
        //   filterPresets: [state_presets.ASSIGNMENTS.LAST_MONTH_ASSIGNMENTS],
        //   requestAction: (filters: IColumnFilter<any>[]) =>
        //     getPagedOdataItems(ASSIGNMENTS_CONTROLLER, 0, 0, filters, [], true),
        //   targetUrl: assignments_known_urls.assignment_list,
        //   storageEnumKey: PaginationEnum.ASSIGNMENTS,
        // },
        // {
        //   containerName: PileContainerEnum.personal,

        //   key: "personal-last-year-assignments",
        //   title: "Le mie assegnazioni dell'ultimo anno",
        //   help: "Le mie assegnazioni dell'ultimo anno",
        //   filterPresets: [
        //     state_presets.ASSIGNMENTS.MY_ASSIGNMENTS,
        //     state_presets.ASSIGNMENTS.LAST_YEAR_ASSIGNMENTS,
        //   ],
        //   requestAction: (filters: IColumnFilter<any>[]) =>
        //     getPagedOdataItems(ASSIGNMENTS_CONTROLLER, 0, 0, filters, [], true),
        //   targetUrl: assignments_known_urls.assignment_list,
        //   storageEnumKey: PaginationEnum.ASSIGNMENTS,
        // },
        // {
        //   containerName: PileContainerEnum.my_area,

        //   key: "last-year-assignments",
        //   help: "Assegnazioni ultimo anno",
        //   title: "Assegnazioni ultimo anno",
        //   filterPresets: [state_presets.ASSIGNMENTS.LAST_YEAR_ASSIGNMENTS],
        //   requestAction: (filters: IColumnFilter<any>[]) =>
        //     getPagedOdataItems(ASSIGNMENTS_CONTROLLER, 0, 0, filters, [], true),
        //   targetUrl: assignments_known_urls.assignment_list,
        //   storageEnumKey: PaginationEnum.ASSIGNMENTS,
        // },
        {
          containerName: PileContainerEnum.personal,

          key: "personal-resolved-assignments",
          help: "Le mie assegnazioni risolte",
          title: "Le mie assegnazioni risolte",
          filterPresets: [
            state_presets.ASSIGNMENTS.MY_ASSIGNMENTS,
            state_presets.ASSIGNMENTS.CLOSED_ASSIGNMENTS,
          ],
          requestAction: (filters: IColumnFilter<any>[]) =>
            getPagedOdataItems(ASSIGNMENTS_CONTROLLER, 0, 0, filters, [], true),
          targetUrl: assignments_known_urls.assignment_list,
          storageEnumKey: PaginationEnum.ASSIGNMENTS,
        },
        {
          containerName: PileContainerEnum.my_area,

          key: "resolved-assignments",
          help: "Assegnazioni risolte",
          title: "Assegnazioni risolte",
          filterPresets: [state_presets.ASSIGNMENTS.CLOSED_ASSIGNMENTS],
          requestAction: (filters: IColumnFilter<any>[]) =>
            getPagedOdataItems(ASSIGNMENTS_CONTROLLER, 0, 0, filters, [], true),
          targetUrl: assignments_known_urls.assignment_list,
          storageEnumKey: PaginationEnum.ASSIGNMENTS,
        },
        {
          containerName: PileContainerEnum.personal,

          key: "personal-standby-assignments",
          help: "Le mie assegnazioni in standby",
          title: "Le mie assegnazioni in stand-by",
          filterPresets: [
            state_presets.ASSIGNMENTS.MY_ASSIGNMENTS,
            state_presets.ASSIGNMENTS.STANDBY_ASSIGNMENTS,
          ],
          requestAction: (filters: IColumnFilter<any>[]) =>
            getPagedOdataItems(ASSIGNMENTS_CONTROLLER, 0, 0, filters, [], true),
          targetUrl: assignments_known_urls.assignment_list,
          storageEnumKey: PaginationEnum.ASSIGNMENTS,
        },
        {
          containerName: PileContainerEnum.my_area,

          key: "standby-assignments",
          help: "Assegnazioni in standby",
          title: "Assegnazioni in stand-by",
          filterPresets: [state_presets.ASSIGNMENTS.STANDBY_ASSIGNMENTS],
          requestAction: (filters: IColumnFilter<any>[]) =>
            getPagedOdataItems(ASSIGNMENTS_CONTROLLER, 0, 0, filters, [], true),
          targetUrl: assignments_known_urls.assignment_list,
          storageEnumKey: PaginationEnum.ASSIGNMENTS,
        },
      ],
    }),
    [
      hist_tags_piles,
      state_presets.ASSIGNMENTS.CLOSED_ASSIGNMENTS,
      state_presets.ASSIGNMENTS.LAST_MONTH_ASSIGNMENTS,
      state_presets.ASSIGNMENTS.LAST_WEEK_ASSIGNMENTS,
      state_presets.ASSIGNMENTS.LAST_YEAR_ASSIGNMENTS,
      state_presets.ASSIGNMENTS.MY_ASSIGNMENTS,
      state_presets.ASSIGNMENTS.NOT_RESOLVED,
      state_presets.ASSIGNMENTS.STANDBY_ASSIGNMENTS,
      state_presets.INPUT_EVENTS.ACTIVE_EVENTS,
      tags_piles,
    ]
  );
  return {
    pileSets,
    state_presets
  };
};
