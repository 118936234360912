import React from 'react';
import { IconButton } from '@mui/material';
import { useNavigationContext } from '../../store/hooks/useNavigationContext';
import { usePanels } from '../../utility/usePanels';
import { BsArrowLeftSquare, BsArrowRightSquare } from 'react-icons/bs';
type Props = {
    height?: string | number
}
const DisposableSidebar: React.FC<Props> = ({ height }) => {
    const {
        sidebar,
        setSidebarIsOpen,
    } = useNavigationContext();
    const {
        isOpen,
        isClosable,
        isModal,
        childrens,
        position,
    } = sidebar;
    const handleClose = (e: React.MouseEvent<HTMLElement>) => {
        e.stopPropagation();
        setSidebarIsOpen(false);
    }
    const { styles } = usePanels(sidebar, height);
    const sidebarBtnStyles = React.useMemo<React.CSSProperties>(() => ({
        transition: 'all 0.25s',
        opacity: sidebar.isOpen ? 1 : 0,
        borderRadius: '8px',
        position: 'absolute',
        // border: '1px solid white',
        backgroundColor: "transparent",
        top: 'calc(50% - 17px)',
        height: 34,
        width: 34,
        fontSize: 30,
    }), [sidebar.isOpen])
    
    return <>
        <div className={`relative flex flex-col ${isOpen ? 'visible bg-slate-600 text-white' : 'invisible no-sidebar'}`} id="sidebar" style={styles}>
            {childrens}
            {isClosable && <IconButton style={{ ...sidebarBtnStyles, right: position === "right" ? undefined : 0, left: position === "left" ? undefined : 0 }} onClick={handleClose}>
                {position === "left" ? <BsArrowLeftSquare className="text-white text-base h-[100%] w-[100%]" /> : <BsArrowRightSquare className="text-white text-base h-[100%] w-[100%]" />}
            </IconButton>}
        </div>
        {isOpen && isModal && isClosable &&
            <div style={{ zIndex: 2, position: 'fixed', top: 0, left: 0, width: '100vw', height: '100vh', backgroundColor: 'rgba(0,0,0,0.4)', cursor: 'pointer' }} onClick={handleClose} />
        }
    </>
}
export { DisposableSidebar }