import { Typography } from '@mui/material'
import React from 'react'
import { useIsMobile } from '../../../utility/useIsMobile'
import { useLocation } from 'react-router-dom'
import { assignments_known_urls } from '../../../types/userStore'
import { useQuery } from '../../../utility/useQuery'
type PROPS={}
export const TitleLabel:React.FC<PROPS> = () => {
    const {isMobile} = useIsMobile();
    const query = useQuery();
    const {pathname} = useLocation();
    const title = React.useMemo(()=>{
        switch(pathname){
            case assignments_known_urls.wrong_home:return "Home"
            case assignments_known_urls.home:return "Home"
            case assignments_known_urls.input_events_list:return "Lista Eventi"
            case assignments_known_urls.assignment_list:return "Assegnazioni Prescrittive"
            case assignments_known_urls.tickets_list:return "Ticket Esterni"
            case assignments_known_urls.assignment_detail:return `Assegnazione ${query.get("id")}`
            case assignments_known_urls.ticket_detail:return `Ticket (esterno) ${query.get("id")}`
            case assignments_known_urls.alert_event_detail:return `Dettaglio Evento ${query.get("id")}`
            case assignments_known_urls.supermetric_event_detail:return `Dettaglio Evento ${query.get("id")}`
            default:return ""
        }
    },[pathname, query])
    const spaceLeft = React.useMemo(()=>{
        switch(pathname){
            case assignments_known_urls.home:return true
            default:return false
        }
    },[])
    return <Typography variant="h1" fontSize={isMobile ? "1.25rem" : "2rem"} fontWeight={"bold"} align="left" style={{ marginLeft: spaceLeft ? 20 : 0 }}>{title}</Typography>
}