import React from 'react'
import { isMobile } from 'react-device-detect'
import { HeaderCell } from '../../../../../components/HeaderCell/HeaderCell'
import { useMetricHeaders } from '../../../../../store/hooks/useMetricHeaders'

export const AssignmentCfgHeaders: React.FC = () => {
    const {headers} = useMetricHeaders();
    if(isMobile){
        return <></>
    }
    return <div className="flex" style={{ marginBottom: 1 }}>
        {headers.map((h) => <HeaderCell key={`header-${h.id}`} header={h} />)}
    </div>
}