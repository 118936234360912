import React from 'react'
import { IInputEvent } from '../../types/eventNotifications';
import { ISortableHeader } from '../../types/general';
import { ODataGrid } from '../ODataGrid/ODataGrid';
import { PaginationEnum } from '../../store/pager';
import { useIsMobile } from '../../utility/useIsMobile';
import { InputEventsGridRow } from './InputEventsGridRow/InputEventsGridRow';
type PROPS = {
    headers: ISortableHeader<IInputEvent>[]
}
export const InputEventsGrid: React.FC<PROPS> = ({ headers }) => {
    const {isMobile} = useIsMobile();
    
    return (
        <ODataGrid
            setHeaders={() => { }}
            expands=''
            controllerName={"InputEvents"}
            selectPrimaryKey={(x: IInputEvent) => ({
                'externalEventId': x.ExternalEventId,
                'ExternalReferenceId': x.ExternalReferenceId,
                'SourceType': x.SourceType,
            })}
            // state={paginationState.pagination.ASSIGNMENTS}
            // setState={(pagination) => paginationDispatch({ type: "SET_PAGINATION", payload: { key: PaginationEnum.ASSIGNMENTS, pagination } })}
            // currentFilters={assignmentsFilters}
            // currentSortings={assignmentSortings}
            // currentItems={assignments}
            enumStoreKey={PaginationEnum.INPUT_EVENTS}
            // getPagedItems={getPagedAssignments}
            // handleRemoveSort={handleRemoveSort}
            // headerCurrentFilter={headerCurrentFilter}
            // headerCurrentSort={headerCurrentSort}
            headers={headers}
            // isLoading={isLoading}
            // lastCallFilters={paginationState.pagination.ASSIGNMENTS.LAST_CALL_FILTERS}
            // lastCallSortings={paginationState.pagination.ASSIGNMENTS.LAST_CALL_SORTINGS}
            // localFilter={localFilter}
            // removeHeaderFilter={removeHeaderFilter}
            // setHeaderFilter={setHeaderFilter}
            // setLocalFilter={handleSetLocalFilter}
            //   showFilters={paginationState.pagination.ASSIGNMENTS.showFilters}
            renderItem={(a: IInputEvent) => (
                <div
                    key={a.ExternalEventId}
                    className={`flex cursor-default  ${isMobile ? "" : "hover:!border-solid hover:!border-gray-200 hover:!shadow"
                        } `} //${a.Status === "New" && !isMobile ? "!text-black bg-pink-100 !border-black" : 'bg-white'}
                    style={{
                        border: "1px solid white",
                    }}
                >
                    <InputEventsGridRow enumStoreKey={PaginationEnum.INPUT_EVENTS} headers={headers} item={a} />
                </div>
            )}
        />
    );
}