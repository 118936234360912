import { IconButton} from '@mui/material'
import React from 'react'
import { AiOutlineDeleteColumn } from 'react-icons/ai'
import { PaginationEnum } from '../../../../store/pager'
import { localStorageHidesItemKey, localStorageResizesItemKey } from '../../../../store/hooks/useHeaders'
import { LightTooltip } from '../../../LightTooltip/LightTooltip'
type PROPS = {
    enumKey: PaginationEnum
}

export const ResetResizeAndHidesButton: React.FC<PROPS> = ({ enumKey }) => {
    const handleReset = () => {
        localStorage.setItem(`${enumKey}-${localStorageResizesItemKey}`, JSON.stringify({}))
        localStorage.setItem(`${enumKey}-${localStorageHidesItemKey}`, JSON.stringify({}))
        window.location.reload();
    }
    return <LightTooltip title={<p className='text-xl'>Resetta dimensioni e visiblità colonne al default</p>} arrow>
        <IconButton
            onClick={handleReset}
        >
            <AiOutlineDeleteColumn />
        </IconButton>
    </LightTooltip>
}