import React from "react";
import { TRowHelp } from "../../services/products";
import { FontIcon } from "@fluentui/react";
import useWindowSize from "../../utility/useWindowSize";
import { topbarHeight } from "../DashboardContainer/DashboardContainer";
type Props = {
  isOpen: boolean;
  width: number;
  helps: TRowHelp[];
  elementAttach?: HTMLElement | null;
  growDirection?: "up" | "down";
  overlayBg?:string
  onClose:()=>void
};
const HelpPopup: React.FC<Props> = ({
  isOpen,
  width,
  helps,
  elementAttach,
  growDirection,
  overlayBg,
  onClose,
}) => {
  const { height } = useWindowSize();
  const trianglePositionStyles = React.useMemo<React.CSSProperties>(
    () =>
      growDirection === "up"
        ? {
            borderLeft: "10px solid transparent",
            borderRight: "10px solid transparent",
            borderTop: "10px solid white",
            left: elementAttach
              ? elementAttach.offsetLeft + elementAttach.offsetWidth - 23
              : undefined,
            right: elementAttach ? undefined : 3,
            top: elementAttach
              ? elementAttach.offsetTop
              : "calc(100% + 0px)",
          }
        : {
            borderLeft: "10px solid transparent",
            borderRight: "10px solid transparent",
            borderBottom: "10px solid white",
            left: elementAttach
              ? elementAttach.offsetLeft + elementAttach.offsetWidth - 23
              : undefined,
            right: elementAttach ? undefined : 3,
            top: elementAttach
              ? elementAttach.offsetTop + 1 + 20
              : "calc(100% + 0px)",
          },
    [elementAttach, growDirection]
  );
  const positionStyles = React.useMemo<React.CSSProperties>(
    () =>
      growDirection === "up"
        ? {
            left: elementAttach
              ? elementAttach.offsetLeft + elementAttach.offsetWidth - width
              : undefined,
            right: elementAttach ? undefined : 2,
            bottom:
              elementAttach && height
                ? height - elementAttach.offsetTop - 6 - topbarHeight * 2
                : "calc(100% + 8px)",
            maxHeight: elementAttach
              ? elementAttach.offsetTop - 20
              : 400,
          }
        : {
            left: elementAttach
              ? elementAttach.offsetLeft + elementAttach.offsetWidth - width
              : undefined,
            right: elementAttach ? undefined : 2,
            top: elementAttach
              ? elementAttach.offsetTop + 10 + 20
              : "calc(100% + 8px)",
          },
    [elementAttach, growDirection, width, height]
  );
  if (!isOpen) {
    return null;
  }
  return (
    <>
    <div style={{width:'100vw',height:'100vh',backgroundColor:overlayBg ?? 'rgba(0,0,0,0.2)',zIndex:3,left:0,top:0,position:'fixed',cursor:'pointer'}} onClick={onClose}/>
      <div
        style={{
          width: 0,
          height: 0,
          zIndex: 999999,
          ...trianglePositionStyles,
        }}
        className="absolute border-amber-500"
      />
      <div
        className="absolute border-2 rounded-md border-white bg-gray-100 px-4 py-1"
        style={{
          width: width,
          overflow:'auto',
          zIndex: 999999,
          minWidth: 300,
          maxWidth: "90%",
          minHeight: 100,
          ...positionStyles,
        }}
      >
        {helps.map((s) => (
          <>
            <p className="text-xl text-black">
              {helps.length > 1 && (
                <FontIcon className="!m-0 !pt-2" iconName="RadioBullet" />
              )}
              {s.Item}{s.Description ? ':' : ''}
            </p>
            <p className="text-xl ml-6 text-black">{s.Description}</p>
          </>
        ))}
      </div>
    </>
  );
};
export { HelpPopup };
