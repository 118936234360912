import React from 'react'
import { settingsContext } from '../../../../../store/settingsStore';
import { TMetric } from '../../../../../types/alerts';
import { MiniLoadingSpinner } from '../../../../../components/spinner/MiniLoadingSpinner/MiniLoadingSpinner';
import { MetricExpandableContainer } from '../../MetricExpandableContainer';
import { MetricLeaf } from '../../MetricLeaf';
import { assignments_stepper_elementId } from '../../AssignmentsVisualSteps';
import { topbarHeight } from '../../../../../components/DashboardContainer/DashboardContainer';
import './styles.css'
import { AssignmentCfgHeaders } from '../AssignmentCfgHeaders/AssignmentCfgHeaders';
import { SelectedChips, chipsHeight } from '../SelectedChips/SelectedChips';
import { toolbarHeight } from './Step1Toolbar';
import { Typography } from '@mui/material';
type Props = {
    filterMetrics: (metric: TMetric) => boolean,
    sortMetrics: (a: TMetric, b: TMetric) => number,
    loadingState: { isLoading: boolean }
}
export const Step1List: React.FC<Props> = ({
    loadingState,
    filterMetrics,
    sortMetrics,
}) => {
    const { state: contextState } = React.useContext(settingsContext)
    const { metrics } = contextState;
    console.log(metrics)
    const stepperHeight = document.getElementById(assignments_stepper_elementId)?.getBoundingClientRect().height;
    const filterLeafs = (m: TMetric) => (m.ChildMetrics === undefined || m.ChildMetrics === null || m.ChildMetrics.length === 0)
    const filterParents = (m: TMetric) => (m.ChildMetrics !== undefined && m.ChildMetrics !== null && m.ChildMetrics.length > 0)
    return <>
        <SelectedChips />
        <AssignmentCfgHeaders />
        <div className='w-[100%] border-b border-white mb-1' />
        <div className="flex flex-col h-[100%] overflow-auto border-b-2 border-b-slate-800"
            style={{ maxHeight: `calc(100vh - ${topbarHeight * 3 + toolbarHeight + (stepperHeight ?? topbarHeight * 2) + chipsHeight + 125}px)` }}
        >
            {loadingState.isLoading
                &&
                <div className="w-[100%] flex justify-center">
                    <MiniLoadingSpinner style={{ color: 'white',position:'fixed',width:50,height:50,top:'calc(50vh - 25px)',left:'calc(50vw - 25px)',zIndex:9999999 }} />
                </div>}
            {metrics.filter(filterMetrics).length === 0 && <p className="w-[100%] text-center text-2xl text-white">Nessuna metrica trovata</p>}
            {metrics.filter(filterMetrics).filter(filterParents).sort(sortMetrics).map((m) =>
                <MetricExpandableContainer
                    key={`step1-metric-${m.Id}`}
                    metric={m}
                />)}
            {metrics.filter(filterLeafs).length > 0 && <Typography className='!text-white !text-2xl w-[100%] mt-2 mb-1' align='center'>Metriche non raggruppate</Typography>}
            {metrics.filter(filterMetrics).filter(filterLeafs).sort(sortMetrics).map((m) => <>
                <MetricLeaf
                    key={`step1-metric-${m.Id}`}
                    metric={m}
                />
            </>
            )}
        </div>
    </>


}