import React from "react";
import { RiVideoFill } from "react-icons/ri";
import { TMainDashboardIconProps } from "./types";
import { MainDashboardIcon } from "./MainDashboardIcon";

const PromoVideoIcon: React.FC<TMainDashboardIconProps> = (props) => {
  return (
    <MainDashboardIcon {...props} onClick={() => window.open(process.env.REACT_APP_PROMO_VIDEO_URL ?? '')}>
      <RiVideoFill />
    </MainDashboardIcon>
  );
};
export { PromoVideoIcon };
