import { useMsal } from '@azure/msal-react';
import React from 'react'
import { topbarHeight } from '../../Page/Topbar/Topbar';
import { Avatar, IconButton } from '@mui/material';
import { FaUserCircle } from 'react-icons/fa';
import { navigationContext } from '../../../store/navigationStore';
import { ISidebarOptions } from '../../../types/panels';
import { defaultGraphExplorerSidebarOptions } from '../../GraphExplorer/constants';
import { useUserContext } from '../../../../../store/hooks/useUserContext';
export const UserAvatar: React.FC<{ dropDownExtraStyles?: React.CSSProperties, triangleExtraStyles?: React.CSSProperties }> = ({ dropDownExtraStyles, triangleExtraStyles }) => {
    const [isOpen, setIsOpen] = React.useState(false);
    const { instance } = useMsal();
    const triangleStyle = {
        right: 0,
        top: -4,
        width: 0,
        height: 0,
        borderLeft: '5px solid transparent',
        borderRight: '5px solid transparent',
        borderBottom: '5px solid rgb(235,235,235)',
        zIndex: 1002
    }
    const dropDownStyle = {
        border: '1px solid white',
        backgroundColor: 'rgb(235,235,235)',
        right: 15,
        top: topbarHeight ? topbarHeight - 10 : 30,
        borderRadius: '4px 2px 4px 4px',
        zIndex: 1001
    }
    const handleLogout = () => {
        instance.logout();
    }
    const { state: navState, dispatch: navDispatch } = React.useContext(navigationContext)
    const setSidebarOptions = (opt: ISidebarOptions) => navDispatch({ type: "SET_SIDEBAR_OPTIONS", options: opt })
    const {
        userInfo
    } = useUserContext();
    return <div className="relative cursor-pointer" onClick={(e: any) => { e.stopPropagation(); setIsOpen(!isOpen) }}>
        <Avatar sx={{ bgcolor: "rgb(38,118,1)", border: '1px solid white', borderRadius: '50%', marginRight: 2 }}>{userInfo.account.username.slice(0, 2).toUpperCase()}</Avatar>
        {isOpen &&
            <div className="fixed" style={{ width: '100vw', height: '100vh', left: 0, top: 0, opacity: 1, zIndex: 999999999 }}>
                <div className="py-1 absolute border border-white cursor-default" style={{ ...dropDownStyle, ...dropDownExtraStyles, minHeight: 150 }}>
                    <div className="absolute" style={{ ...triangleStyle, ...triangleExtraStyles }} />
                    <p className=" m-0 px-4 text-lg py-1 border-b border-white" style={{ textTransform: 'capitalize' }}>{userInfo.account.username}</p>
                    {/* <div className='flex flex-col items-start w-[100%] px-2 py-4'>
                        {userInfo.customClaims.map((claim) => <div className='border-b border-dashed items-center flex justify-between'
                            style={{ width: 'calc(100% - 8px)', margin: '0px 4px' }} >
                            <p className="text-lg grow-2" style={{ textTransform: 'capitalize' }}>{claim.ProductId}:</p>
                            <p className="text-base text-right ml-2 grow" style={{ textTransform: 'capitalize' }}>{claim.RuoloId}</p>
                        </div>)}
                    </div> */}
                    <IconButton
                        style={{
                            position: 'absolute',
                            left: 5,
                            bottom: 5,
                            height: 25,
                            width: 25,
                            padding: 0
                        }}
                        onClick={() => setSidebarOptions({ ...defaultGraphExplorerSidebarOptions, isOpen: true })}>
                        <FaUserCircle className='text-white' style={{ width: 25, height: 25 }} />
                    </IconButton>
                    <p
                        style={{
                            position: 'absolute',
                            right: 10,
                            bottom: 5,
                            width: 150,
                            verticalAlign: 'bottom',
                            padding: 0,
                            margin: 0
                        }}
                        className="text-xl text-end hover:underline cursor-pointer border-t border-white" onClick={handleLogout}>Logout</p>
                </div>
            </div>
        }
    </div>
}