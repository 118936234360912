import { createCtx } from "../store/general"
import { IPowerBiEmbedError, I_BIReport } from "../types/powerBi"
export interface IEmbedState {
    error:string
    reportRendered:string|null
}
export const initialEmbedState : IEmbedState = {
    error:'',
    reportRendered:null,
}
type TState = {
    isLoading:boolean
    accessToken:string
    embedAccess?:{[key:string]:boolean}
    embedErrors?:{[key:string]:IPowerBiEmbedError}
    workspace_items:{[key:string]:I_BIReport[]}
    embedState:IEmbedState
}

const initialState: TState = {
    isLoading:false,
    accessToken:'',
    workspace_items:{},
    embedState:initialEmbedState,
}
type AppState = TState;
type Action =
    {type:"SET_STATE",payload:TState} |
    {type:"SET_IS_LOADING",payload:boolean} |
    {type:"SET_ACCESS_TOKEN",payload:string} |
    {type:"SET_EMBED_ERRORS",payload:{[key:string]:IPowerBiEmbedError}} |
    {type:"SET_EMBED_ACCESS",payload:{[key:string]:boolean}} |
    {type:"SET_WORKSPACE_ITEMS",payload:{[key:string]:I_BIReport[]}}|
    {type:"SET_EMBED_STATE",payload:IEmbedState}

function reducer(state: AppState, action: Action): AppState {
    switch (action.type) {
        case "SET_STATE":return action.payload
        case "SET_IS_LOADING":return {...state,isLoading:action.payload}
        case "SET_ACCESS_TOKEN":return {...state,accessToken:action.payload}
        case "SET_EMBED_ERRORS":return {...state,embedErrors:action.payload}
        case "SET_EMBED_ACCESS":return {...state,embedAccess:action.payload}
        case "SET_WORKSPACE_ITEMS":return {...state,workspace_items:action.payload}
        case "SET_EMBED_STATE":return {...state,embedState:action.payload}
        default:
            return state;
    }
}

export const [powerbiContext, PowerBIProvider] = createCtx(reducer, initialState);