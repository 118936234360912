import React from "react";
import {known_products} from "../../types/userStore";
import {
  Alert,
  AlertTitle,
  Button,
  Checkbox,
  Container,
  FormControl,
  IconButton,
  InputLabel,
  MenuItem,
  Select,
  SelectChangeEvent,
  TextField,
} from "@mui/material";
import {
  IDashboardColumnsDimensions,
  getDashboardPagesLocalStorageKey,
  useProductDashboardDimensions,
} from "../../hooks/useProductDashboardDimensions";
import {useNavigate} from "react-router-dom";
import {DefaultButton, PrimaryButton} from "@fluentui/react";
import {BiArrowBack} from 'react-icons/bi'
import {IntesaBottomLogo} from "../../components/IntesaBottomLogo/IntesaBottomLogo";
import {BiReset} from "react-icons/bi";
import {useQuery} from "../../hooks/useQuery";
import {TooltipContainer} from "../../components/TooltipContainer/TooltipContainer";
import {useGlobalStore} from "../../store/hooks/useGlobalStore";
import {
  DashboadSizeEnum,
  IFrontendSettingsGet,
  getAllFrontendSettingsPerProductSize,
} from "../../services/frontendSettings";
import {insertOrUpdateFrontendSettings} from "../../services/frontendSettings";
import {HelpIcon} from "../../components/HelpIcon/HelpIcon";
import { MiniLoadingSpinner } from "../../components/spinner/MiniLoadingSpinner/MiniLoadingSpinner";
import { topbarHeight } from "../../components/DashboardContainer/DashboardContainer";

export interface IExtendedFrontendSetting {
  key: string;
  setting: IFrontendSettingsGet;
}
type Props = {};
const DashboardPageEditor: React.FC<Props> = () => {
  const {setGlobalError} = useGlobalStore();
  const definedSizes = Object.values(DashboadSizeEnum);
  const [selectedSize, setSelectedSize] = React.useState<DashboadSizeEnum>(
    DashboadSizeEnum.MD_DESKTOP
  );
  const [viewMode, setViewMode] = React.useState<"all" | "page">("all");
  const [successMessage, setSuccessMessage] = React.useState<null | string>(
    null
  );
  const [errorMessage, setErrorMessage] = React.useState<null | string>(null);
  const toolbarHeight = topbarHeight;
  const bottombarHeight = toolbarHeight;
  const query = useQuery();
  const areas = Object.values(known_products).map((x) => ({
    key: x.id,
    text: x.dashboard_url,
  }));
  const [selectedArea, setSelectedArea] = React.useState(
    query.get("selected-area") ?? areas[0].key
  );
  const {
    isLoadingSettings,
    setIsLoadingSettings,
    areaSettings,
    setNewSettingsOnStore,
    currentSize,
  } = useProductDashboardDimensions(selectedArea,selectedSize,"filtered_all");
  const areaKey = React.useMemo(()=>getDashboardPagesLocalStorageKey(selectedArea,selectedSize),[selectedArea,selectedSize])
  const [selectedPage, setSelectedPage] = React.useState(0);
  console.log(areaSettings)
  const [state, setState] = React.useState<IDashboardColumnsDimensions|undefined>(areaSettings);
  React.useEffect(()=>setState(areaSettings),[areaSettings])
  const getPageColumns = React.useCallback(
    (p: number, onlyVisiblePages = false) => {
      let pageItems: {key: string; setting: IFrontendSettingsGet}[] = [];
      if(!state){
        return pageItems
      }
      let currPage = 0;
      let usedColumns = 0;
      const entries = onlyVisiblePages
        ? Object.entries(state).filter(([key, setting]) => setting.IsVisible).sort((a,b)=>a[1].UIOrder - b[1].UIOrder)
        : Object.entries(state).sort((a,b)=>a[1].UIOrder - b[1].UIOrder);
      for (let [key, setting] of entries) {
        pageItems.push({key, setting});
        usedColumns += setting.SectionColumns;
        if (usedColumns === 10 && currPage === p) {
          return pageItems;
        }
        if (usedColumns >= 10) {
          currPage++;
          usedColumns = 0;
          pageItems = [];
        }
      }
      return pageItems;
    },
    [state]
  );
  const selectedPageColumns = React.useMemo(() => {
    return getPageColumns(selectedPage);
  }, [getPageColumns, selectedPage]);
  const HelpText = React.useMemo(
    () =>
      "Per un corretto funzionamento delle dashboards, ogni pagina deve avere esattamente 10 colonne.",
    []
  );
  const navigate = useNavigate();
  const handleChange = (
    e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
    x: IExtendedFrontendSetting,
    column: keyof IFrontendSettingsGet
  ) => {
    setState({
      ...state,
      [x.key]: {...x.setting, [column]: parseFloat(e.target.value)},
    });
  };
  const totalPages = React.useMemo(() => {
    if(!state){
      return 0;
    }
    let usedColumns = 0;
    let totalPages = 0;
    for (let setting of Object.values(state)) {
      usedColumns += setting.SectionColumns;
      if (usedColumns >= 10) {
        usedColumns = 0;
        totalPages++;
      }
    }
    return totalPages;
  }, [state]);
  const visiblePages = React.useMemo(() => {
    if(!state){
      return 0;
    }
    let usedColumns = 0;
    let visiblePages = 0;
    for (let setting of Object.values(state).filter(
      (setting) => setting.IsVisible
    )) {
      usedColumns += setting.SectionColumns;
      if (usedColumns >= 10) {
        usedColumns = 0;
        visiblePages++;
      }
    }
    return visiblePages;
  }, [state]);
  const validatePages = React.useCallback(() => {
    for (let i = 0; i < visiblePages; i++) {
      const page = getPageColumns(i, true);
      console.log(page);
      if (
        page
          .filter((x) => x.setting.IsVisible)
          .map((x) => x.setting.SectionColumns)
          .reduce((a, b) => a + b, 0) !== 10
      ) {
        setGlobalError({
          Message: `Vericare pagina ${i + 1}.\n${HelpText}`,
          Details: "",
        });
        return false;
      }
    }
    return true;
  }, [visiblePages, getPageColumns, setGlobalError, HelpText]);
  const handleSave = React.useCallback(
    async (e: any) => {
      if(!state){
        return;
      }
      if (validatePages()) {
        setIsLoadingSettings(true);
        // localStorage.setItem(getDashboardPagesLocalStorageKey(selectedArea, selectedSize), JSON.stringify(state))
        insertOrUpdateFrontendSettings(selectedArea, selectedSize, state)
          .then(() => {
            getAllFrontendSettingsPerProductSize(selectedArea, selectedSize).then(
              (settings) => setNewSettingsOnStore(settings,areaKey)
            );
            setSuccessMessage(
              `Salvato i settings correttamente per la risoluzione ${selectedSize} - area ${selectedArea}`
            );
          })
          .finally(() => setIsLoadingSettings(false));
      }
    },
    [
      selectedArea,
      selectedSize,
      setIsLoadingSettings,
      setNewSettingsOnStore,
      state,
      validatePages,
      areaKey
    ]
  );
  const handleCheckBoxChange = React.useCallback(
    (e: React.ChangeEvent<HTMLInputElement>, x: IExtendedFrontendSetting) => {
      setIsLoadingSettings(true);
      insertOrUpdateFrontendSettings(selectedArea, selectedSize, {
        ...state,
        [x.key]: {
          ...x.setting,
          IsVisible: !x.setting.IsVisible,
        },
      })
        .then(() =>
          getAllFrontendSettingsPerProductSize(selectedArea, selectedSize).then((settings) =>
            setNewSettingsOnStore(settings,areaKey)
          )
        )
        .finally(() => setIsLoadingSettings(false));
    },
    [
      selectedArea,
      selectedSize,
      setIsLoadingSettings,
      setNewSettingsOnStore,
      state,
      areaKey
    ]
  );

  const mapStateItem = (x: IExtendedFrontendSetting) => {
    return (
      <div className={`flex w-[100%] justify-center items-center my-1 border-x ${x.setting.IsVisible ? 'border-white' : 'border-zinc-500'}`}>
        <Checkbox
          checked={x.setting.IsVisible}
          onChange={(e) => handleCheckBoxChange(e, x)}
        />
        <p className="w-[150px]">{x.key}:</p>
        <TextField
          size="small"
          variant="outlined"
          type="number"
          value={x.setting.SectionColumns}
          InputLabelProps={{
            style: {
              color: "white",
              paddingRight: 10,
              paddingLeft: 10,
            },
          }}
          inputProps={{
            style: {
              color: "white",
              backgroundColor: "rgb(51,65,85)",
              borderRadius: 8,
              paddingRight: 10,
              paddingLeft: 10,
              width: 100,
            },
          }}
          className="!border-slate-800 !mx-2"
          onChange={(e) => handleChange(e, x, "SectionColumns")}
        />
        <TextField
          size="small"
          variant="outlined"
          type="number"
          value={x.setting.UIOrder}
          InputLabelProps={{
            style: {
              color: "white",
              paddingRight: 10,
              paddingLeft: 10,
            },
          }}
          InputProps={{
            style: {
              color: "white",
              backgroundColor: "rgb(51,65,85)",
              borderRadius: 8,
              paddingRight: 10,
              paddingLeft: 10,
              width: 100,
            },
          }}
          className="!border-slate-800 !text-center !mx-2"
          onChange={(e) => handleChange(e, x, "UIOrder")}
        />
      </div>
    );
  };
  const mapStateItems = (items: IExtendedFrontendSetting[]) => {
    let usedColumns = 0;
    let currPage = viewMode === "all" ? 1 : selectedPage + 1;
    let result: React.ReactNode = (
      <>
        <div className="flex w-[100%] justify-center items-center py-2 mb-4 relative border bg-slate-700 rounded-lg">
          <p className="text-2xl font-bold w-[200px]">Sezione</p>
          <TooltipContainer
            customContentStyles={{
              content: {
                fontSize: 20,
                color: "black",
              },
            }}
            tooltip={{
              text: HelpText,
              id: "Dashboard-page-editor-help",
            }}
          >
            <div className="flex items-center w-[100px]">
              <p className="text-2xl font-bold">Colonne </p>
              <HelpIcon style={{marginLeft: 10, height: 20, width: 20}} />
            </div>
          </TooltipContainer>
          <p className="text-xl font-bold ml-4">Posizione</p>
          <p
            style={{position: "absolute", right: 10,width:100}}
            className="text-2xl font-bold"
          >
            Pagina
          </p>
        </div>
      </>
    );
    const sortItems = (
      a: IExtendedFrontendSetting,
      b: IExtendedFrontendSetting
    ) => a.setting.UIOrder - b.setting.UIOrder;
    let newPage = true;
    for (let item of items.sort(sortItems)) {
      if (viewMode === "all" && newPage) {
        result = (
          <>
            {result}
            <p className="w-[100%] text-xl border-b font-bold text-right">
              {currPage}
            </p>
          </>
        );
        newPage = false;
      }
      result = (
        <>
          {result}
          {mapStateItem(item)}
        </>
      );

      usedColumns += item.setting.IsVisible ? item.setting.SectionColumns : 0;
      if (usedColumns >= 10) {
        usedColumns = 0;
        newPage = true;
        currPage++;
      }
    }
    return result;
  };

  return (
    <>
      <div
        className="flex w-[100%] bg-slate-800 items-center justify-between text-white border-b border-white !z-[999]"
        style={{position: "fixed", height: toolbarHeight}}
      >
        <IconButton
          style={{
            width: 40,
            height: toolbarHeight - 10,
            top: 5,
            objectFit: "contain",
          }}
          className="!rounded hover:bg-slate-600 p-6 bg-slate-700"
          onClick={() => navigate(`${selectedArea ? "/" + selectedArea : "/"}`)}
        >
          <BiArrowBack className="text-2xl text-white"/>
        </IconButton>
        <p
          className="px-2 bg-slate-700 text-center rounded py-1 text-2xl font-bold"
          style={{
            height: toolbarHeight - 10,
            marginTop: 5,
            border: "1px solid rgb(148,163,184)",
          }}
        >
          Modifica Dimensioni Pagine
        </p>
        {/* <LoadingSpinner show={isLoadingSettings}/> */}
        {isLoadingSettings && <MiniLoadingSpinner containerClassNames="fixed top-[70px] right-[20px] z-[10000] text-white" style={{color:'white'}}/>}
        <div className="w-[50px] mr-8" />
        {successMessage && (
          <Alert
            onClose={() => {
              setSuccessMessage(null);
            }}
            style={{zIndex: 1000000, position: "fixed", right: 10, top: 10}}
            severity="success"
          >
            <AlertTitle>Perfetto!</AlertTitle>
            <p className="text-xl">{successMessage}</p>
          </Alert>
        )}
        {errorMessage && (
          <Alert
            onClose={() => {
              setErrorMessage(null);
            }}
            style={{zIndex: 10000000, position: "fixed", right: 10, top: 10}}
            severity="error"
          >
            <AlertTitle>Errore!</AlertTitle>
            <p className="text-xl">{errorMessage}</p>
          </Alert>
        )}
      </div>
      <div
        className="w-[100%] text-white"
        style={{
          backgroundColor: "rgb(70,96,129)",
          height: `calc(100vh - ${bottombarHeight}px)`,
          padding: `${toolbarHeight + 20}px 0px`,
          overflowY: "auto",
        }}
      >
        <Container>
          <FormControl className="w-[100%]">
            <InputLabel className="!text-white" id="product-select-label">
              Prodotto
            </InputLabel>
            <Select
              value={selectedArea}
              variant="outlined"
              className="!my-2 !text-white bg-slate-700 !text-2xl !pl-4"
              fullWidth
              label="Prodotto"
              labelId="product-select-label"
              id="product-select"
              onChange={(e: SelectChangeEvent) =>
                setSelectedArea(e.target.value as string)
              }
            >
              {Object.entries(known_products).map((at) => (
                <MenuItem value={at[1].id} className="!pl-4">
                  {at[0]}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
          <div className="flex flex-col h-[100%]">
            <div className="flex items-center flex-wrap w-[100%] justify-between my-4">
              {definedSizes.map((x) => (
                <Button
                  onClick={() => setSelectedSize(x)}
                  variant={selectedSize === x ? "contained" : "outlined"}
                  className={
                    selectedSize === x
                      ? `!my-2 !text-white ${currentSize === x ? "!border-amber-400" : '!border-white'}`
                      : `!my-2 !text-gray-400 ${currentSize === x ? "!border-amber-400" : '!border-white'}`
                  }
                  
                >
                  {x}
                </Button>
              ))}
            </div>
            <div className="flex items-center w-[100%] justify-between my-4">
              <div className="flex items-center">
                <Button
                  onClick={() => setViewMode("all")}
                  variant={viewMode === "all" ? "contained" : "outlined"}
                  className={
                    viewMode === "all"
                      ? "!text-white !mx-2"
                      : "!border-white !text-gray-400 !mx-2"
                  }
                >
                  Tutto
                </Button>
                <Button
                  onClick={() => setViewMode("page")}
                  className={
                    viewMode === "page"
                      ? "!text-white !mx-2"
                      : "!border-white !text-gray-400 !mx-2"
                  }
                  variant={viewMode === "page" ? "contained" : "outlined"}
                >
                  Pagina
                </Button>
              </div>
              {viewMode === "page" && (
                <div className="flex items-center">
                  <p className="text-xl text-center">Pagina:</p>
                  <IconButton
                    className="!bg-transparent !hover:bg-transparent !mx-2"
                    onClick={() =>
                      selectedPage <= 0 ? {} : setSelectedPage(selectedPage - 1)
                    }
                  >
                    <p className="text-2xl text-white">-</p>
                  </IconButton>
                  <p className="text-xl text-center">{selectedPage + 1}</p>
                  <IconButton
                    className="!bg-transparent !hover:bg-transparent !mx-2"
                    onClick={() =>
                      selectedPage + 1 > totalPages - 1
                        ? {}
                        : setSelectedPage(selectedPage + 1)
                    }
                  >
                    <p className="text-2xl text-white">+</p>
                  </IconButton>
                </div>
              )}
              <IconButton
                className="!text-white !bg-transparent !hover:bg-transparent !ml-10"
                onClick={() => setState(areaSettings)}
              >
                <BiReset />
              </IconButton>
            </div>
            {mapStateItems(
              viewMode === "all" && state
                ? Object.entries(state).map((e) => ({key: e[0], setting: e[1]}))
                : selectedPageColumns
            )}
            {selectedArea && (
              <div className="w-[100%] flex justify-between mt-4">
                <DefaultButton
                  className="px-8 py-6 rounded text-xl"
                  onClick={() => setState(areaSettings)}
                  text="Annulla"
                />
                <DefaultButton
                  className="px-8 py-6 rounded text-xl"
                  onClick={() =>
                    getAllFrontendSettingsPerProductSize(selectedArea,selectedSize).then((res)=>setNewSettingsOnStore(res,areaKey))
                  }
                  text="Reset"
                />
                <PrimaryButton
                  className="px-8 py-6 rounded !bg-slate-700 !text-white text-xl"
                  onClick={handleSave}
                  text="SALVA"
                />
              </div>
            )}
          </div>
        </Container>
        <div
          className="flex w-[100%] bg-intesa-green items-center text-white border-t border-white"
          style={{position: "fixed", bottom: 0, height: bottombarHeight}}
        >
          <IntesaBottomLogo customLeft={"0px"} sidebarWidth={0} />
        </div>
      </div>
    </>
  );
};
export {DashboardPageEditor};
