import React from 'react'
import { Page } from '../../components/Page/Page'
import { useQuery } from '../../utility/useQuery';
import { SuperMetricEventDetail } from '../../components/SuperMetricEventDetail/SuperMetricEventDetail';
export const SuperMetricEventDetailPage: React.FC = () => {
    const query = useQuery();
    const id = parseInt(query.get("id") ?? "-1") ?? -1
    return <Page>
        <SuperMetricEventDetail id={id} />
    </Page>
}