import React from "react";
import { FlexCol } from "../../Flex/FlexCol";
import { FlexRow } from "../../Flex/FlexRow";
import { DefaultButton, FontIcon, IconButton } from "@fluentui/react";
import { DashboardTextInput } from "../../DashboardTextInput/DashboardTextInput";
import { MiniLoadingSpinner } from "../../spinner/MiniLoadingSpinner/MiniLoadingSpinner";
import { TAlertRowActionState, TAlertRowState } from "../AlertRow";
import {
  NotificationTypes,
  TAlert,
} from "../../../types/alerts";
import { useUserContext } from "../../../store/hooks/useUserContext";
import {
  Button,
  Checkbox,
  FormControl,
  InputLabel,
  ListItemText,
  MenuItem,
  OutlinedInput,
  TextField,
} from "@mui/material";
import Select, { SelectChangeEvent } from "@mui/material/Select";
import { useGraphAuthentication } from "../../../hooks/useGraphAuthentication";
import { IUserInfo, TPlannerUsersDictResponse } from "../../../types/user";
import { useNavigationContext } from "../../../store/hooks/useNavigationContext";
import { AxiosError } from "axios";
import { useDashboardsNavigation } from "../../../store/hooks/useDashboardsNavigation";
import { CreateAlertTask, GetPlannerUsers, ISendMailNotification, ISendTaskNotification, SendAlertMail } from "../../../services/alerts";
interface FormError {
  msg: string;
  action?: React.ReactNode;
}
type Props = {
  alert: TAlert;
  state: TAlertRowState;
  setState: (x: TAlertRowState) => void;
  alertState: TAlertRowActionState;
  setAlertState: (x: TAlertRowActionState) => void;
  metricName: string;
  type: "mail" | "task" | "detail" | null;
};
type TPopupState = {
  input_type: "combo" | "text";
  targetUsers: string[];
  emailText: string;
  UserNote: string;
  error: string;
};
const AlertActionPopup: React.FC<Props> = ({
  metricName,
  state,
  setState,
  alert,
  alertState,
  setAlertState,
  type,
}) => {
  const { userInfo } = useUserContext();
  const {getProductIdFromPath} = useDashboardsNavigation();
  const productId = React.useMemo(() => getProductIdFromPath(), [getProductIdFromPath]);
  const { getAccessToken } = useGraphAuthentication({
    loadConditions: false,
    setIsLoading: (b: boolean) => setState({ ...state, isPopupLoading: b }),
    graphTokenSelector: (u: IUserInfo) => u.graphAccessToken,
    loadItems: (t: string) => {},
  });
  const [gettingGraphToken, setGettingGraphToken] = React.useState(false);
  const [popupState, setPopupState] = React.useState<TPopupState>({
    input_type: "text",
    targetUsers: [],
    emailText: "",
    UserNote: "",
    error: "",
  });
  React.useEffect(()=>{
    if(type === "mail" && popupState.input_type !== "text"){
      setPopupState({
        ...popupState,
        targetUsers:[],
        input_type:"text"
      })
    }
  },[popupState, type])
  const [activateDisabledExplanations, setActivateDisabledExplanations] =
    React.useState(false);
  const HandleAddEmail = React.useCallback(
    (e: any) => {
      setActivateDisabledExplanations(true);
      const isValidEmail = (email: string) => {
        if (!email.includes("@")) {
          return false;
        }
        if (
          !email.endsWith(".com") &&
          !email.endsWith(".it") &&
          !email.endsWith(".net")
        ) {
          return false;
        }
        return true;
      };
      if (e.target.value && isValidEmail(e.target.value)) {
        if (!popupState.targetUsers.includes(e.target.value)) {
          setPopupState({
            ...popupState,
            targetUsers: [...popupState.targetUsers, e.target.value],
            error: "",
          });
        }
      }
    },
    [popupState]
  );
  const handleRemoveEmail = React.useCallback(
    (s: string) => {
      setPopupState({
        ...popupState,
        targetUsers: popupState.targetUsers.filter((x) => x !== s),
      });
    },
    [popupState]
  );
  const handleMailKeyPress = (e: any) => {
    setPopupState({ ...popupState, error: "" });
    if (e.key === "Enter") {
      HandleAddEmail(e);
    }
  };
  const handlePopupChange = React.useCallback(
    (e: any) => {
      switch (e.target.name) {
        case "emailText": {
          setPopupState({ ...popupState, emailText: e.target.value });
          break;
        }
        case "notes": {
          setPopupState({ ...popupState, UserNote: e.target.value });
          break;
        }
        default:
          console.error(e.target.name, " not managed");
      }
    },
    [popupState]
  );
  const handlePopupSubmit = React.useCallback(() => {
    if (!userInfo.graphAccessToken || userInfo.graphAccessToken === "") {
      setAlertState({
        msg: "Graph token is required",
        error: true,
        success: false,
      });
      return;
    }
    const { targetUsers, UserNote } = popupState;
    switch (type) {
      case "mail": {
        setState({ ...state, isPopupLoading: true });

        const payload: ISendMailNotification = {
          AlertId: alert.Id,
          targetUsers,
          UserNote,
          GraphAPIUserToken: userInfo.graphAccessToken,
          NotificationType: NotificationTypes.Mail,
          ProductId: productId,
        };
        SendAlertMail(payload)
          .then(() =>
            setAlertState({
              msg: "Mail inviata con successo",
              error: false,
              success: true,
            })
          )
          .catch((err: AxiosError) =>
            {
              const respData  = err.response?.data as {Message:string}
            setAlertState({
              msg: "Errore " + respData.Message,
              error: true,
              success: false,
            })}
          )
          .finally(() => {
            setState({
              ...state,
              isOpen: false,
              popupOpen: null,
              isPopupLoading: false,
            });
            closeSidebar();
          });
        break;
      }
      case "task": {
        setState({ ...state, isPopupLoading: true });
        const payload: ISendTaskNotification = {
          AlertId: alert.Id,
          targetUsers,
          UserNote,
          GraphAPIUserToken: userInfo.graphAccessToken,
          NotificationType: NotificationTypes.Task,
          ProductId: productId,
        };
        CreateAlertTask(payload)
          .then(() =>
            setAlertState({
              msg: "Task creato con successo",
              error: false,
              success: true,
            })
          )
          .catch((err: any) =>
            setAlertState({
              msg: "Errore " + err.toString(),
              error: true,
              success: false,
            })
          )
          .finally(() => {
            setState({
              ...state,
              isOpen: false,
              popupOpen: null,
              isPopupLoading: false,
            });
          });
        break;
      }
      default:
        return;
    }
  }, [
    alert,
    popupState,
    productId,
    setAlertState,
    setState,
    state,
    type,
    userInfo.graphAccessToken,
  ]);
  const [graphUsers, setGraphUsers] = React.useState<TPlannerUsersDictResponse>({});
  React.useEffect(() => {
    if (!userInfo.graphAccessToken) return;
    GetPlannerUsers(userInfo.graphAccessToken)
      .then((res:TPlannerUsersDictResponse) => setGraphUsers(res))
      .catch((err: AxiosError) => console.log(err));
  }, [userInfo.graphAccessToken]);
  const handleEmailChange = (
    event: SelectChangeEvent<typeof popupState.targetUsers>
  ) => {
    setActivateDisabledExplanations(true);
    const {
      target: { value },
    } = event;
    setPopupState({
      ...popupState,
      targetUsers:
        // On autofill we get a stringified value.
        typeof value === "string" ? value.split(",") : value,
    });
  };
  const { setSidebarIsOpen } = useNavigationContext();
  const closeSidebar = () => {
    setSidebarIsOpen(false);
  };
  const disabledExplanations = React.useMemo<FormError[]>(() => {
    let result: FormError[] = [];
    if (!activateDisabledExplanations) return result;
    if (!userInfo.graphAccessToken) {
      result.push({
        msg: "Graph token non valido o blank",
        action: (
          <IconButton
            disabled={gettingGraphToken}
            className="bg-white rounded-lg px-2 py-1 hover:bg-white text-slate-800"
            onClick={() => {
              setGettingGraphToken(true);
              getAccessToken(true, "graph").finally(() =>
                setGettingGraphToken(false)
              );
            }}
          >
            <FontIcon
              iconName="StatusCircleSync"
              style={{ fontSize: "2rem" }}
            />
          </IconButton>
        ),
      });
    }
    if (popupState.targetUsers.length === 0) {
      result.push({ msg: "Inserisci almeno un email" });
    }
    return result;
  }, [popupState, userInfo.graphAccessToken, activateDisabledExplanations]);
  
  return (
    <>
      {/* <div className="h-[100vh] w-[100vw] fixed cursor-pointer" onClick={() => setState({ ...state, popupOpen: null })} style={{ backgroundColor: 'rgba(0,0,0,0.3)', zIndex: 99997, left: 0, top: 0 }} /> */}
      <FlexCol
        className="h-[100%] bg-slate-800 p-4 border text-white"
        style={{
          // position: 'fixed',
          // top: '10%',
          // left: popupLeft,
          // maxHeight: '80%',
          overflowY: "auto",
          overflowX: "hidden",
          // minHeight: '50%',
          // maxWidth: popupMaxWidth,
          // width: popupWidth,
          zIndex: 999,
        }}
      >
        {/* <FlexRow alignItems='center' className="w-[100%]"> */}
        <FlexRow
          className="w-[100%] mb-4 border-b"
          alignItems="center"
          justifyContent="space-between"
        >
          <p className="text-center w-[100%] text-xxl">
            {type === "mail" ? "Invia Email" : "Crea Task"} per {metricName}
          </p>
          <IconButton
            onClick={closeSidebar}
            className="ml-auto hover:bg-transparent"
          >
            <FontIcon className="text-xl text-white" iconName="ChromeClose" />
          </IconButton>
        </FlexRow>
        {type === "task" &&
          <div className="w-[100%] flex flex-row px-2 items-center">
          <p className="text-lg">Input: </p>
          <Button
            className="!mx-auto"
            variant={
              popupState.input_type === "combo" ? "contained" : "outlined"
            }
            onClick={() =>
              setPopupState({
                ...popupState,
                input_type: "combo",
                error: "",
              })
            }
          >
            Selezione
          </Button>
          <Button
            className="!mx-auto"
            variant={
              popupState.input_type === "text" ? "contained" : "outlined"
            }
            onClick={() =>
              setPopupState({
                ...popupState,
                input_type: "text",
                error: "",
              })
            }
          >
            Testo
          </Button>
        </div>}

        {popupState.input_type === "text" && (
          <>
            <FormControl
              sx={{
                m: 1,
                width: "100%",
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
              }}
            >
              <DashboardTextInput
                inputType="text"
                name="emailText"
                onChange={handlePopupChange}
                onBlur={HandleAddEmail}
                onKeyDown={handleMailKeyPress}
                value={popupState.emailText}
                label="Aggiungi Destinatari:"
                labelCustomClassNames="text-lg mr-2 min-w-[200px]"
                placeholder="e-mail"
              />
            </FormControl>
          </>
        )}
        {popupState.input_type === "combo" && (
          <>
            <FormControl
              sx={{
                m: 1,
                width: "100%",
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
              }}
            >
              <InputLabel className="!text-white" id="user-select-label">
                Destinatari
              </InputLabel>
              <Select
                // fullWidth
                // multiple
                // labelId='user-select-label'
                // id="user-select"
                // input={<OutlinedInput label="Name" />}
                // value={popupState.emails}
                // onChange={(e: SelectChangeEvent) => HandleAddEmail(e.target.value)}
                className="w-[100%] !my-2 !text-white bg-slate-600 !border-white"
                fullWidth
                labelId="demo-multiple-name-label"
                id="demo-multiple-name"
                multiple
                disabled={Object.values(graphUsers).length === 0}
                value={popupState.targetUsers}
                onChange={handleEmailChange}
                input={<OutlinedInput label="Destinatari" />}
                renderValue={(selected) => selected.join(", ")}
              >
                {Object.entries(graphUsers).map(([id,mail]) => (
                  <MenuItem key={id} value={mail}>
                    <Checkbox
                      checked={popupState.targetUsers.indexOf(mail) > -1}
                    />
                    <ListItemText primary={mail} />
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </>
        )}
        {popupState.error && (
          <p className="text-red-500 text-xxl text-center w-[100%]">
            {popupState.error}
          </p>
        )}
        {popupState.input_type === "text" &&
          popupState.targetUsers.length > 0 && (
            <>
              <FlexRow
                alignItems="center"
                className="w-[100%] flex-wrap border-y py-4 my-2"
              >
                {popupState.targetUsers.map((x) => (
                  <p className="text-xl text-white mx-2">
                    {x}
                    <IconButton
                      onClick={() => handleRemoveEmail(x)}
                      className="rounded-full text-white bg-slate-800 hover:bg-slate-800 hover:text-white mx-2"
                    >
                      <FontIcon iconName="ChromeClose" />
                    </IconButton>
                  </p>
                ))}
              </FlexRow>
            </>
          )}
        <FormControl sx={{ m: 1, width: "100%" }}>
          <TextField
            label="Note"
            type="text"
            variant="outlined"
            InputLabelProps={{
              style: {
                color: "white",
              },
            }}
            inputProps={{
              style: {
                color: "white",
              },
            }}
            className="w-[100%] !my-2 !text-white bg-slate-600 !border-white"
            multiline
            rows={6}
            name="notes"
            onChange={handlePopupChange}
            value={popupState.UserNote}
            placeholder="Note..."
          />
        </FormControl>
        {/* {!userInfo.graphAccessToken && activateDisabledExplanations && (
          <div className="flex w-[100%] items-center justify-end px-2">
            <p className="text-lg mr-2">Get a new Graph Token</p>
            <IconButton
              disabled={gettingGraphToken}
              className="bg-white rounded-lg px-2 py-1 hover:bg-white text-slate-800"
              onClick={() => {
                setGettingGraphToken(true);
                getAccessToken(true, "graph").finally(() =>
                  setGettingGraphToken(false)
                );
              }}
            >
              <FontIcon
                iconName="StatusCircleSync"
                style={{ fontSize: "2rem" }}
              />
            </IconButton>
          </div>
        )} */}
        <FlexRow
          className="w-[100%] px-auto mt-2 p-2 mb-2"
          alignItems="center"
          justifyContent="space-between"
        >
          <DefaultButton
            className="mt-auto text-xxl px-4 py-6 rounded self-start bg-white hover:bg-white hover:text-black text-black"
            text={"Annulla"}
            onClick={closeSidebar}
          />
          {state.isPopupLoading && (
            <MiniLoadingSpinner style={{ color: "white" }} />
          )}
          <DefaultButton
            disabled={
              popupState.targetUsers.length === 0 || !userInfo.graphAccessToken
            }
            className={
              popupState.targetUsers.length === 0 || !userInfo.graphAccessToken
                ? "mt-auto text-xxl px-4 py-6 rounded self-end bg-gray-600 hover:bg-gray-600 text-white"
                : "mt-auto text-xxl px-4 py-6 rounded self-end bg-intesa-green hover:bg-intesa-green hover:text-white text-white"
            }
            text={type === "mail" ? "Invia email" : "Crea task"}
            onClick={handlePopupSubmit}
          />
        </FlexRow>
        {disabledExplanations.map((s) => (
          <div className="flex w-[100%]">
            <p className="text-red-400 text-base px-2 text-center">
              {s.msg}
            </p>
            {s.action}
          </div>
        ))}
      </FlexCol>
    </>
  );
};
export { AlertActionPopup };
