/* eslint-disable jsx-a11y/img-redundant-alt */
import React from "react";
import { Pile } from "../../Pile/Pile";
import { hasPermission } from "../../../store/hooks/useUserStore";
import { known_actions } from "../../../types/userStore";
import { useUserContext } from "../../../store/hooks/useUserContext";
import { assignments_known_urls } from "../../../apps/assignments/types/userStore";
type Props = {
  productId: string;
};
const SBAssignmentsElement: React.FC<Props> = ({ productId }) => {
  const getImg = React.useCallback(
    (imgName: string, isReport?: boolean, imgRows?: number) => {
      const height = imgRows ? `${imgRows * 10}%` : "100%";
      try {
        // const fileName = isReport ? '../../../svg/reports/' + imgName : '../../../svg/' + imgName
        if (isReport) {
          return (
            <img
              style={{
                height,
                objectFit: "cover",
                borderRadius: 4,
                margin: "4px 2px 0px 2px",
              }}
              src={imgName ? require("../../../images/reports/" + imgName) : ""}
              alt="Image Missing"
            />
          );
        } else {
          return (
            <img
              style={{
                height,
                objectFit: "cover",
                borderRadius: "8px 8px 0px 0px",
                margin: "0px 0px 0px 0px",
              }}
              src={
                imgName ? require("../../../images/services/" + imgName) : ""
              }
              alt="Image Missing"
            />
          );
        }
      } catch (error) {
        console.log(error);
        return (
          <img
            src=""
            style={{ height, objectFit: "cover" }}
            alt={`Image for ${imgName} Missing`}
          />
        );
      }
    },
    []
  );
  const {userClaims} = useUserContext();
  if(!hasPermission(userClaims,known_actions.assignments)){
    return null;
  }
  return <Pile
    id={"Segnalazioni"}
    productId={productId}
    key={"SBAssignments"}
    customColor
    titleCustomBgColor='rgb(22 101 52)'
    showHelp
    tooltip={{
      id: 'SBAssignments',
      text: "Applicazione per gestione attività che richiedono l'attenzioni degli utenti destinatari"
    }}
    className="relative shadow-xl m-2 cursor-pointer rounded-lg bg-green-800 hover:!bg-green-700 hover:!text-white"
    img={getImg("assignments/SBAssignments.png")}
    titleSize='xxl'
    size="x1"
    // openInNewTab
    // targetUrl={"/assignments/index"}
    targetUrl={assignments_known_urls.home}
    title={"Segnalazioni SBH"}
  />
};
export { SBAssignmentsElement };
