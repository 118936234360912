import React from 'react'
import { HeaderFilterProps } from '../types';
import { TextHeaderFilterCell } from './TextHeaderFilterCell/TextHeaderFilterCell';
import { DateTimeHeaderFilterCell } from './DateTimeHeaderFilterCell/DateTimeHeaderFilterCell';
import { EnumHeaderFilterCell } from './EnumHeaderFilterCell/EnumHeaderFilterCell';
import { DateRangeHeaderFilterCell } from './DateRangeHeaderFilterCell/DateRangeHeaderFilterCell';
import { BooleanHeaderFilterCell } from './BooleanHeaderFilterCell/BooleanHeaderFilterCell';
export const HeaderFilterCell: React.FC<HeaderFilterProps> = (props) => {
    if(props.header.hideFilter){
        return <div style={{ margin: '0px 0px 2px 0px',height:55 }}></div>
    }
    
    if (props.header.customFilterCell) {
        return props.header.customFilterCell(props) 
    } else {
        switch(props.filterValueType){
            case "string":return <TextHeaderFilterCell {...props} />
            case "datetime":return <DateTimeHeaderFilterCell {...props} />
            case "daterange":return <DateRangeHeaderFilterCell {...props} />
            case "enum":return <EnumHeaderFilterCell {...props} />
            case "boolean":return <BooleanHeaderFilterCell {...props} />
            default:return <TextHeaderFilterCell {...props} />
        }
    }
}