import React from "react";
import { TAssignment } from "../../../types/assignments";
import { Typography } from "@mui/material";
import { AssignmentCard } from "./AssignmentCard/AssignmentCard";
import { ISortableHeader } from "../../../types/general";
import { useIsMobile } from "../../../utility/useIsMobile";
import { ODataGridRowCell } from "../../ODataGrid/ODataGridRow/ODataGridRowCell/ODataGridRowCell";
import { ODataGridRow } from "../../ODataGrid/ODataGridRow/ODataGridRow";
import { PaginationEnum } from "../../../store/pager";
import { GridRowProps } from "../../../types/odata";

export const AssignmentRow: React.FC<GridRowProps<TAssignment>> = ({
  item: assignment,
  headers,
  enumStoreKey
}) => {
  return <ODataGridRow
    enumStoreKey={enumStoreKey}
    item={assignment}
    headers={headers}
    itemContainerCustomClassNames={(x) => x.Status === "New"
      ? "border-none"
      : "border-gray-300"
    }
    itemCustomClassNames={(x) => x.Status === "New"
      ? "bg-white"
      : "bg-gray-100"
    }
    customGridCard={<AssignmentCard assignment={assignment} headers={headers} />}
  />
};
