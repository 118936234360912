import React from "react";
import {IPile} from "../../store/hooks/usePiles";
import {LinearProgress, Typography} from "@mui/material";
import {isMobile} from "react-device-detect";
import {useNavigate} from "react-router-dom";
import {paginationContext} from "../../store/pager";
import {LightTooltip} from "../LightTooltip/LightTooltip";
import { useUserContext } from "../../../../store/hooks/useUserContext";
type Props = {
  pile: IPile;
};
export const Pile: React.FC<Props> = ({pile}) => {
  const {accessToken} = useUserContext();
  const {dispatch} = React.useContext(paginationContext);
  const [isLoading, setIsLoading] = React.useState(false);
  const [count, setCount] = React.useState(0);
  React.useEffect(() => {
    console.log(pile,count)
    const get_count = async () => {
      if (accessToken) {
        setIsLoading(true);
        pile
          .requestAction(pile.filterPresets.map((preset) => preset.filter))
          .then((res) => setCount(res["odata.count"] ?? 0))
          .finally(() => setIsLoading(false));
      }
    };
    if (!count) {
      get_count();
    }
  }, [accessToken, pile]);
  const navigate = useNavigate();
  const handleClick = () => {
    dispatch({
      type: "SET_FILTERS_PRESETS",
      payload: {
        key: pile.storageEnumKey,
        presets: pile.filterPresets,
      },
    });
    navigate(pile.targetUrl)
    // navigate(`${pile.targetUrl}/[]/${stringifyPresets(pile.filterPresets)}`);
  };
  const bg_classNames = pile.customColorClassName ? pile.customColorClassName(count) : "bg-intesa-green text-white"
  return (
    <LightTooltip
      placement="top-end"
      sx={{fontSize: "1.2rem"}}
      slotProps={{
        popper: {
          modifiers: [
            {
              name: "offset",
              options: {
                offset: [0, -14],
              },
            },
          ],
        },
      }}
      title={pile.help}
    >
      <div
        onClick={handleClick}
        className={`flex flex-col relative cursor-pointer items-center shadow hover:shadow-lg shadow-gray-700 ${bg_classNames}`}
        style={{
          aspectRatio: 4 / 3,
          minWidth: isMobile ? "calc(50% - 5px)" : 250,
          flexGrow:1,
          height: isMobile ? 150 : 175,
          margin: 2,
        }}
      >
        {isLoading && (
          <LinearProgress
            style={{
              position: "absolute",
              right: 5,
              bottom: 5,
              color: "white",
              width: 20,
            }}
          />
        )}
        <Typography
          fontSize={isMobile ? '5rem':"6rem"}
          // variant="h1"
          fontWeight={"bold"}
          style={{flexGrow: 4,lineHeight:1.1,margin:0,padding:0}}
        >
          {count}
        </Typography>
        <Typography
          fontSize={isMobile ? '0.9rem' : "1.35rem"}
          align="center"
          style={{flexGrow: 1,margin:0,padding:0, verticalAlign: "baseline", maxWidth: "80%"}}
        >
          {pile.title}
        </Typography>
        {pile.subtitle && <Typography
          fontSize={isMobile ? '0.65rem' : "0.8rem"}
          align="center"
          style={{flexGrow: 1,margin:0,padding:0, verticalAlign: "baseline", maxWidth: "80%"}}
        >
          {pile.subtitle}
        </Typography>}
      </div>
    </LightTooltip>
  );
};
