import { FontIcon, IconButton } from '@fluentui/react';
import React from 'react';
import './styles.css'
import { EditableAssets, SearchAssets } from '../../store/dashboardsStore';
import { useUserContext } from '../../store/hooks/useUserContext';
import { hasPermission } from '../../store/hooks/useUserStore';
import { known_actions, sbh_area } from '../../types/userStore';
import { FlexRow } from '../Flex/FlexRow';
import { AiOutlineClose } from 'react-icons/ai'
import { InputAdornment, TextField } from '@mui/material';
import { useDashboardsStore } from '../../store/hooks/useDashboardsStore';
import useWindowSize from '../../utility/useWindowSize';
import { SELECTED_ICON_QUERY_KEY, useQueryValues } from '../../hooks/useQueryValues';
    import { IFrontendSettingsGet } from '../../services/frontendSettings';
import { ProductPageSection } from '../../hooks/useProductDashboardDimensions';
type Props = {
    icon: React.ReactNode,
    text: string,
    settings?: IFrontendSettingsGet,
    editable: boolean,
    headerType?: "specialReports" | "reports" | "scorecards",
    searchKey?:SearchAssets
    handleSearchChange?: (e: React.ChangeEvent<HTMLInputElement>) => void
    setSearchValue?:(s:string)=>void
    id:ProductPageSection
}
const DashboardHeaderText: React.FC<Props> = ({ id, searchKey,icon, text, settings, editable, headerType, handleSearchChange, setSearchValue }) => {
    const {
        contextState:state,
        contextDispatch:dispatch,
        search
    } = useDashboardsStore();
    const searchValue = searchKey ? search[searchKey] : '';
    const {
        userClaims
    } = useUserContext();
    const handleAddClick = (e: any) => {
        if (!headerType) return;
        dispatch({ type: 'TOGGLE_ADD_MODE', payload: headerType })

    }
    const handleEditClick = (e: any) => {
        if (!headerType) return;
        dispatch({ type: 'TOGGLE_EDIT_MODE', payload: headerType })
    }
    const iconName = React.useMemo(() => {
        if (!headerType) return '';
        switch (headerType) {
            case "reports": return state.editMode[EditableAssets.REPORTS] ? "MobileSelected" : "Edit"
            case "scorecards": return state.editMode[EditableAssets.SCORECARDS] ? "MobileSelected" : "Edit"
            case "specialReports": return state.editMode[EditableAssets.SPECIAL_REPORTS] ? "MobileSelected" : "Edit"
            default:
                return 'Edit'
        }
    }, [state.editMode, headerType])
    const _hasEditPermissions = hasPermission(userClaims, known_actions.edit_assets, sbh_area)
    const {width} = useWindowSize();
    const l = React.useMemo(()=>{
        if(!width) return 40;
        if(width > 1000) return 40;
        return 24;
    },[width])
    const {queryValues} = useQueryValues();
    return <div style={{ minWidth: (`${(settings?.SectionColumns??0) * 10}%`), position: 'relative' }}>
        <FlexRow style={{ padding: `0px ${l}px`, }} alignItems="center" justifyContent='center'>
            <div className="mt-2 mr-2" style={{ marginBottom: 6 }} onClick={(e: React.MouseEvent<HTMLElement>) => e.stopPropagation()}>
                {icon}
            </div>
            <p className={`header-text text-center ${queryValues[SELECTED_ICON_QUERY_KEY] === id ? 'underline' : ''}` } >
                {text}
            </p>
            {editable && _hasEditPermissions &&
                <>
                    <IconButton onClick={handleAddClick} className="h-[25px] w-[25px] p-1 ml-2 hover:!bg-white bg-white rounded-md hover:!text-slate-300 text-slate-800 mt-2 mb-1"><FontIcon style={{ fontSize: '1rem' }} iconName={"Add"} /></IconButton>
                    <IconButton onClick={handleEditClick} className="h-[25px] w-[25px] p-1 ml-2 hover:!bg-white bg-white rounded-md hover:!text-slate-300 text-slate-800 mt-2 mb-1"><FontIcon style={{ fontSize: '1rem' }} iconName={iconName} /></IconButton>
                </>}
            {handleSearchChange && <TextField
                variant='outlined'
                value={searchValue}
                label="Cerca"
                placeholder='Cerca....'
                InputLabelProps={{
                    style: {
                        color: 'white',
                        paddingRight: 10,
                        paddingLeft: 10
                    }
                }}
                InputProps={{
                    style: {
                        color: 'white',
                        backgroundColor: 'rgb(70,96,129)',
                        borderRadius: 8,
                        paddingRight: 10,
                        paddingLeft: 10,
                        maxWidth:200
                    },
                    endAdornment: searchValue ? <InputAdornment position="end"><IconButton className="!p-0 !m-0 !w-[20px] !h-[20px] !hover:bg-transparent !bg-transparent !text-white" onClick={() => setSearchValue ? setSearchValue('') : {}}><AiOutlineClose /></IconButton></InputAdornment> : null,
    
                }}
                autoComplete='off'
                size='small'
                className="!ml-2 !my-4 !text-white !border-slate-800"
                onChange={handleSearchChange}
            />}

        </FlexRow>

    </div>

}
export { DashboardHeaderText }