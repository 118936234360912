import React from "react";
import { MiniLoadingSpinner } from "../spinner/MiniLoadingSpinner/MiniLoadingSpinner";
import TutorialImg from "../../images/tutorials/tutorial.png";
import { usePowerBiAuth } from "./hooks/usePowerBiAuth";
import { factories, models, service } from "powerbi-client";
import { useQuery } from "../../hooks/useQuery";
import { useDashboardsStore } from "../../store/hooks/useDashboardsStore";
import { useUserContext } from "../../store/hooks/useUserContext";
import useWindowSize from "../../utility/useWindowSize";
import { DisposableSidebar } from "../DisposableSidebar/DisposableSidebar";
import { usePowerBIContext } from "../../store/hooks/usePowerBiContext";
import { FontIcon, IconButton } from "@fluentui/react";
import { reportTopbarHeight } from "../topbar/ReportTopBar/ReportTopBar";
import { useNavigationContext } from "../../store/hooks/useNavigationContext";
import { AllReportsListNavigator } from "../AllReportsListNavigator/AllReportsListNavigator";
import { ISidebarOptions } from "../../types/panels";
import { IPowerBiEmbedError } from "../../types/powerBi";

const powerbi = new service.Service(
  factories.hpmFactory,
  factories.wpmpFactory,
  factories.routerFactory
);

const containerId = "power-bi-embed-container";
export const QUERY_EMBED_ID_KEY = "Id";
export const QUERY_EMBED_WORKSPACE_ID_KEY = "wsId";
export const QUERY_EMBED_TYPE_KEY = "type";
export const reportSidebarOptions: (b: boolean) => ISidebarOptions = (
  isOpen: boolean
) => ({
  isOpen: isOpen,
  isModal: false,
  isClosable: false,
  width: "l",
  position: "right",
  transitionMovementType: "horizontal",
  transitionResizeType: "width",
  childrens: <AllReportsListNavigator />,
  offset: {
    x: 0,
    y: 0,
  },
});
const PowerBIUserEmbed: React.FC = () => {
  const query = useQuery();
  const { contextState, loadProductsAndAssets } = useDashboardsStore();
  const { setSidebarOptions } = useNavigationContext();
  const { products } = React.useMemo(() => contextState, [contextState]);
  const { accessToken } = useUserContext();
  const {
    isLoading,
    setIsLoading,
    powerBiAccessToken,
    setAccessToken,
    embedState: state,
    setEmbedState: setState
  } = usePowerBIContext();
  const panesFilters = state.reportRendered === null ? { filters: undefined } : {};
  React.useEffect(() => {
    if (accessToken && products.length === 0) {
      loadProductsAndAssets();
    }
  }, [accessToken, loadProductsAndAssets, products]);
  const { topbarHeight } = useWindowSize();
  const getEmbedUrlFromQuery = () => {
    switch (query.get("type")) {
      case "report":
        return `https://app.powerbi.com/reportEmbed?reportId=${query.get(
          QUERY_EMBED_ID_KEY
        )}&groupId=${query.get(QUERY_EMBED_WORKSPACE_ID_KEY)}`;
      case "scorecard":
        return `https://app.powerbi.com/scorecardEmbed?scorecardId=${query.get(
          QUERY_EMBED_ID_KEY
        )}`;
      default:
        return null;
    }
  };

  const { getAccessToken, loginState, setLoginState } = usePowerBiAuth({
    setAccessToken: (token) => {
      setAccessToken(token);
      setIsLoading(false);
    },
    catchError: (err: any, ssoError: boolean) => {
      setLoginState({
        ...loginState,
        sso: {
          success: !ssoError,
          error: ssoError,
        },
        popup: {
          success: false,
          error: true,
        },
      });
    },
  });
  const embedConfiguration: any = {
    type: "report",
    tokenType: null,
    accessToken: powerBiAccessToken,
    embedUrl: getEmbedUrlFromQuery(),
    id: query.get(QUERY_EMBED_ID_KEY),
    pageName: query.get("pageName") ?? "",
    settings: {
      persistentFiltersEnabled: true,
      bars: {
        actionBar: {
          visible: true,
        },
      },
      panes: {
        ...panesFilters,
        pageNavigation: {
          visible: true,
          position: models.PageNavigationPosition.Left,
        },

      },
      personalBookmarksEnabled: true,
    },
  };
  React.useEffect(() => {
    setState({
      ...state,
      error: "",
    });
    setLoginState({
      sso: {
        success: false,
        error: false,
      },
      popup: {
        success: false,
        error: false,
      },
    });
    if (!powerBiAccessToken) {
      setIsLoading(true);
      getAccessToken(true);
    }
    // return () => setState(initialState)
  }, [query]);
  const containerHTMLElement = document.getElementById(containerId);

  if (
    containerHTMLElement &&
    powerBiAccessToken &&
    query.get(QUERY_EMBED_ID_KEY) &&
    getEmbedUrlFromQuery() &&
    state.reportRendered !== getEmbedUrlFromQuery()
  ) {
    const report = powerbi.embed(containerHTMLElement, embedConfiguration);

    // Clear any other loaded handler events
    report.off("loaded");

    // Triggers when a content schema is successfully loaded
    report.on("loaded", function () {
      console.log("Report load successful");
    });

    // Clear any other rendered handler events
    report.off("rendered");

    // Triggers when a content is successfully embedded in UI
    report.on("rendered", function () {
      console.log("Report render successful");
      setState({ ...state, reportRendered: getEmbedUrlFromQuery() });
    });

    // Clear any other error handler event
    report.off("error");

    // Below patch of code is for handling errors that occur during embedding
    report.on("error", function (event: IPowerBiEmbedError) {
      const errorMsg = `${event.detail.errorCode}-${event.detail.message}-${event.detail.detailedMessage}`;
      console.error(errorMsg);
      // setState({
      //   ...state,
      //   error: errorMsg as string,
      // });
      // Use errorMsg variable to log error in any destination of choice
    });
  }
  React.useEffect(() => {
    if (!query.get(QUERY_EMBED_ID_KEY)) {
      setSidebarOptions(reportSidebarOptions(true));
    }
    return () => setSidebarOptions(reportSidebarOptions(false));
  }, [query, setSidebarOptions]);
  return (
    <div
      className="bg-slate-700"
      style={{
        height: `calc(100vh - ${topbarHeight ? topbarHeight * 1.1 : 100}px)`,
        maxWidth: "100vw",
        overflow: "hidden",
      }}
    >
      {!query.get(QUERY_EMBED_ID_KEY) && (
        <p className="text-white text-3xl text-center w-[100%] mt-2">
          Seleziona un report
        </p>
      )}

      {state.error &&
        <div className="flex flex-col-reverse items-center justify-center h-[100%]">
          <p className="bg-slate-700 text-xxxl text-center w-[100vw] pt-4 text-white">
            {state.error}
            <IconButton
              disabled={isLoading}
              className="bg-white rounded-lg !ml-4 hover:bg-white text-slate-800"
              onClick={() => {
                setIsLoading(true);
                getAccessToken(true, "graph").finally(() =>
                  setIsLoading(false)
                );
              }}
            >
              <FontIcon
                iconName="StatusCircleSync"
                style={{ fontSize: "2rem" }}
              />
            </IconButton>
          </p>
        </div>
      }
      {isLoading && (
        <div className="w-[100%] bg-slate-700">
          <MiniLoadingSpinner style={{ color: "white", margin: 20 }} />
        </div>
      )}
      <div
        style={{
          height: `calc(100vh - ${reportTopbarHeight}px)`,
          width: "100%",
        }}
        id={containerId}
      />
      <DisposableSidebar />
    </div>
  );
};
export { PowerBIUserEmbed };
