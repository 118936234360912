import React from 'react'
import { TMetric } from '../../../../../types/alerts'
import { isMobile } from 'react-device-detect'
import { TableCell } from '../TableCell/TableCell'
import { settingsContext } from '../../../../../store/settingsStore'
import { useMetricHeaders } from '../../../../../store/hooks/useMetricHeaders'
type Props = {
    metric: TMetric
    isSelectable: boolean
}
export const TableRow: React.FC<Props> = ({ metric, isSelectable }) => {
    const { headers } = useMetricHeaders();
    const { state, dispatch } = React.useContext(settingsContext);
    const { selectedMetrics } = state;
    const selectedMetricsIds = React.useMemo(() => selectedMetrics?.map((x) => x.Id), [selectedMetrics])
    const checked = selectedMetricsIds ? selectedMetricsIds.find(id => id === metric.Id) !== undefined : false;
    const handleSelectChange: () => void = React.useCallback(() => {
        if (selectedMetrics) {
            if (selectedMetrics.find(x => x.Id === metric.Id)) {
                dispatch({ type: "SET_SELECTED_METRICS", payload: selectedMetrics.filter(x => x.Id !== metric.Id) })
            } else {
                dispatch({ type: "SET_SELECTED_METRICS", payload: [...selectedMetrics, metric] })
            }
        } else {
            dispatch({ type: "SET_SELECTED_METRICS", payload: [metric] })
        }
    }, [selectedMetrics, dispatch, metric])
    if(isMobile){
        return <></>
    }
    return <div
        onClick={isSelectable ? handleSelectChange : undefined}
        className={`${checked ? "bg-white" : ""} flex items-center min-h-[40px] max-h-[42px] grow ${isSelectable ? "cursor-pointer" : "cursor-default"}`}
        style={{
            marginBottom: 1,
        }}>
        {headers.map((h) => <TableCell
            checked={checked}
            key={`cell-${metric.Id}-${h.id}`}
            element={h.element ? h.element(metric) : undefined}
            value={h.value ? h.value(metric) : undefined}
            width={h.width}
        />)}
    </div>
}