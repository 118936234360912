import React from "react";
import { intesa_green } from "../../../themes/themes";
import { useDashboardsStore } from "../../../store/hooks/useDashboardsStore";
import { FontIcon, IconButton } from "@fluentui/react";
import { FlexCol } from "../../Flex/FlexCol";
import { HelpIconBtn } from "../../HelpIconBtn/HelpIconBtn";
import { HelpIcon } from "../../HelpIcon/HelpIcon";
import { useNavigationContext } from "../../../store/hooks/useNavigationContext";
import { TGroupedHelp } from "../RowDashboardContainer";
import { HelpContainer } from "../HelpsLibrary/HelpContainer/HelpContainer";
import useWindowSize from "../../../utility/useWindowSize";
import { isMobile } from "react-device-detect";
import './styles.css';
import {
  BsArrowLeftSquare,
  BsArrowRightSquare,
} from "react-icons/bs";
import {
  InputBase,
  styled,
} from "@mui/material";
import { ProductSelector } from "../../ProductSelector/ProductSelector";
import { isDesktopWidth } from "../../../pages/MainDashboard/MainDashboard";
export const DESKTOP_ROW_DASHBOARD_CONTAINER_SIDEBAR_WIDTH_MINI = 75;
export const DESKTOP_ROW_DASHBOARD_CONTAINER_SIDEBAR_WIDTH_FULL = 225;
export const MOBILE_ROW_DASHBOARD_CONTAINER_SIDEBAR_WIDTH = 60;
export const MOBILE_ROW_DASHBOARD_CONTAINER_SIDEBAR_WIDTH_FULL = 200;
export const ROW_DASHBOARD_CONTAINER_SIDEBAR_IS_PINNED_LOCAL_STORAGE_KEY =
  "row-dashboard-container-sidebar-is-pinned";
export const ROW_DASHBOARD_DEFAULT_CLOSE_STORAGE_KEY =
  "row-dashboard-container-sidebar-default-closed";
type Props = {
  showBorder: boolean;
  showHelp: boolean;
  showBackArrow: boolean;
  handleGoBack: () => void;
  helps?: TGroupedHelp[];
  currentIcons?: [string, React.ReactNode][];
  title: string;
};
const StyledInput = styled(InputBase)(({ theme }) => ({
  "label + &": {
    color: "white",
  },
  "& .MuiInputBase-input": {
    borderRadius: 4,
    position: "relative",
    // backgroundColor: "#466081",
    // color: "white",
    backgroundColor: "lightgrey",
    color: intesa_green,
    fontWeight: "bold",
    border: "1px solid black",
    fontSize: 13,
    padding: "4px 8px 4px 4px",
    transition: theme.transitions.create(["border-color", "box-shadow"]),
    // Use the system font instead of the default Roboto font.
    "&:focus": {
      borderRadius: 4,
      borderColor: "#80bdff",
      boxShadow: "0 0 0 0.2rem rgba(0,123,255,.25)",
    },
  },
  "& .MuiSvgIcon-root": {
    color: intesa_green,
    fill: intesa_green,
  },
}));
const RowDashboardContainerSidebar: React.FC<Props> = ({
  showBorder,
  showBackArrow,
  handleGoBack,
  showHelp,
  helps,
  currentIcons,
  title,
}) => {
  const {
    contextState,
    setRowDashboardContainerSidebarWidth: setSidebarWidth,
  } = useDashboardsStore();
  const { rowDashboardContainersidebarWidth: sidebarWidth } = contextState;
  const { width } = useWindowSize();
  const [isPinned, setIsPinned] = React.useState<boolean>(
    JSON.parse(
      localStorage.getItem(
        ROW_DASHBOARD_CONTAINER_SIDEBAR_IS_PINNED_LOCAL_STORAGE_KEY
      ) ?? (!isMobile).toString()
    )
  );
  const { sidebar:helpSidebar, setSidebarOptions:setHelpOptions } = useNavigationContext();
  const handleOpenCloseSidebar = React.useCallback(() => {
    if (!isPinned) {
      setIsPinned(true);
    }
    if(helpSidebar.isOpen){
      setHelpOptions({...helpSidebar,isOpen:false})
    }
    const nextSidebarWidth =
      sidebarWidth === DESKTOP_ROW_DASHBOARD_CONTAINER_SIDEBAR_WIDTH_FULL
        ? DESKTOP_ROW_DASHBOARD_CONTAINER_SIDEBAR_WIDTH_MINI
        : DESKTOP_ROW_DASHBOARD_CONTAINER_SIDEBAR_WIDTH_FULL;
    setSidebarWidth(nextSidebarWidth);
    localStorage.setItem(
      ROW_DASHBOARD_DEFAULT_CLOSE_STORAGE_KEY,
      JSON.stringify(
        nextSidebarWidth === DESKTOP_ROW_DASHBOARD_CONTAINER_SIDEBAR_WIDTH_MINI
      )
    );
  // }, [isPinned, setHelpOptions, setSidebarWidth, sidebarWidth]);
}, [helpSidebar, isPinned, setHelpOptions, setSidebarWidth, sidebarWidth]);
  const resizeRowDashboardContainerSidebar = React.useCallback(() => {
    
    if (!width || isPinned) {
      return;
    }
    // if(isPinned && helpSidebar.isOpen){
    //   setHelpOptions({...helpSidebar,isOpen:false})
    // }
    if (
      isPinned &&
      !isMobile &&
      sidebarWidth !== DESKTOP_ROW_DASHBOARD_CONTAINER_SIDEBAR_WIDTH_FULL
    ) {
      setSidebarWidth(DESKTOP_ROW_DASHBOARD_CONTAINER_SIDEBAR_WIDTH_FULL);
      return;
    } else if (!isPinned) {
      const newWidth = isDesktopWidth(width) ? DESKTOP_ROW_DASHBOARD_CONTAINER_SIDEBAR_WIDTH_MINI : MOBILE_ROW_DASHBOARD_CONTAINER_SIDEBAR_WIDTH;
      if(newWidth !== sidebarWidth){
        setSidebarWidth(newWidth);
        
      }
      
    }
  // },[helpSidebar, isPinned, setHelpOptions, setSidebarWidth, sidebarWidth, width]);
},[isPinned, setSidebarWidth, sidebarWidth, width]);
  React.useEffect(() => {
    resizeRowDashboardContainerSidebar();
  }, [resizeRowDashboardContainerSidebar]);
  const handleOpenHelp = React.useCallback(() => {
    setHelpOptions({
      isOpen: !helpSidebar.isOpen,
      isModal: false,
      isClosable: true,
      width: "xl",
      position: "left",
      transitionMovementType: "none",
      transitionResizeType: "none",
      childrens: (
        <>
          {helps &&
            helps.map((help: TGroupedHelp) => (
              <div
                style={{ marginRight: 30 }}
                key={`${help.items.map((h) => h.title).join("-")}`}
              >
                <p className="text-xl border-b w-[100%] mt-2 pl-1">
                  {help.parent}
                </p>
                {help.items.map((h) => (
                  <HelpContainer help={h} />
                ))}
              </div>
            ))}
        </>
      ),
      offset: {
        x: isPinned ? sidebarWidth : 0,
        y: 0,
      },
    });
    // setState({
    //     openHelp: true
    // })
  }, [setHelpOptions, helpSidebar.isOpen, helps, isPinned, sidebarWidth]);
  const l = React.useMemo(
    () => (sidebarWidth - 10 > 60 ? 60 : sidebarWidth - 10),
    [sidebarWidth]
  );
  const handleMouseEnter = () => {
    if (isMobile || !width || isPinned) {
      return;
    }
    if (width > 600) {
      setSidebarWidth(DESKTOP_ROW_DASHBOARD_CONTAINER_SIDEBAR_WIDTH_FULL);
    } else {
      setSidebarWidth(MOBILE_ROW_DASHBOARD_CONTAINER_SIDEBAR_WIDTH_FULL);
    }
  };
  const rowDashboardContainersidebarWidth_isFull = React.useMemo(
    () => sidebarWidth > DESKTOP_ROW_DASHBOARD_CONTAINER_SIDEBAR_WIDTH_MINI,
    [sidebarWidth]
  );
  const homeIconsLeft = React.useMemo(
    () => (rowDashboardContainersidebarWidth_isFull ? 10 : "auto"),
    [rowDashboardContainersidebarWidth_isFull]
  );
  const homeIconWidth = React.useMemo(
    () => (rowDashboardContainersidebarWidth_isFull ? undefined : "100%"),
    [rowDashboardContainersidebarWidth_isFull]
  );
  const iconsXMargin = React.useMemo(
    () => (rowDashboardContainersidebarWidth_isFull ? 10 : "auto"),
    [rowDashboardContainersidebarWidth_isFull]
  );

  return (
    <div
      onMouseEnter={handleMouseEnter}
      onMouseLeave={resizeRowDashboardContainerSidebar}
      className={`fixed flex flex-col ${showBorder ? "border-r" : ""}`}
      style={{
        top: 0,
        transition: "all 0.35s",
        borderColor: intesa_green,
        backgroundColor: "rgb(41,52,72)",
        width: sidebarWidth,
        height: "calc(100vh)",
      }}
    >
      {showBackArrow && (
        <>
          <IconButton
            style={{
              zIndex: 999999,
              height: 60,
              width: homeIconWidth,
              position: "absolute",
              left: homeIconsLeft,
              top: 5,
            }}
            className="rounded-full hover:bg-transparent text-xxxxl "
            onClick={handleGoBack}
          >
            <FontIcon iconName="Home" style={{ color: "white" }} className="" />
          </IconButton>
          {rowDashboardContainersidebarWidth_isFull && (
            <p
              className="text-xl cursor-pointer text-white"
              onClick={handleGoBack}
              style={{
                zIndex: 999999,
                position: "absolute",
                left: 60,
                top: 20,
              }}
            >
              Home
            </p>
          )}
        </>
      )}
      <FlexCol
        style={{
          marginTop: 60,
          marginLeft: iconsXMargin,
          marginRight: iconsXMargin,
          maxHeight:'50vh',
          borderTop: "1px solid white",
          paddingTop: 5,
          overflow:'auto'
        }}
      >
        {currentIcons?.map((el) => (
          <div key={el[0]} className="my-1">
            {el[1]}
          </div>
        ))}
      </FlexCol>
      <div
        style={{
          maxHeight: "50vh",
          display: "flex",
          flexDirection: "column",
          justifyContent: "end",
          alignItems: "end",
          marginTop: "auto",
          bottom: showHelp ? l / 2 + 30 : 20,
          right: 5,
          position:'absolute',
        }}
      >
        <IconButton
          className="rounded-full hover:bg-transparent text-xxl"
          onClick={() => {
            localStorage.setItem(
              ROW_DASHBOARD_CONTAINER_SIDEBAR_IS_PINNED_LOCAL_STORAGE_KEY,
              JSON.stringify(!isPinned)
            );
            setIsPinned(!isPinned);
          }}
          style={{
            zIndex: 999999,
            height: 25,
            width: 25,
            marginBottom: 10,
            marginRight: 15,
          }}
        >
          <FontIcon
            iconName={isPinned ? "PinnedSolid" : "Pinned"}
            className="text-white"
          />
        </IconButton>
        {!isMobile && (
          <IconButton
            onClick={handleOpenCloseSidebar}
            style={{
              backgroundColor: "transparent",
              height: 35,
              width: 35,
              zIndex: 999999,
              marginBottom: 10,
              marginRight: 10,
              color: "white",
              transition: "all 0.35s",
            }}
          >
            {sidebarWidth ===
            DESKTOP_ROW_DASHBOARD_CONTAINER_SIDEBAR_WIDTH_MINI ? (
              <BsArrowRightSquare className="text-white text-base h-[100%] w-[100%]" />
            ) : (
              <BsArrowLeftSquare className="text-white text-base h-[100%] w-[100%]" />
            )}
          </IconButton>
        )}
        <ProductSelector titleFontSize={rowDashboardContainersidebarWidth_isFull
              ? 18
              : 16}/>
      </div>
      {showHelp && (
        <>
          <HelpIconBtn
            // onClick={()=>{}}
            onClick={handleOpenHelp}
            style={{
              backgroundColor: "transparent",
              height: l / 2,
              zIndex: 999999,
              position: "absolute",
              left: `calc(${sidebarWidth/2}px - 15px)`,
              width: 30,
              bottom: 10,
            }}
          >
            <HelpIcon style={{width:30,height:30}}/>
          </HelpIconBtn>
        </>
      )}
    </div>
  );
};
export { RowDashboardContainerSidebar };
