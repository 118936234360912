import React from 'react';
import { TableRowKpi } from './TableRowKpi';
import { TAssetBagSection } from '../../services/products';
interface Props {
    sections:TAssetBagSection[],
    additionalInfos?:string,
    extraStyles?:React.CSSProperties
    maxHeight?:number
}
const TableKpi:React.FC<Props> = ({sections,extraStyles,maxHeight,additionalInfos}) => {
    return <div className="table-kpi flex flex-col h-[100%] overflow-y-auto border rounded mx-2 bg-slate-300" style={{height:maxHeight, borderColor:'rgb(110,126,149)',zIndex:2,...extraStyles}}>
        {sections.map((r,i)=><TableRowKpi isFirst={i === 0} key={r.Description} row={r} showBorder={false} />)}
        <div className="mt-2"/>
        {additionalInfos && <div className="mt-auto w-[100%] self-end border-t">
            <p className="text-base py-2 text-gray-600 text-center">{additionalInfos}</p>
        </div>}
    </div>
}
export {TableKpi}