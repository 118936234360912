import React from 'react';
import { DashboardHeaderText } from '../../DashboardHeader/DashboardHeaderText';
import { useDashboardsStore } from '../../../store/hooks/useDashboardsStore';
import { SearchAssets } from '../../../store/dashboardsStore';
import { ServicesIcon } from '../Icons/ServicesIcon';
import { HeaderProps } from './types';
import { ProductPageSection } from '../../../hooks/useProductDashboardDimensions';
const ServicesColumnHeader: React.FC<HeaderProps> = ({ settings }) => {
    const {
        search, contextDispatch
    } = useDashboardsStore();
    const setSearchValue = (s: string) => contextDispatch({
        type: "SET_SEARCH",
        payload: {
            ...search,
            [SearchAssets.SERVICES]: s
        }
    })
    const handleSearchChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        setSearchValue(e.target.value)
    }
    return <DashboardHeaderText
        id={ProductPageSection.SERVICES}
        icon={<ServicesIcon onlyIcon/>}
        settings={settings}
        editable={false}
        text="Servizi"
        searchKey={SearchAssets.SERVICES}
        setSearchValue={setSearchValue}
        handleSearchChange={handleSearchChange}
    />
}
export { ServicesColumnHeader }