import { ProductPageSection } from "../../hooks/useProductDashboardDimensions";
import {IFrontendSettingsGet} from "../../services/frontendSettings";
import {TSize} from "../general";
import {known_products} from "../userStore";
export enum columnElementID {
    Services = "SERVICES",
    Reports = "REPORTS",
    SpecialReports = "SPECIALREPORTS",
    Scorecards = "SCORECARDS",
    KPI = "KPI",
    Files = "FILES",
    Alerts = "ALERTS",
    Tutorials = "TUTORIALS",
  }
export type TProductDashboardPilesDimensions = {[k in columnElementID]?: TSize};
export enum EditableAssets {
  SCORECARDS = "SCORECARDS",
  REPORTS = "REPORTS",
  SPECIAL_REPORTS = "SPECIAL_REPORTS",
}
export enum SearchAssets {
  SCORECARDS = "SCORECARDS",
  REPORTS = "REPORTS",
  SPECIAL_REPORTS = "SPECIAL_REPORTS",
  SERVICES = "SERVICES",
}
export type TProductDashboardState = {
  productId?: keyof typeof known_products;
};
export type ColumnElement = {
  id: columnElementID;
  settings?: IFrontendSettingsGet;
  el: (isFirst: boolean) => React.ReactNode;
  header: React.ReactNode;
  icon: React.ReactNode;
};
export type AppState = TProductDashboardState;

export type TProductDashboardSectionMap = {
  [key in ProductPageSection]: {
    settings?:IFrontendSettingsGet
    header: React.ReactNode;
    section: React.ReactNode;
    icon: React.ReactNode;
  };
};
