import React from 'react'
import { useUserContext } from '../../../store/hooks/useUserContext';
import { TMetric } from '../../../types/alerts';
import { MetricAssignmentsForm } from './Steps/Step_2/MetricAssignmentsForm';
import { StepsEnum, settingsContext } from '../../../store/settingsStore';
import { AssignmentsStepper } from './AssignmentsStepper';
import { getAssignnmentMetrics } from '../../../services/assignments';
import { AssignmentsVisualSteps } from './AssignmentsVisualSteps';
import { MetricAssignmentsSummary } from './Steps/Step_3/MetricAssignmentsSummary';
import { Step1Toolbar } from './Steps/Step_1/Step1Toolbar';
import { isMobile } from 'react-device-detect';
import { Step1List } from './Steps/Step_1/Step1List';
import { Alert } from '@mui/material';
import { topbarHeight } from '../../../components/DashboardContainer/DashboardContainer';
import { AssignmentPriorityEnum } from '../../../types/assignments';
export const AssignmentsSettingsPage: React.FC = () => {
    const [loadingState, setLoadingState] = React.useState({ isLoading: false, isSaving: false });
    const { accessToken } = useUserContext();
    const { state: contextState, dispatch: contextDispatch } = React.useContext(settingsContext)
    const { selectedProductId, currentStep, metricSortings } = contextState;
    const [searchMetric, setSearchMetric] = React.useState<string | null>(null)
    const _getProductMetrics = React.useCallback(async () => {
        console.log(metricSortings)
        if (accessToken && selectedProductId) {
            setLoadingState({ ...loadingState, isLoading: true });
            getAssignnmentMetrics(selectedProductId,metricSortings)
                .then((ChildMetrics) => {
                    contextDispatch({ type: "SET_METRICS", payload: ChildMetrics });
                })
                .catch((err) => console.log(err))
                .finally(() => setLoadingState({ ...loadingState, isLoading: false }))
        }
    }, [selectedProductId, accessToken, metricSortings])
    React.useEffect(() => {
        _getProductMetrics();
    }, [_getProductMetrics])
    const filterMetrics = (metric: TMetric) => {
        // const filter_1 = contextState.selectedMethod === "NEW" ? !metric.HasAnExitingConfiguration : metric.HasAnExitingConfiguration;
        // return searchMetric ? filter_1 && metric.Name.toLowerCase().includes(searchMetric.toLowerCase()) : filter_1;
        return searchMetric ? metric.Id.toString().includes(searchMetric) || metric.Name.toLowerCase().includes(searchMetric.toLowerCase()) : true;
    };
    const sortMetrics = (a: TMetric, b: TMetric) => {
        const { AssignmentsConfiguration: a_cfg } = a;
        const { AssignmentsConfiguration: b_cfg } = b;
        if (!a_cfg && !b_cfg) {
            return a.Name > b.Name ? 1 : -1
        }
        if(!a_cfg){
            return 1;
        }
        if(!b_cfg){
            return -1;
        }
        return Object.values(AssignmentPriorityEnum).indexOf(b_cfg.Priority) - Object.values(AssignmentPriorityEnum).indexOf(a_cfg.Priority)
    };
    React.useEffect(() => {
        if (currentStep < StepsEnum.SELECTION) {
            contextDispatch({ type: "SET_STEP", payload: StepsEnum.SELECTION })
        }
    }, [contextDispatch, currentStep])
    return <div className="relative max-w-[100%] overflow-y-auto bg-slate-600 p-4" style={{ height: 'calc(100vh - 48px)' }}>
        {contextState.popupMessage &&
            <div style={{ width: isMobile ? '100vh' : '50vh', margin: '2px auto' }}>
                <Alert
                    style={{ position: 'fixed', zIndex: 9999999, top: topbarHeight, left: `calc(50% - ${isMobile ? 150 : 250}px)`, width: isMobile ? 300 : 500 }}
                    variant='filled'
                    onClose={() => { contextDispatch({ type: "SET_STATE", payload: { ...contextState, popupMessage: '' } }) }}
                    severity={contextState.popupType === "success" ? "success" : "error"}
                >
                    {contextState.popupMessage}
                </Alert>
            </div>}
        {contextState.currentStep > 0 && <><div className="mt-4" /> <AssignmentsVisualSteps /></>}
        <div className="mt-4" />
        {/* {contextState.currentStep === 0 &&
            // <div className="flex flex-col items-center h-[100%] mx-auto px-2" style={{minWidth:isMobile?undefined:600,width:'100%',maxWidth:1000}}>
            <div style={{ minWidth: isMobile ? undefined : 600, width: '100%', maxWidth: 1000, borderRadius: 6, overflow: 'auto', padding: 6, margin: '0px auto' }}>
                <ActionSelectors reload={_getProductMetrics} />
                <div className="mt-8" />
                <Step0List />
            </div>
        } */}
        {contextState.currentStep === StepsEnum.SELECTION &&
            <>
                <Step1Toolbar setSearchMetric={setSearchMetric} searchMetric={searchMetric} isLoading={loadingState.isLoading} />
                <Step1List filterMetrics={filterMetrics} sortMetrics={sortMetrics} loadingState={loadingState} />
            </>
        }
        {
            contextState.currentStep === StepsEnum.FORM &&
            <MetricAssignmentsForm />
        }
        {
            contextState.currentStep === StepsEnum.CONFIRM &&
            <MetricAssignmentsSummary
            />
        }
        <AssignmentsStepper />
    </div>
}