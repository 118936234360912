import React from "react";
import { TMainDashboardIconProps } from "./types";
import { MainDashboardIcon } from "./MainDashboardIcon";
import { FiSettings } from "react-icons/fi";
import { useNavigate } from "react-router-dom";
import { know_urls } from "../../../types/userStore";

const AssetConfigIcon: React.FC<TMainDashboardIconProps> = (props) => {
  const navigate = useNavigate();
  return (
    <MainDashboardIcon {...props} onClick={()=>navigate(know_urls.edit_assets)}>
              <FiSettings />
    </MainDashboardIcon>
  );
};
export { AssetConfigIcon };
