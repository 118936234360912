import React from "react";
import { isMobile } from "react-device-detect";
import { topbarHeight } from "../../../components/DashboardContainer/DashboardContainer";
import { IconButton } from "@fluentui/react";
import { TMainDashboardIconProps } from "./types";
import { TooltipContainer } from "../../../components/TooltipContainer/TooltipContainer";
const MainDashboardIcon: React.FC<TMainDashboardIconProps> = ({
  children,
  onClick,
  tooltip,
}) => {
  const leftIconHeight = React.useMemo(
    () => (isMobile ? 30 : topbarHeight - 10),
    []
  );
  const leftIconStyles: React.CSSProperties = React.useMemo(
    () => ({
      width: leftIconHeight,
      height: leftIconHeight,
      zIndex: 999999,
    }),
    [leftIconHeight]
  );
  const btn = (
    <IconButton
      style={{
        ...leftIconStyles,
      }}
      className="hover:bg-transparent !bg-intesa-green rounded-md text-xxl text-white hover:!text-white p-1"
      onClick={onClick}
    >
      {children}
    </IconButton>
  );
  return tooltip ? (
    <TooltipContainer
      tooltip={tooltip}
      customContentStyles={{ content: { fontSize: 20 } }}
    >
      {btn}
    </TooltipContainer>
  ) : (
    <>{btn}</>
  );
};
export { MainDashboardIcon };
