import React from "react";
import { useQuery } from "../hooks/useQuery";
export const QUERY_PAGE_KEY = "page";
export const QUERY_NAME_KEY = "name";
export const QUERY_TYPE_KEY = "type";
export const SELECTED_ICON_QUERY_KEY = "selected-icon";
export const MAIN_DASHBOARD_VIEW_TYPE = "view-type";
export const SELECTED_AREA = "selected-area"
export interface IQueryValues{
    [QUERY_PAGE_KEY]:string|null,
    [MAIN_DASHBOARD_VIEW_TYPE]:string|null,
    [SELECTED_ICON_QUERY_KEY]:string|null
    [SELECTED_AREA]:string|null
}
export function useQueryValues() {
    const query = useQuery();
    const queryValues: IQueryValues = React.useMemo(() => ({
        [QUERY_PAGE_KEY]:query.get(QUERY_PAGE_KEY),
        [SELECTED_ICON_QUERY_KEY]:query.get(SELECTED_ICON_QUERY_KEY),
        [MAIN_DASHBOARD_VIEW_TYPE]:query.get(MAIN_DASHBOARD_VIEW_TYPE),
        [SELECTED_AREA]:query.get(SELECTED_AREA)
    }), [query])
    const encodeValues = (url: string, keys?: (keyof IQueryValues)[], values?: IQueryValues, onlyNewValues?:boolean) => {
        let result: string = '';
        let keysToEncode: (keyof IQueryValues)[] = keys ? keys : Object.keys(queryValues) as (keyof IQueryValues)[]
        keysToEncode.forEach((k, index) => {
            let value = values && values[k] ? values[k] : queryValues[k];
            if(onlyNewValues && values){
                value = values[k]
            }
            if (value) {
                if (!result) {
                    result = url.endsWith('?') ? url : `${url}?`
                }
                result += `${k}=${value}${index < keysToEncode.length - 1 ? '&' : ''}`
            }
        });
        if(!result){
            return url;
        }
        return result.endsWith('&') ? result.slice(0, -1) : result;
    }
    const replaceEncodedValue = (keys: (keyof IQueryValues)[], values: string[]) => {
        let used_keys:(keyof IQueryValues)[] = [];
        let used_values:string[] = []
        Object.entries(queryValues).forEach(([k,v])=>{
            const needed_key = keys.includes(k as keyof IQueryValues);
            if(v || needed_key){
                used_keys.push(k as keyof IQueryValues)
                used_values.push(needed_key ? values[keys.indexOf(k as keyof IQueryValues)] : v)
            }
        })
        return encodeValues(window.location.pathname,used_keys,Object.fromEntries(used_values.map((x,i)=>[used_keys[i],x])) as any)
    }
    return {
        query,
        queryValues,
        encodeValues,
        replaceEncodedValue,
    }
}