import dayjs from "dayjs";
import {PaginationEnum} from "../pager";
import {AssignmentStatusEnum, TAssignment} from "../../types/assignments";
import {IColumnFilter, ODataFilterOperators} from "../../types/odata";
import React from "react";
import {IColumnFilterPreset} from "../../types/general";
import {ETicket} from "../../types/etickets";
import {IInputEvent} from "../../types/eventNotifications";
import { useUserContext } from "../../../../store/hooks/useUserContext";
export enum AssignmentPresetsEnum {
  MY_ASSIGNMENTS = "MY_ASSIGNMENTS",
  LAST_WEEK_ASSIGNMENTS = "LAST_WEEK_ASSIGNMENTS",
  LAST_MONTH_ASSIGNMENTS = "LAST_MONTH_ASSIGNMENTS",
  LAST_YEAR_ASSIGNMENTS = "LAST_YEAR_ASSIGNMENTS",
  NOT_RESOLVED = "NOT_RESOLVED",
  CLOSED_ASSIGNMENTS = "CLOSED_ASSIGNMENTS",
  STANDBY_ASSIGNMENTS = "STANDBY_ASSIGNMENTS",
  NOT_STANDBY = "NOT_STANDBY",
  RESOLVED = "RESOLVED",
  NEW = "NEW"
}
export enum TicketsPresetsEnum {}
export enum IInputEventPresetsEnum {
  ACTIVE_EVENTS = "ACTIVE_EVENTS",
}
export interface IPagedPresets {
  [PaginationEnum.ASSIGNMENTS]: {
    [key in AssignmentPresetsEnum]: IColumnFilterPreset<TAssignment>;
  };
  [PaginationEnum.TICKETS]: {
    [key in TicketsPresetsEnum]: IColumnFilterPreset<ETicket>;
  };
  [PaginationEnum.INPUT_EVENTS]: {
    [key in IInputEventPresetsEnum]: IColumnFilterPreset<IInputEvent>;
  };
}
export interface IUseFilterPresetsContext {
  presets: IPagedPresets;
}
export const useFilterPresets: () => IUseFilterPresetsContext = () => {
  const {account} = useUserContext();
  const myAssignmentsFilter: IColumnFilter<TAssignment> = React.useMemo(
    () => ({
      columnName: "OwnerEmail",
      operator: "eq",
      value: account.username,
      valueType: "string",
    }),
    [account.username]
  );
  const activeEventsFilter: IColumnFilter<IInputEvent> = React.useMemo(
    () => ({
      columnName: "IsActive",
      operator: "eq",
      value: true,
      valueType: "boolean",
    }),
    []
  );
  const statusFilter: (
    status: AssignmentStatusEnum,
    operator?: ODataFilterOperators
  ) => IColumnFilter<any> = React.useCallback(
    (status: AssignmentStatusEnum, operator?: ODataFilterOperators) => ({
      columnName: "Status",
      operator: operator ?? "eq",
      value: status,
      valueType: "enum",
    }),
    []
  );
  const openingTimeFilter: (
    days: number,
    months: number,
    operator: ODataFilterOperators
  ) => IColumnFilter<TAssignment> = React.useCallback(
    (days, months, operator) => ({
      columnName: "OpeningTime",
      operator: "ge-le",
      value: {
        startDate: dayjs().add(days, "days").add(months, "months"),
        endDate: dayjs().add(1, "days"),
      },
      valueType: "daterange",
    }),
    []
  );
  const presets: IPagedPresets = React.useMemo(
    () => ({
      [PaginationEnum.ASSIGNMENTS]: {
        [AssignmentPresetsEnum.MY_ASSIGNMENTS]: {
          presetName: "Le mie assegnazioni",
          filter: myAssignmentsFilter,
          filterGroup: "",
        },
        [AssignmentPresetsEnum.NEW]: {
          presetName: "Assegnazioni Attive",
          filter: statusFilter(AssignmentStatusEnum.New, "eq"),
          filterGroup: "Stato",
        },
        [AssignmentPresetsEnum.RESOLVED]: {
          presetName: "Assegnazioni Risolte",
          filter: statusFilter(AssignmentStatusEnum.Resolved, "eq"),
          filterGroup: "Stato",
        },
        [AssignmentPresetsEnum.NOT_RESOLVED]: {
          presetName: "Assegnazioni Aperte",
          filter: statusFilter(AssignmentStatusEnum.Resolved, "ne"),
          help:"Assegnazioni non risolte",
          filterGroup: "Stato",
        },
        [AssignmentPresetsEnum.NOT_STANDBY]: {
          presetName: "Assegnazioni non in standby",
          filter: statusFilter(AssignmentStatusEnum.StandBy, "ne"),
          filterGroup: "Stato",
          help:"Assegnazioni non in standby"
        },
        [AssignmentPresetsEnum.CLOSED_ASSIGNMENTS]: {
          presetName: "Assegnazioni Chiuse",
          filter: statusFilter(AssignmentStatusEnum.Resolved),
          filterGroup: "Stato",
        },
        [AssignmentPresetsEnum.STANDBY_ASSIGNMENTS]: {
          presetName: "Assegnazioni in stand-by",
          filter: statusFilter(AssignmentStatusEnum.StandBy),
          filterGroup: "Stato",
        },
        [AssignmentPresetsEnum.LAST_WEEK_ASSIGNMENTS]: {
          presetName: "Assegnazioni ultima settimana",
          filter: openingTimeFilter(-7, 0, "ge"),
          filterGroup: "Data Creazione",
        },
        [AssignmentPresetsEnum.LAST_MONTH_ASSIGNMENTS]: {
          presetName: "Assegnazioni ultimo mese",
          filter: openingTimeFilter(0, -1, "ge"),
          filterGroup: "Data Creazione",
        },
        [AssignmentPresetsEnum.LAST_YEAR_ASSIGNMENTS]: {
          presetName: "Assegnazioni ultimo anno",
          filter: openingTimeFilter(0, -12, "ge"),
          filterGroup: "Data Creazione",
        },
      },
      [PaginationEnum.TICKETS]: {},
      [PaginationEnum.INPUT_EVENTS]: {
        [IInputEventPresetsEnum.ACTIVE_EVENTS]: {
          presetName: "Eventi attivi",
          filter: activeEventsFilter,
          filterGroup: "",
        },
      },
    }),
    [myAssignmentsFilter, openingTimeFilter, statusFilter, activeEventsFilter]
  );
  return {
    presets,
    statusFilter
  };
};
