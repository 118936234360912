import { IFacepilePersona } from "@fluentui/react";
import React from "react";
import { ICustomClaims } from "../../services/user";
import { BuildingSettings } from "../../types/Building";
import { IUserInfo } from "../../types/user";
import { userContext } from "../userStore";

export const useUserContext = () => {
    const { state, dispatch } = React.useContext(userContext);
    const setUserInfo = (payload: IUserInfo) => dispatch({ type: "SET_USER_INFO", payload })
    const setUserClaims = (payload: ICustomClaims[]) => dispatch({ type: "SET_USER_CLAIMS", payload })
    const setAccessToken = (payload: string) => dispatch({ type: "SET_ACCESS_TOKEN", payload })
    const setGraphAccessToken = (payload: string) => dispatch({ type: "SET_GRAPH_ACCESS_TOKEN", payload })
    const setBuildingSettings = (payload: BuildingSettings) => dispatch({ type: "SET_BUILDINGS_SETTINGS", payload })
    const setUserImage = (payload: IFacepilePersona[]) => dispatch({ type: "SET_USER_IMAGE", payload })
    const setErrors = (payload: string[]) => dispatch({ type: "SET_ERRORS", payload })
    const authenticatedCall = React.useCallback((f:(params?:any)=>void,params?:any) => {
        if(state.userInfo.accessToken){
            return f(params)
        }
    },[state.userInfo.accessToken])
    return {
        userState: state,
        userImage: state.userImage,
        userInfo: state.userInfo,
        userClaims: state.userInfo.customClaims,
        accessToken: state.userInfo.accessToken,
        account: state.userInfo.account,
        buildingSettings: state.buildingSettings,
        errors:state.errors,
        dispatch,
        setUserInfo,
        setUserClaims,
        setAccessToken,
        setUserImage,
        setGraphAccessToken,
        setBuildingSettings,
        setErrors,
        authenticatedCall,
    }
}