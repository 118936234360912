import axios from "axios"
import { IExtendedAlert, IMetric, TAlert } from "../types/alerts"
export const ALERT_CONTROLLER = "Alert";
export const getMetric = async (id:number)=>{
    return axios.get(`${process.env.REACT_APP_SERVER_URL}/Metric/Single/${id}`).then((res)=>res.data as IMetric)
}
export const getAlert = async (id:number)=>{
    return axios.get(`${process.env.REACT_APP_SERVER_URL}/${ALERT_CONTROLLER}/Single/${id}`).then((res)=>res.data as TAlert)
}
export const getExtendedAlert = async (id:number)=>{
    return axios.get(`${process.env.REACT_APP_SERVER_URL}/Alert/SingleWithMetric/${id}`).then((res)=>res.data as IExtendedAlert)
}