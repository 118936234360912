import React from 'react';
import { IconProps } from './iconsConstants';
import { ReactComponent as Logo } from '../../../images/dashboard_icons/scorecards.svg';
import { DashboardSideIcon } from './DashboardSideIcon';
import { default_icon_styles } from './constants';
import { ProductPageSection } from '../../../hooks/useProductDashboardDimensions';
export const ScorecardSvg: React.FC<{style?:React.CSSProperties}> = ({style}) => <Logo style={style} />
const ScorecardsIcon: React.FC<IconProps> = ({onlyIcon}) => {
    const id = ProductPageSection.SCORECARDS
    const tooltip = React.useMemo(()=>({
        id:`${id}-dashboard_icon`,
        text:"Scorecards"
    }),[id])
    return <DashboardSideIcon
        onlyIcon={onlyIcon}
        id={id}
        tooltip={tooltip}
        icon={ScorecardSvg({style:{ fill: 'rgb(51,65,85)', color: 'rgb(51,65,85)',...default_icon_styles }})}
    />

}
export { ScorecardsIcon }