import React, { FormEventHandler } from "react";
import { Button, Dialog, FormControl, IconButton, Paper, TextField, styled } from "@mui/material";
import { TbMessageStar } from "react-icons/tb";
import { topbarHeight } from "../../../components/DashboardContainer/DashboardContainer";
import { intesa_green } from "../../../themes/themes";
import { isMobile } from "react-device-detect";
import { IoClose } from "react-icons/io5";
import useWindowSize from "../../../utility/useWindowSize";
import { hasPermission } from "../../../store/hooks/useUserStore";
import { useUserContext } from "../../../store/hooks/useUserContext";
import { known_actions } from "../../../types/userStore";
import { BiEdit } from "react-icons/bi";
import { DateTimePicker } from '@mui/x-date-pickers/DateTimePicker';
import { LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import './styles.css'
import dayjs from "dayjs";
import { IUserNotificationGet, IUserNotificationPost, addUserNotification, deleteUserNotification, editUserNotification, getUserNotifications } from "../../../services/userNotification";
import { RiDeleteBin2Line } from "react-icons/ri";
import { MiniLoadingSpinner } from "../../../components/spinner/MiniLoadingSpinner/MiniLoadingSpinner";
export const user_notifications_local_storage_key = "READ_USER_NOTIFICATIONS"
export const UserNotificationsIcon: React.FC = () => {
    const startDate = dayjs().toISOString()
    const [isLoading, setIsLoading] = React.useState(false);
    const { width } = useWindowSize();
    const { userClaims, accessToken } = useUserContext();
    const iconPosition: Partial<React.CSSProperties> = {
        right: 10,
        bottom: topbarHeight + 10
    }
    const [userNotifications, setUserNotifications] = React.useState<IUserNotificationGet[]>([])
    const loadUserNotifications = React.useCallback(async () => {
        if (accessToken) {
            setIsLoading(true)
            getUserNotifications(startDate)
                .then((un) => setUserNotifications(un))
                .finally(() => setIsLoading(false))
        }
    }, [accessToken])
    React.useEffect(() => {
        loadUserNotifications();
    }, [loadUserNotifications])
    const defaultOpen = React.useMemo(() => {
        const read_user_notifications: number[] = JSON.parse(localStorage.getItem(user_notifications_local_storage_key) ?? "[]");
        return userNotifications.some((n) => !read_user_notifications.includes(n.Id))
    }, [userNotifications]);
    const [isOpen, setIsOpen] = React.useState(defaultOpen);
    React.useEffect(() => {
        if (userNotifications.length > 0 && isOpen) {
            localStorage.setItem(user_notifications_local_storage_key, JSON.stringify(userNotifications.map((n) => n.Id)))
        }
    }, [userNotifications, isOpen])
    React.useEffect(() => {
        if (defaultOpen) { setIsOpen(true) }
    }, [defaultOpen])
    const [edit, setEdit] = React.useState(false);
    const hasEditPermissions = hasPermission(userClaims, known_actions.edit_broadcast, 'sbh');
    const [formData, setFormData] = React.useState<IUserNotificationPost>({
        Title: '',
        Text: '',
        Start: dayjs().format(),
        End: dayjs().add(7, 'days').format()
    })
    const [openConfirmDeleteMsg, setOpenConfirmDeleteMsg] = React.useState<IUserNotificationGet | null>(null);
    const [editMsg, setEditMsg] = React.useState<IUserNotificationGet | null>(null);
    const editableKeys: (keyof IUserNotificationGet)[] = React.useMemo(() => ["Title", "Text", "Start", "End"], [])
    const validateFormData = React.useCallback((data: IUserNotificationGet | IUserNotificationPost) =>
        Object.entries(data).every(e => editableKeys.includes(e[0] as keyof IUserNotificationGet) ? e[1] as boolean : true), [editableKeys])
    const disabledSubmit = React.useMemo(() => editMsg ? !validateFormData(editMsg) : !validateFormData(formData), [editMsg, formData, validateFormData])
    const handleChange = (key: keyof IUserNotificationPost, value: any) => {
        const d = editMsg ?? formData;
        const f: any = editMsg ? setEditMsg : setFormData;
        const dateKeys = ["Start", "End"];
        const v = dateKeys.includes(key) ? dayjs(value) : value;
        f({ ...d, [key]: v });
    }
    const handleSubmitMessage: FormEventHandler<HTMLFormElement> = async (ev) => {
        ev.preventDefault();
        if (editMsg) {
            if (!validateFormData(editMsg)) {
                // setError("")
                console.error("EditMsg not valid", editMsg)
                return;
            } else {
                setIsLoading(true)
                editUserNotification(editMsg)
                    .then((newMsg) => {
                        setUserNotifications(userNotifications.map((n) => n.Id === newMsg.Id ? newMsg : n));
                        setEditMsg(null)

                    })
                    .finally(() => setIsLoading(false))
            }
        } else {
            if (!validateFormData(formData)) {
                // setError("")
                console.error("FormData not valid", formData)
                return;
            } else {
                setIsLoading(true)
                addUserNotification(formData)
                    .then(() => {
                        setUserNotifications([...userNotifications, formData as IUserNotificationGet]);
                        setFormData({
                            Title: '',
                            Text: '',
                            Start: dayjs().format(),
                            End: dayjs().add(7, 'days').format()
                        })

                    })
                    .finally(() => setIsLoading(false))
            }
        }
    }
    const handleDeleteMessage = async (msg: IUserNotificationGet) => {
        if (msg.Id) {
            setIsLoading(true);
            deleteUserNotification(msg)
                .then(() => setUserNotifications(userNotifications.filter((n) => n.Id !== msg.Id)))
                .catch((err) => {
                    console.error(err);
                    loadUserNotifications();
                })
                .finally(() => setIsLoading(false))
        } else {
            const handleReloadAndDelete = async () => {
                getUserNotifications(startDate)
                    .then((res) => {
                        const toDelete = res.find(n => n.Title === msg.Title && n.Text === msg.Text);
                        if (toDelete) {
                            setIsLoading(true);
                            deleteUserNotification(toDelete)
                                .then(() => setUserNotifications(res.filter((n) => n.Id !== null && n.Id !== toDelete.Id)))
                                .finally(() => setIsLoading(false))
                        }
                    })
            }
            await handleReloadAndDelete();
        }
    }

    const paper_zIndex = 99999;
    if (userNotifications.length === 0 && !hasEditPermissions) return null;
    return <>
        <IconButton
            onClick={() => setIsOpen(!isOpen)}
            className="!fixed"
            style={{ ...iconPosition, zIndex: 99999, backgroundColor: intesa_green, color: 'white' }}>
            <TbMessageStar />
        </IconButton>
        <Dialog
            open={openConfirmDeleteMsg !== null}
        >
            <div className="flex flex-col w-[100%] py-4 px-4 min-h-[200px]">
                <p className="text-2xl text-center w-[100%] flex">Sei sicuro di voler cancellare il messaggio <p className="mx-2 text-intesa-green font-bold"> {openConfirmDeleteMsg?.Title} ?</p></p>
                <div className="flex w-[100%] justify-between mt-auto self-end">
                    <StyledOutlinedButton
                        type="button"
                        variant="outlined"
                        color="primary"
                        onClick={() => setOpenConfirmDeleteMsg(null)}
                    >
                        Annulla
                    </StyledOutlinedButton>
                    <StyledContainedButton
                        type="button"
                        variant="contained"
                        color="primary"
                        onClick={() => {
                            if (openConfirmDeleteMsg) {
                                handleDeleteMessage(openConfirmDeleteMsg)
                                    .then(() => {
                                        setOpenConfirmDeleteMsg(null);
                                        setEditMsg(null);
                                    });
                            }
                        }}
                    >
                        Elimina
                    </StyledContainedButton>
                </div>
            </div>
        </Dialog>

        {isOpen &&
            <>
                <Paper
                    elevation={6}
                    className="!bg-white !text-black"
                    style={{
                        zIndex: paper_zIndex,
                        border: `2px solid ${intesa_green}`,
                        borderRadius: 10,
                        bottom: topbarHeight + 20,
                        padding: '10px 38px',
                        right: 60,
                        position: 'fixed',
                        maxWidth: isMobile ? 'calc(99vw - 60px)' : width && width > 2000 ? '50vw' : '70vw',
                        maxHeight: `calc(100vh - ${topbarHeight + 20 + 10}px)`,
                        minHeight: 250,
                        minWidth: 250,
                        overflow: 'auto'
                    }}
                >
                    {isLoading && <MiniLoadingSpinner
                        style={{
                            position: 'absolute',
                            right: 5,
                            bottom: 5,
                            width: 35,
                            height: 35,
                            fontSize: '1.25rem'
                        }}

                    />}
                    {hasEditPermissions &&
                        <IconButton
                            style={{
                                position: 'absolute',
                                right: 5,
                                top: 45,
                                width: 35,
                                fontSize: '1.25rem'
                            }}
                            onClick={() => setEdit(!edit)}
                        >
                            <BiEdit />
                        </IconButton>}
                    <IconButton
                        style={{
                            position: 'absolute',
                            right: 5,
                            top: 5,
                            width: 35,
                            fontSize: '1.25rem'
                        }}
                        onClick={() => setIsOpen(false)}>
                        <IoClose />
                    </IconButton>

                    {userNotifications.map((msg, i) => <div className={`flex items-start w-[100%] py-2 ${i < userNotifications.length - 1 ? 'border-b' : ''}`}>
                        {edit && <IconButton className="!my-auto" disabled={isLoading} onClick={() => setOpenConfirmDeleteMsg(msg)}><RiDeleteBin2Line /></IconButton>}
                        {edit && <IconButton className="!my-auto" disabled={isLoading} onClick={() => setEditMsg(msg)}><BiEdit /></IconButton>}
                        <>
                            <p className="w-[35%] text-xl text-intesa-green font-bold mr-2 my-auto">{msg.Title ?? 'Info'}</p>
                            <p key={msg.Id} className="text-lg my-auto max-w-[80%]">
                                {msg.Text}
                            </p>
                        </>
                    </div>)}
                    {edit && <form onSubmit={handleSubmitMessage} className="flex flex-col items-center">
                        <p className={`text-2xl w-[100%] pt-1 ${userNotifications.length > 0 ? 'border-t' : ''}`}>
                            {editMsg ? `Modifica msg ${editMsg.Id}-${editMsg.Title}` : "Aggiungi una notifica utente"}
                            {/* <IconButton
                                style={{
                                    marginLeft: 20,
                                    fontSize: '1.25rem'
                                }}
                                onClick={() => setEdit(false)}>
                                <IoClose />
                            </IconButton> */}
                        </p>
                        <FormControl className="flex items-center w-[100%] p-2">
                            <p>Titolo:</p>
                            <TextField
                                onChange={(ev) => handleChange("Title", ev.target.value)}
                                id="msg-title"
                                name="title"
                                value={editMsg ? editMsg.Title : formData.Title}
                                size="small"
                                fullWidth
                            />
                        </FormControl>
                        <FormControl className="flex items-center w-[100%] p-2">
                            <p>Messaggio:</p>
                            <TextField
                                multiline
                                onChange={(ev) => handleChange("Text", ev.target.value)}
                                value={editMsg ? editMsg.Text : formData.Text}
                                id="msg-text"
                                name="text"
                                size="small"
                                fullWidth
                            />

                        </FormControl>
                        <FormControl className="flex items-center w-[100%] p-2">
                            <p>Data di inizio:</p>
                            <LocalizationProvider dateAdapter={AdapterDayjs}>
                                <StyledDateTimePicker
                                    onChange={(value) => handleChange("Start", value)}
                                    value={editMsg ? dayjs(editMsg.Start) : dayjs(formData.Start)}
                                    defaultValue={dayjs()}
                                    viewRenderers={{
                                        hours: null,
                                        minutes: null,
                                        seconds: null,
                                    }}
                                    sx={{
                                        '.MuiPickersPopper-root': {
                                            zIndex: paper_zIndex + 1
                                        },
                                    }}
                                />
                            </LocalizationProvider>
                        </FormControl>
                        <FormControl className="flex items-center w-[100%] p-2">
                            <p>Data di fine:</p>
                            <LocalizationProvider dateAdapter={AdapterDayjs}>
                                <StyledDateTimePicker
                                    onChange={(value) => handleChange("End", value)}
                                    value={editMsg ? dayjs(editMsg.End) : dayjs(formData.End)}
                                    viewRenderers={{
                                        hours: null,
                                        minutes: null,
                                        seconds: null,
                                    }}
                                    sx={{
                                        '.MuiPickersPopper-root': {
                                            zIndex: paper_zIndex + 1
                                        },
                                    }}
                                />
                            </LocalizationProvider>
                        </FormControl>
                        <div
                            className="flex justify-between w-[100%] mt-2"
                        >
                            <StyledOutlinedButton
                                type="button"
                                variant="outlined"
                                color="primary"
                                onClick={() => {
                                    if (editMsg) {
                                        setEditMsg(null);
                                    }
                                    setEdit(false)

                                }}
                            >
                                Annulla
                            </StyledOutlinedButton>
                            <StyledContainedButton
                                type="submit"
                                variant="contained"
                                color="primary"
                                disabled={disabledSubmit}
                            >
                                {editMsg ? "Modifica" : "Aggiungi"}
                            </StyledContainedButton>
                        </div>
                    </form>}
                </Paper>
                <div style={{
                    bottom: topbarHeight + 26,
                    position: 'fixed',
                    right: 45,
                    width: 0,
                    height: 0,
                    zIndex: paper_zIndex + 1,
                    borderLeft: "8px solid rgb(37,137,0)",
                    borderRight: "8px solid transparent",
                    borderTop: "8px solid transparent",
                    borderBottom: "8px solid transparent",
                }} />
            </>}
    </>;
}
const StyledDateTimePicker = styled(DateTimePicker)({
    '.MuiPickersToolbar-root': {
        color: '#bbdefb',
        borderRadius: 2,
        borderWidth: 1,
        borderColor: '#2196f3',
        border: '1px solid',
        backgroundColor: '#0d47a1',
    },
})
export const StyledContainedButton = styled(Button)({
    // backgroundColor: intesa_green,
    // color: 'white',
    // '&:hover': {
    //     backgroundColor: intesa_green,
    // }
    boxShadow: 'none',
    color: 'white',
    textTransform: 'none',
    fontSize: 16,
    padding: '6px 12px',
    border: '1px solid',
    lineHeight: 1.5,
    backgroundColor: '#0063cc',
    borderColor: '#0063cc',
    fontFamily: [
        '-apple-system',
        'BlinkMacSystemFont',
        '"Segoe UI"',
        'Roboto',
        '"Helvetica Neue"',
        'Arial',
        'sans-serif',
        '"Apple Color Emoji"',
        '"Segoe UI Emoji"',
        '"Segoe UI Symbol"',
    ].join(','),
    '&:hover': {
        backgroundColor: '#0069d9',
        borderColor: '#0062cc',
        boxShadow: 'none',
    },
    '&:active': {
        boxShadow: 'none',
        backgroundColor: '#0062cc',
        borderColor: '#005cbf',
    },
    '&:focus': {
        boxShadow: '0 0 0 0.2rem rgba(0,123,255,.5)',
    },
})
export const StyledOutlinedButton = styled(Button)({
    // backgroundColor: 'white',
    // color: 'black',
    // borderColor: 'black',
    // '&:hover': {
    //     backgroundColor: intesa_green,
    //     color: 'white'
    // }
    boxShadow: 'none',
    color:'#0063cc',
    textTransform: 'none',
    fontSize: 16,
    padding: '6px 12px',
    border: '1px solid',
    lineHeight: 1.5,
    backgroundColor: 'whitesmoke',
    borderColor: 'white',
    fontFamily: [
        '-apple-system',
        'BlinkMacSystemFont',
        '"Segoe UI"',
        'Roboto',
        '"Helvetica Neue"',
        'Arial',
        'sans-serif',
        '"Apple Color Emoji"',
        '"Segoe UI Emoji"',
        '"Segoe UI Symbol"',
    ].join(','),
    '&:hover': {
        backgroundColor: '#0069d9',
        borderColor: '#0062cc',
        boxShadow: 'none',
        color:'white'
    },
    '&:active': {
        boxShadow: 'none',
        backgroundColor: '#0062cc',
        borderColor: '#005cbf',
    },
    '.Mui-disabled':{
        color:'white'
    },
    '&:focus': {
        boxShadow: '0 0 0 0.2rem rgba(0,123,255,.5)',
    },
})