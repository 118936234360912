import {
  ContextualMenu,
  DefaultButton,
  FontIcon,
  IDragOptions,
  IconButton,
  Modal,
  PrimaryButton,
} from "@fluentui/react";
import { Button } from "@mui/material";
import React from "react";
import { useNavigate } from "react-router-dom";
import { FlexCol } from "../../components/Flex/FlexCol";
import { FlexRow } from "../../components/Flex/FlexRow";
import { useMsal } from "@azure/msal-react";
type Props = {};
const InvalidLoginRequestPage: React.FC<Props> = () => {
  const navigate = useNavigate();
  const {instance} = useMsal();
  const navigateToLoginPage = React.useCallback(
    () => navigate("/login"),
    [navigate]
  );
  const hardReset = () => {
    localStorage.clear();
    sessionStorage.clear();
    instance.logout();
    window.location.reload();
  };
  const [resetModalOpen, setResetModalOpen] = React.useState(false);
  const dragOptions: IDragOptions = {
    moveMenuItemText: "Move",
    closeMenuItemText: "Close",
    menu: ContextualMenu,
    dragHandleSelector: ".ms-Modal-scrollableContent > div:first-child",
  };
  return (
    <div className="bg-slate-600 h-[100vh] w-[100vw] flex flex-col items-center pt-8">
      <div className="flex">
        <p className="text-xl text-white w-[100%] text-center">
          Accesso non autorizzato{" "}
        </p>
        <IconButton
          className="mr-4 rounded hover:bg-transparent"
          onClick={() => {
            setResetModalOpen(true);
          }}
        >
          <FontIcon
            iconName="SyncStatus"
            style={{
              fill: "white",
              stroke: "white",
              color: "white",
              objectFit: "contain",
              fontSize: 22,
            }}
          />
        </IconButton>
      </div>
      <Button
        onClick={navigateToLoginPage}
        variant="outlined"
        className="!bg-slate-800 !text-white"
      >
        Login
      </Button>
      <Modal
        isOpen={resetModalOpen}
        dragOptions={dragOptions}
        styles={{
          main: {
            maxWidth: 800,
            height: 150,
            backgroundColor: "rgb(51,65,85)",
            borderRadius: 4,
          },
          scrollableContent: { height: "100%", width: "100%" },
        }}
        onDismiss={() => setResetModalOpen(false)}
      >
        <FlexCol
          alignItems="center"
          justifyContent="space-between"
          className="h-[100%] p-2 text-white"
        >
          <p className="text-lg">
            Confermi di voler resettare la cache e rieffettuare la login?
          </p>
          <FlexRow className="w-[100%]" justifyContent="space-between">
            <DefaultButton
              text="Annulla"
              onClick={() => setResetModalOpen(false)}
            />
            <PrimaryButton text="Reset" onClick={() => hardReset()} />
          </FlexRow>
        </FlexCol>
      </Modal>
    </div>
  );
};
export { InvalidLoginRequestPage };
