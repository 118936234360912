import { createCtx } from "../store/general";
import { IPanelOptions, ISidebarOptions } from "../types/panels";
import { SetActionPayload, SetActionReducer } from "./hooks/useSetActions";

export const NAVIGATION_SET_ACTION = 'NAVIGATION_SET_ACTION';
export const DEFAULT_NAVIGATION_TYPE = "default";
export const PANEL_NAVIGATION_TYPE = "panel";
export enum NavigationStateKeysEnum {
    NAVIGATION_TYPE = "NAVIGATION_TYPE",
    PANEL = "PANEL",
    SIDEBAR = "SIDEBAR"
}
type NavigationTypes = typeof DEFAULT_NAVIGATION_TYPE | typeof PANEL_NAVIGATION_TYPE
type TState = {
    [NavigationStateKeysEnum.NAVIGATION_TYPE]: NavigationTypes,
    [NavigationStateKeysEnum.PANEL]: IPanelOptions,
    [NavigationStateKeysEnum.SIDEBAR]: ISidebarOptions,
}
// usage
const initialState: TState = {
    [NavigationStateKeysEnum.NAVIGATION_TYPE]: DEFAULT_NAVIGATION_TYPE,
    [NavigationStateKeysEnum.PANEL]: {
        isOpen: false,
        isClosable:true,
        isModal: true,
        title: "Dettagli",
        childrens: null,
        width: "xl",
        position: "right",
        transitionMovementType:"both",
        transitionResizeType:"both",
        offset:{
            x:0,
            y:0
        }
    },
    [NavigationStateKeysEnum.SIDEBAR]: {
        isOpen: false,
        isClosable:true,
        isModal: false,
        childrens: null,
        width: "custom",
        customWidth:300,
        position: "left",
        transitionMovementType:"horizontal",
        transitionResizeType:"none",
        offset:{
            x:0,
            y:0
        }
    },
}
type AppState = TState;
type Action =
    | { type: typeof NAVIGATION_SET_ACTION, payload: SetActionPayload }
    | { type: "SET_PANEL_OPTIONS", options: IPanelOptions }
    | { type: "SET_SIDEBAR_OPTIONS", options: ISidebarOptions }

function reducer(state: AppState, action: Action): AppState {
    switch (action.type) {
        case NAVIGATION_SET_ACTION:
            return SetActionReducer(action, state)
        case "SET_PANEL_OPTIONS":
            return { ...state, [NavigationStateKeysEnum.PANEL]: action.options }
        case "SET_SIDEBAR_OPTIONS":
            return { ...state, [NavigationStateKeysEnum.SIDEBAR]: action.options }
        default:
            return state;
    }
}

export const [navigationContext, NavigationProvider] = createCtx(reducer, initialState);