import { TFile } from "../components/FileExplorer/FileExplorer";
import { createCtx } from "../store/general";
import { TDateRange, TMetric } from "../types/alerts";
import {
  TGroupedProductAssets,
  TProduct,
  TProductAsset,
} from "../services/products";
import moment from "moment";
import {
  DESKTOP_ROW_DASHBOARD_CONTAINER_SIDEBAR_WIDTH_MINI,
  DESKTOP_ROW_DASHBOARD_CONTAINER_SIDEBAR_WIDTH_FULL,
  MOBILE_ROW_DASHBOARD_CONTAINER_SIDEBAR_WIDTH,
  ROW_DASHBOARD_DEFAULT_CLOSE_STORAGE_KEY,
} from "../components/RowDashboardContainer/RowDashboardContainerSidebar/RowDashboardContainerSidebar";
import { isMobile } from "react-device-detect";
import { IDashboardColumnsDimensions } from "../hooks/useProductDashboardDimensions";
export const NEWSLETTER_HASH_KEY = "news-letters-hash";
export enum EditableAssets {
  SCORECARDS = "SCORECARDS",
  REPORTS = "REPORTS",
  SPECIAL_REPORTS = "SPECIAL_REPORTS",
}
export enum SearchAssets {
  SCORECARDS = "SCORECARDS",
  REPORTS = "REPORTS",
  SPECIAL_REPORTS = "SPECIAL_REPORTS",
  SERVICES = "SERVICES",
}
type TState = {
  products: TProduct[];
  groupedProductAssets: TGroupedProductAssets;
  isLoaded: boolean;
  editMode: {
    [key in keyof typeof EditableAssets]: boolean;
  };
  addMode: {
    [key in keyof typeof EditableAssets]: boolean;
  };
  search: {
    [key in keyof typeof SearchAssets]: string;
  };
  alertDateFilter: TDateRange | null;
  metrics: { [key: string]: TMetric[] };
  deactiveMetrics: { [key: string]: TMetric[] };
  tutorials: { [key: string]: TProductAsset[] };
  news_letters: TProductAsset[];
  executiveReports: { [key: string]: TFile[] };
  viewDisabledAssets: boolean;
  rowDashboardContainersidebarWidth: number;
  settings: { [key: string]: IDashboardColumnsDimensions };
  isLoadingSettings: boolean;
  news_letters_hash: string | null;
};

const initialState: TState = {
  products: [],
  groupedProductAssets: {},
  isLoaded: false,
  editMode: {
    [EditableAssets.SCORECARDS]: false,
    [EditableAssets.REPORTS]: false,
    [EditableAssets.SPECIAL_REPORTS]: false,
  },
  addMode: {
    [EditableAssets.SCORECARDS]: false,
    [EditableAssets.REPORTS]: false,
    [EditableAssets.SPECIAL_REPORTS]: false,
  },
  search: {
    [SearchAssets.SCORECARDS]: "",
    [SearchAssets.REPORTS]: "",
    [SearchAssets.SPECIAL_REPORTS]: "",
    [SearchAssets.SERVICES]: "",
  },
  alertDateFilter: { startDate: moment().add(-1, "weeks").toDate() },
  metrics: {},
  deactiveMetrics: {},
  tutorials: {},
  executiveReports: {},
  news_letters: [],
  viewDisabledAssets: false,
  rowDashboardContainersidebarWidth: isMobile
    ? MOBILE_ROW_DASHBOARD_CONTAINER_SIDEBAR_WIDTH
    : JSON.parse(
        localStorage.getItem(ROW_DASHBOARD_DEFAULT_CLOSE_STORAGE_KEY) ?? "false"
      )
    ? DESKTOP_ROW_DASHBOARD_CONTAINER_SIDEBAR_WIDTH_MINI
    : DESKTOP_ROW_DASHBOARD_CONTAINER_SIDEBAR_WIDTH_FULL,
  settings: {},
  isLoadingSettings: false,
  news_letters_hash: localStorage.getItem(NEWSLETTER_HASH_KEY),
};
type AppState = TState;
type Action =
  | { type: "SET_LOADED"; payload: boolean }
  | { type: "SET_PRODUCTS"; payload: TProduct[] }
  | { type: "SET_GROUPED_PRODUCT_ASSETS"; payload: TGroupedProductAssets }
  | { type: "SET_STATE"; payload: TState }
  | {
      type: "TOGGLE_EDIT_MODE";
      payload: "reports" | "scorecards" | "specialReports" | "all";
    }
  | {
      type: "TOGGLE_ADD_MODE";
      payload: "reports" | "scorecards" | "specialReports";
    }
  | { type: "SET_ALERT_DATE_FILTER"; payload: TDateRange | null }
  | { type: "SET_METRICS"; payload: { [key: string]: TMetric[] } }
  | { type: "SET_DEACTIVE_METRICS"; payload: { [key: string]: TMetric[] } }
  | { type: "SET_TUTORIALS"; payload: { [key: string]: TProductAsset[] } }
  | { type: "SET_NEWS_LETTERS"; payload: TProductAsset[] }
  | { type: "SET_EXEC_REPORTS"; payload: { [key: string]: TFile[] } }
  | {
      type: "SET_SEARCH";
      payload: { [key in keyof typeof SearchAssets]: string };
    }
  | { type: "SET_VIEW_DISABLED_ASSETS"; payload: boolean }
  | { type: "SET_ROW_DASHBOARD_CONTAINER_SIDEBAR_WIDTH"; payload: number }
  | {
      type: "SET_SETTINGS";
      payload: { [key: string]: IDashboardColumnsDimensions };
    }
  | {
      type: "SET_ISLOADING_SETTINGS";
      payload: boolean;
    }
  | {
      type: "SET_NEWS_LETTERS_HASH";
      payload: string | null;
    };
function reducer(state: AppState, action: Action): AppState {
  switch (action.type) {
    case "SET_LOADED":
      return { ...state, isLoaded: action.payload };
    case "SET_PRODUCTS":
      return { ...state, products: action.payload };
    case "SET_GROUPED_PRODUCT_ASSETS":
      return { ...state, groupedProductAssets: action.payload };
    case "SET_STATE":
      return { ...state, ...action.payload };
    case "TOGGLE_EDIT_MODE": {
      switch (action.payload) {
        case "reports":
          return {
            ...state,
            editMode: {
              ...state.editMode,
              [EditableAssets.REPORTS]: !state.editMode[EditableAssets.REPORTS],
            },
          };
        case "specialReports":
          return {
            ...state,
            editMode: {
              ...state.editMode,
              [EditableAssets.SPECIAL_REPORTS]:
                !state.editMode[EditableAssets.SPECIAL_REPORTS],
            },
          };
        case "scorecards":
          return {
            ...state,
            editMode: {
              ...state.editMode,
              [EditableAssets.SCORECARDS]:
                !state.editMode[EditableAssets.SCORECARDS],
            },
          };
        case "all":
          return {
            ...state,
            editMode: {
              ...state.editMode,
              [EditableAssets.REPORTS]: !state.editMode[EditableAssets.REPORTS],
              [EditableAssets.SPECIAL_REPORTS]:
                !state.editMode[EditableAssets.SPECIAL_REPORTS],
              [EditableAssets.SCORECARDS]:
                !state.editMode[EditableAssets.SCORECARDS],
            },
          };
        default:
          return state;
      }
    }
    case "TOGGLE_ADD_MODE": {
      switch (action.payload) {
        case "reports":
          return {
            ...state,
            addMode: {
              ...state.addMode,
              [EditableAssets.REPORTS]: !state.addMode[EditableAssets.REPORTS],
            },
          };
        case "scorecards":
          return {
            ...state,
            addMode: {
              ...state.addMode,
              [EditableAssets.SCORECARDS]:
                !state.addMode[EditableAssets.SCORECARDS],
            },
          };
        case "specialReports":
          return {
            ...state,
            addMode: {
              ...state.addMode,
              [EditableAssets.SPECIAL_REPORTS]:
                !state.addMode[EditableAssets.SPECIAL_REPORTS],
            },
          };
        default:
          return state;
      }
    }
    case "SET_ALERT_DATE_FILTER": {
      return { ...state, alertDateFilter: action.payload };
    }
    case "SET_METRICS": {
      return { ...state, metrics: action.payload };
    }
    case "SET_DEACTIVE_METRICS": {
      return { ...state, deactiveMetrics: action.payload };
    }
    case "SET_TUTORIALS": {
      return { ...state, tutorials: action.payload };
    }
    case "SET_NEWS_LETTERS": {
      return { ...state, news_letters: action.payload };
    }
    case "SET_EXEC_REPORTS": {
      return { ...state, executiveReports: action.payload };
    }
    case "SET_VIEW_DISABLED_ASSETS": {
      return { ...state, viewDisabledAssets: action.payload };
    }
    case "SET_SEARCH": {
      return {
        ...state,
        search: {
          ...state.search,
          [SearchAssets.REPORTS]: action.payload.REPORTS,
          [SearchAssets.SPECIAL_REPORTS]: action.payload.SPECIAL_REPORTS,
          [SearchAssets.SCORECARDS]: action.payload.SCORECARDS,
          [SearchAssets.SERVICES]: action.payload.SERVICES,
        },
      };
    }

    case "SET_ROW_DASHBOARD_CONTAINER_SIDEBAR_WIDTH":
      return { ...state, rowDashboardContainersidebarWidth: action.payload };
    case "SET_SETTINGS":
      return { ...state, settings: action.payload };
    case "SET_ISLOADING_SETTINGS":
      return { ...state, isLoadingSettings: action.payload };
    case "SET_NEWS_LETTERS_HASH":
      return { ...state, news_letters_hash: action.payload };
    default:
      return state;
  }
}

export const [dashboardsContext, DashboardsProvider] = createCtx(
  reducer,
  initialState
);
