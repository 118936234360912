import { FontIcon } from '@fluentui/react';
import React from 'react';
import { IconProps } from './iconsConstants';
import { DashboardSideIcon } from './DashboardSideIcon';
import { ProductPageSection } from '../../../hooks/useProductDashboardDimensions';
const FilesIcon: React.FC<IconProps> = ({onlyIcon}) => {
    const icon = <FontIcon iconName="OneDriveFolder16" style={{fontSize:23}}/>
    const id = ProductPageSection.FILES
    const tooltip = {
        id:`${id}-dashboard_icon`,
        text:"Executive Reports"
    }
    // const icon = <GrOnedrive style={{...default_icon_styles,border:'1px solid black'}}/>
    return <DashboardSideIcon
        onlyIcon={onlyIcon}
        id={id}
    tooltip={tooltip}
    icon={icon}
/>
}
export { FilesIcon }