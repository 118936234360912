import React from 'react';
import { TSize } from '../../types/general';
type Props = {
    size:TSize
    direction:"up"|"down"|"left"|"right"
}
const Spacer:React.FC<Props> = ({size,direction}) => {
    const _direction = React.useMemo(()=>{
        switch(direction){
            case "up":return "t"
            case "down":return "b"
            case "left":return "l"
            case "right":return "r"
            default:return "t"
        }
    },[direction])
    const _size = React.useMemo(()=>{
        switch(size){
            case "s":return `m${_direction}-2`
            case "m":return `m${_direction}-4`
            case "l":return `m${_direction}-6`
            case "xl": return `m${_direction}-8`
            default:return `m${_direction}-2`
        }
    },[size])
    return <div className={_size} />
}
export {Spacer}