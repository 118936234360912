import React from 'react';
import useWindowSize from '../../utility/useWindowSize';
import { topbarHeight } from '../DashboardContainer/DashboardContainer';
const IntesaBottomLogo: React.FC<{sidebarWidth:number,customLeft?:string|number}> = ({sidebarWidth,customLeft}) => {
    const { width:windowWidth } = useWindowSize();
    const imgWidth = React.useMemo(()=>(windowWidth && windowWidth > 900) ? 400 : 200,[windowWidth])
    const imgSize = React.useMemo(()=>({
        width:imgWidth,
        height:topbarHeight,
        left:customLeft ?? `calc(((100vw - ${sidebarWidth}px)/ 2) - ${imgWidth/2}px )`
    }),[customLeft, imgWidth, sidebarWidth])
    // eslint-disable-next-line jsx-a11y/img-redundant-alt
    return <img style={{ position: 'absolute', bottom: 0, left: imgSize.left, width: imgSize.width, height: imgSize.height, objectFit: 'contain' }} src={require(`../../images/logo/IntesaLogoWhite.png`)} alt="Image Missing" />
    
}
export { IntesaBottomLogo }