import React from 'react';
import { IconProps } from './iconsConstants';
import './styles.css'
import { DashboardSideIcon } from './DashboardSideIcon';
import {RiAlertFill} from 'react-icons/ri'
import { default_icon_styles } from './constants';
import { ProductPageSection } from '../../../hooks/useProductDashboardDimensions';
const AlertsIcon: React.FC<IconProps> = ({onlyIcon}) => {
    // const icon = <Logo style={{ fill: 'rgb(51,65,85)', color: 'rgb(51,65,85)' }} />
    const icon = <RiAlertFill style={default_icon_styles}/>
    const id = ProductPageSection.ALERTS
    const tooltip = {
        id:`${id}-dashboard_icon`,
        text:"Segnalazioni"
    }
    return <DashboardSideIcon
        onlyIcon={onlyIcon}
        id={id}
        tooltip={tooltip}
        icon={icon}
    />
}
export { AlertsIcon }