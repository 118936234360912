import React from "react";
import { TAlert, TMetric } from "../../../types/alerts";
import { ExpandableContainer } from "../../ExpandableContainer/ExpandableContainer";
import { MetricRow } from "../MetricRow/MetricRow";
import "../styles.css";
type Props = {
  name?: string;
  description?: string;
  metrics: TMetric[];
  productId: string;
};
const ScorecardAlerts: React.FC<Props> = ({
  name,
  description,
  metrics,
  productId,
}) => {
  const alertIsActive: (a: TAlert) => boolean = (a: TAlert) => {
    if (a.IsActive) return true;
    let result = false;
    return result as boolean;
  };
  const someActiveAlert = React.useMemo(() => {
    let result = false;
    metrics.forEach((metric) => {
      if (metric.Alerts) {
        metric.Alerts.forEach(
          (alert) => (result = result || alertIsActive(alert))
        );
      }
      if (metric.Metrics) {
        metric.Metrics.forEach((m) => {
          if (m.Alerts) {
            m.Alerts.forEach(
              (alert) => (result = result || alertIsActive(alert))
            );
          }
        });
      }
    });
    return result;
  }, [metrics]);
  const alertsLength = React.useMemo(() => {
    let result = 0;
    const countLeafs = (a: TMetric) => {
      if (a.Alerts && a.Alerts.length > 0) {
        result += a.Alerts ? a.Alerts.length : 0;
      }
      if (a.Metrics) {
        a.Metrics.forEach((m) => countLeafs(m));
      }
    };
    metrics.forEach((m) => countLeafs(m));
    return result;
  }, [metrics]);
  const [lastOpenLevel, setLastOpenLevel] = React.useState(0);

  const [open, setOpen] = React.useState(false);
  const handleContainerOpen = () => {
    setOpen(!open);
    if (!open) {
      setLastOpenLevel(1);
    } else {
      setLastOpenLevel(0);
    }
  };
  const showCount = React.useMemo(() => {
    return lastOpenLevel === 0;
  }, [lastOpenLevel]);
  // const { productAssets } = useDashboardsStore();
  // const navigate = useNavigate();
  // const scorecards = React.useMemo(() => productAssets.filter((a) => a.ProductId === productId && a.Level.toString() === 'ProductPage' && a.AssetType.toString() === TAssetTypes.ScoreCard.toString()), [productAssets])

  return (
    <ExpandableContainer
      open={open}
      setOpen={handleContainerOpen}
      text={name ?? "--name error--"}
      textClassNames="max-w-[100%] truncate container-title mb-2 mt-1"
      iconPosition="beforeText"
      iconButtonClassNames="text-white text-xl my-auto hover:bg-transparent hover:text-white"
      textIsClickable
      headerAfterChildren={
        <>
          {/* <TooltipContainer
            tooltip={{
              text: description ?? '',
              id: `SCORECARD_${name}_DESCRIPTION`,
            }}
            containerStyle={{
              padding: 0,
              margin: 'auto 2px',
              borderRadius: 10,
            }}
            customContentStyles={{
              content: { fontSize: 22 },
              root: {
                padding: 8,
                margin: 0,
                borderRadius: 10,
                boxShadow: "3px 3px rgba(0,0,0,0.3)",
              },
            }}
          >
            <HelpIconBtn
              style={{ height: 20, width: 20, margin: "auto 6px 0px 3px" }}
            >
              <HelpIcon />
            </HelpIconBtn>
          </TooltipContainer> */}
          {showCount && (
            <p
              className={`max-w-[20%] text-xl font-bold ml-auto mr-4 my-auto text-white border ${
                someActiveAlert ? "bg-amber-600" : "bg-intesa-green"
              } rounded text-center px-4 py-1`}
            >
              {alertsLength}
            </p>
          )}
        </>
      }
    >
      {/* {alerts.map((alert) => (
            <AlertRow level={1} setLastOpen={setLastOpenLevel} productId={productId} alert={alert} lastOpen={lastOpenLevel} />
        ))} */}
      {metrics.map((m) => (
        <MetricRow
          key={m.MetricGuid}
          level={1}
          productId={productId}
          lastOpen={lastOpenLevel}
          setLastOpen={setLastOpenLevel}
          metric={m}
        />
      ))}
    </ExpandableContainer>
  );
};
export { ScorecardAlerts };
