import React from 'react'
import { settingsContext } from '../../../../../store/settingsStore'
import { StyledTextField } from '../../../../../components/StyledTextField/StyledTextField'
import { Checkbox, Chip, FormControl, Grid, IconButton, MenuItem, Select, SelectChangeEvent, Typography, } from '@mui/material'
import { StyledOutlinedButton } from '../../../../MainDashboard/UserNotificationsIcon/UserNotificationsIcon'
import { GoPersonAdd } from 'react-icons/go'
import { TiDeleteOutline } from "react-icons/ti";
import { CurrentConfigurationsList } from '../CurrentConfigurationsList'
import { CurrentSelectedMetrics } from '../CurrentSelectedMetrics'
import { AssignmentsStepper } from '../../AssignmentsStepper'
import { AssignmentPriorityEnum, TConfigurationFormData } from '../../../../../types/assignments'
import { IoAddCircleSharp } from 'react-icons/io5'
import { arraysAreSame } from '../../../../../utility/arrays'
import { TMetric } from '../../../../../types/alerts'

type Props = {
}
export const MetricAssignmentsForm: React.FC<Props> = () => {
    const { state: contextState, dispatch: contextDispatch } = React.useContext(settingsContext)
    const { configurationFormData, selectedMetrics } = contextState;
    const {
        NotificationUserMail: notificationUserMail,
        ClosureReason: closureReasons,
        Tags
    } = configurationFormData;
    const [newNotificationMail, setNewNotificationMail] = React.useState('');
    const [newClosureReason, setNewClosureReason] = React.useState('');
    const [newTag, setNewTag] = React.useState('');
    const label = { inputProps: { 'aria-label': 'Checkbox demo' } };
    React.useEffect(() => {
        let initialFormData: TConfigurationFormData = configurationFormData;
        const tryGetSimpleValue = (selector: (x: TMetric) => any, compareFunc: (x: TMetric) => boolean|undefined,defaultValue:any) => {
            if(!selectedMetrics){
                return defaultValue ?? ''
            }
            console.log(selectedMetrics)
            let metricsWithConfiguration = selectedMetrics?.filter((x) => x && x.AssignmentsConfiguration);
            if (metricsWithConfiguration
                && metricsWithConfiguration.length > 0
                && metricsWithConfiguration[0].AssignmentsConfiguration
                && metricsWithConfiguration.every(compareFunc)) {
                    return selector(metricsWithConfiguration[0])
            }
            return defaultValue ?? ''

        }
        const tryGetOwnerMail = () => {
            const defaultValue = '';
            if(!selectedMetrics){
                return defaultValue
            }
            let metricsWithConfiguration = selectedMetrics?.filter((x) => x && x.AssignmentsConfiguration);
            if(metricsWithConfiguration.length === 0){
                return defaultValue 
            }
            if (metricsWithConfiguration && metricsWithConfiguration.length > 0
                && metricsWithConfiguration[0].AssignmentsConfiguration
                && metricsWithConfiguration.every(val => metricsWithConfiguration && val.AssignmentsConfiguration?.OwnerMail === metricsWithConfiguration[0].AssignmentsConfiguration?.OwnerMail)) {
                return metricsWithConfiguration[0].AssignmentsConfiguration?.OwnerMail;
            }
            return ''
        }
        const tryGetNotificationUsers = () => {
            const defaultValue:string[] = [];
            if(!selectedMetrics){
                return defaultValue
            }
            let metricsWithConfiguration = selectedMetrics?.filter((x) => x && x.AutomaticNotification);
            if(metricsWithConfiguration.length === 0){
                return defaultValue 
            }
            if (metricsWithConfiguration && metricsWithConfiguration.length > 0
                && metricsWithConfiguration[0].AutomaticNotification
                && metricsWithConfiguration.every(val => metricsWithConfiguration && val.AutomaticNotification && metricsWithConfiguration[0].AutomaticNotification &&
                    arraysAreSame(val.AutomaticNotification,
                        metricsWithConfiguration[0].AutomaticNotification,
                        (x, y) => x.UserMail === y.UserMail))) {
                return metricsWithConfiguration[0].AutomaticNotification.map((x) => x.UserMail);
            }
            return []
        }
        const tryGetClosureReasons = () => {
            const defaultValue:string[] = [];
            if(!selectedMetrics){
                return defaultValue ?? ''
            }
            let metricsWithConfiguration = selectedMetrics?.filter((x) => x && x.AssignmentsConfiguration);
            if(metricsWithConfiguration.length === 0){
                return defaultValue 
            }
            if (metricsWithConfiguration && metricsWithConfiguration.length > 0
                && metricsWithConfiguration[0].AssignmentsConfiguration
                && metricsWithConfiguration.every(val => metricsWithConfiguration && val.AssignmentsConfiguration?.ClosureReason && metricsWithConfiguration[0].AssignmentsConfiguration?.ClosureReason &&
                    arraysAreSame(val.AssignmentsConfiguration.ClosureReason,
                        metricsWithConfiguration[0].AssignmentsConfiguration.ClosureReason,
                        (x, y) => x === y))) {
                return metricsWithConfiguration[0].AssignmentsConfiguration.ClosureReason;
            }
            return []
        }
        contextDispatch({
            type: "SET_STATE",
            payload: {
                ...contextState,
                configurationFormData: {
                    ...initialFormData,
                    OwnerMail: tryGetOwnerMail(),
                    NotificationUserMail: tryGetNotificationUsers(),
                    ClosureReason: tryGetClosureReasons(),
                    CanBeDelegated:tryGetSimpleValue((x)=>x.AssignmentsConfiguration?.CanBeDelegated,(x)=>x.AssignmentsConfiguration?.CanBeDelegated,false),
                    IsAutomaticClose:tryGetSimpleValue((x)=>x.AssignmentsConfiguration?.IsAutomaticClose,(x)=>x.AssignmentsConfiguration?.IsAutomaticClose,false),
                    IsEnable:tryGetSimpleValue((x)=>x.AssignmentsConfiguration?.IsEnable,(x)=>x.AssignmentsConfiguration?.IsEnable,false),
                }
            }
        })
    }, [])
    const handleAddNewNotificationMail = React.useCallback((mailparam?: string[]) => {
        let _newNotificationMail: string[] = [];
        if (!mailparam && newNotificationMail.split(';').length > 0) {
            _newNotificationMail = newNotificationMail.split(';')
        } else {
            _newNotificationMail = mailparam ?? [newNotificationMail]
        }
        _newNotificationMail = _newNotificationMail.filter((email) => !notificationUserMail.find((x) => x === email))
        const isValidMail = (email: string) => {
            return String(email)
                .toLowerCase()
                .match(
                    /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|.(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
                );
        }
        const notValidEmails = _newNotificationMail.some((email) => !isValidMail(email))
        if (notValidEmails) {
            return
        }
        else {
            contextDispatch({
                type: "SET_STATE",
                payload: {
                    ...contextState,
                    configurationFormData: {
                        ...configurationFormData,
                        NotificationUserMail: [...notificationUserMail, ..._newNotificationMail]
                    }
                }
            })
        }
    }, [configurationFormData, contextDispatch, contextState, newNotificationMail, notificationUserMail])
    const handleAddNewClosureReason = React.useCallback(() => {
        if (!closureReasons.find((x) => x === newClosureReason)?.length) {
            contextDispatch({
                type: "SET_STATE",
                payload: {
                    ...contextState,
                    configurationFormData: {
                        ...configurationFormData,
                        ClosureReason: [...closureReasons, newClosureReason]
                    }
                }
            })
        }
    }, [configurationFormData, contextDispatch, contextState, closureReasons, newClosureReason])
    const handleAddNewTag = React.useCallback(() => {
        if (!Tags.find((x) => x === newTag)?.length) {
            contextDispatch({
                type: "SET_STATE",
                payload: {
                    ...contextState,
                    configurationFormData: {
                        ...configurationFormData,
                        Tags: [...Tags, newTag]
                    }
                }
            })
        }
    }, [configurationFormData, contextDispatch, contextState, Tags, newTag])
    const handleRemoveNotificationMail = React.useCallback((email: string) => {
        contextDispatch({
            type: "SET_STATE",
            payload: {
                ...contextState,
                configurationFormData: {
                    ...configurationFormData,
                    NotificationUserMail: notificationUserMail.filter((x) => x !== email)
                }
            }
        })
    }, [configurationFormData, contextDispatch, contextState, notificationUserMail])
    const handleRemoveClosureReason = React.useCallback((reason: string) => {
        contextDispatch({
            type: "SET_STATE",
            payload: {
                ...contextState,
                configurationFormData: {
                    ...configurationFormData,
                    ClosureReason: closureReasons.filter((x) => x !== reason)
                }
            }
        })
    }, [configurationFormData, contextDispatch, contextState, closureReasons])
    const handleRemoveTag = React.useCallback((tag: string) => {
        contextDispatch({
            type: "SET_STATE",
            payload: {
                ...contextState,
                configurationFormData: {
                    ...configurationFormData,
                    Tags: Tags.filter((x) => x !== tag)
                }
            }
        })
    }, [configurationFormData, contextDispatch, contextState, Tags])
    const PriorityEnumMap: { [key in AssignmentPriorityEnum]: string } = {
        [AssignmentPriorityEnum.Low]: "Bassa",
        [AssignmentPriorityEnum.Medium]: "Media",
        [AssignmentPriorityEnum.High]: "Alta",
    }
    // const handleNewNotificationMailKeyDown:React.Key = React.useCallback((e)=>{

    // },[])
    return <div className='flex flex-col items-start min-h-[300px] w-[100%] text-white' style={{ maxHeight: 'calc(100vh - 50px)' }}>
        <div className="flex justify-between w-[100%] px-2">
            <div className="items-center flex">
                <p className='ml-2 text-xl'>Abilita:</p>
                <Checkbox
                    {...label}
                    // sx={{
                    //     color: green[400],
                    //     '&.Mui-checked': {
                    //         color: green[600],
                    //     },
                    // }}

                    name="isEnable"
                    checked={configurationFormData.IsEnable}
                    onChange={(x, checked) => contextDispatch({ type: "SET_STATE", payload: { ...contextState, configurationFormData: { ...configurationFormData, IsEnable: checked } } })}
                />

            </div>
        </div>
        <div className="mt-4" />
        <Grid container spacing={1}>
            <Grid item xs={12} sm={6}>
                <div className="flex flex-col w-[100%] border rounded-xl p-2" style={{ borderColor: 'rgba(240,240,240,0.2)' }}>
                    <p className="text-2xl text-center w-[100%] mb-4">Azioni Prescrittive</p>
                    <FormControl>
                        <Select
                            style={{ zIndex: 999999, backgroundColor: 'rgb(71 85 105)' }}
                            inputProps={{
                                zIndex: 999999
                            }}
                            variant="outlined"
                            className="!my-2 !text-white !bg-slate-700"
                            fullWidth
                            label="Priorità"
                            labelId="select-cfg-priority-label"
                            id="select-cfg-priority"
                            value={configurationFormData.Priority}
                            onChange={(e: SelectChangeEvent) =>
                                contextDispatch({ type: "SET_STATE", payload: { ...contextState, configurationFormData: { ...configurationFormData, Priority: e.target.value as unknown as AssignmentPriorityEnum } } })
                            }
                            MenuProps={{ style: { zIndex: 999999 } }}
                        >
                            {Object.entries(AssignmentPriorityEnum).map((x) => (
                                <MenuItem style={{ zIndex: 999999 }} value={x[0]}>{x[1]}</MenuItem>
                            ))}
                        </Select>
                    </FormControl>
                    <div className="mt-4" />
                    <FormControl>
                        <StyledTextField
                            required
                            autoComplete='off'
                            label="Owner Email"
                            name="ownerMail"
                            value={configurationFormData.OwnerMail}
                            onChange={(e) => contextDispatch({ type: "SET_STATE", payload: { ...contextState, configurationFormData: { ...configurationFormData, OwnerMail: e.target.value } } })}
                        />
                    </FormControl>
                    <div className="mt-4" />
                    <div className='flex items-center'>
                        <p className='ml-2 text-xl'>Chiusura Automatica:</p>
                        <Checkbox
                            {...label}
                            // sx={{
                            //     color: green[400],
                            //     '&.Mui-checked': {
                            //         color: green[600],
                            //     },
                            // }}

                            name="isAutomaticClose"
                            checked={configurationFormData.IsAutomaticClose}
                            onChange={(x, checked) => contextDispatch({ type: "SET_STATE", payload: { ...contextState, configurationFormData: { ...configurationFormData, IsAutomaticClose: checked } } })}
                        />
                    </div>
                    <div className='mt-4' />
                    <FormControl>
                        <div className="flex w-[100%]">
                            <StyledTextField
                                value={newClosureReason}
                                autoComplete='off'
                                label="Motivazioni chiusura"
                                fullWidth
                                name="closureReason"
                                onChange={(e) => setNewClosureReason(e.target.value)}
                            />
                            <IconButton
                                size='large'
                                onClick={handleAddNewClosureReason}
                            >
                                <IoAddCircleSharp style={{ color: 'white' }} />
                            </IconButton>
                        </div>
                        <div className="mt-2" />
                        {closureReasons.length > 0 ?
                            <Grid container spacing={1}>
                                {closureReasons.map((reason) =>
                                    <Grid item sm={12} md={6} lg={4}>
                                        <Chip
                                            onDelete={() => handleRemoveClosureReason(reason)}
                                            sx={{
                                                width: '100%',
                                                fontSize: '0.95rem',
                                                backgroundColor: '#0062cc',
                                                color: 'white'
                                            }}
                                            label={reason}
                                            deleteIcon={<TiDeleteOutline style={{ marginLeft: 'auto', color: 'white', fill: 'white' }} />}
                                        />
                                    </Grid>
                                )}
                            </Grid>
                            :
                            <Typography align="center" variant='h6'>
                                Nessuna Motivazione chiusura configurata, aggiungine almeno una.
                            </Typography>}
                    </FormControl>
                    <div className="mt-2" />
                    <FormControl>
                        <div className="flex w-[100%]">
                            <StyledTextField
                                value={newTag}
                                autoComplete='off'
                                label="Tags"
                                fullWidth
                                name="tags"
                                onChange={(e) => setNewTag(e.target.value)}
                            />
                            <IconButton
                                size='large'
                                onClick={handleAddNewTag}
                            >
                                <IoAddCircleSharp style={{ color: 'white' }} />
                            </IconButton>
                        </div>
                        <div className="mt-2" />
                        {Tags.length > 0 ?
                            <Grid container spacing={1}>
                                {Tags.map((tag) =>
                                    <Grid item sm={12} md={6} lg={4}>
                                        <Chip
                                            onDelete={() => handleRemoveTag(tag)}
                                            sx={{
                                                width: '100%',
                                                fontSize: '0.95rem',
                                                backgroundColor: '#0062cc',
                                                color: 'white'
                                            }}
                                            label={tag}
                                            deleteIcon={<TiDeleteOutline style={{ marginLeft: 'auto', color: 'white', fill: 'white' }} />}
                                        />
                                    </Grid>
                                )}
                            </Grid>
                            :
                            <Typography align="center" variant='h6'>
                                Nessun Tag configurato, aggiungine uno dalla textbox sopra.
                            </Typography>}
                    </FormControl>
                    <div className="mt-2" />
                    {/* <div className="items-center flex px-2">
                        <p className="text-xl text-white">Delegabile:</p>
                        <Checkbox
                            {...label}
                            // sx={{
                            //     color: green[400],
                            //     '&.Mui-checked': {
                            //         color: green[600],
                            //     },
                            // }}
                            name="canBeDelegated"
                            checked={configurationFormData.CanBeDelegated}
                            onChange={(x, checked) => contextDispatch({ type: "SET_STATE", payload: { ...contextState, configurationFormData: { ...configurationFormData, CanBeDelegated: checked } } })}
                        />
                    </div>
                    <div className="mt-4" /> */}

                </div>
            </Grid>
            <Grid item xs={12} sm={6}>
                <div className="flex flex-col w-[100%] border rounded-xl p-2" style={{ borderColor: 'rgba(240,240,240,0.2)' }}>
                    {/* <div className="items-center flex px-2">
                        <p className="text-xl text-white">Escalation:</p>
                        <Checkbox
                            {...label}
                            // sx={{
                            //     color: green[400],
                            //     '&.Mui-checked': {
                            //         color: green[600],
                            //     },
                            // }}
                            name="isEscalationRequired"
                            checked={configurationFormData.IsEscalationRequired}
                            onChange={(x, checked) => contextDispatch({ type: "SET_STATE", payload: { ...contextState, configurationFormData: { ...configurationFormData, IsEscalationRequired: checked } } })}
                        />
                    </div>
                    <div className="mt-4" />
                    <FormControl>
                        <StyledTextField
                            autoComplete='off'
                            disabled={!configurationFormData.IsEscalationRequired}
                            label="Email escalation"
                            name="escalationReceiversMail"
                            onChange={(e) => contextDispatch({ type: "SET_STATE", payload: { ...contextState, configurationFormData: { ...configurationFormData, EscalationReceiversMail: e.target.value } } })}
                            value={configurationFormData.EscalationReceiversMail}
                        />
                    </FormControl>
                    <div className="mt-4" />
                    <FormControl>
                        <StyledTextField
                            autoComplete='off'
                            disabled={!configurationFormData.IsEscalationRequired}
                            value={configurationFormData.IsEscalationRequired ? configurationFormData.EscalationTimeInDays : 0}
                            type="number"
                            label="Giorni per l'escalation:"
                            name="escalationTimeInDays"
                            onChange={(e) => contextDispatch({ type: "SET_STATE", payload: { ...contextState, configurationFormData: { ...configurationFormData, EscalationTimeInDays: parseInt(e.target.value) } } })}
                        />
                    </FormControl> */}
                    <p className="text-2xl text-center w-[100%] mb-4">Notifiche informative</p>
                    <FormControl>
                        <div className="flex w-[100%]">
                            <StyledTextField
                                value={newNotificationMail}
                                autoComplete='off'
                                label="Utenti Notificati"
                                fullWidth
                                name="receiversMail"
                                onPaste={(e) => {
                                    // console.log(e.clipboardData.getData('Text'));
                                    const v = e.clipboardData.getData('Text').split(';')
                                    handleAddNewNotificationMail(v)
                                }}
                                onChange={(e) => setNewNotificationMail(e.target.value)}
                            // onKeyDown={handleNewNotificationMailKeyDown}
                            // onBlur={handleAddNewNotificationMail}
                            />
                            <IconButton
                                size='large'
                                onClick={() => handleAddNewNotificationMail()}
                            >
                                <GoPersonAdd style={{ color: 'white' }} />
                            </IconButton>
                        </div>
                        <div className="mt-2" />
                        {notificationUserMail.length > 0 ?
                            <Grid container spacing={1}>
                                {notificationUserMail.map((email) =>
                                    <Grid item sm={12} md={6} lg={4}>
                                        <Chip
                                            onDelete={() => handleRemoveNotificationMail(email)}
                                            sx={{
                                                width: '100%',
                                                fontSize: '0.95rem',
                                                backgroundColor: '#0062cc',
                                                color: 'white'
                                            }}
                                            label={email}
                                            deleteIcon={<TiDeleteOutline style={{ marginLeft: 'auto', color: 'white', fill: 'white' }} />}
                                        />
                                    </Grid>
                                )}
                            </Grid>
                            :
                            <Typography align="center" variant='h6'>
                                Nessun utente verrà notificato (Aggiungine uno dalla textfield sopra).
                            </Typography>}
                    </FormControl>

                </div>
            </Grid>
        </Grid>
        <div className="mt-4" />
        <CurrentSelectedMetrics />
        <div className="mt-4" />
        <CurrentConfigurationsList />
        <AssignmentsStepper />
    </div >
}