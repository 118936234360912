import { Select, SelectChangeEvent, MenuItem } from '@mui/material'
import React from 'react'
import { isMobile } from 'react-device-detect'
import { settingsContext } from '../../../../../store/settingsStore'
import { known_products } from '../../../../../types/userStore'
import { StyledTextField } from '../../../../../components/StyledTextField/StyledTextField'
type Props = {
    isLoading: boolean,
    searchMetric: string | null,
    setSearchMetric: (x: string | null) => void
}
export const toolbarHeight = 60;
export const Step1Toolbar: React.FC<Props> = ({ isLoading, searchMetric, setSearchMetric }) => {
    const { state: contextState, dispatch: contextDispatch } = React.useContext(settingsContext)
    return <div className={`flex  w-[100%] items-center ${isMobile ? 'flex-col' : 'flex-row justify-between'}`} style={{ height: toolbarHeight }}>
        {/* <Grid container spacing={1} className='!w-[80%]'>
            {Object.entries(known_products).map((at) => at[1].id === contextState.selectedProductId ?
                <Grid item xs={12} sm={6} md={4}>
                    <StyledContainedButton >
                        {at[0]}
                    </StyledContainedButton>
                </Grid>
                :
                <Grid item xs={12} sm={6} md={4}>
                    <StyledOutlinedButton >
                        {at[0]}
                    </StyledOutlinedButton>
                </Grid>
            )}
        </Grid>
        <div className="ml-auto" /> */}
        <Select
            style={{ zIndex: 999999, backgroundColor: 'rgb(71 85 105)', marginRight: isMobile ? 0 : 10 }}
            inputProps={{
                zIndex: 999999
            }}
            variant="outlined"
            className="!my-2 !text-white !bg-slate-700"
            fullWidth
            label="Prodotto"
            labelId="product-select-label"
            id="product-select"
            value={contextState.selectedProductId ?? ''}
            disabled={isLoading}
            onChange={(e: SelectChangeEvent) =>
                contextDispatch({ type: "SET_SELECTED_PRODUCTID", payload: e.target.value as string })
            }
            MenuProps={{ style: { zIndex: 999999 } }}
        >
            {Object.entries(known_products).map((at) => (
                <MenuItem style={{ zIndex: 999999 }} value={at[1].id}>{at[0]}</MenuItem>
            ))}
        </Select>
        {contextState.currentStep === 1 && <StyledTextField
            onBlur={undefined} variant="outlined" placeholder="Cerca..." sx={{ '&fieldset': { backgroudColor: 'red' } }} value={searchMetric} onChange={(e) => setSearchMetric(e.target.value)} />}
    </div>
}