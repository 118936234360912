import React from "react";
import {Link} from "react-router-dom";
type Props = {
  id: string;
  targetUrl?: string;
  disabled?:boolean
  className: string;
  style: React.CSSProperties;
  onClick?: () => void;
  children?: React.ReactNode;
  openInNewTab?: boolean;
};
const PileWrapper: React.FC<Props> = ({
  id,
  onClick,
  targetUrl,
  className,
  style,
  children,
  openInNewTab,
  disabled,
}) => {
  if (openInNewTab && targetUrl) {
    console.log(targetUrl)
    const handleClick = () => disabled ? {} : window.open(targetUrl);
    return (
      <div id={id} className={className} style={style} onClick={handleClick}>
        {children}
      </div>
    );
  }
  return targetUrl && !disabled ? (
    <Link
      id={id}
      aria-disabled={disabled}
      to={targetUrl}
      className={className}
      style={style}
      onClick={disabled ? ()=> {} : onClick}
    >
      {children}
    </Link>
  ) : (
    <div id={id} className={className} style={style} onClick={disabled ? ()=> {} : onClick}>
      {children}
    </div>
  );
};
export {PileWrapper};
