import React from 'react';
import { useUserContext } from '../../store/hooks/useUserContext';
import { UserFacepile } from './UserFacepile/UserFacepile';

const UserProfileIcon: React.FC = () => {
    const {userImage} = useUserContext();
    const dropDownExtraStyles = React.useMemo(() => ({ backgroundColor: 'rgb(103,126,155)', right: 25, top: 65 }), [])
    const triangleExtraStyles = React.useMemo(() => ({
        borderBottom: '10px solid white',
        borderLeft: '10px solid transparent',
        borderRight: '10px solid transparent',
        top: -10,
        right:0,
    }), [])
    return <UserFacepile userImage={userImage} dropDownExtraStyles={dropDownExtraStyles} triangleExtraStyles={triangleExtraStyles} />
}
export { UserProfileIcon }