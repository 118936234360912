import React from "react";
import {
  ProductPageSection,
  useProductDashboardDimensions,
} from "../../hooks/useProductDashboardDimensions";
import { ServicesSection } from "../../components/DashboardSections/ServicesSection/ServicesSection";
import { columnElementID } from "../../types/stores/dashboardNavigationStore";
import { ServicesColumnHeader } from "../../components/Columns/Headers/ServicesColumnHeader";
import { ServicesIcon } from "../../components/Columns/Icons/ServicesIcon";
import { ReportIcon } from "../../components/Columns/Icons/ReportIcon";
import { ReportColumnHeader } from "../../components/Columns/Headers/ReportColumnHeader";
import { TAssetTypes } from "../../services/products";
import { ReportsSection } from "../../components/DashboardSections/ReportsSection/ReportsSection";
import { SpecialReportIcon } from "../../components/Columns/Icons/SpecialReportIcon";
import { ScorecardsIcon } from "../../components/Columns/Icons/ScorecardsIcon";
import { ScorecardsSection } from "../../components/DashboardSections/ScorecardsSection/ScorecardsSection";
import { TPileSize } from "../../types/general";
import useWindowSize from "../../utility/useWindowSize";
import { ScorecardColumnHeader } from "../../components/Columns/Headers/ScorecardColumnHeader";
import { SpecialReportColumnHeader } from "../../components/Columns/Headers/SpecialReportColumnHeader";
import { useQuery } from "../../hooks/useQuery";
import {
  QUERY_PAGE_KEY,
  SELECTED_ICON_QUERY_KEY,
} from "../../hooks/useQueryValues";
import { KPIColumnHeader } from "../../components/Columns/Headers/KPIColumnHeader";
import { KPISection } from "../../components/DashboardSections/KPISection/KPISection";
import { KPIIcon } from "../../components/Columns/Icons/KPIIcon";
import { FilesHeader } from "../../components/Columns/Headers/FilesHeader";
import { FilesSection } from "../../components/DashboardSections/FilesSection/FilesSection";
import { FilesIcon } from "../../components/Columns/Icons/FilesIcon";
import { AlertsIcon } from "../../components/Columns/Icons/AlertsIcon";
import { AlertsSection } from "../../components/DashboardSections/AlertsSection/AlertsSection";
import { AlertsHeader } from "../../components/Columns/Headers/AlertsHeader";
import { TutorialsIcon } from "../../components/Columns/Icons/TutorialsIcon";
import { TutorialsSection } from "../../components/DashboardSections/TutorialsSection/TutorialsSection";
import { TutorialsHeader } from "../../components/Columns/Headers/TutorialsHeader";
import { known_products } from "../../types/userStore";
import { useLocation } from "react-router-dom";
export const useDashboardsNavigation = () => {
  const {pathname} = useLocation();
  const getProductIdFromPath = React.useCallback(() => {
    const area_urls = Object.values(known_products).map((x) =>
      x.dashboard_url.slice(1)
    );
    const area_ids = Object.values(known_products).map((x) => x.id);
    let _ = document.URL.split("/")[3];
    if (_.includes("?")) {
      _ = _.slice(0, _.indexOf("?"));
    }
    const area_id = area_ids[area_urls.findIndex((x) => x === _)];
    return area_id ?? undefined;
  // eslint-disable-next-line react-hooks/exhaustive-deps
  },[pathname]);
  const productId = React.useMemo(() => getProductIdFromPath(), [getProductIdFromPath]);
  const { areaSettings } = useProductDashboardDimensions(productId);
  const query = useQuery();
  const page = React.useMemo(
    () => parseInt(query.get(QUERY_PAGE_KEY) ?? "0"),
    [query]
  );
  const { width } = useWindowSize();
  const sortSections = React.useCallback(
    (a: ProductPageSection, b: ProductPageSection) =>
      areaSettings && areaSettings[a] && areaSettings[b]
        ? (areaSettings[a]?.UIOrder ?? 0) - (areaSettings[b]?.UIOrder ?? 0)
        : 0,
    [areaSettings]
  );
  const visibleSections = React.useMemo(
    () => {
      return Object.values(ProductPageSection)
        .filter((x) => areaSettings && areaSettings[x]?.IsVisible)
        .sort(sortSections);
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [areaSettings] //sortSections already depends on areaSettings.
  );
  const defaultPileSize = React.useCallback((width?: number) => {
    if (!width) {
      return "x1" as TPileSize;
    }
    if (width > 1800) {
      return "x2" as TPileSize;
    } else if (width > 1300) {
      return "x1" as TPileSize;
    } else {
      return "x1" as TPileSize;
    }
  }, []);
  const pilesDimensions = React.useMemo(
    () => ({
      [columnElementID.SpecialReports]:defaultPileSize(width),
      [columnElementID.Reports]:defaultPileSize(width),
      [columnElementID.Tutorials]:defaultPileSize(width),
      [columnElementID.Scorecards]:defaultPileSize(width),
    }),
    [defaultPileSize,width]
  );
  const sectionsMap = React.useMemo(
    () => ({
      [ProductPageSection.SERVICES]: {
        settings: areaSettings?.SERVICES,
        header: <ServicesColumnHeader settings={areaSettings?.SERVICES} />,
        section: <ServicesSection />,
        icon: <ServicesIcon />,
      },
      [ProductPageSection.REPORTS]: {
        settings: areaSettings?.REPORTS,
        header: <ReportColumnHeader settings={areaSettings?.REPORTS} />,
        section: <ReportsSection reportType={TAssetTypes.Report} />,
        icon: <ReportIcon />,
      },
      [ProductPageSection.SPECIAL_REPORTS]: {
        settings: areaSettings?.SPECIAL_REPORTS,
        header: (
          <SpecialReportColumnHeader settings={areaSettings?.SPECIAL_REPORTS} />
        ),
        section: <ReportsSection reportType={TAssetTypes.SpecialReport} />,
        icon: <SpecialReportIcon />,
      },
      [ProductPageSection.SCORECARDS]: {
        settings: areaSettings?.SCORECARDS,
        header: <ScorecardColumnHeader settings={areaSettings?.SCORECARDS} />,
        section: <ScorecardsSection />,
        icon: <ScorecardsIcon />,
      },
      [ProductPageSection.KPI]: {
        settings: areaSettings?.KPI,
        header: <KPIColumnHeader settings={areaSettings?.KPI} />,
        section: <KPISection />,
        icon: <KPIIcon />,
      },
      [ProductPageSection.FILES]: {
        settings: areaSettings?.FILES,
        header: <FilesHeader settings={areaSettings?.FILES} />,
        section: <FilesSection />,
        icon: <FilesIcon />,
      },
      [ProductPageSection.ALERTS]: {
        settings: areaSettings?.ALERTS,
        header: <AlertsHeader settings={areaSettings?.ALERTS} />,
        section: <AlertsSection />,
        icon: <AlertsIcon />,
      },
      [ProductPageSection.TUTORIALS]: {
        settings: areaSettings?.TUTORIALS,
        header: <TutorialsHeader settings={areaSettings?.TUTORIALS} />,
        section: <TutorialsSection />,
        icon: <TutorialsIcon />,
      },
    }),
    [areaSettings]
  );
  const pageSections = React.useMemo(() => {
    const sortedSections = Object.values(
      visibleSections.map((id) => sectionsMap[id])
    )
      .filter((x) => x.settings && x.settings.IsVisible)
      .sort((a, b) =>
        a.settings && b.settings ? a.settings.UIOrder - b.settings.UIOrder : 0
      );
    const getCurrentElementsIndex = () => {
      let usedColumns = 0;
      let _page = page ?? 0;
      let i = 0;
      while (_page > 0) {
        let x = sortedSections[i];
        if (!x || !x.settings?.SectionColumns) break;
        usedColumns += x.settings?.SectionColumns;
        if (usedColumns >= 10) {
          _page--;
          usedColumns = 0;
        }
        i++;
      }
      return i;
    };
    const currentIndex = getCurrentElementsIndex();
    if (sortedSections.slice(currentIndex).length === 0) {
      return [];
    }
    let used_columns = 0;
    return sortedSections.slice(currentIndex).map((item) => {
      if (item.settings?.SectionColumns) {
        if (used_columns + item.settings?.SectionColumns <= 10) {
          used_columns += item.settings?.SectionColumns;
          return item;
        } else {
          return null;
        }
      }
      return null;
    });
  }, [page, sectionsMap, visibleSections]);
  const getSectionPage = React.useCallback(
    (id: ProductPageSection) => {
      const sortedSections = Object.entries(sectionsMap)
        .filter(([key, setting]) => setting.settings?.IsVisible)
        .sort((a, b) =>
          a[1].settings && b[1].settings
            ? a[1].settings.UIOrder - b[1].settings.UIOrder
            : 0
        );
      let _page = 0;
      let used_columns = 0;
      for (let [section_id, section] of sortedSections) {
        if (id === section_id) {
          return _page;
          // return `/${productId}?${PAGE_QUERY_KEY}=${_page}&${SELECTED_ICON_QUERY_KEY}=${id}`;
        }
        if (section.settings?.SectionColumns) {
          used_columns += section.settings.SectionColumns;
        }
        if (used_columns >= 10) {
          _page++;
          used_columns = 0;
        }
      }
    },
    [sectionsMap]
  );
  const getSectionPageUrl = React.useCallback(
    (id: ProductPageSection) => {
      return `/${productId}?${QUERY_PAGE_KEY}=${getSectionPage(
        id
      )}&${SELECTED_ICON_QUERY_KEY}=${id}`;
    },
    [getSectionPage, productId]
  );
  const maxPage = React.useMemo(() => {
    const sectionsPages: number[] = Object.values(ProductPageSection).map(
      (id) => getSectionPage(id) ?? -1
    );
    return Math.max(...sectionsPages);
  }, [getSectionPage]);
  return {
    productId,
    areaSettings,
    pageSections,
    currentIcons: visibleSections.map((id) => [
      id as string,
      sectionsMap[id].icon,
    ]) as [string, React.ReactNode][],
    pilesDimensions,
    getSectionPageUrl,
    getProductIdFromPath,
    maxPage,
  };
};
