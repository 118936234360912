import axios from "axios";
import { IColumnFilter, IColumnSorting, ODataResponse } from "../types/odata";
import { buildOdataUrl } from "../utility/odata";

export const getPagedOdataItems:(controller:string,
    skip:number,
    top:number,
    filters:IColumnFilter<any>[],
    sortings:IColumnSorting<any>[],
    onlyCount?:boolean,
    expand?:string
)
    =>
    Promise<ODataResponse<any[]>> = async (controller,skip,top,filters,sortings,onlyCount,expand) => {
    const url = buildOdataUrl(controller,skip,top,filters,sortings,onlyCount,expand);
    return axios.get(url).then((res)=>{
        return res.data})
}