import React from "react";
import { IUser, } from "../../services/user";
import { EditPanel } from "../EditPanel/EditPanel";
import { UserNotifications } from "./UserNotifications/UserNotifications";
type Props = {
  isOpen: boolean;
  onClose: (e: any) => void;
  user: IUser;
};
const UserNotificationsPanel: React.FC<Props> = ({
  isOpen,
  onClose,
  user,
}) => {
  
  if (!isOpen) {
    return null;
  }
  return (
    <EditPanel closePanel={onClose} title={`Notifiche Automatiche segnalazioni ${user.User}`}>
      <UserNotifications user={user} editable/>
    </EditPanel>
  );
};
export { UserNotificationsPanel };
