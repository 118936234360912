import React from 'react';
import { IconProps } from './iconsConstants';
import { DashboardSideIcon } from './DashboardSideIcon';
import { default_icon_styles } from './constants';
import { BsFillClipboardDataFill } from 'react-icons/bs';
import { ProductPageSection } from '../../../hooks/useProductDashboardDimensions';
// export const SpecialReportSvg:React.FC = ()=><Logo style={{ fill: 'rgb(51,65,85)', color: 'rgb(51,65,85)',...default_icon_styles }} />


export const SpecialReportSvg: React.FC = () => <BsFillClipboardDataFill style={{ ...default_icon_styles, marginTop: 4, marginRight: 4 }} />
const SpecialReportIcon: React.FC<IconProps> = ({onlyIcon}) => {
    const id = ProductPageSection.SPECIAL_REPORTS
    const tooltip = {
        id:`${id}-dashboard-icon`,
        text:"Report di business"
    }
    return <DashboardSideIcon
        onlyIcon={onlyIcon}
        id={id}
        tooltip={tooltip}
        icon={SpecialReportSvg({})}
    />
}
export { SpecialReportIcon }