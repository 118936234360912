import { FontIcon, IconButton } from "@fluentui/react";
import React from "react";
import { useNavigate } from "react-router-dom";
import {
  DashboardContainer,
  topbarHeight,
} from "../../components/DashboardContainer/DashboardContainer";
import { DashboardSection } from "../../components/DashboardSection/DashboardSection";
import { Pile } from "../../components/Pile/Pile";
import AssetImmImg from "../../images/products/assetimmobiliare/asset-immobiliare4.png";
import OccupancyImg from "../../images/products/occupancy/openspace.jpg";
import SBImg from "../../images/products/smartbuilding/iot.jpg";
import EnergyImg from "../../images/products/energy/fotovoltaico.jpg";
import "./styles.css";
import { DashboardConfigurationIcon } from "../../components/DashboardConfigurationIcon/DashboardConfigurationIcon";
import { DashboardFooter } from "../../components/DashboardFooter/DashboardFooter";
import { DashboardKPI } from "../../components/DashboardGauge/DashboardGauge";
import { TableKpi } from "../../components/KPI/TableKpi";
import {
  DASHBOARD_IMAGE_KEY,
  TAssetTypes,
  TProduct,
  TRowHelp,
} from "../../services/products";
import { MiniLoadingSpinner } from "../../components/spinner/MiniLoadingSpinner/MiniLoadingSpinner";
import { useDashboardsStore } from "../../store/hooks/useDashboardsStore";
import { DashboardColumn } from "../../components/DashboardColumn/DashboardColumn";
import { hasPermission } from "../../store/hooks/useUserStore";
import { useUserContext } from "../../store/hooks/useUserContext";
import useWindowSize from "../../utility/useWindowSize";
import { useMainDashboardDimensions } from "../../hooks/useMainDashboardDimensions";
import "./styles.css";
import { CustomSegmentLabelPosition } from "react-d3-speedometer";
import { FlexCol } from "../../components/Flex/FlexCol";
import { FlexRow } from "../../components/Flex/FlexRow";
import { withAITracking } from "@microsoft/applicationinsights-react-js";
import { reactPlugin } from "../../components/application-insights/AppInsights";
import { Spacer } from "../../components/Spacer/Spacer";
import {
  NeededClaims,
  known_actions,
  know_urls,
  known_products,
  sbh_area,
} from "../../types/userStore";
import { HelpIcon } from "../../components/HelpIcon/HelpIcon";
import { HelpIconBtn } from "../../components/HelpIconBtn/HelpIconBtn";
import { DoughnutKpi } from "../../components/KPI/DoughnutKpi";
import { highlightClassNames } from "../../themes/themes";
import { isMobile } from "react-device-detect";
import {
  AiOutlineArrowLeft,
  AiOutlineArrowRight,
} from "react-icons/ai";
import {
  MAIN_DASHBOARD_VIEW_TYPE,
  useQueryValues,
} from "../../hooks/useQueryValues";
import { NewsLettersContainer } from "../../components/NewsLettersContainer/NewsLettersContainer";
import { HelpPopup } from "../../components/HelpPopup/HelpPopup";
import { formatNumericValue } from "../../utility/formats";
import { EditProductPanel } from "../../components/EditProductPanel/EditProductPanel";
import { FormLinkIcon } from "./Icons/FormLinkIcon";
import { AssetConfigIcon } from "./Icons/AssetConfigIcon";
import { NewsLettersIcon } from "./Icons/NewsLettersIcon";
import { MainDashboardIconsContainer } from "./Icons/MainDashboardIconsContainer";
import { PromoVideoIcon } from "./Icons/PromoVideoIcon";
import { UserNotificationsIcon } from "./UserNotificationsIcon/UserNotificationsIcon";
import { emergency_mails } from "../../utility/emergency_mails";
import { assignments_known_urls } from "../../apps/assignments/types/userStore";
import { AppsIcon } from "./AppsIcon/AppsIcon";
export const PRODUCT_VIEW_TYPE = "product";
export const NEWSLETTER_VIEW_TYPE = "newsletter";
export const minDesktopWidth = 900;
export const isDesktopWidth = (width?: number) => width && width > minDesktopWidth;
const MainDashboard: React.FC = () => {
  const { pileWidth, page, productsSlice, setPage, width, height } =
    useMainDashboardDimensions();
  const { height: windowHeight } = useWindowSize();
  const rangeKpiContainerHeight =
    windowHeight && windowHeight > 1800 ? 325 : 315;
  const { queryValues, encodeValues } = useQueryValues();
  const view_type = queryValues[MAIN_DASHBOARD_VIEW_TYPE] ?? PRODUCT_VIEW_TYPE;
  const [state, setState] = React.useState<{
    isLoading: boolean;
    productsSlice: number;
    page: number;
    view_type: string | null;
  }>({
    isLoading: false,
    productsSlice,
    page: 0,
    view_type,
  });
  React.useEffect(() => {
    setState({ ...state, view_type });
  }, [view_type]);
  const {
    contextState,
    loadProductsAndAssets,
    news_letters_hash,
    hashNewsLetters,
  } = useDashboardsStore();
  const { products, groupedProductAssets, news_letters } = contextState;
  const navigate = useNavigate();
  const areaIcon = React.useCallback((product: TProduct) => {
    const productId = product.ProductId;
    const defaultImgStyles: React.CSSProperties = {
      objectFit: "fill",
      height: "calc(100% - 40px)",
      width: "100%",
      borderTop: "1px solid rgb(70,92,120)",
      borderRadius: "0px 0px 5px 5px",
      left: 0,
      top: 40,
      margin: "0px",
      position: "absolute",
      color: "white",
      fill: "white",
    };
    let src = product.Metadata[DASHBOARD_IMAGE_KEY];
    if (src) {
      return <img src={src} style={defaultImgStyles} />;
    }
    try {
      switch (productId) {
        case known_products["iot-connected-buildings"].id:
          return <img src={SBImg} style={defaultImgStyles} />;
        case known_products.energy.id:
          return <img src={EnergyImg} style={defaultImgStyles} />;
        case known_products.occupancy.id:
          return <img src={OccupancyImg} style={defaultImgStyles} />;
        case known_products.assetimmobiliare.id:
          return <img src={AssetImmImg} style={defaultImgStyles} />;
        default:
          return <img alt="" />;
      }
    } catch (error) {
      return <img src="" alt={`Image for ${productId} Missing`} />;
    }
  }, []);

  const { accessToken, userClaims } = useUserContext();

  React.useEffect(() => {
    if (accessToken && products.length === 0) {
      setState({ ...state, isLoading: true });
      loadProductsAndAssets().finally(() =>
        setState({ ...state, isLoading: false })
      );
    }
  }, [accessToken]);
  // const _hasUserConfigPermissions = React.useMemo(
  //   () =>
  //     hasPermission(userClaims, know_actions.main_dashboard_users, sbh_area),
  //   [userClaims]
  // );

  const _settingsIcon = React.useMemo(() => {
    // if (!_hasUserConfigPermissions) return undefined;
    return (
      <DashboardConfigurationIcon
        onClick={() => navigate(know_urls.user_settings)}
        text="Configurazioni"
        iconName="Settings"
      />
    );
    // }, [_hasUserConfigPermissions, navigate]);
  }, [navigate]);
  const _hasEditAssetsPermissions = React.useCallback(
    () => hasPermission(userClaims, known_actions.edit_assets, sbh_area),
    [userClaims]
  );
  const leftIconHeight = React.useMemo(
    () => (isMobile ? 30 : topbarHeight - 10),
    []
  );
  const _assetsConfigIconLeft = React.useMemo(
    () => leftIconHeight + 20,
    [leftIconHeight]
  );

  const dashboardSectionPadding_X = React.useMemo(() => "5%", []);
  const sortProducts = React.useCallback(
    (a: TProduct, b: TProduct) => a.UIPosition - b.UIPosition,
    []
  );
  const pages_len = React.useMemo(
    () =>
      Math.floor(
        products.filter((p) => p.IsEnabled && p.ProductId !== "sbh").length /
        productsSlice
      ),
    [products, productsSlice]
  );
  const handleNextPage = React.useCallback(
    (e: any) => {
      if (page + 1 > pages_len - 1) {
        setPage(0);
      } else {
        setPage(page + 1);
      }
    },
    [page, pages_len, setPage]
  );
  const handlePrevPage = React.useCallback(
    (e: any) => {
      if (page - 1 >= 0) {
        setPage(page - 1);
      } else {
        setPage(pages_len - 1);
      }
    },
    [page, pages_len, setPage]
  );
  const otherPageExists = React.useMemo(() => {
    return (
      productsSlice <
      products.filter((p) => p.IsEnabled && p.ProductId !== "sbh").length - 1
    );
  }, [products, productsSlice]);
  const showPage = React.useCallback(
    (i: number) =>
      i >= page * productsSlice && i < page * productsSlice + productsSlice,
    [page, productsSlice]
  );
  const areas_permissions = React.useMemo(() => {
    let result: { [url: string]: boolean } = {};
    Object.values(known_products).forEach((x) => {
      result[x.id] = hasPermission(
        userClaims,
        x.dashboard_url as keyof NeededClaims,
        x.id as any
      );
    });
    return result;
  }, [userClaims]);
  const tablesKpiID = "table-kpi";
  const [tableMaxHeight, setTableMaxHeight] = React.useState<
    number | undefined
  >(undefined);
  const setCurrentTableMaxHeight = React.useCallback(() => {
    if (isMobile || (width && width < 800)) return;
    const tables = document.getElementsByClassName(tablesKpiID);
    if (!tables || tables.length === 0) return;
    let _ = tables[0].getBoundingClientRect().height;
    for (let i = 0; i < tables.length; i++) {
      if (tables[i].getBoundingClientRect().height > _) {
        _ = tables[i].getBoundingClientRect().height;
      }
    }
    setTableMaxHeight(_);
  }, [width]);
  React.useEffect(() => {
    if (height && tableMaxHeight !== undefined) {
      setTableMaxHeight(undefined);
    }
    setCurrentTableMaxHeight();
  }, [height, width]);

  React.useEffect(() => {
    if (height && height < 1000 && tableMaxHeight !== undefined) {
      setTableMaxHeight(undefined);
    }
    setCurrentTableMaxHeight();
  }, [contextState.groupedProductAssets, height]);
  // React.useEffect(()=>{
  //     if(height && tableMaxHeight){
  //         setTableMaxHeight(undefined);
  //         setCurrentTableMaxHeight();
  //     }
  // },[height])
  const pagesArrowDim = React.useMemo(
    () => (isDesktopWidth(width) ? 40 : 20),
    [width]
  );
  const formatGaugeNumber = (n: any) => {
    return n.toLocaleString();
  };
  const [gaugeOpenHelp, setGaugeOpenHelp] = React.useState<null | string>(null);
  const gaugeHelps = React.useMemo(() => {
    let result: { [key: string]: TRowHelp[] } = {};
    if (groupedProductAssets[TAssetTypes.RangeKPI]) {
      products.forEach((product) => {
        let productResult: TRowHelp[] = [];
        if (groupedProductAssets[TAssetTypes.RangeKPI]) {
          groupedProductAssets[TAssetTypes.RangeKPI]
            ?.filter((gauge) => gauge.ProductId === product.ProductId)
            .forEach((s) =>
              productResult.push({
                Item: s.Name,
                Description: s.AssetBag.HelpDescription,
              })
            );
        }
        result[product.ProductId] = productResult;
      });
    }
    return result;
  }, [groupedProductAssets, products]);
  const doughnutHelps = React.useMemo(() => {
    let result: { [key: string]: TRowHelp[] } = {};
    if (groupedProductAssets[TAssetTypes.Doughnut]) {
      products.forEach((product) => {
        let productResult: TRowHelp[] = [];
        groupedProductAssets[TAssetTypes.Doughnut]
          ?.filter((d) => d.ProductId === product.ProductId)
          .forEach(
            (x) =>
            (productResult = x.AssetBag.Helps
              ? [...productResult, ...x.AssetBag.Helps]
              : productResult)
          );
        result[product.ProductId] = productResult;
      });
    }
    return result;
  }, [groupedProductAssets, products]);
  const handleGaugeHelpOpen = (e: any, productId: string | null) => {
    e.stopPropagation();
    setGaugeOpenHelp(productId);
  };
  const hasDefaultPermission = hasPermission(
    userClaims,
    known_actions.default_values,
    sbh_area
  );
  const hasProductEditPermission = hasPermission(
    userClaims,
    known_actions.edit_product,
    sbh_area
  );
  const [product_edit, setProductEdit] = React.useState<null | TProduct>(null);
  const handleOpenEditPanel = React.useCallback(
    (p: TProduct) => setProductEdit(p),
    []
  );

  return (
    <>
      <UserNotificationsIcon />
      <AppsIcon />
      <DashboardContainer
        showAllReportIcon
        showReset
        showUser
        showBorder
        containerStyles={{ justifyContent: "start", overflowY: "auto" }}
      >
        {state.isLoading && (
          <MiniLoadingSpinner
            containerClassNames="h-[100vh]"
            style={{ color: "white", marginTop: "calc(30vh - 20px)" }}
          />
        )}

        {state.view_type === "product" && (
          <DashboardSection
            title=""
            extraStyles={{
              overflowX: "hidden",
              justifyContent: "start",
              width: "100%",
              margin: "1px 0px",
              position: "relative",
              padding: `2px ${dashboardSectionPadding_X}`,
            }}
          >
            {otherPageExists && (
              <IconButton
                style={{
                  zIndex: 100,
                  height: pagesArrowDim * 2,
                  width: pagesArrowDim,
                  position: isDesktopWidth(width) ? "fixed" : 'absolute',
                  left: 5,
                  bottom: isDesktopWidth(width) ? "65vh" : '75vh',
                }}
                className="!rounded !bg-intesa-green hover:bg-intesa-green"
                onClick={handlePrevPage}
              >
                <AiOutlineArrowLeft className="text-white text-4xl" />
              </IconButton>
            )}
            {/* <p onClick={()=>navigate(known_urls.home)}>ASSIGNMENTS</p> */}
            {products
              .filter((p) => p.IsEnabled && p.ProductId !== "sbh")
              .sort(sortProducts)
              .map((product, i) => (
                <DashboardColumn
                  pileWidth={pileWidth}
                  show={showPage(i)}
                  key={`${product.ProductId}-column`}
                >
                  <div className="relative">
                    <Pile
                      id={product.ProductId}
                      productId={product.ProductId}
                      showHelp
                      disabled={!areas_permissions[product.ProductId]}
                      titlePosition="up"
                      titleCustomStyles={{
                        position: "absolute",
                        left: 0,
                        padding: "0px 10%",
                        height: 40,
                        borderRadius: "5px 5px 0px 0px",
                        top: 0,
                        width: "100%",
                        zIndex: 2,
                        color: !areas_permissions[product.ProductId]
                          ? "gray"
                          : "white",
                        backgroundColor: !areas_permissions[product.ProductId]
                          ? "lightgray"
                          : "rgb(41,52,72)",
                      }}
                      extraStyles={{
                        cursor: !areas_permissions[product.ProductId]
                          ? "not-allowed"
                          : "pointer",
                      }}
                      titleSize="xxl"
                      size="unset"
                      tooltip={{
                        id: product.ProductId,
                        text: product.Description ?? "",
                      }}
                      key={product.ProductId}
                      onClick={() => {
                        if (areas_permissions[product.ProductId]) {
                          navigate(`/${product.ProductId}?page=0`);
                        }
                      }}
                      title={product.Name}
                      img={areaIcon(product)}
                    />
                    {hasProductEditPermission && (
                      <IconButton
                        onClick={() => handleOpenEditPanel(product)}
                        className="!rounded !bg-slate-700 !hover:bg-slate-700 !text-white"
                        style={{
                          position: "absolute",
                          right: isDesktopWidth(width) ? 10 : 15,
                          top: 10,
                          zIndex: 9999,
                        }}
                      >
                        <FontIcon iconName="Edit" />
                      </IconButton>
                    )}
                  </div>
                  <FlexCol
                    alignItems="stretch"
                    className="w-[100%] flex flex-col h-[100%]"
                  >
                    <FlexRow
                      className="relative my-2 mx-2 mt-1 border-2 border-slate-500 rounded shadow-md"
                      alignItems="center"
                    >
                      <FlexCol className="w-[100%]">
                        <div className="w-[100%] h-[60px]">
                          {groupedProductAssets[TAssetTypes.Target]
                            ?.filter((g) => g.ProductId === product.ProductId)
                            .map((globalTarget) => (
                              <FlexRow
                                key={`${globalTarget.ProductId}-target-${globalTarget.Id}`}
                                justifyContent="space-around"
                                className="w-[100%] px-2"
                              >
                                {globalTarget.AssetBag?.TargetList?.map(
                                  (target, i) => (
                                    <FlexCol
                                      key={`${globalTarget.ProductId}-target-${globalTarget.Id}-${i}`}
                                      justifyContent={
                                        target.Value ? "center" : "top"
                                      }
                                      alignItems="center"
                                    >
                                      {target.Value !== null && (
                                        <>
                                          <p
                                            className={`!p-2 text-center text-sm  mt-2 ${hasDefaultPermission &&
                                              target.IsDefault
                                              ? highlightClassNames
                                              : ""
                                              }`}
                                            style={{ lineHeight: '0.9rem' }}
                                          >
                                            {target.Label}
                                            {target.IsDefault &&
                                              hasDefaultPermission &&
                                              target.Label
                                              ? "*"
                                              : ""}
                                          </p>
                                          <p className="text-center text-xxl font-bold">
                                            {`${formatNumericValue(
                                              parseFloat(target.Value),
                                              target.Format
                                            )} ${target.UoM}` ?? "-"}
                                          </p>
                                        </>
                                      )}
                                    </FlexCol>
                                  )
                                )}
                              </FlexRow>
                            ))}
                        </div>
                        <Spacer size="l" direction="down" />
                        <FlexRow
                          justifyContent="center"
                          className="w-[100%]"
                          alignItems="center"
                        >
                          {groupedProductAssets[TAssetTypes.RangeKPI]
                            ?.filter((g) => g.ProductId === product.ProductId)
                            .map((kpi, i) => (
                              <DashboardKPI
                                isDefault={kpi.AssetBag.IsDefault}
                                key={`${kpi.ProductId}-gauge-${i}`}
                                segmentColors={kpi.AssetBag.SegmentsColors}
                                customSegmentStops={kpi.AssetBag.Segments}
                                customSegmentLabels={kpi.AssetBag.SegmentsLabels?.map(
                                  (x) => ({
                                    text: x.text,
                                    position:
                                      x.position === "OUTSIDE"
                                        ? CustomSegmentLabelPosition.Outside
                                        : CustomSegmentLabelPosition.Inside,
                                    color: x.color,
                                  })
                                )}
                                // segmentColors={gaugesSettings[kpi.ProductId]?.segmentColors ?? []}
                                customWidth={
                                  groupedProductAssets[
                                    TAssetTypes.RangeKPI
                                  ]?.filter(
                                    (g) => g.ProductId === product.ProductId
                                  ).length === 1
                                    ? 250
                                    : 150
                                }
                                containerStyles={{
                                  position: "relative",
                                  maxWidth: `calc(${(1 /
                                    (groupedProductAssets[
                                      TAssetTypes.RangeKPI
                                    ]?.filter(
                                      (g) => g.ProductId === product.ProductId
                                    )?.length ?? 1)) *
                                    100
                                    }%)`,
                                  maxHeight: rangeKpiContainerHeight,
                                  height: rangeKpiContainerHeight - 50,
                                  flexGrow: 1,
                                }}
                                customHeight={
                                  groupedProductAssets[
                                    TAssetTypes.RangeKPI
                                  ]?.filter(
                                    (g) => g.ProductId === product.ProductId
                                  ).length === 1
                                    ? 150
                                    : 135
                                }
                                kpiType={"gauge"}
                                formatValue={(n: number) =>
                                  `${formatNumericValue(n, kpi.AssetBag.Format)} ${kpi.AssetBag.UoM ?? ""
                                  }`
                                }
                                formatSegment={formatGaugeNumber}
                                // ringWidth={gauges.filter((g) => g.ProductId === product.ProductId).length === 1 ? 30 : 25}
                                footerText={kpi.Name}
                                footerText2={kpi.Description}
                                title={
                                  kpi.AssetBag.DisplayTitle
                                    ? kpi.AssetBag.Title
                                    : ""
                                }
                                id={kpi.Id.toString()}
                                value={kpi.AssetBag.Value ?? 0}
                                percent={kpi.AssetBag.Value ?? 0}
                                limits={{
                                  min: kpi.AssetBag.Min ?? 0,
                                  max: kpi.AssetBag.Max ?? 0,
                                }}

                              // customSegmentStops={gaugesSettings[kpi.ProductId]?.customSegmentStops ?? []}
                              // customSegmentLabels={gaugesSettings[kpi.ProductId]?.customSegmentLabels ?? []}
                              />
                            ))}
                        </FlexRow>
                        {(groupedProductAssets[TAssetTypes.Doughnut]?.filter(
                          (g) => g.ProductId === product.ProductId
                        )?.length ?? 0) > 0 ? (
                          <div
                            style={{
                              height: rangeKpiContainerHeight - 45,
                              maxHeight: rangeKpiContainerHeight,
                            }}
                            className="flex items-center justify-center w-[100%]"
                          >
                            <DoughnutKpi
                              key={`${product.ProductId}-doughnuts`}
                              noContainer
                              assets={
                                groupedProductAssets[
                                  TAssetTypes.Doughnut
                                ]?.filter(
                                  (g) => g.ProductId === product.ProductId
                                ) ?? []
                              }
                            />
                          </div>
                        ) : null}
                        <HelpIconBtn
                          onClick={(e: any) =>
                            handleGaugeHelpOpen(
                              e,
                              gaugeOpenHelp === product.ProductId
                                ? null
                                : product.ProductId
                            )
                          }
                          style={{
                            position: "absolute",
                            right: -10,
                            bottom: -10,
                            width: 25,
                            height: 25,
                          }}
                        >
                          <HelpIcon style={{ height: 25, width: 25 }} />
                        </HelpIconBtn>

                        <HelpPopup
                          onClose={() => setGaugeOpenHelp(null)}
                          isOpen={gaugeOpenHelp === product.ProductId}
                          helps={[
                            ...(gaugeHelps[product.ProductId] ?? []),
                            ...(doughnutHelps[product.ProductId] ?? []),
                          ]}
                          width={350}
                        />
                      </FlexCol>
                    </FlexRow>
                    <div className="mt-1">
                      {groupedProductAssets[TAssetTypes.CardKPI]
                        ?.filter((g) => g.ProductId === product.ProductId)
                        .map((kpi) => (
                          <TableKpi
                            additionalInfos={kpi.AssetBag.AdditionalInfos}
                            maxHeight={tableMaxHeight}
                            key={`${kpi.ProductId}-tableKpi`}
                            sections={kpi.AssetBag.Section ?? []}
                          />
                        ))}
                    </div>
                    <div className="mt-1" />
                  </FlexCol>
                </DashboardColumn>
              ))}
            {otherPageExists && (
              <IconButton
                style={{
                  zIndex: 100,
                  height: pagesArrowDim * 2,
                  width: pagesArrowDim,
                  position: isDesktopWidth(width) ? "fixed" : 'absolute',
                  right: 5,
                  bottom: isDesktopWidth(width) ? "65vh" : '75vh',
                }}
                className="!rounded !bg-intesa-green hover:bg-intesa-green"
                onClick={handleNextPage}
              >
                <AiOutlineArrowRight className="text-white text-4xl" />
              </IconButton>
            )}
          </DashboardSection>
        )}
        {state.view_type === "newsletter" && (
          <NewsLettersContainer
            assets={groupedProductAssets[TAssetTypes.Newsletter] ?? []}
          />
        )}
        <DashboardFooter
          showBorder
          leftIcons={
            <MainDashboardIconsContainer>
              <FormLinkIcon
                tooltip={{
                  text: "Form suggerimenti SBHub",
                  id: "SUGG_FORM_SBHUB",
                }}
              />
              {_hasEditAssetsPermissions() && (
                <AssetConfigIcon
                  tooltip={{
                    text: "Pagina configurazione Asset",
                    id: "ASSET_CONFIG_PAGE",
                  }}
                />
              )}
              <NewsLettersIcon
                onClick={() =>
                  navigate(
                    encodeValues("", [MAIN_DASHBOARD_VIEW_TYPE], {
                      ...queryValues,
                      [MAIN_DASHBOARD_VIEW_TYPE]:
                        view_type === PRODUCT_VIEW_TYPE
                          ? NEWSLETTER_VIEW_TYPE
                          : PRODUCT_VIEW_TYPE,
                    })
                  )
                }
              />
              <PromoVideoIcon
                tooltip={{
                  text: "Video promozionale SB Hub",
                  id: "PROMO_VIDEO_SBHUB",
                }} />
            </MainDashboardIconsContainer>
          }
          rightIcon={_settingsIcon}
        />
      </DashboardContainer>
      {product_edit && <EditProductPanel product={product_edit} closePanel={() => setProductEdit(null)} />}
    </>
  );
};
export default withAITracking(reactPlugin, MainDashboard);
