import React from "react"
import { Checkbox } from "@mui/material"
import { TMetric } from "../../../types/alerts"
import { ExpandableContainer } from "../../ExpandableContainer/ExpandableContainer"
import { MetricLeaf } from "./MetricLeaf"
import { TMetricNotificationsState } from "./UserNotifications"

type Props = {
    metric: TMetric,
    state: TMetricNotificationsState,
    setState: (state: TMetricNotificationsState) => void,
    editable?: boolean,
}
export const MetricExpandableContaier: React.FC<Props> = ({ metric, state, setState, editable }) => {
    const handleParentChange = React.useCallback((metric: TMetric) => {
        if (metric.Metrics && metric.Metrics.length > 0) {
            const value = !state[metric.Metrics[0].Id] as boolean;
            let newState :TMetricNotificationsState = {};
            Object.assign(newState,state)
            for (let id of metric.Metrics.map((x) => x.Id)) {
                newState[id] = value
            }
            setState(newState);
        }
    }, [state])
    const handleLeafChange =(m: TMetric) => {
        if (!editable) return;
        setState({ ...state, [m.Id]: !state[m.Id] })
    }
    const isLeafMetricChecked = React.useCallback((m:TMetric)=>state[m.Id] === true, [state]);
    const isParentMetricChecked = React.useCallback((m:TMetric)=>metric.Metrics && metric.Metrics.every(isLeafMetricChecked), [state]);
    return <>
        <ExpandableContainer
            containerClassNames="flex w-[100%]"
            headerAfterChildren={<Checkbox
                style={{ color: 'white', marginLeft: 'auto' }}
                checked={isParentMetricChecked(metric)}
                onChange={() => handleParentChange(metric)}
            />}
            textClassNames="text-2xl"
            textIsClickable
            iconPosition="beforeText"
            text={metric.Name}
            defaultOpen={false}
        >
            <>
                <div className="flex flex-col border p-2 ml-auto w-[95%] rounded-lg">{metric.Metrics && metric.Metrics.map((m) => m.Metrics !== undefined && m.Metrics !== null
                    ? <MetricExpandableContaier 
                        metric={m}
                        state={state}
                        setState={setState}
                        editable={editable}
                    />
                    : <MetricLeaf 
                        metric={m}
                        onChange={handleLeafChange}
                        checked={isLeafMetricChecked(m)}
                        editable={editable}
                    />)}</div>
                <div className="mb-4" />
            </>
        </ExpandableContainer>
        <div className="mb-4" />
    </>

}