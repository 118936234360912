import React from 'react'
import { TICKETS_CONTROLLER } from '../../services/eticket'
import { Grid } from '@mui/material'
import { DetailsContainer } from '../DetailsContainer/DetailsContainer'
import { DetailsLabelValueContainer } from '../DetailsLabelValueContainer/DetailsLabelValueContainer'
import { DetailsLabel } from '../DetailsLabel/DetailsLabel'
import { DetailsValue } from '../DetailsValue/DetailsValue'
import { formatDate } from '../../utility/formatDate'
import { ETicket } from '../../types/etickets'
import { EticketSourceTypeEnum } from '../../types/assignments'
import { globalContext } from '../../store/globalStore'
import { getPagedOdataItems } from '../../services/generic'
import { IColumnFilter } from '../../types/odata'
import { APIError } from '../../types/general'
import { TopBarLoading } from '../TopBarLoading/TopBarLoading'
import { DetailsTitle } from '../DetailsTitle/DetailsTitle'
import { DetailNavigatorIcon } from '../DetailNavigatorIcon/DetailNavigatorIcon'
import { assignments_known_urls } from '../../types/userStore'
import { useUserContext } from '../../../../store/hooks/useUserContext'
type Props = {
    sourceType: EticketSourceTypeEnum
    id: number
}
export const ETicketEventDetail: React.FC<Props> = ({ sourceType, id }) => {

    const { authenticatedCall } = useUserContext();
    const [isLoading, setIsLoading] = React.useState(false);
    const { dispatch: globalDispatch } = React.useContext(globalContext);
    const [tickets, setTickets] = React.useState<ETicket[]>()
    const odataFilters: IColumnFilter<ETicket>[] = React.useMemo(() => [
        {
            columnName: "SourceType",
            value: sourceType,
            valueType: "enum",
            operator: "eq"
        },
        {
            columnName: "ExternalEventId",
            value: id,
            valueType: "number",
            operator: "eq"
        }
    ], [id, sourceType])
    React.useEffect(() => {
        const _load = async () => {
            setIsLoading(true);
            getPagedOdataItems(TICKETS_CONTROLLER, 0, 50, odataFilters, [], false)
                .then((res) => setTickets(res.value))
                .catch((err: APIError) => globalDispatch({ type: "SET_ERROR", payload: { message: err.response.data.Message, details: err.toString() } }))
                .finally(() => setIsLoading(false))
        }
        authenticatedCall(_load);
    }, [globalDispatch, odataFilters, authenticatedCall])
    if (isLoading) {
        return <TopBarLoading />
    }
    if (!alert) return null;

    // return <div className="flex flex-col items-center w-[100%]" style={{ padding: 5 }}>
    return <Grid item xs={12} sm={12} md={6}>
        {isLoading ? <TopBarLoading /> : <>
            <DetailsContainer fullWidth>
                {tickets && tickets.length > 0 ? tickets?.map((ticket) =>
                    <>

                        <DetailsTitle text="Ticket" />
                        <DetailNavigatorIcon url={assignments_known_urls.ticket_detail} id={ticket.Id} />
                        <DetailsLabelValueContainer roundedUp>
                            <DetailsLabel label='ID' />
                            <DetailsValue value={ticket.Id} />
                        </DetailsLabelValueContainer>
                        <DetailsLabelValueContainer>
                            <DetailsLabel label='ExternalEventId' />
                            <DetailsValue value={ticket.ExternalEventId} />
                        </DetailsLabelValueContainer>
                        <DetailsLabelValueContainer>
                            <DetailsLabel label='ExternalReferenceId' />
                            <DetailsValue value={ticket.ExternalReferenceId} />
                        </DetailsLabelValueContainer>
                        <DetailsLabelValueContainer>
                            <DetailsLabel label='CRImmobile' />
                            <DetailsValue value={ticket.CRImmobile} />
                        </DetailsLabelValueContainer>
                        <DetailsLabelValueContainer>
                            <DetailsLabel label='Causale' />
                            <DetailsValue value={ticket.Causale} />
                        </DetailsLabelValueContainer>
                        <DetailsLabelValueContainer>
                            <DetailsLabel label='OpeningRequestStatus' />
                            <DetailsValue value={ticket.OpeningRequestStatus} />
                        </DetailsLabelValueContainer>
                        <DetailsLabelValueContainer>
                            <DetailsLabel label='ClosureRequestStatus' />
                            <DetailsValue value={ticket.ClosureRequestStatus} />
                        </DetailsLabelValueContainer>
                        <DetailsLabelValueContainer>
                            <DetailsLabel label='OpeningRequestTime' />
                            <DetailsValue value={formatDate(ticket.OpeningRequestTime)} />
                        </DetailsLabelValueContainer>
                        <DetailsLabelValueContainer>
                            <DetailsLabel label='ClosureRequestTime' />
                            <DetailsValue value={formatDate(ticket.ClosureRequestTime)} />
                        </DetailsLabelValueContainer>
                        <DetailsLabelValueContainer>
                            <DetailsLabel label='OpeningTime' />
                            <DetailsValue value={formatDate(ticket.OpeningTime)} />
                        </DetailsLabelValueContainer>
                        <DetailsLabelValueContainer>
                            <DetailsLabel label='ClosureTime' />
                            <DetailsValue value={formatDate(ticket.ClosureTime)} />
                        </DetailsLabelValueContainer>
                        <DetailsLabelValueContainer>
                            <DetailsLabel label='CodiceAmbito' />
                            <DetailsValue value={ticket.CodiceAmbito} />
                        </DetailsLabelValueContainer>
                        <DetailsLabelValueContainer>
                            <DetailsLabel label='NomeSpecifica' />
                            <DetailsValue value={ticket.NomeSpecifica} />
                        </DetailsLabelValueContainer>
                        <DetailsLabelValueContainer>
                            <DetailsLabel label='Description' />
                            <DetailsValue value={ticket.Description} />
                        </DetailsLabelValueContainer>
                    </>
                )
                    :
                    <p className='text-xl text-center w-[100%]'>Nessun ticket creato</p>
                }
            </DetailsContainer>
        </>
        }
    </Grid>
}