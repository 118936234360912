import { createCtx } from "../store/general";
import { desktopInitialBottombarHeight, desktopInitialSidebarWidth } from "../utility/useIsMobile";
export type TState = {
    sidebarWidth:number|undefined
    bottombarHeight:number|undefined
};

// usage
const initialState: TState = {
    sidebarWidth:desktopInitialSidebarWidth,
    bottombarHeight:desktopInitialBottombarHeight,
};
type AppState = TState;
type Action = { type: "SET_STATE"; payload: TState }

function reducer(state: AppState, action: Action): AppState {
  switch (action.type) {
    case "SET_STATE":
      return action.payload;
    default:
      return state;
  }
}

export const [sidebarsContext, SidebarsProvider] = createCtx(reducer, initialState);
