import { IconButton, Menu, MenuItem } from '@mui/material';
import React from 'react';
type TOption = {
    value: any,
    key: string,
    label: string
}
type Props = {
    options: TOption[]
    icon: JSX.Element
    handleItemClick: (e: React.MouseEvent<HTMLLIElement>,item:TOption) => void
}
const IconButtonMenu: React.FC<Props> = ({ icon, options, handleItemClick }) => {
    const [anchorEl, setAnchorEl] = React.useState(null);
    const handleClick = (e: any) => {
        setAnchorEl(e.currentTarget);
    };
    const handleClose = () => setAnchorEl(null);
    return <><IconButton
        aria-controls="simple-menu"
        aria-haspopup="true"
        onClick={handleClick}>
        {icon}
    </IconButton>
        <Menu
            id="simple-menu"
            anchorEl={anchorEl}
            keepMounted
            open={Boolean(anchorEl)}
            onClose={handleClose}
            anchorOrigin={{
                vertical: 'top',
                horizontal: 'left',
            }}
            transformOrigin={{
                vertical: 'top',
                horizontal: 'left',
            }}
        >
            {options.map(item => (
                <MenuItem onClick={(e) => { handleItemClick(e,item); handleClose(); }} key={item.key} value={item.value}>
                    {item.label}
                </MenuItem>
            ))}
        </Menu>
    </>
}
export { IconButtonMenu }