import React from 'react'
import { ISortableHeader } from '../../../../types/general'
import { IconButton, Typography } from '@mui/material'
import { useHeaderCell } from '../../../HeaderCell/hooks/useHeaderCell'
import { PaginationEnum } from '../../../../store/pager'
import { IoNavigateSharp } from 'react-icons/io5'
import { useNavigate } from 'react-router-dom'
type PROPS = {
    header: ISortableHeader<any>
    x: any
    isLoading: boolean,
    enumStoreKey: PaginationEnum
}
export const statusClassNames = (status:string)=>{
    let _ = status.toLowerCase();
    switch(_){
        case "active":return "!bg-gray-100 !text-black  !border !border-red-400 !p-1 !rounded !shadow leading-8"
        case "resolved":return "!bg-gray-100 !text-black  !border !p-1 !rounded !shadow leading-8"
        case "viewed":return "!bg-gray-200 !text-black !font-bold !border !p-1 !rounded !shadow leading-8"
        case "new":return "!bg-intesa-green !text-white !font-bold !border !p-1 !rounded !shadow align-baseline leading-8"
        default:return "text-black"
    }
}
export const  ODataGridRowCell: React.FC<PROPS> = ({ header, x, isLoading, enumStoreKey }) => {
    const {
        setHeaderFilter
    } = useHeaderCell({
        header, enumStoreKey
    })
    const navigate = useNavigate();
    const getHeaderValue = (header: ISortableHeader<any>) => {
        if (header.selector) {
        console.log(header.selector(x))
        return header.selector(x)
        }
        if (header.text) {
            return header.text(x)
        }
        return undefined;
    }
    const isStatusHeader = ["Stato"].includes(header.label);
    
    if (header.text)
        return <>
            {header.navigationUrl && <IconButton
                // style={{ height:15,width:15}}
                onClick={() => {
                    if (header.navigationUrl) {
                        navigate(header.navigationUrl(x))
                    }
                }}
            >
                <IoNavigateSharp fontSize="0.8rem"/>
            </IconButton>}
            <Typography
                variant="body1"
                onContextMenu={(e) => {
                    e.preventDefault();
                    setHeaderFilter(header, getHeaderValue(header))
                }}
                align="center"
                className={`!w-[80%] !mx-auto !mt-auto !min-h-[30px]  ${isStatusHeader ? statusClassNames(x.Status) : ''}`}
            >
                {x ? header.text(x) : ""}
            </Typography>
        </>
    if (header.element)
        return x ? <>{header.element(x)}</> : null
    return null;
}