import React from 'react';
import { ChartsProps } from './types/charts';
import { useCharts } from './hooks/useCharts';
import { Bar } from 'react-chartjs-2';
import { mobileDashboardHeaderHeight } from '../DashboardHeader/DashboardHeader';
const MOBILE_Charts: React.FC<ChartsProps> = ({charts}) => {
    const containerStyles = React.useMemo<React.CSSProperties>(() => ({
        paddingBottom: 100,
        justifyContent: 'center',
        alignItems: "center",
        flexDirection: "column",
        zIndex: 1,
        minHeight: 300,
    }), [])
    const {
        getChartOptions,
        mapChart
    } = useCharts({ charts });
    return <div style={containerStyles}>{charts.map(mapChart).map((data, i) => (
        <div style={{ maxWidth: 700, margin: 10 }}>
            <Bar
                width={660}
                height={375 - mobileDashboardHeaderHeight * 2}
                style={{
                    minHeight: 375 - mobileDashboardHeaderHeight * 3,
                    maxHeight: 375 - mobileDashboardHeaderHeight * 2,
                    borderRadius: 8,
                    padding: 5,
                    border: "1px solid #222A35",
                    backgroundColor: "rgb(71,85,105)",
                    color: "white",
                    margin: 5,
                }}
                data={data}
                options={getChartOptions(i)}
            />
        </div>
    ))}</div>
}
export { MOBILE_Charts }