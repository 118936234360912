import React from "react";
import {useDashboardsNavigation} from "../../../store/hooks/useDashboardsNavigation";
import {useDashboardsStore} from "../../../store/hooks/useDashboardsStore";
import {KPIColumn} from "../../Columns/KPIColumn";
import {TAssetLevels, TAssetTypes} from "../../../services/products";
import { SBCharts } from "../../Charts/IoT/Charts";
export type KPISectionProps = {};
export const KPISection: React.FC<KPISectionProps> = ({}) => {
  const {productId, areaSettings} =
    useDashboardsNavigation();
  const {groupedProductAssets} = useDashboardsStore();
  const charts = React.useMemo(() => {
    switch (productId) {
      case "iot-connected-buildings":
        return <SBCharts />;
      default:
        return <SBCharts />;
    }
  }, [productId]);
  return (
    <>
      <KPIColumn
        charts={charts}
        settings={areaSettings?.KPI}
        gauges={
          groupedProductAssets[TAssetTypes.RangeKPI]?.filter(
            (x) =>
              x.ProductId === productId &&
              x.Level === TAssetLevels.ProductPage
          ) ?? []
        }
        tables={
          groupedProductAssets[TAssetTypes.CardKPI]?.filter(
            (x) =>
              x.ProductId === productId &&
              x.Level === TAssetLevels.ProductPage
          ) ?? []
        }
      />
    </>
  );
};
