import React from "react";
import {
  DashboadSizeID,
  IDashboardColumnsDimensions,
  getDashboardPagesLocalStorageKey,
} from "../../hooks/useProductDashboardDimensions";
import {dashboardsContext} from "../dashboardsStore";
import {
  DashboadSizeEnum,
  IFrontendSettingsGet,
  getAllFrontendSettings,
} from "../../services/frontendSettings";
import {arraysAreSame} from "../../utility/arrays";
import useWindowSize from "../../utility/useWindowSize";
import {useUserContext} from "./useUserContext";

export interface ISettingsContext {
  currentSize?: DashboadSizeEnum;
  settings: {[key: string]: IDashboardColumnsDimensions};
  isLoadingSettings: boolean;
  loadAllSettings: () => void;
  setSettings: (settings: {[key: string]: IDashboardColumnsDimensions}) => void;
  setIsLoadingSettings: (b: boolean) => void;
  setNewSettingsOnStore: (res: IFrontendSettingsGet[], areaKey: string) => void;
}

export const useDashboardSettings: () => ISettingsContext = () => {
  const {state, dispatch} = React.useContext(dashboardsContext);
  const {settings, isLoadingSettings} = state;
  const {accessToken} = useUserContext();
  const {width, height} = useWindowSize();
  const currentSize = React.useMemo<DashboadSizeEnum|undefined>(
    () => DashboadSizeID(width, height),
    [width, height]
  );
  const setSettings = React.useCallback(
    (payload: {[key: string]: IDashboardColumnsDimensions}) => {
      dispatch({type: "SET_SETTINGS", payload});
    },
    [dispatch]
  );
  const setIsLoadingSettings = React.useCallback(
    (payload: boolean) => dispatch({type: "SET_ISLOADING_SETTINGS", payload}),
    [dispatch]
  );

  const setNewSettingsOnStore = React.useCallback(
    (res: IFrontendSettingsGet[], areaKey: string) => {
      let new_area_key_settings: {[key: string]: IFrontendSettingsGet} = {};
      for (let setting of res) {
        new_area_key_settings[setting.FrontendSectionsEnum] = setting;
      }
      const sortSettingEntries = (
        x: [string, IFrontendSettingsGet],
        y: [string, IFrontendSettingsGet]
      ) => (x[0].toString() > y[0].toString() ? 1 : -1);
      const areaSettings:IDashboardColumnsDimensions|undefined = settings[areaKey];
      const settingsAreDifferent = !areaSettings || !arraysAreSame(
        Object.entries(new_area_key_settings).sort(sortSettingEntries),
        Object.entries(areaSettings).sort(sortSettingEntries),
        (x: [string, IFrontendSettingsGet], y: [string, IFrontendSettingsGet]) =>
          x[0].toString() === y[0].toString() &&
          x[1].SectionColumns === y[1].SectionColumns &&
          x[1].IsVisible === y[1].IsVisible &&
          x[1].UIOrder === y[1].UIOrder &&
          x[1].RowVersion === y[1].RowVersion
      );
      if (Object.keys(new_area_key_settings).length > 0 && settingsAreDifferent) {
        setSettings({...settings, [areaKey]: new_area_key_settings});
      }
    },
    [setSettings, settings]
  );
  const loadAllSettings = React.useCallback(async () => {
    if (!accessToken || !currentSize) return;
    getAllFrontendSettings().then((res: IFrontendSettingsGet[]) => {
      const be_settings: {[key: string]: IDashboardColumnsDimensions} = {};
      for (let setting of res) {
        let areaKey = getDashboardPagesLocalStorageKey(
          setting.ProductId,
          setting.WindowSizeEnum
        );
        be_settings[areaKey] = be_settings[areaKey]
          ? {...be_settings[areaKey], [setting.FrontendSectionsEnum]: setting}
          : {[setting.FrontendSectionsEnum]: setting};
      }
      
      const sortSettingsValues = (
        a: IFrontendSettingsGet,
        b: IFrontendSettingsGet
      ) => a.UIOrder - b.UIOrder;
      const valuesAreEquals = arraysAreSame(
        Object.values(settings)
          .map((x) => x as IFrontendSettingsGet)
          .sort(sortSettingsValues),
        Object.values(be_settings)
          .map((x) => x as IFrontendSettingsGet)
          .sort(sortSettingsValues),
        (a: IFrontendSettingsGet, b: IFrontendSettingsGet) =>
          a.IsVisible === b.IsVisible &&
          a.SectionColumns === b.SectionColumns &&
          a.UIOrder === b.UIOrder
      );
      if (!valuesAreEquals) {
        setSettings(be_settings);
      }
    });
  }, [accessToken, currentSize, setSettings, settings]);

  return {
    currentSize,
    settings,
    isLoadingSettings,
    loadAllSettings,
    setSettings,
    setIsLoadingSettings,
    setNewSettingsOnStore,
  };
};
