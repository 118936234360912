import React from 'react'
import { Page } from '../../components/Page/Page'
import { useQuery } from '../../utility/useQuery';
import { AlertEventDetail } from '../../components/AlertEventDetail/AlertEventDetail';
import { Spacer } from '../../components/Spacer/Spacer';
export const AlertEventDetailPage: React.FC = () => {
    const query = useQuery();
    const id = parseInt(query.get("id") ?? "-1") ?? -1
    return <Page>
        <Spacer spacing={2} direction='y'/>
        <AlertEventDetail id={id} />
    </Page>
}