import { IconButton, Typography } from '@mui/material';
import React, { useState } from 'react';
import { MdOutlineDateRange } from 'react-icons/md';
import { ReactDateRangePicker } from './ReactDateRangePicker';
import { HeaderFilterProps } from '../../types';
import Modal from '@mui/material/Modal';
import dayjs from 'dayjs';
import { IColumnFilter } from '../../../../types/odata';
import { useHeaderCell } from '../../hooks/useHeaderCell';
export const formatDateRange = (filter:IColumnFilter<any>) => {
    const {startDate,endDate} = filter.value;
    const date_format = "DD-MM-YY"
    if (endDate && dayjs(endDate).format(date_format) !== dayjs(startDate).format(date_format)) {
        return `Dal ${dayjs(startDate).set('hour', 0).set('minute', 0).set('second', 0).format(date_format)} al ${dayjs(endDate).set('hour', 0).set('minute', 0).set('second', 0).format(date_format)}`
    }
    return dayjs(startDate).set('hour', 0).set('minute', 0).set('second', 0).format(date_format)
}
export const DateRangeHeaderFilterCell: React.FC<HeaderFilterProps> = ({header,enumStoreKey}) => {
    const {setHeaderFilter,headerCurrentFilter,removeHeaderFilter,isLoading} = useHeaderCell({header,enumStoreKey})

    const [modalOpen, setModalOpen] = useState(false)
    
    const headerCurrentFilterStringValue = React.useMemo(() => headerCurrentFilter?.value ?
        formatDateRange(headerCurrentFilter)
        :
        header.label, [header, headerCurrentFilter])
    return <>
        <div className='flex justify-between items-center border border-solid border-gray-400 hover:!text-black hover:!border-black cursor-pointer'
            onClick={() => (header.clickable && !isLoading) ? setModalOpen(true) : {}}
            onContextMenu={(e)=>{
                e.preventDefault();
                setHeaderFilter(header,undefined)
            }}
            style={{ marginBottom:2, height: 55, width: 'calc(100% - 2px)' }}
        >
            <Typography className='!ml-3' style={{ color: 'gray' }}>{headerCurrentFilterStringValue}</Typography>
            <IconButton
                className='hover:!bg-transparent'
                disabled={isLoading}
            >

                <MdOutlineDateRange />
            </IconButton>
        </div>
        <Modal
            open={modalOpen}
            className='flex flex-col items-center justify-center h-[100vh] w-[100vw] left-0 top-0'
            onClose={() => setModalOpen(false)}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
        >
            <ReactDateRangePicker
                customSetFilters={(range: any) => setHeaderFilter(header, range)}
                startRange={headerCurrentFilter?.value ? headerCurrentFilter.value.startDate : undefined}
                onClose={() => { setModalOpen(false); }} />

        </Modal>
    </>
}