import { AuthenticationResult, Configuration, PublicClientApplication } from "@azure/msal-browser";
import { useMsal } from "@azure/msal-react";
import axios from "axios";
import React from "react";
import { msalConfigGraph, msalConfigPbi } from "../../../authConfig";
let scopeBase: string[] = [
    "https://analysis.windows.net/powerbi/api/Dataset.Read.All",
    "https://analysis.windows.net/powerbi/api/Report.Read.All",
    "https://analysis.windows.net/powerbi/api/Workspace.Read.All",
]

const graphScopeBase = [
    "https://graph.microsoft.com/Files.Read.All",
    "https://graph.microsoft.com/User.ReadBasic.All"
]

// const clientId = process.env.REACT_APP_MSAL_PBI_CLIENTID ?? '';
// const tenantId = process.env.REACT_APP_MSAL_TENANTID ?? "";
// const authority = `https://login.microsoftonline.com/${tenantId}`;
// const redirectUri = process.env.REACT_APP_MSAL_PBI_REDIRECTURI;
// const msalConfigPbi: Configuration = {
//     auth: {
//         clientId,
//         authority,
//         redirectUri,
//     },
//     cache: {
//         cacheLocation: "localStorage", // This configures where your cache will be stored
//         storeAuthStateInCookie: false, // Set this to "true" if you are having issues on IE11 or Edge
//     }
// };
export type Props = {
    setAccessToken:(token:string)=>void
    catchError:(err:any,ssoError:boolean)=>void
}
export function usePowerBiAuth(props:Props) {
    const [loginState, setLoginState] = React.useState({
        sso: {
            success: false,
            error: false,
        },
        popup: {
            success: false,
            error: false,
        }
    })
    const {
        accounts
    } = useMsal();
    const loginRequest = {
        scopes: scopeBase,
        account: accounts[0],
    };
    const graphLoginRequest = {
        scopes: graphScopeBase,
        account: accounts[0],
    };
    const msalInstancePbi = new PublicClientApplication(msalConfigPbi)
    const msalInstanceGraph = new PublicClientApplication(msalConfigGraph)
    const tryLoginWithPopup = async (ssoError = true, tokenType:"graph"|"analysis"="analysis") => {
        const request = tokenType === "graph" ? graphLoginRequest : loginRequest;
        const _instance = tokenType === "graph" ? msalInstanceGraph : msalInstancePbi;
        await _instance.loginPopup(request)
            .then((res: AuthenticationResult) => {
                // setState({ ...state, accessToken: res.accessToken });
                console.log(res.accessToken,request)
                props.setAccessToken(res.accessToken)
                setLoginState({
                    ...loginState,
                    sso: {
                        success: !ssoError,
                        error: ssoError,
                    },
                    popup: {
                        success: true,
                        error: false
                    }
                })
            })
            .catch((err: any) => {
                props.catchError(err,ssoError)
            })
    }
    const getAccessToken = async (tryPopup?:boolean,tokenType:"graph"|"analysis"="analysis") => {
        const request = tokenType === "graph" ? graphLoginRequest : loginRequest;
        const _instance = tokenType === "graph" ? msalInstanceGraph : msalInstancePbi;
        _instance.acquireTokenSilent(request)
            .then((res: AuthenticationResult) => {
                // setState({ ...state, accessToken: res.accessToken });
                props.setAccessToken(res.accessToken)
                setLoginState({
                    ...loginState,
                    sso: {
                        success: true,
                        error: false
                    }
                })
            })
            .catch((err: any) => {
                if (tryPopup && !loginState.popup.error) {
                    tryLoginWithPopup(true,tokenType);
                } else {
                    setLoginState({
                        ...loginState,
                        sso: {
                            error: true,
                            success: false
                        }
                    })
                }
            })
    }
    const getReportsList = async (token?:string) => {
        const url = 'https://api.powerbi.com/v1.0/myorg/reports'
        const config = {
            headers: { Authorization: `Bearer ${token}` }
        };
        return axios.get(url,config).then((res)=>{
            console.log(res)
            return res.data;
        }).catch((err)=>console.log(err))
    }
    const getScorecardsList = async (token?:string) => {
        const url = 'https://api.powerbi.com/v1.0/myorg/scorecards'
        const config = {
            headers: { Authorization: `Bearer ${token}` }
        };
        return axios.get(url,config).then((res)=>{
            console.log(res)
            return res.data;
        })
    }
    return {
        getAccessToken,
        loginState,
        setLoginState,
        getReportsList,
        getScorecardsList,
    }
}