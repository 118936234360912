import React from "react";
import { useDashboardsStore } from "../../store/hooks/useDashboardsStore";
import { ComboBox, FontIcon, Toggle } from "@fluentui/react";
import { TProduct } from "../../services/products";
import { IUser, IUserRole } from "../../services/user";
import './styles.css'
import { GiConfirmed } from "react-icons/gi";
import { BsXCircle, BsXSquare, BsXSquareFill } from "react-icons/bs";
type Props = {
  editable?: boolean;
  handleChange?: (
    u: IUser,
    a: TProduct,
    ruoloId?: string,
    enable?: boolean
  ) => Promise<void>;
  currentValues?: { [key: string]: { RuoloId: string; enable: boolean } };
  user: IUser;
  roles: IUserRole[];
};
const UserProductsToggle: React.FC<Props> = ({
  currentValues,
  editable,
  user,
  roles,
  handleChange,
}) => {
  const { products } = useDashboardsStore();
  const [isLoading, setIsLoading] = React.useState(false);
  const comboOptions = React.useMemo(
    () => roles.map((r) => ({ key: r.RuoloId, text: r.Description })),
    [roles]
  );

  const userIsEnabled = (product: TProduct) => {
    return currentValues && currentValues[product.ProductId]
      ? currentValues[product.ProductId].enable
      : false;
  };

  return <>
    {products
      .filter((p) => p.IsEnabled)
      .map((product) => (
        <div className="flex flex-row my-2 items-end border-b border-solid py-1 w-[100%] ">
          {editable ? (
            <>
              <p
                //   style={{ color: editable ? "rgb(150,150,150)" : "" }}
                className={`text-lg mx-2 w-[30%] font-bold`}
              >
                {product.Name}
              </p>
              <Toggle
                disabled={!editable}
                styles={{
                  text: {
                    color: 'white'
                  },
                  root: { padding: 0, margin: 0 },
                }}
                className="mx-2 w-[20%] min-w-[100px] mt-auto"
                onText="abilitato"
                offText="disabilitato"
                checked={userIsEnabled(product)}
                onChange={(e, checked) => {
                  if (!handleChange) return;
                  handleChange(
                    user,
                    product,
                    currentValues && currentValues[product.ProductId]
                      ? currentValues[product.ProductId].RuoloId
                      : "base",
                    checked
                  );
                }} //ruoloId non è rilevante, abilita e disabilita l'utente nell'area.
              />
              <ComboBox
                styles={{
                  rootDisabled: { backgroundColor: "rgb(230,230,230)" },
                  inputDisabled: {
                    color: "rgb(150,150,150)",
                    fontWeight: "bold",
                    backgroundColor: "rgb(230,230,230)",
                  },
                  container: {
                    flexGrow: 1,
                    width: "40%",
                    maxWidth: 400,
                    //   borderRadius: 8,
                    color: 'white',
                    backgroundColor: "rgb(30 41 59)",
                  },
                  root: {
                    //   borderRadius: 8,
                    //   backgroundColor: "rgb(100,116,139)",
                    backgroundColor: "rgb(30 41 59)",
                    color: 'white'
                  },
                  input: {
                    //   borderRadius: 8,
                    //   backgroundColor: "rgb(100,116,139)",
                    backgroundColor: "rgb(30 41 59)",
                    color: "white",
                    textAlign: 'center'
                  },
                  label: { color: 'white' },
                  optionsContainer: { color: 'white', backgroundColor: "rgb(100,116,139)" },
                  optionsContainerWrapper: { color: 'white', backgroundColor: "rgb(100,116,139)" },
                  callout: { color: 'white' },
                }}
                disabled={!editable || isLoading}
                options={comboOptions}
                selectedKey={
                  currentValues && currentValues[product.ProductId]
                    ? currentValues[product.ProductId].RuoloId
                    : null
                }
                onChange={async (e, opt) => {
                  if (!handleChange) return;
                  setIsLoading(true);
                  handleChange(user, product, opt?.key as string, true).finally(
                    () => setIsLoading(false)
                  );
                }}
              />
            </>
          ) : (
            <>
              {userIsEnabled(product) ? (
                // <FontIcon className="text-3xl text-intesa-green border-intesa-green px-1 border"  iconName="Accept"/>
                <GiConfirmed className="text-3xl text-intesa-green border-intesa-green" />
              ) : (
                <BsXCircle className="text-3xl text-red-500" />
              )}
              <p
                style={{ color: editable ? "rgb(150,150,150)" : "" }}
                className={`text-lg mx-2 w-[30%] font-bold`}
              >
                {product.Name}
              </p>
              <p className={`text-xl mx-auto`}>
                {userIsEnabled(product) && currentValues && currentValues[product.ProductId]
                  ? `${roles.find((r) => r.RuoloId === currentValues[product.ProductId].RuoloId)?.Description}`
                  : null}
              </p>
            </>
          )}
        </div>
      ))}
  </>
};
export { UserProductsToggle };
