import React from "react";
import { paginationContext, PaginationEnum } from "../../../store/pager";
import { ISortableHeader } from "../../../types/general";
import { FaArrowDown, FaArrowUp } from "react-icons/fa";
import { IColumnFilter, IColumnSorting, ODataValueTypes } from "../../../types/odata";
import { IHeaderCellContext } from "../types";
import { useNavigate } from "react-router-dom";
import { assignments_known_urls } from "../../../types/userStore";


export const useHeaderCell: (props: {
    header: ISortableHeader<any>,
    enumStoreKey: PaginationEnum
}) => IHeaderCellContext = (props) => {
    const { enumStoreKey, header, } = props
    const navigate = useNavigate();
    const { state, dispatch } = React.useContext(paginationContext);
    const {isLoading} = state.pagination[enumStoreKey];
    const handleRemoveSort = (e: any, header: ISortableHeader<any>) => {
        e.preventDefault();
        if (isLoading) return;
        dispatch({
            type: "SET_SORTINGS",
            payload: { key: enumStoreKey, sortings: state.pagination[enumStoreKey].sortings.filter((s) => s.key !== header.id) },
        });
    };
    const headerCurrentSort = state.pagination[enumStoreKey].sortings.find((h) => h.key === header.id)
    const headerCurrentFilter = state.pagination[enumStoreKey].filters.find(
        (h) => h.columnName === header.id
    );
    const filterValueType =
        headerCurrentFilter?.valueType ?? header.valueType;
    const removeHeaderFilter = (h: ISortableHeader<any>) => {
        dispatch({
            type: "SET_FILTERS_AND_PRESETS",
            payload: {
                key: enumStoreKey,
                filters: state.pagination[enumStoreKey].filters.filter(
                    (f) => f.columnName !== h.id
                ),
                presets: state.pagination[enumStoreKey].filtersPresets.filter(
                    (preset) => preset.filter.columnName !== h.id
                ),
            },
        });
    };
    const setHeaderFilter = (header: ISortableHeader<any>, value?: any, forceSet?: boolean) => {
        if ((!value || (Array.isArray(value) && value.length === 0)) && !forceSet) {
            removeHeaderFilter(header);
        } else {
            const operator = header.defaultFilterOperator ?? "contains";
            const newValue = value;
            const newPresets = state.pagination[enumStoreKey].filtersPresets.filter(
                                (preset) => preset.filter.columnName !== header.id
                            )
            const newFilters = state.pagination[enumStoreKey].filters.find(
                                (f) => f.columnName === header.id
                            )
                                ? 
                                state.pagination[enumStoreKey].filters.map((f) =>
                                    f.columnName === header.id
                                        ? {
                                            columnName: header.id,
                                            value: newValue,
                                            operator,
                                            valueType: header.valueType,
                                            nestedColumn: header.nestedColumn
                                        }
                                        : f
                                )
                                : 
                                [
                                    ...state.pagination[enumStoreKey].filters,
                                    {
                                        columnName: header.id,
                                        value: newValue,
                                        operator,
                                        nestedColumn: header.nestedColumn,
                                        valueType: header.valueType,
                                    },
                                ]
                // navigate(`${known_urls.assignment_list}/${JSON.stringify(newFilters)}/${JSON.stringify(newPresets)}`)
            dispatch({
                type: "SET_FILTERS_AND_PRESETS",
                payload: {
                    key: enumStoreKey,
                    presets: newPresets,
                    filters: newFilters,
                },
            });
        }
    };
    
    const sortingArrow = React.useMemo(() => {
        if (!headerCurrentSort) return null;
        return headerCurrentSort.direction === "asc" ? (
            <FaArrowUp style={{position:'absolute',right:4,bottom:4,width:10,height:10,}} />
        ) : (
            <FaArrowDown style={{position:'absolute',right:4,bottom:4,width:10,height:10,}} />
        );
    }, [headerCurrentSort]);
    return {
        ...props,
        isLoading,
        handleRemoveSort,
        setHeaderFilter,
        filterValueType,
        sortingArrow,
        removeHeaderFilter,
        headerCurrentFilter,
        headerCurrentSort
    }
}