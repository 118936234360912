import { TextField } from '@mui/material';
import React from 'react';
import { TProductAsset } from '../../../services/products';
import { JsonTextField } from './JsonTextField';
import ReactJSONEditor from "react-jsoneditor-wrapper";
export interface StyledTextFieldProps {
    asset: TProductAsset | null
    label: string
    placeholder: string
    required?: boolean
    inputId: string
    value: any
    onChange?: (e: React.ChangeEvent<HTMLInputElement>) => void
    onBlur?: (e: React.FocusEvent<HTMLInputElement>) => void
    setValue:(value:any)=>void
    multiline?: boolean
    isJson?: boolean,
    disabled?: boolean
    setJsonValue?: (key: string, value: any) => void
    editorRef?:React.MutableRefObject<ReactJSONEditor>
}
const StyledTextField: React.FC<StyledTextFieldProps> = ({
    label,
    placeholder,
    required,
    inputId,
    value,
    onChange,
    multiline,
    isJson,
    disabled,
    setJsonValue,
    onBlur,
    asset,
    editorRef,
    setValue,
}) => {
    return isJson && setJsonValue ?
        <JsonTextField
            setValue={setValue}
            editorRef={editorRef}
            label={label}
            disabled={disabled}
            placeholder={placeholder}
            required={required}
            multiline={multiline}
            value={value}
            onBlur={onBlur}
            onChange={onChange}
            asset={asset}
            inputId={inputId}
            setJsonValue={setJsonValue}
            isJson
        />
        :
        <TextField
            variant="outlined"
            onBlur={onBlur}
            label={label}
            disabled={disabled}
            placeholder={placeholder}
            required={required}
            multiline={multiline}
            fullWidth
            InputLabelProps={{
                style: {
                    color: 'white',
                    paddingRight: 10,
                    paddingLeft: 10
                }
            }}
            inputProps={{
                style: {
                    color: 'white',
                    backgroundColor: 'rgb(51,65,85)',
                    borderRadius: 8,
                    paddingRight: 10,
                    paddingLeft: 10
                },

            }}
            id={inputId}
            value={value}
            onChange={onChange}
            className="!my-2 !text-white !border-slate-800"
        />
}
export { StyledTextField }