import React from 'react';
import { IconButton } from '@mui/material';
import { BsArrowLeftSquare, BsArrowRightSquare } from 'react-icons/bs';
import { usePanels } from './usePanels';
import { NavigationStateKeysEnum, navigationContext } from '../../../../store/navigationStore';
import { FaArrowCircleLeft, FaArrowCircleRight } from 'react-icons/fa';
import { RxCross1 } from "react-icons/rx";
type Props = {
    height?: string | number
    open?: boolean
}
const DisposableSidebar: React.FC<Props> = ({ height, open }) => {
    const { state: navigationState, dispatch: navigationDispatch } = React.useContext(navigationContext);
    const sidebar = navigationState[NavigationStateKeysEnum.SIDEBAR]
    const setSidebarIsOpen = (isOpen: boolean) => navigationDispatch({ type: "SET_SIDEBAR_OPTIONS", options: { ...sidebar, isOpen } })
    const {
        isOpen,
        isClosable,
        isModal,
        childrens,
        position,
    } = sidebar;

    const handleClose = (e: React.MouseEvent<HTMLElement>) => {
        e.stopPropagation();
        setSidebarIsOpen(false);
    }
    const { styles } = usePanels(sidebar, height);
    const sidebarBtnStyles = React.useMemo<React.CSSProperties>(() => ({
        transition: 'all 0.25s',
        opacity: (open || sidebar.isOpen) ? 1 : 0,
        borderRadius: '8px',
        position: 'absolute',
        // border: '1px solid white',
        backgroundColor: "transparent",
        top: 'calc(50% - 17px)',
        height: 34,
        width: 34,
        fontSize: 30,
        zIndex: 999999999
    }), [sidebar.isOpen, open])

    return <>
        <div className={`relative flex flex-col ${isOpen ? 'visible bg-white text-black' : 'invisible no-sidebar'}`} id="sidebar" style={styles}>
            {childrens}
            {isClosable && <IconButton style={{ ...sidebarBtnStyles, top: 5, right: 5 }} onClick={handleClose}>
                <RxCross1 className="!text-black text-base h-[100%] w-[100%]" />
            </IconButton>}
        </div>
        {isOpen && isModal && isClosable &&
            <div style={{ zIndex: 2, position: 'fixed', top: 0, left: 0, width: '100vw', height: '100vh', backgroundColor: 'rgba(0,0,0,0.4)', cursor: 'pointer' }} onClick={handleClose} />
        }
    </>
}
export { DisposableSidebar }