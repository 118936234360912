import React from 'react'
import { settingsContext } from '../../../../../store/settingsStore'
import { Chip, Typography } from '@mui/material';
import { TMetric } from '../../../../../types/alerts';
export const chipsHeight = 60;
export const SelectedChips: React.FC = () => {
    const { state, dispatch } = React.useContext(settingsContext);
    const { selectedMetrics } = state;
    const handleRemove = (m: TMetric) => dispatch({ type: "SET_SELECTED_METRICS", payload: selectedMetrics ? selectedMetrics.filter((x) => x.Id !== m.Id) : [] })
    return <div className="flex items-center mt-2" style={{height:chipsHeight}}>
        {selectedMetrics && selectedMetrics.length > 0 ? selectedMetrics.map((m) => <Chip
            className='!bg-slate-500 !text-white !text-xl'
            style={{height:chipsHeight - 10,margin:'10px 2px',border:'1px solid white'}}
            label={`${m.Id}-${m.Name}`}
            onDelete={()=>handleRemove(m)}
        />) : <Typography className='!text-white !text-2xl w-[100%]' align='center'>Seleziona una o più metriche</Typography>}
    </div>
}