import React from 'react';
import { Directory } from './Directory';
export interface TFile {
    Name:string
    Type:"Folder"|"File"
    Items?:TFile[]
    Url?:string
}
const FileExplorer: React.FC<{files:TFile[]}> = ({ files }) => {
    return <div className="flex flex-col">{files.map((item,index)=>(<Directory key={`${item.Name}-${index}`} file={item}/>))}</div>
}
export { FileExplorer }