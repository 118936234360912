import React from "react";
import { FilesColumn } from "../../Columns/FilesColumn";
import { useDashboardsStore } from "../../../store/hooks/useDashboardsStore";
import { TAssetLevels, TAssetTypes } from "../../../services/products";
import { useDashboardsNavigation } from "../../../store/hooks/useDashboardsNavigation";
export type FilesSectionProps = {};
export const FilesSection: React.FC<FilesSectionProps> = ({ }) => {
  const { groupedProductAssets } = useDashboardsStore();
  const { productId, areaSettings } = useDashboardsNavigation();
  return (
    <FilesColumn
      settings={areaSettings?.FILES}
      files={
        groupedProductAssets[TAssetTypes.FileTreeView]?.filter(
          (x) =>
            x.ProductId === productId &&
            x.Level === TAssetLevels.ProductPage
        ) ?? []
      }
    />
  );
};
