import React from 'react'
import { AssignmentStatusEnum, EticketSourceTypeEnum, TAssignment } from '../../types/assignments'

import { formatDate } from '../../utility/formatDate'
import { DetailsContainer } from '../DetailsContainer/DetailsContainer'
import { DetailsLabelValueContainer } from '../DetailsLabelValueContainer/DetailsLabelValueContainer'
import { DetailsValue } from '../DetailsValue/DetailsValue'
import { DetailsLabel } from '../DetailsLabel/DetailsLabel'
import { Status_Mapper } from '../../store/hooks/useHeaders'
import { DetailsRow } from '../DetailsRow/DetailsRow'
import { DetailsTitle } from '../DetailsTitle/DetailsTitle'
import { assignments_known_urls } from '../../types/userStore'
import { DetailNavigatorIcon } from '../DetailNavigatorIcon/DetailNavigatorIcon'
import { LinearProgress, Typography } from '@mui/material'
import { useIsMobile } from '../../utility/useIsMobile'
import { DetailDescription } from '../DetailsRow/DetailDescription/DetailDescription'
import { AssignmentDetailCard } from './AssignmentDetailCard/AssignmentDetailCard'
import { statusClassNames } from '../ODataGrid/ODataGridRow/ODataGridRowCell/ODataGridRowCell'
import { intesa_green } from '../../../../themes/themes'
import { ExtendedAlertDetail } from '../AlertDetail/ExtendedAlertDetail'
import { ExtendedSuperMetricValueDetailExpandableContainer } from '../SuperMetricValueDetail/ExtendedSuperMetricValueDetailExpandableContainer'
import { IExtendedSuperMetricValue } from '../../types/etickets'
import { useUserContext } from '../../../../store/hooks/useUserContext'
import { globalContext } from '../../store/globalStore'
import { getSuperMetricValue } from '../../services/supermetric'
import { APIError } from '../../types/general'
import { IExtendedAlert } from '../../types/alerts'
import { getExtendedAlert } from '../../services/alerts'
import { Link } from 'react-router-dom'
type Props = {
    isLoading: boolean
    assignment: TAssignment
    fullWidth?: boolean
}
export const AssignmentDetail: React.FC<Props> = (props) => {
    const { isLoading, assignment, fullWidth } = props
    const { authenticatedCall, userClaims } = useUserContext();
    const [localIsLoading, setIsLoading] = React.useState(false);
    const { dispatch: globalDispatch } = React.useContext(globalContext);
    const [superMetricValue, setSuperMetricValue] = React.useState<IExtendedSuperMetricValue>();
    const [alert, setAlert] = React.useState<IExtendedAlert>()
    React.useEffect(() => {
        const _load = async () => {
            setIsLoading(true);
            if (assignment.SourceType === EticketSourceTypeEnum.Metric) {
                getExtendedAlert(assignment.ExternalEventId)
                    .then((res) => setAlert(res))
                    .catch((err: APIError) => globalDispatch({ type: "SET_ERROR", payload: { message: err.response.data.Message, details: err.toString() } }))
                    .finally(() => setIsLoading(false))
            } else {
                getSuperMetricValue(assignment.ExternalEventId)
                    .then((res) => setSuperMetricValue(res))
                    .catch((err: APIError) => globalDispatch({ type: "SET_ERROR", payload: { message: err.response.data.Message, details: err.toString() } }))
                    .finally(() => setIsLoading(false))
            }
        }
        authenticatedCall(_load);
    }, [authenticatedCall, globalDispatch, assignment])
    const custom_values = React.useMemo(() => {
        if (!superMetricValue) {
            return [];
        }
        const custom_value_link_key = "custom";
        const custom_report_link_key = "custom_link_report";
        return Object.entries(superMetricValue.Metadata).filter(([key, value]) => key.toLowerCase().includes(custom_value_link_key) && !key.toLowerCase().includes(custom_report_link_key) ).map(([key, value]) => ({
            Name: `${key.substring(custom_value_link_key.length + 1).replaceAll("_", " ")}`,
            Value: `${value}`
        }))
    }, [superMetricValue])
    const custom_costs = React.useMemo(() => {
        if (!superMetricValue) {
            return [];
        }
        const custom_report_link_key = "custom_costo";
        return Object.entries(superMetricValue.Metadata).filter(([key, value]) => key.toLowerCase().includes(custom_report_link_key)).map(([key, value]) => ({
            Name: `Costo ${key.substring(custom_report_link_key.length + 1).replaceAll("_", " ")}`,
            Value: `${value} ${superMetricValue.Metadata.faultUoM}`
        }))
    }, [superMetricValue])
    const reports_links = React.useMemo(() => {
        if (!superMetricValue) {
            return [];
        }
        const custom_report_link_key = "custom_link_report";
        return Object.entries(superMetricValue.Metadata).filter(([key, value]) => key.toLowerCase().includes(custom_report_link_key)).map(([key, value]) => ({
            Name: key.substring(custom_report_link_key.length + 1).replaceAll("_", " "),
            Link: value
        }))
    }, [superMetricValue])
    const { isMobile } = useIsMobile();
    if (isMobile) {
        return <AssignmentDetailCard {...props} />
    }
    if (isLoading) {
        return <LinearProgress style={{ color: intesa_green, marginTop: 20, width: 100, height: 10 }} />
    }
    return <DetailsContainer fullWidth={fullWidth}>

        <DetailsTitle
            text="Assegnazione"
            extraText={`${Status_Mapper[assignment.Status.toString().toLowerCase()]}`}
            extraTextClassNames={statusClassNames(assignment.Status)}
        />
        <DetailNavigatorIcon url={assignments_known_urls.assignment_detail} id={assignment.Id} />
        <div className='p-2 rounded border shadow shadow-t-none m-2'>
            <DetailsRow>
                <DetailsLabelValueContainer fullWidth>
                    <DetailsLabel align='left' bold label='Assegnata a' />
                    <DetailsValue align='left' value={assignment.OwnerEmail} />
                </DetailsLabelValueContainer>
            </DetailsRow>

            {assignment.Status === AssignmentStatusEnum.Resolved &&
                <DetailsRow>
                    <DetailsLabelValueContainer fullWidth>
                        <DetailsLabel align='left' bold label="Motivo Chiusura" />
                        <DetailsValue align='left' value={assignment.ClosureReason} />
                    </DetailsLabelValueContainer>
                </DetailsRow>
            }

            <DetailsRow>
                <DetailsLabelValueContainer fullWidth>
                    <DetailsLabel bold label='Descrizione' />
                    <DetailsValue align='left' value={assignment.Description} />
                </DetailsLabelValueContainer>
            </DetailsRow>
            {/* <div className='h-[10px]' /> */}
            <DetailsRow >
                <DetailsLabelValueContainer fullWidth>
                    <DetailsLabel bold label='Data Creazione' />
                    <DetailsValue align='left' value={formatDate(assignment.OpeningTime)} />
                </DetailsLabelValueContainer>
                {/* {formatDate(assignment.ExpirationTime) && <DetailsLabelValueContainer direction='row-reverse'>
                    <DetailsValue align='right' value={formatDate(assignment.ExpirationTime)} />
                    <DetailsLabel align='right' bold label='Data Scadenza' />
                </DetailsLabelValueContainer>} */}
            </DetailsRow>
            <DetailsRow >
                <DetailsLabelValueContainer fullWidth direction='row'>
                    <DetailsLabel bold label='Polo' />
                    <DetailsValue align='left' value={assignment.Polo} />
                </DetailsLabelValueContainer>
            </DetailsRow>
            <DetailsRow >
                <DetailsLabelValueContainer fullWidth direction='row'>
                    <DetailsLabel bold label='Presidio' />
                    <DetailsValue align='right' value={assignment.Presidio} />
                </DetailsLabelValueContainer>
            </DetailsRow>

            <DetailsRow >
                <DetailsLabelValueContainer fullWidth direction='row'>
                    <DetailsLabel bold label='Edificio' />
                    <DetailsValue align='right' value={assignment.BuildingName} />
                </DetailsLabelValueContainer>
            </DetailsRow>
            <DetailsRow >
                <DetailsLabelValueContainer fullWidth direction='row'>
                    <DetailsLabel bold label='CRI' />
                    <DetailsValue align='right' value={assignment.CRImmobile} />
                </DetailsLabelValueContainer>
            </DetailsRow>
            <DetailsRow >
                <DetailsLabelValueContainer fullWidth direction='row'>
                    <DetailsLabel bold label='Valore' />
                    <DetailsValue align='right' value={`${superMetricValue?.ActivationValue} ${superMetricValue?.Metadata.faultUoM}`} />
                </DetailsLabelValueContainer>
            </DetailsRow>
            
            {custom_values.map((custom_value) => <DetailsRow >
                <DetailsLabelValueContainer fullWidth direction='row'>
                    <DetailsLabel bold label={custom_value.Name} />
                    <DetailsValue align='right' value={custom_value.Value} />
                </DetailsLabelValueContainer>
            </DetailsRow>)}
            
            {custom_costs.map((custom_cost) => <DetailsRow >
                <DetailsLabelValueContainer fullWidth direction='row'>
                    <DetailsLabel bold label={custom_cost.Name} />
                    <DetailsValue align='right' value={custom_cost.Value} />
                </DetailsLabelValueContainer>
            </DetailsRow>)}
            <div className='flex justify-start px-4'>
                <div>
                    {reports_links.length > 0 && <Typography fontWeight={"bold"} align='left' fontSize="1.25rem">Report collegati:</Typography>}
                    {reports_links.map((r) => <Link target="_blank" to={r.Link}>
                        <Typography align='left' className='hover:!bg-gray-200 !px-4' fontSize="1.25rem">{r.Name}</Typography>
                    </Link>
                    )}
                </div>
            </div>

        </div>
        {assignment.SourceType === EticketSourceTypeEnum.Metric && <ExtendedAlertDetail id={assignment.ExternalEventId} />}
        {assignment.SourceType === EticketSourceTypeEnum.SuperMetric && <ExtendedSuperMetricValueDetailExpandableContainer id={assignment.ExternalEventId} />}
    </DetailsContainer >
}