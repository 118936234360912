import React from "react";
import { known_products } from "../../types/userStore";
import { useDashboardsNavigation } from "../../store/hooks/useDashboardsNavigation";
import { RowDashboardContainerSidebar } from "../RowDashboardContainer/RowDashboardContainerSidebar/RowDashboardContainerSidebar";
import { TGroupedHelp } from "../RowDashboardContainer/RowDashboardContainer";
import { useDashboardsStore } from "../../store/hooks/useDashboardsStore";
import { TAssetTypes } from "../../services/products";
import { DashboardHeader } from "../DashboardHeader/DashboardHeader";
import { DisposableSidebar } from "../DisposableSidebar/DisposableSidebar";
import { ProductDashboardFooter } from "./ProductDashboardFooter/ProductDashboardFooter";
import { Route, Routes, useNavigate } from "react-router-dom";
import { useUserContext } from "../../store/hooks/useUserContext";
import { usePowerBiHelperPreLoad } from "../powerBi/hooks/usePowerBiHelperPreLoad";
import {
  QUERY_PAGE_KEY,
  SELECTED_ICON_QUERY_KEY,
} from "../../hooks/useQueryValues";
import { areaName } from "../../pages/EditAssetsPage/EditAssetsPage";

export type ProductDashboardProps = {
  title: string;
};
export const ProductDashboard: React.FC<ProductDashboardProps> = ({
  title,
}) => {
  const navigate = useNavigate();
  usePowerBiHelperPreLoad();
  const { pageSections, currentIcons, productId } = useDashboardsNavigation();
  const { accessToken } = useUserContext();
  const {
    groupedProductAssets,
    rowDashboardContainersidebarWidth,
    loadProductsAndAssets,
    isLoaded,
  } = useDashboardsStore();
  React.useEffect(() => {
    if (!isLoaded && accessToken) {
      loadProductsAndAssets();
    }
  }, [accessToken, isLoaded, loadProductsAndAssets]);
  const helps = React.useMemo(() => {
    let result = [
      {
        parent: "Servizi",
        items: [
          {
            title: "Ticket Connector",
            help: "Strumento per la visualizzazione dei Fault provenienti dagli edifici monitorati, che permette anche la visualizzazione e la gestione di Ticket che possono essere associati a questi Fault.",
          },
          {
            title: "Logbook",
            help: "Strumento per la gestione e visualizzazione di anomalie generate da algoritmi di monitoraggio.",
          },
        ],
      },
    ];
    const scorecards = groupedProductAssets[TAssetTypes.ScoreCard] ?? [];
    const specialReports =
      groupedProductAssets[TAssetTypes.SpecialReport] ?? [];
    const reports = groupedProductAssets[TAssetTypes.Report] ?? [];
    if (scorecards.length > 0) {
      let x: TGroupedHelp = {
        parent: "Scorecards",
        items: [],
      };
      scorecards.forEach((s) =>
        x.items.push({
          title: s.Name,
          help: s.Description,
        })
      );
      result.push(x);
    }
    if (specialReports.length > 0) {
      let x: TGroupedHelp = {
        parent: "Business Reports",
        items: [],
      };
      specialReports.forEach((r) =>
        x.items.push({
          title: r.Name,
          help: r.Description,
        })
      );
      result.push(x);
    }
    if (reports.length > 0) {
      let x: TGroupedHelp = {
        parent: "Report tecnici",
        items: [],
      };
      reports.forEach((r) =>
        x.items.push({
          title: r.Name,
          help: r.Description,
        })
      );
      result.push(x);
    }

    return result;
  }, [groupedProductAssets]);
  return (
    <>
      <div
        className="flex flex-col min-w-[100vw] h-[100vh] bg-slate-600 text-white overflow-hidden max-w-[100vw]"
        style={{
          zIndex: 3,
          backgroundColor: "#222A35",
          backgroundImage:
            "linear-gradient(to bottom,rgb(70,96,129),rgb(70,96,129))",
        }}
      >
        <div className="flex flex-row justify-start items-start max-w-[100%] w-[100%] overflow-hidden">
          <DashboardHeader>
            {pageSections.map((x) => x && x.header)}
          </DashboardHeader>
          <div style={{ marginLeft: rowDashboardContainersidebarWidth }} />
          {pageSections.map((x, i) => x && x.section)}
          <DisposableSidebar />
        </div>
        <ProductDashboardFooter />
      </div>
      <RowDashboardContainerSidebar
        showBorder
        showBackArrow
        handleGoBack={() => {
          navigate("/");
        }}
        title={title}
        showHelp
        helps={helps}
        currentIcons={currentIcons}
      />
    </>
  );
};
