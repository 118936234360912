import React from "react";
import { TProductAsset } from "../../../services/products";
import { ChartsProps } from "../types/charts";
import useWindowSize from "../../../utility/useWindowSize";

export type TContext = {

}

export const useCharts = ({ charts }: ChartsProps) => {
    const { width, height } = useWindowSize();
    const mapChart = (chart: TProductAsset) => ({
        labels: chart.AssetBag.Period ?? [],
        datasets: [
            {
                label: "Consumi",
                data: chart.AssetBag.Consumi ?? [],
                borderColor: "white",
                backgroundColor: "rgb(146,64,14)",
                borderWidth: 1,
            },
            {
                label: "Continuità",
                data: chart.AssetBag.Continuita ?? [],
                borderColor: "white",
                backgroundColor: "rgb(215,158,11)",
                borderWidth: 1,
            },
        ],
    });


    const barFontSize = React.useMemo(() => {
        if (!width) return 20;
        if (width > 2000) {
            return 24;
        } else if (width > 1000) {
            return 18;
        }
        return 12;
    }, [height,width]);
    const getChartOptions = React.useCallback((i: number) => ({
        responsive: true,
        plugins: {
            legend: {
                position: "top" as const,
                labels: { font: { size: barFontSize }, color: "white" },
            },
            title: {
                display: true,
                text: charts[i].Name ?? "",
                font: {
                    size: barFontSize,
                },
                color: "white",
            },
        },
        scales: {
            x: {
                ticks: {
                    font: {
                        size: barFontSize,
                    },
                    color: "white",
                },
            },
            y: {
                ticks: {
                    font: {
                        size: barFontSize,
                    },
                    color: "white",
                },
            },
        },
    }), [barFontSize, charts]);
    return {
        getChartOptions,
        mapChart,
    }
}